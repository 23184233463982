<template>
  <div class="project-importcsv">
    <div>
      <!-- RESET IMPORT CSV -->
      <v-btn-icon v-if="fileLoaded" color="medium-emphasis" class="float-right" @click="resetImportCsv()">
        <v-icon>fas fa-times</v-icon>
      </v-btn-icon>
    </div>
    <!-- FILE DROP ZONE -->
    <div v-if="! fileLoaded">
      <file-upload :upload="false" :multiple="false" accept="text/csv" input-id="import-projectcsv" @input-file="inputFile">
        <div class="text-h6" style="padding: 60px 0;">
          <i class="far fa-plus-circle"></i>&nbsp; {{ $t('IMPORT_CSV.LOAD_CSV_FILE') }}
        </div>
      </file-upload>
    </div>
    <!-- FILE CONTENT -->
    <div v-else>
      <div class="text-center my-4">
        <v-btn rounded color="primary" :disabled="! canImport" size="large" style="min-width: 300px;" @click="importRows()">
          {{ $t('IMPORT_CSV.IMPORT') }}
        </v-btn>
      </div>
      <v-card>
        <div class="table-responsive">
          <table class="table">
            <thead class="bg-lightgrey">
              <tr>
                <th class="w-0"><!-- delete btn --></th>
                <th v-for="(csvHeader) in csvHeaders" :key="csvHeader.name" class="nobr text-left">
                  <span class="ml-1">{{ csvHeader.name }}</span>
                  <v-select v-model="csvHeader.mapping" :items="bubblePlanFields" density="compact" class="py-1"></v-select>
                </th>
              </tr>
            </thead>
            <tbody>
              <import-csv-row v-for="csvRow in csvData" ref="csvRows" :key="csvRow.id" :csv-headers="csvHeaders" :csv-row="csvRow" @remove-row="removeRow(csvRow.id)">
              </import-csv-row>
            </tbody>
          </table>
        </div>
      </v-card>
      <div class="text-center my-4">
        <v-btn rounded color="primary" :disabled="! canImport" size="large" style="min-width: 300px;" @click="importRows()">
          {{ $t('IMPORT_CSV.IMPORT') }}
        </v-btn>
      </div>
      <!-- <div class="mt-4">
        <v-btn color="secondary" @click="addEmptyRow">
          <v-icon class="mr-2">far fa-plus</v-icon> {{ $t('IMPORT_CSV.ADD_ROW') }}
        </v-btn>
      </div> -->
    </div>
  </div>
</template>

<style lang="scss">
  .project-importcsv {
    table {
      width: 100%;
      tr {
        th:not(:first-child), td:not(:first-child) {
          min-width: 140px;
          max-width: 200px;
        }
      }
    }
  }
</style>

<script>
  import PlanningElement from '@/models/PlanningElement';
  import PlanningLane from '@/models/PlanningLane';
  import FileUpload from '@/components/Reusables/FileUpload.vue';
  import ImportCsvRow from './ImportCsvRow.vue';

  function parseCSVToArray(string) {
    // Get the separator
    const separators = [';', ','];
    const separatersOccs = separators.map((separater) => {
      return { separater, occ: string.match(new RegExp(separater, 'g'))?.length || 0 };
    });
    const maxOccurrence = Math.max(...separatersOccs.map(item => item.occ));
    const separater = separatersOccs.find(item => item.occ === maxOccurrence)?.separater || ';';
    // Convert CSV to Array
    let previous = ''; let row = ['']; const rows = [row]; let char; let s = ! 0; let i = 0; let r = 0;
    [...string].forEach((item) => {
      char = item;
      if (char === '"') {
        if (s && char === previous) row[i] += char;
        s = ! s;
      } else if (char === separater && s) {
        char = '';
        row[++i] = '';
      } else if (char === '\n' && s) {
        if (previous === '\r') row[i] = row[i].slice(0, -1);
        row = rows[++r] = [char = ''];
        i = 0;
      } else {
        row[i] += char;
      }
      previous = char;
    });
    return rows.filter((strRow) => {
      return strRow.some(item => item);
    });
  }

  export default {
    components: {
      FileUpload,
      ImportCsvRow,
    },
    props: {
      planning: { type: Object, required: true },
    },
    emits: ['close'],
    data() {
      return {
        csvData: [],
        csvHeaders: [],
        fileLoaded: false,
        bubblePlanFields: [
          { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.IGNORE'), value: '' },
          { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.LANE'), value: 'lane' },
          { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.TITLE'), value: 'title' },
          { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.STARTTIME'), value: 'starttime' },
          { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.ENDTIME'), value: 'endtime' },
          { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.STARTHOUR'), value: 'schedulestart' },
          { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.ENDHOUR'), value: 'scheduleend' },
          { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.DESCRIPTION'), value: 'html' },
          { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.PROGRESS'), value: 'progress' },
          { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.ACTIONS'), value: 'checklist' },
          // { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.BUDGETS'), value: 'budgets' },
          { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.USERS'), value: 'users' },
          { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.LINKS'), value: 'links' },
          // { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.DEPENDENCIES'), value: 'dependencies' },
          // { title: this.$t('IMPORT_CSV.BUBBLE_PLAN_FIELD.OUTLINELEVEL'), value: 'outlinelevel' },
        ],
      };
    },
    computed: {
      canImport() {
        if (! this.csvData.length) return false;

        const mappings = this.csvHeaders.map(header => header.mapping).filter(mapping => mapping);
        if (! mappings.length) return false;
        return mappings.length == (new Set(mappings)).size; // only unique values
      },
    },
    methods: {
      async inputFile(newFile, oldFile) {
        if (newFile && ! oldFile) {
          if (! (newFile && newFile.file && newFile.file.type === 'text/csv')) return;
          await this.readCSVasText(newFile.file).then((response) => {
            this.csvData = response;
            this.csvHeaders = this.defaultCsvHeaders();
            this.replaceHeadersById();
            this.fileLoaded = true;
          }).catch((error) => {
            if (error) {
              this.$store.dispatch('ui/msgbox/open', { title: "Error : File was not loaded", body: `${error || "Something went wrong."}`, type: 'vdialog' });
            }
          });
        }
      },
      async readCSVasText(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = function (evt) {
            const text = evt.target.result;
            const data = parseCSVToArray(text);
            resolve(data);
          };
          reader.readAsText(file, 'UTF-8');
          reader.onerror = reject;
        });
      },
      replaceHeadersById() {
        const final = [];
        this.csvData.shift();
        this.csvData.forEach((row, indexRow) => {
          const res = { id: window.uuid() };
          this.csvHeaders.forEach(({ id }, index) => {
            res[id] = this.csvData[indexRow][index];
          });
          final.push(res);
        });
        this.csvData = final;
      },
      defaultCsvHeaders() {
        if (! this.csvData.length) return [];
        const headers = this.csvData[0];

        return headers.map((header) => { // Is Bubble Plan or others projects
          let mapping = '';

          const slugHeader = window.slugify(header);
          if (slugHeader === 'ligne') mapping = 'lane';
          if (slugHeader === 'titre') mapping = 'title';
          if (slugHeader === 'du') mapping = 'starttime';
          if (slugHeader === 'au') mapping = 'endtime';
          if (slugHeader === 'debut') mapping = 'schedulestart';
          if (slugHeader === 'fin') mapping = 'scheduleend';
          if (slugHeader === 'description') mapping = 'html';
          if (slugHeader === 'avancement') mapping = 'progress';
          if (slugHeader === 'actions') mapping = 'checklist';
          // if (slugHeader === 'budget') mapping = 'budgets';
          if (slugHeader === 'intervenants') mapping = 'users';
          if (slugHeader === 'pieces-jointes') mapping = 'links';

          return ({ id: window.uuid(), name: header, mapping });
        });
      },
      resetImportCsv() {
        this.csvData = [];
        this.fileLoaded = false;
      },
      importRows() {
        const elementsToAdd = [];
        const newLanesPromisesByLabel = {};
        this.$refs.csvRows.forEach((childComponent) => {
          const elData = childComponent.getDataDest();
          const isMilestone = elData.starttime && ! elData.endtime;
          if (isMilestone) elData.endtime = elData.starttime;
          elData.type = isMilestone ? 'milestone' : 'task';

          const laneLabel = elData.lane;
          delete elData.lane;

          const lane = this.planning.lanes.find(planningLane => planningLane.label === laneLabel); // find lane by label
          if (lane) { // lane already exists in planning
            const el = new PlanningElement(this.planning, elData);
            el.setLaneId(lane.id);
            elementsToAdd.push({ type: el.getType(), el, lane });
            return;
          }

          let lanePromise = newLanesPromisesByLabel[laneLabel];
          if (! lanePromise) {
            newLanesPromisesByLabel[laneLabel] = this.$store.dispatch('planning/lanes/addLane', new PlanningLane(this.planning, { label: laneLabel }));
            lanePromise = newLanesPromisesByLabel[laneLabel];
          }
          lanePromise.then((addedLane) => {
            const el = new PlanningElement(this.planning, elData);
            el.setLaneId(addedLane.id);
            elementsToAdd.push({ type: el.getType(), el, lane: addedLane });
          });
        });

        Promise.all(Object.values(newLanesPromisesByLabel)).then(() => {
          this.$store.dispatch('planning/elements/addElements', { argstab: elementsToAdd });
          this.$emit('close');
          this.resetImportCsv();
        });
      },
      // addEmptyRow() {
      //   const emptyRow = this.csvHeaders.reduce((obj, header) => {
      //     obj[header.id] = '';
      //     return obj;
      //   }, {});
      //   this.csvData.push(emptyRow);
      // },
      removeRow(id) {
        const index = this.csvData.findIndex(row => row.id === id);
        if (index > -1) this.csvData.splice(index, 1);
      },
    },
  };
</script>
