<template>
  <div class="timeline-grid-container" :style="{ top: isKanbanMode ? '' : '-8px' }" style="pointer-events: none">
    <!-- Time Grid -->
    <template v-if="showTimeGrid">
      <div class="timeline-grid-container">
        <div v-for="col in isKanbanMode ? processSteps : visibleTimeline.timelinecols" :style="{ width: `${col.width}px` }"
             class="timeline-grid-block" style="border-color: rgba(51, 51, 51, .25)">
        </div>
      </div>
      <div v-if="! isKanbanMode" class="timeline-grid-container">
        <div v-for="subcol in visibleTimeline.subtimelinecols" :style="{ width: `${subcol.width}px` }"
             class="timeline-grid-block">
        </div>
      </div>
    </template>

    <!-- TIME BAR / VERTICAL MARKER -->
    <div v-for="timeBarBlock in planningElementsTimeBarBlocks"
         :class="[`el-color-${timeBarBlock.colorId}- border-el-color`, { 'timebar-block-task': timeBarBlock.isMacroOrTask }]"
         :style="{ left: timeBarBlock.xposition, width: timeBarBlock.width }"
         class="timebar-block">
      <div v-if="timeBarBlock.width" class="bg-el-color"></div>
    </div>

    <!-- Present Time & Target End Time -->
    <teleport v-if="isMounted && showPresentTime" to="#table-header">
      <div :style="{ left: `${visibleTimeline.presenttimeWidth - 0.75}px` }" class="presenttime">
        <i class="fas fa-caret-down"></i>
      </div>
    </teleport>
    <div v-if="showPresentTime" :style="{ left: `${visibleTimeline.presenttimeWidth - 1.75}px` }" class="presenttime-line"></div>

    <teleport v-if="isMounted && targetEndtimeWidth" to="#table-header">
      <div :style="{ left: `${targetEndtimeWidth - 0.75}px` }" class="presenttime text-warningorange">
        <i class="fas fa-caret-down"></i>
      </div>
    </teleport>
    <div v-if="targetEndtimeWidth" :style="{ left: `${targetEndtimeWidth - 1.75}px` }" class="presenttime-line" style="border-color: rgba(var(--v-theme-warningorange), .4)"></div>
  </div>
</template>

<style lang="scss">
  /* Time Grid */
  .timeline-grid-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .timeline-grid-block {
      display: inline-block;
      height: 100%;
      border-right: 1px solid rgba(51, 51, 51, .1);
    }
  }

  /* Time Bar */
  .timebar-block {
    position: absolute;
    top: 0;
    bottom: 0;
    border-right: 2px solid;
    margin-left: -2.75px;
    opacity: .5;

    &.timebar-block-task {
      border-left: 1px solid;
      border-right: 1px solid;
      margin-left: -1px;

      > div {
        width: calc(100% + 2px);
        height: 100%;
        opacity: .3;
        margin-left: -1px;
      }
    }
  }

  /* Present Time */
  .presenttime {
    position: absolute;
    transform: translate(-50%, -26px);
    color: rgba(var(--v-theme-accent), .8);
    text-shadow: 0px 0px 1px currentColor;
    z-index: 1;
    font-size: 32px;
    pointer-events: none;
  }

  .presenttime-line {
    top: 4px;
    bottom: 0;
    position: absolute;
    border-left: 1px solid rgba(var(--v-theme-accent), .4);
    border-right: 1px solid rgba(var(--v-theme-accent), .4);
  }
</style>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        isMounted: false,
      };
    },
    computed: {
      isKanbanMode() {
        return this.$store.state.ui.planning.mode == 'kanban';
      },
      processSteps() {
        return this.planning.process.steps.concat(this.$store.getters['planning/process/subprojectsProcessSteps']);
      },
      visibleTimeline() {
        return this.planning.visibleTimeline;
      },
      showTimeGrid() {
        return this.isKanbanMode || this.visibleTimeline.show_timegrid;
      },
      planningElementsTimeBarBlocks() {
        if (this.isKanbanMode) return [];
        const timeBars = [];
        this.planning.elements.forEach((el) => {
          if (! el.isVisibleOnPlanning()) return;
          const { colorId } = el.getTimeBar();
          if (typeof colorId != 'number') return;
          const isMacroOrTask = el.isType('macro', 'task');
          const width = isMacroOrTask ? `${el.getWidth() + 1}px` : null;
          const xposition = `${el.xposition + (! isMacroOrTask ? (el.getWidth() / 2) : 0)}px`;
          timeBars.push({ colorId, xposition, width, isMacroOrTask });
        });
        return timeBars;
      },
      showPresentTime() {
        if (this.isKanbanMode) return false;
        return this.visibleTimeline.show_presenttime && this.visibleTimeline.presenttimeWidth > 0 && this.visibleTimeline.presenttimeWidth <= this.visibleTimeline.pxwidth;
      },
      targetEndtimeWidth() {
        if (this.isKanbanMode) return 0;
        const targetEndtime = this.planning.projectsheet?.target_endtime ? moment(this.planning.projectsheet.target_endtime).endOf('day') : null;
        if (! targetEndtime) return 0;
        const targetEndtimeWidth = Math.round(targetEndtime.diffWithWorkdays(this.visibleTimeline.starttime, 'minutes', this.visibleTimeline.workdays) / this.visibleTimeline.minuteperpx);
        if (targetEndtimeWidth < 0 || targetEndtimeWidth > this.visibleTimeline.pxwidth) return 0;
        return targetEndtimeWidth;
      },
    },
    mounted() {
      this.isMounted = true;
    },
  };
</script>
