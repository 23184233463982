<template>
  <v-menu>
    <template #activator="{ props: onMenu }">
      <v-tooltip location="bottom">
        <template #activator="{ props: onTooltip }">
          <div>
            <v-btn-icon :disabled="exporting.inprogress" size="small" v-bind="mergeProps(onMenu, onTooltip)">
              <v-icon>{{ exporting.inprogress ? 'far fa-spinner fa-spin' : 'far fa-file-export' }}</v-icon>
            </v-btn-icon>
          </div>
        </template>
        <span>{{ $t('NAV.EXPORT') }}</span>
      </v-tooltip>
    </template>

    <v-list density="compact">
      <v-list-item v-if="csv" class="px-3" @click="csvExport()">
        <div class="px-1 d-flex align-center">
          <v-icon size="small" class="mr-2">far fa-file-excel</v-icon>
          {{ $t('NAV.EXPORT') }} CSV
        </div>
      </v-list-item>
      <v-list-item v-if="pdf" class="px-3" @click="! exporting.inprogress && pdfExport()">
        <div class="px-1 d-flex align-center">
          <v-icon size="small" class="mr-2">far fa-file-pdf</v-icon>
          {{ $t('NAV.EXPORT') }} PDF
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { mergeProps } from 'vue';

  export default {
    props: {
      exporting: { type: Object, required: true },
      csv: { type: Boolean, default: false },
      pdf: { type: Boolean, default: false },
    },
    emits: ['csv-export', 'pdf-export'],
    methods: {
      csvExport() {
        this.$emit('csv-export');
      },
      pdfExport() {
        this.$emit('pdf-export');
      },
      mergeProps,
    },
  };
</script>
