<template>
  <div class="element-details-card-header">
    <element-details-config-button :el="el" config="show-links"></element-details-config-button>
  </div>
  <!-- LINKS -->
  <div v-if="! isConsult">
    <div v-for="(link, linkIndex) in links" class="link-item d-flex align-center">
      <span class="text-ellipsis">
        <link-icon :link="link" class="mr-1"></link-icon>
        <a :href="subdomainLinkUrl(link.url)" target="_blank" rel="noopener">
          {{ link.name || link.url }}
        </a>

        <v-menu v-if="showPicturePreviewInED && isPicture(link)" content-class="ml-2 rounded-sm" open-on-hover location="right" offset="8" transition="scale-transition" activator="parent">
          <div class="bg-surface" style="max-width: 200px">
            <img v-if="link.url" :src="link.url" :alt="$t('PLANNING.LINK_IMAGE_UNAVAILABLE')" style="width: 100%;">
          </div>
        </v-menu>
      </span>
      <v-btn-icon size="small" class="link-menu ml-auto" @click="editLinkName(link)">
        <v-icon>far fa-pencil-alt</v-icon>
        <v-tooltip location="bottom" activator="parent">{{ $t('GLOBAL.RENAME') }}</v-tooltip>
      </v-btn-icon>
      <v-btn-icon size="small" color="errorred" class="link-menu ml-1" @click="removeLink(linkIndex)">
        <v-icon>far fa-trash-alt</v-icon>
        <v-tooltip location="bottom" activator="parent">{{ $t('ELEMENT-DETAIL.REMOVE_LINK') }}</v-tooltip>
      </v-btn-icon>
    </div>
    <div v-if="links.length" class="pt-4"></div>
    <!-- FILE DROP ZONE -->
    <file-upload v-if="isPremium && ! linksConfig.hideFileUpload" ref="fileUpload" input-id="file-element-details" @uploaded="fileUploaded"></file-upload>
    <div v-if="! isPremium" class="file-drop-box">
      {{ $t('ELEMENT-DETAIL.DROP_FILES_HERE') }}
      <div><a :href="$t('PREMIUM.OFFERS_LINK')" target="_blank" rel="noopener"><b>{{ $t('PREMIUM.PREMIUM_FEATURE') }}</b></a></div>
    </div>
    <!-- ADD LINK FORM -->
    <form class="d-flex align-center" @submit.prevent="addLink()">
      <v-text-field v-model="linkurl" :label="$t('ELEMENT-DETAIL.ADD_LINK')" placeholder="http://..."></v-text-field>
      <v-btn :disabled="! linkurl" color="primary" rounded type="submit" class="ml-2">{{ $t('ELEMENT-DETAIL.ADD') }}</v-btn>
    </form>
    <div class="d-flex align-center mt-4">
      <!-- INTEGRATIONS BUTTONS -->
      <v-btn v-if="! linksConfig.hideOneDrive" class="mx-2" style="flex: 0 1 30%" @click="chooseFromOnedrive">
        <i class="fas fa-cloud mr-2"></i>OneDrive
      </v-btn>
      <v-btn v-if="! linksConfig.hideGoogleDrive" class="mx-2" style="flex: 0 1 30%" @click="chooseFromGoogledrive">
        <i class="fab fa-google-drive mr-2"></i>Google Drive
      </v-btn>
      <v-btn v-if="! linksConfig.hideDropbox" class="mx-2" style="flex: 0 1 30%" @click="chooseFromDropbox">
        <i class="fab fa-dropbox mr-2"></i>Dropbox
      </v-btn>
    </div>
  </div>
  <div v-else class="mb-1">
    <div v-for="link in links" class="link-item d-flex align-center">
      <link-icon :link="link" class="mr-1"></link-icon>
      <a :href="subdomainLinkUrl(link.url)" class="text-ellipsis" target="_blank" rel="noopener">
        {{ link.name || link.url }}
      </a>
    </div>
  </div>
</template>

<style>
  .link-menu {
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
  }
  .link-item:hover .link-menu {
    visibility: visible;
    opacity: 1;
  }

  .file-drop-box {
    background: #f9f9f9;
    border: 5px dashed #ddd;
    text-align: center;
    padding: 20px 0;
    margin-bottom: 10px;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import MicrosoftSrv from '@/components/Integrations/MicrosoftSrv';
  import GoogleSrv from '@/components/Integrations/GoogleSrv';
  import DropboxSrv from '@/components/Integrations/DropboxSrv';
  import FileUpload from '@/components/Reusables/FileUpload.vue';
  import LinkIcon from '@/components/Planning/partials/LinkIcon.vue';
  import ElementDetailsConfigButton from './partials/ElementDetailsConfigButton.vue';

  export default {
    components: {
      FileUpload,
      LinkIcon,
      ElementDetailsConfigButton,
    },
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        linkurl: "",
        showPicturePreviewInED: clientConfig.elements?.showPicturePreviewInED || false,
      };
    },
    computed: {
      isConsult() { return this.el.isType('macro'); },
      links() { return this.el.getLinks() || []; },
      linksConfig() {
        return this.$store.getters['users/accessRight/config'].links;
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    methods: {
      removeLink(index) {
        if (index > -1 && index < this.links.length) {
          this.links.splice(index, 1);
          this.el.setLinks(this.links);
        }
      },
      addLink(files) {
        // Files from dropbox or onedrive. Files empty = url
        if (files) {
          files.forEach((file) => {
            this.links.push(file);
          });
        } else {
          if (! this.linkurl) return;
          let name = this.linkurl.replace(/\/+$/, ''); // strip ending slashes
          name = name.substr(name.lastIndexOf('/') + 1);
          this.links.push({ type: 'link', url: this.linkurl, name });
          this.linkurl = "";
        }
        this.el.setLinks(this.links);
      },
      editLinkName(link) {
        const newName = window.prompt(this.$t('ELEMENT-DETAIL.LINK_NAME'), link.name);
        if (! newName) return;
        link.name = newName;
      },
      fileUploaded(newFile) {
        if (! (newFile && newFile.response && newFile.response.file)) return;
        const { file } = newFile.response;
        this.links.push({ type: 'file', url: file.url, name: file.display_filename, mime: file.mime });
        this.el.setLinks(this.links);
      },
      chooseFromOnedrive() {
        MicrosoftSrv.onedrive.loadPicker((files) => {
          this.addLink(files);
        });
      },
      chooseFromGoogledrive() {
        GoogleSrv.drive.loadPicker((files) => {
          this.addLink(files);
        });
      },
      chooseFromDropbox() {
        DropboxSrv.dropbox.loadPicker((files) => {
          this.addLink(files);
        });
      },
      isPicture(link) {
        if (link.mime && link.mime.includes('image')) return true;
        return /(http)s?:(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg)$)/i.test(link.url);
      },
      subdomainLinkUrl(url) {
        return clientConfig.subdomain && url?.startsWith('https://api.bubbleplan.net') ? `${url}?subdomain=${clientConfig.subdomain}` : url;
      },
    },
  };
</script>
