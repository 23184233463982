<template>
  <div>
    <div class="d-flex align-center mb-2">
      <v-icon class="mr-2" style="flex: 0 0 24px">far fa-align-justify</v-icon>
      <b>{{ $t('PROJECT_SHEET.DESCRIPTION') }}</b>
      <template v-if="userIsProjectAdmin && ! descriptionEditing">
        <v-btn-icon size="small" class="ml-auto export-hidden" @click="descriptionEditing = ! descriptionEditing">
          <v-icon>far fa-pencil-alt</v-icon>
        </v-btn-icon>
      </template>
    </div>
    <v-card class="pa-6">
      <div v-if="descriptionEditing">
        <quill v-model:content="description" :default-content="defaultDescription" :config="{ height: '150px' }"></quill>
        <div class="text-center mt-2">
          <v-btn rounded variant="outlined" class="mx-2" @click="resetDescription">{{ $t('GLOBAL.CANCEL') }}</v-btn>
          <v-btn color="primary" rounded class="mx-2" @click="saveDescription">{{ $t('GLOBAL.SAVE') }}</v-btn>
        </div>
      </div>
      <div v-else class="quill-html" v-html="description"></div>
      <div class="mt-4"></div>
      <div v-if="userIsProjectAdmin || targetEndtime || targetBudget">
        <v-divider class="my-2"></v-divider>
        <div class="d-flex align-center">
          <div v-if="userIsProjectAdmin || targetEndtime" :class="{ 'export-hidden': ! targetEndtime }" class="d-flex align-center flex-grow-1">
            <v-icon class="mr-2" style="width: 12px; height: 32px;">far fa-hourglass-end</v-icon>
            <template v-if="targetEndtimeEditing">
              <date-field v-model="targetEndtime" input-class="pa-0 ma-0" clearable></date-field>
              <v-btn color="primary" rounded size="small" class="mx-2" @click="saveTarget('targetEndtime')">{{ $t('GLOBAL.SAVE') }}</v-btn>
              <v-btn rounded size="small" variant="outlined" class="mr-2" style="min-width: 0" @click="resetTarget('targetEndtime')"><v-icon>far fa-times</v-icon></v-btn>
            </template>
            <template v-else-if="targetEndtime">
              <div>{{ $t('PROJECT_SHEET.TARGET_ENDTIME') }} : <span class="nobr">{{ $filters.moment(targetEndtime, 'date') }}</span></div>
              <v-btn v-if="userIsProjectAdmin" icon size="small" class="ml-2 export-hidden" @click="targetEndtimeEditing = ! targetEndtimeEditing">
                <v-icon>far fa-pencil-alt</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn rounded variant="outlined" @click="targetEndtimeEditing = true">
                <v-icon class="mr-1">far fa-plus</v-icon>{{ $t('PROJECT_SHEET.TARGET_ENDTIME') }}
              </v-btn>
            </template>
          </div>
          <div v-if="userIsProjectAdmin || (targetEndtime && targetBudget)" class="mx-2"></div>
          <div v-if="userIsProjectAdmin || targetBudget" :class="{ 'export-hidden': ! targetBudget }" class="d-flex align-center flex-grow-1">
            <v-icon class="mr-2" style="width: 12px; height: 32px;">far fa-euro-sign</v-icon>
            <template v-if="targetBudgetEditing">
              <v-text-field v-model.number="targetBudget" type="number" class="pa-0 ma-0" style="max-width: 110px"></v-text-field>
              <v-btn color="primary" rounded size="small" class="mx-2" @click="saveTarget('targetBudget')">{{ $t('GLOBAL.SAVE') }}</v-btn>
              <v-btn rounded size="small" variant="outlined" class="mr-2" style="min-width: 0" @click="resetTarget('targetBudget')"><v-icon>far fa-times</v-icon></v-btn>
            </template>
            <template v-else-if="targetBudget">
              <div>{{ $t('PROJECT_SHEET.TARGET_BUDGET') }} : <span class="nobr">{{ $filters.number(targetBudget) }}</span></div>
              <v-btn v-if="userIsProjectAdmin" icon size="small" class="ml-2 export-hidden" @click="targetBudgetEditing = ! targetBudgetEditing">
                <v-icon>far fa-pencil-alt</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn rounded variant="outlined" @click="targetBudgetEditing = true">
                <v-icon class="mr-1">far fa-plus</v-icon>{{ $t('PROJECT_SHEET.TARGET_BUDGET') }}
              </v-btn>
            </template>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import Quill from '@/components/Reusables/Quill';

  export default {
    components: {
      Quill,
    },
    props: {
      planning: { type: Object, required: true },
    },
    emits: ['update'],
    data() {
      const projectsheet = this.planning.projectsheet || {};
      const userIsProjectAdmin = this.planning.meta.access_right == 'admin';
      return {
        userIsProjectAdmin,
        description: projectsheet.description || "",
        descriptionEditing: false,
        defaultDescription: clientConfig.projectsheet?.defaultDescription || "",
        targetBudget: projectsheet.target_budget || '',
        targetBudgetEditing: false,
        targetEndtime: projectsheet.target_endtime && moment(projectsheet.target_endtime) || null,
        targetEndtimeEditing: false,
      };
    },
    methods: {
      resetDescription() {
        const projectsheet = this.planning.projectsheet || {};
        this.description = projectsheet.description || "";
        this.descriptionEditing = false;
      },
      saveDescription() {
        this.$emit('update', {
          description: this.description,
        });
        this.descriptionEditing = false;
      },
      resetTarget(targetName) {
        const projectsheet = this.planning.projectsheet || {};
        if (targetName == 'targetBudget') {
          this.targetBudget = projectsheet.target_budget || '';
          this.targetBudgetEditing = false;
        }
        if (targetName == 'targetEndtime') {
          this.targetEndtime = projectsheet.target_endtime || null;
          this.targetEndtimeEditing = false;
        }
      },
      saveTarget(targetName) {
        if (targetName == 'targetBudget') {
          this.$emit('update', { target_budget: this.targetBudget });
          this.targetBudgetEditing = false;
        }
        if (targetName == 'targetEndtime') {
          this.$emit('update', { target_endtime: this.targetEndtime && this.targetEndtime.format() });
          this.targetEndtimeEditing = false;
        }
      },
    },
  };
</script>
