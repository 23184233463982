<template>
  <div>
    <v-progress-linear :model-value="elProgress" color="successgreen" height="14" class="rounded-t-0"
                       @click.stop="el.isType('task') ? $emit('progress-click', $event) : ''">
      <div :style="{ 'margin-right': `${100 - elProgress}%` }" class="text-white small progress-label">{{ elProgress }}%</div>
    </v-progress-linear>
  </div>
</template>

<style>
  .element .progress-label {
    line-height: 1;
    transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
</style>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
    },
    emits: ['progress-click'],
    computed: {
      elProgress() {
        return this.el.getProgress();
      },
    },
  };
</script>
