<template>
  <div :title="$t('NAV.NAVIGATE_TIMELINE')" class="timeline-scrollbar">
    <div :class="{ highlighted: alwaysHighlighted || isHighlighted }" class="scrollable-background">
      <div class="scrollable-container">
        <div class="scrollable-content" style="height: 1px"></div>
      </div>
    </div>
  </div>
</template>

<style>
  .timeline-scrollbar {
    position: relative;
    height: 8px;
    z-index: 1;
  }

  .scrollable-background {
    will-change: height, opacity;
    height: 8px;
    background-color: #eee;
    opacity: .6;
    cursor: pointer;
    transition: opacity .2s ease-in-out, height .2s ease-in-out;
  }

  .timeline-scrollbar .scrollable-container {
    will-change: transform, height;
    height: 100%;
    transform-origin: 0 0;
    transform-style: flat;
  }
  #table-header:hover .scrollable-background,
  .scrollable-background.highlighted {
    opacity: .9;
    height: 14px;
  }

  .timeline-scrollbar .simplebar-track, .timeline-scrollbar .simplebar-scrollbar {
    height: 100% !important;
    top: 0 !important;
  }
  .simplebar-scrollbar::before {
    background-image: linear-gradient(131deg, #0169C1 0%, #7db9e8 100%);
    top: 0;
    bottom: 0;
  }
</style>

<script>
  import SimpleBar from 'simplebar';
  import 'simplebar/dist/simplebar.css';
  import clientConfig from '@/client_customs/config';

  export default {
    props: {
      visibleTimeline: { type: Object, required: true },
    },
    data() {
      return {
        nodes: {},
        simpleBar: null,
        isHighlighted: false,
        alwaysHighlighted: clientConfig.timeline?.alwaysHighlighted,
      };
    },
    watch: {
      'visibleTimeline.pxwidth': function () {
        setTimeout(() => { this.simpleBar.positionScrollbar(); this.simpleBar.recalculate(); });
      },
    },
    mounted() {
      const container = document.querySelector('.scrollable-container');
      const tableWrapper = document.getElementById('table-wrapper');
      const planningTable = document.getElementById('planning-table');
      const scrollableContent = document.querySelector('.scrollable-content');

      this.simpleBar = new SimpleBar(container, {
        autoHide: false,
        scrollableNode: tableWrapper, // used on simpleBar.init second call
      });

      /* Hack simpleBar internal functions */
      this.simpleBar.originalEvents = {
        onDragStart: this.simpleBar.onDragStart,
        onEndDrag: this.simpleBar.onEndDrag,

      };
      this.simpleBar.onDragStart = (...args) => { // add isHighlighted handling to instance functions
        this.isHighlighted = true;
        this.simpleBar.originalEvents.onDragStart.call(this.simpleBar, ...args);
      };
      this.simpleBar.onEndDrag = (...args) => {
        this.isHighlighted = false;
        this.simpleBar.originalEvents.onEndDrag.call(this.simpleBar, ...args);
      };

      /* replace simpleBar scrollElement by tableWrapper */
      const simpleBarScrollElement = this.simpleBar.getScrollElement(); // document.querySelector('.simplebar-content-wrapper')
      simpleBarScrollElement.removeEventListener('scroll', this.simpleBar.onScroll);
      tableWrapper.addEventListener('scroll', this.simpleBar.onScroll);
      this.simpleBar.contentWrapperEl = tableWrapper;

      this.simpleBar.originalEvents.positionScrollbar = this.simpleBar.positionScrollbar;
      this.simpleBar.positionScrollbar = (...args) => {
        this.simpleBar.originalEvents.positionScrollbar.call(this.simpleBar, ...args);
        // update timeline container position
        container.style.transform = `translate3d(${tableWrapper.scrollLeft}px, 0, 0)`;
        container.style.width = `${tableWrapper.offsetWidth}px`;
        scrollableContent.style.width = `${planningTable.offsetWidth}px`;
      };

      /* reboot simpleBar with contentWrapperEl (scrollElement) = tableWrapper */
      this.simpleBar.init();
    },
  };
</script>
