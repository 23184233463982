import 'moment/dist/locale/fr.js';
import 'moment/dist/locale/fr-ca.js';
import 'moment/dist/locale/en-gb.js';

moment.updateLocale('fr', {
  months: "Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre".split("_"),
  monthsShort: "Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.".split("_"),
  weekdays: "Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi".split("_"),
  weekdaysShort: "Dim._Lun._Mar._Mer._Jeu._Ven._Sam.".split("_"),
  calendar: {
    sameDay: '[Aujourd\'hui à] LT',
    nextDay: '[Demain à] LT',
    nextWeek: 'dddd [à] LT',
    lastDay: '[Hier à] LT',
    lastWeek: 'dddd [dernier à] LT',
    sameElse: 'LL',
  },
  custom(format) {
    return {
      mediumDateNoYear: "D MMM",
      datetime: "LL [à] LT",
    }[format];
  },
});

moment.updateLocale('fr-ca', {
  months: "Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre".split("_"),
  monthsShort: "Janv._Févr._Mars_Avr._Mai_Juin_Juil._Août_Sept._Oct._Nov._Déc.".split("_"),
  weekdays: "Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi".split("_"),
  weekdaysShort: "Dim._Lun._Mar._Mer._Jeu._Ven._Sam.".split("_"),
  custom(format) {
    return {
      mediumDateNoYear: "MMM D",
      datetime: "LL [à] LT",
    }[format];
  },
});

moment.updateLocale('en', {
  calendar: {
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    nextWeek: 'dddd [at] LT',
    lastDay: '[Yesterday at] LT',
    lastWeek: '[Last] dddd [at] LT',
    sameElse: 'LL',
  },
  custom(format) {
    return {
      mediumDateNoYear: "MMM D",
      datetime: "LL [at] LT",
    }[format];
  },
});

moment.updateLocale('en-gb', {
  custom(format) {
    return {
      mediumDateNoYear: "D MMM",
      datetime: "LL [at] LT",
    }[format];
  },
});

moment.fn.isWorkday = function () {
  const isoWeekday = this.isoWeekday();
  return isoWeekday !== 6 && isoWeekday !== 7;
};

moment.fn.toNextWorkday = function (direction = 1) {
  if (this.isWorkday()) return this;
  do {
    this.add(direction < 0 ? -1 : 1, 'day');
  } while (! this.isWorkday());
  if (direction < 0) {
    this.endOf('day');
  } else {
    this.startOf('day');
  }
  return this;
};

moment.fn.addWithWorkdays = function (quantityParam, unit, useWorkdays) {
  // moment().addWithWorkdays(Number, String unit, Boolean useWorkdays);
  if (useWorkdays && quantityParam && typeof unit == 'string' && moment.duration(1, unit).as('days') <= 1) {
    const quantity = +quantityParam;
    const increment = quantity < 0 ? -1 : 1;
    this.toNextWorkday(increment);

    const days = parseInt(moment.duration(quantity, unit).as('days'), 10);
    if (days) {
      // add workdays
      this.add(Math.floor(Math.abs(days) / 5) * 7 * increment, 'days');
      let remaining = days % 5;
      while (remaining) {
        this.add(increment, 'days');
        if (this.isWorkday()) remaining -= increment;
      }
    }

    const remaining = quantity % moment.duration(1, 'day').as(unit);
    this.add(remaining, unit);
    while (! this.isWorkday() && quantity) this.add(quantity < 0 ? -1 : 1, 'day');
    return this;
  }
  return this.add(quantityParam, unit);
};

function countOffdays(start, end) {
  // not tested when end < start
  const days = end.diff(start, 'days');
  const completeweeks = Math.floor(Math.abs(days) / 7);
  let workdays = completeweeks * 5;
  const mydate = moment(start).add(completeweeks, 'weeks');
  if (! mydate.isValid()) return days - workdays;
  while (! end.isBefore(mydate.add(1, 'day'))) {
    if (mydate.isWorkday()) workdays += 1;
  }
  return days - workdays;
}

moment.fn.diffWithWorkdays = function (startParam, unit, useWorkdays) {
  // moment().diffWithWorkdays(Moment or date String, String unit, Boolean useWorkdays);
  if (useWorkdays && typeof unit == 'string') {
    const start = moment(startParam).toNextWorkday(-1);
    const end = moment(this).toNextWorkday();
    if (end.isAfter(start)) {
      const offdays = countOffdays(start, end);
      const timeZoneShift = end.utcOffset() - start.utcOffset(); // if daylight changing in the interval, 1 offday is 23h or 25h (daylight changing is always on sunday)
      return end.diff(start, unit) - moment.duration(offdays, 'days').as(unit) + Math.round(moment.duration(timeZoneShift, 'minutes').as(unit));
    }
  }
  return this.diff(startParam, unit);
};
