<template>
  <tr :class="{ 'portfolio-lane': isLane }">
    <td v-for="col in filteredHeaders" :key="col.key" :class="[col.key == 'expand' ? 'px-0' : 'px-2', `portfolio-col-${col.key}`]"
        :colspan="isLane && col.key == 'title' ? laneTitleColspan : 1">
      <template v-if="! isLane && col.key == 'expand'">
        <v-btn-icon v-if="! rowData.archived_at && ! isLane" :title="isExpanded ? $t('PORTFOLIO.HIDE_LANES') : $t('PORTFOLIO.SHOW_LANES')" size="small" class="export-hidden"
                    @click="$emit('expand')">
          <v-icon>fas {{ isExpanded ? 'fa-caret-down' : 'fa-caret-right' }}</v-icon>
        </v-btn-icon>
      </template>
      <template v-if="! isLane && col.key == 'mood'">
        <v-menu v-if="rowData.moodIcon" :disabled="! hasContent(rowData.waypoint)" :close-on-content-click="false" open-on-hover location="right">
          <template #activator="{ props }">
            <div class="bg-white pointer" style="width: 40px; height: 40px; box-shadow: 0 0px 4px 2px #ccc; border-radius: 50%; margin: auto"
                 v-bind="props" @click="openedProjectSheetProject = rowData">
              <div style="width: 40px; height: 40px; padding: 0px 2px 2px"><div :class="`mood-icon-${rowData.moodIcon}`"></div></div>
            </div>
          </template>
          <v-card class="pa-2" style="max-width: 300px">
            <div v-html="rowData.waypoint"></div>
          </v-card>
        </v-menu>
        <div v-else class="hovervisible-activator">
          <div class="hovervisible-item bg-white text-center pointer" style="width: 40px; height: 40px; box-shadow: 0 0px 4px 2px #ccc; border-radius: 50%; margin: auto"
               @click="openedProjectSheetProject = rowData">
            <div class="d-flex align-center justify-center" style="width: 40px; height: 40px; font-size: 75%; line-height: 1.3">{{ $t('PROJECT_SHEET.PROJECT_SHEET') }}</div>
          </div>
        </div>
      </template>
      <template v-else-if="col.key == 'category'">
        <project-category v-if="rowData.category" :category="rowData.category" :slider="rowData.category.includes(',')" class="my-1" style="max-width: 100px"></project-category>
      </template>
      <template v-else-if="col.key == 'title'">
        <div v-if="! isLane" class="text-subtitle-1 d-flex align-center" style="height: 100%; line-height: normal">
          <router-link v-if="! rowData.archived_at" :to="projectLink">{{ rowData.title }}</router-link>
          <span v-else>{{ rowData.title }}</span>
        </div>
        <div v-else class="ml-8">{{ rowData.title }}</div>
      </template>
      <template v-else-if="! isLane && col.key == 'owner'">
        <div class="d-flex align-center">
          <v-tooltip location="left">
            <template #activator="{ props }">
              <user-avatar v-if="rowData.ownerData" :user="rowData.ownerData" v-bind="props"></user-avatar>
            </template>
            <span>{{ $filters.username(rowData.ownerData) }}</span>
          </v-tooltip>
          <i v-if="rowData.company" class="ml-2" style="line-height: 1">{{ rowData.company }}</i>
          <span v-else class="ml-2" style="line-height: 1">{{ ownerUsername }}</span>
        </div>
      </template>
      <template v-else-if="! isLane && col.key == 'status'">
        <div v-if="rowData.archived_at" class="d-flex align-center">
          <v-icon :title="$t('PORTFOLIO.ARCHIVED')">far fa-archive</v-icon>&nbsp; {{ $filters.moment(rowData.archived_at, 'L') }}
        </div>
        <div v-else class="d-flex align-center">
          <v-icon v-if="isActive" :title="$t('PORTFOLIO.RECENT_MODIFICATION')" color="successgreen">fas fa-circle</v-icon>
          <v-icon v-else :title="$t('PORTFOLIO.NO_RECENT_MODIFICATION')" color="warningorange">fas fa-circle</v-icon>
          <span class="ml-2 nobr">{{ $filters.moment(rowData.updated_at, 'L') }}</span>
        </div>
      </template>
      <template v-else-if="col.key == 'dates'">
        <div v-if="rowData.duration" class="nobr">{{ rowData.duration.humanize() }}</div>
      </template>
      <template v-else-if="col.key == 'endtime'">
        <div v-if="rowData.datesData.end" class="nobr">{{ $filters.moment(rowData.datesData.end, 'L') }}</div>
        <div v-if="rowData.endtimeTarget" :class="endtimeTargetClass" class="nobr rounded px-2">{{ $t('PORTFOLIO.TARGET') }} : {{ $filters.moment(rowData.endtimeTarget, 'L') }}</div>
      </template>
      <template v-else-if="col.key == 'actions'">
        <template v-if="rowData.actionsData.total">
          <div class="d-flex rounded overflow-hidden">
            <div v-for="(actionStat, id) in actionStats" :key="id" :class="actionStat.class" :style="{ width: `${actionStat.percent}%` }"
                 class="text-center" style="flex: 1 1 auto; height: 20px; justify-content: center; display: flex; flex-direction: column; min-width: 22px">
              <small>{{ $filters.number(actionStat.percent) }}%</small>
            </div>
          </div>
          <div class="small nobr text-center">/ {{ $t('GLOBAL.ACTIONS_COUNT', rowData.actionsData.total) }}</div>
        </template>
      </template>
      <template v-else-if="col.key == 'workload'">
        <template v-if="rowData.workloadData.estimated">
          <div class="d-flex rounded overflow-hidden">
            <div v-for="(workloadStat, id) in workloadStats" :key="id" :class="workloadStat.class" :style="{ width: `${workloadStat.percent}%` }"
                 class="text-center" style="flex: 1 1 auto; height: 20px; justify-content: center; display: flex; flex-direction: column; min-width: 22px">
              <small>{{ $filters.number(workloadStat.percent) }}%</small>
            </div>
          </div>
          <div class="small nobr text-center">/ {{ $t(workloadInHours ? 'GLOBAL.HOURS_COUNT' : 'GLOBAL.DAYS_COUNT', Math.round(rowData.workloadData.estimated)) }}</div>
        </template>
      </template>
      <template v-else-if="col.key == 'progress'">
        <template v-if="rowData.progressData.total">
          <div class="d-flex rounded overflow-hidden">
            <div v-for="(progressStat, id) in progressStats" :key="id" :class="progressStat.class" :style="{ width: `${progressStat.percent}%` }"
                 class="text-center" style="flex: 1 1 auto; height: 20px; justify-content: center; display: flex; flex-direction: column; min-width: 22px">
              <small>{{ $filters.number(progressStat.percent) }}%</small>
            </div>
          </div>
          <div class="small nobr text-center">/ {{ $t('GLOBAL.BUBBLES_COUNT', rowData.progressData.total) }}</div>
        </template>
      </template>
      <template v-else-if="col.key == 'users'">
        <div class="d-flex flex-wrap py-1">
          <div v-for="user in projectUsers" class="mr-1">
            <v-tooltip location="left">
              <template #activator="{ props }">
                <user-avatar :user="user" v-bind="props"></user-avatar>
              </template>
              <span>{{ $filters.username(user) }}</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-else-if="col.key == 'budget'">
        <div v-for="(budget, icon) in rowData.budgetData" :key="icon" class="nobr text-ellipsis">
          <span :class="`budget-${icon}`" class="mr-1"></span>
          <span v-if="budget.amount_inprogress && budget.amount">{{ $filters.number(budget.amount_inprogress) }}&nbsp;/</span>
          <span v-if="budget.amount_inprogress && ! budget.amount">{{ $filters.number(budget.amount_inprogress) }}</span>
          <span v-if="budget.amount">{{ $filters.number(budget.amount) }}</span>
        </div>
        <div v-if="rowData.budgetTarget" :class="budgetTargetClass" class="nobr rounded px-2">{{ $t('PORTFOLIO.TARGET') }} : {{ $filters.number(rowData.budgetTarget) }}</div>
      </template>
    </td>
    <modal-project-sheet v-if="openedProjectSheetProject" :project="openedProjectSheetProject" @close="openedProjectSheetProject = null">
    </modal-project-sheet>
  </tr>
</template>

<style>
  .portfolio-lane .portfolio-col-title + .portfolio-col-owner,
  .portfolio-lane .portfolio-col-title + .portfolio-col-status,
  .portfolio-lane .portfolio-col-title + .portfolio-col-owner + .portfolio-col-status {
    display: none;
  }
</style>

<script>
  import ProjectCategory from '@/components/Projects/ProjectCategory';
  import ModalProjectSheet from '@/components/ProjectSheet/ModalProjectSheet';

  export default {
    components: {
      ProjectCategory,
      ModalProjectSheet,
    },
    props: {
      filteredHeaders: { type: Array, required: true },
      rowData: { type: Object, required: true },
      isExpanded: { type: Boolean, default: false },
      isLane: { type: Boolean, default: false },
    },
    emits: ['expand'],
    data() {
      return {
        openedProjectSheetProject: null,
      };
    },
    computed: {
      laneTitleColspan() {
        const headersVals = this.filteredHeaders.map(col => col.key);
        return 1 + (headersVals.includes('owner') ? 1 : 0) + (headersVals.includes('status') ? 1 : 0);
      },
      ownerUsername() {
        return this.$store.getters['users/getUsername'](this.rowData.ownerData, 'short').replace(' ', '\xa0'); // first whitespace made unsecable
      },
      workloadInHours() {
        return this.$store.getters['users/accessRight/config'].workloadInHours;
      },
      projectLink() {
        if (this.rowData.archived_at) return '';
        const isConsult = this.rowData.access_right == 'consult';
        return `/${(isConsult ? 'viewer/' : 'planning/') + this.rowData.id}/${window.slugify(this.rowData.title || "New project")}`;
      },
      isActive() {
        return this.rowData.updated_at.isAfter(moment().add(-3, 'month'));
      },
      actionStats() {
        const actionStats = {
          finished: { label: 'PROJECT_SHEET.FINISHED', class: 'bg-successgreen' },
          urgent: { label: 'PROJECT_SHEET.URGENT', class: 'bg-warningorange' },
          overdue: { label: 'PROJECT_SHEET.OVERDUE', class: 'bg-errorred' },
          future: { label: 'PROJECT_SHEET.FUTURE', class: 'bg-midgrey' },
        };
        Object.keys(actionStats).forEach((status) => {
          if (this.rowData.actionsData[status]) {
            actionStats[status].number = this.rowData.actionsData[status];
            actionStats[status].percent = Math.round(this.rowData.actionsData[status] / this.rowData.actionsData.total * 100) || 0;
          } else {
            delete actionStats[status];
          }
        });
        return actionStats;
      },
      workloadStats() {
        const { used, estimated } = this.rowData.workloadData;
        const workloadStats = {
          used: { label: 'PROJECT_SHEET.DAYS_USED', class: 'bg-successgreen', number: Math.min(used, estimated) },
          remaining: { label: 'PROJECT_SHEET.DAYS_REMAINING', class: 'bg-midgrey', number: Math.max(0, estimated - used) },
          exceeded: { label: 'PROJECT_SHEET.DAYS_EXCEEDED', class: 'bg-errorred', number: Math.max(0, used - estimated) },
        };
        Object.keys(workloadStats).forEach((status) => {
          if (workloadStats[status].number) {
            workloadStats[status].percent = Math.round(workloadStats[status].number / estimated * 100) || 0;
          } else {
            delete workloadStats[status];
          }
        });
        return workloadStats;
      },
      progressStats() {
        const progressStats = {
          finished: { label: 'PROJECT_SHEET.FINISHED', class: 'bg-successgreen' },
          inprogress: { label: 'PROJECT_SHEET.IN_PROGRESS', class: 'bg-warningorange' },
          overdue: { label: 'PROJECT_SHEET.OVERDUE', class: 'bg-errorred' },
          future: { label: 'PROJECT_SHEET.FUTURE', class: 'bg-midgrey' },
        };
        Object.keys(progressStats).forEach((status) => {
          if (this.rowData.progressData[status]) {
            progressStats[status].number = this.rowData.progressData[status];
            progressStats[status].percent = Math.round(this.rowData.progressData[status] / this.rowData.progressData.total * 100) || 0;
          } else {
            delete progressStats[status];
          }
        });
        return progressStats;
      },
      projectUsers() {
        return Object.keys(this.rowData.usersData).map(userId => this.rowData.usersData[userId]).sort((a, b) => {
          const aUsername = this.$store.getters['users/getUsername'](a);
          const bUsername = this.$store.getters['users/getUsername'](b);
          return (aUsername.toLowerCase() < bUsername.toLowerCase()) ? -1 : 1;
        });
      },
      budgetTargetClass() {
        const { budgetData, budgetTarget } = this.rowData;
        const maxBudget = Object.keys(budgetData).reduce((acc, icon) => Math.max(acc, budgetData[icon].amount_inprogress, budgetData[icon].amount), 0);
        return maxBudget <= budgetTarget ? 'bg-successgreen' : 'bg-errorred';
      },
      endtimeTargetClass() {
        return this.rowData.datesData.end && this.rowData.datesData.end.isAfter(this.rowData.endtimeTarget) ? 'bg-errorred' : 'bg-successgreen';
      },
    },
    methods: {
      hasContent(html) {
        if (! html) return false;
        return !! window.html2text(html || "").trim();
      },
    },
  };
</script>
