import { loadRemoteScript } from './helpers';

const appKey = "hfettco7bu512ue";

function loadPicker(cb) {
  loadRemoteScript('https://www.dropbox.com/static/api/2/dropins.js', 'dropboxjs', { appKey }).then(() => {
    const dropboxNode = window.Dropbox.createChooseButton({
      success(data) {
        const files = [];
        data.forEach((file) => {
          files.push({
            type: "dropbox",
            url: file.link,
            name: file.name,
            icon: file.icon,
          });
        });
        if (cb) cb(files);
      },
      multiselect: true,
    });
    dropboxNode.click();
  });
}

export default {
  dropbox: {
    loadPicker,
  },
};
