export default {
  taskMinWidth: 40,
  milestoneMinWidth: 30,
  milestoneIconHeight: 20,
  miniElementBreakWidth: 60,
  elementTopMargin: 7,
  lanePadding: 7,
  laneLevelWidth: 15,
  laneMinHeight: 50,
  hiddenLaneHeight: 30,
  kanbanDefaultColWidth: 200,
  kanbanXsColWidth: 64,
  kanbanDragHelperWidth: 200 - 16 - 20,
};
