<template>
  <div id="planningdrawer" v-fix-to-element="{ target: '.planning-body', top: 0, right: 0, bottom: 0 }"
       :class="[`tab-${planningDrawerTabIndex || 'closed'}`, { 'elevation-2': planningDrawerIsOpened }]"
       class="rounded-e rounded-ts bg-white d-flex flex-column">
    <div v-if="hasTitle" id="planning-drawer-title" :class="titleColorClass" class="rounded-t flex-center-center">
      <div style="position: absolute; right: 4px; top: 4px; bottom: 0; z-index: 1" class="flex-center-center">
        <v-btn-icon size="small" @click="close"><v-icon size="large">far fa-times</v-icon></v-btn-icon>
      </div>
    </div>

    <div class="d-flex overflow-y-hidden fill-height">
      <div id="planning-drawer-scroll-container-parent" class="fill-height" style="flex: 1 1 100%; min-width: 0">
        <div v-show="planningDrawerIsOpened" id="planning-drawer-scroll-container"
             class="fill-height overflow-y-auto overflow-x-hidden light-scrollbar overscroll-behavior-y-contain">
          <planning-drawer-windows :planning="planning" :options="options" :events="events" :can-modify-planning="canModifyPlanning"></planning-drawer-windows>
        </div>
      </div>
      <planning-drawer-menu :planning="planning" :can-modify-planning="canModifyPlanning" :events="events" :has-title="hasTitle" class="ml-auto" style="flex: 0 0 48px"
                            @open="open" @close="close">
      </planning-drawer-menu>
    </div>
  </div>
</template>

<style lang="scss">
  #planningdrawer {
    z-index: 500;
    width: 510px;
    transition: width 0.4s ease-in-out;

    &.tab-closed {
      width: 48px;
    }

    &:not(.tab-closed) {
      transition: width 0.4s ease-in-out, bottom 0.4s ease-in-out;
      bottom: 0px !important;
    }

    &.tab-element #planning-drawer-title, &.tab-color-picker #planning-drawer-title {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  // the following is also used in Dashboards\partials\DashboardsElementModal.vue and Dashboards\Actions\ActionsDashboardCardItem.vue
  #planningdrawer, .dashboards-element-modal, .action-item {
    .v-input--density-comfortable {
      // compact inputs
      --v-input-control-height: 40px;
      --v-input-padding-top: 8px;

      .v-field--variant-outlined, .v-field--single-line, .v-field--no-label {
        --v-field-padding-bottom: 8px;
        --v-field-padding-start: 12px;
        --v-field-padding-end: 12px;
      }

      .v-field--appended { // clearable fields
        --v-field-padding-end: 0;
        padding-inline-end: 0;
      }
    }
  }

  #planning-drawer-title {
    padding-left: 32px;
    padding-right: 48px;
    flex: 0 0 auto;
    line-height: 1;
    position: relative;
    min-height: 36px;
  }

  #planning-drawer-scroll-container-parent {
    position: relative; // required by element details popup menu (recurrence)
  }
</style>

<script>
  import { mapState } from 'vuex';
  import PlanningDrawerWindows from './PlanningDrawerWindows.vue';
  import PlanningDrawerMenu from './PlanningDrawerMenu.vue';

  export default {
    components: {
      PlanningDrawerWindows,
      PlanningDrawerMenu,
    },
    props: {
      planning: { type: Object, required: true },
      options: { type: Object, default: undefined },
      events: { type: Object, default: undefined },
      canModifyPlanning: { type: Boolean, default: false },
    },
    data() {
      return {
        availableTabs: this.canModifyPlanning ? ['exchanges', 'actions'] : [],
      };
    },
    computed: {
      planningDrawerIsOpened() {
        return !! this.planningDrawerTabIndex;
      },
      hasTitle() {
        return this.planningDrawerIsOpened && ['element', 'color-picker', 'config'].includes(this.planningDrawerTabIndex);
      },
      titleColorClass() {
        const el = this.planningDrawerTabIndex == 'element' && this.openedElement || this.planningDrawerTabIndex == 'color-picker' && this.openedColorPickerElement;
        if (! el || ! el.isType) return 'bg-secondary';
        if (el.isType('milestone')) return 'bg-lightgrey';
        return `${el.getColorClass()} bg-el-color`;
      },
      ...mapState('ui/planning', ['planningDrawerTabIndex', 'openedElement', 'openedColorPickerElement']),
    },
    watch: {
      '$route.query.open': {
        handler() {
          if (['exchanges', 'actions'].includes(this.$route.query.open)) {
            const tab = this.$route.query.open;
            this.$router.replace({ query: { ...this.$route.query, open: undefined } });
            // if (tab == 'actions') this.actionsScope.onlyMine = true;
            this.open(tab);
          }
        },
        immediate: true,
      },
      '$store.state.ui.planning.clickOnPlanning': function () {
        this.close();
      },
    },
    methods: {
      open(tab) {
        if (this.availableTabs.includes(tab)) {
          this.$store.state.ui.planning.planningDrawerTabIndex = tab;
        }
      },
      close() {
        this.$store.state.ui.planning.planningDrawerTabIndex = '';
      },
    },
  };
</script>
