<template>
  <v-dialog v-if="! user.id" :model-value="!! premiumWindowIsOpened" height="100%" @update:model-value="! $event && close()">
    <div class="text-center pa-6 subheading"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
  </v-dialog>

  <!-- Premium Subscription -->
  <v-dialog v-else-if="isTrial || ! isPremium" :model-value="!! premiumWindowIsOpened" width="100%" height="100%" :persistent="! premiumWindowBackdropClose" :opacity=".6"
            @update:model-value="! $event && close()">
    <v-card class="premium-subscription-scroll-container h-100 pa-6 bg-background light-scrollbar">
      <v-btn-icon size="small" color="medium-emphasis" style="position: absolute; top: 8px; right: 8px" @click="close">
        <v-icon size="24">far fa-times</v-icon>
      </v-btn-icon>
      <premium-subscription @set-backdrop-close="premiumWindowBackdropClose = $event" @close="close"></premium-subscription>
    </v-card>
  </v-dialog>

  <v-dialog v-else-if="! isAdmin" :model-value="!! premiumWindowIsOpened" width="auto" @update:model-value="! $event && close()">
    <v-card class="pa-6">
      <div class="mb-4">{{ $t('PREMIUM.ONLY_FOR_ADMIN') }}</div>
      <div class="text-center"><v-btn rounded size="large" variant="outlined" @click="close">{{ $t('GLOBAL.CLOSE') }}</v-btn></div>
    </v-card>
  </v-dialog>

  <v-dialog v-else-if="canChangeQuantity" :model-value="!! premiumWindowIsOpened" width="auto" @update:model-value="! $event && close()">
    <v-card class="pa-6" style="max-width: 400px">
      <b>{{ $t('PREMIUM.ADJUST_USERS_QUOTA') }}</b>
      <v-text-field v-model.number="maxusers" type="number" :min="packageMinUsers" max="50" class="mt-2 text-center">
        <template #prepend-inner>
          <v-btn-icon size="small" class="mr-4" @click="maxusers = maxusers - 1">
            <v-icon size="large">far fa-minus</v-icon>
          </v-btn-icon>
        </template>
        <template #append-inner>
          <v-btn-icon size="small" class="ml-4" @click="maxusers = maxusers + 1">
            <v-icon size="large">far fa-plus</v-icon>
          </v-btn-icon>
        </template>
      </v-text-field>
      <div class="mt-1">{{ $t('PREMIUM.WILL_PRORATE') }}</div>
      <div class="d-flex justify-center gap-8 mt-4">
        <v-btn rounded size="large" variant="outlined" @click="close">{{ $t('GLOBAL.CANCEL') }}</v-btn>
        <v-btn :disabled="! maxusers || maxusers == user.company?.maxusers || saving.inprogress" color="accent" rounded size="large" class="px-8" @click="adjustUsersQuota()">
          {{ $t('GLOBAL.VALIDATE') }}
        </v-btn>
      </div>
      <div class="text-errorred">{{ saving.error }}</div>
      <account-link-to-website section="premium" class="mt-8"></account-link-to-website>
    </v-card>
  </v-dialog>

  <v-dialog v-else :model-value="!! premiumWindowIsOpened" width="auto" @update:model-value="! $event && close()">
    <v-card class="pa-6">
      <div class="pre">{{ $t('PREMIUM.CANNOT_CHANGE_PLAN') }}</div>
      <account-link-to-website section="premium" class="my-4"></account-link-to-website>
      <div class="text-center"><v-btn rounded size="large" variant="outlined" @click="close">{{ $t('GLOBAL.CLOSE') }}</v-btn></div>
    </v-card>
  </v-dialog>
</template>

<script>
  import AccountLinkToWebsite from '@/components/Account/AccountLinkToWebsite.vue';
  import PremiumSubscription from './PremiumSubscription.vue';

  export default {
    components: {
      AccountLinkToWebsite,
      PremiumSubscription,
    },
    emits: ['close'],
    data() {
      return {
        maxusers: null,
        packageMinUsers: 1,
        saving: { inprogress: false, error: "" },
        premiumWindowBackdropClose: true,
      };
    },
    computed: {
      canChangeQuantity() {
        return this.package == 'TEAM' || this.package == 'TEAM12M' || this.package == 'BUSINESS' || this.package == 'BUSINESS12M';
      },
      premiumWindowIsOpened() {
        return this.$store.state.ui.navigation.openedModal == 'premium-window';
      },
      user() { return this.$store.state.users.user; },
      package() { return this.user.company && this.user.company.billing_plan; },
      isAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      isTrial() { return this.$store.state.users.accessRight.isTrial; },
    },
    watch: {
      maxusers(newVal) {
        if (newVal < this.packageMinUsers) this.$nextTick(() => { this.maxusers = this.packageMinUsers; });
        if (newVal > 50) this.$nextTick(() => { this.maxusers = 50; });
      },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        this.maxusers = this.user.company?.maxusers;
      });
    },
    methods: {
      adjustUsersQuota() {
        this.saving = { inprogress: true, error: "" };
        window.apiSrv.call('buy/plan/quantity', 'update', { id: this.maxusers }).then(() => {
          this.close();
          window.location.reload();
        }).catch((message) => {
          this.saving = { inprogress: false, error: `Error : ${message}` };
        });
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
