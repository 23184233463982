<template>
  <div>
    <!-- Budgets Summary -->
    <v-card v-for="(sumBudgetByStates, icon, index) in sumBudgetByIconsStates" :key="icon" class="mb-4">
      <table class="table text-center">
        <thead class="bg-lightgrey">
          <tr>
            <th class="nobr">
              <span v-if="! index">{{ $t('PROJECT_SHEET.BUDGET_SUMMARY') }} (<i :class="`budget-${icon}`"></i>)</span>
              <i v-else :class="`budget-${icon}`"></i>
            </th>
            <th class="nobr" style="width: 90px">{{ $t('ELEMENT-DETAIL.AMOUNT_INPROGRESS') }}</th>
            <th class="nobr" style="width: 90px">{{ $t('ELEMENT-DETAIL.AMOUNT') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="state in budgetStateKeysByIcons[icon]" :key="state">
            <td>{{ budgetStates[state] || '-' }}</td>
            <td>
              <template v-if="sumBudgetByStates[state].amount_inprogress">{{ $filters.number(sumBudgetByStates[state].amount_inprogress) }}</template>
            </td>
            <td>
              <template v-if="sumBudgetByStates[state].amount">{{ $filters.number(sumBudgetByStates[state].amount) }}</template>
            </td>
          </tr>
          <tr class="text-primary strong">
            <td>{{ $t('GLOBAL.TOTAL') }}</td>
            <td class="nobr">
              <template v-if="sumBudgetByStates.totals.totalAmountInProgress">{{ $filters.number(sumBudgetByStates.totals.totalAmountInProgress) }}</template>
            </td>
            <td class="nobr">
              <template v-if="sumBudgetByStates.totals.totalAmount">{{ $filters.number(sumBudgetByStates.totals.totalAmount) }}</template>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card>

    <div class="d-flex justify-end">
      <div v-if="budgetTarget" :class="budgetTargetClass" class="py-1 px-4 text-center" style="border-radius: 12px; min-width: 180px">
        {{ $t('PROJECT_SHEET.TARGET_BUDGET') }} : {{ $filters.number(budgetTarget) }}
      </div>
    </div>

    <!-- Budgets details by bubble -->
    <v-card v-if="filteredBudgetElements.length" class="mt-10">
      <table class="table">
        <thead class="bg-lightgrey">
          <tr>
            <th class="nobr">{{ $t('PROJECT_SHEET.BUDGET_DETAIL_BY_BUBBLE') }}</th>
            <th class="nobr" style="width: 90px">{{ $t('ELEMENT-DETAIL.AMOUNT_INPROGRESS') }}</th>
            <th class="nobr" style="width: 90px">{{ $t('ELEMENT-DETAIL.AMOUNT') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="el in filteredBudgetElements" :key="el.id">
            <td class="small">
              <div v-for="state in budgetStateKeys" :key="state">
                <!-- Iterate budgetStateKeys array to fix order -->
                <div v-for="(budget, index) in elBudgetsByStatesByEl[el.id][state]" :key="index">
                  <span style="font-style: italic;" class="pointer" @click="openElement(el)">&nbsp;{{ el.getTitle() || $t('PLANNING.NEW_BUBBLE') }}</span>
                  <span v-if="budgetStates[state]" class="small">&nbsp;({{ $filters.lowercase(budgetStates[state]) }})</span>
                </div>
              </div>
            </td>
            <td class="small nobr text-center">
              <div v-for="state in budgetStateKeys" :key="state">
                <!-- Iterate budgetStateKeys array to fix order -->
                <div v-for="(budget, icon) in elBudgetsByStatesByEl[el.id][state]">
                  <span v-if="budget.amount_inprogress">{{ $filters.number(budget.amount_inprogress) }} <i :class="`budget-${icon}`"></i></span>
                </div>
              </div>
            </td>
            <td class="small nobr text-center">
              <div v-for="state in budgetStateKeys" :key="state">
                <!-- Iterate budgetStateKeys array to fix order -->
                <div v-for="(budget, icon) in elBudgetsByStatesByEl[el.id][state]">
                  <span v-if="budget.amount">{{ $filters.number(budget.amount) }} <i :class="`budget-${icon}`"></i></span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card>

    <div v-if="! filteredBudgetElements.length" class="mt-2 ml-6 text-medium-emphasis">{{ $t('PROJECT_SHEET.NO_BUDGET') }}</div>
  </div>
</template>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
    },
    emits: ['open-element'],
    data() {
      return {
      };
    },
    computed: {
      budgetStates() {
        return this.$store.getters['users/accessRight/config'].budgetStates;
      },
      budgetStateKeys() {
        return [...Object.keys(this.budgetStates), ''];
      },
      budgetStateKeysSet() {
        return new Set(this.budgetStateKeys);
      },
      budgetStateKeysByIcons() {
        return Object.keys(this.sumBudgetByIconsStates).reduce((acc, icon) => {
          const sumBudgetByStates = this.sumBudgetByIconsStates[icon];
          acc[icon] = this.budgetStateKeys.filter(state => sumBudgetByStates[state]?.amount_inprogress || sumBudgetByStates[state]?.amount);
          return acc;
        }, {});
      },
      filteredBudgetElements() {
        return (this.planning.elements || []).filter((el) => {
          if (el.isType('macro')) return false;
          const elBudgets = el.getBudgets();
          if (! elBudgets) return false;
          if (! elBudgets.some(budget => budget.amount || budget.amount_inprogress)) return false;
          return true;
        }).sort((a, b) => (a.getLaneId() < b.getLaneId() ? -1 : 1));
      },
      sumBudgetByIconsStates() {
        // compute el budgets to organize as { [icon]: { [state] : { amont, amount_inprogress}, totals: { totalAmount, totalAmountInProgress } }}
        const budgetsByIconsStates = {};
        this.filteredBudgetElements.forEach((el) => {
          (el.getBudgets() || []).forEach((budget) => {
            const state = this.budgetStateKeysSet.has(budget.state) ? budget.state : '';
            const icon = budget.icon || '';
            if (! budgetsByIconsStates[icon]) budgetsByIconsStates[icon] = { totals: { totalAmount: 0, totalAmountInProgress: 0 } };
            if (! budgetsByIconsStates[icon][state]) budgetsByIconsStates[icon][state] = { amount: 0, amount_inprogress: 0 };
            budgetsByIconsStates[icon][state].amount += budget.amount || 0;
            budgetsByIconsStates[icon][state].amount_inprogress += budget.amount_inprogress || 0;
            budgetsByIconsStates[icon].totals.totalAmount += budget.amount || 0;
            budgetsByIconsStates[icon].totals.totalAmountInProgress += budget.amount_inprogress || 0;
          });
        });
        return budgetsByIconsStates;
      },
      budgetTargetClass() {
        let maxBudget = 0;
        Object.keys(this.sumBudgetByIconsStates).forEach((icon) => {
          maxBudget = Math.max(maxBudget, this.sumBudgetByIconsStates[icon].totals.totalAmount, this.sumBudgetByIconsStates[icon].totals.totalAmountInProgress);
        });
        return maxBudget <= this.budgetTarget ? 'bg-successgreen text-white' : 'bg-errorred text-white';
      },
      elBudgetsByStatesByEl() {
        return this.filteredBudgetElements.reduce((acc, el) => { acc[el.id] = this.elBudgetsByStates(el); return acc; }, {});
      },
      budgetTarget() {
        return this.planning.projectsheet && this.planning.projectsheet.target_budget;
      },
    },
    methods: {
      elBudgetsByStates(el) {
        // compute el budgets to organize as { [state]: { [icon] : budgetObj }}
        const elBudgets = el.getBudgets();
        if (! elBudgets) return null;
        const budgetsByStates = {};
        elBudgets.forEach((budget) => {
          if (! budget.amount && ! budget.amount_inprogress) return;
          const state = this.budgetStateKeysSet.has(budget.state) ? budget.state : '';
          const icon = budget.icon || '';
          if (! budgetsByStates[state]) budgetsByStates[state] = {};
          if (! budgetsByStates[state][icon]) budgetsByStates[state][icon] = { amount: 0, amount_inprogress: 0 };
          budgetsByStates[state][icon].amount += budget.amount || 0;
          budgetsByStates[state][icon].amount_inprogress += budget.amount_inprogress || 0;
        });
        return budgetsByStates;
      },
      openElement(el) {
        this.$emit('open-element', el);
      },
    },
  };
</script>
