/* eslint-disable no-use-before-define */
import constants from '@/js/constants.js';
import JsonPatchSrv from '@/js/services/JsonPatchSrv';
import { innerHeight } from '@/components/Reusables/utils';
import { getDefaultTask, getDefaultMilestone, getDefaultMacro } from './helpers/defaultElements';
import helpers from './helpers/planningInitHelpers';

/** ************** */
/* ELEMENT MODEL */
/** ************** */
function defaultByType(type) {
  if (type == 'macro') return getDefaultMacro();
  return (type == 'milestone' ? getDefaultMilestone() : getDefaultTask());
}

class PlanningElement {
  constructor(planning, srcData) {
    let data = angular.copy(srcData) || {};

    if (data instanceof PlanningElement) {
      _.extend(this, data);
      data = this.data;
    } else {
      const defaultProps = defaultByType(data.type);
      _.extend(this, defaultProps);
      data = angular.merge({}, angular.copy(this.data), data);
      helpers.updateLastVersionsElements(data);
    }

    this.set(data);
    if (! this.id) this.id = 0;
    this.getPlanning = function () {
      return planning;
    };
  }

  getAll() {
    const nonNullData = {};
    Object.keys(this.data).forEach((key) => { if (this.data[key] !== null) nonNullData[key] = this.data[key]; });
    return { id: this.id, ...nonNullData };
  }

  set(elData) {
    const data = elData || {};
    const self = this;
    ['id', 'access_right'].forEach((attr) => {
      if (Object.prototype.hasOwnProperty.call(data, attr)) {
        self[attr] = data[attr];
        delete data[attr];
      }
    });
    Object.keys(data).forEach((key) => {
      self.data[key] = data[key];
    });
    return this;
  }

  reset(data) {
    delete data.id;
    const defaultProps = defaultByType(data.type || this.type);
    _.extend(this.data, { ...defaultProps.data, ...data });
    return this;
  }

  /** ********** */
  /* GET / SET */
  /** ********** */
  getType() {
    return this.data.type;
  }
  setType(value) {
    this.data.type = value;
    return this;
  }
  isType(...args) {
    return args.includes(this.getType());
  }

  isVisibleOnPlanning() {
    return this.visible && ! this.filteredout && this.hasDates();
  }

  getLaneId() {
    return this.data.lane_id;
  }
  setLaneId(value) {
    this.data.lane_id = value;
    return this;
  }

  getTitle() {
    return this.data.title;
  }
  setTitle(value) {
    this.data.title = value;
    return this;
  }

  getTitleStyle() {
    return this.data.titleStyle;
  }
  setTitleStyle(value) {
    this.data.titleStyle = value;
    return this;
  }

  getDescription() {
    return this.data.html;
  }
  setDescription(value) {
    this.data.html = value;
    return this;
  }

  getDescriptionStyle() {
    return this.data.descriptionStyle;
  }
  setDescriptionStyle(value) {
    this.data.descriptionStyle = value;
    return this;
  }

  getProcessStep() {
    return this.data.processStep || null;
  }
  setProcessStep(value) {
    this.data.processStep = value || ''; // do not set to null for change detection in getAll
    return this;
  }

  getProcessPriority() {
    return this.data.processPriority || 0;
  }
  setProcessPriority(value) {
    this.data.processPriority = value || null;
    return this;
  }

  hasDates() {
    return this.data.starttime && this.data.endtime && true || false;
  }

  getStartTime() {
    if (! this.data.starttime) return null;
    return moment(this.data.starttime);
  }
  setStartTime(value) {
    if (moment.isMoment(value)) value = value.format();
    if (! value) value = ''; // do not set to null for change detection in getAll
    this.data.starttime = value;
    if (this.isType('milestone')) this.data.endtime = value;
    return this;
  }

  getEndTime() {
    if (! this.data.endtime) return null;
    return moment(this.data.endtime);
  }
  setEndTime(value) {
    if (moment.isMoment(value)) value = value.format();
    if (! value) value = ''; // do not set to null for change detection in getAll
    this.data.endtime = value;
    if (this.isType('milestone')) this.data.starttime = value;
    return this;
  }

  getSchedule() {
    const schedule = this.data.schedule || { start: null, end: null };
    return {
      start: schedule.start && moment(schedule.start),
      end: schedule.end && moment(schedule.end),
    };
  }
  setSchedule(value) {
    value = angular.copy(value);
    if (value?.start && moment.isMoment(value.start)) value.start = value.start.format();
    if (value?.end && moment.isMoment(value.end)) value.end = value.end.format();
    if (! value.start && ! value.end) value = { start: null, end: null };
    this.data.schedule = value;
    return this;
  }

  getRecurrenceId() {
    return this.data.recurrence_id;
  }
  setRecurrenceId(value) {
    this.data.recurrence_id = value;
    return this;
  }

  getWidth() {
    return this.data.width;
  }
  setWidth(value) {
    this.data.width = value;
    return this;
  }

  getPriority() {
    return this.data.priority;
  }
  setPriority(value) {
    this.data.priority = value;
    return this;
  }

  getConfig(field) {
    if (field) return this.data.config && this.data.config[field];
    return this.data.config;
  }
  setConfig(field, value) {
    // setConfig({all}) or setConfig(field, value)
    this.data.config = this.data.config || {};
    if (value !== undefined) {
      this.data.config[field] = value;
    } else {
      this.data.config = field;
    }
    return this;
  }

  getProgress() {
    return this.data.progress;
  }
  setProgress(value) {
    this.data.progress = value;
    return this;
  }

  getDependencies() {
    return this.data.dependencies;
  }
  setDependencies(value) {
    this.data.dependencies = value;
    return this;
  }

  getSubTasks() {
    return this.data.subTasks;
  }
  setSubTasks(value) {
    this.data.subTasks = value?.length ? value : [];
    return this;
  }

  getUsers() {
    return this.data.users;
  }
  setUsers(value) {
    this.data.users = value;
    return this;
  }

  getChecklist() {
    return this.data.checklist;
  }
  setChecklist(value) {
    this.data.checklist = value;
    return this;
  }

  getLinks() {
    return this.data.links;
  }
  setLinks(value) {
    this.data.links = value;
    return this;
  }

  getBudgets() {
    return this.data.budgets;
  }
  setBudgets(value) {
    this.data.budgets = value;
    return this;
  }

  getColorId() {
    return this.data.color;
  }
  setColorId(value) {
    this.data.color = (typeof value == 'number') ? value : 0;
    return this;
  }
  getColorShadeId() {
    return this.data.colorShade;
  }
  setColorShadeId(value) {
    this.data.colorShade = (typeof value == 'number') ? value : '';
    return this;
  }
  getIconId() {
    return this.data.icon;
  }
  setIconId(value) {
    this.data.icon = value;
    return this;
  }

  getIsLocked() {
    return this.data.isLocked;
  }
  setIsLocked(value) {
    this.data.isLocked = value;
    return this;
  }

  getTimeBar() {
    return this.data.timeBar || { colorId: '' };
  }
  setTimeBar(value) {
    this.data.timeBar = value;
    return this;
  }

  getMeetingId() {
    return this.data.meeting_id;
  }
  setMeetingId(value) {
    this.data.meeting_id = value;
    return this;
  }

  /** ******** */
  /* DISPLAY */
  /** ******** */
  getSecondaryColor() {
    const { colors } = this.getPlanning().config;
    return colors[this.data.color]?.secondary || '#dedefd';
  }

  getColorClass() {
    return `el-color-${this.getColorId()}-${this.getColorShadeId()}`;
  }

  hasIcon() {
    return !! this.data.icon;
  }
  getIcon() {
    const { icons } = this.getPlanning().config;
    return icons[this.data.icon] || {};
  }

  getChecklistItemColor(item) {
    item = item || {};
    if (item.checked) return 'medium-emphasis';
    const deadline = item.duedate ? moment(item.duedate) : this.getEndTime();
    if (! deadline) return 'black';
    if (deadline.isBefore()) return 'errorred';
    if (deadline.add(-1, 'week').isBefore()) return 'warningorange';
    return 'black';
  }

  getChecklistItemClass(item) {
    return `text-${this.getChecklistItemColor(item)}`;
  }

  getEndTimeColorClass() { // for kanban mode
    if (this.getProgress() == 100) return '';
    if (this.getEndTime().isBefore()) return 'text-errorred';
    if (this.getEndTime().add(-1, 'week').isBefore()) return 'text-warningorange';
    return '';
  }

  updateXposition() {
    if (! this.hasDates()) return;
    const { visibleTimeline } = this.getPlanning();
    const starttime = convertToBusinessTimeEquivalent(this.getStartTime());
    let result = starttime.diffWithWorkdays(moment(visibleTimeline.starttime), 'minutes', visibleTimeline.workdays) / visibleTimeline.minuteperpx;

    if (this.isType('milestone')) {
      result -= this.getWidth() / 2;
    } else {
      result = Math.max(result, 0);
    }
    this.xposition = Math.round(result);
  }

  updateHeight() {
    if (! this.visible && ! this.hasDates()) { this.height = 0; return; }
    const height = innerHeight(document.getElementById(`el${this.id}`)); // with padding, not borders
    this.height = Math.round(height || this.height || 0);
  }

  updateWidth() {
    if (! this.hasDates()) return;
    const { visibleTimeline } = this.getPlanning();
    if (this.isType('milestone')) {
      this.visible = !! ((moment(visibleTimeline.starttime).isBefore(this.getStartTime()) && moment(visibleTimeline.endtime).isAfter(this.getStartTime())));
    } else {
      const elStarttime = convertToBusinessTimeEquivalent(this.getStartTime());
      const elEndtime = convertToBusinessTimeEquivalent(this.getEndTime());
      const starttime = moment.max(elStarttime, moment(visibleTimeline.starttime));
      const endtime = moment.min(elEndtime, moment(visibleTimeline.endtime).add(-1 * visibleTimeline.minuteperpx, 'minutes'));
      const duration = endtime.diffWithWorkdays(starttime, 'minutes', visibleTimeline.workdays);
      const realduration = elEndtime.diffWithWorkdays(elStarttime, 'minutes', visibleTimeline.workdays);
      const result = Math.max(constants.taskMinWidth, duration / visibleTimeline.minuteperpx - 1); // min resize
      if (result <= constants.taskMinWidth && duration < realduration) { this.visible = false; } else { this.visible = true; } // hide small overlaps at the edges
      this.setWidth(Math.round(result));
    }
  }

  update() {
    this.updateXposition();
    this.updateWidth();
  }

  /** ******** */
  /* ACTIONS */
  /** ******** */
  move(laneId, positionLeft) {
    if (! this.hasDates()) return;
    const { visibleTimeline } = this.getPlanning();
    this.setLaneId(laneId);
    // Update start time & end time
    if (this.isType('milestone')) positionLeft += this.getWidth() / 2;
    const elduration = this.getEndTime().diffWithWorkdays(this.getStartTime(), 'minutes', visibleTimeline.workdays);
    const starttime = moment(visibleTimeline.starttime).addWithWorkdays(positionLeft * visibleTimeline.minuteperpx, 'minutes', visibleTimeline.workdays);
    this.setStartTime(convertFromBusinessTimeEquivalent(starttime));
    this.setEndTime(this.getStartTime().addWithWorkdays(elduration, 'minutes', visibleTimeline.workdays));
    this.update(); // needed when xposition is programmaticaly set back to initial value
  }

  kanbanMove(laneId, referenceXPosition, referenceYPosition) { // el move in Kanban view / referenceXPosition may be right-click position or center of dragged element
    this.setLaneId(laneId);
    let colIndex = 0;
    const processSteps = this.getPlanning().process.steps;
    while (colIndex < processSteps.length && referenceXPosition >= processSteps[colIndex].width) {
      referenceXPosition -= processSteps[colIndex].width;
      colIndex++;
    }
    if (processSteps[colIndex]) this.setProcessStep(processSteps[colIndex].id);

    if (referenceYPosition) {
      // handle process priority
      const modifiedElements = [this];
      const sameCellElements = []; // visible elements only
      const hiddenSameCellElementsProcessPriority = []; // in backlog or xs columns elements may be hidden
      this.getPlanning().elements.forEach((el) => {
        if (el.isDragPlaceholder || el.id == this.id || el.getLaneId() != laneId || el.getProcessStep() != this.getProcessStep()) return;
        const $el = $(`#el${el.id}`);
        if ($el.length) {
          sameCellElements.push({ id: el.id, y: $el.offset().top + $el.height() / 2, el });
        } else {
          hiddenSameCellElementsProcessPriority.push(el.getProcessPriority());
        }
      });
      sameCellElements.sort((a, b) => a.y - b.y);

      if (sameCellElements.length) {
        let placeBetweenElement;
        for (let i = 0; i < sameCellElements.length; i++) {
          if (sameCellElements[i].y > referenceYPosition) {
            placeBetweenElement = { previous: sameCellElements[i - 1]?.el, next: sameCellElements[i].el, index: i };
            break;
          }
        }

        if (! placeBetweenElement) { // place last
          this.setProcessPriority(sameCellElements.at(-1).el.getProcessPriority() - 1024); // 1024 is arbitrary step value (easy to divide by 2)
        } else if (! placeBetweenElement.previous) { // place first
          this.setProcessPriority(placeBetweenElement.next.getProcessPriority() + 1024);
        } else if (Math.abs(placeBetweenElement.previous.getProcessPriority() - placeBetweenElement.next.getProcessPriority()) > 1e-3) { // place between when gap is > 0.001
          this.setProcessPriority((placeBetweenElement.previous.getProcessPriority() + placeBetweenElement.next.getProcessPriority()) / 2);
        } else { // no gap : reset all cell elements priority
          this.setProcessPriority(0);
          let priority = 1024;
          for (let i = placeBetweenElement.index - 1; i >= 0; i--) {
            sameCellElements[i].el.setProcessPriority(priority);
            priority += 1024;
          }
          priority = -1024;
          for (let i = placeBetweenElement.index; i < sameCellElements.length; i++) {
            sameCellElements[i].el.setProcessPriority(priority);
            priority -= 1024;
          }
          modifiedElements.push(...sameCellElements.map(item => item.el)); // nb : may include elements where process priority was not actually changed
        }
      } else if (hiddenSameCellElementsProcessPriority.length) {
        // no visible elements in cell (xs col), place on top
        this.setProcessPriority(Math.max(0, ...hiddenSameCellElementsProcessPriority) + 1024);
      }
      return modifiedElements; // for dropSelection to know if modifying 1 or all elements
    }
    return [this];
  }

  resize(elWidth, handle) {
    if (! this.hasDates()) return;
    const { visibleTimeline } = this.getPlanning();
    handle = handle || 'e';
    if (this.isType('macro', 'task')) {
      if (handle == 'e') {
        elWidth = Math.min(elWidth, visibleTimeline.pxwidth - this.xposition);
        const endtime = convertToBusinessTimeEquivalent(this.getStartTime()).addWithWorkdays(elWidth * visibleTimeline.minuteperpx, 'minutes', visibleTimeline.workdays);
        this.setEndTime(convertFromBusinessTimeEquivalent(endtime));
      }
      if (handle == 'w') {
        elWidth = Math.min(elWidth, this.xposition + this.getWidth());
        const starttime = convertToBusinessTimeEquivalent(this.getEndTime()).addWithWorkdays(-elWidth * visibleTimeline.minuteperpx, 'minutes', visibleTimeline.workdays);
        this.setStartTime(convertFromBusinessTimeEquivalent(starttime));
      }
    }
    this.setWidth(elWidth);
    this.update(); // update xposition
  }

  updateChecklistProgress() {
    if (! this.isType('task')) return;
    let totalWeight = 0;
    let weight = 0;
    (this.getChecklist() || []).forEach((item) => {
      const itemWeight = item.weight === 0 ? 0 : (item.weight || 1);
      if (item.checked) weight += itemWeight;
      totalWeight += itemWeight;
    });
    this.setProgress(totalWeight ? Math.round(100 * weight / totalWeight) : 0);
  }

  /** ***** */
  /* SAVE */
  /** ***** */
  generateContentPatch(oldState, props, forDeletion = false) {
    const oldStateElements = [];
    const newStateElements = [];
    if (oldState) {
      let oldStateElement = oldState;
      if (props?.length) oldStateElement = _.pick(oldStateElement, props);
      oldStateElement.id = this.id;
      oldStateElements.push(oldStateElement);
    } // else creation
    if (! forDeletion) {
      let newStateElement = this.getAll();
      if (props?.length) newStateElement = _.pick(newStateElement, props);
      newStateElement.id = this.id;
      newStateElements.push(newStateElement);
    } // else deletion

    return JsonPatchSrv.getElementsDiff(oldStateElements, newStateElements);
  }

  saveView(viewId, action = 'update') {
    return window.apiSrv.call(`views/${viewId}/elements`, action, this.getAll()).then((response) => {
      const savedElement = response?.data?.element;
      if (savedElement) this.set(savedElement);
    });
  }

  create() {
    return window.apiSrv.call(`plannings/${this.getPlanning().id}/elements`, 'store', this.getAll()).then((response) => {
      const savedElement = response?.data?.element;
      if (savedElement) this.set(savedElement);
    });
  }
}

/** ********************** */
/* BUSINESS CONFIG HELPERS */
/** ********************** */
function convertToBusinessTimeEquivalent(time) {
  const { businessHours, businessHoursRatio } = window.app.config.globalProperties.$store.getters['users/accessRight/config'];
  if (! businessHours) return time;
  const startOfDay = moment(time).startOf('day');
  const minutesInDay = time.diff(startOfDay, 'minutes');
  return startOfDay.add((Math.min(minutesInDay, businessHours.end) - Math.min(minutesInDay, businessHours.start)) * businessHoursRatio, 'minutes');
}
function convertFromBusinessTimeEquivalent(businessTime) {
  const { businessHours, businessHoursRatio } = window.app.config.globalProperties.$store.getters['users/accessRight/config'];
  if (! businessHours) return businessTime;
  const startOfDay = moment(businessTime).startOf('day');
  const minutesInDay = businessTime.diff(startOfDay, 'minutes');
  return moment(businessTime).startOf('day').minutes(businessHours.start).add(minutesInDay / businessHoursRatio, 'minutes');
}

export default PlanningElement;
