<template>
  <autocomplete-filter v-model="selectedProjects" :items="projectsList" item-value="id"
                       :label="$t('MULTIPROJECTS.PROJECTS')" :placeholder="selectedProjects.length ? '' : $t('MULTIPROJECTS.NO_PROJECT_SELECTED')"
                       v-model:search="searchInput" :prepend-inner-icon="smallScreen ? null : 'far fa-folder-open'" multiple :menu-props="{ location: 'bottom' }"
                       @update:model-value="update">
    <template #prepend-item>
      <filter-toggle :selected-ids="selectedProjects" :sublist-ids="projectIds"
                     @update="selectedProjects = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_PROJECTS') }} ({{ projectIds.length }})
      </filter-toggle>
      <v-divider></v-divider>
      <v-list-group v-if="projectCategories.length" fluid>
        <template #activator="{ props }">
          <v-list-item v-bind="props">
            <v-list-item-title class="ml-4">{{ $t('PROJECTS.CATEGORY', 2) }}</v-list-item-title>
          </v-list-item>
        </template>
        <filter-toggle v-for="category in filteredProjectCategories" :key="category"
                       :selected-ids="selectedProjects" :sublist-ids="projectIdsByCategory[category]" indent dense
                       @update="selectedProjects = $event; update()">
          <project-category :category="category" pointer></project-category> <span style="vertical-align: middle">({{ projectIdsByCategory[category].length }})</span>
        </filter-toggle>
        <v-divider></v-divider>
      </v-list-group>
    </template>
    <template #selection="{ item: { raw: item }, index }">
      <span v-if="selectedProjects.length == 1" class="text-ellipsis">{{ item.title }}</span>
      <span v-else-if="selectedProjects.length == projects.length && index === 0" class="text-ellipsis">{{ $t('MULTIPROJECTS.ALL_PROJECTS') }} ({{ projects.length }})</span>
      <span v-else-if="index === 0" class="text-ellipsis">{{ selectedProjects.length }}/{{ projects.length }}</span>
    </template>
  </autocomplete-filter>
</template>

<script>
  import { mapState } from 'vuex';
  import { getProjectIdsByCategory } from '@/store/multiprojects/multiprojects';
  import ProjectCategory from '@/components/Projects/ProjectCategory';

  export default {
    components: {
      ProjectCategory,
    },
    emits: ['update'],
    data() {
      return {
        searchInput: null,
      };
    },
    computed: {
      selectedProjects: {
        get() {
          return this.$store.state.multiprojects.selectedProjects;
        },
        set(newVal) {
          this.$store.state.multiprojects.selectedProjects.splice(0, this.$store.state.multiprojects.selectedProjects.length, ...newVal);
        },
      },
      projectIds() {
        return this.projects.map(item => item.id).filter(item => item);
      },
      projectIdsByCategory() {
        return getProjectIdsByCategory(this.projects);
      },
      projectCategories() {
        return Object.keys(this.projectIdsByCategory).sort((a, b) => {
          return (a || "").toLowerCase() < (b || "").toLowerCase() ? -1 : 1;
        });
      },
      filteredProjectCategories() {
        if (! this.searchInput) return this.projectCategories;
        const searchInputLowerCase = this.searchInput.toLowerCase();
        return this.projectCategories.filter(item => (item || "").toLowerCase().includes(searchInputLowerCase));
      },
      projectsList() {
        return this.projects.map(project => ({ id: project.id, title: project.meta?.title || this.$t('PLANNING.UNNAMED_PROJECT') }));
      },
      smallScreen() { return this.$vuetify.display.mdAndDown; },
      ...mapState('multiprojects', ['projects']),
    },
    methods: {
      update() {
        this.$emit('update');
      },
    },
  };
</script>
