<template>
  <v-tooltip v-if="user.id && (isTrial || isFree)" location="bottom">
    <template #activator="{ props }">
      <v-btn class="mx-1 text-center" rounded size="small" variant="elevated" style="height: 32px; min-width: 0" v-bind="props"
             @click="openPremium()">
        <div class="hidden-md-and-down">
          <div class="text-primary">{{ $t('NAV.BECOME_PREMIUM') }}</div>
          <span v-if="isTrial" :class="trialDaysLeft > 5 ? 'text-warningorange' : 'text-errorred'">{{ trialDaysLeft }} {{ $t('NAV.TRIAL_DAYS_LEFT', trialDaysLeft) }}</span>
          <span v-else class="text-warningorange">{{ $t('NAV.FREE') }}</span>
        </div>
        <div class="hidden-lg-and-up align-center" style="display: flex">
          <v-icon class="mr-1" color="primary">far fa-rocket-launch</v-icon>
          <span v-if="isTrial" :class="trialDaysLeft > 5 ? 'text-warningorange' : 'text-errorred'">{{ trialDaysLeft }} {{ $t('GLOBAL.DAYS', trialDaysLeft) }}</span>
          <span v-else class="text-warningorange">FREE</span>
        </div>
      </v-btn>
    </template>
    {{ $t('NAV.BECOME_PREMIUM') }}
  </v-tooltip>
</template>

<script>
  export default {
    computed: {
      user() { return this.$store.state.users.user; },
      premiumExpireDate() {
        return this.user.company?.premiumExpireDate;
      },
      isFree() {
        return ! this.premiumExpireDate;
      },
      trialDaysLeft() {
        const daysLeft = moment(this.premiumExpireDate).endOf('day').diff(moment(), 'days');
        return Math.max(daysLeft, 0);
      },
      isTrial() { return this.$store.state.users.accessRight.isTrial; },
    },
    methods: {
      openPremium() {
        this.$store.commit('ui/navigation/setOpenedModal', 'premium-window');
      },
    },
  };
</script>
