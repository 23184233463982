<template>
  <div>
    <div class="d-flex align-center mt-6 mb-1">
      <!-- ICON AND LABEL -->
      <v-icon start>far fa-calendar-alt</v-icon>
      {{ $t(isMilestone ? 'ELEMENT-DETAIL.DATE' : 'ELEMENT-DETAIL.DATES') }}
      <v-spacer></v-spacer>
      <!-- OPTIONS -->
      <template v-if="! isUnplanned && ! isConsult && ! hideDatesAdvancedOptions">
        <div class="pointer mr-2" @click="advancedOptions = ! advancedOptions">
          <i :class="advancedOptions ? 'fa-toggle-on text-successgreen' : 'fa-toggle-off text-black'" class="fas mr-1"></i>{{ $t('ELEMENT-DETAIL.ADVANCED_OPTIONS') }}
        </div>
      </template>
      <!-- SHOW DATES -->
      <element-details-config-button :el="el" :config="isMilestone ? 'show-date' : 'show-dates'"></element-details-config-button>
    </div>
    <!-- MILESTONE -->
    <div v-if="isMilestone">
      <div class="d-flex">
        <div class="flex-grow-1" style="max-width: 50%">
          <label for="milestoneDate">{{ $t('ELEMENT-DETAIL.DATE') }}</label>
          <date-field v-model="milestoneDate" id="milestoneDate" :allowed-dates="allowedDates" :no-null="! isUnplanned" :clearable="isUnplanned"></date-field>
        </div>
        <template v-if="milestoneDate">
          <div class="ml-4"></div>
          <div>
            <label for="scheduleStart">{{ $t('ELEMENT-DETAIL.SCHEDULE_AT') }}</label>
            <time-field v-model="scheduleStart" id="scheduleStart" :placeholder="$t('ELEMENT-DETAIL.SCHEDULE_START')" clearable style="width: 80px"></time-field>
          </div>
          <div class="ml-2"></div>
          <div>
            <label for="scheduleEnd">{{ $t('ELEMENT-DETAIL.SCHEDULE_UNTIL') }}</label>
            <time-field v-model="scheduleEnd" id="scheduleEnd" :placeholder="$t('ELEMENT-DETAIL.SCHEDULE_END')" clearable style="width: 80px"></time-field>
          </div>
        </template>
      </div>
      <div v-if="! isUnplanned && recurrence" class="mt-2">
        <v-btn variant="text" @click="recurrenceMenuIsOpened = true">
          <v-icon start>far fa-sync</v-icon>{{ recurrenceSummaryText }}
        </v-btn>
      </div>
      <div v-if="! milestoneDate" class="mt-2"><i>{{ $t('KANBAN.UNPLANNED_MILESTONE') }}</i></div>
    </div>
    <!-- TASK -->
    <div v-else-if="! isConsult">
      <div class="d-flex">
        <div class="flex-grow-1">
          <label for="taskStartTime">{{ $t('ELEMENT-DETAIL.FROM') }}</label>
          <date-field v-model="taskStartTime" id="taskStartTime" :allowed-dates="allowedDates" :no-null="! isUnplanned" :clearable="isUnplanned"></date-field>
        </div>
        <div v-show="taskStartTime && advancedOptions" class="ml-1" style="max-width: 80px">
          <label for="scheduleStart">{{ $t('ELEMENT-DETAIL.SCHEDULE_AT') }}</label>
          <time-field v-model="scheduleStart" id="scheduleStart" :placeholder="$t('ELEMENT-DETAIL.SCHEDULE_START')" clearable></time-field>
        </div>
        <div class="flex-grow-1 ml-4">
          <label for="taskEndTime">{{ $t('ELEMENT-DETAIL.TO') }}</label>
          <date-field v-model="taskEndTime" id="taskEndTime" :allowed-dates="allowedDates" :no-null="! isUnplanned" :clearable="isUnplanned"></date-field>
        </div>
        <div v-show="taskEndTime && advancedOptions" class="ml-1" style="max-width: 80px">
          <label for="scheduleEnd">{{ $t('ELEMENT-DETAIL.SCHEDULE_AT') }}</label>
          <time-field v-model="scheduleEnd" id="scheduleEnd" :placeholder="$t('ELEMENT-DETAIL.SCHEDULE_END')" clearable></time-field>
        </div>
      </div>
      <div v-if="! isUnplanned" class="mt-2 d-flex">
        <div>
          <label for="taskDuration">{{ $t('ELEMENT-DETAIL.DURATION') }}</label>
          <v-text-field v-model.number="taskDuration" id="taskDuration"
                        :suffix="$t(workdays ? 'GLOBAL.WORKDAYS' : 'GLOBAL.DAYS', taskDuration)" :style="{ 'max-width': workdays ? '170px' : '120px' }"
                        type="number" min="1">
          </v-text-field>
        </div>
        <div v-if="recurrence" class="ml-auto align-self-center">
          <div>&nbsp;</div>
          <v-btn variant="text" @click="recurrenceMenuIsOpened = true">
            <v-icon start>far fa-sync</v-icon>{{ recurrenceSummaryText }}
          </v-btn>
        </div>
      </div>
      <div v-else class="mt-2"><i>{{ $t('KANBAN.UNPLANNED_TASK') }}</i></div>
    </div>
    <!-- MACRO -->
    <div v-else>
      <div class="d-flex">
        <div class="flex-grow-1">
          <label for="taskStartTime">{{ $t('ELEMENT-DETAIL.FROM') }}</label>
          <v-text-field :model-value="taskStartTime.format('DD/MM/YYYY')" id="taskStartTime" readonly no-null></v-text-field>
        </div>
        <div class="ml-4 flex-grow-1">
          <label for="taskEndTime">{{ $t('ELEMENT-DETAIL.TO') }}</label>
          <v-text-field :model-value="taskEndTime.format('DD/MM/YYYY')" id="taskEndTime" readonly no-null></v-text-field>
        </div>
        <div class="ml-4" :style="{ width: workdays ? '150px' : '120px' }">
          <label for="taskDuration">{{ $t('ELEMENT-DETAIL.DURATION') }}</label>
          <v-text-field :model-value="taskDuration" id="taskDuration"
                        :suffix="$t(workdays ? 'GLOBAL.WORKDAYS' : 'GLOBAL.DAYS', taskDuration)" readonly no-null>
          </v-text-field>
        </div>
      </div>
    </div>

    <v-expand-transition v-if="! isConsult">
      <div v-show="advancedOptions" class="mt-4">
        <!-- DATES LOCK -->
        <div>
          <div class="d-flex align-center">
            <v-icon start class="mb-.5">far fa-lock</v-icon>{{ $t('ELEMENT-DETAIL.DATES_LOCK', isMilestone ? 1 : 2) }}
          </div>
          <v-switch v-model="isLocked">
            <template #label>
              <span class="small" style="line-height: 1.2">{{ $t(isLocked ? 'ELEMENT-DETAIL.DATES_LOCK_DESC' : 'ELEMENT-DETAIL.UNLOCKED') }}</span>
            </template>
          </v-switch>
        </div>
        <!-- SEND TO BACKLOG -->
        <div class="mt-2">
          <div class="d-flex align-center">
            <v-icon start class="mb-.5">far fa-calendar-times</v-icon>{{ $t('ELEMENT-DETAIL.REMOVE_FROM_PLANNING') }}
          </div>
          <v-btn size="small" variant="outlined" rounded class="mt-1" @click="sendToBacklog()">
            {{ $t('ELEMENT-DETAIL.SEND_TO_KANBAN') }}
          </v-btn>
        </div>
        <!-- RECURRENCE -->
        <div class="mt-4">
          <div class="d-flex align-center">
            <v-icon size="small" start style="margin-left: 4px; margin-bottom: 2px; position: relative; left: -1px">far fa-sync</v-icon>{{ $t('ELEMENT-DETAIL.RECURRENCE.RECURRENCE') }}
            <span class="label-new ml-2">NEW</span>
          </div>
          <v-btn size="small" variant="outlined" rounded class="mt-1" @click="recurrenceMenuIsOpened = true">
            {{ recurrence ? $t('ELEMENT-DETAIL.RECURRENCE.EDIT_RECURRENCE') : $t('ELEMENT-DETAIL.RECURRENCE.ADD_RECURRENCE') }}
          </v-btn>
        </div>
        <!-- INTEGRATIONS -->
        <element-integrations-manager v-if="displayIntegrations" :el="el" class="mt-4" @close="close"></element-integrations-manager>
      </div>
    </v-expand-transition>
    <v-menu v-model="recurrenceMenuIsOpened" attach="#planning-drawer-scroll-container-parent" content-class="ml-2"
            :close-on-content-click="false" contained width="calc(100% - 8px)" height="100%" transition="slide-x-reverse-transition">
      <v-card class="rounded-0 rounded-bs elevation-4 light-scrollbar overscroll-behavior-y-contain pt-2 pb-6">
        <div class="d-flex align-center font-weight-bold">
          <v-btn-icon size="small" class="mr-2" @click="recurrenceMenuIsOpened = false">
            <v-icon>far fa-chevron-left</v-icon>
          </v-btn-icon>
          {{ recurrence ? $t('ELEMENT-DETAIL.RECURRENCE.EDIT_RECURRENCE') : $t('ELEMENT-DETAIL.RECURRENCE.ADD_RECURRENCE') }}
        </div>
        <div class="px-6">
          <element-details-header-dates-recurrence :el="el" @close="recurrenceMenuIsOpened = false"></element-details-header-dates-recurrence>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import ElementDetailsHeaderDatesRecurrence from './ElementDetailsHeaderDatesRecurrence.vue';
  import ElementIntegrationsManager from '@/components/Integrations/ElementIntegrationsManager.vue';
  import ElementDetailsConfigButton from './partials/ElementDetailsConfigButton.vue';

  export default {
    components: {
      ElementDetailsHeaderDatesRecurrence,
      ElementIntegrationsManager,
      ElementDetailsConfigButton,
    },
    props: {
      el: { type: Object, required: true },
    },
    emits: ['close'],
    data() {
      return {
        workdays: this.el.getPlanning().timeline.workdays,
        advancedOptions: false,
        recurrenceMenuIsOpened: false,
        hideDatesAdvancedOptions: clientConfig.elements?.hideDatesAdvancedOptions || false,
      };
    },
    computed: {
      isMilestone() { return this.el.isType('milestone'); },
      isConsult() { return this.el.isType('macro'); },
      milestoneDate: {
        get() {
          return this.isMilestone && this.el.getStartTime() || null;
        },
        set(newVal) {
          this.el.setStartTime(newVal);
        },
      },
      scheduleStart: {
        get() {
          return this.el.getSchedule().start || null;
        },
        set(newVal) {
          this.el.setSchedule({
            start: newVal,
            end: this.scheduleEnd,
          });
          if (newVal && this.el.isType('macro', 'task')) {
            this.taskStartTime = moment(this.taskStartTime).hours(newVal.hours()).minutes(newVal.minutes()).seconds(0);
          }
        },
      },
      scheduleEnd: {
        get() {
          return this.el.getSchedule().end || null;
        },
        set(newVal) {
          this.el.setSchedule({
            start: this.scheduleStart,
            end: newVal,
          });
          if (newVal && this.el.isType('macro', 'task')) {
            this.taskEndTime = moment(this.taskEndTime).hours(newVal.hours()).minutes(newVal.minutes()).seconds(0);
          }
        },
      },
      taskStartTime: {
        get() {
          return (this.el.isType('macro', 'task')) && this.el.getStartTime() || null;
        },
        set(newVal) {
          if (! newVal) {
            this.el.setStartTime(null);
            return;
          }
          const newdate = moment(newVal).startOf('day');
          if (this.scheduleStart) newdate.hours(this.scheduleStart.hours()).minutes(this.scheduleStart.minutes()).seconds(0);
          this.el.setStartTime(newdate);
          if (this.el.getEndTime()?.isBefore(newdate)) {
            this.scheduleEnd = null;
            this.taskEndTime = newdate;
          }
        },
      },
      taskEndTime: {
        get() {
          return this.el.isType('macro', 'task') && this.el.getEndTime() || null;
        },
        set(newVal) {
          if (! newVal) {
            this.el.setEndTime(null);
            return;
          }
          const newdate = moment(newVal).endOf('day');
          if (this.scheduleEnd) newdate.hours(this.scheduleEnd.hours()).minutes(this.scheduleEnd.minutes()).seconds(0);
          this.el.setEndTime(newdate);
          if (newdate.isBefore(this.el.getStartTime())) {
            this.scheduleStart = null;
            this.taskStartTime = newdate;
          }
        },
      },
      taskDuration: {
        get() {
          return this.el.isType('macro', 'task') && this.duration(this.el.getStartTime(), this.el.getEndTime()) || null;
        },
        set(newVal) {
          const newDuration = parseInt(newVal, 10);
          if (newDuration < 1) return;
          const toadd = newDuration - this.duration(this.el.getStartTime(), this.el.getEndTime());
          if (toadd) this.taskEndTime = this.el.getEndTime().addWithWorkdays(toadd, 'days', this.workdays);
        },
      },
      isUnplanned() {
        return this.isMilestone ? ! this.milestoneDate : ! (this.taskStartTime && this.taskEndTime);
      },
      isLocked: {
        get() { return this.el.getIsLocked(); },
        set(newVal) {
          this.el.setIsLocked(newVal);
        },
      },
      recurrence() {
        const recurrenceId = this.el.getRecurrenceId();
        if (! recurrenceId) return null;
        return this.el.getPlanning()?.config.recurrences?.[recurrenceId] || null;
      },
      recurrenceSummaryText() {
        if (! this.recurrence) return '';
        const { interval, frequency } = this.recurrence;
        let text = this.$t('ELEMENT-DETAIL.RECURRENCE.REPEAT', frequency == 'weeks' || frequency == 'years' ? 2 : 1);
        if (interval > 1) text += ` ${interval}`;
        text += ` ${this.$t(`TIMELINE.${frequency.toUpperCase()}`).toLowerCase()}`;
        return text;
      },
      integrationActive() {
        const outlookStatus = this.$store.state.integrations.microsoft.outlook.connected && this.$store.getters['integrations/microsoft/getElementStatus'](this.el);
        return outlookStatus || this.$store.state.integrations.google.gcal.connected && this.$store.getters['integrations/google/getElementStatus'](this.el);
      },
      displayIntegrations() {
        const { availableIntegrations } = this.$store.getters['users/accessRight/config'];
        return ! (availableIntegrations && ! availableIntegrations.length);
      },
    },
    watch: {
      integrationActive: {
        handler(newVal) {
          if (newVal) this.advancedOptions = true;
        },
        immediate: true,
      },
    },
    created() {
      this.advancedOptions = this.el.getIsLocked() || this.timeBarColorId || (this.el.isType('macro', 'task') && (this.scheduleStart || this.scheduleEnd));
    },
    methods: {
      sendToBacklog() {
        this.advancedOptions = false;
        this.taskStartTime = null;
        this.taskEndTime = null;
      },
      close() {
        this.$emit('close');
      },
      duration(m1, m2) {
        return m2.diffWithWorkdays(m1, 'days', this.workdays) + 1;
      },
      allowedDates(val) {
        if (! this.workdays) return true;
        const date = moment(val);
        if (date.day() === 0 || date.day() === 6) return false;
        return true;
      },
    },
  };
</script>
