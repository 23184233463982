import portfolioImg from '@/client_customs/WPD/wpd_portfolio.png';
import planningKeyDatesImg from '@/client_customs/WPD/wpd_planningkeydates.png';
import textUsers from '@/client_customs/WPD/textUsers';
import lang from '@/client_customs/WPD/lang.json';

const WpdPlanningKeyDates = () => import('@/client_customs/WPD/WpdPlanningKeyDates');
const WpdPortfolio = () => import('@/client_customs/WPD/WpdPortfolio');

export default {
  lang,
  sso: {
    protocol: 'openIDConnect',
    login: 'https://login.microsoftonline.com/a56e04c0-ecfc-4ac7-a1e4-23eacc14ee24/oauth2/v2.0/authorize?client_id=7a5e5f5e-9af5-4adb-b57e-3cde5ba4a753&response_type=code&scope=openid%20email%20profile&redirect_uri=https%3A%2F%2Fwpd.bubbleplan.net',
    response_type: 'code',
  },
  hideManageAccount: true,
  dashboards: {
    router: [
      { path: '/dashboards/wpd/planningkeydates', component: WpdPlanningKeyDates },
      { path: '/dashboards/wpd/portfolio', component: WpdPortfolio },
    ],
    list: [
      { title: 'Planning des Dates Clés', to: '/dashboards/wpd/planningkeydates', img: planningKeyDatesImg },
      { title: 'Portefeuille Projets WPD', to: '/dashboards/wpd/portfolio', img: portfolioImg },
    ],
  },
  elements: {
    defaultTask: {
      data: {
        config: {
          "show-description": false,
          "show-dates": false,
          "show-progress": false,
          "show-checklist": false,
          "show-users": false,
          "show-links": false,
          "show-budgets": false,
        },
      },
    },
    defaultMilestone: {
      data: {
        config: {
          "show-date": false,
          "show-users": false,
          "show-links": false,
        },
      },
    },
  },
  textUsers,
  projects: {
    defaultPrivacy: 'company visible',
    showAdvancedOptions: true,
    filterTemplateName: project => ['modele', 'modèle'].includes((project.title || "").toLowerCase().trim().substr(0, 6)),
  },
  subprojects: {
    visibleDetails: [],
  },
  availableTemplates: [],
  importTemplate: {
    mergeLanes: true,
    changeDates: true,
  },
};
