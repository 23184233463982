<template>
  <form role="form" @submit.prevent>
    <v-row>
      <v-col cols="12">
        <div class="flex-grow-1">
          <field-label :label="$t('NEWPROJECT.PROJECT_NAME')">
            <template #default="{ id }">
              <v-text-field v-model="projectTitle" :id="id" :placeholder="$t('NEWPROJECT.ENTER_PROJECT_NAME')" prepend-inner-icon="far fa-pencil-alt" autofocus autocomplete="nope"></v-text-field>
            </template>
          </field-label>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <date-field v-model="startdate" :label="$t('TIMELINE.START_DATE')" format="dddd DD MMMM YYYY" readonly no-null nudge-details prepend-inner-icon="far fa-calendar-alt"></date-field>
      </v-col>
      <v-col cols="12" md="6">
        <date-field v-model="enddate" :label="$t('TIMELINE.HORIZON_DATE')" :disabled="hasTemplateSelected && ! template.scale_dates"
                    format="dddd DD MMMM YYYY" readonly no-null nudge-details prepend-inner-icon="far fa-flag-checkered">
        </date-field>
      </v-col>
      <v-col cols="12">
        <btn-toggle-field v-model="timeline" :items="availableTimelineOptions" :label="$t('TIMELINE.TIMELINE')"></btn-toggle-field>
        <div class="mt-4"></div>
        <btn-toggle-field v-model="subtimeline" :items="availableSubtimelineOptions" :label="$t('TIMELINE.SUB_TIMELINE')"></btn-toggle-field>
      </v-col>
      <v-col cols="12">
        <!-- Project Category Edit -->
        <project-category-edit v-model="category" :project-categories="projectCategories"></project-category-edit>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-switch v-model="showAdvancedOptions" :label="$t('NEWPROJECT.ADVANCED_OPTIONS')"></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="showAdvancedOptions" class="mt-0">
      <v-col cols="12">
        <btn-toggle-field v-model="template.type" :items="templateOptions" :label="$t('NEWPROJECT.TEMPLATE')"></btn-toggle-field>
        <v-expand-transition>
          <div v-if="template.type">
            <v-autocomplete v-if="template.type == 'user_project' || template.type == 'template_project'" ref="inputTemplateText" v-model="template.user_project"
                            :items="projectsAvailableAsTemplate" item-title="title" return-object :placeholder="$t('NEWPROJECT.SEARCH_PROJECT_BY_NAME')"
                            variant="outlined" density="comfortable" class="mt-2">
            </v-autocomplete>
            <v-select v-else-if="template.type == 'example_project'" ref="inputExampleTemplateText" v-model="template.id" :items="projectTemplates" item-value="id" item-title="title"
                      variant="outlined" density="comfortable" class="mt-2">
            </v-select>
            <v-checkbox v-model="template.scale_dates" :label="$t('NEWPROJECT.AUTOADAPT_TO_HORIZON_DATE')" color="primary" density="comfortable">
            </v-checkbox>
          </div>
        </v-expand-transition>
      </v-col>
      <v-col cols="12">
        <btn-toggle-field v-model="privacy" :items="availablePrivacyOptions" :label="$t('NEWPROJECT.PRIVACY')"></btn-toggle-field>
      </v-col>
      <v-col cols="12">
        <select-user v-model="owner" :items="companyNonConsultUsers" :disabled="! userIsAdmin"
                     :label="$t('NEWPROJECT.OWNER')" :placeholder="owner ? '' : $t('NEWPROJECT.CHOOSE_OWNER')">
        </select-user>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div :class="{ 'mt-6': showAdvancedOptions }" class="text-center">
          <v-btn :disabled="createProjectDisabled" rounded color="primary" size="large" style="min-width: 300px;" @click="createProject()">
            {{ $t('NEWPROJECT.CREATE_PROJECT') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </form>
</template>

<script>
  import defaultConfig from '@/js/defaultConfig';
  import clientConfig from '@/client_customs/config';
  import Planning from '@/models/Planning';
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import ProjectCategoryEdit from '@/components/Projects/ProjectCategoryEdit';

  const clientConfigProjects = clientConfig.projects || {};

  export default {
    components: {
      ProjectCategoryEdit,
    },
    props: {
      projects: { type: Array, default: () => [] },
    },
    data() {
      const { defaultProjectPrivacy } = this.$store.getters['users/accessRight/config'];

      return {
        projectTitle: "",
        startdate: moment().startOf('month'),
        enddate: moment().add(8, 'months').endOf('month'),
        timeline: 'quarters',
        subtimeline: 'months',
        showAdvancedOptions: clientConfigProjects.showAdvancedOptions || false,
        category: '',
        privacy: defaultProjectPrivacy,
        template: { type: '', id: '', title: '', user_project: null, scale_dates: false },
        projectTemplates: null,
        createProjectDisabled: false,
        owner: null,
        timelineOptions: [
          { value: 'years', title: this.$t('TIMELINE.YEARS') },
          { value: 'quarters', title: this.$t('TIMELINE.QUARTERS') },
          { value: 'months', title: this.$t('TIMELINE.MONTHS') },
          { value: 'weeks', title: this.$t('TIMELINE.WEEKS') },
          { value: 'days', title: this.$t('TIMELINE.DAYS') },
          { value: 'none', title: this.$t('TIMELINE.NONE') },
        ],
        availablePrivacyOptions: defaultConfig.availablePrivacyOptions(),
      };
    },
    computed: {
      subcompaniesUsers() {
        if (this.$store.state.users.user.organization) {
          const subcompanies = this.$store.getters['users/getOrganizationSubCompanies'];
          return subcompanies.map(company => company.users).reduce((acc, val) => acc.concat(val), []); // reduce(...) could be replaced by flat() with ie polyfill
        }
        return this.$store.getters['users/getCompanyUsers'];
      },
      companyNonConsultUsers() {
        return this.subcompaniesUsers.filter(user => user.access_right != 'consult');
      },
      availableTimelineOptions() {
        return this.timelineOptions.filter(item => item.value != 'none');
      },
      availableSubtimelineOptions() {
        return this.timelineOptions.slice(this.timelineOptions.findIndex(item => item.value == this.timeline) + 1);
      },
      projectCategories() {
        return this.projects.map(project => project.category);
      },
      projectsWithTemplateStatus() {
        const projectStatusConfig = this.$store.getters['users/accessRight/config'].projectStatus || {};
        return this.projects.filter(project => projectStatusConfig[project.status]?.template).sort((a, b) => (a.title < b.title ? -1 : 1));
      },
      templateOptions() {
        const templateOptions = [{ value: '', title: this.$t('NEWPROJECT.NONE') }];
        if (this.projectsWithTemplateStatus.length) templateOptions.push({ value: 'template_project', title: this.$t('NEWPROJECT.TEMPLATE_PROJECT') });
        templateOptions.push({ value: 'user_project', title: this.$t('NEWPROJECT.FROM_EXISTING_PROJECT') });
        if (this.projectTemplates?.length) templateOptions.push({ value: 'example_project', title: this.$t('NEWPROJECT.EXAMPLE_PROJECT') });
        return templateOptions;
      },
      hasTemplateSelected() {
        if (this.template.type == 'template_project' || this.template.type == 'user_project') return !! this.template.user_project?.id;
        if (this.template.type == 'example_project') return !! this.template.id;
        return false;
      },
      projectsAvailableAsTemplate() {
        if (this.template.type == 'template_project') return this.projectsWithTemplateStatus;

        const projects = clientConfigProjects.filterTemplateName ? this.projects.filter(clientConfigProjects.filterTemplateName) : this.projects.slice();
        return projects.sort((a, b) => (a.title < b.title ? -1 : 1));
      },
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
    },
    watch: {
      startdate(newValue) {
        if (! newValue) return;
        if (moment(this.enddate).isBefore(newValue)) this.enddate = moment(newValue).add(1, this.timeline);
      },
      enddate(newValue) {
        if (! newValue) return;
        if (newValue.isBefore(this.startdate)) this.startdate = moment(newValue).add(-1, this.timeline);
      },
      timeline() {
        this.subtimeline = this.availableSubtimelineOptions[0].value;
      },
      'template.type': function (newVal) {
        if (newVal == 'template_project' || newVal == 'user_project') setTimeout(() => { this.$refs.inputTemplateText.focus(); }, 500);
        if (newVal == 'example_project') {
          if (! this.template.id && this.projectTemplates?.length) this.template.id = this.projectTemplates[0].id;
          setTimeout(() => { this.$refs.inputExampleTemplateText.focus(); }, 500);
        }
      },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        this.owner = this.$store.state.users.user;
      });

      ProjectSrv.listTemplates().then((response) => {
        this.projectTemplates = response.data;
      });
    },
    methods: {
      createProject() {
        this.createProjectDisabled = true;
        const startdate = moment(this.startdate).startOf('day');
        const enddate = moment(this.enddate).endOf('day');
        const steps = [this.timeline];
        if (this.subtimeline !== 'none') steps.push(this.subtimeline);

        const projectContentPromise = new Promise((resolvePromise, rejectPromise) => {
          if ((this.template.type == 'template_project' || this.template.type == 'user_project') && this.template.user_project?.id) { // user project template
            ProjectSrv.get(this.template.user_project.id).then((content) => {
              resolvePromise({
                title: this.projectTitle || this.template.user_project.title,
                content: ProjectSrv.projectFromTemplate({ template: content, startdate, enddate, steps, scaleDates: this.template.scale_dates }),
              });
            }).catch(() => {
              rejectPromise(new Error('Unable to load template project.'));
            });
          } else if (this.template.type == 'example_project' && this.template.id) { // example template
            ProjectSrv.getTemplate(this.template.id).then((content) => {
              const templateProject = this.projectTemplates.find(item => item.id == this.template.id);
              resolvePromise({
                title: this.projectTitle || templateProject && templateProject.title,
                content: ProjectSrv.projectFromTemplate({ template: content, startdate, enddate, steps, scaleDates: this.template.scale_dates }),
              });
            }).catch(() => {
              rejectPromise(new Error('Unable to load template.'));
            });
          } else { // no template
            const content = {
              timeline: {
                hidden: {
                  before: startdate.startOf(steps.at(-1)).format(),
                  after: enddate.endOf(steps.at(-1)).format(),
                },
                steps,
              },
              lanes: [{
                label: this.$t('PLANNING.NEW_LANE'),
              }],
            };

            resolvePromise({
              title: this.projectTitle || this.$t('NEWPROJECT.MY_PROJECT'),
              content,
            });
          }
        });

        projectContentPromise.then((data) => {
          if (! data.content) return Promise.reject(new Error('No content found.'));

          const newPlanning = new Planning(data.content);
          newPlanning.timeline.setColWidth(newPlanning.timeline.bestColWidth());
          data.content = newPlanning.toProject().content;

          if (this.owner && this.owner.id) data.owner_id = this.owner.id;
          data.category = this.category || '';
          data.privacy = this.privacy;

          return ProjectSrv.createNew(data).then((response) => {
            const id = response.data?.planning?.id;
            if (id) this.$router.push(`/planning/${id}/${window.slugify(data.title || "New project")}`);
            return response;
          }).catch(message => Promise.reject(message || "Unable to create project."));
        }).catch((error) => {
          this.$store.dispatch('ui/msgbox/open', { title: this.$t('GLOBAL.ERROR'), body: `${error}` });
          this.createProjectDisabled = false;
        });
      },
    },
  };
</script>
