<template>
  <v-container v-if="initialPlanning && initialPlanning.id" class="bg-white" fluid>
    <!-- Versions Comparative Header -->
    <div class="text-center font-weight-bold">
      <div class="text-primary mb-2">
        {{ finalPlanning && finalPlanning.id && finalPlanning.getTitle() }}
      </div>
      <span>{{ initialPlanningVersionName }}</span>
      <v-icon class="mx-1">far fa-long-arrow-alt-right</v-icon>
      <span>{{ finalPlanningVersionName }}</span>
    </div>

    <div class="d-flex align-center mb-2">
      <!-- Group By Lane -->
      <v-switch v-model="groupByLane" :label="$t('PLANNING_VERSIONS.GROUP_BY_LANE')"></v-switch>
      <v-spacer></v-spacer>
      <!-- Compare to another version -->
      <div class="mr-2 ml-auto" style="width: 320px">
        <select-filter v-model="finalProject" :items="savedProjectVersions" :item-title="getItemText"
                       :label="$t('PLANNING_VERSIONS.FINAL_VERSION_COMPARISON')" item-value="id" return-object
                       @update:model-value="loadFinalProjectVersion">
        </select-filter>
      </div>
    </div>

    <!-- Table Comparative -->
    <div v-if="! dataLoaded.finalVersion" class="text-center ma-6"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <v-data-table v-else :headers="headers" :items="tabItems" :sort-by="[{ key: 'finalstarttime', order: 'asc' }]" :group-by="groupByLane ? [{ key: 'laneId', order: 'asc' }] : []"
                  single-expand>
      <template #group-header="{ item, columns, isGroupOpen, toggleGroup }">
        <tr :title="isGroupOpen(item) ? $t('PLANNING.HIDE_LANE') : $t('PLANNING.SHOW_LANE')" class="pointer" @click="toggleGroup(item)">
          <th :colspan="columns.length" style="background: #EAF0F6">
            <v-icon class="export-hidden mr-2">fas {{ isGroupOpen(item) ? 'fa-caret-down' : 'fa-caret-right' }}</v-icon>
            <span class="vcenter">{{ item.items[0].raw.laneLabel }}</span>
          </th>
        </tr>
      </template>
      <template #item="{ item }">
        <tr>
          <td v-for="col in headers" :key="col.key" class="px-2">
            <template v-if="col.key == 'data-table-group'">
              <v-icon v-if="item.icon.name" :color="item.icon.color" style="margin-bottom: 1px">{{ item.icon.name }}</v-icon>
            </template>
            <template v-if="col.key == 'title'">
              <div style="min-width: 150px">
                <span>{{ item.title }}</span>
              </div>
            </template>
            <template v-else-if="col.key == 'users'">
              <div class="d-flex" style="flex-wrap: wrap">
                <div v-for="user in item.usersData" style="margin-right: 2px">
                  <v-tooltip location="left">
                    <template #activator="{ props }">
                      <img v-if="user.avatar" :src="user.avatar" alt=" " style="max-height: 20px; min-height: 20px; min-width: 20px; border-radius: 50%;" v-bind="props">
                      <i v-else class="far fa-user" style="margin: 0 3px" v-bind="props"></i>
                    </template>
                    <span>{{ $filters.username(user) }}</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
            <template v-if="col.key == 'initialstarttime'">
              <div v-if="item.initialstarttime" class="my-1 nobr">{{ $filters.moment(item.initialstarttime, 'll') }}</div>
            </template>
            <template v-if="col.key == 'finalstarttime'">
              <div v-if="item.finalstarttime" class="my-1 nobr">
                {{ $filters.moment(item.finalstarttime, 'll') }}
              </div>
            </template>
            <template v-if="col.key == 'diffstarttimes'">
              <v-chip v-if="item.diffstarttimes" :color="item.diffstarttimes > 0 ? 'errorred' : 'successgreen'">
                <span class="text-ellipsis">{{ item.diffstarttimes > 0 ? `+${item.diffstarttimes}` : item.diffstarttimes }} {{ $t('GLOBAL.DAYS', item.diffstarttimes) }}</span>
              </v-chip>
            </template>
            <template v-if="col.key == 'initialendtime'">
              <div v-if="item.initialendtime" class="my-1 nobr">{{ $filters.moment(item.initialendtime, 'll') }}</div>
            </template>
            <template v-if="col.key == 'finalendtime'">
              <div v-if="item.finalendtime" class="my-1 nobr">{{ $filters.moment(item.finalendtime, 'll') }}</div>
            </template>
            <template v-if="col.key == 'diffendtimes'">
              <v-chip v-if="item.diffendtimes" :color="item.diffendtimes > 0 ? 'errorred' : 'successgreen'">
                <span class="text-ellipsis">{{ item.diffendtimes > 0 ? `+${item.diffendtimes}` : item.diffendtimes }} {{ $t('GLOBAL.DAYS', item.diffendtimes) }}</span>
              </v-chip>
            </template>
            <template v-else-if="col.key == 'progress'">
              <div v-if="item.progress || item.initialProgress" class="d-flex align-center">
                {{ $filters.number(item.initialProgress) }}%
                <v-icon class="mx-1">far fa-long-arrow-alt-right</v-icon>
                <span>{{ $filters.number(item.progress) }}%</span>
              </div>
            </template>
            <template v-else-if="col.key == 'checklist'">
              <div v-if="item.checklist" class="d-flex align-center" style="min-width: 100px">
                <span>{{ item.initialChecklist || 0 }} / {{ item.checklist }}</span>
                <v-icon class="mx-1">far fa-long-arrow-alt-right</v-icon>
                <span>{{ item.checklist }} / {{ item.checklist }}</span>
              </div>
            </template>
            <template v-else-if="col.key == 'budget'">
              <div v-if="item.initialBudgetData || item.budgetData" class="d-flex align-center">
                <div v-if="item.initialBudgetData">
                  <div v-for="(initialBudget, icon) in item.initialBudgetData" :key="`version-${icon}`" class="nobr text-ellipsis">
                    <span :class="`budget-${icon}`"></span>
                    <span v-if="initialBudget.amount_inprogress && initialBudget.amount">{{ $filters.number(initialBudget.amount_inprogress) }}&nbsp;/</span>
                    <span v-if="initialBudget.amount_inprogress && ! initialBudget.amount">{{ $filters.number(initialBudget.amount_inprogress) }}</span>
                    <span v-if="initialBudget.amount">{{ $filters.number(initialBudget.amount) }}</span>
                  </div>
                </div>
                <div v-else>0</div>
                <v-icon class="mx-1">far fa-long-arrow-alt-right</v-icon>
                <div v-if="item.budgetData">
                  <div v-for="(finalBudget, icon) in item.budgetData" :key="icon" class="nobr text-ellipsis">
                    <span :class="`budget-${icon}`"></span>
                    <span v-if="finalBudget.amount_inprogress && finalBudget.amount">{{ $filters.number(finalBudget.amount_inprogress) }}&nbsp;/</span>
                    <span v-if="finalBudget.amount_inprogress && ! finalBudget.amount">{{ $filters.number(finalBudget.amount_inprogress) }}</span>
                    <span v-if="finalBudget.amount">{{ $filters.number(finalBudget.amount) }}</span>
                  </div>
                </div>
                <div v-else>0</div>
              </div>
            </template>
          </td>
        </tr>
      </template>
      <template #bottom></template>
    </v-data-table>
  </v-container>
  <v-card v-else class="pa-6 ma-1">
    <span>{{ $t('PLANNING_VERSIONS.NO_VERSION_SELECTED') }}</span>
  </v-card>
</template>

<script>
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import Planning from '@/models/Planning';

  export default {
    props: {
      planning: { type: Object, required: true },
      initialPlanning: { type: Object, default: null },
      projectVersionsList: { type: Array, default: () => [] },
    },
    data() {
      return {
        groupByLane: true,
        defaultProjectVersion: { date_of_modification: this.planning.meta.date_of_modification, version_name: this.$t('PLANNING_VERSIONS.CURRENT_VERSION') },
        finalProject: null,
        finalPlanning: null,
        planningSavedVersionsCache: {},
        dataLoaded: { finalVersion: true },
        headers: [
          { title: '', key: 'data-table-group', width: '0px' },
          { title: this.$t('ELEMENT-DETAIL.TITLE'), key: 'title', width: '0px' },
          { title: this.$t('ELEMENT-DETAIL.USERS'), key: 'users', width: '0px' },
          { title: this.$t('PLANNING_VERSIONS.INITIAL_STARTTIME'), key: 'initialstarttime', width: '0px' },
          { title: this.$t('PLANNING_VERSIONS.FINAL_STARTTIME'), key: 'finalstarttime', width: '0px' },
          { title: this.$t('PLANNING_VERSIONS.ECART'), key: 'diffstarttimes', width: '0px' },
          { title: this.$t('PLANNING_VERSIONS.INITIAL_ENDTIME'), key: 'initialendtime', width: '0px' },
          { title: this.$t('PLANNING_VERSIONS.FINAL_ENDTIME'), key: 'finalendtime', width: '0px' },
          { title: this.$t('PLANNING_VERSIONS.ECART'), key: 'diffendtimes', width: '0px' },
          { title: this.$t('ELEMENT-DETAIL.PROGRESS'), key: 'progress', width: '0px' },
          { title: this.$t('ELEMENT-DETAIL.CHECKLIST'), key: 'checklist', width: '0px' },
          { title: this.$t('ELEMENT-DETAIL.BUDGET'), key: 'budget', width: '0px' },
        ],
      };
    },
    computed: {
      tabItems() {
        const items = [];
        (this.finalPlanning?.lanes || []).forEach((lane) => {
          const laneElements = this.$store.getters['planning/lanes/getLaneElements']({ planning: this.finalPlanning, laneId: lane.id });
          laneElements.forEach((el) => {
            const initialEl = this.initialPlanning?.elements.find(item => item.id == el.id);
            const initialBudgetData = this.budget(initialEl);
            const budgetData = this.budget(el);
            const usersData = this.users(el);
            const [elStart, elEnd, initialElStart, initialElEnd] = [el.getStartTime(), el.getEndTime(), initialEl?.getStartTime(), initialEl?.getEndTime()];
            const item = {
              laneId: lane.id,
              laneLabel: lane.label,
              title: el.getTitle() || this.elDefaultTitle(el),
              icon: el.hasIcon() && el.getIcon(),
              users: usersData ? Object.keys(usersData).length : null,
              usersData,
              initialstarttime: initialElStart?.format('YYYY-MM-DD') || null,
              finalstarttime: elStart?.format('YYYY-MM-DD') || null,
              diffstarttimes: elStart && initialElStart ? elStart.diff(initialElStart, 'days') : null,
              initialendtime: initialElEnd?.format('YYYY-MM-DD') || null,
              finalendtime: elEnd?.format('YYYY-MM-DD') || null,
              diffendtimes: elEnd && initialElEnd ? elEnd.diff(initialElEnd, 'days') : null,
              initialProgress: initialEl?.getProgress() || 0,
              progress: el.getProgress() || 0,
              initialChecklist: initialEl?.getChecklist()?.length || 0,
              checklist: el.getChecklist()?.length,
              initialBudgetData,
              budgetData,
              budget: budgetData ? Object.keys(budgetData).reduce((acc, icon) => acc + (budgetData[icon].amount || budgetData[icon].amount_inprogress), 0) : null,
            };
            items.push(item);
          });
        });
        return items;
      },
      initialPlanningVersionName() {
        return this.initialPlanning?.meta?.version_name || `${this.$t('PLANNING_VERSIONS.VERSION_OF')} ${moment(this.initialPlanning?.meta?.date_of_modification).format('L')}`;
      },
      finalPlanningVersionName() {
        return this.finalPlanning?.meta?.version_name || `${this.$t('PLANNING_VERSIONS.VERSION_OF')} ${moment(this.finalPlanning?.meta?.date_of_modification).format('L')}`;
      },
      savedProjectVersions() {
        const savedVersions = this.projectVersionsList.filter(version => version.version_name && ! version.isLocked);
        savedVersions.sort((a, b) => moment(b?.date_of_modification) - moment(a?.date_of_modification));
        return [this.defaultProjectVersion, ...savedVersions];
      },
    },
    created() {
      this.finalProject = this.defaultProjectVersion;
      this.finalPlanning = this.planning;
    },
    methods: {
      loadFinalProjectVersion() {
        const versionId = this.finalProject?.id;
        if (! versionId) { // current version
          this.finalProject = this.defaultProjectVersion;
          this.finalPlanning = this.planning;
          return;
        }
        if (this.planningSavedVersionsCache[versionId]) { // final version cached
          this.finalPlanning = this.planningSavedVersionsCache[versionId];
          return;
        }
        this.dataLoaded.finalVersion = false;
        ProjectSrv.getProjectVersion(versionId).then((content) => {
          this.finalPlanning = new Planning(content);
          this.finalPlanning.meta.version_name = this.finalProject.version_name;
          this.planningSavedVersionsCache[versionId] = this.finalPlanning;
          this.dataLoaded.finalVersion = true;
        }).catch((message) => {
          this.dataLoaded.finalVersion = true;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Planning version was not loaded", body: message });
        });
      },
      users(item) {
        if (! item || ! item.getUsers()) return null;
        const users = {};
        item.getUsers().forEach((user) => {
          if (! user.id) return;
          users[user.id] = this.$store.getters['users/getUserById'](user.id) || user;
        });

        return Object.keys(users).map(userId => users[userId]).sort((a, b) => {
          const aUsername = this.$store.getters['users/getUsername'](a);
          const bUsername = this.$store.getters['users/getUsername'](b);
          return (aUsername.toLowerCase() < bUsername.toLowerCase()) ? -1 : 1;
        });
      },
      budget(item) {
        const elBudgets = item && item.getBudgets();
        if (! elBudgets) return null;
        if (! elBudgets.some(budget => budget.amount || budget.amount_inprogress)) return null;

        const budgetByIcons = {};
        (item.getBudgets() || []).forEach((budget) => {
          const icon = budget.icon || '';
          if (! budgetByIcons[icon]) budgetByIcons[icon] = { amount: 0, amount_inprogress: 0 };
          budgetByIcons[icon].amount += +budget.amount || 0;
          budgetByIcons[icon].amount_inprogress += +budget.amount_inprogress || 0;
        });
        return budgetByIcons;
      },
      elDefaultTitle(el) {
        if (el.isType('macro')) return this.$t('PLANNING.NEW_MACRO_BUBBLE');
        return el.isType('milestone') ? this.$t('PLANNING.MILESTONE') : this.$t('PLANNING.NEW_BUBBLE');
      },
      getItemText(item) {
        return `${moment(item.date_of_modification).format('L')} - ${item.version_name}`;
      },
    },
  };
</script>
