<template>
  <div>
    <b class="ml-1">{{ $t('WORKING_VIEWS.BUBBLES_COLORS') }}</b>
    <div v-for="colorIndex in [0, 1, 2, 3, 4, 5, 6, 7]" :key="colorIndex">
      <div class="d-flex py-2">
        <div :class="`el-color-${colorIndex}-`" :style="{ opacity: elementColorConfig(colorIndex, '') ? '' : '.2' }" class="views-config-color-square bg-el-color pointer"
             @click="selectElementColor(colorIndex, '')">
        </div>
        <div v-for="shadeIndex in [0, 1, 2, 3]" :class="`el-color-${colorIndex}-${shadeIndex}`"
             :style="{ opacity: elementColorConfig(colorIndex, shadeIndex) ? '' : '.2' }" class="views-config-color-square bg-el-color pointer"
             @click="selectElementColor(colorIndex, shadeIndex)">
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .views-config-color-square {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: 2px;
    border-radius: 50%;
  }
</style>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
      configElementsColors: { type: Array, default: () => [] },
    },
    emits: ['update:config-elements-colors'],
    methods: {
      selectColor(colors, colorIndex, shadeIndex) {
        const index = colors.findIndex(x => x.color === colorIndex && x.colorShade === shadeIndex);
        if (index > -1) {
          colors.splice(index, 1); // remove
        } else {
          colors.push({ // add
            color: colorIndex,
            colorShade: shadeIndex,
          });
        }
      },
      selectElementColor(colorIndex, shadeIndex) {
        const configElementsColors = this.configElementsColors.slice();
        this.selectColor(configElementsColors, colorIndex, shadeIndex);
        this.$emit('update:config-elements-colors', configElementsColors);
      },
      elementColorConfig(colorIndex, shadeIndex) {
        return this.configElementsColors.some(x => x.color === colorIndex && x.colorShade === shadeIndex);
      },
    },
  };
</script>
