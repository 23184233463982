<template>
  <v-app id="vue-app">
    <premium-suspended></premium-suspended>
    <alert-banners></alert-banners>
    <router-view name="navigation"></router-view>
    <v-main>
      <login-window-modal></login-window-modal>
      <on-boarding-welcome-modal v-if="$store.state.ui.displayOnBoardingWelcomeModal"></on-boarding-welcome-modal>
      <app-msg-boxes></app-msg-boxes>
      <navigation-fullscreen></navigation-fullscreen>
      <zoho-chat></zoho-chat>
      <v-container id="vue-main-container" fluid style="min-height: calc(100vh - var(--v-layout-top))">
        <router-view :key="$route.path"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
  .mobileview #vue-main-container {
    padding: 0 !important;
  }
</style>

<script>
  import { defineAsyncComponent } from 'vue';
  import clientConfig from '@/client_customs/config';
  import AlertBanners from '@/components/AlertBanners/AlertBanners.vue';
  import LoginWindowModal from '@/components/User/LoginWindowModal.vue';
  import PremiumSuspended from '@/components/Premium/PremiumSuspended.vue';
  import AppMsgBoxes from '@/components/MsgBox/AppMsgBoxes.vue';
  import NavigationFullscreen from '@/components/Navigation/NavigationFullscreen.vue';
  import ZohoChat from '@/components/Help/ZohoChat.vue';

  export default {
    components: {
      AlertBanners,
      LoginWindowModal,
      OnBoardingWelcomeModal: defineAsyncComponent(() => import('@/components/OnBoarding/OnBoardingWelcomeModal.vue')),
      PremiumSuspended,
      AppMsgBoxes,
      NavigationFullscreen,
      ZohoChat,
    },
    created() {
      if (clientConfig.startupScript) clientConfig.startupScript();

      this.$store.state.users.userPromise.then((user) => {
        if (this.$store.state.ui.displayOnBoardingWelcomeModal) return;
        // new version popup
        if (user.last_visit && moment(user.last_visit).isBefore('2024-09-24') && moment().isBefore('2024-11-25')) {
          this.$store.commit('ui/setDisplayNewVersionsWindow', true);
        }
      });
    },
  };
</script>
