<template>
  <div class="monitoringlane" style="page-break-inside: avoid">
    <div style="display: flex">
      <div style="font-weight: bold; width: 280px; flex-shrink: 0;">
        <div :style="{ 'margin-left': `${(level || 0) * 30}px` }" style="padding: 10px 0; height: 100%; display: flex; flex-direction: column;" class="monitoring-plan-cell">
          <span style="margin-left: 5px">{{ label }}</span>
          <div v-if="steps.length" class="mt-2" style="display: flex">
            <button type="button" :style="hasPrevStep ? {} : { color: 'gray', 'pointer-events': 'none' }" class="export-hidden" @click="toNextStep(-1)">
              <i class="fa fa-2x fa-caret-left"></i>
            </button>
            <date-field v-model="currentStepDate" :allowed-dates="allowedDates" input-class="my-0 mx-2 pa-0 text-center">
            </date-field>
            <button type="button" :style="hasNextStep ? {} : { color: 'gray', 'pointer-events': 'none' }" class="export-hidden" @click="toNextStep()">
              <i class="fa fa-2x fa-caret-right"></i>
            </button>
            <span style="flex-grow: 1"></span>
          </div>
          <div v-if="currentStep && currentStep.mood != 'none'" :class="`mood-icon-${currentStep.mood}`" class="mt-1" style="margin: auto; height: 60px"></div>
        </div>
      </div>
      <div class="monitoring-plan-cell" style="padding: 10px; flex-grow: 1">
        <div v-if="currentStep?.description">
          <b class="nobr">{{ $t('MONITORING_PLAN.WAYPOINT') }} :</b>
          <div class="title-underline"></div>
          <div v-html="currentStep.description"></div>
        </div>
      </div>
      <div class="monitoring-plan-cell" style="padding: 10px; flex-grow: 1">
        <div v-if="currentStep?.issues">
          <b class="nobr">{{ $t('MONITORING_PLAN.BLOCKING_POINTS') }} :</b>
          <div class="title-underline"></div>
          <div v-html="currentStep.issues"></div>
        </div>
      </div>
      <div class="monitoring-plan-cell" style="padding: 10px 0; display: flex; flex-direction: column; justify-content: flex-end;">
        <div v-if="canModifyMonitoringPlan" class="export-hidden">
          <button type="button" class="transparent-button" @click="editLane"><i class="far fa-pencil-alt" style="margin-right: 2px"></i></button>
        </div>
        <div>
          <button type="button" :class="{ 'export-invisible': ! comments.length }" class="transparent-button nobr" @click="showComments = ! showComments">
            <i class="fa fa-comment" style="margin-right: 2px"></i><span style="font-size: 14px">{{ comments.length || "+" }}</span>
          </button>
        </div>
      </div>
    </div>
    <v-expand-transition>
      <div v-if="showComments" style="display: flex; justify-content: flex-end;">
        <exchange-center :options="{ search: false, files: false }" :localcomments="comments" style="max-width: 500px; background: rgb(var(--v-theme-lightgrey)); padding: 8px"
                         @add="addComment" @update="updateComment" @delete="deleteComment">
        </exchange-center>
      </div>
    </v-expand-transition>
  </div>
</template>

<style scoped>
  .monitoringlane + .monitoringlane .monitoring-plan-cell {
    border-top: 1px solid #ddd;
  }
</style>

<script>
  import ExchangeCenter from '../ExchangeCenter/ExchangeCenter';

  function deepcopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  export default {
    components: {
      ExchangeCenter,
    },
    props: {
      monitoringLane: { type: Object, required: true },
      project_access_right: { type: String, default: 'consult' },
    },
    emits: ['open-edit-lane', 'update-comments'],
    data() {
      const steps = deepcopy(this.monitoringLane.steps || []);
      return {
        level: this.monitoringLane.level,
        label: this.monitoringLane.label,
        steps,
        currentStepIndex: steps.length - 1,
        comments: deepcopy(this.monitoringLane.comments || []),
        showComments: false,
      };
    },
    computed: {
      currentStep() {
        return this.steps[this.currentStepIndex];
      },
      currentStepDate: {
        get() { return this.currentStep.date; },
        set(newVal) {
          const index = this.steps.findIndex(item => moment(item.date).isSame(newVal, 'day'));
          if (index > -1) this.currentStepIndex = index;
        },
      },
      hasPrevStep() {
        return this.currentStepIndex >= 1;
      },
      hasNextStep() {
        return this.currentStepIndex < this.steps.length - 1;
      },
      canModifyMonitoringPlan() {
        return this.$store.state.users.accessRight.isAdmin || this.project_access_right != 'consult';
      },
    },
    watch: {
      monitoringLane: {
        handler(monitoringLane) {
          this.level = monitoringLane.level;
          this.label = monitoringLane.label;
          this.steps = deepcopy(monitoringLane.steps || []);
          if (this.currentStepIndex >= this.steps.length || ! this.currentStep && this.steps.length) this.currentStepIndex = this.steps.length - 1;
          this.comments = deepcopy(this.monitoringLane.comments || []);
        },
        deep: true,
      },
    },
    methods: {
      toNextStep(amount = 1) {
        this.currentStepIndex += amount;
      },
      allowedDates(date) {
        return this.steps.filter(item => moment(item.date).isSame(date, 'day')).length > 0;
      },
      editLane() {
        this.$emit('open-edit-lane', this.monitoringLane);
      },
      addComment(comment) {
        if (! this.comments.find(item => item.id == comment.id)) this.comments.push(comment);
        this.$emit('update-comments', this.comments);
      },
      updateComment(comment) {
        const index = this.comments.findIndex(item => item.id == comment.id);
        if (index > -1) this.comments.splice(index, 1, comment);
        this.$emit('update-comments', this.comments);
      },
      deleteComment(comment) {
        const index = this.comments.findIndex(item => item.id == comment.id);
        if (index > -1) this.comments.splice(index, 1);
        this.$emit('update-comments', this.comments);
      },
    },
  };
</script>
