<template>
  <v-dialog v-if="displayPremiumSuspended" :model-value="!! displayPremiumSuspended" width="100%" height="100%" persistent :opacity=".8">
    <v-card class="premium-subscription-scroll-container h-100 pa-6 bg-background light-scrollbar">
      <div v-if="! isTrial" class="text-errorred py-4 text-center">
        <span class="text-h6 text-uppercase font-weight-bold">{{ $t('PREMIUM.SUSPENDED.ACCOUNT_SUSPENDED') }}</span>
      </div>
      <premium-subscription hide-package-description></premium-subscription>
    </v-card>
  </v-dialog>
  <v-app-bar v-else-if="displayPremiumExpired" flat color="background">
    <v-alert type="error" prominent :height="60" :rounded="0" class="px-12 py-0 align-self-start">
      {{ $t('PREMIUM.SUSPENDED.ACCOUNT_EXPIRED_SINCE') }} {{ $filters.moment(premiumExpireDate, 'date') }}. {{ $t('PREMIUM.SUSPENDED.ACCOUNT_WILL_BE_SUSPENDED') }}
    </v-alert>
  </v-app-bar>
</template>

<script>
  import PremiumSubscription from './PremiumSubscription';

  export default {
    components: {
      PremiumSubscription,
    },
    data() {
      return {
        premiumExpireDate: null,
      };
    },
    computed: {
      displayPremiumSuspended() {
        return this.$store.state.ui.displayPremiumSuspended;
      },
      displayPremiumExpired() {
        return this.$store.state.ui.displayPremiumExpired;
      },
      isTrial() { return this.$store.state.users.accessRight.isTrial; },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        const { user } = this.$store.state.users;
        this.premiumExpireDate = user.company && user.company.premiumExpireDate;
      });
    },
  };
</script>
