<template>
  <div class="quill-editor mb-4">
    <div ref="editor" :style="`height: ${config.height}`"></div>
  </div>
</template>

<style lang="scss">
  .quill-editor {
    $fontSizeWhitelist: ("10", "12", "14", "18", "36", "48", "72"); // from Summernote
    @each $size in $fontSizeWhitelist {
      $fontSize: unquote($size + "px");
      .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="#{$fontSize}"]::before{
        content: $size;
      }
      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="#{$fontSize}"]::before {
        content: $size;
      }
    }

    .ql-snow .ql-picker.ql-size {
        width: 50px;
    }
  }
</style>

<script>
  import Quill from 'quill';
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';

  import { onMounted, onUnmounted, onBeforeUnmount, watch, ref } from 'vue';

  /**
   * Remove Quill classes and replace by inline styles
   * eg: .ql-align-right => style="text-align: right"
   * except only q-syntax for codeblock
   */
  const alignStyle = Quill.import('attributors/style/align');
  Quill.register(alignStyle, true);
  const backgroundStyle = Quill.import('attributors/style/background');
  Quill.register(backgroundStyle, true);
  const colorStyle = Quill.import('attributors/style/color');
  Quill.register(colorStyle, true);
  const fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = ['10px', '12px', '18px', '36px', '48px', '72px']; // from Summernote
  Quill.register(fontSizeStyle, true);
  // const fontStyle = Quill.import('attributors/style/font');
  // Quill.register(fontStyle, true);
  // const directionStyle = Quill.import('attributors/style/direction');
  // Quill.register(directionStyle, true);
  const Parchment = Quill.import('parchment');
  class IndentAttributor extends Parchment.Attributor.Style {
    add(node, value) {
      if (value === 0) {
        this.remove(node);
        return true;
      }
      return super.add(node, `${value}em`);
    }
  }
  const indentStyle = new IndentAttributor('indent', 'text-indent', {
    scope: Parchment.Scope.BLOCK,
    whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em'],
  });
  Quill.register(indentStyle, true);

  export default {
    props: {
      content: { type: String, default: '' },
      defaultContent: { type: String, default: "" },
      config: { type: Object, default: () => ({}) },
    },
    emits: ['ready', 'update:content'],
    setup(props, context) {
      // eslint-disable-next-line vue/no-setup-props-destructure
      const defaultOptions = {
        theme: 'snow',
        boundary: document.body,
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            // ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }, { size: fontSizeStyle.whitelist }],
            [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            // [{ script: 'sub' }, { script: 'super' }],
            // [{ direction: 'rtl' }],
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            // [{ font: [] }],
            ['clean'],
            ['link', 'image', 'video'],
          ],
        },
        ...props.config,
      };
      const state = {
        editorOption: {},
        quill: null,
      };

      const editor = ref(null);
      let initialContent = '';

      const hasContent = (html) => {
        return !! window.html2text(html || "").trim();
      };

      const cleanSummernoteHtml = (html) => {
        return html
          .replace(/<font [^color]*color="([^"]*)" [^style]*style="([^"]*)"[^>]*>(.*?)<\/font>/g, '<span style="color: $1; $2">$3</span>') // font with style
          .replace(/<font [^style]*style="([^"]*)" [^color]*color="([^"]*)"[^>]*>(.*?)<\/font>/g, '<span style="color: $2; $1">$3</span>') // font with style reversed
          .replace(/<font [^color]*color="([^"]*)"[^>]*>(.*?)<\/font>/g, '<span style="color: $1">$2</span>') // font without style
          .replace(/<strike( |>)/g, '<s$1') // replace strike tags
          .replace(/<\/strike( |>)/g, '</s$1');
      };

      watch(() => props.content, (val) => {
        if (state.quill) {
          if (props.defaultContent && ! hasContent(initialContent)) {
            initialContent = props.defaultContent;
            state.quill.pasteHTML(cleanSummernoteHtml(initialContent));
          } else if (val && val !== initialContent) {
            initialContent = val;
            state.quill.pasteHTML(cleanSummernoteHtml(initialContent));
          } else if (! val) {
            state.quill.setText('');
          }
        }
      });

      const initialize = () => {
        if (editor.value) {
          state.quill = new Quill(editor.value, defaultOptions);

          // Set editor content
          initialContent = props.content;
          if (props.defaultContent && ! hasContent(initialContent)) {
            initialContent = props.defaultContent;
          }
          state.quill.pasteHTML(cleanSummernoteHtml(initialContent));

          // Update model if text changes
          state.quill.on('text-change', () => {
            const html = editor.value.children[0].innerHTML;
            initialContent = hasContent(html) ? html : '';
            context.emit('update:content', initialContent);
          });

          // Emit ready event
          context.emit('ready', state.quill);
        }
      };

      onBeforeUnmount(() => {
        const editorToolbar = editor.value.previousSibling;
        if (editorToolbar && editorToolbar.className.indexOf('ql-toolbar') > -1) {
          editorToolbar.parentNode.removeChild(editorToolbar);
        }
      });

      onMounted(() => {
        initialize();
      });

      onUnmounted(() => {
        state.quill = null;
      });

      return { editor };
    },
  };
</script>
