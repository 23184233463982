<template>
  <div>
    <div v-show="openedTabIndex == 'share'">
      <div style="position: relative;">
        <manage-projects-project-acr :project="{ id: planning.id, access_right: planning.meta.access_right, owner_id: planning.meta.owner_id }"
                                     :shared-users-per-page="10" :link-to-manage="isPremium" hide-owner hide-notifications
                                     @close="close">
        </manage-projects-project-acr>
      </div>
      <v-card class="mt-4 pa-6 mb-2" style="max-width: 1000px;">
        <b>{{ $t('GLOBAL.OPTIONS') }}</b>
        <div class="pointer mt-2">
          <v-switch v-model="dragAndDropEnabled" :label="$t('CONFIG.LOCK_DRAG_DROP')"></v-switch>
        </div>
      </v-card>
    </div>

    <div v-if="hasReadOnlyLink && openedTabIndex == 'share-link'" style="max-width: 600px;">
      <b>{{ $t('SHARE.INVITE_CUSTOMERS') }}</b>
      <v-card class="mt-2 pa-6">
        <div>
          {{ $t('SHARE.INVITE_CUSTOMERS_DETAILS') }}
        </div>
        <div class="d-flex mt-2">
          <v-text-field id="inputReadOnlyLink"
                        :placeholder="$t('SHARE.PREMIUM_FEATURE')" :model-value="readonlyLink" variant="solo" readonly @focus="$event.target.select()">
            <template #append-inner>
              <v-tooltip v-if="isPremium" location="bottom">
                <template #activator="{ props }">
                  <v-btn-icon size="small" v-bind="props" @click="copyToClipboard()">
                    <v-icon v-show="! copyingToClipboard.success && ! copyingToClipboard.error" size="large" color="primary">far fa-clipboard</v-icon>
                    <v-icon v-show="copyingToClipboard.success" size="large" color="successgreen">far fa-check</v-icon>
                    <v-icon v-show="copyingToClipboard.error" size="large" color="errorred">far fa-exclamation-triangle</v-icon>
                  </v-btn-icon>
                </template>
                {{ copyingToClipboard.error ? $t('SHARE.COPY_FAILED') : $t('SHARE.COPY_CLIPBOARD') }}
              </v-tooltip>
              <v-btn v-else :href="$t('PREMIUM.OFFERS_LINK')" target="_blank" rel="noopener" color="successgreen" rounded size="small">
                {{ $t('SHARE.GO_PREMIUM') }}
              </v-btn>
            </template>
          </v-text-field>
        </div>
      </v-card>
      <div class="pt-6"></div>
      <b>{{ $t('GLOBAL.OPTIONS') }}</b>
      <v-card v-if="isPremium && isProjectAdmin" class="mt-2 pa-6">
        <div>
          <v-btn :disabled="resetLinkDisabled" rounded variant="outlined" class="my-1" @click="resetLink">{{ $t('SHARE.RESET_LINK') }}</v-btn>
        </div>
      </v-card>
    </div>

    <keep-alive>
      <views-manager v-if="hasViews && openedTabIndex == 'share-views'" :planning="planning"></views-manager>
    </keep-alive>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import ManageProjectsProjectAcr from '@/components/Manage/ManageProjects/ManageProjectsProjectAcr.vue';
  import ViewsManager from '@/components/ViewsManager/ViewsManager.vue';

  export default {
    components: {
      ManageProjectsProjectAcr,
      ViewsManager,
    },
    props: {
      planning: { type: Object, required: true },
      openedTabIndex: { type: String, required: true },
    },
    emits: ['close'],
    data() {
      return {
        copyingToClipboard: { success: false, error: false },
        resetLinkDisabled: false,
      };
    },
    computed: {
      dragAndDropEnabled: {
        get() {
          return ! this.planning.config.lockDragAndDrop;
        },
        set(newVal) {
          this.startChangingConfig();
          this.planning.config.lockDragAndDrop = ! newVal;
          this.changingConfig();
        },
      },
      readonlyLink() {
        if (! this.isPremium || ! this.planning.meta?.read_only_token) return '';
        let link = `${window.location.protocol}//${window.location.host}${window.location.pathname}#/viewer/${this.planning.id}?rotoken=${this.planning.meta.read_only_token}`;
        if (this.isKanbanMode) link += '&mode=kanban';
        return link;
      },
      hasReadOnlyLink() { return ! this.$store.getters['users/accessRight/config'].hideExtenalLink; },
      hasViews() { return this.$store.getters['users/accessRight/config'].hasViews; },
      isKanbanMode() { return this.$store.state.ui.planning.mode == 'kanban'; },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      isProjectAdmin() { return this.planning.meta.access_right == 'admin'; },
    },
    methods: {
      copyToClipboard() {
        const input = document.getElementById('inputReadOnlyLink');
        this.copyingToClipboard = { success: false, error: false };
        try {
          input.select();
          document.execCommand('selectAll');
          document.execCommand('copy');
          this.copyingToClipboard.success = true;
          setTimeout(() => { this.copyingToClipboard.success = false; }, 3000);
        } catch (e) {
          this.copyingToClipboard.error = true;
        }
      },
      resetLink() {
        this.resetLinkDisabled = true;
        this.$store.dispatch('ui/msgbox/open', {
          title: this.$t('SHARE.RESET_LINK'),
          body: this.$t('SHARE.RESET_LINK_CONFIRMATION'),
          buttons: { ok: this.$t('SHARE.RESET_LINK'), cancel: this.$t('GLOBAL.CANCEL') },
          type: 'vdialog',
        }).then(() => window.apiSrv.call(`sharedplannings/planning/${this.planning.id}/resettoken`).then((response) => {
          if (response && response.data && response.data.read_only_token && this.planning.meta) this.planning.meta.read_only_token = response.data.read_only_token;
        })).catch(() => {}).finally(() => {
          this.resetLinkDisabled = false;
        });
      },
      close() {
        this.$emit('close');
      },
      ...mapActions('planning/config', ['startChangingConfig', 'changingConfig']),
    },
  };
</script>
