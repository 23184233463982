<template>
  <msg-box content-class="bg-background" backdrop-close @close="close">
    <div v-if="! planning">
      <div class="d-flex align-center">
        <b class="text-h6 text-primary">{{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}</b>
      </div>
      <div class="text-center py-12"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
    </div>
    <project-sheet v-else :planning="planning" @update="updateProjectSheet" @reload-planning="loadPlanning()"></project-sheet>
  </msg-box>
</template>

<script>
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import Planning from '@/models/Planning';
  import ProjectSheet from '@/components/ProjectSheet/ProjectSheet';

  export default {
    components: {
      ProjectSheet,
    },
    props: {
      project: { type: Object, required: true },
      readonly: { type: Boolean, default: false },
    },
    emits: ['close'],
    data() {
      return {
        planning: null,
      };
    },
    watch: {
      project: {
        handler() {
          this.loadPlanning();
        },
        immediate: true,
      },
    },
    methods: {
      loadPlanning() {
        ProjectSrv.get(this.project.id).then((content) => {
          this.planning = new Planning(content);
          if (this.readonly) this.planning.meta.access_right = 'consult';
        });
      },
      updateProjectSheet(values) {
        _.extend(this.planning.projectsheet, values);
        this.planning.saveAdmin({ projectsheet: this.planning.projectsheet });
      },
      close() {
        if (this.planning) {
          this.project.category = this.planning.meta.category;
          this.project.imgurl = this.planning.meta.imgurl;
        }
        this.$emit('close');
      },
    },
  };
</script>
