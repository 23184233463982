<template>
  <v-row>
    <v-col md="6" cols="12">
      <div>
        <label for="openActionDescription"><i class="far fa-align-justify fa-fw mr-2"></i>Description</label>
        <v-textarea id="openActionDescription" v-model="actionDescription" placeholder="Description" class="pt-0" rows="2">
        </v-textarea>
      </div>
      <div class="mt-5">
        <label for="openActionOwner"><i class="far fa-user-tie fa-fw mr-2"></i>Commanditaire</label>
        <select-user id="openActionOwner" v-model="actionOwner" class="pt-0" @update:model-value="actionOwnerId = actionOwner && actionOwner.id"></select-user>
      </div>
    </v-col>
    <v-col md="6" cols="12">
      <label for="openActionStepDescription"><i class="far fa-flag fa-fw mr-2"></i>Point d'étape</label>
      <div style="display: flex; align-items: center">
        <date-field v-model="actionStepDate" label="Date" style="flex: 1 1 100%" clearable></date-field>
      </div>
      <v-textarea id="openActionStepDescription" v-model="actionStepDescription" label="État d'avancement" class="mt-3" rows="3">
      </v-textarea>
    </v-col>
    <v-col cols="12" class="mb-4">
      <label for="openActionComments"><i class="far fa-comments fa-fw mr-2"></i>Commentaires</label>
      <div class="mx-auto" style="width: 80%">
        <exchange-center :options="{ search: false, files: false }" :localcomments="actionComments"
                         @add="addComment" @update="updateComment" @delete="deleteComment">
        </exchange-center>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import ExchangeCenter from '@/components/ExchangeCenter/ExchangeCenter';

  export default {
    components: {
      ExchangeCenter,
    },
    props: {
      action: { type: Object, required: true },
    },
    data() {
      return {
        actionOwner: null,
        actionComments: angular.copy(this.action.getExtraField('comments') || []),
      };
    },
    computed: {
      actionDescription: {
        get() { return this.action.getExtraField('description'); },
        set(newVal) { this.action.setExtraField('description', newVal); },
      },
      actionOwnerId: {
        get() { return this.action.getExtraField('owner_id'); },
        set(newVal) { this.action.setExtraField('owner_id', newVal || 0); },
      },
      actionStepDate: {
        get() { return this.action.getExtraField('step_date'); },
        set(newVal) { this.action.setExtraField('step_date', newVal && newVal.format() || null); },
      },
      actionStepDescription: {
        get() { return this.action.getExtraField('step_description'); },
        set(newVal) { this.action.setExtraField('step_description', newVal); },
      },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        if (! this.actionOwner && this.action.getExtraField('owner_id')) this.actionOwner = this.$store.getters['users/getUsername']({ id: this.action.getExtraField('owner_id') });
      });
    },
    methods: {
      addComment(comment) {
        if (! this.actionComments.find(item => item.id == comment.id)) this.actionComments.push(comment);
        this.action.setExtraField('comments', this.actionComments);
      },
      updateComment(comment) {
        const index = this.actionComments.findIndex(item => item.id == comment.id);
        if (index > -1) this.actionComments.splice(index, 1, comment);
        this.action.setExtraField('comments', this.actionComments);
      },
      deleteComment(comment) {
        const index = this.actionComments.findIndex(item => item.id == comment.id);
        if (index > -1) this.actionComments.splice(index, 1);
        this.action.setExtraField('comments', this.actionComments);
      },
    },
  };
</script>
