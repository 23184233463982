<template>
  <div v-if="! hideManageAccount">
    <a :href="`https://bubbleplan.net/auth.php?token=${api_token}&redirect=${redirect}`" target="_blank" rel="noopener">
      <slot>{{ $t('ACCOUNT.MORE_OPTIONS_ON_WEBSITE') }}</slot>
    </a>
  </div>
</template>

<script>
  import clientConfig from '@/client_customs/config';

  export default {
    props: {
      section: { type: String, default: '' },
    },
    data() {
      return {
        api_token: window.apiSrv.getAuthToken(),
        hideManageAccount: clientConfig.hideManageAccount,
      };
    },
    computed: {
      redirect() {
        return this.section ? `mon-compte%23${this.section}` : 'mon-compte';
      },
    },
  };
</script>
