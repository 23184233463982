import { getDefaultMeta } from './helpers/defaultValues';

class PlanningMeta {
  constructor(srcData) {
    const data = angular.copy(srcData) || {};

    if (data instanceof PlanningMeta) {
      _.extend(this, data);
    } else {
      angular.merge(this, getDefaultMeta(), data);
    }
  }

  set(data) {
    Object.keys(data || {}).forEach((key) => {
      this[key] = data[key];
    });
  }
}

export default PlanningMeta;
