<template>
  <template v-if="planningDrawerTabIndex == 'element' && ! closeElement">
    <macro-element-details v-if="openedElement.isType('macro')" :element="openedElement" :options="options.elementDetails"
                           @close="closeElementDetails($event)">
    </macro-element-details>
    <element-details v-else :element="openedElement" :options="openedElementOptions" @close="closeElementDetails($event)"></element-details>
  </template>
  <template v-if="planningDrawerTabIndex == 'color-picker' && ! closeElement">
    <color-picker :target="openedColorPickerElement" :planning="planning" @close="closeColorPicker"></color-picker>
  </template>
  <template v-if="planningDrawerTabIndex == 'dependencies' && ! closeElement">
    <dependencies-manager :planning="planning" :element="openedDependenciesElement" class="pl-4 pb-4" @close="closeDependencies">
    </dependencies-manager>
  </template>
  <template v-if="planningDrawerTabIndex == 'meeting' && ! closeElement">
    <meeting-in-planning-window :element="openedMeetingElement" class="pl-4 pb-4" @close="closeMeeting"></meeting-in-planning-window>
  </template>

  <exchange-center v-if="planningDrawerTabIndex != '' && canModifyPlanning" v-show="planningDrawerTabIndex == 'exchanges'"
                   :options="{ planning_id: planning.id, autofocus: true }" :parentvisible="planningDrawerTabIndex == 'exchanges'"
                   class="pl-4 py-1">
  </exchange-center>
  <template v-if="planningDrawerTabIndex == 'actions' && canModifyPlanning">
    <planning-drawer-actions :planning="planning" class="pl-4 pt-2 pb-4" @open-element="openElement"></planning-drawer-actions>
  </template>
  <template v-if="planningDrawerTabIndex == 'config'">
    <config-window :planning="planning" class="px-4 pb-4" @close="closeConfig"></config-window>
  </template>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import MacroElementDetails from '@/components/ElementDetails/Macro/MacroElementDetails.vue';
  import ElementDetails from '@/components/ElementDetails/ElementDetails.vue';
  import ColorPicker from '@/components/ColorPicker/ColorPicker.vue';
  import DependenciesManager from '@/components/Dependencies/DependenciesManager.vue';
  import MeetingInPlanningWindow from '@/components/BubbleMeeting/MeetingInPlanningWindow.vue';
  import ConfigWindow from '@/components/Config/ConfigWindow.vue';
  import ExchangeCenter from '@/components/ExchangeCenter/ExchangeCenter.vue';
  import PlanningDrawerActions from './PlanningDrawerActions.vue';

  export default {
    components: {
      ElementDetails,
      MacroElementDetails,
      ColorPicker,
      DependenciesManager,
      MeetingInPlanningWindow,
      ExchangeCenter,
      PlanningDrawerActions,
      ConfigWindow,
    },
    props: {
      planning: { type: Object, required: true },
      options: { type: Object, default: () => ({}) },
      events: { type: Object, default: () => ({}) },
      canModifyPlanning: { type: Boolean, default: false },
    },
    data() {
      return {
        closeElement: false,
        unsubscribeAction: null,
      };
    },
    computed: {
      openedElementOptions() {
        // enable duplication for subproject elements
        const elOptions = this.openedElement?.dashboard_el?.project_id ? { canDuplicateEl: true } : null;
        return { ...this.options.elementDetails, ...elOptions };
      },
      ...mapState('ui/planning', ['planningDrawerTabIndex', 'openedElement', 'openedColorPickerElement', 'openedDependenciesElement', 'openedMeetingElement']),

    },
    watch: {
      planningDrawerTabIndex(newVal, oldVal) {
        if (newVal == 'exchanges') {
          setTimeout(() => {
            $(".chat-content").each(function () { $(this).scrollTop(this.scrollHeight); });
          });
        }
        if (! newVal) {
          this.closeWindow(oldVal);
        }
      },
      openedElement: 'onOpenedElementChange',
      openedColorPickerElement: 'onOpenedElementChange',
      openedDependenciesElement: 'onOpenedElementChange',
      openedMeetingElement: 'onOpenedElementChange',
    },
    created() {
      this.unsubscribeAction = this.$store.subscribeAction((action) => {
        if (action.type != 'planning/elements/startChangingElement') return;
        if (action.payload?.doNotClosePlanningDrawer) return;
        // save opened window when opening a new window
        this.closeWindow(this.planningDrawerTabIndex);
      });
    },
    beforeUnmount() {
      this.unsubscribeAction();
      if (this.planningDrawerTabIndex) this.closeWindow(this.planningDrawerTabIndex); // save and close before leaving the page
    },
    methods: {
      onOpenedElementChange(newVal, oldVal) {
        if (newVal) this.$store.dispatch('ui/planning/scrollToEl', { el: { id: newVal.dashboard_el?.id || newVal.id } });
        if (newVal && oldVal) {
          this.closeElement = true;
          this.$nextTick(() => { // close element details and recreate it from scratch
            this.closeElement = false;
          });
        }
        if (newVal) {
          setTimeout(() => {
            const planningDrawerScrollContainer = window.document.getElementById('planning-drawer-scroll-container');
            if (planningDrawerScrollContainer) planningDrawerScrollContainer.scrollTop = 0;
          }, 0);
        }
      },
      closeWindow(tabIndex) {
        // triggers saving
        if (tabIndex == 'element') this.closeElementDetails();
        else if (tabIndex == 'color-picker') this.closeColorPicker();
        else if (tabIndex == 'dependencies') this.closeDependencies();
        else if (tabIndex == 'meeting') this.closeMeeting();
      },
      closeElementDetails(reason) {
        if (this.events.closeElementDetails) {
          this.events.closeElementDetails(reason);
          return;
        }
        this.$store.dispatch('ui/planning/closeElementDetails', reason);
      },
      closeColorPicker() {
        if (this.events.closeColorPicker) {
          this.events.closeColorPicker();
          return;
        }
        this.$store.dispatch('ui/planning/closeColorPicker');
      },
      openElement(el) {
        this.$store.dispatch('ui/planning/scrollToEl', { el, open: this.canModifyPlanning });
      },
      ...mapActions('ui/planning', ['closeDependencies', 'closeMeeting', 'closeConfig']),
    },
  };
</script>
