<template>
  <div>
    <b class="ml-2">{{ $t('WORKING_VIEWS.ICONS_TITLE') }}</b>
    <div class="mt-1"></div>
    <div :style="{ opacity: elementIconConfig('') ? '' : '.2' }" class="pointer ml-2 mb-1" style="padding: 3px;"
         @click="selectElementIcon('')">
      {{ $t('WORKING_VIEWS.WITHOUT_ICON') }}
    </div>
    <v-row class="ma-0" style="max-width: 268px">
      <v-col v-for="iconCode in displayIconCodes" :key="iconCode" :cols="colorPickerIconCol" :style="{ opacity: elementIconConfig(iconCode) ? '' : '.2' }"
             class="text-center" style="padding: 3px 3px 10px; width: 16.666666%; overflow: hidden">
        <v-icon :color="icons[iconCode].color" class="pointer" @click="selectElementIcon(iconCode)">{{ icons[iconCode].name }}</v-icon>
      </v-col>
    </v-row>
    <v-pagination v-if="iconsTotalPages > 1" v-model="iconsCurrentPage" class="pt-2" :length="iconsTotalPages"></v-pagination>
  </div>
</template>

<script>
  import clientConfig from '@/client_customs/config';

  export default {
    props: {
      planning: { type: Object, required: true },
      configElementsIcons: { type: Array, default: () => [] },
    },
    emits: ['update:config-elements-icons'],
    data() {
      return {
        iconsCurrentPage: 1,
        iconsPerPage: clientConfig.icons?.iconsPerPage || 36,
        colorPickerIconCol: clientConfig.icons?.colorPickerIconCol || 2,
      };
    },
    computed: {
      icons() {
        return this.planning.config.icons;
      },
      iconsTotalPages() {
        return Math.ceil(Object.keys(this.icons).length / this.iconsPerPage);
      },
      displayIconCodes() {
        return Object.keys(this.icons).slice(this.iconsPerPage * (this.iconsCurrentPage - 1), this.iconsPerPage * this.iconsCurrentPage);
      },
    },
    methods: {
      selectElementIcon(iconCode) {
        const configElementsIcons = this.configElementsIcons.slice();
        const index = configElementsIcons.indexOf(iconCode);
        if (index > -1) {
          configElementsIcons.splice(index, 1); // remove
        } else {
          configElementsIcons.push(iconCode); // add
        }
        this.$emit('update:config-elements-icons', configElementsIcons);
      },
      elementIconConfig(iconCode) {
        return this.configElementsIcons.includes(iconCode);
      },
    },
  };
</script>
