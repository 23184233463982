<template>
  <v-list-item :density="dense ? 'compact' : null" @click="toggle">
    <template #prepend>
      <div :class="{ 'ml-7': indent }">
        <v-checkbox-btn :model-value="selectedSublistIds.length == sublistIds.length" :indeterminate="indeterminate">
        </v-checkbox-btn>
      </div>
    </template>
    <v-list-item-title><slot name="default"></slot></v-list-item-title>
  </v-list-item>
</template>

<script>
  export default {
    props: {
      selectedIds: { type: Array, required: true },
      sublistIds: { type: Array, required: true },
      indent: { type: Boolean, default: false },
      dense: { type: Boolean, default: false },
    },
    emits: ['update'],
    computed: {
      selectedSublistIds() {
        return _.intersection(this.selectedIds, this.sublistIds);
      },
      indeterminate() {
        return !! (this.selectedSublistIds.length && this.selectedSublistIds.length < this.sublistIds.length);
      },
    },
    methods: {
      toggle() {
        if (this.selectedSublistIds.length == this.sublistIds.length) {
          this.$emit('update', _.without(this.selectedIds, ...this.sublistIds));
        } else {
          this.$emit('update', _.union(this.selectedIds, this.sublistIds));
        }
      },
    },
  };
</script>
