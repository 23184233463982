<template>
  <component :is="slider ? 'VSlideGroup' : 'span'" v-if="category && categoryTags.length" :class="{ 'align-center': slider }" @click.stop.prevent>
    <v-chip v-for="tag in categoryTags" :key="tag" :title="tag" size="small"
            :class="{ pointer: pointer }" :style="{ 'background-color': categoryTagsColor[tag].bg, color: categoryTagsColor[tag].color }"
            class="mr-1">
      <span class="text-ellipsis">{{ tag }}</span>
    </v-chip>
  </component>
</template>

<script>
  import { VSlideGroup } from 'vuetify/components';

  export default {
    components: {
      VSlideGroup,
    },
    props: {
      category: { type: String, default: '' },
      slider: { type: Boolean, default: false },
      pointer: { type: Boolean, default: false },
    },
    computed: {
      categoryTags() {
        return this.categories2tags([this.category]);
      },
      categoryTagsColor() {
        return this.categoryTags.reduce((acc, tag) => {
          const tagColor = this.$store.getters['users/accessRight/config'].getCategoryColor(tag);
          acc[tag] = { bg: `#${tagColor}`, color: window.contrastedTextColor(tagColor) };
          return acc;
        }, {});
      },
    },
    methods: {
      categories2tags(categories) {
        const tags = new Set();
        categories.forEach((item) => {
          (item || '').split(',').forEach((subitem) => {
            const tag = subitem && subitem.trim();
            if (tag) tags.add(tag);
          });
        });
        return [...tags];
      },
    },
  };
</script>
