<template>
  <textarea v-model="val" :style="computedStyles"
            @focus="resize">
  </textarea>
</template>
<script>
  export default {
    props: {
      modelValue: { type: [String, Number], default: '' },
      autosize: { type: Boolean, default: true },
      minHeight: { type: [Number], default: null },
      maxHeight: { type: [Number], default: null },
    },
    emits: ['update:modelValue'],
    data() {
      return {
        // data property for v-model binding with real textarea tag
        val: null,
        // works when content height becomes more then value of the maxHeight property
        maxHeightScroll: false,
      };
    },
    computed: {
      computedStyles() {
        const objStyles = {};
        if (this.autosize) {
          objStyles.resize = 'none';
          if (! this.maxHeightScroll) {
            objStyles.overflow = 'hidden';
          }
        }
        return objStyles;
      },
    },
    watch: {
      modelValue() {
        this.val = this.modelValue;
      },
      val(newVal) {
        this.$nextTick(this.resize);
        this.$emit('update:modelValue', newVal);
      },
    },
    created() {
      this.val = this.modelValue; // fill val with initial modelValue passed via the same name prop
    },
    mounted() {
      this.resize(); // perform initial height adjustment
    },
    methods: {
      // Auto resize textarea by height
      resize() {
        this.$el.style.setProperty('height', 'auto');
        let contentHeight = this.$el.scrollHeight + 1;
        if (this.minHeight) {
          contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight;
        }
        if (this.maxHeight) {
          if (contentHeight > this.maxHeight) {
            contentHeight = this.maxHeight;
            this.maxHeightScroll = true;
          } else {
            this.maxHeightScroll = false;
          }
        }
        const heightVal = `${contentHeight}px`;
        this.$el.style.setProperty('height', heightVal);
        return this;
      },
    },
  };
</script>
