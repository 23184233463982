import i18n from '@/js/plugins/i18n';

const availableLanguages = ['en', 'fr'];

function getFirstBrowserLanguage() {
  const nav = window.navigator;
  const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
  // support for HTML 5.1 "navigator.languages"
  if (_.isArray(nav.languages)) {
    for (let i = 0; i < nav.languages.length; i++) {
      const language = nav.languages[i];
      if (language && language.length) {
        return language;
      }
    }
  }
  // support for other well known properties in browsers
  for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
    const language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      return language;
    }
  }
  return null;
}

function setLang(state, lang) {
  state.lang = lang;
  state.i18n.global.locale = lang;
  moment.locale(lang);
  window.localStorageWrapper.setItem('lang', lang);
  document.querySelector('html').setAttribute('lang', lang);
}

function loadLang({ commit }, langParam = 'en') {
  const lang = availableLanguages.includes(langParam) ? langParam : 'en';
  commit('setLang', lang);
  return Promise.resolve(lang);
}

function loadLocale({ dispatch }, langParam = window.localStorageWrapper.getItem('lang')) {
  let lang = availableLanguages.includes(langParam) ? langParam : '';
  if (! lang) {
    const browserLanguage = (getFirstBrowserLanguage() || '').slice(0, 2);
    lang = availableLanguages.includes(browserLanguage) ? browserLanguage : '';
  }
  lang = lang || 'en';
  dispatch('loadLang', lang);
}

export default {
  namespaced: true,
  state: {
    i18n,
    lang: i18n.global.locale,
  },
  mutations: {
    setLang,
  },
  actions: {
    loadLang,
    loadLocale,
  },
};
