<template>
  <v-card-clickable class="project-preview d-flex flex-column">
    <div class="project-preview-menubar bg-lightgrey nobr" @click.prevent>
      <project-category :category="project.category" slider class="mx-1"></project-category>
      <v-spacer></v-spacer>
      <slot name="notifications"></slot>
      <slot name="menu"></slot>
    </div>
    <div class="project-preview-thumbnailbox">
      <img v-if="thumbnail" :src="thumbnailUrl" :class="{ 'project-preview-snapshot-thumbnail': thumbnailIsProjectSnapshot }" @error="thumbnailUrl = defaultBg">
    </div>
    <div class="project-preview-textbox">
      <div class="font-weight-bold">{{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}</div>
      <slot name="body-details"></slot>
    </div>
  </v-card-clickable>
</template>

<style lang="scss">
  .project-preview {
    height: 100%;
  }

  .project-preview-thumbnailbox {
    height: 138px;
    flex-grow: 1;
    text-align: center;
  }

  .project-preview-snapshot-thumbnail {
    transform: scale(0.2);
    transform-origin: 0 0;
    min-width: 500%;
  }

  .project-preview-menubar {
    display: flex;
    cursor: default;
  }

  .project-preview-textbox {
    position: relative;
    padding: 8px;
    background: white;
  }
</style>

<script>
  import ProjectCategory from './ProjectCategory';

  import defaultBg from '@/img/view_bg.jpg';

  export default {
    components: {
      ProjectCategory,
    },
    props: {
      project: { type: Object, required: true },
      thumbnail: { type: String, default: '' },
    },
    data() {
      return {
        thumbnailUrl: this.thumbnail,
        defaultBg,
      };
    },
    computed: {
      thumbnailIsProjectSnapshot() {
        return this.thumbnailUrl.startsWith(window.apiSrv.snapBaseUrl) || this.thumbnailUrl == defaultBg;
      },
    },
    watch: {
      thumbnail() {
        this.thumbnailUrl = this.thumbnail;
      },
    },
  };
</script>
