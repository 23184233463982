<template>
  <div v-if="col" class="flex-center-center">
    <v-icon v-if="colIcon" :color="colIcon.color" :title="colIcon.label || colIconDefaultLabel(col)"
            size="14" class="mr-1" style="min-width: 14px">
      {{ colIcon.name }}
    </v-icon>
    <div :title="col.label" class="text-ellipsis">{{ col.label }}</div>
  </div>
</template>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
      el: { type: Object, required: true },
    },
    computed: {
      processSteps() {
        if (this.el.project_id && this.el.project_id != this.planning.id) { // planning users when elements are attached to fabricated planning
          const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](this.el.project_id);
          return originalPlanning?.process.steps || [];
        }
        return this.planning.process.steps;
      },
      col() {
        const elProcessStep = this.el.getProcessStep();
        return this.processSteps.find(step => step.id === elProcessStep);
      },
      colIcon() {
        return this.col.hasIcon() && this.col.getIcon();
      },
    },
    methods: {
      colIconDefaultLabel(col) {
        return this.$t(`ICONS.${col.getIconId().toUpperCase()}`);
      },
    },
  };
</script>
