<template>
  <div>
    <div v-selectable-area="{ disabled: ! dynamic, filter: '.element.selectable', start: selectableStart, selected: selectableSelected }" class="table-body notextselect"
         @click="clickOnPlanning(); resetSelection()">
      <template v-for="(lane, laneIndex) in visibleLanes" :key="lane.id">
        <planning-subproject-view v-if="lane.project_id && ! lane.hidden" :id="`lane${lane.id}`" :dynamic="dynamic" :view="view"
                                  :class="laneIndex % 2 ? 'bg-white' : 'bg-verylightgrey'"
                                  :planning="planning" :lane="lane">
        </planning-subproject-view>
        <planning-lane-view v-else :id="`lane${lane.id}`" :dynamic="dynamic" :view="view"
                            :class="laneIndex % 2 ? 'bg-white' : 'bg-verylightgrey'"
                            :planning="planning" :lane="lane" :options="options" :events="events"
                            @open-dependencies="openDependencies" @open-meeting="openMeeting">
        </planning-lane-view>
      </template>
      <planning-timeline-grid :planning="planning"></planning-timeline-grid>
      <dependencies-svg v-if="! isKanbanMode" :planning="planning"></dependencies-svg>
    </div>

    <!-- MODALS -->
    <client-custom-component v-for="compName in clientCustomComponentsList" :key="compName" :name="compName"></client-custom-component>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import clientConfig from '@/client_customs/config';
  import DependenciesSvg from '@/components/Dependencies/DependenciesSvg.vue';
  import PlanningLaneView from './PlanningLaneView.vue';
  import PlanningSubprojectView from './PlanningSubprojectView.vue';
  import PlanningTimelineGrid from './PlanningTimelineGrid.vue';
  import selectable from './mixins/selectable';

  export default {
    components: {
      PlanningLaneView,
      PlanningSubprojectView,
      PlanningTimelineGrid,
      DependenciesSvg,
    },
    mixins: [selectable],
    props: {
      planning: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      options: { type: Object, default: () => ({}) },
      events: { type: Object, default: () => ({}) },
    },
    data() {
      return {
        clientCustomComponentsList: clientConfig.pool && clientConfig.pool.customComponentsList,
      };
    },
    computed: {
      visibleLanes() {
        return this.$store.getters['planning/lanes/getVisibleLanes']({ planning: this.planning });
      },
      isKanbanMode() {
        return this.$store.state.ui.planning.mode == 'kanban';
      },
    },
    mounted() {
      if (this.dynamic && ! this.view) {
        window.Mousetrap.bind('mod+d', (event) => { event.preventDefault(); this.$store.dispatch('selection/duplicateSelection'); });
        window.Mousetrap.bind(['del', 'backspace'], (event) => { event.preventDefault(); this.$store.dispatch('selection/deleteSelection'); });
        window.Mousetrap.bind('esc', (event) => { event.preventDefault(); this.resetSelection(); });
        window.Mousetrap.bind('mod+s', (event) => { event.preventDefault(); this.$store.dispatch('planning/save'); });
      }
      window.Mousetrap.bind('left', (event) => { event.preventDefault(); this.scrollRight(-20); });
      window.Mousetrap.bind('right', (event) => { event.preventDefault(); this.scrollRight(20); });
    },
    unmounted() {
      const hotkeys = ['mod+d', ['del', 'backspace'], 'esc', 'mod+s', 'left', 'right'];
      for (let i = 0; i < hotkeys.length; i++) {
        window.Mousetrap.unbind(hotkeys[i]);
      }
    },
    methods: {
      resetSelection(data) {
        if (! this.dynamic) return;
        this.$store.dispatch('selection/resetSelection', data);
      },
      scrollRight(howmuch) {
        if (! parseInt(howmuch, 10)) return;
        const $tableWrapper = $("#table-wrapper");
        $tableWrapper.scrollLeft($tableWrapper.scrollLeft() + howmuch);
      },
      clickOnPlanning() {
        this.$store.state.ui.planning.clickOnPlanning = Date.now();
      },
      ...mapActions('ui/planning', ['openDependencies', 'openMeeting']),
    },
  };
</script>
