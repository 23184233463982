<template>
  <v-tooltip :disabled="dropdownIsOpen" location="bottom">
    <template #activator="{ props: onTooltip }">
      <v-menu v-model="dropdownIsOpen" :close-on-content-click="false" location="start center">
        <template #activator="{ props: onMenu }">
          <v-btn-icon :disabled="disabled" class="tour-navigate-timeline" size="small" v-bind="mergeProps(onMenu, onTooltip)">
            <v-icon size="large">far fa-search</v-icon>
          </v-btn-icon>
        </template>
        <v-list class="pt-4 pb-8">
          <v-list-item class="d-block">
            <b>{{ $t('NAV.NAVIGATE_TIMELINE') }}</b>
            <v-select v-model="selectedCol" :items="visibleTimelineCols" :item-title="(col) => col.label"
                      :placeholder="$t('NAV.GO_TO_DATE')" :menu-props="{ location: 'bottom' }" return-object density="compact" class="pt-0"
                      @update:model-value="gotoDate()">
            </v-select>
          </v-list-item>
          <v-divider class="my-4"></v-divider>
          <v-list-item class="d-block">
            <b>{{ $t('NAV.LOOK_FOR_BUBBLE') }}</b>
            <v-autocomplete v-model="selectedElement" :items="planning.elements" :item-title="(el) => el.getTitle() || defaultTitle(el)" item-value="id"
                            :placeholder="`${$t('GLOBAL.SEARCH')}...`" return-object density="compact" autofocus class="pt-0"
                            @update:model-value="openSelectedElement()">
              <template #item="{ props, item: { raw: item } }">
                <v-divider v-if="item.divider"></v-divider>
                <v-list-subheader v-else-if="item.header" :title="item.header"></v-list-subheader>
                <v-list-item v-else v-bind="props">
                  <template #title>
                    <span class="text-truncate" style="max-width: 200px">
                      {{ item.getTitle() || defaultTitle(item) }}
                    </span>
                    <small v-if="item.getStartTime()" class="ml-2 text-medium-emphasis">
                      {{ $filters.moment(item.getStartTime(), 'mediumDateNoYear', planningLocale) }}
                    </small>
                    <small v-if="! item.isType('milestone') && item.getEndTime()" class="text-medium-emphasis">
                      &nbsp;- {{ $filters.moment(item.getEndTime(), 'mediumDateNoYear', planningLocale) }}
                    </small>
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <span>{{ $t('NAV.SEARCH_ON_PLANNING') }}</span>
  </v-tooltip>
</template>

<script>
  import { mergeProps } from 'vue';

  export default {
    props: {
      planning: { type: Object, required: true },
      disabled: { type: Boolean, default: false },
    },
    emits: ['open-element'],
    data() {
      return {
        selectedCol: null,
        selectedElement: null,
        dropdownIsOpen: null,
      };
    },
    computed: {
      visibleTimelineCols() {
        return this.planning.visibleTimeline && this.planning.visibleTimeline.timelinecols || null;
      },
      planningLocale() {
        return this.planning.timeline.getTimelocale();
      },
    },
    methods: {
      gotoDate() {
        const col = this.selectedCol;
        const { visibleTimeline } = this.planning;
        if (! col || ! visibleTimeline) return;
        const duration = moment(col.starttime).diffWithWorkdays(visibleTimeline.starttime, 'minutes', visibleTimeline.workdays);
        $("#table-wrapper").scrollLeft(duration / visibleTimeline.minuteperpx - 200);
        this.dropdownIsOpen = false;
        this.$nextTick(() => {
          this.selectedCol = null;
        });
      },
      openSelectedElement() {
        this.$emit('open-element', this.selectedElement);
        this.dropdownIsOpen = false;
        this.$nextTick(() => {
          this.selectedElement = null;
        });
      },
      defaultTitle(el) {
        if (el.isType('macro')) return this.$t('PLANNING.NEW_MACRO_BUBBLE');
        return el.isType('milestone') ? this.$t('PLANNING.MILESTONE') : this.$t('PLANNING.NEW_BUBBLE');
      },
      mergeProps,
    },
  };
</script>
