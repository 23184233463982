<template>
  <v-avatar v-if="user.id && ! avatar && initialsAvatar" :size="size" :color="initialsAvatar.color" :title="tooltipText" v-bind="$attrs">
    <span class="notextselect" :style="initialsAvatar.style">{{ initialsAvatar.text }}</span>
  </v-avatar>
  <v-avatar v-else-if="user.id" :size="size" :title="tooltipText" class="color-inherit" v-bind="$attrs">
    <img v-if="avatar" :src="avatar" class="notextselect" style="height: 100%">
    <i v-else :title="! tooltip && deletedUserText || null" :style="iconSizeStyle" class="far fa-user-slash"></i>
  </v-avatar>
  <v-avatar v-else-if="user.group_id" :size="size" :title="tooltipText" class="color-inherit" v-bind="$attrs">
    <i v-if="group" :style="iconSizeStyle" class="far fa-users"></i>
    <i v-else :title="! tooltip && deletedUserText || null" :style="iconSizeStyle" class="far fa-users-slash"></i>
  </v-avatar>
  <v-avatar v-else-if="user.username" :size="size" :title="tooltipText" class="color-inherit" v-bind="$attrs">
    <i :style="iconSizeStyle" :class="virtualParticipantIcon"></i>
  </v-avatar>

  <span v-if="showUsername" class="user-avatar-username">{{ username }}</span>
</template>

<style>
  .user-avatar-username {
    vertical-align: middle;
    white-space: nowrap;
    line-height: 1;
  }
</style>

<script>
  import { mergeProps } from 'vue';
  import clientConfig from '@/client_customs/config';

  export default {
    inheritAttrs: false,
    props: {
      user: { type: Object, required: true },
      showUsername: { type: Boolean, default: false },
      usernameFormat: { type: String, default: null },
      tooltip: { type: Boolean, default: false },
      size: { type: [Number, String], default: 20 },
    },
    data() {
      return {
        virtualParticipantIcon: clientConfig.virtualParticipantIcon?.[this.user.username] || 'far fa-bullseye',
      };
    },
    computed: {
      organizationUser() {
        const storeUsers = this.$store.state.users;
        const isOrganizationLoaded = storeUsers.user.id && (! storeUsers.accessRight.hasOrganization || storeUsers.user.organization);
        if (isOrganizationLoaded) return this.$store.getters['users/getUserById'](this.user.id);
        return this.user.id ? this.user : null; // use local data while loading or when not connected
      },
      avatar() {
        if (! this.organizationUser?.avatar || this.organizationUser.avatar.match(/^https:\/\/secure\.gravatar\.com\/avatar\/.*\?d=mm$/)) return null; // no avatar or gravatar url with default
        return this.organizationUser.avatar;
      },
      group() {
        if (this.$store.state.users.groups.loaded) return this.$store.getters['users/groups/getGroupById'](this.user.group_id);
        return this.user.group_id ? this.user : null; // use local data while loading or when not connected
      },
      deletedUserText() {
        if (this.user.id && ! this.organizationUser) return this.$t('MULTIPROJECTS.DELETED_USER', 1);
        if (! this.user.id && this.user.group_id && ! this.group) return this.$t('MULTIPROJECTS.DELETED_GROUP');
        return '';
      },
      username() {
        if (this.organizationUser) return this.$filters.username(this.organizationUser, this.usernameFormat);
        return this.group?.title || this.$filters.username(this.user, this.usernameFormat);
      },
      fullUsername() { // ensure color consistancy for initialsAvatar (independent from username format)
        if (this.organizationUser) return this.$filters.username(this.organizationUser);
        return this.group?.title || this.$filters.username(this.user);
      },
      initialsAvatar() {
        if (! this.organizationUser) return null; // deleted user
        return {
          text: this.username.split(' ').map(word => word[0]).join('').toUpperCase(),
          color: `#${window.str2color(this.fullUsername)}`,
          style: this.size < 30 ? { 'font-size': '12px' } : { 'font-size': '1rem', 'line-height': 1.5 },
        };
      },
      tooltipText() {
        if (! this.tooltip) return null;
        return this.deletedUserText ? `${this.deletedUserText} : ${this.username}` : this.username;
      },
      iconSizeStyle() {
        const iconSize = 16 + (+this.size - 20) / 3; // 20 -> 16, 32 -> 20
        return { 'font-size': `${iconSize}px` };
      },
    },
    methods: {
      mergeProps,
    },
  };
</script>
