import PlanningElement from '@/models/PlanningElement';
import Action from '@/models/Action';
import planningsModule from './plannings';
import processModule from './process';

export function getProjectIdsByCategory(projects) {
  return projects.reduce((acc, project) => {
    if (project.meta?.category) {
      project.meta.category.split(',').forEach((tag) => {
        if (! acc[tag]) acc[tag] = [];
        acc[tag].push(project.id);
      });
    }
    return acc;
  }, {});
}

let loadPlanningsPromise;
let loadPlanningsPromiseResolver;
const initLoadPlanningsPromise = () => {
  loadPlanningsPromise = new Promise((resolve) => { // will return loaded plannings
    loadPlanningsPromiseResolver = resolve;
  });
};

async function load({ state, rootState, dispatch, rootGetters }) {
  state.plannings.loadingCounter = { ...state.plannings.loadingCounter, toLoad: 0, loaded: 0 };
  state.loading.counter = state.plannings.loadingCounter;
  state.loading.complete = false;
  await new Promise(resolve => setTimeout(resolve)); // refresh ui
  await rootGetters['users/getOrganizationPromise'];
  state.companyUsers = angular.copy(rootGetters['users/getOrganizationUsers']);
  state.companyUsers.forEach((user) => {
    user.username = rootGetters['users/getUsername'](user);
  });
  if (state.selectedCompanyUsers) {
    state.selectedCompanyUsers = _.intersection(state.selectedCompanyUsers, state.companyUsers.map(item => item.id)); // handle deleted
  } else {
    state.selectedCompanyUsers = state.companyUsers.map(item => item.id);
  }
  await rootGetters['users/getGroupsPromise'];
  state.companyGroups = rootState.users.groups.companyGroups;
  if (state.selectedCompanyGroups) {
    state.selectedCompanyGroups = _.intersection(state.selectedCompanyGroups, state.companyGroups.map(item => item.id)); // handle deleted
  } else {
    state.selectedCompanyGroups = state.companyGroups.map(item => item.id);
  }

  return dispatch('multiprojects/plannings/loadAllPlannings', undefined, { root: true }).then((plannings) => {
    if (loadPlanningsPromiseResolver) loadPlanningsPromiseResolver(plannings);
    return new Promise(resolve => setTimeout(() => resolve(plannings), 300)); // refresh ui
  }).then((plannings) => {
    if (state.selectedProjects) {
      state.selectedProjects = _.intersection(state.selectedProjects, plannings.map(item => item.id).filter(item => item)); // handle deleted or loading failure
    } else {
      state.selectedProjects = plannings.map(item => item.id).filter(item => item);
    }
    state.projects = plannings; // ref binding to store plannings // warning : not reactive if plannings refs change

    plannings.forEach((planning) => {
      if (! planning || ! planning.elements) return;
      planning.elements.forEach((el) => {
        if (! el) return;

        (el.getUsers() || []).forEach((user) => {
          if (user.group_id) return;
          const userId = user.id;
          if (! userId) { // virtual participant
            const username = rootGetters['users/getUsername'](user);
            if (! username || state.virtualParticipants.find(item => item.username == username)) return;
            state.virtualParticipants.push({ username });
            return;
          }

          if (! rootGetters['users/getUserById'](userId) && ! state.deletedUsers.find(item => item.id == userId)) { // deleted user
            const username = rootGetters['users/getUsername'](user, 'short');
            state.deletedUsers.push({
              id: userId,
              username: `${rootState.lang.i18n.global.t('MULTIPROJECTS.DELETED_USER')} ${username ? `(${username})` : state.deletedUsers.length + 1}`,
              isDeleted: true,
            });
          }
        });

        (el.getChecklist() || []).forEach((checklistItem) => {
          if (checklistItem.group_id) return;
          const { user_id: userId, username } = checklistItem;
          if (! userId) { // virtual participant
            if (! username || state.actionVirtualParticipants.find(item => item.username == username)) return;
            state.actionVirtualParticipants.push({ username });
            return;
          }

          if (! rootGetters['users/getUserById'](userId) && ! state.actionDeletedUsers.find(item => item.id == userId)) { // deleted user
            state.actionDeletedUsers.push({
              id: userId,
              username: `${rootState.lang.i18n.global.t('MULTIPROJECTS.DELETED_USER')} ${username ? `(${username})` : state.actionDeletedUsers.length + 1}`,
              isDeleted: true,
            });
          }
        });
      });
    });
    if (state.selectedVirtualParticipants) {
      state.selectedVirtualParticipants = _.intersection(state.selectedVirtualParticipants, state.virtualParticipants.map(item => item.username)); // handle deleted
    } else {
      state.selectedVirtualParticipants = state.virtualParticipants.map(item => item.username);
    }
    if (state.selectedActionVirtualParticipants) {
      state.selectedActionVirtualParticipants = _.intersection(state.selectedActionVirtualParticipants, state.actionVirtualParticipants.map(item => item.username)); // handle deleted
    } else {
      state.selectedActionVirtualParticipants = state.actionVirtualParticipants.map(item => item.username);
    }
    if (state.selectedDeletedUsers) {
      state.selectedDeletedUsers = _.intersection(state.selectedDeletedUsers, state.deletedUsers.map(item => item.id)); // handle deleted
    } else {
      state.selectedDeletedUsers = state.deletedUsers.map(item => item.id);
    }
    if (state.selectedActionDeletedUsers) {
      state.selectedActionDeletedUsers = _.intersection(state.selectedActionDeletedUsers, state.actionDeletedUsers.map(item => item.id)); // handle deleted
    } else {
      state.selectedActionDeletedUsers = state.actionDeletedUsers.map(item => item.id);
    }
    state.virtualParticipants.sort((a, b) => (a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1));
    state.actionVirtualParticipants.sort((a, b) => (a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1));
    plannings.sort((a, b) => {
      if (a?.meta && b?.meta) return (a.meta.title || "").toLowerCase() < (b.meta.title || "").toLowerCase() ? -1 : 1;
      return a?.meta ? -1 : 1;
    });
    state.loading.complete = true;
    return plannings;
  });
}

async function loadMeetingActions({ state, rootState, rootGetters }) {
  await rootGetters['users/getOrganizationPromise'];
  if (state.selectedCompanyUsers) {
    state.selectedCompanyUsers = _.intersection(state.selectedCompanyUsers, rootGetters['users/getOrganizationUsers'].map(item => item.id)); // handle deleted
  } else {
    state.selectedCompanyUsers = rootGetters['users/getOrganizationUsers'].map(item => item.id);
  }
  return Promise.all([window.apiSrv.call('v2/meetings/actions', 'index'), window.apiSrv.call('v2/meetings/actions/completed', 'index')]).then((results) => {
    const meetingActions = results && results[0] && results[0].data && results[0].data.actions || [];
    Array.prototype.push.apply(meetingActions, results && results[1] && results[1].data && results[1].data.actions || []);
    state.meetingActions = meetingActions.map(item => new Action({ type: 'meeting', item }));
    state.meetingActions.forEach((action) => {
      const userId = action.getUserId();
      if (! userId) return;
      if (! rootGetters['users/getUserById'](userId) && ! state.actionDeletedUsers.find(item => item.id == userId)) { // deleted user
        const username = action.getUsername();
        state.actionDeletedUsers.push({
          id: userId,
          username: `${rootState.lang.i18n.global.t('MULTIPROJECTS.DELETED_USER')} ${username ? `(${username})` : state.actionDeletedUsers.length + 1}`,
          isDeleted: true,
        });
      }
    });
    return state.meetingActions;
  });
}

function formatEl({ state }, { el }) {
  if ((el.getDependencies() || []).length) el.originalHasDependencies = true;
  el.setDependencies(null);
  el.setTimeBar(null);
  el.setConfig({
    "show-description": state.selectedDisplay.description,
    "show-progress": state.selectedDisplay.progress,
    "show-checklist": state.selectedDisplay.checklist,
    "show-users": state.selectedDisplay.users,
    "show-date": el.isType('milestone') && state.selectedDisplay.dates,
    "show-dates": el.isType('macro', 'task') && state.selectedDisplay.dates,
    "show-links": state.selectedDisplay.links,
    "show-budgets": state.selectedDisplay.budgets,
    "show-projecttitle": state.selectedDisplay.projecttitle,
  });
}

/* *********** */
/* * GETTERS * */
/* *********** */
function planningActions(state) {
  const actions = [];
  state.projects.forEach((project) => {
    if (! project.elements) return;
    project.elements.forEach((el) => {
      const elementActions = (el.getChecklist() || []).map(item => new Action({ type: 'planning', element: el, item }));
      Array.prototype.push.apply(actions, elementActions);
    });
  });
  return actions;
}

function getOriginalPlanningEl(state, getters) {
  return (el) => {
    if (el.dashboard_el) return el; // already an originalPlanningEl (case switching tabs inside element details)

    const originalPlanning = getters.getPlanningById(el.project_id);
    const originalPlanningEl = new PlanningElement(originalPlanning, { ...el.getAll(), id: parseInt(el.id, 10) });
    originalPlanningEl.dashboard_el = el;
    return originalPlanningEl;
  };
}

const filterProps = ['selectedProjects', 'selectedVirtualParticipants', 'selectedCompanyUsers', 'selectedDeletedUsers', 'selectedActionDeletedUsers', 'selectedCompanyGroups',
'selectedActionVirtualParticipants', 'selectedDates', 'selectedDisplay', 'calendarDisplay', 'filterOptions', 'actionsOptions', 'portfolioOptions', 'processOptions', 'groupsOptions'];

export default {
  namespaced: true,
  modules: {
    plannings: planningsModule,
    process: processModule,
  },
  state: {
    loading: { complete: false, counter: { toLoad: 0, loaded: 0 } },
    projects: [],
    selectedProjects: null,
    virtualParticipants: [],
    selectedVirtualParticipants: [],
    actionVirtualParticipants: [],
    selectedActionVirtualParticipants: null,
    companyUsers: [],
    selectedCompanyUsers: null,
    deletedUsers: [],
    selectedDeletedUsers: [],
    actionDeletedUsers: [],
    selectedActionDeletedUsers: [],
    companyGroups: [],
    selectedCompanyGroups: null,
    meetingActions: [],
    selectedDates: { starttime: null, endtime: null },
    selectedDisplay: {
      description: false, checklist: false, progress: false, users: false, dates: false, links: false, budgets: false, projecttitle: false,
    },
    calendarDisplay: {
      milestone: true, shorttask: true, longtask: true, planningactions: true, meetingactions: true, holidays: true,
    },
    filterOptions: { el_type: 'both', hide_elements: false, unassigned: false },
    actionsOptions: { groupBy: 'user', displayCompleted: false, withWorkloadOnly: false, title: '' },
    portfolioOptions: { displayArchived: false, selectedProjectOwners: [], portfolioView: 'table-view', selectedCols: {}, groupByCategory: true },
    processOptions: { selectedSteps: {} },
    groupsOptions: { useGroups: false, hideUsers: false },
    clientCustomOptions: {},
  },
  getters: {
    planningActions,
    getOriginalPlanningEl,
    getPlanningById: state => id => state.plannings.byId[id],
    getAllPlannings: state => Object.keys(state.plannings.byId).map(id => state.plannings.byId[id]),
  },
  mutations: {
    loadFilters(state, { dashboardName, ...defaultValues } = {}) {
      const options = filterProps.reduce((acc, item) => {
        acc[item] = defaultValues[item];
        return acc;
      }, {});
      if (dashboardName) {
        const config = window.safeParseJSON(window.localStorageWrapper.getItem(`${dashboardName}.filters`)) || {};
        filterProps.forEach((key) => {
          if (typeof config[key] != 'undefined') {
            options[key] = config[key];
            if (key == 'selectedProjects') {
              if (config.selectedCompleteCategories?.length) {
                initLoadPlanningsPromise(); // assumes load finishes in the future (ok because synchronous loadFilters is called first)
                loadPlanningsPromise.then((plannings) => {
                  const projectIdsByCategory = getProjectIdsByCategory(plannings);
                  const projectIdsByCompleteCategory = config.selectedCompleteCategories.map(category => projectIdsByCategory[category] || []);
                  const completeCategoryProjects = [].concat(...projectIdsByCompleteCategory);
                  state.selectedProjects = [...new Set(state.selectedProjects.concat(completeCategoryProjects))]; // state.selectedProjects is already set in next synchronous filterProps.forEach
                });
              }
            }
          }
        });
      }
      filterProps.forEach((key) => {
        if (typeof options[key] == 'object') {
          if (_.isArray(options[key])) {
            state[key] = options[key];
          } else if (options[key] === null) {
            state[key] = null;
          } else {
            _.extend(state[key], options[key]);
          }
        }
      });
    },
    saveFilters(state, dashboardName) {
      const options = filterProps.reduce((acc, item) => {
        acc[item] = state[item];
        if (item == 'selectedProjects') { // case all projects
          const active = state.projects.filter(project => project.id).map(project => project.id);
          if (active.length == state.selectedProjects.length && active.length == _.intersection(active, state.selectedProjects).length) acc[item] = null;
          if (acc[item]?.length) { // look for complete categories
            const projectIdsByCategory = getProjectIdsByCategory(state.projects);
            const selectedCompleteCategories = Object.keys(projectIdsByCategory).filter((category) => {
              const selectedCategoryProjects = _.intersection(state.selectedProjects, projectIdsByCategory[category]);
              return selectedCategoryProjects.length == projectIdsByCategory[category].length;
            });
            if (selectedCompleteCategories.length) acc.selectedCompleteCategories = selectedCompleteCategories;
          }
        }
        if (item == 'selectedCompanyUsers') { // case all company users
          const active = state.companyUsers.map(user => user.id);
          if (active.length == state.selectedCompanyUsers.length && active.length == _.intersection(active, state.selectedCompanyUsers).length) acc[item] = null;
        }
        if (item == 'selectedCompanyGroups') { // case all company groups
          const active = state.companyGroups.map(group => group.id);
          if (active.length == state.selectedCompanyGroups.length && active.length == _.intersection(active, state.selectedCompanyGroups).length) acc[item] = null;
        }
        if (item == 'selectedVirtualParticipants') { // case all virtual participants
          const active = state.virtualParticipants.map(user => user.username);
          if (active.length == state.selectedVirtualParticipants.length && active.length == _.intersection(active, state.selectedVirtualParticipants).length) acc[item] = null;
        }
        if (item == 'selectedActionVirtualParticipants') { // case all action virtual participants
          const active = state.actionVirtualParticipants.map(user => user.username);
          if (active.length == state.selectedActionVirtualParticipants.length && active.length == _.intersection(active, state.selectedActionVirtualParticipants).length) acc[item] = null;
        }
        if (item == 'selectedDeletedUsers') { // case all deleted users
          const active = state.deletedUsers.map(group => group.id);
          if (active.length == state.selectedDeletedUsers.length && active.length == _.intersection(active, state.selectedDeletedUsers).length) acc[item] = null;
        }
        if (item == 'selectedActionDeletedUsers') { // case all action deleted users
          const active = state.actionDeletedUsers.map(group => group.id);
          if (active.length == state.selectedActionDeletedUsers.length && active.length == _.intersection(active, state.selectedActionDeletedUsers).length) acc[item] = null;
        }
        return acc;
      }, {});
      window.localStorageWrapper.setItem(`${dashboardName}.filters`, JSON.stringify(options));
    },
    updateOriginalEl(state, { el, data }) {
      const invariantFields = ['id', 'lane_id', 'timeBar', 'dependencies', 'config']; // fields modified by multiprojects should not be updated
      el.set(_.omit(data, invariantFields));
    },
  },
  actions: {
    load,
    reload: load,
    loadMeetingActions,
    formatEl,
  },
};
