<template>
  <v-window v-model="manageTabIndex" :mandatory="false" :touch="false">
    <!-- TAB PROJECTS -->
    <v-window-item :transition="false" :reverse-transition="false" value="projects-projects">
      <manage-projects-list></manage-projects-list>
    </v-window-item>

    <!-- TAB OPEN PROJECTS-->
    <v-window-item v-for="project in openedProjects" :key="project.id" :transition="false" :reverse-transition="false"
                   :value="`projects-project${project.id}`">
      <manage-projects-project-acr ref="projectTabs" :project="project"></manage-projects-project-acr>
    </v-window-item>
  </v-window>
</template>

<script>
  import { mapState } from 'vuex';
  import ManageProjectsList from './ManageProjectsList.vue';
  import ManageProjectsProjectAcr from './ManageProjectsProjectAcr.vue';

  export default {
    components: {
      ManageProjectsList,
      ManageProjectsProjectAcr,
    },
    computed: {
      manageTabIndex: {
        get() {
          return this.$store.state.manage.manageTabIndex;
        },
        set(newVal) {
          this.$store.state.manage.manageTabIndex = newVal;
        },
      },
      ...mapState('manage', ['dataLoaded', 'projects', 'openedProjects']),
    },
    created() {
      this.$store.commit('eventbus/register', {
        event: 'update-project-tabs',
        listener: 'manage-projects',
        cb: (projectInfo) => {
          this.onUpdateUserRightsInProject(projectInfo);
        },
      });
    },
    methods: {
      onUpdateUserRightsInProject({ userId, projectId, data }) {
        const projectIndex = this.openedProjects.findIndex(item => item.id == projectId);
        if (projectIndex == -1) return;
        const projectTab = this.$refs.projectTabs[projectIndex];
        if (projectTab.onUpdateUserRightsInProject) projectTab.onUpdateUserRightsInProject({ userId, projectId, data });
      },
    },
  };
</script>
