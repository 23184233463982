<template>
  <div :class="sublevel == 1 ? ['lane border-el-color', lane.getColorClass()] : null"
       :style="[{ height: `${laneHeight}px` }, sublevel == 1 ? 'border-style: solid; border-width: 2px 0' : null]">
    <template v-if="subplanning">
      <template v-for="sublane in selectedSublanes" :key="sublane.id">
        <planning-subproject-view v-if="sublevel == 1 && sublane.project_id" :planning="planning" :lane="sublane" :sublevel="2" :dynamic="dynamic" :view="view"></planning-subproject-view>
        <planning-lane-view v-else :id="`lane${sublane.id}`" :planning="subplanning" :lane="sublane"
                            :options="{ elements: { canEditEl } }" :events="{ elementClick, selectColor, checklistClick, progressClick }"
                            :dynamic="false" :view="view" :style="{ height: isKanbanMode ? 'auto' : `${sublane.height}px`, background: 'aliceblue' }" is-sub-lane>
        </planning-lane-view>
      </template>
    </template>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import constants from '@/js/constants.js';
  import PlanningLaneView from './PlanningLaneView';
  import laneHeight from './mixins/laneHeight';

  export default {
    components: {
      PlanningLaneView,
    },
    mixins: [laneHeight],
    props: {
      planning: { type: Object, required: true },
      lane: { type: Object, required: true },
      sublevel: { type: Number, default: 1 }, // 1 = subproject, 2 = subsubproject
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
    },
    data() {
      return {
        planningViewId: this.view ? +this.$route.params.id : null,
      };
    },
    computed: {
      subplanning() {
        return this.getSubprojectByLaneId(this.lane.id);
      },
      visibleLanes() {
        return this.$store.getters['planning/lanes/getVisibleLanes']({ planning: this.subplanning });
      },
      laneContentHeight() { // overwrite laneHeight mixin
        if (! this.subplanning) return constants.laneMinHeight;
        const height = this.selectedSublanes.reduce((prevHeight, lane) => prevHeight + lane.height, 0);
        const padding = this.sublevel == 1 ? 5 : 0;
        return Math.max(height, constants.laneMinHeight) + padding;
      },
      selectedSublanes() {
        return this.visibleLanes.filter((sublane) => {
          const subprojectLaneOriginalId = parseInt(sublane.id, 10);
          return ! (this.lane.project_hidden_sublanes && this.lane.project_hidden_sublanes.includes(subprojectLaneOriginalId));
        });
      },
      ...mapGetters('subprojects', ['getSubprojectByLaneId']),
    },
    watch: {
      isKanbanMode() {
        if (! this.isKanbanMode) this.loadSubproject(); // if planning mode recalculate subproject lane height
      },
      'lane.project_id': function onChangeSubproject() {
        this.loadSubproject();
      },
      laneContentHeight: function onChangeLaneContentHeight(val) {
        this.lane.contentHeight = val;
      },
    },
    mounted() {
      this.loadSubproject().then(() => {
        window.notificationsSrv.checkPlanningUpdates({ planningId: this.lane.project_id, laneId: this.lane.id }, (contentPatch) => {
          if (contentPatch?.length) {
            try {
              contentPatch.forEach((patch) => { // modify contentPatch element/lane ids to match subplanning modified ids
                if (patch.path.startsWith('/elements/')) {
                  const [, id, propertyPath] = /elements\/id=([^/]*)([/]?.*)/.exec(patch.path);
                  const subplanningEl = this.subplanning.elements.find(item => item.o_id == id);
                  if (! subplanningEl) throw new Error('new item');
                  patch.path = `/elements/id=${subplanningEl.id}${propertyPath}`;
                }
                if (patch.path.startsWith('/lanes/')) {
                  const [, id, propertyPath] = /lanes\/id=([^/]*)([/]?.*)/.exec(patch.path);
                  const subplanningLane = this.subplanning.lanes.find(item => item.o_id == id);
                  if (! subplanningLane) throw new Error('new item');
                  patch.path = `/lanes/id=${subplanningLane.id}${propertyPath}`;
                }
                if (patch.op == 'order' && patch.path == '/lanes') {
                  patch.value = patch.value.map((laneId) => {
                    const subplanningLane = this.subplanning.lanes.find(item => item.o_id == laneId);
                    return subplanningLane.id;
                  });
                }
              });
              const modifications = this.subplanning.applyContentPatch(contentPatch, false);
              this.subplanning.elements.filter(el => modifications.elements[el.id]).forEach((el) => { // add last modifier indication
                el.modificationUser = this.subplanning.meta.updated_by;
              });
              this.$store.dispatch('planning/timeline/set', this.planning.timeline);

              if (modifications.elements[this.$store.state.ui.planning.openedElement?.dashboard_el?.id]) {
                // replace this.$store.commit('eventbus/call', { event: 'project-updated', param: modifications });
                // handle when modified element is opened in element-details
                // -> too complicated to merge user modifications with received contentPatch so we just close the window and reload subproject
                this.$store.dispatch('ui/planning/closeElementDetails');
                this.loadSubproject(false);
                return;
              }

              this.debouncedLoadSubproject(); // reload full planning after 30s in case of sync errors
            } catch (e) {
              // applyContentPatch failed
              if (e.message != 'new item') console.error('CATCHED ERROR', e);
              this.loadSubproject(false);
            }
          } else {
            this.loadSubproject(false);
          }
        });

        this.createLaneHeightObserver();
      });
    },
    beforeUnmount() {
      window.notificationsSrv.stopCheckingPlanningUpdates({ planningId: this.lane.project_id, laneId: this.lane.id });
    },
    methods: {
      isDynamic(el) {
        return this.dynamic && (! this.view || el.access_right == 'modify');
      },
      canEditEl(el) {
        return this.isDynamic(el) && this.subplanning?.meta?.access_right && this.subplanning?.meta?.access_right != 'consult';
      },
      loadSubproject(retry = true) {
        return this.fetchSubproject({ id: this.lane.project_id, lane: this.lane, viewId: this.view && this.planningViewId }).then((planning) => {
          planning.elements.forEach((el) => {
            el.update();
          });
          setTimeout(() => {
            planning.elements.forEach((el) => {
              el.updateHeight();
            });
          });
        }).catch((error) => {
          if (! retry) return Promise.reject(error);
          return setTimeout(() => { this.loadSubproject(false); });
        });
      },
      debouncedLoadSubproject: _.debounce(function () { this.loadSubproject(false); }, 30000),
      elementClick(el) {
        const originalPlanningEl = this.$store.getters['ui/subplanning/getOriginalPlanningEl'](el);
        this.$store.commit('ui/planning/openElement', originalPlanningEl);
      },
      selectColor(el) {
        const originalPlanningEl = this.$store.getters['ui/subplanning/getOriginalPlanningEl'](el);
        this.$store.commit('ui/planning/openColorPickerElement', originalPlanningEl);
      },
      checklistClick(el, item) {
        const oldState = { checklist: angular.copy(el.getChecklist()), progress: el.getProgress() };
        item.checked = ! item.checked;
        el.updateChecklistProgress();
        this.$store.dispatch('ui/subplanning/saveEl', { el, props: ['checklist', 'progress'], oldState });
      },
      progressClick(el, event) {
        const oldState = { progress: el.getProgress() };
        const fullWidth = $(event.currentTarget).width();
        if (! fullWidth) return;
        el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
        this.$store.dispatch('ui/subplanning/saveEl', { el, props: ['progress'], oldState });
      },
      ...mapActions('subprojects', ['fetchSubproject']),
    },
  };
</script>
