<template>
  <v-list-item>
    <v-tooltip location="bottom" max-width="300">
      <template #activator="{ props }">
        <div class="pl-3" v-bind="props">
          <v-switch v-model="receiveNotifications" :disabled="receiveNotificationsIsDisabled" style="line-height: 1.1"
                    @update:model-value="! receiveNotificationsIsDisabled && saveReceiveNotifications()">
            <template #label>{{ $t('NOTIFICATIONS.ACTIVATE_NOTIFICATIONS') }}</template>
          </v-switch>
        </div>
      </template>
      {{ $t('NOTIFICATIONS.ACTIVATE_NOTIFICATIONS_DESC') }}
    </v-tooltip>
  </v-list-item>
</template>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        receiveNotificationsIsDisabled: false,
      };
    },
    computed: {
      receiveNotifications: {
        get() {
          return !! this.planning.meta.notifications;
        },
        set(newVal) {
          this.planning.meta.notifications = newVal;
        },
      },
    },
    methods: {
      saveReceiveNotifications() {
        this.receiveNotificationsIsDisabled = true;
        window.apiSrv.call(`sharedplannings/planning/${this.planning.id}/user`, 'update', { id: this.$store.state.users.user.id, notifications: this.receiveNotifications }).then(() => {
          this.receiveNotificationsIsDisabled = false;
        }).catch((message) => {
          this.receiveNotifications = ! this.receiveNotifications;
          this.receiveNotificationsIsDisabled = false;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error", body: message });
        });
      },
    }
  };
</script>
