<template>
  <i v-if="link.type == 'link'" class="link-icon far fa-external-link"></i>
  <img v-else-if="link.icon" :src="getIconImage(link)" class="link-icon">
  <span v-else-if="link.mime" :class="$filters.mimeIcon(link.mime)" class="link-icon"></span>
  <span v-else class="link-icon far fa-paperclip"></span>
</template>

<style>
  .link-icon {
    max-height: 1em;
    max-width: 1.25em;
  }
</style>

<script>
  import onedriveIcon from '@/img/onedrive_icon.png';

  const fileIcons = {
    'img/onedrive_icon.png': onedriveIcon,
  };

  export default {
    props: {
      link: { type: Object, required: true },
    },
    methods: {
      getIconImage(link) {
        if (! link.icon) return "";
        return fileIcons[link.icon] || link.icon;
      },
    },
  };
</script>
