<template>
  <div>
    <navigation></navigation>

    <div v-if="isPremium" id="planningusers">
      <v-progress-linear v-if="! loading.complete" :model-value="loading.counter.toLoad && loading.counter.loaded ? loading.counter.loaded / loading.counter.toLoad * 100 : 2"
                         color="accent" rounded>
      </v-progress-linear>

      <dashboards-filters v-if="loading.complete" :filters="dashboardFilters" can-hide-elements
                          @update="computeUserProject" @update-display="updateDisplay">
        <template #prepend-display>
          <btn-toggle-filter model-value="kanban" mandatory class="mr-3">
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn value="planning" to="/analysis/planningusers" v-bind="props">
                  <v-icon>svgicon-planning</v-icon>
                </v-btn>
              </template>
              {{ $t('PLANNINGUSERS.USERS_PLANNING') }}
            </v-tooltip>
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn value="kanban" v-bind="props">
                  <v-icon>svgicon-kanban</v-icon>
                </v-btn>
              </template>
              {{ $t('PLANNINGUSERS.USERS_KANBAN') }}
            </v-tooltip>
          </btn-toggle-filter>
        </template>
        <template #default>
          <process-steps-filter :steps="allPlanningProcessSteps" class="ml-3"></process-steps-filter>
        </template>
      </dashboards-filters>
      <div v-if="planning && planning.elements && planning.elements.length" v-show="viewIsReady">
        <!-- viewIsReady in v-show to keep timeline scrolling on refresh -->
        <div id="viewer" style="background: transparent;">
          <planning-content :planning="planning"
                            :options="{
                              elements: { canEditEl, hideElements: filterOptions.hide_elements },
                              elementDetails: { canDuplicateEl: true, canSetPriority: true, breadcrumb: true },
                            }"
                            :events="{ elementClick, closeElementDetails, selectColor, closeColorPicker, checklistClick, progressClick }"
                            dashboard>
          </planning-content>
        </div>
      </div>
      <div v-if="loading.complete && ! viewIsReady" style="text-align: center; padding: 15px; font-size: 18px">
        <i class="far fa-spinner fa-spin fa-2x fa-fw"></i>
      </div>

      <v-card v-if="viewIsReady && ! (planning && planning.elements && planning.elements.length)" class="py-6 px-12">
        <span>{{ $t('PLANNINGUSERS.SELECT_PROJECTS_WITH_USERS') }}</span>
      </v-card>
      <div v-show="planning && viewIsReady">
        <list-pagination :items="planning ? allLanes : []" :items-per-page="20" class="d-inline-block" @update="lanesPaginationBoundaries = $event; computeUserProject()"></list-pagination>
      </div>
    </div>

    <div v-if="userLoaded && ! isPremium">{{ $t('PREMIUM.SECTION_IS_PREMIUM') }}</div>
  </div>
</template>

<style>
  #planningusers .content#viewer {
    padding-top: 0 !important;
  }
</style>

<script>
  import { mapState, mapGetters } from 'vuex';
  import Planning from '@/models/Planning';
  import Navigation from '@/components/Navigation/Navigation';
  import PlanningContent from '@/components/Planning/PlanningContent';
  import DashboardsFilters from '../DashboardsFilters/DashboardsFilters';
  import ProcessStepsFilter from '../DashboardsFilters/ProcessStepsFilter';

  export default {
    components: {
      Navigation,
      PlanningContent,
      DashboardsFilters,
      ProcessStepsFilter,
    },
    data() {
      return {
        planning: null,
        viewIsReady: false,
        lanesPaginationBoundaries: { start: undefined, end: undefined },
        exporting: { inprogress: false, success: false, error: false },
      };
    },
    computed: {
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      userLoaded() { return this.$store.state.users.user.id > 0; },
      allPlanningElements() {
        // selected plannings -> elements inside timelineBoundaries range

        const filteredElements = [];
        const selectedProjectsSet = new Set(this.selectedProjects);
        this.projects.forEach((projectData) => {
          if (! projectData.elements || ! selectedProjectsSet.has(projectData.id)) return;
          projectData.elements.forEach((el) => {
            if (! el.getProcessStep()) return;
            filteredElements.push(el);
          });
        });

        const elements = [];
        filteredElements.forEach((el) => {
          if (! el.getUsers() || ! el.getUsers().length) {
            const newel = angular.copy(el);
            newel.id = `${newel.id}.${window.uuid()}`;
            newel.setLaneId(-1);
            elements.push(newel);
            return;
          }

          (el.getUsers() || []).forEach((user) => {
            const laneIds = [user.id || `vp${user.username}`]; // no grouping : 1 lane = 1 user
            laneIds.forEach((laneId) => {
              const newel = angular.copy(el);
              newel.id = `${newel.id}.${window.uuid()}`;
              newel.setLaneId(laneId);
              if (user.id || user.group_id || user.username) {
                // hide other users actions
                newel.filter_user = user.id || (user.group_id && `group${user.group_id}`) || `vp${user.username}`;
              }
              elements.push(newel);
            });
          });
        });
        return elements;
      },
      allPlanningProcessSteps() {
        const steps = {};
        const selectedProjectsSet = new Set(this.selectedProjects);
        this.projects.forEach((projectData) => {
          if (! projectData?.process?.steps || ! selectedProjectsSet.has(projectData.id)) return;
          projectData.process.steps.forEach((step) => {
            if (! step.id) return;
            if (! steps[step.label]) {
              steps[step.label] = { ...step, ids: new Set([`${projectData.id}.${step.id}`]), id: `${projectData.id}.${step.id}` };
            } else {
              steps[step.label].ids.add(`${projectData.id}.${step.id}`);
            }
          });
        });
        return Object.values(steps);
      },
      filteredPlanningProcessSteps() {
        return this.allPlanningProcessSteps.filter(step => this.processOptions.selectedSteps[step.id]);
      },
      allUsersLanes() {
        // selected users -> lanes
        const lanes = [];
        this.companyUsers.concat(this.deletedUsers, this.virtualParticipants).forEach((user) => {
          if (user.id) {
            if (! user.isDeleted) {
              if (this.selectedCompanyUsers.indexOf(user.id) == -1) return;
            } else if (this.selectedDeletedUsers.indexOf(user.id) == -1) {
              return;
            }
          } else if (this.selectedVirtualParticipants.indexOf(user.username) == -1) {
            return;
          }

          const laneId = user.id || `vp${user.username}`;
          lanes.push({
            id: laneId,
            label: this.$store.getters['users/getUsername'](user),
            color: user.id ? null : user.group_id ? 2 : 5,
          });
        });
        return lanes;
      },
      allLanes() {
        return this.allGroupsLanes || this.allUsersLanes;
      },
      useGroups() {
        return false;
      },
      dashboardFilters() {
        return ['Projects', 'Users', 'Display'];
      },
      canAssignGroups() { return this.$store.state.users.accessRight.canAssignGroups; },
      ...mapState('multiprojects', ['projects', 'selectedProjects', 'companyUsers', 'selectedCompanyUsers',
                                    'virtualParticipants', 'selectedVirtualParticipants', 'deletedUsers', 'selectedDeletedUsers']),
      ...mapState('multiprojects', ['loading', 'filterOptions', 'processOptions']),
      ...mapState('multiprojects/process', ['projectStepId2DashboardStepId']),
      ...mapGetters('multiprojects', ['getOriginalPlanningEl']),
    },
    watch: {
      'loading.complete': function (newVal) {
        if (! newVal) {
          this.viewIsReady = false;
        } else {
          this.$store.state.users.userPromise.then(() => {
            this.computeUserProject();
          });
        }
      },
      filteredPlanningProcessSteps() {
        this.$store.commit('multiprojects/process/resetProjectStepId2DashboardStepId');
        this.filteredPlanningProcessSteps.forEach((step) => {
          step.ids.forEach((id) => {
            this.projectStepId2DashboardStepId[id] = step.id;
          });
        });
        this.computeUserProject();
      },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        // if not business, return to planning users
        if (! this.$store.state.users.accessRight.hasProcessDashboard) {
          this.$router.replace('/analysis/planningusers');
        }
      });

      this.$store.commit('multiprojects/loadFilters', {
        dashboardName: this.$route.path.slice(1).replace(/\//g, '_'),
        selectedDisplay: { checklist: true },
      });
      this.$store.dispatch('multiprojects/load');
    },
    methods: {
      canEditEl(el) {
        const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](el.project_id);
        return originalPlanning && originalPlanning.meta && originalPlanning.meta.access_right && originalPlanning.meta.access_right != 'consult';
      },
      saveEl(params) {
        const { el } = params;
        this.$store.dispatch('ui/dashboards/saveEl', params).then((result) => {
          if (result == 'nothing to save') return;
          // update source planning element (for el copies & workloads)
          const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](el.project_id);
          const originalPlanningElement = originalPlanning && (originalPlanning.elements || []).find(item => item.o_id == el.o_id);
          if (originalPlanningElement) {
            const originalElementUsers = angular.copy(originalPlanningElement.getUsers());
            this.$store.commit('multiprojects/updateOriginalEl', { el: originalPlanningElement, data: el.getAll() });
            if (! angular.equals(originalElementUsers, originalPlanningElement.getUsers())) {
              // users was changed -> need full refresh to update lanes
              this.computeUserProject();
              return;
            }
          }

          // update element copies on planningusers
          const planningUsersElements = this.planning.elements.filter(item => item.project_id == el.project_id && item.o_id == el.o_id);
          planningUsersElements.forEach((element) => {
            this.$store.commit('multiprojects/updateOriginalEl', { el: element, data: el.getAll() });
            element.update();
          });
        });
      },
      deleteElement(el) {
        const { openedElement } = this.$store.state.ui.planning;
        if (openedElement && openedElement.id == el.id) this.$store.commit('ui/planning/openElement', null);
        this.$store.dispatch('ui/dashboards/deleteElement', el);
        const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](el.project_id);
        const originalPlanningElementIndex = originalPlanning && (originalPlanning.elements || []).findIndex(item => item.o_id == el.o_id);
        if (originalPlanningElementIndex > -1) {
          originalPlanning.elements.splice(originalPlanningElementIndex, 1);
          this.computeUserProject();
        }
      },
      elementClick(el) {
        this.$store.commit('ui/planning/openElement', this.getOriginalPlanningEl(el));
      },
      closeElementDetails(reason) {
        const { openedElement } = this.$store.state.ui.planning;
        if (! openedElement) return;
        if (reason == 'delete') {
          this.deleteElement(openedElement.dashboard_el);
        } else {
          this.saveEl({ el: openedElement.dashboard_el, newState: openedElement.getAll() });
          if (reason == 'duplicate') {
            this.duplicateElement(openedElement.dashboard_el);
          }
        }
        this.$store.commit('ui/planning/openElement', null);
      },
      selectColor(el) {
        this.$store.commit('ui/planning/openColorPickerElement', this.getOriginalPlanningEl(el));
      },
      closeColorPicker() {
        const { openedColorPickerElement } = this.$store.state.ui.planning;
        if (! openedColorPickerElement) return;
        this.saveEl({ el: openedColorPickerElement.dashboard_el, newState: openedColorPickerElement.getAll() });
        this.$store.commit('ui/planning/openColorPickerElement', null);
      },
      checklistClick(el, item) {
        const oldState = { checklist: angular.copy(el.getChecklist()), progress: el.getProgress() };
        item.checked = ! item.checked;
        el.updateChecklistProgress();
        this.saveEl({ el, props: ['checklist', 'progress'], oldState });
      },
      progressClick(el, event) {
        const oldState = { progress: el.getProgress() };
        const fullWidth = $(event.currentTarget).width();
        if (! fullWidth) return;
        el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
        this.saveEl({ el, props: ['progress'], oldState });
      },
      duplicateElement(el) {
        const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](el.project_id); // with original lane_id
        const originalPlanningElement = originalPlanning && (originalPlanning.elements || []).find(item => item.o_id == el.o_id);
        if (! originalPlanningElement) return;
        const newEl = angular.copy(originalPlanningElement);
        newEl.setDependencies(null);
        newEl.create().then(() => { // create a new element via api / id is updated by api response
          // Send notification (no planningPatch, recipient will reload full planning)
          window.notificationsSrv.callEvent('projectSaved', { planning_id: originalPlanning.id, planning_title: originalPlanning.getTitle() });

          // Add element and reload
          this.$store.commit('multiprojects/plannings/addElementToPlanning', { planningId: originalPlanning.id, el: newEl });
          this.computeUserProject();
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: this.$t('MONITORING_PROGRESS.ERROR_NOT_MODIFIED'), body: message || "" }, { root: true });
        });
      },
      computeUserProject() {
        if (! this.loading.complete) return;
        if (! this.projects.length) return;
        const scrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.viewIsReady = false;

        setTimeout(() => {
          let lanes = this.allLanes.slice(this.lanesPaginationBoundaries.start || undefined, this.lanesPaginationBoundaries.end || undefined);
          if (! this.useGroups && this.filterOptions.unassigned) lanes.unshift({ id: -1, label: this.$t('PLANNINGUSERS.UNASSIGNED') });

          /* list elements */
          const elements = [];
          const laneIds = new Set(lanes.map(lane => lane.id));
          const nonEmptyLaneIds = new Set();
          this.allPlanningElements.forEach((el) => {
            if (this.filterOptions.el_type && this.filterOptions.el_type != 'both' && ! el.isType(this.filterOptions.el_type)) return;
            if (! laneIds.has(el.getLaneId())) return;
            this.$store.dispatch('multiprojects/formatEl', { el });

            // set kanban col id
            const elProcessStep = el.getProcessStep();
            const elProcessStepId = elProcessStep && `${el.project_id}.${elProcessStep}`;
            if (elProcessStepId && this.projectStepId2DashboardStepId[elProcessStepId]) {
              elements.push(el);
              nonEmptyLaneIds.add(el.getLaneId());
            }
          });

          /* filter empty lanes */
          lanes = lanes.filter(lane => nonEmptyLaneIds.has(lane.id));

          const defaultProjectData = new Planning();

          /* meta, config */
          _.extend(defaultProjectData.meta, this.projects[0].meta, {
            title: this.$t('PLANNINGUSERS.USERS_KANBAN'),
            category: '',
            companyconfig: { premium: true },
          });

          this.planning = new Planning({
            config: this.planning && this.planning.config || defaultProjectData.config,
            meta: defaultProjectData.meta,
            lanes,
            elements,
            process: { steps: this.filteredPlanningProcessSteps },
          });

          setTimeout(() => {
            this.$store.state.ui.planning.mode = 'kanban';
            this.viewIsReady = true;
            this.$store.dispatch('planning/set', { planning: this.planning });
            setTimeout(() => window.scrollTo(0, scrollY));
          });
        });
      },
      updateDisplay() {
        // update existing planning without recomputing
        this.planning.elements.forEach((el) => {
          this.$store.dispatch('multiprojects/formatEl', { el });
        });
        this.$nextTick(() => {
          this.planning.elements.forEach((el) => { el.updateHeight(); });
        });
      },
    },
  };
</script>
