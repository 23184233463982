export default {
  namespaced: true,
  state: {
    events: {
      // eventName : { listenerName1: callback1, listenerName2: callback2, ... }

      // List of events in Planning
      'project-updated': { 'element-details': null, 'macro-element-details': null }, // called in Planning.vue
      'update-project': { planning: null }, // called in store/planning/planning.js

      // List of events in Manage
      'update-project-tabs': { 'manage-projects': null }, // called in ManageUsersUserAcr.vue
      'update-user-tabs': { 'manage-users': null }, // called in ManageProjectsProjectAcr.vue + ManageUsersUserAcr.vue
    },
  },
  mutations: {
    register(state, { event, listener, cb }) {
      if (! listener || ! Object.keys(state.events[event] || {}).includes(listener)) {
        console.error('invalid event or listener', event, listener);
        return;
      }
      state.events[event][listener] = cb;
    },
    remove(state, { event, listener } = {}) {
      const events = event ? [event] : Object.keys(state.events);
      events.forEach((eventName) => {
        const listeners = listener ? [listener] : Object.keys(state.events[eventName]);
        listeners.forEach((listenerData) => {
          if (state.events[eventName][listenerData]) state.events[eventName][listenerData] = null;
        });
      });
    },
    call(state, { event, param }) {
      if (! state.events[event]) {
        console.error('invalid event', event);
        return;
      }
      Object.keys(state.events[event]).forEach((listener) => {
        if (state.events[event][listener]) state.events[event][listener](param);
      });
    },
  },
};
