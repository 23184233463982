<template>
  <div class="d-flex align-center ml-2 mb-2">
    <span class="text-h6">{{ $t('HOME.MILESTONES_TO_COME') }}</span>
    <v-tooltip location="left" max-width="200">
      <template #activator="{ props }">
        <v-icon color="info" size="small" class="ml-2" v-bind="props">far fa-question-circle</v-icon>
      </template>
      <span>{{ $t('HOME.MILESTONES_EXPLANATIONS') }}</span>
    </v-tooltip>
  </div>
  <div>
    <v-card v-if="! milestones.length" class="pa-6 text-medium-emphasis">
      {{ $t('HOME.NO_MILESTONE_ACTIVITY') }}
    </v-card>
    <div v-else>
      <v-card>
        <div class="bg-lightgrey">&nbsp;</div>
        <div class="pt-1 mb-2">
          <table>
            <tr v-for="el in displayedMilestones" :key="el.id" class="hover-secondary">
              <td class="w-0 pl-3 pr-2 py-1">
                <v-icon :color="el.getIcon().color" style="flex: 0 0 28px">{{ el.getIcon().name }}</v-icon>
              </td>
              <td class="nobr w-0 px-2">
                <b>{{ $filters.moment(el.getStartTime(), 'mediumDateNoYear') }}</b>
                <span v-if="el.getSchedule() && (el.getSchedule().start || el.getSchedule().end)" class="small">
                  <span v-if="el.getSchedule().start">{{ $filters.moment(el.getSchedule().start, 'LT') }}</span>
                  <span v-else>...</span>
                  <span v-if="el.getSchedule().end">- {{ $filters.moment(el.getSchedule().end, 'LT') }}</span>
                </span>
              </td>
              <td class="px-2">
                {{ el.getTitle() || $t('PLANNING.MILESTONE') }}
              </td>
              <td class="pr-4 rounded-e-pill">
                <span class="ml-2 text-medium-emphasis">
                  <v-icon size="small" class="mr-1 mb-.5">far fa-folder-open</v-icon>
                  <router-link :to="canEditEl(el) ? elementUrl(el) : projectUrl(el)" style="color: inherit">
                    <i class="small">{{ el.project_title }}</i>
                  </router-link>
                </span>
              </td>
            </tr>
          </table>
          <div v-if="milestones.length > showItemsLimit" class="pointer my-2 text-center text-primary"
               @click="showAllItems = ! showAllItems">
            <i :class="showAllItems ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'" class="far"></i>
            {{ showAllItems ? $t('HOME.REDUCE') : $t('HOME.SEE_MORE', milestones.length - showItemsLimit) }}
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      elements: { type: Array, required: true },
    },
    data() {
      return {
        showAllItems: false,
        showItemsLimit: 5,
      };
    },
    computed: {
      milestones() {
        return this.elements.filter(item => item.isType('milestone')).sort((a, b) => {
          const aStartTime = a.getStartTime();
          const bStartTime = b.getStartTime();
          if (aStartTime.isSame(bStartTime, 'day')) return a.project_id < b.project_id ? -1 : 1;
          return aStartTime.isBefore(bStartTime) ? -1 : 1;
        });
      },
      displayedMilestones() {
        return this.showAllItems ? this.milestones : this.milestones.slice(0, this.showItemsLimit);
      },
    },
    methods: {
      canEditEl(el) {
        const planning = el.getPlanning();
        return planning && planning.meta && planning.meta.access_right && planning.meta.access_right != 'consult';
      },
      projectUrl(el) {
        const { project_id: projectId, project_title: projectTitle, project_access_right: projectAccessRight } = el;
        const isConsult = projectAccessRight == 'consult';
        return `/${isConsult ? 'viewer/' : 'planning/'}${projectId}/${window.slugify(projectTitle || "New project")}`;
      },
      elementUrl(el) {
        return `${this.projectUrl(el)}?el=${parseInt(el.id, 10)}`;
      },
    },
  };
</script>
