<template>
  <div v-for="(colorData, colorIndex) in planning.config.colors" :key="colorIndex" :class="disableColorRenaming ? 'mb-1' : 'mb-3'" class="d-flex">
    <div v-if="! compact" class="pt-2 flex-grow-1">
      <input v-if="! disableColorRenaming" v-model="colorData.label" :placeholder="$t(`COLORS.SET_${colorIndex + 1}`)"
             class="input-underlined" spellcheck="false" style="width: 100%; border-width: 0 0 1px;">
      <div v-else>{{ colorData.label || $t(`COLORS.SET_${colorIndex + 1}`) }}</div>
    </div>
    <div :class="compact ? 'ml-2' : 'ml-4'" class="d-flex">
      <div v-for="(shadeIndex, tabIndex) in ['', 0, 1, 2, 3]" class="color-block">
        <div :class="`el-color-${colorIndex}-${shadeIndex}`" class="color-square bg-el-color pointer flex-center-center" :title="colorData.sublabels[tabIndex]"
             @mouseover="updateColors(colorIndex, shadeIndex)" @mouseout="resetColors()" @click="applySelection()">
          <v-icon v-if="initialState.color === colorIndex && initialState.colorShade === shadeIndex" size="small">far fa-check</v-icon>
        </div>
        <div v-if="! compact" style="margin-top: -2px">
          <input v-if="! disableColorRenaming" v-model="colorData.sublabels[tabIndex]" class="input-underlined color-picker-label-input" spellcheck="false">
          <div v-else :title="colorData.sublabels[tabIndex]" class="color-picker-label text-ellipsis">{{ colorData.sublabels[tabIndex] || '&nbsp;' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      color: { required: true }, // v-model
      colorShade: { required: true }, // v-model
      target: { type: Object, required: true }, // element or lane or kanbanHeader
      planning: { type: Object, required: true },
      disableRenaming: { type: Boolean, default: false },
      compact: { type: Boolean, default: false },
    },
    emits: ['update:color', 'update:colorShade', 'selected'],
    data() {
      return {
        disableColorRenaming: this.disableRenaming,
      };
    },
    computed: {
      initialState() {
        return {
          color: this.target.getColorId(),
          colorShade: this.target.getColorShadeId(),
        };
      },
    },
    methods: {
      updateColors(color, colorShade) {
        this.$emit('update:color', color);
        this.$emit('update:colorShade', colorShade);
      },
      resetColors() {
        this.updateColors(this.initialState.color, this.initialState.colorShade);
      },
      applySelection() {
        this.target.setColorId(this.color);
        this.target.setColorShadeId(this.colorShade);
        this.$emit('selected');
      },
    },
  };
</script>
