<template>
  <div v-if="isBusiness">
    <div>
      <div class="d-flex align-center">
        {{ $t('ELEMENT-DETAIL.RECURRENCE.REPEAT', frequency == 'weeks' || frequency == 'years' ? 2 : 1) }}
        <v-text-field v-model.number="interval" type="number" min="1" class="mx-2" style="flex: 0 0 70px"></v-text-field>
        <v-select v-model="frequency" :items="frequencyOptions" style="max-width: 150px"></v-select>
      </div>
    </div>
    <v-expand-transition>
      <!-- Months Options -->
      <div v-show="frequency == 'months'" class="ml-6">
        <v-radio-group v-model="monthlyOption" class="mt-0">
          <v-radio :label="`${$t('ELEMENT-DETAIL.RECURRENCE.EVERY_MONTH', interval)} ${dayOfMonth}`" value="dayOfMonth"></v-radio>
          <v-radio :label="`${$t('ELEMENT-DETAIL.RECURRENCE.EVERY_MONTH', interval)} ${dayOfWeekDesc}`" value="dayOfWeek"></v-radio>
        </v-radio-group>
      </div>
    </v-expand-transition>
    <!-- Reccurrence Ends -->
    <div class="mt-2 d-flex align-center">
      <span class="mr-1">{{ $t('ELEMENT-DETAIL.RECURRENCE.UNTIL') }}</span>
      <date-field v-model="limitDate" :placeholder="$t('ELEMENT-DETAIL.RECURRENCE.NO_LIMIT')" :min="minDate" nudge-details clearable style="max-width: 150px"></date-field>
    </div>
    <!-- Nb occurences -->
    <div class="mt-2 d-flex align-center">
      <span class="mr-1">{{ $t('ELEMENT-DETAIL.RECURRENCE.SHOW_THE') }}</span>
      <v-text-field v-model.number="occurrences" type="number" min="1" max="12" class="mx-1 text-center" style="flex: 0 0 70px"></v-text-field>
      <span>{{ $t('ELEMENT-DETAIL.RECURRENCE.NEXT_OCCURENCES') }}</span>
    </div>
    <div class="d-flex justify-center mt-6 position-relative">
      <v-btn color="primary" size="large" rounded @click="applyRecurrenceOptions">
        <template v-if="! recurrence">{{ $t('GLOBAL.APPLY') }}</template>
        <template v-else>
          {{ $t('ELEMENT-DETAIL.RECURRENCE.REGENERATE') }}
          <v-icon v-tooltip="{ text: $t('ELEMENT-DETAIL.RECURRENCE.REGENERATE_DESC'), maxWidth: 250 }" class="ml-2">far fa-question-circle</v-icon>
        </template>
      </v-btn>
      <v-btn-icon v-if="recurrence" v-tooltip="{ text: $t('ELEMENT-DETAIL.RECURRENCE.DELETE_RECURRENCE'), maxWidth: 250 }" color="errorred" style="position: absolute; right: 0;"
                  @click="deleteRecurrence">
        <v-icon>far fa-trash-alt</v-icon>
      </v-btn-icon>
    </div>
  </div>
  <v-alert v-else color="warningorange" type="warning">
    {{ $t('PREMIUM.BUSINESS.FEATURE_IS_BUSINESS') }}
  </v-alert>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    props: {
      el: { type: Object, required: true },
    },
    emits: ['close'],
    data() {
      const recurrenceId = this.el.getRecurrenceId();
      const recurrence = recurrenceId && this.el.getPlanning()?.config.recurrences?.[recurrenceId];

      return {
        interval: recurrence?.interval || 1,
        frequency: recurrence?.frequency || 'months',
        frequencyOptions: [
          { value: 'days', title: this.$t('TIMELINE.DAYS').toLowerCase() },
          { value: 'weeks', title: this.$t('TIMELINE.WEEKS').toLowerCase() },
          { value: 'months', title: this.$t('TIMELINE.MONTHS').toLowerCase() },
          { value: 'years', title: this.$t('TIMELINE.YEARS').toLowerCase() },
        ],
        monthlyOption: recurrence?.monthlyOption || 'dayOfMonth', // dayOfMonth or dayOfWeek
        monthWeeksName: [
          this.$t('ELEMENT-DETAIL.RECURRENCE.FIRST'),
          this.$t('ELEMENT-DETAIL.RECURRENCE.SECOND'),
          this.$t('ELEMENT-DETAIL.RECURRENCE.THIRD'),
          this.$t('ELEMENT-DETAIL.RECURRENCE.FOURTH'),
        ],
        limitDate: recurrence?.limitDate && moment(recurrence.limitDate) || this.el.getStartTime().add(6, 'months'),
        occurrences: recurrence?.occurrences || 1,
      };
    },
    computed: {
      recurrence() {
        const recurrenceId = this.el.getRecurrenceId();
        if (! recurrenceId) return null;
        return this.el.getPlanning()?.config.recurrences?.[recurrenceId] || null;
      },
      dayOfMonth() {
        if (! this.frequency == 'months') return null;
        return this.el.getStartTime().format('D');
      },
      weekOfMonth() {
        if (! this.frequency == 'months') return null;
        return Math.ceil(this.el.getStartTime().format('D') / 7);
      },
      dayOfWeekDesc() {
        const dayOfWeek = this.el.getStartTime().format('dddd').toLowerCase();
        return this.weekOfMonth <= 4 ? `${this.monthWeeksName[this.weekOfMonth - 1]} ${dayOfWeek}` : `dernier ${dayOfWeek}`;
      },
      minDate() {
        return this.el.getStartTime().add(this.interval, this.frequency).toISOString().slice(0, 10);
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
    watch: {
      occurrences(newVal) {
        if (newVal > 12) this.occurrences = 12;
        if (newVal < 1) this.occurrences = 1;
      },
    },
    methods: {
      applyRecurrenceOptions() {
        const recurrenceOptions = {
          interval: this.interval,
          frequency: this.frequency,
          monthlyOption: this.monthlyOption,
          limitDate: this.limitDate && this.limitDate.format() || null,
          occurrences: this.occurrences,
        };

        let recurrenceId = this.el.getRecurrenceId();
        const planning = this.el.getPlanning();
        let recurrence;

        if (this.recurrence) { // update recurrence
          recurrence = planning.config.recurrences[recurrenceId];
          _.assign(recurrence, recurrenceOptions);
          // remove future elements
          const elStartTime = this.el.getStartTime();
          const futureElements = elStartTime && planning.elements.filter(el => el.getRecurrenceId() == recurrenceId && el.getStartTime()?.isAfter(elStartTime));
          if (futureElements?.length) this.$store.dispatch('planning/elements/deleteElements', { els: futureElements, silent: true });
        } else {
          recurrenceId = window.uuid();
          if (! planning.config.recurrences) planning.config.recurrences = {};
          recurrence = { id: recurrenceId, ...recurrenceOptions };
          planning.config.recurrences[recurrenceId] = recurrence;
          this.el.setRecurrenceId(recurrenceId);
          planning.elements.find(item => item.id == this.el.id).setRecurrenceId(recurrenceId); // update reel element (this.el is a copy)
        }

        this.generateRecurrenceElements({ recurrence, referenceElement: this.el });
        this.changingElement(); // undoredo / save modified elements, including this.el
        this.startChangingConfig(); // reset to skip undoredo
        this.startChangingElement({ doNotClosePlanningDrawer: true }); // reset undoState for this.el
        this.$emit('close');
      },
      deleteRecurrence() {
        const recurrenceId = this.el.getRecurrenceId();
        const planning = this.el.getPlanning();
        const elStartTime = this.el.getStartTime();
        const elementsToDelete = [];
        planning.elements.forEach((item) => {
          if (item.getRecurrenceId() != recurrenceId) return;
          const itemStartTime = item.getStartTime();
          if (elStartTime && itemStartTime && itemStartTime.isAfter(elStartTime)) {
            elementsToDelete.push(item);
          } else {
            item.setRecurrenceId(null);
          }
        });
        if (elementsToDelete.length) this.$store.dispatch('planning/elements/deleteElements', { els: elementsToDelete, silent: true });
        this.el.setRecurrenceId(null);
        planning.elements.find(item => item.id == this.el.id).setRecurrenceId(null); // update reel element (this.el is a copy)

        delete planning.config.recurrences[recurrenceId];
        if (_.isEmpty(planning.config.recurrences)) planning.config.recurrences = null;

        this.changingElement(); // undoredo / save modified elements, including this.el
        this.startChangingElement({ doNotClosePlanningDrawer: true }); // reset undoState for this.el
        this.$emit('close');
        // undo redo config will be added when closing planning drawer (config already saved when changingElement)
      },
      ...mapActions('planning/config', ['generateRecurrenceElements', 'startChangingConfig', 'changingConfig']),
      ...mapActions('planning/elements', ['startChangingElement', 'changingElement']),
    },
  };
</script>
