<template>
  <v-toolbar-items>
    <v-btn :title="selectedView.id ? $t('WORKING_VIEWS.SAVE_VIEW') : $t('WORKING_VIEWS.CREATE_THE_VIEW')" icon
           :disabled="saving.inprogress"
           @click.stop="selectedView.id ? saveView() : createView()">
      <i v-if="saving.inprogress" class="fa fa-fw fa-spinner fa-spin"></i>
      <span v-else-if="saving.error"><i class="far fa-fw fa-exclamation-triangle bg-errorred"></i> &nbsp;{{ saving.error }}</span>
      <v-icon v-else-if="saving.success" color="successgreen">far fa-check</v-icon>
      <v-icon v-else color="primary">fas fa-save</v-icon>
    </v-btn>
    <v-menu v-if="selectedView.id" location="bottom end">
      <template #activator="{ props }">
        <v-btn variant="text" class="px-1" style="min-width: 0" v-bind="props">
          <v-icon>fas fa-caret-down</v-icon>
        </v-btn>
      </template>
      <v-list density="compact">
        <v-list-item class="text-primary" @click="editViewTitle()">
          <v-icon class="mr-1" style="width: 24px; color: inherit">far fa-pencil-alt</v-icon>{{ $t('WORKING_VIEWS.RENAME_VIEW') }}
        </v-list-item>
        <v-list-item class="text-errorred" @click="removeView()">
          <v-icon class="mr-1" style="width: 24px; color: inherit">far fa-trash-alt</v-icon>{{ $t('CUSTOM_VIEWS.DELETE_VIEW') }}
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar-items>
</template>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
      selectedView: { type: Object, required: true },
    },
    emits: ['update-views-list', 'update-view-title'],
    data() {
      return {
        saving: { inprogress: false, success: false, error: "" },
      };
    },
    methods: {
      saveView() {
        if (! this.selectedView.id) return Promise.reject('missing view id');
        this.saving = { inprogress: true, success: false, error: "" };
        return this.selectedView.save().then(() => {
          this.saving.inprogress = false;
          this.saving.success = true;
          setTimeout(() => { this.saving.success = false; }, 5000);
        }).catch((error) => {
          this.saving.inprogress = false;
          this.saving.error = error;
        });
      },
      createView() {
        if (this.selectedView.id) return;
        const newTitle = prompt(this.$t('WORKING_VIEWS.NAME_VIEW'));
        if (newTitle === null) return;
        this.saving = { inprogress: true, success: false, error: "" };
        const newView = { ...this.selectedView, title: newTitle || this.$t('WORKING_VIEWS.UNTITLED') };
        window.apiSrv.call('views', 'store', newView).then((response) => {
          this.$emit('update-views-list', 'create', response?.data?.view?.id);
          this.saving.inprogress = false;
          this.saving.success = true;
          setTimeout(() => { this.saving.success = false; }, 5000);
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : View was not saved", body: message });
          this.saving.inprogress = false;
          this.saving.error = " ";
        });
      },
      editViewTitle() {
        if (! this.selectedView.id) return;
        const newTitle = prompt(this.$t('WORKING_VIEWS.RENAME_VIEW'), this.selectedView.title);
        if (newTitle === null || newTitle === this.selectedView.title) return;
        this.saveView().then(() => { this.$emit('update-view-title', newTitle); });
      },
      removeView() {
        if (! this.selectedView.id) return;
        this.$store.dispatch('ui/msgbox/open', {
          title: this.$t('CUSTOM_VIEWS.CONFIRM_DELETE_VIEW'),
          body: this.$t('CUSTOM_VIEWS.CANNOT_UNDO'),
          buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
        }).then(() => {
          this.selectedView.delete().then(() => {
            this.$emit('update-views-list', 'delete', this.selectedView.id);
          }).catch((message) => {
            if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : View was not deleted", body: message });
          });
        }).catch(() => {});
      },
    },
  };
</script>
