/* eslint no-param-reassign:off */
if (! [].at) { // polyfill for Array.at
  Array.prototype.at = function at(index = 0) {
    const k = index >= 0 ? index : this.length + index;
    return (k < 0 || k >= this.length) ? undefined : this[k];
  };
}

window.localStorageWrapper = {
  clear() {
    try {
      return localStorage.clear();
    } catch (e) {
      return null;
    }
  },
  getItem(item) {
    try {
      return localStorage.getItem(item);
    } catch (e) {
      return null;
    }
  },
  setItem(item, value) {
    try {
      return localStorage.setItem(item, value);
    } catch (e) {
      return null;
    }
  },
  removeItem(item) {
    try {
      return localStorage.removeItem(item);
    } catch (e) {
      return null;
    }
  },
};

window.sessionStorageWrapper = {
  clear() {
    try {
      return sessionStorage.clear();
    } catch (e) {
      return null;
    }
  },
  getItem(item) {
    try {
      return sessionStorage.getItem(item);
    } catch (e) {
      return null;
    }
  },
  setItem(item, value) {
    try {
      return sessionStorage.setItem(item, value);
    } catch (e) {
      return null;
    }
  },
  removeItem(item) {
    try {
      return sessionStorage.removeItem(item);
    } catch (e) {
      return null;
    }
  },
};

window.safeParseJSON = function (str) {
  let decoded;
  try {
    decoded = JSON.parse(str);
  } catch (e) {}
  return decoded;
};

window.uuid = function () {
  const size = 6;
  const charset = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const charsetLength = charset.length;

  const bytes = new Uint8Array(size);
  crypto.getRandomValues(bytes);

  return bytes.reduce((acc, byte) => `${acc}${charset[byte % charsetLength]}`, '');
};

window.isValidEmailAddress = function (emailAddress) {
  const pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,10}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
  return pattern.test(emailAddress);
};

window.html2text = function (html) {
  html = String(html).replace(/<br\s*[/]?>/gi, ' ');
  const el = document.createElement("div");
  el.innerHTML = html;
  return el.textContent || el.innerText || html.replace(/&nbsp;/gi, ' ').replace(/<[^>]+>/gm, '');
};

window.slugify = function (text) {
  return text.toString().toLowerCase().replace(/\s+/g, '-').replace(/[àáâãäå]/g, "a")
    .replace(/æ/g, "ae")
    .replace(/ç/g, "c")
    .replace(/[èéêë]/g, "e")
    .replace(/[ìíîï]/g, "i")
    .replace(/ñ/g, "n")
    .replace(/[òóôõö]/g, "o")
    .replace(/œ/g, "oe")
    .replace(/[ùúûü]/g, "u")
    .replace(/[ýÿ]/g, "y")
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

window.hexToRgb = function (hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
};

window.contrastedTextColor = function (R, G, B) {
  if (R && ! G && ! B) { // hex form
    const rgb = window.hexToRgb(R);
    if (! rgb) return '#333';
    R = rgb.r;
    G = rgb.g;
    B = rgb.b;
  }
  const C = [R / 255, G / 255, B / 255];
  for (let i = 0; i < C.length; ++i) {
    if (C[i] <= 0.03928) {
      C[i] = C[i] / 12.92;
    } else {
      C[i] = ((C[i] + 0.055) / 1.055) ** 2.4;
    }
  }
  const L = 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2];
  if (L > 0.300) { // L>0.179
    return '#333';
  }
  return 'white';
};

window.str2color = function (str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const c = (hash & 0x00FFFFFF).toString(16).toUpperCase();
  return "00000".substring(0, 6 - c.length) + c;
};
