<template>
  <!-- ALERT UNCONFIRMED ACCOUNT -->
  <v-app-bar v-if="unConfirmedUser && ! isUserFirstminutes && ! alertClosedByUser" flat color="background">
    <v-alert color="warningorange" type="warning" icon="far fa-envelope" prominent :height="60" :rounded="0" closable
             class="px-12 py-0 align-self-start">
      <div v-if="! sendEmailConfirmationDone" class="d-flex align-center">
        {{ $t('ALERT_BANNERS.UNCONFIRMED_ACCOUNT') }}
        <v-btn :loading="sendEmailConfirmationInProgress" variant="outlined" class="ml-4" @click="sendEmailConfirmation()">
          {{ $t('ALERT_BANNERS.RESEND_EMAIL_CONFIRMATION') }}
        </v-btn>
      </div>
      <span v-else>{{ $t('ALERT_BANNERS.EMAIL_CONFIRMATION_SENT_TO') }} {{ user.email }}</span>

      <template #close>
        <v-btn-icon size="44" class="mt-2" @click="alertClosedByUser = true">
          <v-icon>far fa-times</v-icon>
        </v-btn-icon>
      </template>
    </v-alert>
  </v-app-bar>
</template>

<script>

  export default {
    data() {
      return {
        sendEmailConfirmationInProgress: false,
        sendEmailConfirmationDone: false,
        alertClosedByUser: false,
      };
    },
    computed: {
      user() { return this.$store.state.users.user; },
      unConfirmedUser() { return this.user.id && ! this.user.confirmed; },
      isUserFirstminutes() {
        if (! this.user.created_at) return true;
        const userCreatedAt = moment(this.user.created_at); // will not be reactive as created_at never changes
        return moment().add(-10, 'minutes').isBefore(userCreatedAt) && userCreatedAt.isBefore();
      },
    },
    methods: {
      sendEmailConfirmation() {
        this.sendEmailConfirmationInProgress = true;
        window.apiSrv.call('users/sendemailconfirmation', 'index').then(() => {
          this.sendEmailConfirmationInProgress = false;
          this.sendEmailConfirmationDone = true;
        }).catch((message) => {
          this.sendEmailConfirmationInProgress = false;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Email confirmation could not be sent", body: `${message || "Something went wrong."}` });
        });
      },
    },
  };
</script>
