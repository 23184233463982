<template>
  <div>
    <planning-element-view v-for="el in laneElements" :id="`el${el.id}`" :key="el.id"
                           v-draggable="isKanbanMode ? kanbanDraggableConfig(el) : draggableConfig(el)"
                           v-resizable="isKanbanMode ? kanbanResizableConfig() : resizableConfig(el)"
                           :el="el" :disable-mini-element="isKanbanMode" :title="el.display_title"
                           :show-details-items="isSubLane ? subprojectsVisibleDetails : null"
                           :class="{ 'placeholder-el': el.isDragPlaceholder, selectable: isDynamic(el) }" :style="elementStyle(el)"
                           @select-color="selectColor(el)" @checklist-click="checklistClick(el, $event)" @progress-click="progressClick(el, $event)"
                           @click.stop="elementClick($event, el)" @open-meeting="openMeeting(el)" @click-subelement="subelementClick($event)">
      <!-- ELEMENT MENU -->
      <template #hover-menu>
        <div class="element-menu" :style="elementMenuPosition(el)" @click.stop>
          <div v-if="el.isType('macro', 'task') && isMiniElement(el)"
               class="text-left element-menu-title bg-el-color">
            {{ el.getTitle() || defaultTitle(el) }}
          </div>
          <div v-if="isDynamic(el)" :style="{ width: el.isType('macro') ? '30px' : '60px' }">
            <v-btn :title="$t(el.isType('macro', 'task') ? 'PLANNING.SELECT_ICON_AND_COLOR' : 'PLANNING.SELECT_ICON')"
                   icon size="x-small" variant="outlined"
                   @click.stop="selectColor(el)">
              <v-icon size="large" color="accent">fas fa-tint</v-icon>
            </v-btn>
            <v-btn v-if="! view && ! el.isType('macro')" :title="$t('PLANNING.DEPENDENCIES')" :disabled="! el.hasDates()"
                   icon size="x-small" variant="outlined" @click.stop="openDependencies(el)">
              <v-icon size="large">far fa-link</v-icon>
            </v-btn>
            <v-btn v-if="! el.isType('macro')" :title="$t('PLANNING.DUPLICATE')" icon size="x-small" variant="outlined" @click.stop="duplicateElement(el)">
              <v-icon size="large" color="info">far fa-copy</v-icon>
            </v-btn>
            <v-btn :title="$t('PLANNING.DELETE')" icon size="x-small" variant="outlined" @click.stop="deleteElement(el)">
              <v-icon size="large" color="errorred">far fa-trash-alt</v-icon>
            </v-btn>
            <v-btn v-if="! accessRight.hideBubbleMeeting && el.isType('milestone')" :title="$t('MEETING.BUBBLE_MEETING')" icon size="x-small" variant="outlined"
                   @click.stop="openMeeting(el)">
              <v-icon size="large" color="meetingprimary">far fa-users</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
      <!-- END ELEMENT MENU -->
      <!-- PLACEHOLDER INFO -->
      <div v-if="! isKanbanMode && (el.isDragPlaceholder || (el.isResizePlaceholder && el.isType('macro', 'task')))" class="placeholder-details">
        <div style="position:absolute;top: 0; right: 0; margin-right: 100%; padding-right: 5px" class="nobr">
          {{ $filters.moment(el.getStartTime(), 'mediumDateNoYear', planningLocale) }}
        </div>
        <div v-if="el.isType('macro', 'task')" style="position:absolute; top: 0; left: 0; margin-left: 100%; padding-left: 5px" class="nobr">
          {{ $filters.moment(el.getEndTime(), 'mediumDateNoYear', planningLocale) }}
        </div>
      </div>
      <!-- END PLACEHOLDER INFO -->
      <!-- UPDATE USER TOOLTIP -->
      <planning-element-view-modification-user v-if="el.modificationUser" :el="el"></planning-element-view-modification-user>
      <!-- END UPDATE USER TOOLTIP -->
    </planning-element-view>
  </div>
</template>

<style>
  .element-menu .element-menu-title {
    min-width: 50px;
    max-width: 200px;
    max-height: 64px;
    padding: 2px 3px;
    border-radius: 4px;
    margin-bottom: 3px;
    margin-right: 3px;
    overflow: hidden;
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import clientConfig from '@/client_customs/config';
  import constants from '@/js/constants.js';
  import PlanningElementView from './PlanningElementView.vue';
  import PlanningElementViewModificationUser from './PlanningElementViewModificationUser.vue';
  import draggable from './mixins/draggable';
  import resizable from './mixins/resizable';
  import selectable from './mixins/selectable';

  export default {
    components: {
      PlanningElementView,
      PlanningElementViewModificationUser,
    },
    emits: ['open-dependencies', 'open-meeting'],
    mixins: [draggable, resizable, selectable],
    props: {
      planning: { type: Object, required: true },
      lane: { type: Object, required: true },
      laneElements: { type: Array, default: () => ([]) },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      isSubLane: { type: Boolean, default: false },
      options: { type: Object, default: () => ({}) },
      events: { type: Object, default: () => ({}) },
    },
    data() {
      return {
        subprojectsVisibleDetails: this.planning.meta.companyconfig.subprojectsVisibleDetails || this.$store.getters['users/accessRight/config'].subprojectsVisibleDetails,
      };
    },
    computed: {
      isKanbanMode() {
        return this.$store.state.ui.planning.mode == 'kanban';
      },
      planningLocale() {
        return this.planning.timeline.getTimelocale();
      },
      accessRight() { return this.$store.state.users.accessRight; },
    },
    methods: {
      isDynamic(el) {
        return this.dynamic && (! this.view || el.access_right == 'modify');
      },
      canEditEl(el) {
        return this.options.elements && this.options.elements.canEditEl && this.options.elements.canEditEl(el) || this.isDynamic(el);
      },
      elementClick(event, el) {
        if (! this.canEditEl(el)) return;
        this.selectableClick(event, el);
        if (event.ctrlKey) return; // hold ctrl for selection only

        if (this.events.elementClick) {
          this.events.elementClick(el);
          return;
        }
        this.$store.dispatch('ui/planning/openElementDetails', el);
      },
      selectColor(el) {
        if (! this.canEditEl(el)) return;
        if (this.events.selectColor) {
          this.events.selectColor(el);
          return;
        }
        this.$store.dispatch('ui/planning/openColorPicker', el);
      },
      openDependencies(el) {
        if (! this.isDynamic(el)) return;
        this.$emit('open-dependencies', el);
      },
      duplicateElement(el) {
        if (clientConfig.elements && clientConfig.elements.customDuplicate) {
          clientConfig.elements.customDuplicate.cb.call(this, el);
          return;
        }
        this.$store.dispatch('ui/planning/addElement', { type: el.getType(), el });
      },
      deleteElement(el) {
        this.$store.dispatch('ui/planning/deleteElement', el);
      },
      openMeeting(el) {
        if (! this.isDynamic(el)) return;
        this.$emit('open-meeting', el);
      },
      elementStyle(el) {
        if (this.isKanbanMode) {
          return {
            cursor: this.canEditEl(el) ? 'pointer' : 'default',
          };
        }
        return {
          left: `${el.xposition}px`,
          width: `${el.getWidth()}px`,
          top: `${el.yposition}px`,
          cursor: this.canEditEl(el) ? 'pointer' : 'default',
        };
      },
      elementMenuPosition(el) {
        let { wrapperData } = this.$store.state.ui.planning;
        if (! wrapperData.width) {
          this.$store.commit('ui/planning/updateWrapperData', $("#table-wrapper"));
          wrapperData = this.$store.state.ui.planning.wrapperData;
        }
        const elPosition = { x: el.xposition, width: el.getWidth(), y: el.yposition };
        let planningTableWidth = this.planning.visibleTimeline.pxwidth;
        if (this.isKanbanMode) {
          const elNode = document.getElementById(`el${el.id}`);
          if (elNode) _.extend(elPosition, { x: elNode.offsetLeft, width: elNode.offsetWidth, y: elNode.offsetTop });
          const planningTableNode = document.getElementById('planning-table');
          if (planningTableNode) planningTableWidth = planningTableNode.offsetWidth;
        }
        const elementFreeSpaceRight = Math.min(wrapperData.width + wrapperData.scrollLeft, planningTableWidth) - (elPosition.x + elPosition.width);
        let top = 0;
        if (this.planning.lanes.at(-1) == this.lane) {
          const elementFreeSpaceBottom = this.lane.height - elPosition.y;
          const miniElementTitleHeight = this.isMiniElement(el) ? 66 : 0; // max-height: 64px;
          top = Math.min(0, elementFreeSpaceBottom - (miniElementTitleHeight + 55));
        }
        if (this.isKanbanMode && this.planning.process.steps.length == 1) { // only backlog
          return {
            right: 0,
            top: `${top}px`,
            'text-align': 'right',
            'margin-top': el.isType('macro', 'task') && el.hasIcon() ? '22px' : '0',
          };
        }
        return {
          left: elementFreeSpaceRight < 60 ? null : '100%',
          right: elementFreeSpaceRight < 60 ? '100%' : null,
          top: `${top}px`,
          'text-align': elementFreeSpaceRight > 60 ? 'left' : 'right',
          'margin-top': el.isType('macro', 'task') && el.hasIcon() ? '22px' : '0',
        };
      },
      defaultTitle(el) {
        return el.isType('macro') ? this.$t('PLANNING.NEW_MACRO_BUBBLE') : this.$t('PLANNING.NEW_BUBBLE');
      },
      isMiniElement(el) {
        return ! this.isKanbanMode && el.getWidth() <= constants.miniElementBreakWidth;
      },
      checklistClick(el, item) {
        if (! this.canEditEl(el)) return;
        if (this.events.checklistClick) {
          this.events.checklistClick(el, item);
          return;
        }
        this.startChangingElement();
        item.checked = ! item.checked;
        el.updateChecklistProgress();
        this.changingElement(el);
      },
      progressClick(el, event) {
        if (! this.canEditEl(el)) return;
        if (this.events.progressClick) {
          this.events.progressClick(el, event);
          return;
        }
        this.startChangingElement();
        const fullWidth = $(event.currentTarget).width();
        if (! fullWidth) return;
        el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
        this.changingElement(el);
      },
      subelementClick(elId) {
        const el = this.planning.elements.find(item => item.id == elId);
        if (! el || ! this.canEditEl(el)) return;
        if (this.events.elementClick) {
          this.events.elementClick(el);
          return;
        }
        this.$store.dispatch('ui/planning/openElementDetails', el);
      },
      ...mapActions('planning/elements', ['startChangingElement', 'changingElement']),
    },
  };
</script>
