export default {
  lang: {
    fr: {
      PROJECT_SHEET: {
        TARGET_ENDTIME: "Date MAD cible",
      },
    },
    en: {
      PROJECT_SHEET: {
        TARGET_ENDTIME: "Target MAD date",
      },
    },
  },
  sso: {
    protocol: 'openIDConnect',
    login: 'https://login.microsoftonline.com/64079cd0-2318-4789-95e3-36315b96e351/oauth2/v2.0/authorize?client_id=2e38c391-23c6-49a3-8083-340eccc95c1e&response_type=code&scope=openid%20email%20profile&redirect_uri=https%3A%2F%2Fbaccarat.bubbleplan.net',
    response_type: 'code',
  },
  hideManageAccount: true,
};
