<template>
  <v-card class="pa-6">
    <b>{{ $t('MANAGE.CONFIG.PLANNING_SHARE') }}</b>
    <div class="pt-2">
      <v-switch v-model="hasViews">
        <template #label>{{ $t('SHARE.CUSTOM_VIEWS') }}</template>
      </v-switch>
      <v-switch v-model="hideExtenalLink" :true-value="false" :false-value="true"
                :disabled="clientConfig.planningShare && clientConfig.planningShare.hideExtenalLink">
        <template #label>{{ $t('SHARE.INVITE_CUSTOMERS') }}</template>
      </v-switch>
    </div>
    <div class="py-4"></div>
    <b>{{ $t('MANAGE.CONFIG.FILES') }}</b>
    <div class="pt-2 d-flex">
      <v-checkbox v-model="links.hideOneDrive" :true-value="false" :false-value="true"
                  @update:model-value="setParam('links', links)">
        <template #label>{{ $t('MANAGE.CONFIG.ONEDRIVE') }}</template>
      </v-checkbox>
      <v-checkbox v-model="links.hideGoogleDrive" :true-value="false" :false-value="true" class="ml-4"
                  @update:model-value="setParam('links', links)">
        <template #label>{{ $t('MANAGE.CONFIG.GOOGLE_DRIVE') }}</template>
      </v-checkbox>
      <v-checkbox v-model="links.hideDropbox" :true-value="false" :false-value="true" class="ml-4"
                  @update:model-value="setParam('links', links)">
        <template #label>{{ $t('MANAGE.CONFIG.DROPBOX') }}</template>
      </v-checkbox>
      <v-checkbox v-model="links.hideFileUpload" :true-value="false" :false-value="true" class="ml-4"
                  @update:model-value="setParam('links', links)">
        <template #label>{{ $t('MANAGE.CONFIG.FILE_UPLOAD') }}</template>
      </v-checkbox>
    </div>
    <div class="py-4"></div>
    <b>{{ $t('MANAGE.CONFIG.CALENDAR_INTEGRATIONS') }}</b>
    <div class="pt-2 d-flex">
      <v-checkbox v-model="availableIntegrations" value="microsoft">
        <template #label>{{ $t('INTEGRATIONS.MICROSOFT_OUTLOOK') }}</template>
      </v-checkbox>
      <v-checkbox v-model="availableIntegrations" value="google" class="ml-4">
        <template #label>{{ $t('INTEGRATIONS.GOOGLE_CALENDAR') }}</template>
      </v-checkbox>
    </div>
  </v-card>
</template>

<script>
  export default {
    computed: {
      hasViews: {
        get() {
          return this.computedConfig.hasViews;
        },
        set(newVal) {
          this.setParam('hasViews', newVal);
        },
      },
      hideExtenalLink: {
        get() {
          return this.computedConfig.hideExtenalLink;
        },
        set(newVal) {
          this.setParam('hideExtenalLink', newVal);
        },
      },
      links() {
        return this.computedConfig.links;
      },
      availableIntegrations: {
        get() {
          return this.computedConfig.availableIntegrations;
        },
        set(newVal) {
          this.setParam('availableIntegrations', newVal);
        },
      },
      computedConfig() {
        return this.$store.getters['users/accessRight/config'];
      },
      clientConfig() {
        return this.$store.state.users.accessRight.clientConfig;
      },
    },
    methods: {
      setParam(prop, val) {
        this.$store.dispatch('users/accessRight/updateBusinessConfig', { [prop]: val });
      },
    },
  };
</script>
