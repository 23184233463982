import { getDefaultProcessConfig } from './helpers/defaultValues';

class PlanningProcessConfig {
  constructor(planning, srcData) {
    const data = angular.copy(srcData) || {};

    if (data instanceof PlanningProcessConfig) {
      _.extend(this, data);
    } else {
      angular.merge(this, getDefaultProcessConfig(), data);
    }

    this.getPlanning = function () {
      return planning;
    };
  }

  set(data) {
    Object.keys(data || {}).forEach((key) => {
      this[key] = data[key];
    });
  }

  getAll() {
    const nonNullData = {};
    const excludedKeys = ['getPlanning'];
    Object.keys(this).forEach((key) => { if (this[key] !== null && ! excludedKeys.includes(key)) nonNullData[key] = this[key]; });
    return nonNullData;
  }

  getShowPlannedElements() {
    if (typeof this.showPlannedElements != 'boolean') {
      const planning = this.getPlanning();
      if (planning && planning.elements.length < 50) return true; // when not set, activate if few elements
    }
    return this.showPlannedElements || false;
  }
  setShowPlannedElements(value) {
    this.showPlannedElements = value;
  }

  getHiddenSubsteps() {
    return this.hidden_substeps;
  }
  addHiddenSubstep(value) {
    if (! this.hidden_substeps) this.hidden_substeps = [];
    if (! this.hidden_substeps.includes(value)) this.hidden_substeps.push(value);
  }
  removeHiddenSubstep(value) {
    if (! this.hidden_substeps) return;
    const index = this.hidden_substeps.indexOf(value);
    if (index !== -1) this.hidden_substeps.splice(index, 1);
  }
}

export default PlanningProcessConfig;
