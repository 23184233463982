<template>
  <div class="text-center">
    <div v-if="! user.id">
      <v-icon size="large">far fa-spinner fa-spin</v-icon>
    </div>
    <template v-else>
      <div v-if="! avatarEditing" class="d-inline-block" style="position: relative">
        <user-avatar :user="user" :alt="$t('ACCOUNT.AVATAR')" :size="100"></user-avatar>
        <v-btn style="position: absolute; right: 0; bottom: 0; transform: translate(25%, 25%);" icon size="small" @click="avatarEditing = true">
          <v-icon>far fa-pencil-alt</v-icon>
        </v-btn>
      </div>

      <v-slide-y-transition hide-on-leave>
        <div v-if="avatarEditing">
          <div class="avatar-cropper" style="display: inline-block; position: relative">
            <croppa-vue-3 v-model="croppa" :placeholder="$t('ACCOUNT.SELECT_IMAGE')" :placeholder-font-size="16"
                          :zoom-speed="7" :file-size-limit="20971520" :quality="1" accept="image/*" prevent-white-space>
            </croppa-vue-3>
            <div v-show="chosenFile" class="controls" style="position: absolute; bottom: 0; left: calc(100% - 80px)">
              <v-btn icon size="x-small" @click="zoomIn()"><v-icon>far fa-search-plus</v-icon></v-btn>
              <v-btn icon size="x-small" @click="zoomOut()"><v-icon>far fa-search-minus</v-icon></v-btn>
            </div>
          </div>
          <div class="d-flex justify-center mt-4 gap-4">
            <v-btn variant="outlined" rounded @click="avatarEditing = false">Annuler</v-btn>
            <v-btn color="primary" rounded :loading="loading" :disabled="! chosenFile" @click="uploadAvatar">{{ $t('GLOBAL.SAVE') }}</v-btn>
          </div>
        </div>
      </v-slide-y-transition>
    </template>
  </div>
</template>

<style lang="scss">
  .avatar-cropper .croppa-container {
    border-radius: 50%;
    overflow: hidden;
  }
</style>

<script>
  import CroppaVue3 from '@/components/Reusables/CroppaVue3/cropper.vue';

  export default {
    components: {
      CroppaVue3,
    },
    data() {
      return {
        loading: false,
        croppa: {},
        avatarEditing: false,
      };
    },
    computed: {
      chosenFile() {
        return this.croppa.getChosenFile && this.croppa.getChosenFile();
      },
      user() { return this.$store.state.users.user; },
    },
    methods: {
      zoomIn() {
        if (! this.chosenFile) return;
        this.croppa.zoomIn();
      },
      resetCroppa() {
        this.croppa = {};
      },
      zoomOut() {
        if (! this.chosenFile) return;
        this.croppa.zoomOut();
      },
      uploadAvatar() {
        if (! this.chosenFile) return;
        this.loading = true;
        this.croppa.generateBlob((blob) => {
          if (! blob) return;
          blob.name = `${this.chosenFile.name.replace(/\..{3,4}$/, '')}.png`;
          window.apiSrv.uploadResource(blob).then((response) => {
            const id = response && response.data && response.data.file && response.data.file.id;
            if (! id) return Promise.reject(new Error('invalid upload'));
            return window.apiSrv.call('users', 'update', { id: this.user.id, avatar: response.data.file.url }).then(() => {
              this.$store.commit('users/setUser', { avatar: response.data.file.url });
              this.loading = false;
              this.avatarEditing = false;
            });
          });
        }, 'image/png');
      },
    },
  };
</script>
