<template>
  <section style="max-width: 1000px">
    <div v-if="! shared_projects_loaded && ! shared_projects_error" style="text-align:center"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-if="! shared_projects_loaded && shared_projects_error">{{ $t('GLOBAL.ERROR') }} : {{ shared_projects_error }}</div>
    <div v-show="shared_projects_loaded">
      <div v-if="(userIsAdmin && companyGroups.length) || userGroups.length">
        <b>{{ $t('MANAGE.GROUPS') }}</b>
        <v-card class="mt-2 mb-6">
          <div class="table-responsive">
            <table v-show="userGroups.length" class="table">
              <thead class="bg-lightgrey">
                <tr>
                  <th>{{ $t('MANAGE.GROUP') }}</th>
                  <th v-if="userIsAdmin" class="text-center">{{ $t('MANAGE.REMOVE') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="group in sortedUserGroups" :key="group.id">
                  <td>{{ group.title }}</td>
                  <td v-if="userIsAdmin" class="text-center">
                    <i :title="$t('MANAGE.REMOVE')" class="fa fa-fw fa-user-times access-right-icon" @click="outOfGroup(group)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="userIsAdmin" class="pa-6 flex-center-center">
            <v-select v-model="groupToAdd" :items="groupsUserIsNotIn.map(group => ({ value: group, title: group.title }))" style="max-width: 300px">
            </v-select>
            <v-btn :class="{ ' disabled': companyGroups.length == userGroups.length }" color="primary" rounded size="large" class="mx-2"
                   @click="addInGroup(groupToAdd);">
              {{ $t('MANAGE.ADD_MEMBER_IN_GROUP') }}
            </v-btn>
          </div>
        </v-card>
        <div class="py-6"></div>
      </div>
      <div>
        <b>{{ $t('MANAGE.USER_PROJECTS_ACR') }}</b>
        <div v-if="! shared_projects.length">{{ $t('MANAGE.NO_PROJECT') }}</div>
        <v-card v-if="shared_projects.length" class="mt-2 mb-6">
          <div class="table-responsive">
            <table class="table">
              <thead class="bg-lightgrey">
                <tr>
                  <th>{{ $t('MANAGE.PROJECT_NAME') }}</th>
                  <th class="text-center">{{ $t('MANAGE.ACCESS_RIGHT') }}</th>
                  <th v-if="nbPlanWithNotifs" class="text-center nobr">
                    {{ $t('NOTIFICATIONS.NOTIFICATIONS') }}&nbsp; <i :title="$t('NOTIFICATIONS.ACTIVATE_NOTIFICATIONS')" class="fa fa-info-circle text-medium-emphasis"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="project in shared_projects" :key="project.id">
                  <td class="pointer pl-6" @click="openProject(project);">{{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}</td>
                  <td class="text-center">
                    <div v-if="project.special_role">
                      <div v-if="project.special_role != 'owner'">{{ $t('ACR.' + $filters.uppercase(project.access_right)) }} ({{ $t('ACR.' + $filters.uppercase(project.special_role)) }})</div>
                      <div v-if="project.special_role == 'owner'">
                        {{ $t('ACR.' + $filters.uppercase(project.access_right)) }}
                        <v-menu v-model="ownerPopover[project.id]" :close-on-content-click="false" location="left" offset="20">
                          <template #activator="{ props }">
                            <v-btn size="small" variant="outlined" rounded class="ml-1" v-bind="props">{{ $t('ACR.OWNER') }}</v-btn>
                          </template>
                          <div class="bg-white px-4 py-2">
                            <span class="text-h6 text-primary">{{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}</span>
                            <manage-change-owner :project="project" class="mt-2 text-center" @change-owner="ownerPopover[project.id] = false; changeOwner($event);"></manage-change-owner>
                          </div>
                        </v-menu>
                      </div>
                    </div>
                    <div v-if="! project.canChangeRights">
                      <i :class="{ 'text-errorred': project.access_right == 'none' }" :style="{ opacity: project.access_right == 'none' ? 1 : .3 }"
                         :title="$t('ACR.NONE')" class="far fa-ban mx-1">
                      </i>
                      <i :class="{ 'text-successgreen': project.access_right == 'consult' }" :style="{ opacity: project.access_right == 'consult' ? 1 : .3 }"
                         :title="$t('ACR.CONSULT')" class="far fa-eye mx-1">
                      </i>
                      <i :class="{ 'text-successgreen': project.access_right == 'modify', invisible: user.access_right == 'consult' }"
                         :style="{ opacity: project.access_right == 'modify' ? 1 : .3 }" :title="$t('ACR.MODIFY')" class="far fa-pencil-alt mx-1">
                      </i>
                      <i :class="{ 'text-warningorange': project.access_right == 'admin', invisible: user.access_right == 'consult' }"
                         :style="{ opacity: project.access_right == 'admin' ? 1 : .3 }" :title="$t('ACR.ADMIN')" class="far fa-unlock-alt mx-1">
                      </i>
                    </div>
                    <div v-if="project.canChangeRights && ! project.special_role" :class="{ disabled: planning_access_rights_disabled[project.id] }">
                      <i :class="{ 'selected text-errorred': project.access_right == 'none' }" :title="$t('ACR.NONE')" class="far fa-ban access-right-icon mx-1"
                         @click="! planning_access_rights_disabled[project.id] && setPlanningUserRights(project, 'none')">
                      </i>
                      <i :class="{ 'selected text-successgreen': project.access_right == 'consult' }" :title="$t('ACR.CONSULT')" class="far fa-eye access-right-icon mx-1"
                         @click="! planning_access_rights_disabled[project.id] && setPlanningUserRights(project, 'consult')">
                      </i>
                      <i :class="{ 'selected text-successgreen': project.access_right == 'modify', invisible: user.access_right == 'consult' }"
                         :title="$t('ACR.MODIFY')" class="far fa-pencil-alt access-right-icon mx-1"
                         @click="! planning_access_rights_disabled[project.id] && setPlanningUserRights(project, 'modify')">
                      </i>
                      <i :class="{ 'selected text-warningorange': project.access_right == 'admin', invisible: user.access_right == 'consult' }"
                         :title="$t('ACR.ADMIN')" class="far fa-unlock-alt access-right-icon mx-1"
                         @click="! planning_access_rights_disabled[project.id] && setPlanningUserRights(project, 'admin')">
                      </i>
                    </div>
                  </td>
                  <td v-if="nbPlanWithNotifs" class="text-center">
                    <i v-show="project.canChangeNotifs && project.access_right != 'none'" :class="project.notifications ? 'fa-toggle-on text-successgreen' : 'fa-toggle-off'"
                       :style="{ opacity: notifications_disabled[project.id] ? .3 : 1 }" class="far pointer"
                       @click="! notifications_disabled[project.id] && toggleNotifications(project)">
                    </i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card>
      </div>
    </div>
  </section>
</template>

<script>
  import ManageChangeOwner from '../partials/ManageChangeOwner.vue';

  export default {
    components: {
      ManageChangeOwner,
    },
    props: {
      user: { type: Object, required: true },
    },
    data() {
      return {
        shared_projects_loaded: false,
        shared_projects: [],
        nbPlanWithNotifs: 0,
        planning_access_rights_disabled: {},
        notifications_disabled: [],
        newOwner: {},
        ownerPopover: {},
        groupToAdd: null,
      };
    },
    computed: {
      companyGroups() {
        return this.$store.state.users.groups.companyGroups;
      },
      userGroups() {
        return this.companyGroups.filter(group => group && group.users.find(user => user.id == this.user.id));
      },
      sortedUserGroups() {
        return _.sortBy(this.userGroups, 'title');
      },
      groupsUserIsNotIn() {
        return _.sortBy(this.companyGroups.filter(this.isUserNotInGroup), 'title');
      },
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
    },
    created() {
      [this.groupToAdd] = this.groupsUserIsNotIn;
      this.loadProjectsRights().catch((message) => {
        this.shared_projects_error = message || "";
      });
    },
    methods: {
      loadProjectsRights() {
        this.shared_projects_error = "";
        this.shared_projects_loaded = false;
        return window.apiSrv.call('sharedplannings/user', 'show', this.user.id).then((response) => {
          this.shared_projects = [];
          (response?.data?.shared_plannings || []).forEach((project) => {
            project.canChangeRights = this.canIChangeRightsOnProject(project);
            project.canChangeNotifs = project.canChangeRights || this.user.id == this.$store.state.users.user.id;
            if (project.canChangeNotifs) this.nbPlanWithNotifs++;
            this.shared_projects.push(project);
          });
          this.shared_projects.sort((a, b) => {
            const rights = ['admin', 'modify', 'consult', 'none'];
            const [indexA, indexB] = [rights.indexOf(a.access_right), rights.indexOf(b.access_right)];
            if (indexA == indexB) {
              return a.title < b.title ? -1 : 1;
            }
            return indexA > indexB ? 1 : -1;
          });
          this.shared_projects_loaded = true;
        });
      },
      canIChangeRightsOnProject(project) {
        if (! project) return false;
        if (this.userIsAdmin) return true;
        return project.access_right == "admin";
      },
      outOfGroup(group) {
        group.removeUser(this.user).then(() => {
          const index = (this.userGroups || []).findIndex(item => item.id === group.id);
          if (index == -1) return;
          this.userGroups.splice(index, 1);
        });
      },
      isUserNotInGroup(group) {
        return this.userGroups.indexOf(group) == -1;
      },
      setPlanningUserRights(project, newRight) {
        this.planning_access_rights_disabled[project.id] = true;
        const oldValue = project.access_right;
        project.access_right = newRight;
        window.apiSrv.call(`sharedplannings/planning/${project.id}/user`, 'update', { id: this.user.id, access_right: newRight }).then(() => {
          this.planning_access_rights_disabled[project.id] = false;
          this.$store.commit('eventbus/call', { event: 'update-project-tabs', param: { projectId: project.id, userId: this.user.id, data: { right: newRight } } });
        }).catch((message) => {
          project.access_right = oldValue;
          this.planning_access_rights_disabled[project.id] = false;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Project right was not modified", body: message || "" });
        });
      },
      toggleNotifications(project) {
        this.notifications_disabled[project.id] = true;
        const oldValue = project.notifications;
        project.notifications = ! project.notifications;
        window.apiSrv.call(`sharedplannings/planning/${project.id}/user`, 'update', { id: this.user.id, notifications: project.notifications }).then(() => {
          this.notifications_disabled[project.id] = false;
          this.$store.commit('eventbus/call', { event: 'update-project-tabs', param: { projectId: project.id, userId: this.user.id, data: { notifications: project.notifications } } });
        }).catch((message) => {
          project.notifications = oldValue;
          this.notifications_disabled[project.id] = false;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Project notifications was not modified", body: message || "" });
        });
      },
      addInGroup(group) {
        if (group) {
          group.addUser(this.user);
          this.userGroups.push(group);
        }
      },
      changeOwner({ projectId, ownerId }) {
        const project = this.shared_projects.find(item => item.id == projectId);
        if (! ownerId || ! project) return;
        project.owner_id = ownerId;
        this.loadProjectsRights().then(() => {
          const refreshedProject = this.shared_projects.find(item => item.id == projectId);
          const userNewData = { right: refreshedProject.access_right, special_role: refreshedProject.special_role || "" };
          this.$store.commit('eventbus/call', { event: 'update-project-tabs', param: { projectId, userId: this.user.id, data: userNewData } });
          this.$store.commit('eventbus/call', { event: 'update-project-tabs', param: { projectId, userId: ownerId, data: { right: "admin", special_role: "owner" } } });
          this.$store.commit('eventbus/call', { event: 'update-user-tabs', param: { userId: ownerId, projectId, data: { right: "admin", special_role: "owner" } } });
          this.$store.dispatch('manage/loadProjects');
        });
      },
      onUpdateProjectRightsForUser({ userId, projectId, data }) {
        if (userId != this.user.id) {
          console.error('incorrect user tab');
          return;
        }
        const project = this.shared_projects.find(item => item.id == projectId);
        if (! project) return;
        if (typeof data.special_role != 'undefined') project.special_role = data.special_role;
        if (data.special_role == 'owner') project.owner_id = this.user.id;
        if (data.right) project.access_right = data.right;
        if (typeof data.notifications != 'undefined') project.notifications = data.notifications;
        project.canChangeRights = this.canIChangeRightsOnProject(project);
        project.canChangeNotifs = project.canChangeRights || this.user.id == this.$store.state.users.user.id;
      },
      onUpdateUserRights() {
        this.loadProjectsRights().catch((message) => {
          this.shared_projects_error = message || "";
        });
      },
      openProject(project) {
        this.$store.dispatch('manage/openProjectFromId', project.id);
      },
    },
  };
</script>
