<template>
  <div v-if="content == 'categoryCorner'" :title="meeting.category"
       :style="{ 'border-color': `#${categoryColor} transparent transparent transparent`, 'border-width': `${cornerSize}px ${cornerSize}px 0 0` }"
       class="category-corner mb-2">
  </div>
  <div v-else-if="content == 'title'">
    <div class="text-h6">{{ meeting.title || 'Réunion sans titre' }}</div>
    <div v-if="meeting.category" class="text-subtitle-1">Dans la suite de réunions <b :style="`color: #${categoryColor}`">{{ meeting.category }}</b></div>
    <div v-else class="text-subtitle-1">Hors suite de réunions</div>
    <div v-if="meeting.status != 'draft'" row class="mt-2 text-caption d-flex flex-wrap">
      <!--
            <div :class="{ 'text-primary': meetingStatusIndex == 0 }" class="mr-4">
              <i class="far fa-info mr-1"></i>Détails
            </div>
      -->
      <div :class="{ 'text-primary': meetingStatusIndex == 1, 'text-grey': meetingStatusIndex < 1 }" class="mr-4">
        <i class="far fa-clipboard-check mr-1"></i>Préparation
      </div>
      <div :class="{ 'text-primary': meetingStatusIndex == 2, 'text-grey': meetingStatusIndex < 2 }" class="mr-4">
        <i class="far fa-chalkboard-teacher mr-1"></i>Animation
      </div>
      <div :class="{ 'text-primary': meetingStatusIndex == 3, 'text-grey': meetingStatusIndex < 3 }" class="mr-4">
        <i class="far fa-file-signature mr-1"></i>Compte rendu
      </div>
    </div>
    <div v-if="meeting.status == 'draft'" class="mt-2 text-caption font-weight-bold">
      Brouillon : les participants n'ont pas encore été invités.
    </div>
  </div>
  <div v-else-if="content == 'aboutme'">
    <div v-if="isMeetingAdmin">
      <p>Vous êtes <span class="bg-primary text-white" style="padding: 2px 5px; border-radius: 12px;">Organisateur</span> de cette réunion.</p>
    </div>
    <div v-if="meeting.status != 'closed'" wrap align-center justify-center>
      <div v-if="expectedAttendanceString[currentUser.expected_attendance]" class="pt-2 pb-0">
        Votre présence est
        <span :class="{ 'text-errorred': currentUser.expected_attendance == 'required', 'text-primary': currentUser.expected_attendance == 'optional' }">
          {{ expectedAttendanceString[currentUser.expected_attendance] }}
        </span>.
      </div>
      <div class="pt-0">
        <div class="mb-1">Participerez vous ?</div>
        <div>
          <v-btn v-for="option in participationChoices" :key="option.value" :active="currentUser.attendance == option.value"
                 :color="`${option.color}`" :variant="currentUser.attendance != option.value && 'outlined'" size="small" rounded class="mx-1" style="min-width: 50px"
                 @click="currentUser.attendance = option.value; saveMeetingUser();">
            {{ option.text }}
          </v-btn>
        </div>
      </div>
      <div v-if="['no', 'maybe'].includes(currentUser.attendance)" class="pt-0">
        <v-text-field ref="attendanceReasonTextField" v-model="currentUser.attendance_reason"
                      label="Raison de votre absence" append-icon="fa fa-check-circle"
                      @change="saveMeetingUser" @click:append="$refs.attendanceReasonTextField.blur()">
        </v-text-field>
      </div>
    </div>
    <div v-else-if="['yes', 'no'].includes(currentUser.attendance)">
      <div v-if="currentUser.attendance == 'yes'">Vous avez participé à cette réunion.</div>
      <div v-if="currentUser.attendance == 'no'">
        Vous n'avez pas participé à cette réunion <i v-if="currentUser.attendance_reason">({{ currentUser.attendance_reason }})</i>.
      </div>
    </div>
    <div v-else-if="! isMeetingAdmin" class="text-medium-emphasis">Aucune information disponible</div>
  </div>
  <div v-else-if="content == 'description'">
    <div v-if="meeting.description">
      <b>Ordre du jour</b>
      <div class="pre">{{ meeting.description }}</div>
    </div>
    <div v-else class="text-medium-emphasis">Aucun ordre du jour</div>
  </div>
  <div v-else-if="content == 'date'">
    <b v-if="displayedDate">{{ displayedDate }}</b>
    <div v-else class="text-medium-emphasis">A planifier</div>
  </div>
  <div v-else-if="content == 'place'" class="pre">
    <div v-if="meeting.location_address || meeting.location">
      <b>{{ meeting.location_address }}</b>
      <div v-if="meeting.location_address && meeting.location" class="font-italic">{{ meeting.location }}</div>
      <div v-else class="font-weight-bold">{{ meeting.location }}</div>
    </div>
    <div v-else class="text-medium-emphasis">Lieu à déterminer</div>
  </div>
  <div v-else-if="content == 'attendees'">
    <template v-if="meeting.users.length">
      <b>Participants</b>
      <div style="display: flex; flex-wrap: wrap; margin-bottom: -8px">
        <template v-for="(user, index) in meeting.users" :key="index">
          <avatar-tooltiped :user="user" :border-color="attendanceColorText(user.attendance)">
            <div class="text-center">
              <div>
                <b>{{ $filters.username(user) }}</b> {{ attendanceText(user.attendance) }}
              </div>
              <div v-if="isMeetingAdmin && expectedAttendanceString[user.expected_attendance]">
                Présence {{ expectedAttendanceString[user.expected_attendance] }}
              </div>
              <div v-if="user.access_right == 'admin'">
                Organisateur
              </div>
            </div>
          </avatar-tooltiped>
        </template>
      </div>
    </template>
    <div v-else class="text-medium-emphasis">Aucun participant renseigné</div>
  </div>
  <div v-else-if="content == 'summary'">
    <b>Compte rendu</b>
    <div class="editor-text" v-html="meeting.summary"></div>
  </div>
</template>

<style>
  .category-corner {
    flex: 0 0 0;
    align-self: flex-start;
    margin-right: -16px;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .editor-text .ql-indent-1{
    margin-left: 4.5em;
  }
  .editor-text .ql-indent-2{
    margin-left: 7.5em;
  }
  .editor-text .ql-indent-3{
    margin-left: 10.5em;
  }
  .editor-text .ql-indent-4{
    margin-left: 13.5em;
  }
  .editor-text .ql-indent-5{
    margin-left: 16.5em;
  }
  .editor-text .ql-indent-6{
    margin-left: 19.5em;
  }
  .editor-text .ql-indent-7{
    margin-left: 22.5em;
  }
  .editor-text .ql-indent-8{
    margin-left: 25.5em;
  }
  .editor-text .ql-size-large{
    font-size: 1.5em;
  }
  .editor-text .ql-size-huge{
    font-size: 2.5em;
  }
  .editor-text .ql-size-small{
    font-size: 0.75em;
  }
</style>

<script>
  // import { mapState } from 'vuex';
  // import utils from '@/utils.js';
  // import Meeting from '@/models/Meeting';
  import avatarTooltiped from './AvatarTooltiped';

  export default {
    components: {
      avatarTooltiped,
    },
    props: {
      content: { required: true, type: String },
      fromMeeting: { required: false, type: Object, default: null },
      cornerSize: { type: Number, default: 100 },
    },
    data() {
      return {
        participationChoices: [{ value: 'yes', title: 'Oui', color: 'successgreen' }, { value: 'no', title: 'Non', color: 'errorred' }, { value: 'maybe', title: 'Peut-être', color: 'secondary' }],
        expectedAttendanceString: { required: 'Requise', no: 'Excusée', optional: 'Facultative' },
        currentUser: {},
        statusString: { draft: "Brouillon", closed: "Close", preparation: "Préparation", animation: "En cours" },
        meetingUser: null,
        rootMeeting: null,
      };
    },
    computed: {
      isMeetingAdmin() { return (this.fromMeeting || ! this.meetingUser) ? false : this.$store.getters['current/isMeetingAdmin']; },
      meeting() { return this.fromMeeting || this.rootMeeting; },
      meetingStatusIndex() {
        // return Meeting.statusIndex(this.meeting.status || 'preparation');
        const order = ['draft', 'preparation', 'animation', 'closed'];
        return order.indexOf(this.meeting.status || 'preparation');
      },
      categoryColor() {
        if (! this.meeting.category) return 'fff';
        // return utils.str2color(this.meeting.category);
        return window.str2color(this.meeting.category);
      },
      displayedDate() {
        const date = moment(this.meeting.date);
        if (! date.isValid()) return null;
        const endDate = moment(date).add(this.meeting.duration, 'seconds');
        return this.meeting.duration ? `${date.format("dddd DD MMMM YYYY [de] HH:mm")} à ${endDate.format("HH:mm")}` : date.format("dddd DD MMMM YYYY [à] HH:mm");
      },
      // ...mapState('current', { rootMeeting: 'meeting' }),
      // ...mapState('current', ['meetingUser']),
    },
    created() {
      this.currentUser = this.fromMeeting ? null : this.meetingUser;
    },
    methods: {
      saveMeetingUser() { this.meeting.saveUser(this.meetingUser, 'update'); },
      attendanceText(attendance) {
        if (attendance == 'yes') {
          return "participera";
        }
        if (attendance == 'no') {
          return "ne participera pas";
        }
        return attendance == 'maybe' ? "participera peut-être" : "n'a pas répondu";
      },
      attendanceColorText(attendance) {
        if (attendance == 'yes') {
          return 'successgreen';
        }
        if (attendance == 'no') {
          return 'errorred';
        }
        return attendance == 'maybe' ? 'warningorange' : '';
      },
    },
  };
</script>
