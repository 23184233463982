<template>
  <div v-if="isLoaded && (inProgressActions.length || completedActions.length)">
    <h4>{{ $t('MEETING.ACTIONS_IN_PROGRESS') + ` (${inProgressActions.length})` }}</h4>
    <v-expansion-panels v-model="panelProgressActions" flat hover style="z-index: 9999 !important;">
      <v-expansion-panel v-for="action in inProgressActions" :key="action.data.id">
        <v-expansion-panel-title class="py-0 pl-0" @click="openedAction = action">
          <meeting-actions-item :action="action" @click-complete="checkAction(action)"></meeting-actions-item>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <open-action class="pr-0 py-0 mt-n6" :action="action" @close="closeOpenedAction"></open-action>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <h4>{{ $t('MEETING.ACTIONS_COMPLETED') + ` (${completedActions.length})` }}</h4>
    <v-expansion-panels v-model="panelCompleteActions" flat hover style="z-index: 9999 !important;">
      <v-expansion-panel v-for="action in completedActions" :key="action.data.id">
        <v-expansion-panel-title class="py-0 pl-0" @click="openedAction = action">
          <meeting-actions-item :action="action" @click-uncomplete="checkAction(action)"></meeting-actions-item>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <open-action class="pr-0 py-0 mt-n6" :action="action" @close="closeOpenedAction"></open-action>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import OpenAction from '@/components/Dashboards/Actions/OpenAction';
  import MeetingActionsItem from './MeetingActionsItem';

  export default {
    components: {
      OpenAction,
      MeetingActionsItem,
    },
    props: {
      meetingActions: { type: Array, default: null },
    },
    data() {
      return {
        isLoaded: false,
        openedAction: null,
        panelProgressActions: [],
        panelCompleteActions: [],
      };
    },
    computed: {
      inProgressActions() {
        return this.meetingActions.filter(action => ! action.data.completed_at)
          .sort((itemA, itemB) => moment(itemA.data.due_date).diff(itemB.data.due_date));
      },
      completedActions() {
        return this.meetingActions.filter(action => action.data.completed_at)
          .sort((itemA, itemB) => moment(itemA.data.completed_at).diff(itemB.data.completed_at));
      },
    },
    created() {
      if (this.meetingActions.length) {
        this.isLoaded = true;
      }
    },
    methods: {
      checkAction(action) {
        action.setChecked(! action.getChecked());
        this.saveAction(action);
      },
      saveAction(action) {
        action.save().catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : action was not saved", body: message });
        });
      },
      closeOpenedAction(reason) {
        if (reason == 'save') {
          if (this.openedAction.type == 'meeting' && ! this.meetingActions.includes(this.openedAction)) this.meetingActions.push(this.openedAction);
        } else if (reason == 'delete') {
          if (this.openedAction.type == 'meeting') {
            const index = this.meetingActions.findIndex(item => item == this.openedAction);
            if (index > -1) this.meetingActions.splice(index, 1);
          }
        } else {
          this.openedAction.reset();
        }
        this.openedAction = null;
        this.panelProgressActions = [];
        this.panelCompleteActions = [];
      },
    },
  };
</script>
