<template>
  <div class="element-details-card-header">
    <element-details-config-button :el="el" config="show-checklist" class="ml-2"></element-details-config-button>
  </div>
  <div class="my-2" style="margin-left: -20px">
    <draggable :list="checklist" item-key="id" handle=".checklist-draggable-anchor" @update="updateChecklist">
      <template #item="{ element: item, index: itemIndex }">
        <element-details-checklist-item :el="el" :checklist="checklist" :item="item" :duedate-picker-start="duedatePickerStart"
                                        @update-checklist="updateChecklist" @remove-checklist-item="removeChecklistItem(itemIndex)"
                                        @add-responsible="addResponsible(item, $event)">
        </element-details-checklist-item>
      </template>
    </draggable>
  </div>
  <div class="mt-3 mb-1"><label for="checklist">{{ $t('ELEMENT-DETAIL.NEW_CHECKLIST_ITEM') }}</label></div>
  <form class="d-flex align-center" style="margin-left: -12px" @submit.prevent="addChecklistItem()">
    <v-checkbox-btn disabled style="flex: 0 0 auto"></v-checkbox-btn>
    <v-text-field id="checklist" v-model="addChecklistItemTitle"></v-text-field>
    <v-btn :disabled="! addChecklistItemTitle" color="primary" class="ml-2" type="submit">{{ $t('ELEMENT-DETAIL.ADD') }}</v-btn>
  </form>
</template>

<script>
  import draggable from 'vuedraggable';
  import { getDefaultChecklistItem } from '@/models/helpers/defaultElements';
  import ElementDetailsChecklistItem from './ElementDetailsChecklistItem.vue';
  import ElementDetailsConfigButton from './partials/ElementDetailsConfigButton.vue';

  export default {
    components: {
      draggable,
      ElementDetailsChecklistItem,
      ElementDetailsConfigButton,
    },
    props: {
      el: { type: Object, required: true },
    },
    emits: ['add-user', 'update-progress'],
    data() {
      return {
        checklist: this.el.getChecklist() || [],
        addChecklistItemTitle: "",
        duedatePickerStart: { year: null, month: null }, // open all duedate pickers on the same month
      };
    },
    computed: {
      elStartTime() {
        return this.el.getStartTime();
      },
      assignActionsToCreator() {
        return this.$store.getters['users/accessRight/config'].assignActionsToCreator;
      },
    },
    updated() {
      const elCheckList = this.el.getChecklist();
      if (elCheckList) this.checklist = elCheckList;
    },
    watch: {
      elStartTime: {
        handler(elStartTime) {
          this.duedatePickerStart = { year: elStartTime && +elStartTime.year(), month: elStartTime && +elStartTime.month() };
        },
        immediate: true,
      },
    },
    methods: {
      addChecklistItem() {
        if (! this.addChecklistItemTitle) return;
        const checklistItem = { ...getDefaultChecklistItem(), title: this.addChecklistItemTitle };
        if (this.assignActionsToCreator) {
          this.addResponsible(checklistItem, this.$store.state.users.user);
        }
        this.checklist.push(checklistItem);
        this.updateChecklist();
        this.addChecklistItemTitle = "";
      },
      removeChecklistItem(index) {
        if (index > -1 && index < this.checklist.length) {
          this.checklist.splice(index, 1);
          this.updateChecklist();
        }
      },
      addResponsible(item, user) {
        if (! user) return; // click on input clear button
        if (user.group_id) {
          item.user_id = null;
          item.group_id = user.group_id;
          item.username = user.title;
        } else if (user.id) {
          item.user_id = user.id;
          item.group_id = null;
          item.username = this.$store.getters['users/getUsername'](user, 'short');
        } else if (user.username) {
          item.user_id = null;
          item.group_id = null;
          item.username = user.username;
        }
        this.$emit('add-user', user);
      },
      updateChecklist() {
        this.el.setChecklist(this.checklist);
        this.$emit('update-progress');
      },
    },
  };
</script>
