<template>
  <div class="pb-12">
    <!-- ADMIN ONLY -->
    <div class="position-relative">
      <v-btn-icon style="position: absolute" :style="$vuetify.display.xs ? 'top: -40px' : $vuetify.display.sm ? '' : 'left: -56px'" @click="$emit('go-to-previous-slide')">
        <v-icon>far fa-long-arrow-alt-left</v-icon>
      </v-btn-icon>
      <div class="text-primary text-center my-6" style="font-size: 32px;"><b>{{ $t('PREMIUM.SUBSCRIPTION.BILLING_ADDRESS') }}</b></div>
    </div>
    <div>
      <div class="mb-1"><b>{{ $t('PREMIUM.SUBSCRIPTION.COMPANY_NAME') }}</b></div>
      <v-text-field ref="companyName" v-model="companyName" bg-color="white" :error-messages="companyNameError"
                    prepend-inner-icon="far fa-building">
      </v-text-field>
      <div class="mt-4 mb-1"><b>{{ $t('PREMIUM.SUBSCRIPTION.ADMINISTRATIVE_EMAIL') }}</b></div>
      <v-text-field v-model="companyEmail" bg-color="white" prepend-inner-icon="far fa-envelope"></v-text-field>
      <div class="mt-4 mb-1"><b>{{ $t('PREMIUM.SUBSCRIPTION.BILLING_ADDRESS') }}</b></div>
      <v-textarea v-model="companyPostalAddress" bg-color="white" rows="4"
                  prepend-inner-icon="far fa-map-marker-alt">
      </v-textarea>
      <div class="mt-4 mb-1"><b>{{ $t('PREMIUM.SUBSCRIPTION.DOMICILIATION') }}</b></div>
      <div class="d-sm-flex">
        <v-select v-model="domiciliation" :items="domiciliationOptions" bg-color="white"></v-select>
        <v-text-field v-if="domiciliation != 'fr'" v-model="domiciliationInput" bg-color="white" class="ml-2" style="min-width: 50%"
                      :label="domiciliation == 'eu' ? $t('PREMIUM.SUBSCRIPTION.VAT_NUMBER') : $t('PREMIUM.SUBSCRIPTION.COUNTRY')" :error-messages="vatError"
                      @change="updateVatError">
        </v-text-field>
      </div>

      <!-- Btns Back & Change -->
      <div class="mt-6 text-center">
        <v-btn class="ml-auto" color="successgreen" :disabled="! canGoToNextSlide" :loading="completeCompanyInprogress" rounded size="large"
               @click="completeCompany()">
          {{ $t('PREMIUM.SUBSCRIPTION.CHANGE_ADDRESS') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    emits: ['go-to-next-slide', 'go-to-previous-slide', 'update:vat-rate'],
    data() {
      return {
        company: {},
        companyEmail: "",
        companyName: "",
        companyPostalAddress: "",
        companyNameError: "",
        domiciliation: 'fr',
        domiciliationOptions: [
          { value: 'fr', title: this.$t('PREMIUM.SUBSCRIPTION.FRANCE') },
          { value: 'eu', title: this.$t('PREMIUM.SUBSCRIPTION.EUROPEAN_UNION') },
          { value: 'non-eu', title: this.$t('PREMIUM.SUBSCRIPTION.NON_EU') },
        ],
        domiciliationInput: '',
        vatError: "",
        completeCompanyInprogress: false,
        regexEUVatNumber: /^((AT)(U\d{8})|(BE)(0\d{9})|(BG)(\d{9,10})|(CY)(\d{8}[LX])|(CZ)(\d{8,10})|(DE)(\d{9})|(DK)(\d{8})|(EE)(\d{9})|(EL|GR)(\d{9})|(ES)([\dA-Z]\d{7}[\dA-Z])|(FI)(\d{8})|(FR)([\dA-Z]{2}\d{9})|(HU)(\d{8})|(IE)(\d{7}[A-Z]{2})|(IT)(\d{11})|(LT)(\d{9}|\d{12})|(LU)(\d{8})|(LV)(\d{11})|(MT)(\d{8})|(NL)(\d{9}(B\d{2}|BO2))|(PL)(\d{10})|(PT)(\d{9})|(RO)(\d{2,10})|(SE)(\d{12})|(SI)(\d{8})|(SK)(\d{10}))$/i,
      };
    },
    computed: {
      companyVat: {
        get() {
          if (this.domiciliation == 'non-eu') return `non-EU-${this.domiciliationInput}`;
          return this.domiciliation == 'eu' ? this.domiciliationInput.toUpperCase().replace(/\s/g, '') : 'france';
        },
        set(newVal) {
          if (newVal.startsWith('non-EU-')) {
            this.domiciliation = 'non-eu';
            this.domiciliationInput = newVal.replace('non-EU-', '');
          } else if (newVal.toUpperCase().replace(/\s/g, '').match(this.regexEUVatNumber)) { // if valid VAT Number
            this.domiciliation = 'eu';
            this.domiciliationInput = newVal.toUpperCase().replace(/\s/g, '');
          } else {
            this.domiciliation = 'fr';
          }
        },
      },
      canGoToNextSlide() {
        if (! this.companyEmail || ! this.companyName || ! this.companyPostalAddress) return false;
        return true;
      },
      user() { return this.$store.state.users.user; },
      isAdmin() { return this.$store.state.users.accessRight.isAdmin; },
    },
    created() {
      this.$store.state.users.userPromise.then((user) => {
        this.company = user.company;
        this.companyEmail = this.company?.email;
        this.companyName = this.company?.name || '';
        this.companyPostalAddress = this.company?.postal_address || '';
        this.companyVat = this.company?.vat || '';
      });
    },
    methods: {
      updateVatError() {
        if (this.domiciliation == 'eu' && ! this.companyVat.match(this.regexEUVatNumber)) {
          this.vatError = this.$t('PREMIUM.SUBSCRIPTION.VAT_NUMBER_ERROR');
        } else {
          this.vatError = '';
        }
      },
      completeCompany() {
        if (! this.isAdmin) return;
        if (! this.company?.id) {
          this.$store.dispatch('ui/msgbox/open', { body: this.$t('PREMIUM.SUBSCRIPTION.YOU_HAVE_NO_COMPANY'), type: 'vdialog' });
          return;
        }
        if (! this.companyEmail || ! this.companyName || ! this.companyPostalAddress) {
          this.$store.dispatch('ui/msgbox/open', { body: this.$t('PREMIUM.SUBSCRIPTION.PLEASE_COMPLETE_FIELDS'), type: 'vdialog' });
          return;
        }
        if (this.companyName.length < 3 || this.companyName.length > 50) {
          this.companyNameError = this.$t('PREMIUM.SUBSCRIPTION.COMPANY_NAME_ERROR');
          return;
        }
        this.$emit('update:vat-rate', 0.2);
        if (this.domiciliation == 'eu') {
          if (! this.companyVat.match(this.regexEUVatNumber)) { // if invalid EU Vat Number
            this.vatError = this.$t('PREMIUM.SUBSCRIPTION.VAT_NUMBER_ERROR');
            return;
          }
          if (! this.companyVat.startsWith('FR')) this.$emit('update:vat-rate', 0);
        }
        this.companyNameError = '';
        this.vatError = '';
        if (this.companyEmail == this.company.email && this.companyName == this.company.name && this.companyVat == this.company.vat && this.companyPostalAddress == this.company.postal_address) {
          this.$emit('go-to-next-slide');
          return;
        }
        this.completeCompanyInprogress = true;
        const companyData = {
          id: this.user.company_id,
          email: this.companyEmail,
          name: this.companyName,
          postal_address: this.companyPostalAddress,
          vat: this.companyVat,
        };
        window.apiSrv.call('companies', 'update', companyData).then(() => {
          this.user.company.email = this.companyEmail;
          this.user.company.name = this.companyName;
          this.user.company.postal_address = this.companyPostalAddress;
          this.user.company.vat = this.companyVat;
          this.completeCompanyInprogress = false;
          this.$emit('go-to-next-slide');
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Company was not modified", body: message || "", type: 'vdialog' });
          this.completeCompanyInprogress = false;
        });
      },
    },
  };
</script>
