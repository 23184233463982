<template>
  <v-card class="pa-6 d-flex flex-column gap-4">
    <manage-profile-my-profile-avatar class="mt-4 mb-6"></manage-profile-my-profile-avatar>

    <!-- Firstname and Lastname -->
    <div class="d-flex column-gap-4">
      <v-text-field v-model="firstname" :placeholder="$t('ACCOUNT.FIRSTNAME')"
                    prepend-inner-icon="far fa-user" style="flex: 1 1 50%" @change="updateProfile()"></v-text-field>
      <v-text-field v-model="lastname" :placeholder="$t('ACCOUNT.LASTNAME')"
                    style="flex: 1 1 50%" @change="updateProfile()"></v-text-field>
    </div>
    <!-- Phone number, language and e-mail -->
    <v-text-field v-model="phone" :placeholder="$t('LOGIN.PHONE')" @change="updateProfile()"
                  prepend-inner-icon="far fa-phone"></v-text-field>
    <v-select v-model="lang" :items="[{ value: 'fr', title: 'Français' }, { value: 'en', title: 'English' }]"
              :label="$t('ACCOUNT.EMAIL_LANGUAGE')" prepend-inner-icon="far fa-globe-europe"
              @update:model-value="updateProfile()">
    </v-select>
    <div class="d-flex">
      <v-text-field v-model="email" :placeholder="$t('GLOBAL.EMAIL')" prepend-inner-icon="far fa-at"
                    disabled></v-text-field>
      <v-btn-icon v-show="displayEmailEditButton" color="primary" class="ml-2" @click="changeEmail()">
        <v-icon>far fa-pencil-alt</v-icon>
      </v-btn-icon>
    </div>
    <!-- Change e-mail Field-->
    <form @submit.prevent="updateEmail" v-if="displayChangeEmail" class="d-flex column-gap-4">
      <v-text-field v-model="newEmail" @update:model-value="errorEmail = ''" :hide-details="errorEmail !== ''" :placeholder="$t('ACCOUNT.NEW_EMAIL')" :rules="[rules.email]"
                    style="flex: 1 1 50%"></v-text-field>
      <v-text-field v-model="check_passwordEmail" @update:model-value="errorEmail = ''" :hide-details="errorEmail !== ''" :placeholder="$t('GLOBAL.PASSWORD')" type="password"
                    style="flex: 1 1 50%"></v-text-field>
      <v-btn :loading="loading" color="primary" rounded size="large" type="submit"
             :disabled="newEmail === '' || check_passwordEmail === ''">
        {{ $t('GLOBAL.SAVE') }}
      </v-btn>
    </form>
    <div v-show="errorEmail">
      <p class="errorred">{{ errorEmail }}</p>
    </div>
    <!-- Password -->
    <div class="d-flex">
      <v-text-field :placeholder="$t('GLOBAL.PASSWORD')" type="password" model-value="**************"
                    prepend-inner-icon="far fa-lock" disabled></v-text-field>
      <v-btn-icon v-show="displayPasswordEditButton" color="primary" class="ml-2" @click="changePassword()">
        <v-icon>far fa-pencil-alt</v-icon>
      </v-btn-icon>
    </div>
    <!-- Field to change password -->
    <form @submit.prevent="updatePassword" v-if="displayChangePassword" class="d-flex column-gap-4">
      <v-text-field v-model="check_passwordPassword" @update:model-value="errorPassword = ''" :placeholder="$t('ACCOUNT.OLD_PASSWORD')" type="password"
                    style="flex: 1 1 50%"></v-text-field>
      <v-text-field v-model="newPassword" @update:model-value="errorPassword = ''" :placeholder="$t('ACCOUNT.NEW_PASSWORD')" type="password"
                    style="flex: 1 1 50%"></v-text-field>
      <v-text-field v-model="passwordConfirmation" @update:model-value="errorPassword = ''" :placeholder="$t('ACCOUNT.CONFIRM_PASSWORD')" type="password"
                    style="flex: 1 1 50%"></v-text-field>
      <v-btn :loading="loading" color="primary" rounded size="large" type="submit">{{ $t('GLOBAL.SAVE') }}</v-btn>
    </form>
    <div v-show="errorPassword">
      <p class="errorred">{{ errorPassword }}</p>
    </div>
    <account-link-to-website class="mt-6"></account-link-to-website>
  </v-card>
</template>

<script>
  import ManageProfileMyProfileAvatar from './ManageProfileMyProfileAvatar.vue';
  import AccountLinkToWebsite from '@/components/Account/AccountLinkToWebsite.vue';

  export default {
    components: {
      ManageProfileMyProfileAvatar,
      AccountLinkToWebsite,
    },
    data() {
      return {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        newEmail: "",
        check_passwordEmail: "",
        check_passwordPassword: "",
        lang: "",
        newPassword: "",
        passwordConfirmation: "",
        displayChangePassword: false,
        displayPasswordEditButton: true,
        displayChangeEmail: false,
        displayEmailEditButton: true,
        errorEmail: "",
        errorPassword: "",
        loading: false,
        rules: {
          email: value => window.isValidEmailAddress(value) || this.$t('ACCOUNT.INVALID_EMAIL'),
        },
      };
    },
    computed: {
      user() { return this.$store.state.users.user; },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        this.firstname = this.user.firstname;
        this.lastname = this.user.lastname;
        this.phone = this.user.phone;
        this.email = this.user.email;
        this.lang = this.user.lang;
      });
    },
    methods: {
      changeEmail() {
        this.displayEmailEditButton = false;
        this.displayChangeEmail = true;
        this.displayPasswordEditButton = true;
        this.displayChangePassword = false;
      },
      changePassword() {
        this.displayPasswordEditButton = false;
        this.displayChangePassword = true;
        this.displayEmailEditButton = true;
        this.displayChangeEmail = false;
      },
      updateProfile() {
        this.errorPassword = "";
        const data = {
          id: this.user.id,
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phone,
          lang: this.lang,
        };
        this.loading = true;
        window.apiSrv.call('users', 'update', data).then(() => {
          this.$store.commit('users/setUser', data);
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: this.$t('API.SAVING_ERROR'), body: `${message || "Something went wrong."}` });
        }).finally(() => {
          this.loading = false;
        });
      },
      updateEmail() {
        const data = {
          id: this.user.id,
          email: this.newEmail,
          check_password: this.check_passwordEmail,
        };
        this.loading = true;
        window.apiSrv.call('users', 'update', data).then(() => {
          this.$store.commit('users/setUser', data);
        }).catch((message) => {
          this.errorEmail = typeof message == 'object' && message.email || message;
        }).finally(() => {
          this.loading = false;
        });
      },
      updatePassword() {
        const data = {
          id: this.user.id,
          check_password: this.check_passwordPassword,
          password: this.newPassword,
          password_confirmation: this.passwordConfirmation,
        };
        this.loading = true;
        window.apiSrv.call('users', 'update', data).then(() => {
          this.$store.commit('users/setUser', data);
        }).catch((message) => {
          this.errorPassword = typeof message == 'object' && message.password || message;
        }).finally(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
