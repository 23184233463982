<template>
  <div class="pt-6 px-6 d-flex align-baseline">
    <span class="text-h6 font-weight-bold">{{ $t(sectionTitles[section]) }}</span>
    <select v-model="lang" class="text-medium-emphasis ml-auto mr-n1" style="background: transparent; border: none; padding: 2px 4px; outline: none">
      <option value="fr">FR</option>
      <option value="en">EN</option>
    </select>
  </div>
  <v-card class="mt-4 mx-6 pa-12 flex-1-0 d-flex flex-column" v-bind="$attrs">
    <template v-if="section == 'login'">
      <form ref="formLogin" @submit.prevent="submitLogin">
        <v-text-field v-model="email" :placeholder="$t('GLOBAL.EMAIL')" :disabled="isSubmittingLogin"
                      prepend-inner-icon="far fa-at" autocomplete="email" autofocus required>
        </v-text-field>
        <div class="mt-4"></div>
        <v-text-field v-model="password" :placeholder="$t('GLOBAL.PASSWORD')" :error-messages="loginMsg" :disabled="isSubmittingLogin"
                      type="password" prepend-inner-icon="far fa-lock" required>
        </v-text-field>
        <v-text-field v-if="showTfa" v-model="tfaCode" placeholder="2FA" :disabled="isSubmittingLogin"
                      prepend-inner-icon="far fa-phone" class="mt-2">
        </v-text-field>
        <div ref="formCaptcha" style="margin: 8px -24px 0;"></div>
        <div class="form-actions form-group text-center mt-6">
          <v-btn :disabled="isSubmittingLogin" :loading="isSubmittingLogin" type="submit" color="primary" class="px-12" rounded size="large">
            {{ $t('LOGIN.SUBMIT') }}
          </v-btn>
        </div>
      </form>
      <div class="mt-12"></div>
      <div class="mt-auto text-center">
        <div>
          <v-btn variant="text" @click.prevent="navigateSection('password-remind')">{{ $t('LOGIN.FORGOT_PASSWORD') }}</v-btn>
        </div>
        <div class="mt-1">
          <v-btn v-if="! createAccountLink" variant="text" @click.prevent="navigateSection('register')">{{ $t('LOGIN.CREATE_ACCOUNT') }}</v-btn>
          <v-btn v-else :href="createAccountLink" target="_blank" rel="noopener" variant="text">{{ $t('LOGIN.CREATE_ACCOUNT') }}</v-btn>
        </div>
      </div>
    </template>
    <user-register v-if="section == 'register'" @navigate-section="navigateSection"></user-register>
    <user-password-remind v-if="section == 'password-remind'" @navigate-section="navigateSection"></user-password-remind>
    <user-password-reset v-if="section == 'password-reset'" @navigate-section="navigateSection"></user-password-reset>
    <!-- SECTION SSO -->
    <div v-if="isLoading" class="py-6 text-center"><i class="far fa-spinner fa-spin fa-3x"></i></div>
    <div v-if="errorMessage" class="text-errorred" v-html="errorMessage"></div>
  </v-card>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import UserRegister from './UserRegister';
  import UserPasswordRemind from './UserPasswordRemind';
  import UserPasswordReset from './UserPasswordReset';
  // import PKCE from './PKCE';

  export default {
    components: {
      UserRegister,
      UserPasswordRemind,
      UserPasswordReset,
    },
    emits: ['navigate'],
    data() {
      return {
        section: clientConfig.sso ? 'sso' : 'login',
        source: clientConfig.login && clientConfig.login.integrationSource || undefined,
        createAccountLink: clientConfig.login && clientConfig.login.createAccountLink,
        sectionTitles: {
          login: 'LOGIN.TITLE',
          register: 'LOGIN.REGISTER_TITLE',
          'password-remind': 'LOGIN.REMIND_PASSWORD_TITLE',
          'password-reset': 'LOGIN.RESET_PASSWORD_TITLE',
          sso: 'Authentification',
        },
        email: "",
        password: "",
        showTfa: false, // 2FA authentification
        tfaCode: undefined,
        loginMsg: "",
        isSubmittingLogin: false,
        isLoading: false,
        errorMessage: "",
      };
    },
    computed: {
      lang: {
        get() {
          return this.$store.state.lang.lang;
        },
        set(newLang) {
          this.$store.dispatch('lang/loadLang', newLang);
        },
      },
    },
    watch: {
      $route() {
        this.readQueryParams();
      },
    },
    created() {
      this.readQueryParams();
      if (clientConfig.sso) this.handleSSO();
    },
    methods: {
      readQueryParams() {
        if (this.$route.query.form == 'reset' && this.$route.query.token) {
          const query = { ...this.$route.query, resettoken: this.$route.query.token, form: undefined, token: undefined };
          this.$router.replace({ query });
        }
        if (this.$route.query.email) {
          const query = { ...this.$route.query, registeremail: this.$route.query.email, email: undefined };
          this.$router.replace({ query });
        }

        if (this.$route.query.resettoken) {
          this.navigateSection('password-reset');
        } else if (this.$route.query.registeremail) {
          this.navigateSection('register');
        }
      },
      navigateSection(section) {
        this.section = section;
      },
      submitLogin() {
        this.isSubmittingLogin = true;
        const captcha = (this.$refs.formLogin.querySelector("[name='g-recaptcha-response']") || {}).value;
        this.$refs.formCaptcha.innerHTML = '';
        window.apiSrv.login({ email: this.email, password: this.password, tfa_code: this.tfaCode, captcha, source: this.source }).catch((error) => {
          this.isSubmittingLogin = false;
          this.loginMsg = error.message;

          if (error.status == 423) {
            if (error.message == 'enter tfa code') {
              this.showTfa = true;
              return;
            }
            window.apiSrv.call('users/login/captcha').then((captchaResponse) => {
              $(this.$refs.formCaptcha).html(captchaResponse.data);
            });
          }
        });
      },
      handleSSO() {
        if (! clientConfig.sso) return;
        this.isLoading = true;
        const responseType = clientConfig.sso.response_type || 'ticket';
        let responseData = window.location.search.match(new RegExp(`${responseType}=([^&]*)`));
        if (! responseData) responseData = window.location.hash.match(new RegExp(`${responseType}=([^&]*)`));
        if (responseData) {
          if (window.history) window.history.replaceState(null, null, './');

          // if (clientConfig.sso.protocol == 'PKCE') {
          //   PKCE.validate(responseData[1]).then((message) => {
          //     // TO DO
          //     this.errorMessage = message;
          //   }).catch((error) => {
          //     this.errorMessage = `Erreur : ${error}`;
          //   }).finally(() => { this.isLoading = false; });
          //   return;
          // }

          const ssoRedirect = window.sessionStorageWrapper.getItem('sso_redirect');
          window.apiSrv.ssoLogin({
            protocol: clientConfig.sso.protocol,
            [responseType]: responseData[1],
            allow_sso_anonymous: /#\/(viewer|planningview)\/.*\?(view)?rotoken=/i.test(ssoRedirect) || undefined,
          }).then(() => {
            if (ssoRedirect) {
              window.location.replace(ssoRedirect);
              window.sessionStorageWrapper.removeItem('sso_redirect');
            }
            window.location.reload();
          }).catch((error) => {
            this.isLoading = false;
            this.errorMessage = 'Erreur : ';
            if (typeof error == 'object') {
              this.errorMessage += Object.keys(error).map((key) => {
                const messages = error[key];
                if (Array.isArray(messages)) return `- ${messages.join('\n- ')}`;
                return messages;
              }).join("\n");
            } else {
              this.errorMessage += error || "";
            }
          });
        } else {
          window.sessionStorageWrapper.setItem('sso_redirect', window.location.href);
          // if (clientConfig.sso.protocol == 'PKCE') {
          //   PKCE.login();
          //   return;
          // }
          window.location.href = clientConfig.sso.login;
        }
      },
    },
  };
</script>
