<template>
  <v-btn-icon :title="$t(el.getConfig(config) ? 'ELEMENT-DETAIL.HIDE_ON_PLANNING' : 'ELEMENT-DETAIL.SHOW_ON_PLANNING')" size="small" tabindex="-1"
              :disabled="planningHasActiveWorkingView"
              @click="el.getConfig()[config] = ! el.getConfig()[config]">
    <v-icon>far {{ el.getConfig(config) ? 'fa-eye' : 'fa-eye-slash' }}</v-icon>
  </v-btn-icon>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
      config: { type: String, required: true },
    },
    computed: {
      planningHasActiveWorkingView() {
        return this.$store.getters['ui/workingview/planningHasActiveWorkingView'](this.el.getPlanning());
      },
    },
  };
</script>
