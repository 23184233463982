<template>
  <div class="d-flex" style="flex-grow: 1; position: relative;">
    <!-- CHECKBOX-->
    <div class="d-flex align-center">
      <v-checkbox v-model="hidden" :title="hidden ? $t('CONFIG.SHOW_LANE_AND_CHILDREN') : $t('CONFIG.HIDE_LANE_AND_CHILDREN')" :true-value="false" :false-value="true"
                  :color="null" true-icon="far fa-check-square" class="mx-1"
                  @update:model-value="checkboxUpdate(lane)">
      </v-checkbox>
    </div>
    <!-- LANE TEXT-->
    <div class="d-flex align-center py-1 mr-1 flex-grow-1">
      <textarea-autosize v-if="textareaReady" v-model="lane.label" v-contentautoselect :style="lane.getLabelStyle()" spellcheck="false" rows="1"
                         class="flex-grow-1" style="background: transparent; color: inherit; resize: none; border: none; text-align: center"
                         @focus="startChangingContent(lane); setConfigLaneLabelSelected(lane.id, true)"
                         @blur="changingContent(lane); setConfigLaneLabelSelected(lane.id, false)">
      </textarea-autosize>
    </div>
    <!-- LANE ICONS-->
    <div class="d-flex align-center pa-1" style="background: rgba(0,0,0,0.3);">
      <!-- INDENT LANE-->
      <div class="d-flex align-center" style="width: 12px">
        <v-btn-icon v-show="lane.level" :title="$t('PLANNING.INDENT_LANE')" size="small" density="compact" style="width: 8px"
                    @click="setConfigLaneLevel(lane, lane.level - 1)">
          <v-icon color="white" style="width: 8px">fas fa-caret-left</v-icon>
        </v-btn-icon>
      </div>
      <div class="d-flex align-center" style="width: 12px">
        <v-btn-icon v-show="lane.level <= 1" :title="$t('PLANNING.INDENT_LANE')" size="small" density="compact" style="width: 8px"
                    @click="setConfigLaneLevel(lane, lane.level + 1)">
          <v-icon color="white" style="width: 8px">fas fa-caret-right</v-icon>
        </v-btn-icon>
      </div>
      <v-spacer class="mr-1"></v-spacer>
      <!-- SHOW AND HIDE LANE-->
      <v-btn-icon :title="hidden ? $t('PLANNING.SHOW_LANE') : $t('PLANNING.HIDE_LANE')" size="small" density="compact"
                  @click="updateConfigLane(lane)">
        <v-icon color="white">{{ hidden ? 'far fa-eye' : 'far fa-eye-slash' }}</v-icon>
      </v-btn-icon>
      <v-spacer class="mr-1"></v-spacer>
      <!-- COLOR-->
      <v-btn-icon :title="$t('PLANNING.SELECT_COLOR')" size="small" density="compact" :class="`config-lanes-lane-${lane.id}-select-color`" @click="selectConfigLaneColor(lane)">
        <v-icon color="successgreen">fas fa-tint</v-icon>
      </v-btn-icon>
      <v-spacer class="mr-1"></v-spacer>
      <!-- BOLD TOGGLE BUTTON-->
      <v-btn-toggle :model-value="(lane.getLabelStyle() || {})['font-weight']" style="height: 20px"
                    @update:model-value="toggleConfigLaneLabelStyle(lane, 'font-weight')">
        <v-btn value="bold" icon size="small" density="compact" style="min-width: 20px">
          <v-icon>far fa-bold</v-icon>
        </v-btn>
      </v-btn-toggle>
      <!-- ITALIC TOGGLE BUTTON-->
      <v-btn-toggle :model-value="(lane.getLabelStyle() || {})['font-style']" class="ml-1" style="height: 20px"
                    @update:model-value="toggleConfigLaneLabelStyle(lane, 'font-style')">
        <v-btn value="italic" icon size="small" density="compact" style="min-width: 20px">
          <v-icon>far fa-italic</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-spacer class="mx-1"></v-spacer>
      <!-- DELETE LANE-->
      <delete-lane-popover :lane="lane" :target="undefined" @delete="deleteConfigLane(lane)"></delete-lane-popover>
    </div>
    <!-- ADD LANE-->
    <div :style="{ top: lastOfBlock ? 'calc(100% + 4px)' : '100%' }" style="position: absolute; right: -20px; transform: translateY(-50%); z-index: 1">
      <v-btn :title="$t('PLANNING.ADD_LANE')" icon size="small" density="comfortable"
             @click="addConfigLane(lane)">
        <v-icon color="successgreen" size="large">far fa-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import TextareaAutosize from '@/components/Reusables/TextareaAutosize.vue';
  import DeleteLanePopover from '@/components/Planning/PlanningLaneHeaders/DeleteLanePopover.vue';

  export default {
    components: {
      TextareaAutosize,
      DeleteLanePopover,
    },
    props: {
      lane: { type: Object, required: true },
      lastOfBlock: { type: Boolean, default: false },
    },
    emits: ['select-config-lane-color', 'checkbox-update', 'add-config-lane', 'delete-config-lane'],
    data() {
      return {
        laneLabelSelected: {},
        textareaReady: false,
      };
    },
    computed: {
      hidden: {
        get() { return !! this.lane.getHidden(); },
        set(newVal) {
          this.lane.setHidden(newVal);
        },
      },
    },
    mounted() {
      setTimeout(() => {
        this.textareaReady = true;
      });
    },
    methods: {
      setConfigLaneLevel(lane, level) {
        this.startChangingContent(lane);
        lane.level = Math.max(0, Math.min(2, level));
        this.changingContent(lane);
      },
      updateConfigLane(lane) {
        if (this.hidden == true) {
          this.startChangingContent(lane);
          this.setLaneHidden({ lane, hidden: false });
          this.changingContent(lane);
        } else {
          this.startChangingContent(lane);
          this.setLaneHidden({ lane, hidden: true });
          this.changingContent(lane);
        }
      },
      selectConfigLaneColor(lane) {
        this.$emit('select-config-lane-color', lane);
      },
      checkboxUpdate(lane) {
        this.$emit('checkbox-update', lane);
      },
      addConfigLane(lane) {
        this.$emit('add-config-lane', lane);
      },
      deleteConfigLane(lane) {
        this.$emit('delete-config-lane', lane);
      },
      toggleConfigLaneLabelStyle(lane, prop) {
        const activeValue = { 'font-weight': 'bold', 'font-style': 'italic' }[prop] || '';
        this.startChangingContent(lane);
        const laneLabelStyle = lane.getLabelStyle() || {};
        laneLabelStyle[prop] = laneLabelStyle[prop] == activeValue ? 'normal' : activeValue;
        lane.setLabelStyle(laneLabelStyle);
        this.changingContent(lane);
      },
      setConfigLaneLabelSelected(laneId, val) {
        this.laneLabelSelected[laneId] = val;
      },
      ...mapActions('planning/lanes', ['startChangingContent', 'setLaneHidden', 'changingContent']),
    },
  };
</script>
