<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div v-if="debug" class="export-hidden" style="background: white; padding: 7px; margin-bottom: 40px; overflow: hidden">
    <input type="button" value="X" style="float: right; margin: 4px 16px" @click="debug = false">
    <h3>Debug</h3>
    <div>
      <select v-if="subprojects.length" v-model="selectedPlanning" style="appearance: auto; border-style: dotted;">
        <option v-for="item in [null, ...subprojects]" :value="item">{{ (item || planning).getTitle() }}</option>
      </select>
      <p>
        <span v-for="(val, label) in tabs"><a href @click.prevent="selectedtab = label">{{ label }}</a>&nbsp;&nbsp;&nbsp;</span>
        <input v-show="canBeFiltered" v-model="filter" placeholder="Filter" class="ml-4">
      </p>
      <pre>{{ selectedtab }}{{ filteredData.length ? ` (${filteredData.length})` : '' }} : {{ filteredData }}</pre>
    </div>
  </div>
</template>

<script>
  import DebugSrv from './DebugSrv';

  export default {
    props: {
      planning: { type: Object, required: true },
      subprojects: { type: Array, default: () => [] },
    },
    data() {
      return {
        debug: DebugSrv.debug,
        selectedPlanning: null,
        selectedtab: 'Elements',
        tabs: {
          Lanes: 'lanes', Timeline: 'timeline', visibleTimeline: 'visibleTimeline', Elements: 'elements', Config: 'config', Meta: 'meta', ProjectSheet: 'projectsheet', Process: 'process',
        },
        filter: '',
      };
    },
    computed: {
      canBeFiltered() {
        return ['Lanes', 'Elements', 'Process'].includes(this.selectedtab);
      },
      filteredData() {
        const planning = this.selectedPlanning || this.planning;
        let data = planning[this.tabs[this.selectedtab]];
        if (! this.filter || ! this.canBeFiltered) {
          return data;
        }
        if (this.selectedtab == 'Process') data = data.steps;
        const filterRegexp = new RegExp(this.filter, 'i');
        return data.filter(item => JSON.stringify(item).match(filterRegexp));
      },
    },
  };
</script>
