<template>
  <div class="exchange-line my-1">
    <div v-if="newday" class="text-center">
      <div class="bg-secondary rounded-pill d-inline-block py-1 px-4 my-2 small">{{ $filters.moment(exchange.date, 'calendar') }}</div>
    </div>

    <user-avatar v-if="newauthor" :title="exchange.user.email" :user="exchange.user" :size="40" class="chat-avatar"></user-avatar>
    <div :class="{ 'mt-4': newauthor && ! newday }" class="comment-block">
      <div :class="newauthor ? 'rounded-te' : 'rounded-t'" class="chat-text py-1 px-2 rounded-b bg-lightgrey hovervisible-activator">
        <div v-if="newauthor" class="font-weight-bold">
          {{ $filters.username(exchange.user) || 'Anonym' }}
        </div>
        <div v-if="exchange.file" class="mt-1">
          <a :href="exchange.file.url" target="_blank" rel="noopener">
            <img v-if="exchange.file.type == 'image'" :src="exchange.file.url" :title="exchange.file.name" class="file-img" @load="$emit('autoscroll')">
            <span v-else>
              <i :class="$filters.mimeIcon(exchange.file.mime)" class="mr-1"></i>
              {{ exchange.file.name }}
            </span>
          </a>
        </div>
        <div class="d-flex align-end">
          <div>
            <span v-if="exchange.text" ref="textcontent" v-html="enrichedContent(exchange.text)"></span>
            <span v-if="exchange.edition_date && exchange.edition_date !== exchange.date" :title="$filters.moment(exchange.edition_date, 'calendarOrDatetime')" class="text-medium-emphasis small">
              ({{ $t('EXCHANGECENTER.EDITED') }})
            </span>
            <span v-if="exchange.waiting" style="margin-left: 7px"><i class="far fa-spinner fa-spin fa-fw"></i></span>
          </div>
          <span class="ml-auto text-medium-emphasis small">{{ $filters.moment(exchange.date, 'time') }}</span>
        </div>
        <div v-if="me && exchange.user.id == me.id" class="chat-text-menu hovervisible-item">
          <v-btn icon :title="$t('EXCHANGECENTER.EDIT_MESSAGE')" size="small" density="comfortable" @click="$emit('edit')">
            <v-icon>far fa-pencil-alt</v-icon>
          </v-btn>
          <v-btn icon :title="$t('EXCHANGECENTER.DELETE_MESSAGE')" size="small" density="comfortable" class="ml-1" @click="$emit('delete')">
            <v-icon color="errorred">far fa-trash-alt</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .chat-content {
    .exchange-line {
      position: relative;
    }

    .chat-avatar {
      position: absolute;
    }

    .comment-block {
      margin-left: 46px;
    }

    .chat-text {
      position: relative;
      z-index: 100;

      .file-img {
        max-height: 160px;
        max-width: 100%;
      }
    }

    .chat-text-menu {
      position: absolute;
      top: 2px;
      right: 4px;
    }
  }
</style>

<script>
  import emojis from './emojis';

  const rEmojis = new RegExp(`:(${emojis.join("|")}):`, "g");

  export default {
    props: {
      exchange: { type: Object, required: true },
      prevExchange: { type: Object, default: null },
      nextExchange: { type: Object, default: null },
    },
    emits: ['autoscroll', 'edit', 'delete'], // `select-${$this.data('select')}`
    computed: {
      newauthor() {
        return ! (this.prevExchange && this.exchange.user.id == this.prevExchange.user.id && moment(this.exchange.date).diff(this.prevExchange.date, 'minutes') < 60);
      },
      lastofauthor() {
        return ! (this.nextExchange && this.exchange.user.id == this.nextExchange.user.id && moment(this.nextExchange.date).diff(this.exchange.date, 'minutes') < 60);
      },
      newday() {
        return ! (this.prevExchange && moment(this.exchange.date).format('YYYY MM DD') == moment(this.prevExchange.date).format('YYYY MM DD'));
      },
      me() {
        return this.$store.state.users.user;
      },
    },
    mounted() {
      const vm = this;
      $(this.$refs.textcontent).delegate("a[data-select]", "click", function (event) {
        event.preventDefault();
        const $this = $(this);
        vm.$emit(`select-${$this.data('select')}`, $this.data('value'));
      });
    },
    beforeUnmount() {
      $(this.$refs.textcontent).undelegate();
    },
    methods: {
      enrichedContent(text) {
        let enrichedText = text || "";
        enrichedText = enrichedText.replace(/(^|\s)(@[^ ]*)\b/g, '$1<a class="pointer" data-select="at" data-value="$2">$2</a>'); // @something
        enrichedText = enrichedText.replace(/(^|\s)(#[^ ]*)\b/g, '$1<a class="pointer" data-select="hashtag" data-value="$2">$2</a>'); // #something
        enrichedText = enrichedText.replace(/(https?:\/\/[a-zA-Z0-9._-]{4,})/gi, '<a href="$1" target="_blank" rel="noopener">$1</a>'); // http(s)://
        enrichedText = enrichedText.replace(/:\)/g, ':smile:').replace(/;\)/g, ':wink:')
          .replace(rEmojis, (match, txt) => `<i class='emoji emoji_${txt}' title=':${txt}:'>${txt}</i>`);
        return enrichedText;
      },
    },
  };
</script>
