export default {
  namespaced: true,
  state: {
    projectStepId2DashboardStepId: {
      // `${projectId}.${processStep}`: dashboardStepId
    },
  },
  mutations: {
    resetProjectStepId2DashboardStepId(state) {
      state.projectStepId2DashboardStepId = {};
    },
  },
};
