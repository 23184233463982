<template>
  <div class="mb-1"><label :for="inputId">{{ $t('PROJECTS.CATEGORY', 2) }}</label></div>
  <v-combobox :model-value="categoryTags" :id="inputId" :items="projectCategoryTags" :hint="$t('PROJECTS.SELECT_CATEGORY')"
              :delimiters="[',']" multiple clearable hide-selected persistent-hint hide-no-data
              :prepend-inner-icon="prependIcon"
              @update:model-value="updateTags">
    <template #selection="{ attrs, item: { raw: item } }">
      <v-chip v-if="item" v-bind="attrs" :style="{ 'background-color': getCategoryTagColor(item).bg, color: getCategoryTagColor(item).color }"
              :title="item" size="small" style="max-width: 150px; margin-top: 2px; margin-bottom: 2px">
        <span class="text-ellipsis pr-2">{{ item }}</span>
        <v-icon :style="{ color: getCategoryTagColor(item).color }" @click="removeItem(item)">far fa-times</v-icon>
      </v-chip>
    </template>
    <template #item="{ props, item: { raw: item } }">
      <v-list-item v-bind="props">
        <v-chip :style="{ 'background-color': getCategoryTagColor(item).bg, color: getCategoryTagColor(item).color }"
                :title="item" size="small" class="pointer" style="max-width: 300px">
          <span class="text-ellipsis">{{ item }}</span>
        </v-chip>
        <template #title></template>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
  export default {
    props: {
      modelValue: { type: String, default: null },
      projectCategories: { type: Array, default: () => [] },
      prependIcon: { type: String, default: 'far fa-tags' },
    },
    emits: ['update:modelValue'],
    data() {
      return {
        categoryTags: null,
        inputId: `categoryfield${this.$.uid}`,
      };
    },
    computed: {
      projectCategoryTags() {
        return this.categories2tags(this.projectCategories);
      },
    },
    watch: {
      modelValue: {
        handler(newVal) {
          this.categoryTags = newVal ? newVal.split(',') : [];
        },
        immediate: true,
      },
    },
    methods: {
      categories2tags(categories) {
        const tags = new Set();
        categories.forEach((item) => {
          (item || '').split(',').forEach((subitem) => {
            const tag = subitem && subitem.trim();
            if (tag) tags.add(tag);
          });
        });
        return [...tags];
      },
      updateTags(newVal) {
        if (newVal.some(item => ! item || item.trim() != item || item.includes(',')) || new Set(newVal).size != newVal.length) { // trim items + ',' in copied text + unicity
          this.categoryTags = this.categories2tags(newVal);
          return;
        }
        const newCategory = newVal.join(',');
        this.$emit('update:modelValue', newCategory);
      },
      getCategoryTagColor(tag) {
        const tagColor = this.$store.getters['users/accessRight/config'].getCategoryColor(tag);
        return { bg: `#${tagColor}`, color: window.contrastedTextColor(tagColor) };
      },
      removeItem(item) {
        const index = this.categoryTags.indexOf(item);
        if (index >= 0) this.categoryTags.splice(index, 1);
        this.$emit('update:modelValue', this.categoryTags.join(','));
      },
    },
  };
</script>
