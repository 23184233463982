<template>
  <v-menu v-model="contextMenu.show" content-class="timeline-menu export-hidden"
          :target="[contextMenu.x - 100, contextMenu.y]"
          @update:model-value="$emit('input', $event)">
    <div class="d-flex align-center mb-1">
      <btn-toggle-filter v-model="lastStepInput" density="compact">
        <v-btn v-for="item in timelineOptions" :key="item.value" :value="item.value" size="small">
          {{ item.title }}
        </v-btn>
      </btn-toggle-filter>
      <v-tooltip v-if="canOpenConfig" location="bottom" z-index="1000">
        <template #activator="{ props }">
          <v-btn icon size="x-small" class="ml-auto" v-bind="props" @click="openConfig">
            <v-icon size="x-large">far fa-cog</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('PLANNING.PLANNING_CONFIG') }}</span>
      </v-tooltip>
    </div>
    <btn-toggle-filter v-model.number="colwidthInput" density="compact">
      <v-btn v-for="item in availableColWidthOptions" :key="item.value" :value="item.value" size="small">
        {{ item.title }}
      </v-btn>
    </btn-toggle-filter>
  </v-menu>
</template>

<style>
  .timeline-menu {
    z-index: 400 !important;
    background: rgba(0, 0, 0, .3);
    border-radius: 12px !important;
    padding: 4px;
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import defaultConfig from '@/js/defaultConfig';

  export default {
    props: {
      contextMenu: { type: Object, required: true },
      timeline: { type: Object, required: true },
      canOpenConfig: { type: Boolean, default: false },
    },
    emits: ['input'],
    data() {
      return {
        lastStep: null,
        colwidth: null,
        timelineOptions: defaultConfig.timelineOptions().slice(1, -1).reverse(),
        availableColWidthOptions: defaultConfig.availableColWidthOptions().reverse(),
      };
    },
    computed: {
      getLastStep() {
        return this.timeline.getLastStep();
      },
      lastStepInput: {
        get() {
          return this.lastStep;
        },
        set(newVal) {
          this.lastStep = newVal;
          if (! newVal) {
            this.$nextTick(() => { this.lastStep = this.getLastStep; });
            return;
          }
          const defaultTimelineOptions = defaultConfig.timelineOptions();
          const stepIndex = defaultTimelineOptions.findIndex(item => item.value == newVal);
          const steps = stepIndex > 0 ? [defaultTimelineOptions[stepIndex - 1].value, newVal] : [newVal];
          this.$store.dispatch('planning/timeline/updateTimeline', {
            steps,
            colwidth: this.timeline.bestColWidth(null, null, steps),
          });
        },
      },
      getColWidth() {
        return this.timeline.getColWidth();
      },
      colwidthInput: {
        get() {
          return this.colwidth;
        },
        set(newVal) {
          this.colwidth = newVal;
          if (! newVal) {
            this.$nextTick(() => { this.colwidth = this.getColWidth; });
            return;
          }
          this.$store.dispatch('planning/timeline/updateTimeline', { colwidth: newVal });
        },
      },
    },
    watch: {
      getLastStep: {
        handler() {
          this.lastStep = this.getLastStep;
        },
        immediate: true,
      },
      getColWidth: {
        handler() {
          this.colwidth = this.getColWidth;
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions('ui/planning', ['openConfig']),
    },
  };
</script>
