<template>
  <v-list>
    <v-list-item>
      <div class="d-flex align-center pb-1">
        <v-btn :title="$t('ELEMENT-DETAIL.COPY_EL_URL')" size="x-small" icon color="secondary" class="mr-2" @click="copyElUrl">
          <v-icon v-if="copyingElLinkToClipboard.success" color="successgreen">far fa-check</v-icon>
          <v-icon v-else-if="copyingElLinkToClipboard.error" color="errorred">far fa-exclamation-triangle</v-icon>
          <v-icon v-else>far fa-link</v-icon>
        </v-btn>
        <v-btn size="small" color="error" rounded class="ml-auto" @click="close('delete')">
          <v-icon class="mr-2">far fa-trash-alt</v-icon>{{ $t('ELEMENT-DETAIL.DELETE_ELEMENT') }}
        </v-btn>
      </div>
    </v-list-item>
    <template v-if="options.canChangeLane">
      <v-divider></v-divider>
      <v-list-item>
        <label for="elLaneId">{{ $t('PLANNING.LANE') }}</label>
        <v-select id="elLaneId" v-model="elLaneId" :items="lanes" item-title="label" item-value="id" prepend-inner-icon="far fa-th-list" class="my-1"></v-select>
      </v-list-item>
    </template>
    <template v-if="options.canSetPriority">
      <v-divider></v-divider>
      <v-tooltip location="left">
        <template #activator="{ props }">
          <v-list-item v-bind="props">
            <div class="d-flex align-center flex-grow-1">
              <div class="mr-2 text-center" style="flex: 0 0 24px;">
                <v-icon color="medium-emphasis">far fa-sliders-v</v-icon>
              </div>
              {{ $t('ELEMENT-DETAIL.PRIORITY') }}
              <div class="ml-auto text-center" style="min-width: 23px">
                <v-btn-icon size="x-small"><v-icon size="x-large" @click="elPriority = (elPriority || 0) + 1">fas fa-caret-up</v-icon></v-btn-icon>
                <div style="margin: -14px 0 -9px"><small>{{ elPriority || 'auto' }}</small></div>
                <v-btn-icon size="x-small"><v-icon size="x-large" @click="elPriority = (elPriority || 0) - 1">fas fa-caret-down</v-icon></v-btn-icon>
              </div>
            </div>
          </v-list-item>
        </template>
        <span class="pre">{{ $t('ELEMENT-DETAIL.PRIORITY_ON_PLANNING') }}</span>
      </v-tooltip>
    </template>
  </v-list>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
      options: { type: Object, default: () => ({}) },
    },
    emits: ['close'],
    data() {
      return {
        planning: this.el.getPlanning(),
        copyingElLinkToClipboard: { success: false, error: false },
      };
    },
    computed: {
      lanes() {
        return this.planning.lanes.filter(lane => ! lane.project_id);
      },
      elLaneId: {
        get() {
          return this.el.getLaneId();
        },
        set(newVal) {
          this.el.setLaneId(newVal);
        },
      },
      elPriority: {
        get() {
          return this.el.getPriority();
        },
        set(newVal) {
          this.el.setPriority(newVal);
        },
      },
    },
    methods: {
      copyElUrl() {
        const elUrl = `${this.planning.getUrl()}?el=${this.el.id}`;
        this.copyingElLinkToClipboard = { success: false, error: false };
        try {
          navigator.clipboard.writeText(elUrl);
          this.copyingElLinkToClipboard.success = true;
          setTimeout(() => { this.copyingElLinkToClipboard.success = false; }, 5000);
        } catch (e) {
          this.copyingElLinkToClipboard.error = true;
        }
      },
      close(reason) {
        this.$emit('close', reason);
      },
    },
  };
</script>
