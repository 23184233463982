import { defineStore, acceptHMRUpdate } from 'pinia';

const store = defineStore('NavigationProjectsHistory', {
  state: () => ({
    history: null, // [{ type, id, path, rotoken, title, imgurl }]
  }),
  getters: {
    getHistory(state) {
      if (state.history) return state.history;
      state.history = window.safeParseJSON(window.localStorageWrapper.getItem('project_navigation_history')) || [];
      return state.history;
    },
  },
  actions: {
    register({ path, rotoken, title, imgurl }) {
      const pathCheck = /^\/(planning|viewer|planningview)\/(.+?)(\/.*)?$/i.exec(path);
      if (! pathCheck) return;
      const [, type, id] = pathCheck;
      const finalTitle = title || "New project";
      const finalPath = `/${type}/${id}/${window.slugify(finalTitle)}`;

      this.history = this.getHistory.filter((item) => {
        return ! (item.type == type && item.id == id);
      }).slice(0, 3);
      this.history.unshift({ type, id, path: finalPath, rotoken, title: finalTitle, imgurl });

      window.localStorageWrapper.setItem('project_navigation_history', JSON.stringify(this.history.filter(item => ! item.rotoken))); // do not save rotoken in localstorage
    },
  },
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot));
export default store;
