<template>
  <div class="px-md-4 py-2 d-flex">
    <div class="mr-8" style="width: 250px; flex: 0 0 250px; max-width: 30%">
      <div class="overflow-y-auto overflow-x-hidden light-scrollbar overscroll-behavior-y-contain pr-2"
           style="position: fixed; top: calc(var(--v-layout-top) + 26px); bottom: 16px; width: 250px; max-width: 30%">
        <v-btn :disabled="isConsultOnly" color="primary" rounded block size="large" class="mb-4 justify-start justify-md-center" @click="tabIndex = 'new'">
          <v-icon size="large" start>far fa-plus</v-icon>
          {{ $t('PROJECTS.START_A_NEW_PROJECT') }}
        </v-btn>
        <div v-if="hasStatusOrViews" class="mt-4 mb-1 font-weight-bold text-ellipsis">{{ $t('PROJECTS.ACTIVE_PROJECTS') }}</div>
        <tabs-vertical v-model="tabIndex" :mandatory="false">
          <v-tab value="projects">
            <v-icon start>far fa-folder-open</v-icon>
            {{ $t('PROJECTS.PROJECTS') }}
          </v-tab>
          <v-tab v-if="views.length" value="views">
            <v-icon start>far fa-eye</v-icon>
            {{ $t('SHARE.CUSTOM_VIEWS') }}
          </v-tab>
        </tabs-vertical>
        <div v-if="hasStatusOrViews" class="mt-4 mb-1 font-weight-bold text-ellipsis">{{ $t('PROJECTS.STATUS.STATUS', 2) }}</div>
        <tabs-vertical v-model="tabIndex" :mandatory="false">
          <v-tab v-for="status in availableProjectStatusKeys" :key="status" :value="`${status}projects`">
            <v-icon start>far fa-asterisk</v-icon>
            {{ projectStatusConfig[status].label }}
          </v-tab>
        </tabs-vertical>
        <div v-if="hasStatusOrViews" class="mt-4 mb-1 font-weight-bold text-ellipsis">{{ $t('PROJECTS.ARCHIVES') }}</div>
        <tabs-vertical v-model="tabIndex" :mandatory="false">
          <v-tab :disabled="isConsultOnly" value="archived">
            <v-icon start>far fa-archive</v-icon>
            {{ $t('PROJECTS.ARCHIVES') }}
          </v-tab>
        </tabs-vertical>
      </div>
    </div>

    <v-window v-model="tabIndex" :touch="false" class="flex-grow-1" style="min-height: 70vh">
      <!-- TAB PROJECTS -->
      <v-window-item v-for="status in ['', ...availableProjectStatusKeys]" :key="status" :value="`${status}projects`"
                     :transition="false" :reverse-transition="false">
        <home-projects-list :data-loaded="data_loaded" :errors="errors" :filters="filters"
                            :projects="projectsByStatus[status]"
                            :status-info="status ? projectStatusConfig[status] : {}"
                            @archive-project="archiveProject($event)"
                            @duplicate-project="duplicateProject($event)">
        </home-projects-list>
      </v-window-item>
      <!-- TAB CUSTOM VIEWS -->
      <v-window-item :transition="false" :reverse-transition="false" :disabled="isConsultOnly" value="views">
        <v-row>
          <v-col v-for="view in views" :key="`view${view.id}`" lg="3" sm="4" cols="12" class="projectbox list-fade-item">
            <home-projects-project :project="view" view></home-projects-project>
          </v-col>
        </v-row>
      </v-window-item>
      <!-- TAB NEW PROJECT -->
      <v-window-item :transition="false" :reverse-transition="false" :disabled="isConsultOnly" value="new">
        <div class="d-flex">
          <v-card class="flex-grow-1 pa-12" style="max-width: 680px">
            <div v-if="! data_loaded.projects && ! errors.loadProjects" class="text-center"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
            <div v-else-if="! data_loaded.projects && errors.loadProjects">{{ errors.loadProjects }}</div>
            <div v-else-if="data_loaded.projects && isMaxProjectsReached" class="text-center">
              <div><i class="far fa-exclamation-circle fa-4x text-warning"></i></div>
              <div class="mt-2">{{ $t('PROJECTS.YOU_HAVE_REACHED_YOUR_MAXIMUM_PROJECTS') }}<br>{{ $t('PROJECTS.DELETE_AN_EXISTING_PROJECT') }}</div>
              <div>
                <a :href="$t('PREMIUM.OFFERS_LINK')" target="_blank" rel="noopener">
                  <v-btn color="accent" rounded size="large" class="mt-4">{{ $t('PROJECTS.LEARN_ABOUT_PREMIUM') }}</v-btn>
                </a>
              </div>
            </div>
            <template v-else-if="data_loaded.projects && ! isMaxProjectsReached && ! isConsultOnly">
              <home-projects-newproject :projects="projects"></home-projects-newproject>
              <v-btn-icon style="position: absolute; top: 4px; left: 4px" size="small" @click="tabIndex = 'projects'">
                <v-icon size="large">far fa-arrow-left</v-icon>
              </v-btn-icon>
            </template>
          </v-card>
          <div v-if="data_loaded.projects && ! isMaxProjectsReached && ! isConsultOnly" class="d-none d-lg-block text-center" style="padding: 50px 16px; flex-shrink: 999;">
            <img v-if="tabIndex == 'new'" v-reload-src-on-mount src="@/img/animations/illustration-newproject.gif" style="max-width: min(400px, 100%); max-height: 100%">
          </div>
        </div>
      </v-window-item>
      <!-- TAB ARCHIVED -->
      <v-window-item :transition="false" :reverse-transition="false" :disabled="isConsultOnly" value="archived">
        <home-projects-filters :filters="filters" :projects="archivedprojects"></home-projects-filters>
        <div v-show="! data_loaded.archivedprojects && ! errors.loadArchivedProjects" class="text-center"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
        <div v-show="! data_loaded.archivedprojects && errors.loadArchivedProjects">{{ errors.loadArchivedProjects }}</div>
        <div v-if="data_loaded.archivedprojects && ! archivedprojects.length">{{ $t('PROJECTS.NO_ARCHIVED_PROJECT') }}</div>
        <div v-show="data_loaded.archivedprojects && archivedprojects.length && ! isConsultOnly">
          <v-container class="pa-0 mt-n2 mb-3" style="max-width: calc(100% - 36px)">
            <!-- only way to add margin around pagination ? -->
            <v-pagination v-if="filteredArchivedProjects.length > archivedProjectsPerPage" v-model="archivedProjectsCurrentPage" :length="archivedProjectsPaginationLength"></v-pagination>
          </v-container>
          <!-- GRID -->
          <v-container v-if="filters.display == 'grid'" fluid class="pa-0">
            <v-row class="pb-1">
              <transition-group name="list-fade" tag="div" class="d-flex" style="flex-wrap: wrap; width: 100%">
                <v-col v-for="archivedproject in displayedFilteredArchivedProjects" :key="archivedproject.id" lg="3" sm="6" cols="12" class="projectbox list-fade-item">
                  <v-lazy v-if="vLazyAvailable" :options="{ rootMargin: '280px' }" style="height: 100%; min-height: 270px">
                    <home-projects-project :project="archivedproject" archived @delete="deleteArchived(archivedproject)" @restore="restoreArchived(archivedproject)"></home-projects-project>
                  </v-lazy>
                  <home-projects-project v-else :project="archivedproject" archived @delete="deleteArchived(archivedproject)" @restore="restoreArchived(archivedproject)"></home-projects-project>
                </v-col>
              </transition-group>
            </v-row>
          </v-container>
          <!-- LIST -->
          <v-card v-if="filters.display == 'list'">
            <div class="table-responsive">
              <table class="table">
                <thead class="bg-lightgrey">
                  <tr>
                    <th>{{ $t('NEWPROJECT.PROJECT_NAME') }}</th>
                    <th>{{ $t('PROJECTS.ARCHIVED_ON') }}</th>
                    <th>{{ $t('GLOBAL.OWNER') }}</th>
                    <th>{{ $t('GLOBAL.CREATION_DATE') }}</th>
                    <th>{{ $t('GLOBAL.ACCESS_RIGHT') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="archivedproject in displayedFilteredArchivedProjects" :key="archivedproject.id">
                    <td>{{ archivedproject.title || $t('PLANNING.UNNAMED_PROJECT') }}</td>
                    <td>{{ $filters.moment(archivedproject.deleted_at, 'calendar') }}</td>
                    <td>{{ translateApiText(archivedproject.owner_name) }}</td>
                    <td>{{ $filters.moment(archivedproject.date_of_creation, 'datetime') }}</td>
                    <td>{{ $t($filters.uppercase(`ACR.${archivedproject.access_right}`)) }}</td>
                    <td>
                      <template v-if="archivedproject.access_right == 'admin'">
                        <v-btn :title="$t('PROJECTS.RESTORE')" size="small" icon class="my-0" @click="restoreArchived(archivedproject)">
                          <v-icon color="primary">far fa-trash-restore</v-icon>
                        </v-btn>
                        <v-btn :title="$t('PROJECTS.DELETE')" size="small" icon class="my-0" @click="deleteArchived(archivedproject)">
                          <v-icon color="errorred">far fa-trash-alt</v-icon>
                        </v-btn>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-card>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
  import Planning from '@/models/Planning';
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import HomeProjectsList from './HomeProjectsList.vue';
  import HomeProjectsProject from './HomeProjectsProject.vue';
  import HomeProjectsNewproject from './HomeProjectsNewproject.vue';
  import HomeProjectsFilters from './HomeProjectsFilters.vue';
  import HomeProjectsMixin from './HomeProjectsMixin';

  export default {
    components: {
      HomeProjectsList,
      HomeProjectsProject,
      HomeProjectsNewproject,
      HomeProjectsFilters,
    },
    mixins: [HomeProjectsMixin],
    data() {
      const filters = {
        company: null, company_id: null, categories: [], title: null, user: null, display: 'grid', sort: '-date_of_modification',
      };
      try {
        _.extend(filters, JSON.parse(window.sessionStorageWrapper.getItem('option.projectsFilters')) || null);
      } catch (e) { /* continue with defaults */ }

      return {
        data_loaded: { projects: false, archivedprojects: false },
        errors: { loadProjects: null, loadArchivedProjects: null },
        projects: [],
        views: [],
        filters,
        archivedprojects: [],
        tabIndex: null,
        archivedProjectsPerPage: 50,
        archivedProjectsCurrentPage: 1,
      };
    },
    computed: {
      projectStatusConfig() {
        return this.$store.getters['users/accessRight/config'].projectStatus || {};
      },
      projectStatusKeysSet() {
        const projectStatusKeys = [...Object.keys(this.projectStatusConfig), ''];
        return new Set(projectStatusKeys);
      },
      projectsByStatus() {
        const projectsByStatus = {};
        projectsByStatus[''] = []; // always displays tab projects

        this.projects.forEach((project) => {
          const status = this.projectStatusKeysSet.has(project.status) ? project.status : '';
          if (! projectsByStatus[status]) projectsByStatus[status] = [];
          projectsByStatus[status].push(project);
        });
        return projectsByStatus;
      },
      availableProjectStatusKeys() {
        const orderedStatus = [...this.projectStatusKeysSet];
        return Object.keys(this.projectsByStatus).filter(item => item).sort((a, b) => orderedStatus.indexOf(a) - orderedStatus.indexOf(b));
      },
      hasStatusOrViews() {
        return this.availableProjectStatusKeys.length > 1 || this.views.length;
      },
      filteredArchivedProjects() {
        const filtered = this.archivedprojects.filter(project => this.filterProjectByCompany(project) && this.filterProjectByCategory(project)
          && this.filterProjectByTitle(project) && this.filterProjectByUser(project));
        if (this.filters.sort) {
          let { sort } = this.filters;
          let reverse = false;
          if (sort.startsWith('-')) {
            sort = sort.slice(1);
            reverse = true;
          }
          if (sort == 'date_of_modification') sort = 'deleted_at';
          if (sort == 'title') {
            filtered.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
          } else {
            filtered.sort((a, b) => (a[sort] < b[sort] ? -1 : 1));
          }
          if (reverse) filtered.reverse();
        }
        return filtered;
      },
      displayedFilteredArchivedProjects() {
        return this.filteredArchivedProjects.slice(this.archivedProjectsPerPage * (this.archivedProjectsCurrentPage - 1), this.archivedProjectsPerPage * this.archivedProjectsCurrentPage);
      },
      isMaxProjectsReached() {
        if (! this.data_loaded.projects) return true;
        const numberprojects = this.projects.length;
        const maxprojects = this.$store.state.users.accessRight.maxprojects || 0;
        if (numberprojects < maxprojects) return false;
        return true;
      },
      archivedProjectsPaginationLength() {
        return Math.ceil(this.filteredArchivedProjects.length / this.archivedProjectsPerPage);
      },
      vLazyAvailable() {
        return 'IntersectionObserver' in window;
      },
      isConsultOnly() { return this.$store.state.users.accessRight.isConsultOnly; },
    },
    watch: {
      filters: {
        handler() {
          if (this.filters) window.sessionStorageWrapper.setItem('option.projectsFilters', JSON.stringify(this.filters));
        },
        deep: true,
      },
      $route: {
        handler() {
          const newHash = this.$route.hash.replace('#', '');
          if (newHash == 'new') this.tabIndex = newHash;
          $(window).scrollTop(0);
        },
        immediate: true,
      },
      tabIndex(newVal, oldVal) {
        if (! newVal && oldVal) this.tabIndex = oldVal; // prevent empty tab when clicking on selected tab
        if (newVal) this.$router.replace({ hash: newVal == 'new' ? `#new` : null });
      },
      archivedProjectsPaginationLength() {
        if (this.archivedProjectsCurrentPage > this.archivedProjectsPaginationLength) this.archivedProjectsCurrentPage = 1;
      },
    },
    created() {
      this.$store.getters['users/getOrganizationPromise'].then((organization) => {
        if (organization) {
          const mylevel = (organization.getCompanies().find(item => item.id == this.$store.state.users.user.company_id) || {}).level;
          const maxlevel = Math.max(...organization.getCompanies().map(item => item.level), 0);
          if (mylevel < maxlevel) {
            if (! this.filters.company) this.filters.company = 'mine';
            return;
          }
        }
        this.filters.company = null;
      });
      this.loadProjects();
      this.loadArchivedProjects();

      this.$store.state.users.userPromise.then(() => {
        if (this.$store.getters['users/accessRight/config'].hasViews) this.loadViews();
      });
    },
    methods: {
      projectListLoaded() {
        if (! this.projects.length) {
          if (! this.data_loaded.archivedprojects) { setTimeout(this.projectListLoaded, 300); return; }
        }
        this.data_loaded.projects = true;

        this.$store.state.users.userPromise.then(() => {
          if (this.isConsultOnly) { // force access to consult
            this.projects.forEach((project) => {
              project.access_right = 'consult';
            });
          }
        });
      },
      loadProjects() {
        this.data_loaded.projects = false;
        ProjectSrv.list().then((results) => {
          this.projects = results;
          this.projectListLoaded();
        }).catch((message) => {
          this.errors.loadProjects = this.$t('GLOBAL.ERROR') + (message ? ` : ${message}` : '');
        });
      },
      loadArchivedProjects() {
        this.data_loaded.archivedprojects = false;
        ProjectSrv.listArchived().then((results) => {
          this.archivedprojects = results;
          this.data_loaded.archivedprojects = true;
        }).catch((message) => {
          this.errors.loadArchivedProjects = this.$t('GLOBAL.ERROR') + (message ? ` : ${message}` : '');
        });
      },
      loadViews() {
        window.apiSrv.call('views', 'index').then((response) => {
          this.views = response?.data?.views?.sort((a, b) => (a.date_of_modification < b.date_of_modification ? 1 : -1)) || [];
        });
      },
      archiveProject(project) {
        ProjectSrv.remove(project.id).then(() => {
          const index = (this.projects || []).findIndex(item => item.id === project.id);
          if (index >= 0) this.projects.splice(index, 1);
          setTimeout(this.loadArchivedProjects, 300);
        }).catch((message) => {
          this.$store.dispatch('ui/msgbox/open', { title: this.$t('GLOBAL.ERROR'), body: `${message}` });
        });
      },
      restoreArchived(project) {
        if (this.isMaxProjectsReached) {
          this.$store.dispatch('ui/msgbox/open', { title: this.$t('PROJECTS.YOU_HAVE_REACHED_YOUR_MAXIMUM_PROJECTS'), body: this.$t('PROJECTS.DELETE_AN_EXISTING_PROJECT') });
          return;
        }

        ProjectSrv.restoreArchived(project.id).then(() => {
          const index = (this.archivedprojects || []).findIndex(item => item.id === project.id);
          if (index >= 0) this.archivedprojects.splice(index, 1);
          setTimeout(this.loadProjects, 300);
        }).catch((message) => {
          this.$store.dispatch('ui/msgbox/open', { title: this.$t('GLOBAL.ERROR'), body: `${message}` });
        });
      },
      deleteArchived(project) {
        this.$store.dispatch('ui/msgbox/open', {
          title: this.$t('PROJECTS.CONFIRM_DELETE', { project_name: project.title }),
          body: this.$t('PROJECTS.CONFIRM_DELETE_BODY'),
          buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
        }).then(() => {
          ProjectSrv.removeArchived(project.id).then(() => {
            const index = (this.archivedprojects || []).findIndex(item => item.id === project.id);
            if (index >= 0) this.archivedprojects.splice(index, 1);
          }).catch((message) => {
            this.$store.dispatch('ui/msgbox/open', { title: this.$t('GLOBAL.ERROR'), body: `${message}` });
          });
        }).catch(() => {});
      },
      duplicateProject(project) {
        if (this.isMaxProjectsReached) {
          this.$store.dispatch('ui/msgbox/open', { title: this.$t('PROJECTS.YOU_HAVE_REACHED_YOUR_MAXIMUM_PROJECTS'), body: this.$t('PROJECTS.DELETE_AN_EXISTING_PROJECT') });
          return;
        }
        this.data_loaded.projects = false;
        ProjectSrv.get(project.id)
          .then((content) => {
            const newPlanning = new Planning(content);
            const data = {
              title: `${newPlanning.getTitle() || this.$t('PLANNING.UNNAMED_PROJECT')} ${this.$t('PROJECTS.COPY')}`,
              content: newPlanning.toProject().content,
              category: newPlanning.meta && newPlanning.meta.category,
              status: newPlanning.meta && newPlanning.meta.status,
            };
            return ProjectSrv.createNew(data);
          })
          .then((response) => {
            // copy access rights to new project
            const newid = response.data?.planning?.id;
            return window.apiSrv.call(`sharedplannings/planning/${newid}/copy/${project.id}`, 'update');
          })
          .catch((message) => {
            this.$store.dispatch('ui/msgbox/open', { title: this.$t('GLOBAL.ERROR'), body: `${message}` });
          })
          .finally(() => {
            setTimeout(this.loadProjects);
          });
      },
    },
  };
</script>
