import { defineAsyncComponent } from 'vue';
import lang from '@/client_customs/Maaf/lang.js';
import MaafPictosElView from '@/client_customs/Maaf/MaafPictosElView.vue'; // initial el height problem with lazyloading

const MaafColors = defineAsyncComponent(() => import('@/client_customs/Maaf/MaafColors.vue'));
const MaafPictos = defineAsyncComponent(() => import('@/client_customs/Maaf/MaafPictos.vue'));

export default {
  subdomain: 'maaf',
  clientCustomComponents: { MaafColors, MaafPictos, MaafPictosElView },
  lang,
  startupScript() {
    import('../../Maaf/maaf.scss');
  },
  links: {
    hideOneDrive: true,
    hideDropbox: true,
    hideGoogleDrive: true,
  },
  elements: {
    elementCards: [
      { id: 'header', icon: 'far fa-info-circle', label: "Détails" },
      { id: 'checklist', icon: 'far fa-tasks', label: "Volumes" },
      { id: 'progress', icon: 'far fa-arrows-alt-h', label: "Période" },
      { id: 'users', icon: 'far fa-user', label: "Tags spécifiques (cibles) - Pilote projet (CPM)" },
      { id: 'links', icon: 'far fa-paperclip', label: "Pièces jointes (ex : les créas, la fiche synthèse, fiche bilan)" },
      { id: 'budgets', icon: 'far fa-euro-sign', label: "Budget" },
    ],
    macroElementCards: [
      { id: 'header', icon: 'far fa-info-circle', label: "Détails" },
      { id: 'sub-tasks', icon: 'far fa-sitemap', label: "Sous-Bulles" },
      { id: 'users', icon: 'far fa-user', label: "Tags spécifiques (cibles) - Pilote projet (CPM)" },
      { id: 'links', icon: 'far fa-paperclip', label: "Pièces jointes (ex : les créas, la fiche synthèse, fiche bilan)" },
      { id: 'budgets', icon: 'far fa-euro-sign', label: "Budget" },
    ],
    hideProgressBar: true,
    hideDatesAdvancedOptions: true,
    hideChecklistAdvancedOptions: true,
    showPicturePreviewInED: true,
    elTitleTooltip(el) {
      const colors = el.getPlanning()?.config?.colors;
      const elColorShade = el.getColorShadeId();
      return colors?.[el.getColorId()]?.sublabels[(typeof elColorShade == 'number' ? elColorShade : -1) + 1] || null;
    },
    miniElementFullTitle: true,
  },
  lanes: {
    hideEmptySublanes: true,
  },
  designcolor: { main: '#0090a6', second: '#e2001a' },
  colors: [
    { label: "AUTO", sublabels: ["AUTO 4R", "LOA LLD", "MOTO 2R", "Camping car", "autres véhicules"] },
    { label: "MRH (HABITATION)", sublabels: ["TEMPO HAB", "TEMPO JEUNE", "ASSCO", "ADI", "PNO", "PJ"] },
    { label: "SANTE", sublabels: ["SANTE INDIV", "SANTE COLLECTIVE", "SANTE TNS"] },
    { label: "PREV (PREVOYANCE)", sublabels: ["ACCIDENTS DE LA VIE (TRF)", "ASSURANCE DECES (TD)", "ASSURANCE EMPRUNTEUR (ADI)", "PREV TNS", "PREV COLLECTIVE"] },
    { label: "EPARGNE", sublabels: ["EPARGNE", "PER", "PATRIMOINE", "EPARGNE SALARIALE"] },
    { label: "CREDIT", sublabels: ["CREDIT AUTO", "CREDIT MOTO", "CREDIT TRAVAUX"] },
    { label: "PRO", sublabels: ["MULTIPRO", "DECENNALE", "RC PRO"] },
    { label: "MULTIPRODUIT", sublabels: [""] },
    { label: "THÉMATIQUES FIDÉLISATION", sublabels: ["MARQUE D'ATTENTION", "BILAN RELATIONNEL", "INSTITUTIONNEL", "PARRAINAGE", "PRÉVENTION PÉDAGOGIE CONSEIL", "RÉTENTION", "SERVICES (VALORISATION)", "RÉGLEMENTAIRE VIE DU CONTRAT"] },
  ],
  colorsOptions: {
    customLayout: true,
    cardsForTarget(target) {
      if (target.isType?.('macro', 'task')) {
        return [
          { id: 'colors', icon: 'far fa-tint', label: "Couleur", customComponent: 'MaafColors' },
          { id: 'icons', icon: 'far fa-icons', label: "Icônes", customComponent: 'MaafPictos' },
          { id: 'timebar', icon: 'far fa-grip-lines-vertical', label: "Marqueur vertical" },
        ];
      }
      return [
        { id: 'colors', icon: 'far fa-tint', label: "Couleur", customComponent: 'MaafColors' },
        { id: 'icons', icon: 'far fa-icons', label: "Icône" },
        { id: 'timebar', icon: 'far fa-grip-lines-vertical', label: "Marqueur vertical" },
      ];
    },
  },
  icons: {
    customIconComponent: 'MaafPictosElView',
  },
  textUsers: ['Jeunes', 'Pros', 'PAT', 'PFS', 'NEW', 'Promo', 'Santéclair', 'Parrainage'],
  virtualParticipantIcon: { Jeunes: 'far fa-bullseye-arrow', Pros: 'far fa-bullseye-arrow', PAT: 'far fa-bullseye-arrow', PFS: 'far fa-concierge-bell', NEW: 'far fa-stars', Promo: 'far fa-money-bill', Santéclair: 'far fa-heartbeat', Parrainage: 'far fa-hand-holding-box' },
  hideManageAccount: true,
};
