<template>
  <msg-box v-for="msgbox in msgboxList" :key="msgbox.id" :type="msgbox.type" @close="msgbox.dismiss('cancel')">
    <template v-if="msgbox.title" #title>
      {{ msgbox.title }}
    </template>
    <template v-if="msgbox.body" #default>
      <div v-html="msgbox.body"></div>
    </template>
    <template #buttons>
      <div :style="{ 'justify-content': msgbox.buttons.ok && msgbox.buttons.cancel ? 'flex-end' : 'center' }" class="d-flex flex-wrap gap-4 my-3">
        <v-btn v-if="msgbox.buttons.cancel" rounded size="large" variant="outlined" style="min-width: 100px" @click="msgbox.dismiss('cancel')">{{ msgbox.buttons.cancel }}</v-btn>
        <v-btn v-if="msgbox.buttons.ok" color="primary" rounded size="large" style="min-width: 100px" @click="msgbox.close()">{{ msgbox.buttons.ok }}</v-btn>
      </div>
    </template>
  </msg-box>
</template>

<script>
  export default {
    computed: {
      msgboxList() {
        return this.$store.state.ui.msgbox.list;
      },
    },
  };
</script>
