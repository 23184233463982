<template>
  <div style="max-width: 800px;">
    <b>{{ fullDisplay ? $t('IMPORT_TEMPLATE.TITLE') : $t('IMPORT_TEMPLATE.TITLE_LIBRARY') }}</b>
    <div class="mt-2">
      <div v-if="fullDisplay" class="small text-medium-emphasis">{{ $t('IMPORT_TEMPLATE.DESCRIPTION') }}</div>
      <v-autocomplete v-model="selectedProject" :items="projectsAvailableAsTemplate" :placeholder="$t('IMPORT_TEMPLATE.SEARCH_PROJECT_TITLE')"
                      :disabled="! projects.length" :loading="! projects.length" item-title="title" item-value="id" return-object class="mt-2">
        <template #item="{ item: { raw: item }, props }">
          <v-divider v-if="item.divider"></v-divider>
          <v-list-item v-else v-bind="props"></v-list-item>
        </template>
      </v-autocomplete>
    </div>
    <div :style="{ opacity: selectedProject ? null : .3, 'pointer-events': selectedProject ? null : 'none' }" class="mt-4">
      <div v-if="fullDisplay" class="mt-6"><b>{{ $t('GLOBAL.OPTIONS') }}</b></div>
      <div class="mt-2">
        <div v-if="fullDisplay" class="ml-2 d-flex align-center">
          <v-switch v-model="changeDates" color="primary" prepend-icon="far fa-calendar-alt"
                    :label="$t('IMPORT_TEMPLATE.ADJUST_START_DATE')">
          </v-switch>
          <date-field v-model="startdate" :disabled="! changeDates" density="compact" format="dddd DD MMMM YYYY" readonly no-null class="ml-2">
          </date-field>
        </div>
        <div>
          <template v-if="fullDisplay">
            <field-label :label="$t('EXPORT.LANES')">
              <template #default="{ id }">
                <v-select v-model="selectedLanesList" :id="id" :items="availableLanes" prepend-inner-icon="far fa-th-list"
                          :disabled="! templateContent" :loading="selectedProject && ! templateContent" item-value="id" item-title="label" multiple>
                  <template #prepend-item>
                    <filter-toggle :selected-ids="selectedLanesList" :sublist-ids="availableLanesIds"
                                   @update="selectedLanesList = $event">
                      {{ $t('EXPORT.ALL_LANES') }} ({{ availableLanesIds.length }})
                    </filter-toggle>
                    <v-divider></v-divider>
                  </template>
                  <template #selection="{ item: { raw: item }, index }">
                    <span v-if="selectedLanesList.length == 1">{{ item.label }}</span>
                    <span v-else-if="selectedLanesList.length == availableLanes.length && index === 0">{{ $t('EXPORT.ALL_LANES') }} ({{ availableLanes.length }})</span>
                    <span v-else-if="index === 0">{{ selectedLanesList.length }}/{{ availableLanes.length }}</span>
                  </template>
                </v-select>
              </template>
            </field-label>
            <v-switch v-model="mergeLanes" :label="$t('IMPORT_TEMPLATE.MERGE_LANES')"></v-switch>
          </template>
          <field-label v-else :label="$t('EXPORT.LANES')">
            <template #default="{ id }">
              <v-select :id="id" :items="[{ id: 'all', label: $t('EXPORT.ALL_LANES') }].concat(availableLanes)" prepend-inner-icon="far fa-th-list"
                        :disabled="! templateContent" :loading="selectedProject && ! templateContent" item-value="id" item-title="label"
                        :model-value="selectedLanesList.length > 1 ? 'all' : selectedLanesList[0]" @update:model-value="selectedLanesList = $event == 'all' ? availableLanesIds : [$event]">
              </v-select>
            </template>
          </field-label>
        </div>

        <v-expand-transition v-if="fullDisplay && templateContent">
          <v-card v-show="mergeLanes" class="mt-2 mb-6">
            <div class="table-responsive">
              <table class="table" style="z-index: 2000; position: relative">
                <thead class="bg-lightgrey">
                  <tr>
                    <td class="text-center" style="width: 50%"><b class="text-uppercase">{{ $t('IMPORT_TEMPLATE.TEMPLATE_LANE') }}</b></td>
                    <td class="text-center"><v-icon color="medium-emphasis">fas fa-caret-right</v-icon></td>
                    <td class="text-center" style="width: 50%"><b class="text-uppercase">{{ $t('IMPORT_TEMPLATE.DESTINATION_LANE') }}</b></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lane in selectedLanes" :key="lane.id">
                    <td>{{ lane.label }}</td>
                    <td><v-icon color="medium-emphasis">fas fa-caret-right</v-icon></td>
                    <td><v-select v-model="lane.destination" :items="destinationLanes"></v-select></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-card>
        </v-expand-transition>

        <div v-if="isBusiness" class="mt-6">
          <field-label :label="fullDisplay ? $t('IMPORT_TEMPLATE.ELEMENTS') : $t('IMPORT_TEMPLATE.SELECT_ELEMENT')">
            <template #default="{ id }">
              <v-autocomplete v-if="fullDisplay" :id="id" v-model="selectedElementsList" :items="availableElements"
                              :disabled="! templateContent" :loading="selectedProject && ! templateContent" multiple item-value="id">
                <template #prepend-inner>
                  <div class="text-center" style="width: 32px; margin-top: 8px">
                    <div style="width: 24px; border-radius: 4px; overflow: hidden; display: inline-block">
                      <div class="text-white text-center task-default-blue" style="height: 8px; width: 100%; line-height: 0;">...</div>
                      <div style="height: 14px; width: 100%; background: rgba(216, 216, 253, 0.7);"></div>
                      <div></div>
                    </div>
                  </div>
                </template>
                <template #prepend-item>
                  <filter-toggle :selected-ids="selectedElementsList" :sublist-ids="availableElementsIds"
                                 @update="selectedElementsList = $event">
                    {{ $t('IMPORT_TEMPLATE.ALL_ELEMENTS') }} ({{ availableElementsIds.length }})
                  </filter-toggle>
                  <v-divider></v-divider>
                </template>
                <template #selection="{ item: { raw: item }, index }">
                  <span v-if="selectedElementsList.length == 1">{{ item.title }}</span>
                  <span v-else-if="selectedElementsList.length == availableElements.length && index === 0">{{ $t('IMPORT_TEMPLATE.ALL_ELEMENTS') }} ({{ availableElements.length }})</span>
                  <span v-else-if="index === 0">{{ selectedElementsList.length }}/{{ availableElements.length }}</span>
                </template>
              </v-autocomplete>
              <v-autocomplete v-else :items="availableElements"
                              :disabled="! templateContent" :loading="selectedProject && ! templateContent" item-value="id"
                              :model-value="selectedElementsList[0]" @update:model-value="selectedElementsList = [$event]">
              </v-autocomplete>
            </template>
          </field-label>
        </div>
      </div>
    </div>
    <div class="text-center mt-8">
      <v-btn :disabled="! templateContent || importing || ! selectedElementsList.length" :loading="importing" color="primary" rounded :min-width="180" @click="importTemplate()">
        {{ $t('IMPORT_TEMPLATE.IMPORT') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { reactive } from 'vue';
  import clientConfig from '@/client_customs/config';
  import Planning from '@/models/Planning';
  import ProjectSrv from '@/components/Projects/ProjectSrv';

  export default {
    props: {
      planning: { type: Object, required: true },
      fixedLane: { type: [Number, String], default: null },
      fixedDate: { type: Object, default: null },
    },
    emits: ['close'],
    data() {
      const data = {
        projects: [],
        selectedProject: null,
        templateContent: null,
        changeDates: clientConfig.importTemplate && clientConfig.importTemplate.changeDates && true || false,
        startdate: moment(),
        selectedLanesList: [],
        selectedElementsList: [],
        mergeLanes: clientConfig.importTemplate && clientConfig.importTemplate.mergeLanes ? 0 : null,
        loadingSubproject: false,
        importing: false,
      };
      if (this.fixedDate) {
        data.changeDates = true;
        data.startdate = this.fixedDate;
      }
      if (this.fixedLane) data.mergeLanes = 0;
      return data;
    },
    computed: {
      fullDisplay() {
        return ! this.fixedDate && ! this.fixedLane;
      },
      projectsWithTemplateStatus() {
        const projectStatusConfig = this.$store.getters['users/accessRight/config'].projectStatus || {};
        return this.projects.filter(project => projectStatusConfig[project.status]?.template);
      },
      projectsAvailableAsTemplate() {
        let { projects } = this;
        if (clientConfig.projects?.filterTemplateName) {
          projects = projects.filter(clientConfig.projects.filterTemplateName);
        } else if (this.projectsWithTemplateStatus.length) {
          return [
            ...this.projectsWithTemplateStatus.slice().sort((a, b) => a.title.localeCompare(b.title)),
            { divider: true },
            ..._.difference(projects, this.projectsWithTemplateStatus).sort((a, b) => a.title.localeCompare(b.title)),
          ];
        }
        return projects.slice().sort((a, b) => a.title.localeCompare(b.title));
      },
      availableLanes() {
        if (! this.templateContent) return [];
        return reactive(this.templateContent.lanes.map(item => ({ id: item.id, label: item.label, destination: this.fixedLane })));
        // explicitely return reactive object to allow reactivity on template when mutating destination
      },
      availableLanesIds() {
        return this.availableLanes.map(item => item.id);
      },
      selectedLanes() {
        const selectedLanesListSet = new Set(this.selectedLanesList);
        return this.availableLanes.filter(item => selectedLanesListSet.has(item.id));
      },
      destinationLanes() {
        const destinationLanes = this.planning.lanes.map(lane => ({ value: lane.id, title: lane.label }));
        destinationLanes.unshift({ value: null, title: this.$t('IMPORT_TEMPLATE.CREATE_LANE') });
        return destinationLanes;
      },
      availableElements() {
        if (! this.templateContent) return [];
        const selectedLanesIds = new Set(this.selectedLanes.map(item => item.id));
        const laneIndex = this.selectedLanes.reduce((acc, item, index) => { acc[item.id] = index; return acc; }, {});
        return this.templateContent.elements
          .filter(item => selectedLanesIds.has(item.lane_id))
          .sort((a, b) => (laneIndex[a.lane_id] - laneIndex[b.lane_id]) || (a.starttime < b.starttime ? -1 : 1))
          .map(item => ({ id: item.id, title: item.title }));
      },
      availableElementsIds() {
        return this.availableElements.map(item => item.id);
      },
      selectedElements() {
        const selectedElementsListSet = new Set(this.selectedElementsList);
        return this.availableElements.filter(item => selectedElementsListSet.has(item.id));
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
    watch: {
      selectedProject(newVal) {
        if (! newVal) return;
        this.templateContent = null;
        this.loadingSubproject = true;
        ProjectSrv.get(newVal.id).then((content) => {
          this.templateContent = content;
        }).finally(() => {
          this.loadingSubproject = false;
        });
      },
      availableLanesIds(newVal) {
        this.selectedLanesList = newVal;
      },
      availableElementsIds(newVal) {
        if (this.fixedDate) {
          // library mode : keep selection if still in availableElementsIds
          this.selectedElementsList = _.intersection(this.selectedElementsList, newVal);
          return;
        }
        this.selectedElementsList = newVal; // reset selection to all availableElementsIds
      },
    },
    created() {
      ProjectSrv.list().then((data) => {
        this.projects = data;
        if (this.projectsWithTemplateStatus.length) this.selectedProject = this.projectsAvailableAsTemplate[0]; // autoselect first template
      });
    },
    methods: {
      close() {
        this.$emit('close');
      },
      importTemplate() {
        if (! this.templateContent) return;
        const templateContent = { ...this.templateContent };
        templateContent.lanes = templateContent.lanes.filter(lane => this.selectedLanes.find(item => item.id == lane.id));
        templateContent.elements = templateContent.elements.filter(el => this.selectedElements.find(item => item.id == el.id));
        const template = new Planning(ProjectSrv.projectFromTemplate({ template: templateContent, startdate: this.changeDates && this.startdate }));
        const promises = [];
        this.importing = true;
        setTimeout(() => {
          const elementsMap = new Map();

          template.lanes.forEach((templateLane) => {
            const selectedLane = this.selectedLanes.find(item => item.id == templateLane.id);
            if (! selectedLane) return;

            let newLanePromise;
            if (this.mergeLanes === 0 && selectedLane.destination) {
              newLanePromise = Promise.resolve(this.planning.lanes.find(lane => lane.id == selectedLane.destination));
            } else {
              newLanePromise = this.$store.dispatch('planning/lanes/addLane', templateLane);
            }
            newLanePromise = newLanePromise.then((newlane) => {
              const templateLaneElements = template.elements.filter(templateEl => templateEl.getLaneId() == templateLane.id);
              templateLaneElements.forEach((newel) => {
                newel.setLaneId(newlane.id);
              });
              const argstab = templateLaneElements.map(templateEl => ({ type: templateEl.getType(), lane: newlane, el: templateEl }));
              return this.$store.dispatch('planning/elements/addElements', { argstab }).then((newels) => {
                newels.forEach((newel, elIndex) => {
                  elementsMap.set(templateLaneElements[elIndex], newel);
                });
              });
            });
            promises.push(newLanePromise);
          });
          if (promises.length) {
            Promise.all(promises).then(() => {
              const elementsMapIds = {};
              elementsMap.forEach((newel, templateEl) => {
                elementsMapIds[templateEl.id] = newel.id;
              });
              // restore dependencies
              elementsMap.forEach((newel, templateEl) => {
                const dependencies = templateEl.getDependencies();
                if (! dependencies) return;
                const newDependencies = [];
                dependencies.forEach((dependency) => {
                  const targetId = elementsMapIds[dependency.target_id];
                  if (! targetId) return;
                  dependency.target_id = targetId;
                  newDependencies.push(dependency);
                });
                if (newDependencies.length) newel.setDependencies(newDependencies);
              });
              // update macro subTasks
              elementsMap.forEach((newel, templateEl) => {
                const subTasks = templateEl.getSubTasks();
                if (! subTasks) return;
                const newSubTasks = [];
                subTasks.forEach((subTaskId) => {
                  if (! elementsMapIds[subTaskId]) return;
                  newSubTasks.push(elementsMapIds[subTaskId]);
                });
                newel.setSubTasks(newSubTasks);
              });

              this.$store.dispatch('planning/save', null);
              setTimeout(() => {
                elementsMap.forEach((newel) => {
                  newel.updateHeight();
                });
              });
            });
          }
          this.importing = false;

          this.close();
        });
      },
    },
  };
</script>
