<template>
  <div v-if="elementBreadcrumb" class="ml-n4 d-flex align-center">
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-btn icon variant="flat" size="small" density="comfortable" :href="elementBreadcrumb.url" target="_blank" rel="noopener" class="bg-white mr-2" v-bind="props">
          <v-icon>far fa-folder-open</v-icon>
        </v-btn>
      </template>
      {{ $t('ELEMENT-DETAIL.OPEN_PROJECT') }}
    </v-tooltip>
    {{ elementBreadcrumb.label }}
    <v-icon size="small" class="mx-2" style="pointer-events: none; margin-top: 1px">far fa-chevron-right</v-icon>
  </div>
</template>

<script>
  export default {
    props: {
      element: { type: Object, required: true },
    },
    computed: {
      elementBreadcrumb() {
        const planning = this.element && this.element.getPlanning();
        if (! planning) return null;
        return {
          label: `${planning.getTitle() || this.$t('PLANNING.UNNAMED_PROJECT')}`,
          url: `${planning.getUrl()}?scrolltoel=${this.element.id}`,
        };
      },
    },
  };
</script>
