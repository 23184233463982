import { createI18n } from 'vue-i18n';
import EN from '@/lang/en.json';
import FR from '@/lang/fr.json';
import vuetifyFR from '@/lang/vuetifyFR';
import vuetifyEN from '@/lang/vuetifyEN';
import clientConfig from '@/client_customs/config';

const initLang = window.localStorageWrapper.getItem('lang') || 'en';
const i18n = createI18n({
  legacy: false,
  locale: initLang,
  fallbackLocale: 'en',
  messages: {
    en: { ...vuetifyEN, ...angular.merge(EN, clientConfig.lang && clientConfig.lang.en) },
    fr: { ...vuetifyFR, ...angular.merge(FR, clientConfig.lang && clientConfig.lang.fr) },
  },
});

export default i18n;
