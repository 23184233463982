import { getDefaultProcessStep } from './helpers/defaultValues';

class PlanningProcessStep {
  constructor(planning, srcData) {
    const data = angular.copy(srcData) || {};

    if (data instanceof PlanningProcessStep) {
      _.extend(this, data);
    } else {
      angular.merge(this, getDefaultProcessStep(), data);
    }

    this.getPlanning = function () {
      return planning;
    };
  }

  getAll() {
    const nonNullData = {};
    const excludedKeys = ['getPlanning'];
    Object.keys(this).forEach((key) => { if (this[key] !== null && ! excludedKeys.includes(key)) nonNullData[key] = this[key]; });
    return _.extend({ id: this.id }, nonNullData);
  }

  set(data) {
    angular.merge(this, data);
    return this;
  }

  getColorId() {
    return this.color;
  }
  setColorId(value) {
    this.color = (typeof value == 'number') ? value : 0;
    return this;
  }
  getColorShadeId() {
    return this.colorShade;
  }
  setColorShadeId(value) {
    this.colorShade = (typeof value == 'number') ? value : '';
    return this;
  }
  getIconId() {
    return this.icon;
  }
  setIconId(value) {
    this.icon = value;
    return this;
  }

  /** ******** */
  /* DISPLAY */
  /** ******** */
  getColorClass() {
    return `el-color-${this.getColorId()}-${this.getColorShadeId()}`;
  }

  hasIcon() {
    return !! this.icon;
  }
  getIcon() {
    const { icons } = this.getPlanning().config;
    return icons[this.icon] || {};
  }
}

export default PlanningProcessStep;
