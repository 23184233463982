/* eslint-disable import/no-mutable-exports */
import clientConfig from '@/client_customs/config';

function getDefaultColors() {
  const defaultColors = [
    {
      label: '',
      secondary: '#DEDEFD', // expected hex form
      sublabels: ['', '', '', '', ''],
    },
    {
      label: '',
      secondary: '#FFFFCB',
      sublabels: ['', '', '', '', ''],
    },
    {
      label: '',
      secondary: '#D8F3EE',
      sublabels: ['', '', '', '', ''],
    },
    {
      label: '',
      secondary: '#FBD6D6',
      sublabels: ['', '', '', '', ''],
    },
    {
      label: '',
      secondary: '#CDACDA',
      sublabels: ['', '', '', '', ''],
    },
    {
      label: '',
      secondary: '#CAD2D2',
      sublabels: ['', '', '', '', ''],
    },
    {
      label: '',
      secondary: '#FADFEE',
      sublabels: ['', '', '', '', ''],
    },
    {
      label: '',
      secondary: '#FCE2CE',
      sublabels: ['', '', '', '', ''],
    },
  ];

  if (clientConfig.colors) {
    if (clientConfig.colorsOptions?.customLayout) {
      defaultColors.splice(0, defaultColors.length, ...clientConfig.colors);
      return defaultColors;
    }

    defaultColors.forEach((color, index) => {
      if (! clientConfig.colors[index]) return;
      if (typeof clientConfig.colors[index].label == 'string') color.label = clientConfig.colors[index].label;
      if (clientConfig.colors[index].sublabels && clientConfig.colors[index].sublabels.length == 5) color.sublabels = clientConfig.colors[index].sublabels;
    });
  }

  return defaultColors;
}

function getDefaultIcons() {
  let defaultIcons = {
    milestone0: { name: 'fa icon-diamonds', color: 'primary', label: '' },
    milestone1: { name: 'fa icon-diamonds', color: 'warningorange', label: '' },
    milestone2: { name: 'fa icon-diamonds', color: 'successgreen', label: '' },
    milestone3: { name: 'fa icon-diamonds', color: 'errorred', label: '' },
    milestone4: { name: 'fa icon-diamonds', color: 'info', label: '' },
    milestone5: { name: 'fa icon-diamonds', color: 'darkgrey', label: '' },
    danger: { name: 'fas fa-times-circle', color: 'errorred', label: '' },
    warning: { name: 'fas fa-exclamation-triangle', color: 'warningorange', label: '' },
    done: { name: 'fas fa-check-circle', color: 'successgreen', label: '' },
    question: { name: 'fas fa-question-circle', color: 'primary', label: '' },
    information: { name: 'fas fa-info-circle', color: 'primary', label: '' },
    forbidden: { name: 'fas fa-minus-circle', color: 'errorred', label: '' },
    phone: { name: 'fas fa-phone', color: 'primary', label: '' },
    email: { name: 'fas fa-envelope', color: 'successgreen', label: '' },
    document: { name: 'far fa-file-alt', color: 'darkgrey', label: '' },
    signature: { name: 'far fa-edit', color: 'successgreen', label: '' },
    send: { name: 'fas fa-paper-plane', color: 'info', label: '' },
    talk: { name: 'fas fa-bullhorn', color: 'primary', label: '' },
    user: { name: 'fas fa-user', color: 'primary', label: '' },
    users: { name: 'fas fa-users', color: 'successgreen', label: '' },
    userFriends: { name: 'fas fa-user-friends', color: 'info', label: '' },
    admin: { name: 'fas fa-user-shield', color: 'warningorange', label: '' },
    secret: { name: 'fas fa-user-secret', color: 'errorred', label: '' },
    manager: { name: 'fas fa-user-tie', color: 'primary', label: '' },
    rocket: { name: 'fas fa-rocket', color: 'successgreen', label: '' },
    target: { name: 'fas fa-bullseye-arrow', color: 'successgreen', label: '' },
    shareAlt: { name: 'fas fa-share-alt', color: 'primary', label: '' },
    share: { name: 'fas fa-share', color: 'successgreen', label: '' },
    lock: { name: 'fas fa-lock', color: 'errorred', label: '' },
    unlock: { name: 'fas fa-unlock-alt', color: 'successgreen', label: '' },
    clock: { name: 'fas fa-clock', color: 'info', label: '' },
    time: { name: 'fas fa-hourglass-half', color: 'warningorange', label: '' },
    alarm: { name: 'fas fa-alarm-clock', color: 'errorred', label: '' },
    tasks: { name: 'fas fa-tasks', color: 'primary', label: '' },
    comment: { name: 'fas fa-comment', color: 'successgreen', label: '' },
    cog: { name: 'fas fa-cog', color: 'darkgrey', label: '' },
    star: { name: 'fas fa-star', color: 'primary', label: '' },
    star1: { name: 'fas fa-star', color: 'warningorange', label: '' },
    star2: { name: 'fas fa-star', color: 'successgreen', label: '' },
    star3: { name: 'fas fa-star', color: 'errorred', label: '' },
    star4: { name: 'fas fa-star', color: 'info', label: '' },
    star5: { name: 'fas fa-star', color: 'darkgrey', label: '' },
    happy: { name: 'far fa-smile', color: 'successgreen', label: '' },
    neutral: { name: 'far fa-meh', color: 'darkgrey', label: '' },
    sad: { name: 'far fa-frown', color: 'errorred', label: '' },
    stats: { name: 'fas fa-chart-pie', color: 'primary', label: '' },
    chartline: { name: 'fas fa-chart-line', color: 'successgreen', label: '' },
    chartbar: { name: 'fas fa-chart-bar', color: 'warningorange', label: '' },
    handshake: { name: 'fas fa-handshake', color: 'successgreen', label: '' },
    help: { name: 'fas fa-hands-helping', color: 'warningorange', label: '' },
    halt: { name: 'fas fa-hand-paper', color: 'errorred', label: '' },
    appreciation: { name: 'fas fa-thumbs-up', color: 'successgreen', label: '' },
    bad: { name: 'fas fa-thumbs-down', color: 'errorred', label: '' },
    gift: { name: 'fas fa-gift', color: 'successgreen', label: '' },
    go: { name: 'fas fa-traffic-light-go', color: 'successgreen', label: '' },
    slow: { name: 'fas fa-traffic-light-slow', color: 'warningorange', label: '' },
    stop: { name: 'fas fa-traffic-light-stop', color: 'errorred', label: '' },
    image: { name: 'far fa-image', color: 'primary', label: '' },
    video: { name: 'fab fa-youtube', color: 'info', label: '' },
    book: { name: 'fas fa-book', color: 'darkgrey', label: '' },
    industry: { name: 'fas fa-industry', color: 'warningorange', label: '' },
    company: { name: 'fas fa-building', color: 'info', label: '' },
    store: { name: 'fas fa-store', color: 'primary', label: '' },
    warehouse: { name: 'fas fa-warehouse', color: 'darkgrey', label: '' },
    calendar: { name: 'fas fa-calendar-alt', color: 'info', label: '' },
    newDate: { name: 'fas fa-calendar-plus', color: 'successgreen', label: '' },
    toolbox: { name: 'fas fa-toolbox', color: 'primary', label: '' },
    tools: { name: 'fas fa-tools', color: 'info', label: '' },
    design: { name: 'fas fa-pencil-ruler', color: 'successgreen', label: '' },
    digging: { name: 'fas fa-digging', color: 'warningorange', label: '' },
    binoculars: { name: 'fas fa-binoculars', color: 'primary', label: '' },
    quality: { name: 'fas fa-gem', color: 'info', label: '' },
    sun: { name: 'fas fa-sun', color: 'warningorange', label: '' },
    sunCloud: { name: 'fas fa-cloud-sun', color: 'info', label: '' },
    rain: { name: 'fas fa-cloud-rain', color: 'primary', label: '' },
    storm: { name: 'fas fa-poo-storm', color: 'errorred', label: '' },
    buld: { name: 'fas fa-lightbulb', color: 'warningorange', label: '' },
    lightbuld: { name: 'fas fa-lightbulb-on', color: 'successgreen', label: '' },
    press: { name: 'fas fa-newspaper', color: 'darkgrey', label: '' },
    presentation: { name: 'fas fa-presentation', color: 'successgreen', label: '' },
    conference: { name: 'fas fa-users-class', color: 'info', label: '' },
    desktop: { name: 'fas fa-desktop', color: 'primary', label: '' },
    laptop: { name: 'fas fa-laptop', color: 'successgreen', label: '' },
    mobile: { name: 'fas fa-mobile-alt', color: 'warningorange', label: '' },
    powerpoint: { name: 'fas fa-file-powerpoint', color: 'info', label: '' },
    certificate: { name: 'fas fa-file-certificate', color: 'successgreen', label: '' },
    euro: { name: 'fas fa-euro-sign', color: 'warningorange', label: '' },
    bill: { name: 'fas fa-money-bill', color: 'primary', label: '' },
    windturbine: { name: 'fas fa-wind-turbine', color: 'primary', label: '' },
    fire: { name: 'fas fa-burn', color: 'errorred', label: '' },
    attachement: { name: 'fas fa-paperclip', color: 'successgreen', label: '' },
    globe: { name: 'fas fa-globe', color: 'primary', label: '' },
    database: { name: 'fas fa-database', color: 'primary', label: '' },
    sitemap: { name: 'fas fa-sitemap', color: 'info', label: '' },
    redo: { name: 'fas fa-redo', color: 'primary', label: '' },
    sync: { name: 'fas fa-sync', color: 'info', label: '' },
    pin: { name: 'fas fa-thumbtack', color: 'primary', label: '' },
    pin3: { name: 'fas fa-thumbtack', color: 'errorred', label: '' },
    flag: { name: 'fas fa-flag', color: 'successgreen', label: '' },
    trophy: { name: 'fas fa-trophy', color: 'warningorange', label: '' },
    position: { name: 'fas fa-map-marker-alt', color: 'primary', label: '' },
    location: { name: 'fas fa-location', color: 'info', label: '' },
    route: { name: 'fas fa-route', color: 'successgreen', label: '' },
    car: { name: 'fas fa-car', color: 'primary', label: '' },
    truck: { name: 'fas fa-truck', color: 'warningorange', label: '' },
    ship: { name: 'fas fa-ship', color: 'primary', label: '' },
    plane: { name: 'fas fa-plane', color: 'info', label: '' },
    bulldozer: { name: 'fas fa-truck-plow', color: 'errorred', label: '' },
  };

  if (clientConfig.icons) {
    if (clientConfig.icons.replace) {
      defaultIcons = clientConfig.icons.replace;
    } else {
      (clientConfig.icons.remove || []).forEach((item) => { delete defaultIcons[item]; });
      const position = clientConfig.icons.position || 0;
      const iconsKeys = Object.keys(defaultIcons);
      let customIcons = {};
      iconsKeys.slice(0, position).forEach((item) => { customIcons[item] = defaultIcons[item]; });
      customIcons = { ...customIcons, ...clientConfig.icons.add };
      iconsKeys.slice(position).forEach((item) => {
        customIcons[item] = _.extend(defaultIcons[item], customIcons[item]);
      });
      defaultIcons = customIcons;
    }
  }

  return defaultIcons;
}

export { getDefaultColors, getDefaultIcons };
