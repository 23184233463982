<template>
  <autocomplete-filter v-model="selectedProjectOwners" :items="projectOwnersList" item-title="username" item-value="id"
                       :label="$t('MULTIPROJECTS.OWNERS')" :placeholder="selectedProjectOwners.length ? '' : $t('MULTIPROJECTS.NO_OWNER_SELECTED')"
                       :prepend-inner-icon="smallScreen ? null : 'far fa-user'" multiple :menu-props="{ location: 'bottom' }"
                       @update:model-value="update">
    <template #prepend-item>
      <filter-toggle :selected-ids="selectedProjectOwners" :sublist-ids="projectOwnerIds"
                     @update="selectedProjectOwners = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_OWNERS') }} ({{ projectOwnersCount }})
      </filter-toggle>
      <v-divider></v-divider>
      <v-list-group v-if="organizationCompanies.length" fluid>
        <template #activator="{ props }">
          <v-list-item v-bind="props">
            <v-list-item-title class="ml-4">{{ $t('PROJECTS.ORGANIZATION') }}</v-list-item-title>
          </v-list-item>
        </template>
        <filter-toggle v-for="company in organizationCompanies" :key="company.id"
                       :selected-ids="selectedProjectOwners" :sublist-ids="projectOwnerIdsByOrganizationCompany[company.id]" indent
                       @update="selectedProjectOwners = $event; update()">
          {{ company.name }} ({{ projectOwnerIdsByOrganizationCompany[company.id].length }})
        </filter-toggle>
        <v-divider></v-divider>
      </v-list-group>
    </template>
    <template #item="{ props, item: { raw: item } }">
      <v-divider v-if="item.divider"></v-divider>
      <v-list-subheader v-else-if="item.header" :title="item.header"></v-list-subheader>
      <v-list-item v-else v-bind="props">
        <template #prepend>
          <user-avatar :user="item" :size="32"></user-avatar>
        </template>
      </v-list-item>
    </template>
    <template #selection="{ item: { raw: item }, index }">
      <span v-if="selectedProjectOwners.length == 1" class="text-ellipsis">{{ item.username }}</span>
      <span v-else-if="selectedProjectOwners.length == projectOwnersCount && index === 0" class="text-ellipsis">{{ $t('MULTIPROJECTS.ALL_OWNERS') }} ({{ projectOwnersCount }})</span>
      <span v-else-if="index === 0" class="text-ellipsis">{{ selectedProjectOwners.length }}/{{ projectOwnersCount }}</span>
    </template>
  </autocomplete-filter>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      archivedProjects: { type: Array, default: () => [] },
    },
    emits: ['update'],
    computed: {
      selectedProjectOwners: {
        get() {
          return this.portfolioOptions.selectedProjectOwners;
        },
        set(newVal) {
          this.portfolioOptions.selectedProjectOwners.splice(0, this.portfolioOptions.selectedProjectOwners.length, ...newVal);
        },
      },
      projectOwners() {
        const projectOwners = new Map();
        this.projects.concat(this.portfolioOptions.displayArchived ? this.archivedProjects : []).forEach((project) => {
          const ownerId = project.meta ? project.meta.owner_id : project.owner_id;
          if (ownerId && ! projectOwners.has(ownerId)) {
            const owner = this.$store.getters['users/getUserById'](ownerId);
            if (! owner) return; // owner can be null in very specific case when project owner is outside organization (ie planningshares have not been correctly updated / deleted)
            projectOwners.set(ownerId, {
              id: ownerId,
              username: this.$store.getters['users/getUsername'](owner),
            });
          }
        });
        return projectOwners;
      },
      projectOwnerIds() {
        return [...this.projectOwners.keys()];
      },
      organizationCompanies() {
        const { organization } = this.$store.state.users.user;
        if (organization && organization.getCompanies().length > 1) return organization.getCompanies().filter(item => item.users.length);
        return [];
      },
      projectOwnerIdsByOrganizationCompany() {
        return this.organizationCompanies.reduce((acc, company) => {
          acc[company.id] = company.users.filter(item => this.projectOwners.has(item.id)).map(item => item.id);
          return acc;
        }, {});
      },
      projectOwnersList() {
        if (this.organizationCompanies.length) {
          const projectOwnersList = [];
          for (let i = 0; i < this.organizationCompanies.length; i++) {
            const company = this.organizationCompanies[i];
            const projectOwners = company.users.map(user => this.projectOwners.get(user.id)).filter(item => item);
            if (! projectOwners.length) continue;
            projectOwnersList.push({ header: company.name }, { divider: true }, ...projectOwners);
          }
          return projectOwnersList;
        }
        return [...this.projectOwners.values()];
      },
      projectOwnersCount() {
        return this.projectOwners.size;
      },
      smallScreen() { return this.$vuetify.display.mdAndDown; },
      ...mapState('multiprojects', ['projects', 'portfolioOptions']),
    },
    created() {
      if (! this.selectedProjectOwners.length) this.selectedProjectOwners = this.projectOwnerIds.slice();
    },
    methods: {
      update() {
        this.$emit('update');
      },
    },
  };
</script>
