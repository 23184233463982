<template>
  <div class="d-flex align-center mb-2">
    <v-icon class="mr-2" style="flex: 0 0 24px">far fa-paperclip</v-icon>
    <b>{{ $t('PROJECT_SHEET.FILES_DESCRIPTION') }}</b>
  </div>
  <v-card class="pa-6">
    <div v-for="el in filteredLinksElements" class="mb-2">
      <div class="small pointer" style="font-style: italic;" @click="openElement(el)">
        {{ el.getTitle() || $t('PLANNING.NEW_BUBBLE') }}
      </div>
      <ul class="list-unstyled" style="margin-left: 16px; margin-top: 2px">
        <li v-for="link in el.getLinks()" class="text-ellipsis" style="margin-bottom: 2px">
          <link-icon :link="link" class="mr-1"></link-icon>
          <a :href="subdomainLinkUrl(link.url)" target="_blank" rel="noopener">{{ link.name || link.url }}</a>
        </li>
      </ul>
    </div>
    <div v-if="! filteredLinksElements.length" class="mt-2 text-medium-emphasis">{{ $t('PROJECT_SHEET.NO_FILE') }}</div>
  </v-card>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import LinkIcon from '@/components/Planning/partials/LinkIcon.vue';

  export default {
    components: {
      LinkIcon,
    },
    props: {
      planning: { type: Object, required: true },
    },
    emits: ['open-element'],
    data() {
      return {
      };
    },
    computed: {
      filteredLinksElements() {
        return this.planning.elements.filter(el => ! el.isType('macro') && el.getLinks() && el.getLinks().length)
          .sort((a, b) => (a.getLaneId() < b.getLaneId() ? -1 : 1));
      },
    },
    methods: {
      openElement(el) {
        this.$emit('open-element', el);
      },
      subdomainLinkUrl(url) {
        return clientConfig.subdomain && url?.startsWith('https://api.bubbleplan.net') ? `${url}?subdomain=${clientConfig.subdomain}` : url;
      },
    },
  };
</script>
