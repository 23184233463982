<template>
  <v-menu v-if="isVisible" location="bottom center">
    <template #activator="{ props }">
      <v-btn :active="$route.path == '/projects'" variant="text" class="pl-1 pr-2" style="min-width: 0" v-bind="props">
        <v-icon>fas fa-caret-down</v-icon>
      </v-btn>
    </template>
    <v-list density="compact">
      <v-list-item v-for="(navigation, index) in projectNavigationHistoryFiltered" :key="index"
                   :to="navigation.path + (navigation.rotoken ? `?rotoken=${navigation.rotoken}` : '')">
        <template #prepend>
          <v-img :src="navigation.imgurl" :width="48" :max-height="40" cover position="0 0" class="mr-2"></v-img>
        </template>
        <i v-if="navigation.type == 'planningview'">Vue : &nbsp;</i>{{ navigation.title }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { mapStores } from 'pinia';
  import useNavigationProjectsHistoryStore from '@/stores/navigation-projects-history';

  export default {
    emits: ['is-visible'],
    computed: {
      projectNavigationHistoryFiltered() {
        const pathCheck = /^\/(planning|viewer|planningview)\/(.+?)(\/.*)?$/i.exec(this.$route.path);
        if (! pathCheck) return this.NavigationProjectsHistoryStore.getHistory;
        const [, type, id] = pathCheck;
        return this.NavigationProjectsHistoryStore.getHistory.filter(item => ! (item.type == type && item.id == id));
      },
      isVisible() {
        return !! this.projectNavigationHistoryFiltered.length;
      },
      ...mapStores(useNavigationProjectsHistoryStore),
    },
    watch: {
      isVisible: {
        handler(newVal) {
          this.$emit('is-visible', newVal);
        },
        immediate: true,
      },
    },
  };
</script>
