<template>
  <dashboards-filters :filters="dashboardFilters" use-action-users>
    <template #prepend>
      <select-filter v-model="actionsOptions.groupBy"
                     :items="groupByOptions"
                     :label="$t('MONITORING_ACTIONS.FILTERS.GROUP_BY')" :menu-props="{ location: 'bottom' }" style="flex: 1 0 120px">
      </select-filter>
      <div class="mx-4 my-n4">
        <v-switch v-model="actionsOptions.displayCompleted" density="compact" class="my-n4">
          <template #label>
            <small>{{ $t('MONITORING_ACTIONS.FILTERS.DISPLAY_COMPLETED_ACTIONS') }}</small>
          </template>
        </v-switch>
        <v-switch v-model="actionsOptions.withWorkloadOnly" density="compact" class="my-n4">
          <template #label>
            <small>{{ $t('MONITORING_ACTIONS.FILTERS.WITH_WORKLOAD_ONLY') }}</small>
          </template>
        </v-switch>
      </div>
      <v-tooltip :disabled="dropdownIsOpen" location="bottom">
        <template #activator="{ props: onTooltip }">
          <v-menu v-model="dropdownIsOpen" :close-on-content-click="false" location="bottom end">
            <template #activator="{ props: onMenu }">
              <v-btn icon color="secondary" size="small" v-bind="mergeProps(onMenu, onTooltip)">
                <v-badge :model-value="!! actionsOptions.title" color="successgreen" floating dot>
                  <v-icon>far fa-search</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <v-list class="pt-4 pb-8">
              <v-list-item class="d-block">
                <text-filter :model-value="actionsOptions.title" :label="$t('MONITORING_ACTIONS.FILTERS.FILTER_ACTIONS')" :placeholder="$t('MONITORING_ACTIONS.FILTERS.SEARCH_TITLE')"
                             id="actions-dashboard-search-input" clearable style="min-width: 180px"
                             @change="actionsOptions.title = $event.target.value" @keydown.enter="actionsOptions.title = $event.target.value" @click:clear="actionsOptions.title = ''">
                </text-filter>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <span>{{ $t('GLOBAL.SEARCH') }}</span>
      </v-tooltip>
      <div class="dashboard-filters-spacer"></div>
    </template>
    <template #append>
      <slot name="append"></slot>
    </template>
  </dashboards-filters>
</template>

<script>
  import { mergeProps } from 'vue';
  import { mapState } from 'vuex';
  import DashboardsFilters from '../DashboardsFilters/DashboardsFilters.vue';

  export default {
    components: {
      DashboardsFilters,
    },
    data() {
      return {
        dropdownIsOpen: null,
      };
    },
    computed: {
      dashboardFilters() {
        return ['Projects', this.actionsOptions.groupBy == 'group' ? 'GroupUsers' : 'Users', 'Dates'];
      },
      groupByOptions() {
        const options = [{ value: 'user', title: this.$t('MULTIPROJECTS.USERS') }, { value: 'planning', title: this.$t('MULTIPROJECTS.PROJECTS') }];
        if (this.isBusiness) options.push({ value: 'group', title: this.$t('GLOBAL.GROUPS') });
        return options;
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      ...mapState('multiprojects', ['actionsOptions']),
    },
    watch: {
      'actionsOptions.title': function () {
        this.dropdownIsOpen = false;
      },
      dropdownIsOpen(newVal) {
        if (newVal) {
          setTimeout(() => document.getElementById('actions-dashboard-search-input')?.focus(), 100);
        }
      },
    },
    methods: {
      mergeProps,
    },
  };
</script>
