<template>
  <div class="element-details-checklist-item hovervisible-activator">
    <div class="d-flex align-center">
      <v-icon size="small" class="checklist-draggable-anchor hovervisible-item grab text-medium-emphasis mb-.5">far fa-grip-vertical</v-icon>
      <v-checkbox v-model="item.checked" color="successgreen" density="compact" tabindex="-1"
                  style="flex: 0 0 auto; --v-input-control-height: 32px; --v-theme-overlay-multiplier: 0">
      </v-checkbox>
      <input v-model="item.title" class="flex-grow-1 py-1 px-2 rounded" style="background: transparent; color: inherit; border: none;"
             @focus="itemOptionsIsOpened = true">
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-btn-icon size="x-small" color="errorred" class="hovervisible-item" v-bind="props" @click="removeChecklistItem">
            <v-icon size="large">far fa-trash-alt</v-icon>
          </v-btn-icon>
        </template>
        <span>{{ $t('ELEMENT-DETAIL.DELETE_CHECKLIST_ITEM') }}</span>
      </v-tooltip>
    </div>

    <v-expand-transition v-if="! hideChecklistAdvancedOptions">
      <div v-if="itemOptionsIsOpened || hasAdvancedContent" class="ml-5 mb-1 d-flex align-center flex-wrap" style="column-gap: 4px">
        <!-- Responsible -->
        <div v-if="item.user_id || item.group_id || item.username" class="mx-1 d-flex align-center">
          <user-workload-indicator v-if="hasUserWorkloadIndicator" :user="{ id: item.user_id, group_id: item.group_id, username: item.username }" :starttime="starttime" :endtime="endtime"
                                   small bottom hide-zero class="mr-1">
          </user-workload-indicator>
          <div class="d-flex align-center text-ellipsis hovervisible-activator-2" style="position: relative;">
            <user-avatar :user="{ id: item.user_id, group_id: item.group_id, username: item.username }" show-username username-format="short" class="mr-1"></user-avatar>
            <i :title="$t('ELEMENT-DETAIL.REMOVE_RESPONSIBLE')"
               class="hovervisible-item-2 far fa-times fa-lg pointer text-errorred" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"
               @click="item.user_id = null; item.group_id = null; item.username = null">
            </i>
          </div>
        </div>

        <div v-else class="d-inline-flex align-center small-input rounded py-1">
          <label :for="`itemResponsible${itemId}`" class="pl-2 pr-1 d-inline-flex align-center pointer">
            <v-tooltip location="bottom" max-width="150">
              <template #activator="{ props: iconProps }">
                <v-icon size="small" color="medium-emphasis" class="mb-.5" v-bind="iconProps">far fa-user-plus</v-icon>
              </template>
              <span>{{ $t('ELEMENT-DETAIL.ADD_RESPONSIBLE') }}</span>
            </v-tooltip>
          </label>
          <div class="input-autosize-wrapper mr-1 overflow-hidden">
            <div class="input-autosize-invisible-text" style="min-width: 160px"></div>
            <select-user :id="`itemResponsible${itemId}`" :placeholder="$t('ELEMENT-DETAIL.RESPONSIBLE')" :workloads="{ starttime, endtime }"
                         with-groups with-text-users class="flex-grow-1 mt-n2"
                         variant="plain" no-icon
                         @update:model-value="$emit('add-responsible', $event)">
            </select-user>
          </div>
        </div>

        <!-- Date -->
        <div class="py-1 px-2 d-inline-flex align-center">
          <span v-if="itemDueDate" :class="checklistItemClass" class="nobr hovervisible-activator-2" style="position: relative">
            <v-icon size="small" class="mb-1">far fa-calendar-alt</v-icon>
            {{ $filters.moment(itemDueDate, 'mediumDateNoYear') }}
            <i :title="`${$t('ELEMENT-DETAIL.REMOVE_DUE_DATE')} : ${$filters.moment(itemDueDate, 'date')}`"
               class="hovervisible-item-2 far fa-times fa-lg pointer text-errorred" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"
               @click="itemDueDate = null">
            </i>
          </span>
          <v-menu v-else v-model="itemDueDateMenuIsOpened" :close-on-content-click="false" location="bottom center" min-width="290px">
            <template #activator="{ props: onMenu }">
              <v-tooltip location="bottom">
                <template #activator="{ props: onTooltip }">
                  <v-icon size="small" :color="iconColor(false)" class="mb-.5 pointer" v-bind="mergeProps(onMenu, onTooltip)">far fa-calendar-plus</v-icon>
                </template>
                <span>{{ $t('ELEMENT-DETAIL.ADD_DUE_DATE') }}</span>
              </v-tooltip>
            </template>
            <v-date-picker v-model="itemDueDate" v-model:year="duedatePickerStart.year" v-model:month="duedatePickerStart.month" color="primary" no-title scrollable hide-header show-adjacent-months
                           @update:model-value="itemDueDateMenuIsOpened = false">
            </v-date-picker>
          </v-menu>
        </div>

        <!-- Workload -->
        <div class="d-inline-flex align-center small-input rounded py-1">
          <label :for="`itemWorkload${itemId}`" class="pl-2 pr-1 d-inline-flex align-center pointer">
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-icon size="small" :color="iconColor(itemWorkload)" class="mb-.5" v-bind="props">far fa-weight-hanging</v-icon>
              </template>
              <span class="pre">{{ workloadInHours ? $t('HR.WORKLOAD_HOURS_INFORMATION') : $t('HR.WORKLOAD_DAYS_INFORMATION') }}</span>
            </v-tooltip>
          </label>
          <div class="input-autosize-wrapper">
            <div class="input-autosize-invisible-text" style="min-width: 70px">{{ itemWorkload }}</div>
            <input :id="`itemWorkload${itemId}`" :value="itemWorkload"
                   :placeholder="$t('HR.WORKLOAD_ESTIMATED')" :title="$t('HR.WORKLOAD_ESTIMATED')"
                   type="number" step="any" min="0"
                   @change="itemWorkload = $event.target.value">
          </div>
          <v-divider vertical class="small-input-focus-visible"></v-divider>
          <label :for="`itemWorkloadUsed${itemId}`" class="pl-1 pr-1 d-inline-flex align-center pointer">
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-icon size="small" :color="iconColor(itemWorkloadUsed)" class="mb-.5" v-bind="props">far fa-stopwatch</v-icon>
              </template>
              <span class="pre">{{ workloadInHours ? $t('HR.WORKLOAD_HOURS_INFORMATION') : $t('HR.WORKLOAD_DAYS_INFORMATION') }}</span>
            </v-tooltip>
          </label>
          <div class="input-autosize-wrapper mr-1">
            <div class="input-autosize-invisible-text" style="min-width: 90px">{{ itemWorkloadUsed }}</div>
            <input :id="`itemWorkloadUsed${itemId}`" :value="itemWorkloadUsed"
                   :placeholder="$t('HR.WORKLOAD_USED')" :title="$t('HR.WORKLOAD_USED')"
                   type="number" step="any" min="0"
                   @change="itemWorkloadUsed = $event.target.value">
          </div>
        </div>

        <!-- Weight -->
        <div class="d-inline-flex align-center small-input rounded py-1">
          <label :for="`itemWeight${itemId}`" class="pl-2 pr-1 d-inline-flex align-center pointer">
            <v-tooltip location="bottom" max-width="150">
              <template #activator="{ props: iconProps }">
                <v-icon size="small" :color="iconColor(item.weight)" class="mb-.5" v-bind="iconProps">far fa-chart-pie</v-icon>
              </template>
              <span>{{ $t('ELEMENT-DETAIL.WEIGHTS') }}</span>
            </v-tooltip>
          </label>
          <v-tooltip location="right">
            <template #activator="{ props }">
              <div class="input-autosize-wrapper mr-1" v-bind="props">
                <div class="input-autosize-invisible-text" style="min-width: 50px">{{ item.weight || 1 }}</div>
                <input :id="`itemWeight${itemId}`" v-model.number="item.weight" placeholder="1" type="number"
                       step="any" min="0">
              </div>
            </template>
            <span>{{ itemWeight(item.weight) }}%</span>
          </v-tooltip>
        </div>

        <v-btn-icon v-if="! hasAdvancedContent" size="x-small" class="ml-auto mr-8" @click="itemOptionsIsOpened = false">
          <v-icon size="large">far fa-caret-up</v-icon>
        </v-btn-icon>
      </div>
    </v-expand-transition>
  </div>
</template>

<style lang="scss">
  .element-details-checklist-item:hover {
    background-color: rgba(var(--v-theme-secondary), .3);
    outline: rgba(var(--v-theme-secondary), .3) solid 4px;
  }

  .small-input {
    :placeholder-shown {
      text-overflow: ellipsis;
      font-size: .75em;
    }

    .input-autosize-wrapper {
      position: relative;
      height: 24px;

      .input-autosize-invisible-text {
        visibility: hidden;
        min-width: 0;
        transition: all .3s ease-in-out;
      }

      input, .v-input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &:not(:focus-within) {
      .input-autosize-wrapper {
        .input-autosize-invisible-text {
          min-width: 0 !important;
        }
      }
    }

    input {
      background: transparent;
      color: inherit;
      border: none;
      outline: none;
    }

    &:focus-within {
      outline: currentColor auto 1px;
    }

    &:not(:focus-within) {
      /* Hide number arrows when out of focus in : Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }

      .small-input-focus-visible {
        visibility: hidden;
      }
    }
  }
</style>

<script>
  import { mergeProps } from 'vue';
  import clientConfig from '@/client_customs/config';
  import workloadMixin from '@/components/Workload/workloadMixin';
  import UserWorkloadIndicator from '@/components/Workload/UserWorkloadIndicator.vue';

  export default {
    components: {
      UserWorkloadIndicator,
    },
    mixins: [workloadMixin],
    props: {
      el: { type: Object, required: true },
      checklist: { type: Array, default: () => [] },
      item: { type: Object, required: true },
      duedatePickerStart: { type: Object, default: () => ({ year: null, month: null }) },
    },
    emits: ['add-responsible', 'remove-checklist-item', 'update-checklist'],
    data() {
      return {
        itemOptionsIsOpened: this.checklist.length == 1, // opened by default when only one item
        showItemSelectUser: false,
        itemDueDateMenuIsOpened: false,
        hideChecklistAdvancedOptions: clientConfig.elements?.hideChecklistAdvancedOptions || false,
      };
    },
    computed: {
      hasAdvancedContent() {
        return this.item.user_id || this.item.group_id || this.item.username || this.item.duedate || this.item.workload || this.item.workload_used || this.item.weight;
      },
      locale() {
        return this.$store.state.lang.lang;
      },
      user() {
        const user = this.$store.getters['users/getUserById'](this.item.user_id);
        if (! user) return null;
        this.item.username = this.$store.getters['users/getUsername'](user, 'short'); // in case of username update
        return user;
      },
      group() {
        const group = this.$store.getters['users/groups/getGroupById'](this.item.group_id);
        if (! group) return null;
        this.item.username = group.title; // in case of title update
        return group;
      },
      checklistItemClass() {
        return this.el.getChecklistItemClass(this.item);
      },
      starttime() {
        return this.el.getStartTime();
      },
      endtime() {
        return this.itemDueDate || this.el.getEndTime();
      },
      itemDueDate: {
        get() {
          return this.item.duedate && moment(this.item.duedate);
        },
        set(newVal) {
          this.item.duedate = newVal ? newVal.format() : null;
        },
      },
      itemWorkload: {
        get() {
          return this.workloadToDisplay(this.item.workload);
        },
        set(newVal) {
          const val = typeof newVal == 'string' ? newVal.replace(',', '.') : newVal;
          this.item.workload = this.workloadToStore(parseFloat(val) || null);
        },
      },
      itemWorkloadUsed: {
        get() {
          return this.workloadToDisplay(this.item.workload_used);
        },
        set(newVal) {
          const val = typeof newVal == 'string' ? newVal.replace(',', '.') : newVal;
          this.item.workload_used = this.workloadToStore(parseFloat(val) || null);
        },
      },
      itemId() {
        return this.item.id;
      },
      workloadInHours() {
        return this.$store.getters['users/accessRight/config'].workloadInHours;
      },
      hasUserWorkloadIndicator() { return this.$store.state.users.accessRight.hasUserWorkloadIndicator; },
    },
    watch: {
      'item.checked': function () {
        this.$emit('update-checklist');
      },
      'item.weight': function () {
        this.$emit('update-checklist');
      },
    },
    methods: {
      itemWeight(weightParam) {
        const weight = weightParam === 0 ? 0 : (weightParam || 1);
        return Math.round(100 * weight / this.checklist.reduce((a, b) => a + (b.weight === 0 ? 0 : (b.weight || 1)), 0)) || 0;
      },
      iconColor(propVal) {
        return propVal ? null : 'medium-emphasis';
      },
      removeChecklistItem() {
        this.$emit('remove-checklist-item');
      },
      mergeProps,
    },
  };
</script>
