import PlanningLane from '@/models/PlanningLane';
import lanes from './lanes';
import timeline from './timeline';
import config from './config';
import meta from './meta';
import elements from './elements';
import process from './process';

export default {
  namespaced: true,
  modules: {
    lanes,
    timeline,
    config,
    meta,
    elements,
    process,
  },
  state: {
  },
  getters: {
    hasSubprojects(state) {
      return state.lanes && state.lanes.find(item => item.project_id) && true || false;
    },
  },
  actions: {
    set({ rootState, commit, dispatch }, { planning }) {
      rootState.planning = planning;
      dispatch('timeline/set', planning.timeline);
      dispatch('config/init', planning.config);
      commit('refresh', null, { root: true });
      setTimeout(() => { // init all calculations in a single batch on startup to avoid components individual recalcultation
        planning.elements.forEach((el) => {
          el.updateHeight();
        });
      });
    },
    save({ rootState, commit, dispatch }) {
      const { planning } = rootState;
      const isView = rootState.ui.planning.view;
      const isDynamic = rootState.ui.planning.dynamic;

      if (rootState.ui.planning.saving.inprogress) {
        rootState.ui.planning.saving.saveagain = true;
        return;
      }
      dispatch('ui/planning/startSaving', null, { root: true });

      if (isView) {
        setTimeout(async () => { // wait for new elements creation
          const { default: vueApp } = await import('@/js/vueApp');
          const viewId = vueApp?.config.globalProperties.$route.params.id;
          planning.saveView(viewId).then((hasSavedContent) => {
            dispatch('ui/planning/savingSuccess', null, { root: true });
            if (rootState.ui.planning.saving.saveagain) {
              setTimeout(() => dispatch('save'), 300);
              return;
            }
            // Send notification
            if (hasSavedContent) window.notificationsSrv.callEvent('projectSaved', { planning_id: planning.id, planning_title: planning.getTitle() });
          }).catch((message) => {
            dispatch('ui/planning/savingError', message, { root: true });
            if (rootState.ui.planning.saving.saveagain) {
              setTimeout(() => dispatch('save'), 300);
            } else {
              commit('eventbus/call', { event: 'update-project' }, { root: true }); // update project to resolve saving conflicts
            }
          });
        });
      } else if (isDynamic) {
        planning.save().then((contentPatch) => {
          dispatch('ui/planning/savingSuccess', null, { root: true });
          // Send notification
          if (contentPatch) window.notificationsSrv.callEvent('projectSaved', { contentPatch, planning_id: planning.id, planning_title: planning.getTitle() });
          if (rootState.ui.planning.saving.saveagain) setTimeout(() => dispatch('save'), 300);
        }).catch((message) => {
          dispatch('ui/planning/savingError', message, { root: true });
          if (rootState.ui.planning.saving.saveagain) {
            setTimeout(() => dispatch('save'), 300);
          } else {
            commit('eventbus/call', { event: 'update-project' }, { root: true }); // update project to resolve saving conflicts
          }
        });
      }
    },
    changeTimeline({ dispatch }, oldTimeline) {
      dispatch('elements/onChangeTimeline', oldTimeline);
      dispatch('subprojects/onChangeTimeline', null, { root: true });
    },
    deleteLanes({ dispatch }, deletedLanes) {
      return dispatch('elements/onDeleteLanes', deletedLanes);
    },
  },
  mutations: {

  },
};
