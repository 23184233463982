<template>
  <v-menu v-model="dropdownIsOpen" :close-on-content-click="false" location="bottom center" transition="slide-y-transition" offset="4">
    <template #activator="{ props: onMenu }">
      <v-tooltip :disabled="dropdownIsOpen" location="bottom">
        <template #activator="{ props: onTooltip }">
          <v-btn icon size="36" class="ml-2 mr-3 tour-profile" v-bind="mergeProps(onMenu, onTooltip)">
            <user-avatar :user="me" :size="36"></user-avatar>
          </v-btn>
        </template>
        <span>{{ $t('NAV.ACCOUNT') }}</span>
      </v-tooltip>

      <!--  MODALS -->
      <premium-window v-if="premiumWindowIsOpened" @close="closeModals"></premium-window>
    </template>
    <v-list class="py-0">
      <v-list-item lines="two">
        <template #prepend>
          <router-link to="/manage#profile" class="mr-3" @click="dropdownIsOpen = false">
            <user-avatar :user="me" :title="$t('ACCOUNT.CHANGE_AVATAR')" :size="48"></user-avatar>
          </router-link>
        </template>
        <div style="position: relative">
          <div class="font-weight-bold">{{ me.firstname || '' }} {{ me.lastname || '' }}</div>
          <div :title="me.email" class="text-medium-emphasis small text-ellipsis" style="position: absolute; width: 100%">{{ me.email }}</div>
          <div class="small">&nbsp;</div> <!-- email placeholder -->
        </div>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item :title="$t('NAV.MANAGE')" to="/manage" :active="false" lines="two" @click="dropdownIsOpen = false">
        <template #subtitle>
          <span v-if="isTrial" class="text-uppercase">{{ $t('ACCOUNT.FREE_TRIAL') }}</span>
          <span v-else-if="isPremium">{{ me.company && me.company.name }}</span>
          <span v-else>FREE</span>
        </template>
        <template #append>
          <v-btn icon :title="$t('PREMIUM.UPDATE_SUBSCRIPTION')" size="small" class="ml-1" @click.prevent="openPremium()">
            <v-icon size="large" color="accent">fas fa-cart-plus</v-icon>
          </v-btn>
        </template>
      </v-list-item>
      <v-divider v-if="! isPremium"></v-divider>
      <v-list-item v-if="! isPremium" @click="openPremium()">
        <b>{{ $filters.uppercase($t('ACCOUNT.UPGRADE')) }}</b>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item lines="two">
        <template #prepend>
          <span class="nobr mr-2">{{ $t('ACCOUNT.LANGUAGE') }} :</span>
        </template>
        <v-select v-model="lang" :items="[{ value: 'en', title: 'English' }, { value: 'fr', title: 'Français' }]"
                  density="compact">
        </v-select>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item @click="dropdownIsOpen = false; toggleFullScreenMode()">
        <i class="far fa-expand mr-2"></i>{{ $t('NAV.FULLSCREEN') }}
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item href="./meeting" target="_blank" rel="noopener" class="text-meetingprimary">
        <i class="far fa-external-link mr-2"></i><span class="nobr">{{ $t('MEETING.BUBBLE_MEETING') }}</span>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item @click="dropdownIsOpen = false; logout()">
        <i class="far fa-sign-out-alt mr-2"></i>{{ $t('ACCOUNT.LOG_OUT') }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { mergeProps } from 'vue';
  import clientConfig from '@/client_customs/config';
  import PremiumWindow from '@/components/Premium/PremiumWindow.vue';

  export default {
    components: {
      PremiumWindow,
    },
    data() {
      return {
        dropdownIsOpen: null,
      };
    },
    computed: {
      lang: {
        get() {
          return this.$store.state.lang.lang;
        },
        set(newLang) {
          this.$store.dispatch('lang/loadLang', newLang);
        },
      },
      me() {
        return this.$store.state.users.user;
      },
      premiumWindowIsOpened() {
        return this.$store.state.ui.navigation.openedModal == 'premium-window';
      },
      displayIntegrations() {
        const { availableIntegrations } = this.$store.getters['users/accessRight/config'];
        return ! (availableIntegrations && ! availableIntegrations.length);
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      isTrial() { return this.$store.state.users.accessRight.isTrial; },
    },
    methods: {
      openPremium() {
        this.$store.commit('ui/navigation/setOpenedModal', 'premium-window');
      },
      closeModals() {
        this.$store.commit('ui/navigation/setOpenedModal', null);
      },
      toggleFullScreenMode() {
        this.$store.state.ui.fullscreenMode = ! this.$store.state.ui.fullscreenMode;
      },
      logout() {
        this.$store.commit('ui/navigation/setOpenedModal', 'logout');
        window.apiSrv.logout().then(() => {
          this.$store.commit('users/reset');
          if (clientConfig.sso) {
            this.$store.commit('ui/navigation/setOpenedModal', 'logoutDone');
          } else {
            window.location.href = "";
          }
        }).catch(() => {
          this.closeModals();
          this.$store.commit('users/reset');
          window.location.href = "";
        });
      },
      mergeProps,
    },
  };
</script>
