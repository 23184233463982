<template>
  <div v-if="label" class="mb-1">{{ label }}</div>
  <v-btn-toggle divided variant="outlined" density="comfortable" class="btn-toggle-field-content" v-bind="$attrs">
    <v-btn v-for="item in items" :key="item.value" :value="item.value" size="large" :active="false" :disabled="!! item.disabled"
           :class="modelValue == item.value ? 'bg-secondary' : ''"
           @click="$emit('update:model-value', item.value)">
      <v-icon v-if="item.icon" class="mr-2">{{ item.icon }}</v-icon>
      {{ item.title }}
    </v-btn>
  </v-btn-toggle>
</template>

<style>
  .btn-toggle-field-content .v-icon {
    opacity: var(--v-medium-emphasis-opacity);
  }
</style>

<script>
  export default {
    props: {
      modelValue: { type: [String, Number], default: '' },
      items: { type: Array, required: true },
      label: { type: String, default: '' },
    },
    emits: ['update:model-value'],
  };
</script>
