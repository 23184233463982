<template>
  <div>
    <planning-navigation v-if="(dynamic && ! view) || userConnected" :dynamic="dynamic" :view="view"
                         :planning="planning" :planning-view-id="planningViewId">
    </planning-navigation>

    <v-fade-transition hide-on-leave>
      <project-report v-if="projectReportIsOpened" :planning="planning" :dynamic="dynamic" @close="closeProjectReport"></project-report>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <div v-show="! projectReportIsOpened" :id="(dynamic && ! view) ? 'planning' : 'viewer'">
        <planning-content :planning="planning" :dynamic="dynamic" :view="view" :options="{ elementDetails: { canSetPriority: true, canChangeLane: true } }"></planning-content>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { mapStores } from 'pinia';
  import useNavigationProjectsHistoryStore from '@/stores/navigation-projects-history';
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import Alert from '@/models/Alert';
  import Planning from '@/models/Planning';
  import ProjectReport from '@/components/ProjectReport/ProjectReport.vue';
  import PlanningNavigation from './PlanningNavigation.vue';
  import PlanningContent from './PlanningContent.vue';

  export default {
    components: {
      ProjectReport,
      PlanningNavigation,
      PlanningContent,
    },
    props: {
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
    },
    data() {
      return {
        planning: {},
        planningId: this.view ? null : +this.$route.params.id,
        planningViewId: this.view ? +this.$route.params.id : null,
        tours: [],
        scrollValues: { planning: null, kanban: null },
      };
    },
    computed: {
      userConnected() {
        return this.$store.state.users.user.id > 0;
      },
      planningTitle() {
        return this.planning.getTitle?.();
      },
      isKanbanMode() {
        return this.$store.state.ui.planning.mode == 'kanban';
      },
      planningAndKanbanDisplayState() {
        const planningOrKanban = !! this.planning.visibleTimeline && ! this.projectReportIsOpened;
        return { planning: planningOrKanban && ! this.isKanbanMode, kanban: planningOrKanban && this.isKanbanMode };
      },
      ...mapState('ui/planning', ['projectReportIsOpened']),
      ...mapStores(useNavigationProjectsHistoryStore),
    },
    watch: {
      planningTitle: _.debounce(function (newVal) {
        if (typeof newVal != 'string') return;
        // register navigation event
        this.NavigationProjectsHistoryStore.register({ path: this.$route.path, rotoken: this.$route.query.rotoken, title: newVal, imgurl: this.planning.meta?.imgurl });
      }, 1000),
      planningAndKanbanDisplayState(newVals, oldVals) { // watch for display change to (re)store scroll value
        Object.keys(newVals).forEach((key) => {
          if (newVals[key] == oldVals[key]) return;
          if (! newVals[key]) { // hidding planning / kanban : store scroll value
            const tableWrapper = document.getElementById('table-wrapper');
            if (tableWrapper) this.scrollValues[key] = tableWrapper.scrollLeft;
          } else {
            setTimeout(() => {
              const tableWrapper = document.getElementById('table-wrapper');
              if (! tableWrapper) return;
              if (key == 'planning' && this.scrollValues[key] === null) {
                tableWrapper.scrollLeft = this.planning.visibleTimeline.presenttimeWidth - 0.25 * tableWrapper.clientWidth; // init scroll to current time
              } else {
                tableWrapper.scrollLeft = this.scrollValues[key] || 0; // restore scroll
              }
            });
          }
        });
      },
    },
    created() {
      this.loadProject();
      this.$store.commit('eventbus/register', { event: 'update-project', listener: 'planning', cb: this.updateProject });
    },
    beforeUnmount() {
      window.notificationsSrv.stopCheckingPlanningUpdates();
      this.$store.commit('eventbus/remove', { event: 'update-project', listener: 'planning' });
      this.tours.forEach((tour) => { if (tour) (tour.cancel || tour.stop)(); });
    },
    methods: {
      loadProject() {
        let request;
        if (this.view) {
          request = ProjectSrv.getView({ viewId: this.planningViewId, viewrotoken: this.$route.query.viewrotoken });
        } else {
          request = ProjectSrv.get(this.planningId, this.$route.query.rotoken);
        }
        request.then((content) => {
          this.planning = new Planning(content);
          if (! this.view && this.dynamic) {
            if (this.planning.meta && this.planning.meta.access_right == 'consult') {
              this.planning = {};
              const prettyname = this.$route.params?.prettyname;
              this.$router.replace({ path: `/viewer/${this.planningId}${prettyname ? `/${prettyname}` : ''}`, query: this.$route.query });
              return;
            }
          }
          this.$store.commit('ui/planning/loadPlanningMode', { planningId: this.planning.id });
          // broadcast event
          this.onProjectLoaded();
          this.onProjectFirstLoad();
        }).catch((message) => {
          this.$store.state.ui.planning.errors = `${message || "Something went wrong."}`;
          window.status = "screenshotready";
        });
      },
      updateProject() {
        let request;
        if (this.view) {
          request = ProjectSrv.getView({ viewId: this.planningViewId, viewrotoken: this.$route.query.viewrotoken });
        } else {
          request = ProjectSrv.get(this.planningId, this.$route.query.rotoken);
        }
        if (this.dynamic) this.$store.dispatch('ui/planning/startSaving');
        request.then((content) => {
          const modifications = this.planning.update(content);
          this.planning.elements.filter(el => modifications.elements?.[el.id]).forEach((el) => { // add last modifier indication
            el.modificationUser = this.planning.meta.updated_by;
          });
          this.onProjectLoaded(); // this.$store.dispatch('planning/timeline/set', this.planning.timeline); might be enough
          this.$store.commit('eventbus/call', { event: 'project-updated', param: modifications });
        }).catch(() => {}).finally(() => {
          if (this.dynamic) this.$store.dispatch('ui/planning/resetSaving');
        });
      },
      debouncedUpdateProject: _.debounce(function () { this.updateProject(); }, 30000),
      setScreenshotParams() {
        const urlparams = this.$route.query;
        if (! urlparams.snapshot) return;

        this.$store.state.ui.planning.snapshot = true;
        if (urlparams.mode == 'kanban') {
          this.$store.state.ui.planning.mode = 'kanban';
          // process steps
          if (urlparams.stepshidden) {
            urlparams.stepshidden.split(',').forEach((stepId) => {
              if (stepId == 'backlog') stepId = null;
              const index = (this.$store.state.planning.process.steps || []).findIndex(item => item.id === stepId);
              if (index > -1) this.$store.state.planning.process.steps.splice(index, 1);
            });
          }
        } else {
          // timeline
          const timeline = angular.copy(this.$store.state.planning.timeline);
          timeline.show_presenttime = false;
          if (urlparams.steps) timeline.steps = urlparams.steps.split(',');
          if (urlparams.hiddenbefore && moment(urlparams.hiddenbefore).isValid()) timeline.setHiddenBefore(urlparams.hiddenbefore);
          if (urlparams.hiddenafter && moment(urlparams.hiddenafter).isValid()) timeline.setHiddenAfter(urlparams.hiddenafter);
          if (urlparams.colwidth) timeline.colwidth = urlparams.colwidth;
          this.$store.dispatch('planning/timeline/set', timeline);
        }
        // lanes
        if (urlparams.laneshidden) {
          urlparams.laneshidden.split(',').forEach((laneId) => {
            const index = (this.$store.state.planning.lanes || []).findIndex(item => item.id === +laneId);
            if (index > -1) this.$store.state.planning.lanes.splice(index, 1);
          });
        }
      },
      onProjectFirstLoad() {
        // Check update notificatons
        window.notificationsSrv.checkPlanningUpdates({ planningId: this.planning.id }, (contentPatch) => {
          if (contentPatch?.length) {
            try {
              const modifications = this.planning.applyContentPatch(contentPatch);
              this.planning.elements.filter(el => modifications.elements[el.id]).forEach((el) => { // add last modifier indication
                el.modificationUser = this.planning.meta.updated_by;
              });
              this.$store.dispatch('planning/timeline/set', this.planning.timeline);
              this.$store.commit('eventbus/call', { event: 'project-updated', param: modifications });

              this.debouncedUpdateProject(); // reload full planning after 30s in case of sync errors
            } catch (e) {
              // applyContentPatch failed
              console.error('CATCHED ERROR', e);
              this.updateProject();
            }
          } else {
            this.updateProject();
          }
        });

        // Alerts
        setTimeout(() => {
          Alert.load(this.$store.state.planning);
        });

        // Recurrences
        if (! this.view && this.dynamic) {
          setTimeout(() => {
            this.$store.state.users.userPromise.then(() => {
              if (! this.$store.state.users.accessRight.isBusiness) return;
              this.$store.dispatch('planning/config/updatePlanningRecurrences').then((needToSave) => {
                if (needToSave) this.$store.dispatch('planning/save');
              });
            });
          }, 3000); // not needed immediately, free some resources at start
        }

        // Screenshot mode
        if (this.$route.query.snapshot) {
          setTimeout(() => {
            Promise.all(this.$store.state.subprojects.promises).then(() => {
              this.setScreenshotParams();
              setTimeout(() => { window.status = "screenshotready"; }, 300);
            });
          });
        } else if (this.$route.query.mode == 'kanban') { // share link param
          this.$store.state.ui.planning.mode = 'kanban';
        }

        /*  GUIDED TOUR */
        if (this.dynamic && this.$route.query.tour) {
          this.$nextTick(() => {
            import('@/components/Tour/TourSrv').then((TourSrv) => {
              if (this.$route.query.tour == 'onboarding') {
                this.$router.replace({ query: {} });
                setTimeout(() => {
                  if (this._isDestroyed) return;
                  const tour = TourSrv.launchAddBubble();
                  if (tour) this.tours.push(tour);
                }, 5000);
              } else {
                if (this._isDestroyed) return;
                const tour = TourSrv.launch(this.$router, this.$route.query.tour);
                if (tour) this.tours.push(tour);
              }
            });
          });
        }

        /* Scroll to element */
        if (this.$route.query.el || this.$route.query.scrolltoel) {
          const elId = this.$route.query.el || this.$route.query.scrolltoel;
          const el = this.planning.elements.find(item => item.id == elId);
          const openElement = this.dynamic && !! this.$route.query.el; // save in a var before route is replaced
          if (el) setTimeout(() => { this.$store.dispatch('ui/planning/scrollToEl', { el, open: openElement }); });
          this.$router.replace({ query: { ...this.$route.query, el: undefined, scrolltoel: undefined } });
        }
      },
      onProjectLoaded() {
        this.$store.dispatch('undoredo/reset');
        setTimeout(() => {
          this.$store.dispatch('planning/set', { planning: this.planning });
          this.planning = this.$store.state.planning;

          if (! this.$store.state.users.user.id) { // user (and business config) not loaded yet
            this.$store.state.users.userPromise.then(() => {
              if (this.$store.state.users.accessRight.isBusiness) {
                this.$store.dispatch('planning/set', { planning: this.planning }); // reload with business config
                this.planning = this.$store.state.planning;
              }
            });
          }
        });
      },
      ...mapActions('ui/planning', ['closeProjectReport']),
    },
  };
</script>
