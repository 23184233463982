export default {
  namespaced: true,
  modules: {},
  state: {
    gcal: {
      loading: null,
      connected: null,
      config: { sync_miletones: null, sync_tasks: null },
      elements: null,
      url: null,
      last_refresh: null,
    },
  },
  getters: {
    getElementStatus: state => ((el) => {
      const { gcal } = state;
      if (! gcal.connected) return null;
      const planning = el.getPlanning();
      if (! planning) return null;
      const elementConfig = gcal.elements.find(item => item.element_id == el.id && item.planning_id == planning.id);
      if (elementConfig && elementConfig.sync) {
        return elementConfig.sync == 1;
      }
      if (! el.getUsers() || ! el.getUsers().find(item => item.id == window.app.config.globalProperties.$store.state.users.user.id)) return false;
      return gcal.config[el.isType('task') ? 'sync_tasks' : 'sync_milestones'] || false;
    }),
  },
  actions: {
    refreshConnection({ state }) {
      const { gcal } = state;
      if (gcal.connected && moment().diff(gcal.last_refresh, 'minutes') < 2) return Promise.resolve(gcal);
      gcal.loading = true;
      return window.apiSrv.call('integrations/google', undefined, undefined, true).then((response) => {
        gcal.connected = response && response.data && response.data.connected || false;
        _.extend(gcal.config, response && response.data && response.data.config || {});
        gcal.elements = response && response.data && response.data.elements || [];
        gcal.url = response && response.data && response.data.url || "";
        gcal.last_refresh = moment();
        return gcal;
      }).catch((message) => {
        gcal.connected = false;
        return Promise.reject(message);
      }).finally(() => {
        gcal.loading = false;
      });
    },

    setElementStatus({ state }, { el, sync }) {
      const { gcal } = state;
      const planning = el.getPlanning();
      if (! planning) return Promise.reject(new Error("missing planning"));
      gcal.loading = true;
      return window.apiSrv.call('integrations/google/elements', 'store', { planning_id: planning.id, element_id: el.id, sync }).then((response) => {
        const elementConfigIndex = gcal.elements.findIndex(item => item.element_id == el.id && item.planning_id == planning.id);
        if (elementConfigIndex > -1) gcal.elements.splice(elementConfigIndex, 1);
        if (response && response.data && response.data.element) gcal.elements.push(response.data.element);
      }).finally(() => {
        gcal.loading = false;
      });
    },

    setConfig({ state }, data) {
      const { gcal } = state;
      gcal.loading = true;
      return window.apiSrv.call('integrations/google/config', 'store', data).then((response) => {
        if (response && response.data && response.data.config) gcal.config = response.data.config;
      }).finally(() => {
        gcal.loading = false;
      });
    },

    postEvent(context, { el, elId }) { // passing original elId for dashboard els
      const planning = el.getPlanning();
      const element = { ...el.getAll(), id: elId || el.id };
      return window.apiSrv.call('integrations/google/events', 'store', { element, planning: { id: planning.id, title: planning.getTitle(), url: planning.getUrl() } });
    },

    logout({ state }) {
      const { gcal } = state;
      gcal.loading = true;
      return window.apiSrv.call('integrations/google/logout').then(() => {
        gcal.connected = false;
      }).finally(() => {
        gcal.loading = false;
      });
    },
  },
  mutations: {

  },
};
