export default {
  namespaced: true,
  state: {
    openedModal: null,
  },
  mutations: {
    setOpenedModal(state, val) {
      state.openedModal = val;
    },
  },
};
