<template>
  <div v-if="invites.length">
    <b>{{ $t('MANAGE.INVITES.COMPANY_INVITES') }}</b>
    <v-card class="mt-2 pa-6">
      <b class="text-errorred">{{ $t('MANAGE.INVITES.WARNING') }}</b>
      <div v-if="companyUsersCount > 1" class="py-2 d-flex align-center text-errorred">
        <v-icon color="errorred" class="mr-2">far fa-exclamation-triangle</v-icon> {{ $t('MANAGE.INVITES.WARNING2') }}
      </div>
      <div v-for="invite in invites" class="my-2">
        {{ invite.inviter_name }} {{ $t('MANAGE.INVITES.INVITES_YOU_TO_JOIN') }} « {{ invite.company_name }} »
        <div class="mt-2">
          <v-btn :loading="isLoading" color="primary" rounded @click="joinCompany(invite.company_id)">{{ $t('MANAGE.INVITES.ACCEPT_INVITE') }}</v-btn>
          <v-btn :loading="isLoading" color="errorred" rounded class="ml-4 px-6" @click="rejectInvite(invite)">{{ $t('MANAGE.INVITES.REJECT_INVITE') }}</v-btn>
        </div>
        <div class="py-2"></div>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    props: {
      companyUsersCount: { type: Number, default: 1 },
    },
    data() {
      return {
        invites: [],
        isLoading: false,
      };
    },
    created() {
      window.apiSrv.call('companyinvites', 'index').then((response) => {
        this.invites = response.data && response.data.invites || [];
      });
    },
    methods: {
      joinCompany(id) {
        this.isLoading = true;
        window.apiSrv.call(`companyinvites/accept/${id}`).then(() => {
          window.location.reload();
        }).catch(() => {
          this.isLoading = false;
        });
      },
      rejectInvite(invite) {
        this.isLoading = true;
        window.apiSrv.call('companyinvites', 'destroy', invite.id).then(() => {
          const index = this.invites.indexOf(invite);
          if (index > -1) this.invites.splice(index, 1);
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Invitation was not deleted", body: message || "" });
        }).finally(() => {
          this.isLoading = false;
        });
      },
    },
  };
</script>
