<template>
  <div>
    <div class="d-flex align-center mb-2">
      <v-icon class="mr-2" style="flex: 0 0 24px">far fa-paperclip</v-icon>
      <b>{{ $t('PROJECT_SHEET.PROJECT_SHEET_FILES') }}</b>
    </div>
    <v-card class="pa-6">
      <div>
        <ul v-if="files.length" class="list-unstyled mb-4">
          <li v-for="file in files" class="hovervisible-activator d-flex align-center">
            <span class="text-ellipsis">
              <link-icon :link="file" class="mr-2"></link-icon>
              <a :href="subdomainLinkUrl(file.url)" :title="file.name" target="_blank" rel="noopener" @click.stop>
                {{ file.name || file.display_filename }}
              </a>

              <v-menu v-if="isPicture(file)" content-class="ml-2 rounded-sm" open-on-hover location="right" offset="8" transition="scale-transition" activator="parent">
                <div class="bg-surface" style="max-width: 400px">
                  <img v-if="file.url" :src="file.url" :alt="$t('PLANNING.LINK_IMAGE_UNAVAILABLE')" style="width: 100%;">
                </div>
              </v-menu>
            </span>

            <template v-if="userIsProjectAdmin">
              <v-btn-icon size="small" class="ml-auto hovervisible-item" @click="editLinkName(file)">
                <v-icon>far fa-pencil-alt</v-icon>
                <v-tooltip location="bottom" activator="parent">{{ $t('GLOBAL.RENAME') }}</v-tooltip>
              </v-btn-icon>
              <v-btn-icon size="small" color="errorred" class="ml-1 hovervisible-item" @click="deleteFile(file)">
                <v-icon>far fa-trash-alt</v-icon>
                <v-tooltip location="bottom" activator="parent">{{ $t('GLOBAL.DELETE') }}</v-tooltip>
              </v-btn-icon>
            </template>
          </li>
        </ul>
        <file-upload v-if="userIsProjectAdmin" ref="fileUpload" input-id="file-project-sheet" class="export-hidden" @uploaded="fileUploaded"></file-upload>
        <!-- ADD LINK FORM-->
        <form v-if="userIsProjectAdmin" class="export-hidden" @submit.prevent="addLink()">
          <field-label :label="$t('ELEMENT-DETAIL.ADD_LINK')">
            <template #default="{ id }">
              <div class="d-flex align-center">
                <v-text-field v-model="linkurl" :id="id" placeholder="https://..." style="max-width: 300px"></v-text-field>
                <v-btn :disabled="! linkurl" color="primary" rounded type="submit" class="ml-2">{{ $t('ELEMENT-DETAIL.ADD') }}</v-btn>
              </div>
            </template>
          </field-label>
        </form>
      </div>
    </v-card>
  </div>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import FileUpload from '@/components/Reusables/FileUpload.vue';
  import LinkIcon from '@/components/Planning/partials/LinkIcon.vue';

  function deepcopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  export default {
    components: {
      FileUpload,
      LinkIcon,
    },
    props: {
      planning: { type: Object, required: true },
    },
    emits: ['update'],
    data() {
      const projectsheet = this.planning.projectsheet || {};
      const userIsProjectAdmin = this.planning.meta.access_right == 'admin';

      return {
        userIsProjectAdmin,
        files: deepcopy(projectsheet.files || []),
        linkurl: "",
      };
    },
    methods: {
      isPicture(link) {
        if (link.mime && link.mime.includes('image')) return true;
        return /(http)s?:(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg)$)/i.test(link.url);
      },
      subdomainLinkUrl(url) {
        return clientConfig.subdomain && url?.startsWith('https://api.bubbleplan.net') ? `${url}?subdomain=${clientConfig.subdomain}` : url;
      },
      fileUploaded(newFile) {
        if (! (newFile && newFile.response && newFile.response.file)) return;
        this.files.push(newFile.response.file);
        this.$emit('update', {
          files: deepcopy(this.files),
        });
      },
      addLink() {
        if (! this.linkurl) return;
        let name = this.linkurl.replace(/\/+$/, ''); // strip ending slashes
        name = name.substr(name.lastIndexOf('/') + 1);
        this.files.push({ type: 'link', url: this.linkurl, name });
        this.linkurl = "";
        this.$emit('update', {
          files: deepcopy(this.files),
        });
      },
      editLinkName(link) {
        const newName = window.prompt(this.$t('GLOBAL.RENAME'), link.name || link.display_filename);
        if (! newName) return;
        link.name = newName;
        this.$emit('update', {
          files: deepcopy(this.files),
        });
      },
      deleteFile(file) {
        const index = this.files.indexOf(file);
        if (index == -1) return;
        this.files.splice(index, 1);
        this.$emit('update', {
          files: deepcopy(this.files),
        });
      },
    },
  };
</script>
