<template>
  <v-app-bar v-if="! mobileview" id="topbar" order="0" density="compact" flat color="background" :extended="extended" :extension-height="64" class="toolbar-scrollable px-4 pb-4" style="z-index: 1000">
    <!-- pb-4 ensures margin with content on page scrolling -->
    <router-link to="/home" class="ml-3 d-flex">
      <img :title="$t('HOME.PERSONAL_DASHBOARD')" src="@/img/bubble-plan-logo-color.png" class="mr-4" style="height: 40px;">
    </router-link>

    <!--    SECTIONS NAVIGATION-->
    <v-toolbar-items class="mr-2" variant="text">
      <v-btn :class="projectsHistoryIsVisible ? 'pr-1' : 'pr-2'" to="/projects" class="pl-2 text-uppercase tour-projects">{{ $t('NAV.PROJECTS') }}</v-btn>
      <navigation-projects-history @is-visible="projectsHistoryIsVisible = $event"></navigation-projects-history>

      <v-btn :class="isPremium ? 'pr-1' : 'pr-2'" to="/dashboards" class="ml-3 pl-2 text-uppercase tour-dashboards">{{ $t('HOME.DASHBOARDS') }}</v-btn>
      <navigation-dashboards-menu v-if="isPremium"></navigation-dashboards-menu>
      <!-- <v-btn v-if="accessRight.hasMeeting" href="./meeting" text color="meetingprimary" active-class="" class="px-2 text-uppercase" style="min-width: 0">
        <span class="hidden-md-and-down">{{ $t('MEETING.BUBBLE_MEETING') }}</span>
        <span class="hidden-lg-and-up">MEETING</span>
      </v-btn> -->

      <!-- Herblay ticketing -->
      <v-btn v-if="accessRight.hasHerblayTicketing" to="/dashboards/herblay/eventform/admin" class="ml-3 px-2 text-uppercase">Ticketing</v-btn>
      <!-- Locabri import -->
      <v-btn v-if="accessRight.hasLocabriImport" to="/dashboards/locabri/import" class="ml-3 px-2 text-uppercase">Import</v-btn>
    </v-toolbar-items>

    <!-- SLOT LEFT (DEFAULT) -->
    <slot></slot>
    <v-spacer></v-spacer>

    <!-- SLOT RIGHT -->
    <slot name="right"></slot>

    <!-- BECOME PREMIUM -->
    <navigation-become-premium></navigation-become-premium>

    <!-- BUTTONS : HELP, NOTIFICATIONS, MANAGE, ACCOUNT-->
    <navigation-help :config="config"></navigation-help>
    <navigation-notifications :planning="planning"></navigation-notifications>
    <navigation-manage></navigation-manage>
    <navigation-account></navigation-account>

    <template v-if="extended" #extension>
      <slot name="extension"></slot>
    </template>

    <!--  LOGOUT SPLASH SCREEN -->
    <modal v-if="logoutIsOpened" :closable="false" :backdrop-opacity="logoutDone ? 1 : .8">
      <div style="position: fixed; left: 0; right: 0; z-index: 99999999;">
        <section class="text-center text-white" style="height: 100vh;">
          <div v-if="! logoutDone" class="pt-12">
            <h3>{{ $t('ACCOUNT.LOGGING_OUT') }}</h3><div class="pa-4"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
          </div>
          <div v-else class="pt-12"><h3>{{ $t('ACCOUNT.DISCONNECTED') }}</h3></div>
        </section>
      </div>
    </modal>
  </v-app-bar>
</template>

<style>
  #topbar .v-btn--variant-text.v-btn--active {
    --v-activated-opacity: 0;
    border-bottom: 2px solid #0169C1;
  }
</style>

<script>
  import NavigationProjectsHistory from './NavigationProjectsHistory.vue';
  import NavigationDashboardsMenu from './NavigationDashboardsMenu.vue';
  import NavigationBecomePremium from './NavigationBecomePremium.vue';
  import NavigationHelp from './NavigationHelp.vue';
  import NavigationNotifications from './NavigationNotifications.vue';
  import NavigationManage from './NavigationManage.vue';
  import NavigationAccount from './NavigationAccount.vue';

  export default {
    components: {
      NavigationProjectsHistory,
      NavigationDashboardsMenu,
      NavigationBecomePremium,
      NavigationHelp,
      NavigationNotifications,
      NavigationManage,
      NavigationAccount,
    },
    props: {
      config: { type: String, default: '' }, // planning, projects, manage
      section: { type: String, default: '' }, // homepage section
      extended: { type: Boolean, default: false },
      planning: { type: Object, default: null }, // for notifications in planning page
    },
    data() {
      return {
        projectsHistoryIsVisible: null,
      };
    },
    computed: {
      accessRight() {
        return this.$store.state.users.accessRight;
      },
      isPremium() {
        return this.accessRight.isPremium;
      },
      mobileview() {
        return this.$store.state.ui.mobileview;
      },
      logoutIsOpened() {
        return ['logout', 'logoutDone'].includes(this.$store.state.ui.navigation.openedModal);
      },
      logoutDone() {
        return this.$store.state.ui.navigation.openedModal == 'logoutDone';
      },
    },
  };
</script>
