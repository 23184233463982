<template>
  <div>
    <div class="d-flex align-center mb-2">
      <v-icon class="mr-2" style="flex: 0 0 24px">far fa-flag</v-icon>
      <b>{{ $t('PROJECT_SHEET.WAYPOINTS') }}</b>
      <template v-if="userIsProjectAdmin">
        <v-btn-icon size="small" class="ml-auto export-hidden" @click="addStep">
          <v-icon size="large">far fa-plus</v-icon>
        </v-btn-icon>
      </template>
    </div>
    <v-card class="pa-6">
      <div v-if="currentStep">
        <div v-if="stepEditing">
          <div class="d-flex align-center">
            <button type="button" class="export-hidden" :style="hasPrevStep ? {} : { color: 'gray', 'pointer-events': 'none' }" @click="toNextStep(-1)">
              <i class="fa fa-2x fa-caret-left"></i>
            </button>
            <div class="mx-4 pb-1 flex-grow-1" style="max-width: 200px;">
              <date-field v-model="currentStepDate" no-null></date-field>
            </div>
            <button type="button" class="export-hidden" :style="hasNextStep ? {} : { color: 'gray', 'pointer-events': 'none' }" @click="toNextStep()">
              <i class="fa fa-2x fa-caret-right"></i>
            </button>
            <v-spacer></v-spacer>
            <template v-if="userIsProjectAdmin">
              <v-divider vertical class="mx-2"></v-divider>
              <v-btn icon size="small" @click="deleteStep">
                <v-icon color="errorred">far fa-trash-alt</v-icon>
              </v-btn>
            </template>
          </div>
          <div class="mt-4">
            <div v-for="moodicon in ['sun', 'suncloud', 'cloud', 'storm']" :key="moodicon" :style="currentStep.mood == moodicon ? { border: '3px solid green', padding: '6px' } : null"
                 class="pointer vcenter moodicon-wrapper" @click="currentStep.mood == moodicon ? currentStep.mood = null : currentStep.mood = moodicon">
              <div :class="`mood-icon-${moodicon}`" :title="$t(`MOOD.${$filters.uppercase(moodicon)}`)"></div>
            </div>
          </div>
          <div class="mt-4">
            <quill v-model:content="currentStep.waypoint" :default-content="defaultStepWaypoint" :config="{ height: '150px' }"></quill>
          </div>
          <div v-if="stepsExtraFields" class="mt-2" style="display: flex; flex-wrap: wrap; margin: 8px -8px 0">
            <div v-for="field in stepsExtraFields" :key="field.name" :style="{ width: `${(field.width || 1) * 100}%` }" style="padding: 0 8px; flex-grow: 1;">
              <i :class="field.icon"></i>&nbsp;
              <b>{{ field.title }}</b>
              <div class="title-underline"></div>
              <quill v-model:content="currentStep[field.name]" :config="{ height: '150px' }"></quill>
            </div>
          </div>
          <div class="text-center">
            <v-btn rounded variant="outlined" class="mx-2" @click="resetSteps">{{ $t('GLOBAL.CANCEL') }}</v-btn>
            <v-btn color="primary" rounded class="mx-2" @click="saveSteps">{{ $t('GLOBAL.SAVE') }}</v-btn>
          </div>
        </div>
        <div v-else>
          <div class="d-flex align-center">
            <button type="button" class="export-hidden" :style="hasPrevStep ? null : { color: 'gray', 'pointer-events': 'none' }" @click="toNextStep(-1)">
              <i class="fa fa-2x fa-caret-left"></i>
            </button>
            <span class="mx-4">{{ $filters.moment(currentStep.date, 'mediumDate') }}</span>
            <button type="button" class="export-hidden" :style="hasNextStep ? null : { color: 'gray', 'pointer-events': 'none' }" @click="toNextStep()">
              <i class="fa fa-2x fa-caret-right"></i>
            </button>
            <template v-if="userIsProjectAdmin">
              <v-btn-icon size="small" class="ml-auto export-hidden" @click="stepEditing = ! stepEditing">
                <v-icon>far fa-pencil-alt</v-icon>
              </v-btn-icon>
            </template>
          </div>
          <div class="d-flex">
            <div v-if="currentStep.mood" class="moodicon-wrapper" style="margin-right: 16px; flex-shrink: 0;">
              <div :class="`mood-icon-${currentStep.mood}`" :title="$t(`MOOD.${$filters.uppercase(currentStep.mood)}`)"></div>
            </div>
            <div class="quill-html" v-html="currentStep.waypoint"></div>
          </div>
          <div v-if="filteredStepsExtraFields" style="display: flex; flex-wrap: wrap; margin: 8px -8px 0">
            <div v-for="field in filteredStepsExtraFields" :key="field.name"
                 :style="{ width: `${(field.width || 1) * 100}%` }" class="mb-1 px-2" style="flex-grow: 1;">
              <i v-if="field.icon" :class="field.icon" class="mr-2"></i>
              <b>{{ field.title }}</b>
              <div class="title-underline"></div>
              <div class="quill-html" v-html="currentStep[field.name]"></div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import Quill from '@/components/Reusables/Quill.vue';

  function deepcopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  export default {
    components: {
      Quill,
    },
    props: {
      planning: { type: Object, required: true },
    },
    emits: ['update'],
    data() {
      const projectsheet = this.planning.projectsheet || {};
      const steps = deepcopy(projectsheet.steps || []);
      const userIsProjectAdmin = this.planning.meta.access_right == 'admin';
      return {
        userIsProjectAdmin,
        steps,
        currentStepIndex: steps.length - 1,
        stepEditing: false,
        defaultStepWaypoint: clientConfig.projectsheet?.defaultStepWaypoint || "",
      };
    },
    computed: {
      currentStep() { return this.steps[this.currentStepIndex]; },
      currentStepDate: {
        get() { return this.currentStep?.date; },
        set(newVal) {
          if (! newVal) return;
          const step = this.currentStep;
          step.date = newVal.format();
          this.steps.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
          this.currentStepIndex = this.steps.indexOf(step);
        },
      },
      hasPrevStep() {
        return this.currentStepIndex >= 1;
      },
      hasNextStep() {
        return this.currentStepIndex < this.steps.length - 1;
      },
      stepsExtraFields() {
        // [{"title":"Points d'attention","name":"issues","width":0.5},{"title":"Points de blocage à lever","name":"actions","width":0.5}]
        return this.$store.getters['users/accessRight/config'].projectsheet_steps_extrafields;
      },
      filteredStepsExtraFields() {
        return this.stepsExtraFields?.filter(field => this.hasContent(this.currentStep[field.name]));
      },
    },
    created() {
      this.steps.forEach((step) => { step.id = step.id || window.uuid(); }); // init id for old versions
    },
    methods: {
      toNextStep(amount = 1) {
        this.currentStepIndex += amount;
      },
      addStep() {
        const newStep = { id: window.uuid(), waypoint: "", date: moment().format(), mood: null, updated_at: moment().format() };
        this.steps.push(newStep);
        this.steps.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
        this.currentStepIndex = this.steps.indexOf(newStep);
        this.stepEditing = true;
      },
      deleteStep() {
        if (this.currentStep) this.steps.splice(this.currentStepIndex, 1);
        this.currentStepIndex = Math.min(this.currentStepIndex, this.steps.length - 1);
        if (! this.steps.length) this.saveSteps();
      },
      resetSteps() {
        const projectsheet = this.planning.projectsheet || {};
        this.steps = deepcopy(projectsheet.steps || []);
        this.currentStepIndex = Math.min(this.currentStepIndex, this.steps.length - 1);
        this.stepEditing = false;
      },
      saveSteps() {
        const projectsheet = this.planning.projectsheet || {};
        this.steps.forEach((step) => {
          if (! step.id) return;
          const originalStep = (projectsheet.steps || []).find(item => item.id == step.id);
          if (originalStep && ! angular.equals(originalStep, step)) step.updated_at = moment().format();
        });

        this.$emit('update', {
          steps: deepcopy(this.steps),
        });
        this.stepEditing = false;
      },
      hasContent(html) {
        return !! window.html2text(html || "").trim();
      },
    },
  };
</script>
