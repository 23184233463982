<template>
  <div>
    <v-checkbox v-for="option in selectedDisplayOptions" :key="option.value" density="compact"
                v-model="option.state" :indeterminate="option.state == null"
                @click="handleCheck(option.value)">
      <template #label>{{ option.title }}</template>
    </v-checkbox>
  </div>
</template>

<script>
  export default {
    props: {
      configSelectedDisplay: { type: Object, default: () => ({}) },
    },
    emits: ['update:config-selected-display'],
    data() {
      return {
        selectedDisplayOptions: [
          { value: 'description', title: this.$t('WORKING_VIEWS.DESCRIPTION'), state: null },
          { value: 'checklist', title: this.$t('WORKING_VIEWS.CHECKLIST'), state: null },
          { value: 'progress', title: this.$t('WORKING_VIEWS.PROGRESS'), state: null },
          { value: 'users', title: this.$t('WORKING_VIEWS.USERS'), state: null },
          { value: 'dates', title: this.$t('WORKING_VIEWS.DATES'), state: null },
          { value: 'links', title: this.$t('WORKING_VIEWS.LINKS'), state: null },
          { value: 'budgets', title: this.$t('WORKING_VIEWS.BUDGETS'), state: null },
        ],
      };
    },
    watch: {
      configSelectedDisplay: {
        handler(newVal) {
          this.selectedDisplayOptions.forEach((option) => {
            option.state = newVal[option.value]; // state is needed as a local configSelectedDisplay[option.value] to erase checkbox default behavior
          });
        },
        immediate: true,
        deep: true,
      },
    },
    created() {
      this.selectedDisplayOptions.forEach((option) => {
        this.$watch(() => option.state, () => {
          this.$nextTick(() => { option.state = this.configSelectedDisplay[option.value]; }); // erase checkbox default behavior
        });
      });
    },
    methods: {
      handleCheck(item) {
        const configSelectedDisplay = { ...this.configSelectedDisplay };
        if (this.configSelectedDisplay[item] == null) {
          configSelectedDisplay[item] = true;
        } else if (this.configSelectedDisplay[item] == true) {
          configSelectedDisplay[item] = false;
        } else {
          configSelectedDisplay[item] = null; // set default value
        }
        this.$emit('update:config-selected-display', configSelectedDisplay);
      },
    },
  };
</script>
