export default {
  sso: {
    protocol: 'openIDConnect',
    login: 'https://idp.lillemetropole.fr/oauth2/authorize?client_id=melbubbleplan&response_type=code&scope=openid%20email%20profile&redirect_uri=https%3A%2F%2Fmel.bubbleplan.net',
    response_type: 'code',
  },
  hideManageAccount: true,
  integrations: {
    availableIntegrations: ['microsoft'],
  },
  links: {
    hideOneDrive: true,
    hideDropbox: true,
    hideGoogleDrive: true,
  },
};
