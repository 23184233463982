<template>
  <v-tooltip location="bottom">
    <template #activator="{ props }">
      <v-avatar :size="size" class="ma-2" v-bind="props">
        <v-img :src="avatar" :alt="username" :class="borderColor" style="border:solid 3px white; background-color: white !important"></v-img>
      </v-avatar>
    </template>
    <slot>{{ username }}</slot>
  </v-tooltip>
</template>

<script>
  export default {
    props: {
      borderColor: { type: String, default: "" },
      size: { type: Number, default: 40 },
      user: { required: true, type: Object },
    },
    data() {
      return {};
    },
    computed: {
      username() {
        return this.$store.getters['users/getUsername'](this.user);
      },
      avatar() {
        return this.user && this.user.avatar || "https://secure.gravatar.com/avatar/0?d=mm";
      },
    },
  };
</script>
