<template>
  <div v-if="(elType == 'task' || elType == 'macro') && (elStartTime || elEndTime)">
    <table class="w-100 small">
      <tr>
        <td v-if="elStartTime" class="nobr" style="padding-right: 2px">
          {{ $filters.moment(elStartTime, 'mediumDateNoYear', planningLocale) }}
          <span v-if="elSchedule.start" style="color: rgba(0,0,0,0.65);">{{ $filters.moment(elSchedule.start, 'LT', planningLocale) }}</span>
        </td>
        <td v-if="elEndTime" :class="elEndTimeColorClass" class="nobr float-right">
          {{ $filters.moment(elEndTime, 'mediumDateNoYear', planningLocale) }}
          <span v-if="elSchedule.end" style="color: rgba(0,0,0,0.65);">{{ $filters.moment(elSchedule.end, 'LT', planningLocale) }}</span>
        </td>
      </tr>
    </table>
  </div>
  <div v-else-if="elType == 'milestone' && elStartTime" class="date">
    <div :class="elEndTimeColorClass">{{ $filters.moment(elStartTime, 'mediumDateNoYear', planningLocale) }}</div>
    <div v-if="elSchedule.start || elSchedule.end" class="small" style="font-weight: normal;">
      <span v-if="elSchedule.start">{{ $filters.moment(elSchedule.start, 'LT', planningLocale) }}</span>
      <span v-else>...</span>
      <span v-if="elSchedule.end">- {{ $filters.moment(elSchedule.end, 'LT', planningLocale) }}</span>
    </div>
  </div>
</template>

<style>
  .element.milestone .date {
    font-weight: bold;
    color: grey;
  }
</style>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
      el: { type: Object, required: true },
    },
    computed: {
      elType() {
        return this.el.getType();
      },
      elStartTime() {
        return this.el.getStartTime();
      },
      elEndTime() {
        return this.el.getEndTime();
      },
      elEndTimeColorClass() {
        const isKanbanMode = this.$store.state.ui.planning.mode == 'kanban';
        return isKanbanMode && this.el.getEndTimeColorClass() || null;
      },
      elSchedule() {
        return this.el.getSchedule();
      },
      planningLocale() {
        return this.planning.timeline.getTimelocale();
      },
    },
  };
</script>
