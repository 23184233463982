<template>
  <div v-if="selectedRisk" :class="selectedRisk.color" :title="riskTitle" class="users-bar nobr text-ellipsis">
    Risque {{ selectedRisk.label }}
  </div>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
      customField: { type: Object, required: true },
    },
    data() {
      const { dataField } = this.customField;
      return {
        dataField,
        defaultsValues: {
          value: 'no',
          description: "",
        },
        riskOptions: [
          { value: 'low', label: 'Faible', color: 'bg-successgreen' },
          { value: 'medium', label: 'Moyen', color: 'bg-warningorange' },
          { value: 'high', label: 'Élevé', color: 'bg-errorred' },
          { value: 'no', label: 'non évalué', color: 'bg-black' },
        ],
      };
    },
    computed: {
      values() {
        let values = this.el.data.customFields && this.el.data.customFields[this.dataField];
        if (typeof values == 'string') values = { ...this.defaultsValues, value: values }; // convert from v0
        return values || {};
      },
      selectedRisk() {
        if (! this.values.value || this.values.value == 'no') return null;
        return this.riskOptions.find(item => item.value == this.values.value);
      },
      riskTitle() {
        let title = `Risque ${this.selectedRisk.label}`;
        if (this.values.description) title += ` : ${this.values.description}`;
        return title;
      },
    },
  };
</script>
