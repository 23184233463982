import Planning from '@/models/Planning';
import PlanningElement from '@/models/PlanningElement';

const invariantFields = ['id'];

export default {
  namespaced: true,
  state: {},
  mutations: {
  },
  getters: {
    getOriginalPlanningEl() {
      return (el) => {
        const originalPlanningEl = new PlanningElement(el.getPlanning(), { ...el.getAll(), id: el.o_id });
        originalPlanningEl.dashboard_el = el;
        return originalPlanningEl;
      };
    },
    getOriginalLaneId() {
      return el => el.getPlanning()?.lanes.find(lane => lane.id == el.getLaneId())?.o_id;
    },
  },
  actions: {
    saveEl({ dispatch, getters, rootState }, { el, oldState: oldStateParam, newState: newStateParam, props = [] }) {
      // el is dashboard_el
      // use oldState if el has been modified, or newState  otherwise
      let oldState = oldStateParam;
      let newState = newStateParam;
      if (newState && ! oldState) {
        // apply new state
        newState = _.omit(newState, invariantFields);
        oldState = _.omit(el.getAll(), invariantFields);
        el.set(newState); // set rather than reset does not modify el if not needed (prevents reactive workloads/other_computed update)
        el.update();
      }
      newState = _.omit(el.getAll(), invariantFields);

      let savingProps = props.length ? props : Object.keys(newState);
      savingProps = savingProps.filter(prop => ! angular.equals(newState[prop], oldState[prop]) && ! invariantFields.includes(prop));
      if (! savingProps.length) return Promise.resolve('nothing to save');

      const { lastSavedProjectVersion } = el.getPlanning();
      const originalPlanning = new Planning({ id: lastSavedProjectVersion.id, meta: { title: lastSavedProjectVersion.title }, ...lastSavedProjectVersion.content });
      const originalPlanningEl = originalPlanning.elements.find(item => item.id == el.o_id);
      if (! originalPlanningEl) return 'nothing to save';
      originalPlanningEl.set(_.pick(newState, savingProps));
      if (savingProps.includes('lane_id')) {
        const originalLaneId = getters.getOriginalLaneId(el);
        if (originalLaneId) originalPlanningEl.setLaneId(originalLaneId);
      }
      return originalPlanning.save().then((contentPatch) => {
        if (contentPatch) window.notificationsSrv.callEvent('projectSaved', { contentPatch, planning_id: originalPlanning.id, planning_title: originalPlanning.getTitle() });
      }).catch((message) => {
        el.set(oldState);
        el.update();
        if (message) dispatch('ui/msgbox/open', { title: rootState.lang.i18n.global.t('MONITORING_PROGRESS.ERROR_NOT_MODIFIED'), body: message || "" }, { root: true });
        return 'nothing to save';
      });
    },
    deleteElement({ dispatch, rootState }, el) {
      // el is dashboard_el
      // remove el
      const subplanning = el.getPlanning();
      const index = subplanning.elements.findIndex(element => element.id == el.id);
      if (index > -1) subplanning.elements.splice(index, 1);

      // save
      const { lastSavedProjectVersion } = el.getPlanning();
      const originalPlanning = new Planning({ id: lastSavedProjectVersion.id, meta: { title: lastSavedProjectVersion.title }, ...lastSavedProjectVersion.content });
      const originalPlanningElIndex = originalPlanning.elements.findIndex(item => item.id == el.o_id);
      if (originalPlanningElIndex == -1);
      originalPlanning.elements.splice(originalPlanningElIndex, 1);
      originalPlanning.save().then((contentPatch) => {
        if (contentPatch) window.notificationsSrv.callEvent('projectSaved', { contentPatch, planning_id: subplanning.id, planning_title: subplanning.getTitle() });
      }).catch((message) => {
        subplanning.elements.push(el);
        el.update();
        if (message) dispatch('ui/msgbox/open', { title: rootState.lang.i18n.global.t('MONITORING_PROGRESS.ERROR_NOT_MODIFIED'), body: message || "" }, { root: true });
      });
    },
    duplicateElement({ getters, commit, dispatch, rootState }, el) {
      // el is dashboard_el
      const newEl = getters.getOriginalPlanningEl(el);
      if (! newEl) return;
      const originalLaneId = getters.getOriginalLaneId(newEl);
      if (originalLaneId) newEl.setLaneId(originalLaneId);
      newEl.setDependencies(null);
      newEl.create().then(() => { // create a new element via api / id is updated by api response
        const subplanning = el.getPlanning();
        if (! subplanning) return;
        subplanning.lastSavedProjectVersion.content.elements.push(newEl.getAll()); // update lastSavedProjectVersion
        commit('subprojects/addSubplanningElement', { projectId: subplanning.id, el: newEl }, { root: true });
        newEl.update();
        setTimeout(() => {
          newEl.updateHeight();
        });
        window.notificationsSrv.callEvent('projectSaved', { planning_id: subplanning.id, planning_title: subplanning.getTitle() });
      }).catch((message) => {
        if (message) dispatch('ui/msgbox/open', { title: rootState.lang.i18n.global.t('MONITORING_PROGRESS.ERROR_NOT_MODIFIED'), body: message || "" }, { root: true });
      });
    },
    closeElementDetails({ rootState, commit, dispatch }, reason) {
      const { openedElement } = rootState.ui.planning;
      if (! openedElement) return;
      if (reason == 'delete') {
        dispatch('deleteElement', openedElement.dashboard_el);
      } else {
        dispatch('saveEl', { el: openedElement.dashboard_el, newState: openedElement.getAll() });
        if (reason == 'duplicate') {
          dispatch('duplicateElement', openedElement.dashboard_el);
        }
      }
      commit('ui/planning/openElement', null, { root: true });
    },
    closeColorPicker({ rootState, commit, dispatch }) {
      const { openedColorPickerElement } = rootState.ui.planning;
      if (! openedColorPickerElement) return;
      dispatch('saveEl', { el: openedColorPickerElement.dashboard_el, newState: openedColorPickerElement.getAll() });

      commit('ui/planning/openColorPickerElement', null, { root: true });
    },
  },
};
