import { reactive } from 'vue';
import constants from '@/js/constants';
import PlanningProcessStep from '@/models/PlanningProcessStep';

const undoState = { config: {}, steps: [] };

function insertNewStep(planning, newstep) {
  const step = reactive(new PlanningProcessStep(planning, newstep));
  planning.process.steps.push(step);
  return step;
}

function set(planning, { config: newconfig, steps: newsteps }) {
  if (newconfig) Object.assign(planning.process.config, newconfig);
  if (newsteps) {
    planning.process.steps.splice(0, planning.process.steps.length);
    while (newsteps.length) {
      const newstep = newsteps.shift();
      insertNewStep(planning, newstep);
    }
  }
}

function undoredoAndSave(context) {
  const { rootState, dispatch } = context;
  const newState = {};
  const oldState = {};
  if (! angular.equals(rootState.planning.process.config, undoState.config)) {
    newState.config = angular.copy(rootState.planning.process.config);
    oldState.config = angular.copy(undoState.config);
  }
  if (! angular.equals(rootState.planning.process.steps, undoState.steps)) {
    newState.steps = rootState.planning.process.steps.map(step => angular.copy(step));
    oldState.steps = undoState.steps.map(step => angular.copy(step));
  }
  if (! newState.config && ! newState.steps) return;
  dispatch('undoredo/add', {
    action: () => {
      set(rootState.planning, { config: angular.copy(newState.config), steps: newState.steps?.map(step => angular.copy(step)) });
    },
    rollback: () => {
      set(rootState.planning, { config: angular.copy(oldState.config), steps: oldState.steps?.map(step => angular.copy(step)) });
    },
  }, { root: true });
  context.dispatch('planning/save', null, { root: true });
}

/* ******* */
/* GETTERS */
/* ******* */
function subprojectStepId2ProjectStepId(state, getters, rootState, rootGetters) {
  // automatically map subprojects steps ids to project steps ids by using the label
  // [subprojectId.subprojectStepId => projectStepId or label]
  const stepsIdsMapping = {};
  const stepLabel2Id = rootState.planning.process.steps.reduce((acc, step) => { acc[step.label] = step.id; return acc; }, {});
  const visibleLanes = rootGetters['planning/lanes/getVisibleLanes']();
  visibleLanes.forEach((lane) => {
    if (lane.project_id) {
      const subproject = rootGetters['subprojects/getSubprojectByLaneId'](lane.id);
      if (subproject) {
        subproject.process.steps.forEach((subprojectStep) => {
          if (! subprojectStep.id) return; // skip backlog
          stepsIdsMapping[`${subproject.id}.${subprojectStep.id}`] = stepLabel2Id[subprojectStep.label] || subprojectStep.label;
        });
      }
    }
  });
  return stepsIdsMapping;
}

function convertSubprojectStepId(state, getters, rootState) {
  return function (subprojectId, stepId) {
    if (rootState.ui.planning.dashboard) return rootState.multiprojects.process.projectStepId2DashboardStepId[`${subprojectId}.${stepId}`]; // process dashboard conversion table
    return getters.subprojectStepId2ProjectStepId[`${subprojectId}.${stepId}`];
  };
}

function subprojectsProcessSteps(state, getters, rootState, rootGetters) {
  const visibleLanes = rootGetters['planning/lanes/getVisibleLanes']();
  const hiddenSubSteps = new Set(rootState.planning.process.config.getHiddenSubsteps()); // will get xs width

  const processStepsIds = new Set(state.steps.map(item => item.id));
  return visibleLanes.reduce((acc, lane) => {
    if (lane.project_id) {
      const subproject = rootGetters['subprojects/getSubprojectByLaneId'](lane.id);
      if (subproject) {
        subproject.process.steps.forEach((subprojectStep) => {
          if (! subprojectStep.id) return; // skip backlog
          const newId = getters.convertSubprojectStepId(subproject.id, subprojectStep.id); // newId should always exist by construction
          if (! newId || processStepsIds.has(newId)) return; // skip if col label already exists (merge)
          processStepsIds.add(newId);
          const subprojectStepCopy = angular.copy(subprojectStep);
          subprojectStepCopy.o_id = subprojectStep.id;
          subprojectStepCopy.id = newId;
          subprojectStepCopy.width = hiddenSubSteps.has(newId) ? constants.kanbanXsColWidth : constants.kanbanDefaultColWidth;
          acc.push(subprojectStepCopy);
        });
      }
    }
    return acc;
  }, []);
}

/** ***** */
/*  EDIT  */
/** ***** */
function startChangingProcess({ state }) {
  undoState.config = angular.copy(state.config);
  undoState.steps = state.steps.map(step => angular.copy(step));
}

function changingProcess(context) {
  undoredoAndSave(context);
}

/* *************** */
/* ACTIONS : STEPS */
/* *************** */
function addStep(context, newstep) {
  const { state: { steps }, rootState } = context;
  if (! steps) return null;
  startChangingProcess(context);
  const step = insertNewStep(rootState.planning, newstep);
  step.id = window.uuid();
  step.label = newstep?.label || rootState.lang.i18n.global.t('KANBAN.NEW_COLUMN') || '';
  changingProcess(context);
  return step;
}

function addDefaultSteps(context, defaultsteps) {
  const { state: { steps }, rootState } = context;
  if (! steps) return null;
  startChangingProcess(context);
  const stepsData = [];
  defaultsteps.forEach((defaultStep) => {
    const step = insertNewStep(rootState.planning, defaultStep);
    stepsData.push(step);
  });
  changingProcess(context);
  return stepsData;
}

function deleteStep(context, stepId) {
  const { state: { steps }, rootState, dispatch } = context;
  startChangingProcess(context);
  const index = steps.findIndex(step => step.id == stepId);
  const step = steps[index];
  if (step) {
    dispatch('planning/elements/startChangingElement', null, { root: true });
    rootState.planning.elements.forEach((el) => {
      if (el.getProcessStep() === stepId) { // send el to backlog
        el.setProcessStep(null);
      }
    });
    dispatch('planning/elements/changingElement', null, { root: true });
    steps.splice(index, 1);
  }
  changingProcess(context);
}

export default {
  namespaced: true,
  state: {
    config: {},
    steps: [],
  },
  getters: {
    subprojectStepId2ProjectStepId,
    convertSubprojectStepId,
    subprojectsProcessSteps,
  },
  actions: {
    startChangingProcess,
    changingProcess,
    addStep,
    addDefaultSteps,
    deleteStep,
  },
};
