<template>
  <article v-if="planning" :style="{ 'padding-top': projectsheetCurrentStep?.mood ? '16px' : '' }">
    <v-card :flat="false" class="overflow-visible">
      <div v-if="article.showhead" style="margin-top: 24px">
        <div style="padding: 4px 10px 0 50px; min-height: 50px; display: flex; align-items: center;">
          <div v-if="projectsheetCurrentStep?.mood" class="bg-surface"
               style="width: 60px; height: 60px; box-shadow: 0 0px 4px 2px rgb(var(--v-theme-lightgrey)); border-radius: 50%; z-index: 1; position: absolute; left: -20px; top: -20px">
            <div style="width: 60px; height: 60px; padding: 5px"><div :class="`mood-icon-${projectsheetCurrentStep.mood}`"></div></div>
          </div>
          <h4><router-link :to="`/viewer/${planning.id}`" target="_blank" rel="noopener" style="color: inherit">{{ planning.title }}</router-link></h4>
          <span class="ml-2"></span>
          -
          <span class="ml-2"></span>
          <div v-if="owner">
            <img :src="owner.avatar" class="vcenter" style="height: 30px; width: 30px; border-radius: 50%">
            <b class="vcenter ml-2">{{ $filters.username(owner) }}</b>
          </div>
        </div>
        <div v-if="projectsheetDisplayCount" class="projectsheet-wrapper export-hidden-if-empty">
          <div :style="{ 'flex-direction': showProjectsheet ? 'column' : 'row' }" class="projectsheet-menu export-hidden">
            <a class="pointer" @click="showProjectsheet = ! showProjectsheet">
              <i :class="showProjectsheet ? 'fa-caret-down' : 'fa-caret-right'" class="fa fa-fw"></i><span v-show="! showProjectsheet">&nbsp;{{ $t('PROJECT_SHEET.PROJECT_SHEET') }}</span>
            </a>
            <div v-if="projectsheetCurrentStepUpdatedAt" :title="`${$t('MONITORING_PLAN.UPDATED')} ${projectsheetCurrentStepUpdatedAt}`"
                 :class="showProjectsheet ? 'mt-2' : 'ml-2'"
                 class="label-new" style="cursor: default; font-size: .8em; padding-left: .5em; padding-right: .5em; border-radius: 28px; box-shadow: 0 0px 4px 2px #ccc;">
              NEW
            </div>
            <div v-if="showProjectsheet && hasProjectSheetEditRights" class="mt-2">
              <button type="button" :title="$t('MONITORING_PLAN.EDIT_PROJECT_SHEET')" class="transparent-button nobr" @click="$refs.projectsheet && $refs.projectsheet.openEditProjectSheet()">
                <i class="far fa-pencil-alt"></i>
              </button>
            </div>
          </div>
          <div style="flex-grow: 1; width: 100%">
            <monitoring-plan-consult-planning-project-sheet v-if="showProjectsheet" ref="projectsheet" :projectsheet-display="projectsheetDisplay" :project-id="planning.id"
                                                            @set-projectsheet-edit-rights="hasProjectSheetEditRights = $event">
            </monitoring-plan-consult-planning-project-sheet>
          </div>
        </div>
      </div>
      <div v-if="lanes.length" class="export-hidden-if-empty" style="padding: 4px 10px;">
        <div class="export-hidden">
          <a class="pointer" @click="showProjectLanes = ! showProjectLanes">
            <i :class="showProjectLanes ? 'fa-caret-down' : 'fa-caret-right'" class="fa fa-fw"></i><span v-show="! showProjectLanes">&nbsp;{{ $t('MONITORING_PLAN.LANE_WATCH') }}</span>
          </a>
        </div>
        <template v-if="showProjectLanes">
          <monitoring-plan-consult-planning-lane v-for="lane in lanes" :key="lane.id" :monitoring-lane="lane" :project_access_right="planning.access_right"
                                                 @open-edit-lane="$emit('open-edit-lane', $event)"
                                                 @update-comments="$emit('update-lane', { planning_id: planning.id, lane_id: lane.id, comments: $event })">
          </monitoring-plan-consult-planning-lane>
        </template>
      </div>
      <div v-if="hasProgressDashboard" class="projectsheet-wrapper export-hidden-if-empty pr-4">
        <div :style="{ 'flex-direction': showProgressDashboard ? 'column' : 'row' }" class="projectsheet-menu export-hidden">
          <a class="pointer" @click="showProgressDashboard = ! showProgressDashboard">
            <i :class="showProgressDashboard ? 'fa-caret-down' : 'fa-caret-right'" class="fa fa-fw"></i><span v-show="! showProgressDashboard">&nbsp;{{ $t('MONITORING_PROGRESS.TITLE') }}</span>
          </a>
        </div>
        <monitoring-plan-consult-planning-progress-dashboard v-if="showProgressDashboard" :kanban-display="kanbanDisplay" :project-id="planning.id" style="flex: 1 1 auto">
        </monitoring-plan-consult-planning-progress-dashboard>
      </div>
    </v-card>
  </article>
</template>

<style>
  .monitoring-plan .projectsheet-wrapper {
    padding: 2px 10px;
    border-bottom: 1px solid rgb(var(--v-theme-lightgrey));
    border-top: 1px solid rgb(var(--v-theme-lightgrey));
    display: flex;
  }

  .monitoring-plan .projectsheet-menu {
    flex-shrink: 0;
    min-width: 26px;
    display: flex;
    align-items: center;
  }
</style>

<script>
  import MonitoringPlanConsultPlanningProjectSheet from './MonitoringPlanConsultPlanningProjectSheet.vue';
  import MonitoringPlanConsultPlanningLane from './MonitoringPlanConsultPlanningLane.vue';
  import MonitoringPlanConsultPlanningProgressDashboard from './MonitoringPlanConsultPlanningProgressDashboard.vue';

  export default {
    components: {
      MonitoringPlanConsultPlanningProjectSheet,
      MonitoringPlanConsultPlanningLane,
      MonitoringPlanConsultPlanningProgressDashboard,
    },
    props: {
      article: { type: Object, required: true },
      plannings: { type: Array, required: true },
    },
    emits: ['open-edit-lane', 'update-lane'],
    data() {
      return {
        showProjectsheet: this.article.showprojectsheet || false,
        projectsheetDisplay: this.article.projectsheet_display || { description: true, steps: true, files: false, project_users: false, activity: false },
        showProjectLanes: false,
        showProgressDashboard: false,
        hasProjectSheetEditRights: false,
      };
    },
    computed: {
      planning() {
        return this.plannings.find(item => item.id == this.article.planning_id);
      },
      lanes() {
        return (this.article.lanes || []).map(laneId => (this.planning.monitoring_lanes || []).filter(lane => lane.id == laneId)[0]).filter(item => item);
      },
      projectsheetDisplayCount() {
        return (this.projectsheetDisplay.description && 1 || 0) + (this.projectsheetDisplay.steps && 1 || 0) + (this.projectsheetDisplay.files && 1 || 0)
          + (this.projectsheetDisplay.project_users && 1 || 0) + (this.projectsheetDisplay.activity && 1 || 0);
      },
      owner() {
        return this.$store.getters['users/getUserById'](this.planning.owner_id);
      },
      projectsheetCurrentStep() {
        const completePlanning = this.$store.getters['multiprojects/getPlanningById'](this.planning.id);
        return completePlanning?.projectsheet?.steps?.at(-1);
      },
      projectsheetCurrentStepUpdatedAt() {
        if (! this.projectsheetCurrentStep) return null;
        const updatedMoment = moment(this.projectsheetCurrentStep.updated_at || this.projectsheetCurrentStep.date || "2001-01-01");
        return updatedMoment.isAfter(moment().add(-1, 'week').endOf('day')) && this.$filters.moment(updatedMoment, 'calendar');
      },
      kanbanDisplay() {
        return this.article.kanban_display || {};
      },
      hasProgressDashboard() {
        return Object.keys(this.kanbanDisplay).reduce((acc, item) => acc || this.kanbanDisplay[item], false);
      },
    },
    created() {
      if (this.planning) this.$store.dispatch('multiprojects/plannings/load', { planningId: this.planning.id }).catch(() => {});
    },
  };
</script>
