export default [
  "ALT",
  "APLINOX",
  "ARCHITECTE",
  "ARCOMETAL",
  "AXIMA",
  "BOUSSAC",
  "BOVIS",
  "CFC 34",
  "CIDECO",
  "CP INSTALL",
  "FMI STEPHANE FERCOK",
  "G CLIMATIQUE",
  "HOBART",
  "INTERIM",
  "ISOLAGRO",
  "JOEL TRANS JOS",
  "LANEF BERTRAND LECOINTRE",
  "LE FROID COUFFEEN",
  "LEBOZEC",
  "LIONEL VICTOR",
  "MCL PHILIPPE LESAULNIER",
  "MEDERIC FROID NRJ",
  "PESSEGUES",
  "S2G",
  "SNEE",
  "TECHNIS",
  "TLA",
  "VIT TRANSPORT",
];
