<template>
  <div class="element-details-card-header">
    <element-details-config-button :el="el" config="show-subTasks"></element-details-config-button>
  </div>
  <div class="d-flex mb-5">
    <v-icon size="small" start class="mt-6 align-self-center">far fa-filter</v-icon>
    <div>
      <!-- LANES -->
      <span>{{ $t('EXPORT.LANES') }}</span>
      <v-select v-model="selectedLanesList" :items="availableLanes" :loading="! planning"
                item-value="id" item-title="label"
                multiple>
        <template #prepend-item>
          <filter-toggle :selected-ids="selectedLanesList" :sublist-ids="availableLanesIds"
                         @update="selectedLanesList = $event">
            {{ $t('EXPORT.ALL_LANES') }} ({{ availableLanesIds.length }})
          </filter-toggle>
          <v-divider></v-divider>
        </template>
        <template #selection="{ item: { raw: item }, index }">
          <span v-if="selectedLanesList.length == 1">{{ item.label }}</span>
          <span v-else-if="selectedLanesList.length == availableLanes.length && index === 0" class="nobr">{{ $t('EXPORT.ALL_LANES') }} ({{ availableLanes.length }})</span>
          <span v-else-if="index === 0">{{ selectedLanesList.length }}/{{ availableLanes.length }}</span>
        </template>
      </v-select>
    </div>

    <!-- DATES -->
    <div class="ml-4 flex-grow-1">
      <label for="subtasksFilterFrom">{{ $t('ELEMENT-DETAIL.FROM') }}</label>
      <date-field v-model="subTaskStartTimeFilter" id="subtasksFilterFrom" clearable></date-field>
    </div>
    <div class="ml-2 flex-grow-1">
      <label for="subtasksFilterto">{{ $t('ELEMENT-DETAIL.TO') }}</label>
      <date-field v-model="subTaskEndTimeFilter" id="subtasksFilterto" clearable></date-field>
    </div>
  </div>

  <!-- SUB TASKS -->
  <v-autocomplete v-model="newSubTask" :items="filteredSubTasks" :item-title="(el) => el.header ? '' : (el.getTitle() || defaultTitle(el))" item-value="id"
                  :placeholder="$t('ELEMENT-DETAIL.LOOK_FOR_BUBBLE')" class="pt-0" return-object
                  @update:model-value="addSubTask">
    <template #item="{ props, item: { raw: item } }">
      <v-divider v-if="item.divider"></v-divider>
      <v-list-subheader v-else-if="item.header" :title="item.header"></v-list-subheader>
      <v-list-item v-else v-bind="props">
        <template #title>
          <span v-if="item.isType('macro')" class="mr-1">(macro)</span>
          <span class="text-truncate" style="max-width: 200px">
            {{ item.getTitle() || defaultTitle(item) }}
          </span>
          <small v-if="item.getStartTime()" class="ml-2 text-medium-emphasis">
            {{ $filters.moment(item.getStartTime(), 'mediumDateNoYear', planningLocale) }}
          </small>
          <small v-if="! item.isType('milestone') && item.getEndTime()" class="text-medium-emphasis">
            &nbsp;- {{ $filters.moment(item.getEndTime(), 'mediumDateNoYear', planningLocale) }}
          </small>
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
  <template v-if="elSubTasks.length">
    <table class="w-100 mt-3">
      <tr v-for="(subTask, subTaskIndex) in elSubTasks">
        <td>
          <span v-if="subTask.isType('macro')" class="mr-1">(macro)</span>
          <span>{{ subTask.getTitle() || defaultTitle(subTask) }}</span>
          <span v-if="subTask.getProgress()"> ({{ subTask.getProgress() }}%)</span>
          <small v-if="subTask.getStartTime()" class="ml-2 text-medium-emphasis">
            {{ $filters.moment(subTask.getStartTime(), 'mediumDateNoYear', planningLocale) }}
          </small>
          <small v-if="! subTask.isType('milestone') && subTask.getEndTime()" class="text-medium-emphasis">
            - {{ $filters.moment(subTask.getEndTime(), 'mediumDateNoYear', planningLocale) }}
          </small>
        </td>
        <td class="text-right mt-2" style="width: 0px">
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn-icon size="small" color="errorred" class="sub-task-menu ml-2" v-bind="props" @click.stop="removeSubTask(subTaskIndex)">
                <v-icon>far fa-trash-alt</v-icon>
              </v-btn-icon>
            </template>
            <span>{{ subTask.isType('milestone') ? $t('ELEMENT-DETAIL.REMOVE_MILESTONE') : $t('ELEMENT-DETAIL.REMOVE_TASK') }}</span>
          </v-tooltip>
        </td>
      </tr>
    </table>
    <hr class="mx-auto my-0" style="border-top: 1px solid #ccc">
  </template>
</template>

<script>
  import ElementDetailsConfigButton from '../partials/ElementDetailsConfigButton';

  export default {
    components: {
      ElementDetailsConfigButton,
    },
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        newSubTask: null,
        planning: this.el.getPlanning(),
        selectedLanesList: [],
        subTaskStartTimeFilter: null,
        subTaskEndTimeFilter: null,
      };
    },
    computed: {
      elSubTasks() {
        if (! this.el.isType('macro')) return null;
        return this.planning.elements.filter(item => this.elSubTasksId.has(parseInt(item.id, 10)));
      },
      elSubTasksId() {
        return new Set(this.el.getSubTasks());
      },
      availableLanes() {
        if (! this.planning) return [];
        return this.planning.lanes.map(item => ({ id: item.id, label: item.label }));
      },
      availableLanesIds() {
        return this.availableLanes.map(item => item.id);
      },
      elsChildren() {
        const elsChildren = {};
        this.planning.elements.forEach((el) => {
          if (! el.getSubTasks()) return; // take only el with children
          elsChildren[el.id] = el.getSubTasks();
        });
        return elsChildren;
      },
      elsParents() {
        const elsParents = {};
        Object.keys(this.elsChildren).forEach((key) => {
          const parent = parseInt(key, 10);
          const children = this.elsChildren[key];

          children.forEach((child) => {
            if (! elsParents[child]) elsParents[child] = new Set();
            elsParents[child].add(parent);
          });
        });
        return elsParents;
      },
      elParentsIds() {
        const parentsIds = this.macroParents(this.el.id, new Set());
        return parentsIds;
      },
      filteredSubTasks() {
        const filteredSubTasks = { task: [], milestone: [], macro: [] };
        this.planning.elements.forEach((el) => {
          if (this.elParentsIds.has(el.id)) return; // ignore parents of selected macro
          if (! this.selectedLanesList.includes(el.getLaneId())) return;
          if (this.subTaskStartTimeFilter && ! (el.getEndTime() || el.getStartTime())?.isSameOrAfter(this.subTaskStartTimeFilter)) return; // if starttime or endtime is null, use the other as fallback
          if (this.subTaskEndTimeFilter && ! (el.getStartTime() || el.getEndTime())?.isSameOrBefore(this.subTaskEndTimeFilter)) return;
          if (this.elSubTasks.find(item => item.id == el.id)) return;
          filteredSubTasks[el.getType()].push(el);
        });
        Object.values(filteredSubTasks).forEach((els) => { els.sort((a, b) => (a.getStartTime() < b.getStartTime() ? -1 : 1)); });
        const result = [];
        if (filteredSubTasks.task.length) result.push({ header: this.$t('GLOBAL.BUBBLES') }, ...filteredSubTasks.task);
        if (filteredSubTasks.milestone.length) result.push({ header: this.$t('GLOBAL.MILESTONES') }, ...filteredSubTasks.milestone);
        if (filteredSubTasks.macro.length) result.push({ header: this.$t('GLOBAL.MACRO_BUBBLES') }, ...filteredSubTasks.macro);
        return result;
      },
      planningLocale() {
        return this.planning.timeline.getTimelocale();
      },
    },
    created() {
      this.selectedLanesList = this.availableLanes.map(item => item.id);
    },
    methods: {
      macroParents(macroId, macroParents) {
        if (macroParents.has(macroId)) return null;
        macroParents.add(macroId);

        const parentsIds = new Set(this.elsParents[macroId]);
        parentsIds.forEach((parentId) => {
          if (macroParents.has(parentId)) return null;
          return this.macroParents(parentId, macroParents);
        });
        return macroParents;
      },
      addSubTask() {
        this.elSubTasks.push(this.newSubTask);
        const subTasksId = this.elSubTasks.map(item => item.o_id || item.id);
        this.el.setSubTasks(subTasksId);
        this.$nextTick(() => {
          this.newSubTask = null;
        });
      },
      removeSubTask(index) {
        if (index > -1 && index < this.elSubTasks.length) {
          this.elSubTasks.splice(index, 1);
          const subTasksId = this.elSubTasks.map(item => item.o_id || item.id);
          this.el.setSubTasks(subTasksId);
          this.$nextTick(() => {
            this.newSubTask = null;
          });
        }
      },
      defaultTitle(el) {
        if (el.isType('macro')) return this.$t('PLANNING.NEW_MACRO_BUBBLE');
        return el.isType('milestone') ? this.$t('PLANNING.MILESTONE') : this.$t('PLANNING.NEW_BUBBLE');
      },
    },
  };
</script>
