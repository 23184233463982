<template>
  <div style="line-height: normal">
    <div>
      <div class="d-flex align-center mb-1"><v-icon class="mr-1">far fa-check-square</v-icon> &nbsp;{{ $t('PROJECT_SHEET.ACTIONS') }}</div>
      <div v-if="actionStats" class="d-flex rounded overflow-hidden">
        <div v-for="(actionStat, id) in actionStats" :key="id" :class="actionStat.class" :style="{ width: `${actionStat.percent}%` }"
             class="flex-center-center flex-column" style="height: 60px; min-width: 100px">
          <div class="text-h6" style="line-height: normal">{{ actionStat.number }}</div>
          {{ $t(actionStat.label) }}
        </div>
      </div>
      <div v-else class="mt-2 text-medium-emphasis">{{ $t('PROJECT_SHEET.NO_ACTION') }}</div>
    </div>
    <div v-if="workloadStats" class="mt-4">
      <div class="d-flex align-center mb-1"><v-icon class="mr-1">far fa-weight-hanging</v-icon> &nbsp;{{ $t('HR.WORKLOAD') }}</div>
      <div class="d-flex rounded overflow-hidden">
        <div v-for="(workloadStat, id) in workloadStats" :key="id" :class="workloadStat.class" :style="{ width: `${workloadStat.percent}%` }"
             class="flex-center-center flex-column" style="height: 60px; min-width: 100px">
          <div class="text-h6" style="line-height: normal">{{ $filters.number(workloadStat.number) }} {{ workloadInHours ? $t('PROJECT_SHEET.HOURS_SHORT') : $t('PROJECT_SHEET.DAYS_SHORT') }}</div>
          {{ $t(workloadStat.label) }}
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="d-flex align-center mb-1"><v-icon class="mr-1">far fa-minus</v-icon> &nbsp;{{ $t('PROJECT_SHEET.PROGRESS') }}</div>
      <div v-if="bubbleStats" class="d-flex rounded overflow-hidden">
        <div v-for="(bubbleStat, id) in bubbleStats" :key="id" :class="bubbleStat.class" :style="{ width: `${bubbleStat.percent}%` }"
             class="flex-center-center flex-column" style="height: 60px; min-width: 100px">
          <div class="text-h6" style="line-height: normal">{{ bubbleStat.number }}</div>
          {{ $t(bubbleStat.label) }}
        </div>
      </div>
      <div v-else class="mt-2 text-medium-emphasis">{{ $t('PROJECT_SHEET.NO_BUBBLE') }}</div>
    </div>
  </div>
</template>

<script>
  import workloadMixin from '@/components/Workload/workloadMixin';

  export default {
    mixins: [workloadMixin],
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
      };
    },
    computed: {
      actionStats() {
        const stats = {
          finished: { label: 'PROJECT_SHEET.FINISHED', class: 'bg-successgreen', number: 0 },
          urgent: { label: 'PROJECT_SHEET.URGENT', class: 'bg-warningorange', number: 0 },
          overdue: { label: 'PROJECT_SHEET.OVERDUE', class: 'bg-errorred', number: 0 },
          future: { label: 'PROJECT_SHEET.FUTURE', class: 'bg-midgrey', number: 0 },
        };
        this.planning.elements.forEach((el) => {
          if (! el.getChecklist() || ! el.hasDates()) return;
          el.getChecklist().forEach((action) => {
            const color = el.getChecklistItemColor(action);
            if (action.checked) {
              stats.finished.number++;
            } else if (color === 'errorred') {
              stats.overdue.number++;
            } else if (color === 'warningorange') {
              stats.urgent.number++;
            } else {
              stats.future.number++;
            }
          });
        });
        const total = stats.finished.number + stats.urgent.number + stats.overdue.number + stats.future.number;
        if (! total) return null;
        Object.keys(stats).forEach((id) => {
          if (stats[id].number) {
            stats[id].percent = stats[id].number / total * 100;
          } else {
            delete stats[id];
          }
        });
        return stats;
      },
      workloadStats() {
        let estimated = 0;
        let used = 0;
        this.planning.elements.forEach((el) => {
          if (! el.getChecklist() || ! el.hasDates()) return;
          el.getChecklist().forEach((action) => {
            used += this.workloadToDisplay(action.workload_used || 0);
            estimated += this.workloadToDisplay(action.workload || action.workload_used || 0);
          });
        });
        estimated = Math.round(estimated * 100) / 100;
        used = Math.round(used * 100) / 100;
        if (! estimated && ! used) return null;
        const remaining = Math.round((estimated - used) * 100) / 100;
        const stats = {
          used: { label: 'PROJECT_SHEET.DAYS_USED', class: 'bg-successgreen', number: Math.min(used, estimated) },
          remaining: { label: 'PROJECT_SHEET.DAYS_REMAINING', class: 'bg-midgrey', number: Math.max(0, remaining) },
          exceeded: { label: 'PROJECT_SHEET.DAYS_EXCEEDED', class: 'bg-errorred', number: Math.max(0, -remaining) },
        };
        Object.keys(stats).forEach((id) => {
          if (stats[id].number) {
            stats[id].percent = stats[id].number / estimated * 100;
          } else {
            delete stats[id];
          }
        });
        return stats;
      },
      workloadInHours() {
        return this.$store.getters['users/accessRight/config'].workloadInHours;
      },
      bubbleStats() {
        const stats = {
          finished: { label: 'PROJECT_SHEET.FINISHED', class: 'bg-successgreen', number: 0 },
          inprogress: { label: 'PROJECT_SHEET.IN_PROGRESS', class: 'bg-warningorange', number: 0 },
          overdue: { label: 'PROJECT_SHEET.OVERDUE', class: 'bg-errorred', number: 0 },
          future: { label: 'PROJECT_SHEET.FUTURE', class: 'bg-midgrey', number: 0 },
        };
        this.planning.elements.forEach((el) => {
          if (! el.isType('task') || ! el.hasDates()) return;
          let id;
          if (el.getProgress() == 100) {
            id = 'finished';
          } else if (el.getEndTime().isBefore()) {
            id = 'overdue';
          } else if (el.getStartTime().isAfter()) {
            id = 'future';
          } else {
            id = 'inprogress';
          }
          stats[id].number++;
        });
        const total = stats.finished.number + stats.inprogress.number + stats.overdue.number + stats.future.number;
        if (! total) return null;
        Object.keys(stats).forEach((id) => {
          if (stats[id].number) {
            stats[id].percent = stats[id].number / total * 100;
          } else {
            delete stats[id];
          }
        });
        return stats;
      },
    },
  };
</script>
