<template>
  <section style="max-width: 1000px;">
    <div v-if="! sharedUsersLoaded && ! sharedUsersError" class="text-center"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-if="! sharedUsersLoaded && sharedUsersError">{{ $t('GLOBAL.ERROR') }} : {{ sharedUsersError }}</div>
    <div v-show="sharedUsersLoaded">
      <template v-if="! hideOwner && project.owner_id">
        <b>{{ $t('MANAGE.OWNER') }}</b>
        <v-card class="mt-2 pa-6 mb-6">
          <div>
            <user-avatar v-if="owner" :user="owner" :size="32" show-username class="mr-2"></user-avatar>
          </div>
          <div v-if="project.access_right == 'admin'" class="mt-2">
            <div class="text-medium-emphasis mb-2" v-html="$t('MANAGE.OWNER_DESC')"></div>
            <manage-change-owner :project="project" class="d-flex align-center column-gap-4" @change-owner="changeOwner"></manage-change-owner>
          </div>
        </v-card>
      </template>
      <div v-show="sharedUsers.length" class="mb-6">
        <div v-if="linkToManage" class="float-right">
          <router-link :to="`/manage?open=${project.id}`" class="pointer" @click="close()">{{ $t('SHARE.MANAGE_ACCESS_RIGHTS') }}</router-link>
        </div>
        <b>{{ $t('MANAGE.PROJECT_ACR') }}</b>
        <v-pagination v-if="companySharedUsers.length > sharedUsersPerPage" v-model="sharedUsersCurrentPage"
                      :length="Math.ceil(companySharedUsers.length / sharedUsersPerPage)" class="mt-2">
        </v-pagination>
        <manage-projects-project-acr-table :project="project" :users-list="displayedCompanySharedUsers" :hide-notifications="hideNotifications"
                                           :planning-access-rights-disabled="planningAccessRightsDisabled" :notifications-disabled="notificationsDisabled"
                                           @set-planning-user-rights="setPlanningUserRights($event.user, $event.newRight)"
                                           @toggle-notifications="toggleNotifications($event.user)">
          <template v-if="canInviteUsers" #footer>
            <div class="pa-6">
              <router-link to="/manage#company" @click="close()">{{ $t('MANAGE.INVITE_USER') }}</router-link>
            </div>
          </template>
        </manage-projects-project-acr-table>

        <!-- EXTERNAL USERS-->
        <div v-if="(canInviteExternalUsers && ! hideOwner) || externalSharedUsers.length" class="mt-6">
          <b>{{ $t('MANAGE.EXTERNAL_USERS') }}</b>
          <manage-projects-project-acr-table :project="project" :users-list="externalSharedUsers" :hide-notifications="hideNotifications"
                                             :planning-access-rights-disabled="planningAccessRightsDisabled" :notifications-disabled="notificationsDisabled"
                                             @set-planning-user-rights="setPlanningUserRights($event.user, $event.newRight)"
                                             @toggle-notifications="toggleNotifications($event.user)">
            <template v-if="canInviteExternalUsers && ! hideOwner" #footer>
              <div class="pa-6">
                {{ $t('MANAGE.ADD_ORGANIZATION_USER') }}
                <div class="mt-2 d-flex align-center">
                  <select-user v-model="selectedNewOrganizationUser" :excludes="sharedUsers.map(item => item.id)"
                               autocomplete="nope" class="mr-3" style="max-width: 360px">
                  </select-user>
                  <v-btn :disabled="! selectedNewOrganizationUser" rounded @click="addExternalUser">{{ $t('GLOBAL.ADD') }}</v-btn>
                </div>
              </div>
            </template>
          </manage-projects-project-acr-table>
        </div>
        <!-- END EXTERNAL USERS-->
      </div>

      <template v-if="project.privacy">
        <b><span>{{ $t('MANAGE.DEFAULT_PRIVACY_IS') }} :</span> {{ privacyTexts[project.privacy] }}</b>
        <v-card v-if="project.access_right == 'admin'" class="mt-2 pa-6">
          <table>
            <tr>
              <td class="pr-4 py-2">
                <v-btn color="primary" rounded size="large" block variant="outlined" @click="setPrivacy('company')">
                  {{ project.privacy == 'company' ? $t('MANAGE.RESET') : $t('MANAGE.SET') }} : {{ $filters.uppercase($t('ACR.COMPANY')) }}
                </v-btn>
              </td>
              <td class="py-2">{{ $t('MANAGE.PRIVACY_COMPANY_DESC1_1') }} <strong>{{ $t('MANAGE.PRIVACY_COMPANY_DESC1_2') }}</strong><br>{{ $t('MANAGE.PRIVACY_COMPANY_DESC2_1') }}</td>
            </tr>
            <tr>
              <td class="pr-4 py-2">
                <v-btn color="primary" rounded size="large" block variant="outlined" @click="setPrivacy('company visible')">
                  {{ project.privacy == 'company visible' ? $t('MANAGE.RESET') : $t('MANAGE.SET') }} : {{ $filters.uppercase($t('ACR.COMPANY_VISIBLE')) }}
                </v-btn>
              </td>
              <td class="py-2">
                {{ $t('MANAGE.PRIVACY_COMPANYVISIBLE_DESC1_1') }} <strong>{{ $t('MANAGE.PRIVACY_COMPANYVISIBLE_DESC1_2') }}</strong><br>
                {{ $t('MANAGE.PRIVACY_COMPANYVISIBLE_DESC2_1') }}
              </td>
            </tr>
            <tr>
              <td class="pr-4 py-2">
                <v-btn color="primary" rounded size="large" block variant="outlined" @click="setPrivacy('private')">
                  {{ project.privacy == 'private' ? $t('MANAGE.RESET') : $t('MANAGE.SET') }} : {{ $filters.uppercase($t('ACR.PRIVATE')) }}
                </v-btn>
              </td>
              <td class="py-2">{{ $t('MANAGE.PRIVACY_PRIVATE_DESC1_1') }} <strong>{{ $t('MANAGE.PRIVACY_PRIVATE_DESC1_2') }}</strong><br>{{ $t('MANAGE.PRIVACY_PRIVATE_DESC2_1') }}</td>
            </tr>
          </table>
        </v-card>
      </template>
    </div>
  </section>
</template>

<style>
  .pagination {
    margin: 0;
  }
</style>

<script>
  import ManageChangeOwner from '../partials/ManageChangeOwner.vue';
  import ManageProjectsProjectAcrTable from './ManageProjectsProjectAcrTable.vue';

  export default {
    components: {
      ManageChangeOwner,
      ManageProjectsProjectAcrTable,
    },
    props: {
      project: { type: Object, required: true },
      sharedUsersPerPage: { type: Number, default: 20 },
      linkToManage: { type: Boolean, default: false },
      hideOwner: { type: Boolean, default: false },
      hideNotifications: { type: Boolean, default: false },
    },
    emits: ['close'],
    data() {
      return {
        sharedUsers: [],
        sharedUsersCurrentPage: 1,
        planningAccessRightsDisabled: {},
        notificationsDisabled: {},
        privacyTexts: {
          company: this.$t('ACR.COMPANY'),
          'company visible': this.$t('ACR.COMPANY_VISIBLE'),
          private: this.$t('ACR.PRIVATE'),
        },
        selectedNewOrganizationUser: null,
      };
    },
    computed: {
      owner() {
        return this.$store.getters['users/getUserById'](this.project.owner_id);
      },
      companySharedUsers() {
        return this.sharedUsers.filter(item => this.projectCompanyUsersIds.includes(item.id));
      },
      externalSharedUsers() {
        return this.sharedUsers.filter(item => ! this.projectCompanyUsersIds.includes(item.id));
      },
      displayedCompanySharedUsers() {
        return this.companySharedUsers.slice().sort((a, b) => {
          if (a.firstname != b.firstname) return a.firstname < b.firstname ? -1 : 1;
          return a.lastname < b.lastname ? -1 : 1;
        }).slice(this.sharedUsersPerPage * (this.sharedUsersCurrentPage - 1), this.sharedUsersPerPage * this.sharedUsersCurrentPage);
      },
      canInviteUsers() {
        return this.userIsAdmin || ! this.$store.state.users.user.company;
      },
      canInviteExternalUsers() {
        return this.hasOrganization && this.project.access_right == 'admin';
      },
      projectCompanyUsersIds() {
        let companyusers = this.$store.getters['users/getCompanyUsers'];
        if (this.$store.state.users.user.organization) {
          const ownerId = this.project.owner_id;
          const company = this.$store.state.users.user.organization.getCompanies().find(organizationCompany => (organizationCompany.users || []).find(item => item.id == ownerId));
          if (company && company.users) companyusers = company.users;
        }
        return companyusers.map(item => item.id);
      },
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      hasOrganization() { return this.$store.state.users.accessRight.hasOrganization; },
    },
    created() {
      this.loadProjectUsers().catch((message) => {
        this.sharedUsersError = message || "";
      });
    },
    methods: {
      loadProjectUsers() {
        this.sharedUsersError = "";
        this.sharedUsersLoaded = false;
        return window.apiSrv.call('sharedplannings/planning', 'show', this.project.id).then((response) => {
          this.sharedUsers = response?.data?.shared_users || [];
          this.sharedUsersLoaded = true;
        });
      },
      userIsConsultOnly(user) {
        return (this.$store.getters['users/getUserById'](user && user.id) || {}).access_right == 'consult';
      },
      setPrivacy(newPrivacy) {
        this.$store.dispatch('ui/msgbox/open', {
          title: this.$t('MANAGE.SET_PRIVACY_TO', { privacy: this.privacyTexts[newPrivacy] }),
          body: this.$t('MANAGE.THIS_WILL_RESET_PRIVACY'),
          buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
        }).then(() => {
          this.sharedUsersLoaded = false;
          window.apiSrv.call('sharedplannings/planning', 'update', { id: this.project.id, privacy: newPrivacy }).then(() => {
            this.project.privacy = newPrivacy;
            this.loadProjectUsers();
          }).catch(() => {
            this.loadProjectUsers();
          });
        }).catch(() => {});
      },
      setPlanningUserRights(user, newRight) {
        if (newRight == 'none' && this.externalSharedUsers.includes(user)) {
          this.removeUserRight(user);
          return;
        }
        this.planningAccessRightsDisabled[user.id] = true;
        const oldValue = user.access_right;
        user.access_right = newRight;
        window.apiSrv.call(`sharedplannings/planning/${this.project.id}/user`, 'update', { id: user.id, access_right: newRight }).then(() => {
          this.planningAccessRightsDisabled[user.id] = false;
          this.$store.commit('eventbus/call', { event: 'update-user-tabs', param: { userId: user.id, projectId: this.project.id, data: { right: newRight } } });
        }).catch((message) => {
          user.access_right = oldValue;
          this.planningAccessRightsDisabled[user.id] = false;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : User right was not modified", body: message || "" });
        });
      },
      addExternalUser() {
        if (! this.selectedNewOrganizationUser) return;
        const user = this.selectedNewOrganizationUser;
        const newRight = 'consult';
        window.apiSrv.call(`sharedplannings/planning/${this.project.id}/user`, 'update', { id: user.id, access_right: newRight }).then(() => {
          this.sharedUsers.push(_.extend(angular.copy(user), { access_right: newRight }));
          this.$store.commit('eventbus/call', { event: 'update-user-tabs', param: { userId: user.id, projectId: this.project.id, data: { right: newRight } } });
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : User right was not modified", body: message || "" });
        });

        this.selectedNewOrganizationUser = null;
      },
      removeUserRight(user) {
        this.planningAccessRightsDisabled[user.id] = true;
        window.apiSrv.call(`sharedplannings/planning/${this.project.id}/user`, 'destroy', { id: user.id }).then(() => {
          const index = this.sharedUsers.indexOf(user);
          if (index > -1) this.sharedUsers.splice(index, 1);
          this.planningAccessRightsDisabled[user.id] = false;
          this.$store.commit('eventbus/call', { event: 'update-user-tabs', param: { userId: user.id, projectId: this.project.id, data: { right: 'none' } } });
        }).catch((message) => {
          this.planningAccessRightsDisabled[user.id] = false;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : User right was not modified", body: message || "" });
        });
      },
      changeOwner({ ownerId }) {
        const formerOwnerId = this.project.owner_id;
        this.project.owner_id = ownerId;
        this.loadProjectUsers().then(() => {
          this.$store.state.users.userPromise.then(() => {
            const me = this.sharedUsers.find(item => item.id == this.$store.state.users.user.id);
            if (! me) return;
            this.project.access_right = me.access_right;
          });
          this.$store.commit('eventbus/call', { event: 'update-user-tabs', param: { userId: ownerId, projectId: this.project.id, data: { right: "admin", special_role: "owner" } } });
          const formerOwner = this.sharedUsers.find(item => item.id == formerOwnerId);
          if (formerOwner) {
            const formerOwnerNewData = { right: formerOwner.access_right, special_role: formerOwner.special_role || "" };
            this.$store.commit('eventbus/call', { event: 'update-user-tabs', param: { userId: formerOwnerId, projectId: this.project.id, data: formerOwnerNewData } });
          }
          this.$store.dispatch('manage/loadProjects');
        });
      },
      toggleNotifications(user) {
        this.notificationsDisabled[user.id] = true;
        const oldValue = user.notifications;
        user.notifications = ! user.notifications;
        window.apiSrv.call(`sharedplannings/planning/${this.project.id}/user`, 'update', { id: user.id, notifications: user.notifications }).then(() => {
          this.notificationsDisabled[user.id] = false;
          this.$store.commit('eventbus/call', { event: 'update-user-tabs', param: { userId: user.id, projectId: this.project.id, data: { notifications: user.notifications } } });
        }).catch((message) => {
          user.notifications = oldValue;
          this.notificationsDisabled[user.id] = false;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : User notifications was not modified", body: message || "" });
        });
      },
      onUpdateUserRightsInProject({ userId, projectId, data }) {
        if (projectId != this.project.id) {
          console.error('incorrect user tab');
          return;
        }
        const userIndex = this.sharedUsers.findIndex(item => item.id == userId);
        if (userIndex == -1) return;
        const user = this.sharedUsers[userIndex];
        if (typeof data.special_role != 'undefined') this.sharedUsers[userIndex] = { ...user, special_role: data.special_role }; // the special role can be an empty string
        if (data.special_role == "owner") this.project.owner_id = userId;
        if (data.right) this.sharedUsers[userIndex] = { ...user, access_right: data.right };
        if (typeof data.notifications != 'undefined') this.sharedUsers[userIndex] = { ...user, notifications: data.notifications };
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
