<template>
  <v-card class="progress-dashboard-col">
    <div :class="`bg-${col.color}`" class="py-2 flex-center-center">
      <v-icon size="small" class="mr-2">{{ col.icon }}</v-icon>
      <span class="text-subtitle-1">{{ $t(col.label) }}</span>
    </div>
    <div class="px-2 mb-2 pt-3 light-scrollbar" style="overflow-y: auto; height: 100%">
      <v-fade-transition hide-on-leave>
        <transition-group v-if="sortedColElements.length" name="list-fade" tag="div" style="position: relative;">
          <v-row v-for="el in sortedColElements" :key="el.id" class="list-fade-item" style="margin:0 0 16px; page-break-inside: avoid; width: 100%; transform: none">
            <v-col :title="$filters.moment(el.getStartTime(), 'mediumDate')" cols="2" class="small" style="padding: 0">
              {{ $filters.moment(el.getStartTime(), 'mediumDateNoYear') }}
            </v-col>
            <v-col cols="8" style="padding: 0 2px">
              <planning-element-view :id="`el${el.id}`" :el="el" :title="el.display_title || ''"
                                     :style="{ cursor: canEditEl(el) ? 'pointer' : 'default' }" style="position: relative" disable-mini-element
                                     @select-color="selectColor(el)" @checklist-click="checklistClick(el, $event)" @progress-click="progressClick(el, $event)"
                                     @click.stop="elementClick($event, el)">
                <template v-if="canEditEl(el) && (col.id != 'finished' || el.isType('milestone'))" #hover-menu>
                  <div class="element-menu d-flex align-end" style="left: 100%; width: 25px" @click.stop>
                    <v-btn v-if="col.id != 'finished'" :title="$t('MONITORING_PROGRESS.VALIDATE_PROGRESS')" icon size="x-small" variant="outlined"
                           @click="validateProgress(el)">
                      <v-icon color="accent" size="large">far fa-check</v-icon>
                    </v-btn>
                    <v-btn v-else-if="el.isType('milestone')" :title="$t('MONITORING_PROGRESS.CANCEL_PROGRESS')" icon size="x-small" variant="outlined"
                           @click="cancelMilestoneProgress(el)">
                      <v-icon color="accent" size="large">far fa-undo</v-icon>
                    </v-btn>
                  </div>
                </template>
              </planning-element-view>
            </v-col>
            <v-col v-if="! el.isType('milestone')" :title="$filters.moment(el.getEndTime(), 'mediumDate')" cols="2" class="small text-right" style="padding: 0">
              {{ $filters.moment(el.getEndTime(), 'mediumDateNoYear') }}
            </v-col>
          </v-row>
        </transition-group>
      </v-fade-transition>
      <div v-if="! sortedColElements.length" class="text-medium-emphasis text-center">{{ $t('MONITORING_PROGRESS.NO_BUBBLE_AVAILABLE') }}</div>
    </div>
  </v-card>
</template>

<style>
  .progress-dashboard-col {
    width: 300px;
    max-width: 400px;
    margin-right: 15px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }
  .progress-dashboard-col:last-child {
    margin-right: 0;
  }
  .progress-dashboard-col .milestone-icon {
    position: static !important;
    display: inline-block !important;
    vertical-align: middle !important;
    margin: 0 !important;
    min-height: 0 !important;
  }
  .progress-dashboard-col .milestone-icon + * {
    display: inline-block !important;
    vertical-align: middle !important;
    max-width: 100%;
  }
  .progress-dashboard-col .milestone-icon .milestone-icon-dash {
    display: none !important;
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import PlanningElementView from '@/components/Planning/PlanningElementView';

  export default {
    components: {
      PlanningElementView,
    },
    props: {
      col: { type: Object, required: true }, // { id, color, icon, label, orderBy }
      colElements: { type: Array, default: () => [] },
    },
    emits: ['save-el'],
    data() {
      return {
      };
    },
    computed: {
      sortFunction() {
        if (this.col.orderBy == 'data.starttime') {
          return (a, b) => (a.getStartTime().isBefore(b.getStartTime()) ? -1 : 1);
        }
        if (this.col.orderBy == '-data.starttime') {
          return (a, b) => (b.getStartTime().isBefore(a.getStartTime()) ? -1 : 1);
        }
        if (this.col.orderBy == 'data.endtime') {
          return (a, b) => (a.getEndTime().isBefore(b.getEndTime()) ? -1 : 1);
        }
        if (this.col.orderBy == '-data.endtime') {
          return (a, b) => (b.getEndTime().isBefore(a.getEndTime()) ? -1 : 1);
        }
        return () => {};
      },
      sortedColElements() {
        return this.colElements.slice().sort(this.sortFunction);
      },
    },
    methods: {
      canEditEl(el) {
        const planning = el.getPlanning();
        return planning?.meta?.access_right && planning.meta.access_right != 'consult';
      },
      elementClick(event, el) {
        if (! this.canEditEl(el)) return;
        this.openElementDetails(el);
      },
      selectColor(el) {
        if (! this.canEditEl(el)) return;
        this.openColorPicker(el);
      },
      checklistClick(el, item) {
        if (! this.canEditEl(el)) return;
        const oldState = { checklist: angular.copy(el.getChecklist()), progress: el.getProgress() };
        item.checked = ! item.checked;
        el.updateChecklistProgress();
        this.$store.dispatch('multiprojects/formatEl', { el });
        this.$emit('save-el', { el, props: ['checklist', 'progress'], oldState });
      },
      progressClick(el, event) {
        if (! this.canEditEl(el)) return;
        const oldState = { progress: el.getProgress() };
        const fullWidth = $(event.currentTarget).width();
        if (! fullWidth) return;
        el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
        if (el.getProgress() != oldState.progress) this.saveProgress(el, oldState);
      },
      validateProgress(el) {
        if (! this.canEditEl(el)) return;
        const oldState = { progress: el.getProgress() };
        el.setProgress(100);
        if (el.getProgress() != oldState.progress) this.saveProgress(el, oldState);
      },
      cancelMilestoneProgress(el) {
        if (! el.isType('milestone') || ! this.canEditEl(el)) return;
        const oldState = { progress: el.getProgress() };
        el.setProgress(null);
        if (el.getProgress() != oldState.progress) this.saveProgress(el, oldState);
      },
      saveProgress(el, oldState) {
        this.$store.dispatch('multiprojects/formatEl', { el });
        this.$emit('save-el', { el, props: ['progress'], oldState });
      },
      ...mapActions('ui/dashboards', ['openElementDetails', 'openColorPicker']),
    },
  };
</script>
