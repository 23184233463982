class WorkingView {
  constructor(data) {
    this.id = data.id;
    this.title = data.title || "";
    this.planning_id = data.planning_id;
    this.config = {
      timeline: ! Array.isArray(data.config.timeline) && data.config.timeline || {}, // convert [] to {} (correct php saving {} as [])
      lanes: data.config.lanes || [],
      elementsColors: data.config.elementsColors || [],
      elementsIcons: data.config.elementsIcons || [],
      elementsUsers: ! Array.isArray(data.config.elementsUsers) && data.config.elementsUsers || {},
      selectedDisplay: ! Array.isArray(data.config.selectedDisplay) && data.config.selectedDisplay || {},
    };
  }

  /** GET */
  getElementsColors() {
    return this.config.elementsColors;
  }

  getElementsIcons() {
    return this.config.elementsIcons;
  }

  getLanes() {
    return this.config.lanes;
  }

  getSelectedDisplay() {
    return this.config.selectedDisplay;
  }

  getTimeline() {
    return this.config.timeline;
  }

  getElementsUsers() {
    return this.config.elementsUsers;
  }

  /** SAVE */
  save() {
    if (! this.id) return Promise.reject(new Error("unsaved view"));
    return window.apiSrv.call('views', 'update', this);
  }

  /** DELETE */
  delete() {
    if (! this.id) return Promise.reject(new Error("unsaved view"));
    return window.apiSrv.call('views', 'destroy', this);
  }
}

export default WorkingView;
