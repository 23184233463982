<template>
  <v-card>
    <div v-show="! dataLoaded.projects && ! dataLoaded.projects_error" class="text-center pa-12"><v-icon size="large">far fa-spinner fa-spin</v-icon></div>
    <div v-if="! dataLoaded.projects && dataLoaded.projects_error">{{ dataLoaded.projects_error }}</div>
    <div v-show="dataLoaded.projects && ! projects.length">{{ $t('MANAGE.NO_PROJECT') }}</div>
    <div v-show="dataLoaded.projects && projects.length">
      <div class="table-responsive">
        <table class="table">
          <thead class="bg-lightgrey">
            <tr>
              <th>{{ $t('MANAGE.PROJECT_NAME') }}</th>
              <th>{{ $t('MANAGE.OWNER') }}</th>
              <th>{{ $t('MANAGE.CREATION_DATE') }}</th>
              <th>{{ $t('MANAGE.LAST_MODIFICATION') }}</th>
              <th class="pr-6">{{ $t('MANAGE.DEFAULT_PRIVACY') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="project in sortedProjects" :key="project.id" class="pointer" @click="openProject(project)">
              <td class="pl-6">{{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}</td>
              <td>{{ translateApiText(project.owner_name) }}</td>
              <td>{{ $filters.moment(project.date_of_creation, 'datetime') }}</td>
              <td>{{ $filters.moment(project.date_of_modification, 'calendar') }} {{ $t('GLOBAL.BY') }} {{ translateApiText(project.last_modification_user_name) }}</td>
              <td>{{ $filters.ucfirst(privacyTexts[project.privacy]) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  export default {
    data() {
      return {
        privacyTexts: {
          company: this.$t('ACR.COMPANY'),
          'company visible': this.$t('ACR.COMPANY_VISIBLE'),
          private: this.$t('ACR.PRIVATE'),
        },
      };
    },
    computed: {
      sortedProjects() {
        return _.sortBy(this.projects, 'date_of_modification').reverse();
      },
      ...mapState('manage', ['dataLoaded', 'projects']),
    },
    methods: {
      translateApiText(text) {
        return text && text.substr(0, 4) == "API." ? this.$t(text) : text;
      },
      ...mapActions('manage', ['openProject']),
    },
  };
</script>
