export default {
  namespaced: true,
  state: {
    byUser: {
      // XXX: [{ id, icon, title, start, end, occupancy, description }],
    },
  },
  mutations: {
    set(state, { userId, holidays }) {
      state.byUser[userId] = _.sortBy(holidays || [], 'start');
    },
  },
  getters: {
    icons: (state, getters, rootState) => {
      return [
        { id: 'holiday', color: 'white', label: rootState.lang.i18n.global.t('HR.VACATION'), name: 'far fa-umbrella-beach' },
        { id: 'training', color: 'white', label: rootState.lang.i18n.global.t('HR.TRAINING'), name: 'far fa-users-class' },
        { id: 'parttime', color: 'white', label: rootState.lang.i18n.global.t('HR.PART_TIME'), name: 'far fa-hourglass-half' },
      ];
    },
  },
  actions: {
    async load({ commit }) {
      window.apiSrv.call('useroptions/holidays/all', 'index').then((response) => {
        Object.entries(response?.data?.useroptions?.values || {}).forEach(([userId, value]) => {
          commit('set', { userId, holidays: value });
        });
      });
    },
    save({ state, dispatch }, userId) {
      return window.apiSrv.call('useroptions', 'update', { id: 'holidays', user_id: userId, value: state.byUser[userId] }).catch((message) => {
        if (message) dispatch('ui/msgbox/open', { title: "Error : Holidays were not modified", body: message || "" }, { root: true });
        throw message;
      });
    },
  },
};
