<template>
  <autocomplete-filter v-model="selectedGroupUsers" :items="groupUsers" item-title="title" item-value="group_id"
                       :label="$t('GLOBAL.GROUPS')" :placeholder="selectedGroupUsers.length ? '' : $t('MULTIPROJECTS.NO_GROUP_SELECTED')"
                       :prepend-inner-icon="smallScreen ? null : 'far fa-users'" multiple :menu-props="{ location: 'bottom' }"
                       @update:model-value="update">
    <template #prepend-item>
      <v-list-item @click.stop>
        <v-switch v-model="groupsOptions.hideUsers" :label="$t('MULTIPROJECTS.HIDE_USERS')" @update:model-value="update">
        </v-switch>
      </v-list-item>
      <filter-toggle :selected-ids="selectedGroupUsers" :sublist-ids="groupUserIds"
                     @update="selectedGroupUsers = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_GROUPS_USERS') }} ({{ groupUserIds.length }})
      </filter-toggle>
      <v-divider></v-divider>
    </template>
    <template #selection="{ item: { raw: item }, index }">
      <span v-if="selectedGroupUsers.length == 1" class="text-ellipsis">{{ item.title }}</span>
      <span v-else-if="selectedGroupUsers.length == groupUsers.length && index === 0" class="text-ellipsis">{{ $t('MULTIPROJECTS.ALL_GROUPS_USERS') }} ({{ groupUsers.length }})</span>
      <span v-else-if="index === 0" class="text-ellipsis">{{ selectedGroupUsers.length }}/{{ groupUsers.length }}</span>
    </template>
  </autocomplete-filter>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    emits: ['update'],
    data() {
      return {
      };
    },
    computed: {
      selectedGroupUsers: {
        get() {
          return this.$store.state.multiprojects.selectedCompanyGroups;
        },
        set(newVal) {
          this.$store.state.multiprojects.selectedCompanyGroups.splice(0, this.$store.state.multiprojects.selectedCompanyGroups.length, ...newVal);
        },
      },
      groupUsers() {
        return (this.$store.getters['users/groups/getCompanyGroups'] || []).map(item => ({
          group_id: item.id,
          title: item.title,
          icon: 'far fa-users',
        }));
      },
      groupUserIds() {
        return this.groupUsers.map(item => item.group_id);
      },
      smallScreen() { return this.$vuetify.display.mdAndDown; },
      ...mapState('multiprojects', ['groupsOptions']),
    },
    methods: {
      update() {
        this.$emit('update');
      },
    },
  };
</script>
