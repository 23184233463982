import ClientCustomComponent from '@/client_customs/ClientCustomComponent';
import Modal from '@/components/Reusables/Modal';
import MsgBox from '@/components/MsgBox/MsgBox.vue';
import FieldLabel from '@/components/Reusables/FieldLabel';
import DateField from '@/components/Reusables/DateField';
import TimeField from '@/components/Reusables/TimeField';
import BtnToggleField from '@/components/Reusables/BtnToggleField';
import SelectUser from '@/components/Reusables/SelectUser';
import FilterToggle from '@/components/Reusables/FilterToggle';
import ListPagination from '@/components/Reusables/ListPagination';
import UserAvatar from '@/components/Reusables/UserAvatar';

/* GLOBAL COMPONENTS */
export default {
  ClientCustomComponent: ClientCustomComponent.default || ClientCustomComponent,
  Modal: Modal.default || Modal,
  MsgBox: MsgBox.default || MsgBox,
  FieldLabel: FieldLabel.default || FieldLabel,
  DateField: DateField.default || DateField,
  TimeField: TimeField.default || TimeField,
  BtnToggleField: BtnToggleField.default || BtnToggleField,
  SelectUser: SelectUser.default || SelectUser,
  FilterToggle: FilterToggle.default || FilterToggle,
  ListPagination: ListPagination.default || ListPagination,
  UserAvatar: UserAvatar.default || UserAvatar,
};
