<template>
  <v-btn v-if="! isMilestone" :disabled="! initialState.icon" block size="small" rounded variant="outlined" color="errorred"
         @mouseover="updateIcon('')" @mouseout="resetIcon()" @click="applySelection()">
    {{ $t('COLORPICKER.REMOVE_ICON') }}
  </v-btn>
  <v-row class="ma-0 pt-2">
    <v-col v-for="iconCode in displayIconCodes" :key="iconCode" :cols="colorPickerIconCol" class="text-center pt-1 px-1 pb-2" style="overflow: hidden">
      <v-icon :color="planningIcons[iconCode].color" class="pointer" @mouseover="updateIcon(iconCode)" @mouseout="resetIcon()" @click="applySelection()">
        {{ planningIcons[iconCode].name }}
      </v-icon>
      <div v-if="! disableIconRenaming" style="margin-top: -2px">
        <input v-model="planningIcons[iconCode].label" :placeholder="$t(`ICONS.${$filters.uppercase(iconCode)}`)"
               class="input-underlined color-picker-label-input" spellcheck="false">
      </div>
      <div v-else :title="planningIcons[iconCode].label || $t(`ICONS.${$filters.uppercase(iconCode)}`)" class="color-picker-label">
        {{ planningIcons[iconCode].label || $t(`ICONS.${$filters.uppercase(iconCode)}`) }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import clientConfig from '@/client_customs/config';

  export default {
    props: {
      icon: { required: true }, // v-model
      target: { type: Object, required: true }, // element or lane or kanbanHeader
      planning: { type: Object, required: true },
      disableRenaming: { type: Boolean, default: false },
    },
    emits: ['update:icon', 'selected'],
    data() {
      return {
        el: this.target.isType?.('macro', 'milestone', 'task') ? this.target : null,
        disableIconRenaming: this.disableRenaming || clientConfig.icons?.disableRenaming,
        planningIcons: this.planning.config.icons,
        colorPickerIconCol: clientConfig.icons?.colorPickerIconCol || 2,
      };
    },
    computed: {
      isMilestone() {
        return this.el && this.el.isType('milestone');
      },
      displayIconCodes() {
        return Object.keys(this.planningIcons);
      },
      initialState() {
        return {
          icon: this.target.getIconId?.(),
        };
      },
    },
    methods: {
      updateIcon(icon) {
        this.$emit('update:icon', icon);
      },
      resetIcon() {
        this.updateIcon(this.initialState.icon);
      },
      applySelection() {
        this.target.setIconId(this.icon);
        this.$emit('selected');
      },
    },
  };
</script>
