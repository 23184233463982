<style lang="scss">
  .fullscreen {
    .v-main {
      --v-layout-top: 0px !important;
    }

    #topbar {
      display: none;
    }
  }

  .v-main {
    transition: none !important;
  }
</style>

<script>
  const fullscreenKeys = ['f11', 'ctrl+command+f'];

  export default {
    render() { return null; },
    computed: {
      fullscreenMode: {
        get() { return this.$store.state.ui.fullscreenMode; },
        set(newVal) { this.$store.state.ui.fullscreenMode = newVal; },
      },
    },
    mounted() {
      window.Mousetrap.bind(fullscreenKeys, (event) => {
        event.preventDefault();
        this.fullscreenMode = ! this.fullscreenMode;
      });
      document.addEventListener('fullscreenchange', this.onFullscreenChange);
    },
    beforeUnmount() {
      window.Mousetrap.unbind(fullscreenKeys);
      document.removeEventListener('fullscreenchange', this.onFullscreenChange);
    },
    watch: {
      fullscreenMode(newVal) {
        if (! newVal) {
          if (document.fullscreenElement) document.exitFullscreen();
          document.body.classList.remove('fullscreen');
        } else if (document.documentElement.requestFullscreen) {
          // set full screen if available
          document.documentElement.requestFullscreen();
          document.body.classList.add('fullscreen');
        }

        window.scrollTo(0, 1); // force a scroll event to update planning drawer position
        window.scrollTo(0, 0); // scroll top to reset timeline position
      },
    },
    methods: {
      onFullscreenChange() {
        this.fullscreenMode = !! document.fullscreenElement;
      },
    },
  };
</script>
