class Group {
  constructor(data = {}) {
    this.id = data.id;
    this.title = data.title || "";
    this.special_name = data.special_name || "";
    this.company_id = data.company_id || window.app.config.globalProperties.$store.state.users.user.company_id;
    this.users = data.users || [];
  }

  addUser(userData) {
    const user = window.app.config.globalProperties.$store.getters['users/getUserById'](userData.id);
    if (! user) return Promise.reject('user not found in company');
    const index = this.users.findIndex(item => item.id == user.id);
    if (index != -1) return Promise.reject('user already in group');
    return window.apiSrv.call(`groups/${this.id}/users/${user.id}`, 'store', null).then(() => {
      this.users.push(user);
      return user;
    });
  }

  removeUser(userData) {
    const userId = userData.id;
    const index = this.users.findIndex(item => item.id == userId);
    if (index == -1) { return Promise.reject(`user ${userId} not in group ${this.id}`); }
    return window.apiSrv.call(`groups/${this.id}/users`, 'destroy', { id: userId }).then(() => {
      this.users.splice(index, 1);
    });
  }

  getCompanyName() {
    const store = window.app.config.globalProperties.$store;
    let companies = [store.state.users.user.company];
    if (store.state.users.accessRight.hasOrganization && store.state.users.user.organization) {
      companies = store.state.users.user.organization.getCompanies();
    }
    return (companies.find(item => item.id == this.company_id) || {}).name;
  }
}

export default Group;
