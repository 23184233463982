<template>
  <v-btn class="tour-subprojects mt-4" size="small" rounded v-bind="$attrs" @click="openNewSubproject()">
    <span class="d-inline-flex"><v-icon size="large" class="mr-1">far fa-folder-plus</v-icon> {{ $t('PLANNING.NEW_SUBPROJECT') }}</span>
  </v-btn>

  <!-- MODALS -->
  <modal v-if="newSubprojectIsOpened" @close="newSubprojectIsOpened = false">
    <new-subproject :planning="planning" @close="newSubprojectIsOpened = false"></new-subproject>
  </modal>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import NewSubproject from '@/components/Subprojects/NewSubproject';

  export default {
    components: {
      NewSubproject,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        newSubprojectIsOpened: false,
      };
    },
    computed: {
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    methods: {
      openNewSubproject() {
        if (! this.isPremium) {
          this.$store.dispatch('ui/msgbox/open', { title: this.$t('PREMIUM.PREMIUM_FEATURE'), body: clientConfig.hidePremiumFeatures ? '' : this.$t('PREMIUM.SUBPROJECT_IS_PREMIUM') });
          return;
        }
        this.newSubprojectIsOpened = true;
      },
    },
  };
</script>
