<template>
  <div class="element-details">
    <!-- TITLE -->
    <teleport to="#planning-drawer-title">
      <element-details-breadcrumb v-if="options.breadcrumb" :element="element"></element-details-breadcrumb>
      <div v-if="el.hasIcon()" class="bg-white rounded-circle mr-2 flex-center-center" style="padding: 6px">
        <v-icon :color="el.getIcon().color" size="x-small">{{ el.getIcon().name }}</v-icon>
      </div>
      {{ elTitle }}
    </teleport>

    <!-- MENU -->
    <div :class="{ invisible: ! menu.show }" class="element-details-menu d-flex justify-center" style="pointer-events: none" v-scroll:#planning-drawer-scroll-container="onScroll">
      <div class="d-inline-flex bg-secondary rounded-b elevation-2 px-3" style="pointer-events: all">
        <v-tooltip v-for="card in elementCards" :key="card.id" location="bottom">
          <template #activator="{ props }">
            <v-btn-icon size="x-small" v-bind="props" @click="displayCard(card.id)">
              <v-icon size="large">{{ card.icon }}</v-icon>
            </v-btn-icon>
          </template>
          {{ card.label }}
        </v-tooltip>
        <v-tooltip v-for="customField in customFieldsInMenu" :key="customField.dataField" location="bottom">
          <template #activator="{ props }">
            <v-btn-icon size="x-small" v-bind="props" @click="displayCardCustomField(customField)">
              <v-icon size="large">{{ customField.icon }}</v-icon>
            </v-btn-icon>
          </template>
          {{ customField.label }}
        </v-tooltip>

        <v-divider vertical class="my-2"></v-divider>

        <v-tooltip v-if="options.canDuplicateEl" location="bottom">
          <template #activator="{ props }">
            <v-btn-icon size="x-small" v-bind="props" @click="close('duplicate')">
              <v-icon size="large">far fa-copy</v-icon>
            </v-btn-icon>
          </template>
          {{ $t('PLANNING.DUPLICATE') }}
        </v-tooltip>
        <v-menu :close-on-content-click="false" :min-width="200" location="bottom end" offset="4">
          <template #activator="{ props: onMenu }">
            <v-tooltip location="bottom">
              <template #activator="{ props: onTooltip }">
                <v-btn-icon size="x-small" v-bind="mergeProps(onMenu, onTooltip)">
                  <v-icon size="large">far fa-cog</v-icon>
                </v-btn-icon>
              </template>
              {{ $t('ELEMENT-DETAIL.LANE_PRIORITY_DELETE') }}
            </v-tooltip>
          </template>
          <element-details-positioning-menu :el="el" :options="options" @close="close($event)"></element-details-positioning-menu>
        </v-menu>
      </div>
    </div>

    <!-- CONTENT -->
    <div class="element-details-content">
      <div class="small text-medium-emphasis text-center mb-1">{{ $t('ELEMENT-DETAIL.CHANGES_ARE_SAVED_AUTOMATICALLY') }}</div>
      <template v-for="card in elementCards" :key="card.id">
        <div :id="`edcard-${card.id}`" class="element-details-card">
          <h3 class="text-primary d-inline-flex align-center pointer" @click="display[card.id] = ! display[card.id]">
            <v-icon size="small" style="width: 32px">{{ card.icon }}</v-icon>
            {{ card.label }}
            <v-icon v-if="! display[card.id]" end class="align-self-end">far fa-angle-right</v-icon>
          </h3>
          <v-expand-transition>
            <div v-show="display[card.id]" class="element-details-card-content">
              <element-details-header v-if="card.id == 'header'" :el="el" @close="close"></element-details-header>
              <element-details-checklist v-else-if="card.id == 'checklist'" :el="el" @add-user="addUser($event)" @update-progress="updateProgress"></element-details-checklist>
              <element-details-progress v-else-if="card.id == 'progress'" :el="el"></element-details-progress>
              <element-details-users v-else-if="card.id == 'users'" ref="ElementDetailsUsers" :el="el"></element-details-users>
              <element-details-links v-else-if="card.id == 'links'" :el="el"></element-details-links>
              <element-details-alerts v-else-if="card.id == 'alerts'" :el="el"></element-details-alerts>
              <element-details-budget v-else-if="card.id == 'budgets'" :el="el"></element-details-budget>
              <element-details-comments v-else-if="card.id == 'comments'" :el="el"></element-details-comments>
            </div>
          </v-expand-transition>
        </div>
      </template>
      <div v-for="customField in customFields" v-show="customField.active" :id="`edcard-cf_${customField.dataField}`" :key="`cf_${customField.dataField}`" class="element-details-card">
        <client-custom-component :name="customField.component" :el="el" :custom-field="customField"></client-custom-component>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .element-details-content {
    margin-top: 4px;
    margin-right: 4px;
    padding: 0px 8px 8px 4px;

    .element-details-card {
      position: relative;
      margin-top: 16px;

      .element-details-card-content {
        padding-left: 32px;
        padding-bottom: 16px;
      }
    }
  }

  .element-details {
    .element-details-menu {
      position: sticky;
      top: 0;
      z-index: 101;
      opacity: 1;
      transform: translateY(0);
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      &.invisible {
        opacity: 0;
        transform: translateY(-100%);
      }
    }
  }

  /* Subcomponent styles */
  .element-details-card-header {
    position: absolute;
    top: -6px;
    right: 0;
    display: flex;
    align-items: center;
  }
</style>

<script>
  import { mergeProps } from 'vue';
  import clientConfig from '@/client_customs/config';
  import { useAlertBeforeUnload } from './partials/alertBeforeUnload';
  import ElementDetailsBreadcrumb from './ElementDetailsBreadcrumb.vue';
  import ElementDetailsHeader from './ElementDetailsHeader.vue';
  import ElementDetailsProgress from './ElementDetailsProgress.vue';
  import ElementDetailsChecklist from './ElementDetailsChecklist.vue';
  import ElementDetailsUsers from './ElementDetailsUsers.vue';
  import ElementDetailsLinks from './ElementDetailsLinks.vue';
  import ElementDetailsAlerts from './ElementDetailsAlerts.vue';
  import ElementDetailsBudget from './ElementDetailsBudget.vue';
  import ElementDetailsComments from './ElementDetailsComments.vue';
  import ElementDetailsPositioningMenu from './ElementDetailsPositioningMenu.vue';

  export default {
    components: {
      ElementDetailsBreadcrumb,
      ElementDetailsHeader,
      ElementDetailsProgress,
      ElementDetailsChecklist,
      ElementDetailsUsers,
      ElementDetailsLinks,
      ElementDetailsAlerts,
      ElementDetailsBudget,
      ElementDetailsComments,
      ElementDetailsPositioningMenu,
    },
    props: {
      element: { type: Object, required: true },
      options: { type: Object, default: () => ({}) },
    },
    emits: ['close'],
    setup(props, { emit }) {
      useAlertBeforeUnload(props.element, () => emit('close'));
    },
    data() {
      let elementCards = clientConfig.elements?.elementCards || [
        { id: 'header', icon: 'far fa-info-circle', label: this.$t('ELEMENT-DETAIL.HEADER') },
        { id: 'checklist', icon: 'far fa-tasks', label: this.$t('ELEMENT-DETAIL.CHECKLIST') },
        { id: 'progress', icon: 'far fa-long-arrow-alt-right', label: this.$t('ELEMENT-DETAIL.PROGRESS') },
        { id: 'users', icon: 'far fa-user', label: this.$t('ELEMENT-DETAIL.USERS') },
        { id: 'links', icon: 'far fa-paperclip', label: this.$t('ELEMENT-DETAIL.ATTACHMENTS') },
        { id: 'alerts', icon: 'far fa-alarm-exclamation', label: this.$t('ELEMENT-DETAIL.ALERTS') },
        { id: 'budgets', icon: 'far fa-euro-sign', label: this.$t('ELEMENT-DETAIL.BUDGET') },
        { id: 'comments', icon: 'far fa-comment-dots', label: this.$t('ELEMENT-DETAIL.COMMENTS') },
      ];
      if (! this.element.isType('task')) elementCards = elementCards.filter(card => ! ['checklist', 'progress', 'budgets'].includes(card.id));

      return {
        el: angular.copy(this.element),
        elementCards,
        display: {
          // eslint-disable-next-line object-property-newline
          header: true, checklist: false, progress: false, users: false, links: false, alerts: false, budgets: false, comments: true,
          ...clientConfig.elementDetails?.display,
        },
        menu: {
          scrollTop: 0,
          show: true,
          clickTime: 0,
        },
        initialStateEl: angular.copy(this.element),
        customFields: clientConfig.elements?.customFields,
      };
    },
    computed: {
      isMilestone() {
        return this.element.isType('milestone');
      },
      elTitle() {
        const defaultTitle = this.isMilestone ? this.$t('PLANNING.MILESTONE') : this.$t('PLANNING.NEW_BUBBLE');
        return this.el.getTitle() || defaultTitle;
      },
      customFieldsInMenu() {
        return this.customFields?.filter(customField => customField.inMenu);
      },
    },
    watch: {
      el: {
        handler: _.debounce(function () {
          this.element.reset(this.el.getAll());
          this.element.alerts = this.el.alerts;
        }, 100),
        deep: true,
      },
    },
    created() {
      this.initDisplay();
      this.$store.commit('eventbus/register', { event: 'project-updated', listener: 'element-details', cb: this.onProjectUpdate });
    },
    beforeUnmount() {
      this.$store.commit('eventbus/remove', { event: 'project-updated', listener: 'element-details' });
    },
    methods: {
      close(reason) {
        this.$emit('close', reason);
      },
      onScroll(e) {
        const scrollDiff = e.target.scrollTop - this.menu.scrollTop;
        if (scrollDiff > 0 || scrollDiff < -70) {
          if (Date.now() > this.menu.clickTime + 1000) this.menu.show = scrollDiff < 0; // do not show menu if scroll is due to click on menu
          if (e.target.scrollTop < 100) this.menu.show = true; // always show menu when close to top
          this.menu.scrollTop = e.target.scrollTop;
        }
      },
      onProjectUpdate(modifications) { // project updated in background by another user or instance
        if (! modifications.elements[this.element.id]) return;
        const updatedElement = this.element;
        if (! updatedElement.getPlanning().elements.find(item => item.id == updatedElement.id)) {
          this.close();
          return;
        }
        if (angular.equals(updatedElement.getAll(), this.initialStateEl.getAll())) {
          updatedElement.set(this.el.getAll());
          updatedElement.modificationUser = null;
        } else {
          this.initialStateEl = angular.copy(updatedElement);
          this.el = angular.copy(updatedElement);
        }
      },
      initDisplay() {
        if (this.el.getProgress() || this.el.getProcessStep()) this.display.progress = true;
        if (this.el.getChecklist() && this.el.getChecklist().length) this.display.checklist = true;
        if (this.el.getUsers() && this.el.getUsers().length) this.display.users = true;
        if (this.el.getLinks() && this.el.getLinks().length) this.display.links = true;
        if (this.el.getBudgets() && this.el.getBudgets().some(budget => budget.amount || budget.amount_inprogress)) this.display.budgets = true;
        if (this.el.alerts && this.el.alerts.length) this.display.alerts = true;
        if (! this.display.progress && ! this.display.checklist && ! this.display.users && ! this.display.links && ! this.display.budgets && ! this.display.alerts) this.display.checklist = true;
      },
      displayCard(prop) {
        this.display[prop] = true;
        const displayNode = document.getElementById(`edcard-${prop}`);
        if (displayNode && displayNode.scrollIntoView) {
          this.menu.show = false;
          this.menu.clickTime = Date.now();
          setTimeout(() => displayNode.scrollIntoView({ behavior: 'smooth' }));
        }
      },
      displayCardCustomField(customField) {
        customField.active = true;
        const displayNode = document.getElementById(`edcard-cf_${customField.dataField}`);
        if (displayNode && displayNode.scrollIntoView) {
          this.menu.show = false;
          this.menu.clickTime = Date.now();
          setTimeout(() => displayNode.scrollIntoView({ behavior: 'smooth' }));
        }
      },
      addUser(user) {
        if (this.$refs.ElementDetailsUsers?.length) {
          this.$refs.ElementDetailsUsers[0].addUser(user);
          this.display.users = true;
        }
      },
      updateProgress() {
        this.el.updateChecklistProgress();
        if (this.el.getProgress()) this.display.progress = true;
      },
      mergeProps,
    },
  };
</script>
