import vuetifyDefaultLang from 'vuetify/lib/locale/fr.mjs';

export default {
  $vuetify: {
    ...vuetifyDefaultLang,
    dataIterator: {
      noResultsText: 'Aucune correspondance trouvée',
      loadingText: "Chargement de l'élément...",
    },
    dataFooter: {
      itemsPerPageText: 'Élements par page:',
      itemsPerPageAll: 'Tous',
      nextPage: 'Page suivante',
      prevPage: 'Page précédente',
      firstPage: 'Première page',
      lastPage: 'Dernière page',
      pageText: '{0}-{1} sur {2}',
    },
    noDataText: 'Aucune donnée trouvée',
  },
};
