function getInitialState() {
  return {
    openedElement: null,
    openedColorPickerElement: null,
  };
}

const invariantFields = ['id', 'config', 'dependencies'];

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset(state, values) {
      _.extend(state, getInitialState(), values);
    },
    openElement(state, el = null) {
      state.openedElement = el;
    },
    openColorPickerElement(state, el = null) {
      state.openedColorPickerElement = el;
    },
  },
  actions: {
    openElementDetails({ commit, rootGetters }, el) {
      commit('openElement', rootGetters['multiprojects/getOriginalPlanningEl'](el));
    },
    closeElementDetails({ state, commit, dispatch }, reason) {
      if (! state.openedElement) return;
      if (reason == 'delete') {
        dispatch('deleteElement', state.openedElement.dashboard_el);
      } else {
        dispatch('saveEl', { el: state.openedElement.dashboard_el, newState: state.openedElement.getAll() });
      }
      commit('openElement', null);
    },
    openColorPicker({ commit, rootGetters }, el) {
      commit('openColorPickerElement', rootGetters['multiprojects/getOriginalPlanningEl'](el));
    },
    closeColorPicker({ state, commit, dispatch }) {
      if (! state.openedColorPickerElement) return;
      dispatch('saveEl', { el: state.openedColorPickerElement.dashboard_el, newState: state.openedColorPickerElement.getAll() });
      commit('openColorPickerElement', null);
    },
    saveEl({ dispatch, rootState, rootGetters }, { el, oldState: oldStateParam, newState: newStateParam, props = [] }) {
      const $t = rootState.lang.i18n.global.t;
      // el is dashboardEl
      // use oldState if el has been modified, or newState  otherwise
      let oldState = oldStateParam;
      let newState = newStateParam;
      if (newState && ! oldState) {
        // apply new state
        newState = _.omit(newState, invariantFields);
        oldState = _.omit(el.getAll(), invariantFields);
        el.set(newState); // set rather than reset does not modify el if not needed (prevents reactive workloads/other_computed update)
      }
      newState = _.omit(el.getAll(), invariantFields);

      const originalPlanningEl = rootGetters['multiprojects/getOriginalPlanningEl'](el);
      if (! props.length) {
        let warningMsgBox;
        Object.keys(newState).forEach((prop) => {
          if (invariantFields.includes(prop)) return;
          if (angular.equals(newState[prop], oldState[prop])) return;
          if (['starttime', 'endtime'].includes(prop) && el.originalHasDependencies) {
            warningMsgBox = { title: $t('MONITORING_PROGRESS.ERROR_DATES'), body: $t('MONITORING_PROGRESS.ERROR_DATES_DESC') };
            el.setStartTime(oldState.starttime);
            el.setEndTime(oldState.endtime);
            return;
          }
          props.push(prop);
        });
        if (warningMsgBox) dispatch('ui/msgbox/open', warningMsgBox, { root: true });
      }
      if (! props.length) return Promise.resolve('nothing to save');
      const originalPlanning = rootGetters['multiprojects/getPlanningById'](el.project_id);
      // if (! originalPlanning) originalPlanning = el.getPlanning(); // fallback when plannings are not all loaded (eg kanban in HomeUser)
      return originalPlanning.saveElement(originalPlanningEl, oldState, { props }).then((contentPatch) => {
        if (contentPatch) window.notificationsSrv.callEvent('projectSaved', { contentPatch, planning_id: originalPlanning.id, planning_title: originalPlanning.getTitle() });
      }).catch((message) => {
        el.set(oldState);
        if (message) dispatch('ui/msgbox/open', { title: $t('MONITORING_PROGRESS.ERROR_NOT_MODIFIED'), body: message || "" }, { root: true });
        return 'nothing to save';
      });
    },
    deleteElement({ state, commit, dispatch, rootState, rootGetters }, el) {
      const $t = rootState.lang.i18n.global.t;
      if (state.openedElement && state.openedElement.id == el.id) commit('openElement', null);
      const originalPlanning = rootGetters['multiprojects/getPlanningById'](el.project_id);
      // if (! originalPlanning) originalPlanning = el.getPlanning(); // fallback when plannings are not all loaded (eg kanban in HomeUser)
      const originalPlanningEl = rootGetters['multiprojects/getOriginalPlanningEl'](el);
      originalPlanning.saveElement(originalPlanningEl, originalPlanningEl.getAll(), { forDeletion: true }).then((contentPatch) => {
        if (contentPatch) {
          const dashboardPlanning = el.getPlanning();
          if (dashboardPlanning) {
            const index = dashboardPlanning.elements.findIndex(element => element.id == el.id);
            if (index > -1) dashboardPlanning.elements.splice(index, 1);
          }
          window.notificationsSrv.callEvent('projectSaved', { contentPatch, planning_id: originalPlanning.id, planning_title: originalPlanning.getTitle() });
        }
      }).catch((message) => {
        if (message) dispatch('ui/msgbox/open', { title: $t('MONITORING_PROGRESS.ERROR_NOT_MODIFIED'), body: message || "" }, { root: true });
      });
    },
  },
};
