<template>
  <v-container>
    <div v-if="! meetingId" class="pa-4">
      <!-- NO MEETING ATTACHED -->
      <template v-if="hasMeeting">
        <div>
          <div class="mb-3"><b class="text-primary">{{ $t('MEETING.CREATE_NEW_MEETING') }}</b></div>
          <v-btn v-if="canCreateMeeting" class="ml-4" color="primary" rounded @click="createMeeting()">{{ $t('MEETING.NEW_MEETING') }}</v-btn>
          <span v-else class="ml-4">{{ $t('MEETING.ONLY_ORGANIZER_CAN_CREATE') }}</span>
          <h4 v-if="error" class="text-errorred">{{ error }}</h4>
        </div>
        <v-divider class="my-4"></v-divider>
        <div>
          <b class="text-primary">{{ $t('MEETING.ATTACH_EXISTING_MEETING') }}</b>
          <div class="ml-4">
            <v-autocomplete v-model="meetingToAttach" :label="$t('MEETING.SEARCH_MEETING_TITLE')" :disabled="! meetings.length" :loading="! meetings.length"
                            :items="meetings" item-title="title" return-object>
            </v-autocomplete>
            <v-checkbox v-model="moveToAttachedMeetingDate" :disabled="! meetingToAttach"
                        :label="$t('MEETING.MOVE_TO_MEETING_DATE') + meetingToAttachFormatedDate" class="mt-0">
            </v-checkbox>
            <v-btn class="px-4" :disabled="! meetingToAttach" color="primary" rounded @click="attachMeeting()">{{ $t('MEETING.ATTACH_MEETING') }}</v-btn>
          </div>
        </div>
      </template>
      <div v-else>
        <b class="text-meetingprimary">{{ $t('MEETING.PREMIUM.PILOT_MEETINGS') }}</b>
        <img src="./atouts_meeting.jpg" class="my-2" style="width: 100%">
        <div class="d-flex align-center justify-center">
          <v-btn href="./meeting" color="accent" rounded class="ml-4">{{ $t('MEETING.PREMIUM.START_TRIAL') }}</v-btn>
          <v-btn class="ml-4" variant="text" color="primary" href="https://bubblemeeting.net" target="_blank" rel="noopener">{{ $t('MEETING.PREMIUM.LEARN_MORE') }}</v-btn>
        </div>
      </div>
    </div>

    <div v-else-if="meeting">
      <v-tabs v-model="openedTabIndex" class="mx-2" slider-color="primary">
        <!-- SUMMARY -->
        <v-tab value="summary" class="px-2">{{ $t('MEETING.SUMMARY') }}</v-tab>
        <!-- REPORT -->
        <v-tab v-if="meeting.summary" value="report" class="px-2">{{ $t('MEETING.REPORT') }}</v-tab>
        <!-- ACTIONS -->
        <v-tab v-if="meetingActions.length" value="actions" class="px-2">
          <v-badge content="9999+" color="primary" location="top" offset-x="-3">
            <template #badge>
              {{ inProgressActionsLength }}
            </template>
            {{ $t('MEETING.ACTIONS') }}
          </v-badge>
        </v-tab>
        <v-btn :href="`./meeting/#/meeting/${meetingId}`" color="primary" rounded class="my-auto ml-auto mr-6" @click="closeMeetingElement">
          {{ $t('MEETING.ACCESS_MEETING') }}
        </v-btn>
      </v-tabs>
      <v-window v-model="openedTabIndex">
        <!-- TAB ITEM SUMMARY -->
        <v-window-item value="summary">
          <v-alert v-if="! elementDateIsMeetingDate" type="warning" color="warningorange" closable border="start" colored-border density="compact"
                   elevation="2" class="mt-4 mx-2">
            {{ $t('MEETING.MILESTONE_DATE_DIFFERENT_FROM_MEETING') }}
            <v-btn size="small" color="primary" rounded variant="outlined" class="my-2" @click="moveToMeetingDate">{{ $t('MEETING.MOVE_TO_MEETING_DATE') }}</v-btn>
          </v-alert>
          <div class="mt-1 mx-2">
            <div class="d-flex">
              <meeting-details :from-meeting="meeting" :corner-size="72" content="categoryCorner"></meeting-details>
              <meeting-details class="mt-3" :from-meeting="meeting" content="title" style="width: 340px;"></meeting-details>
            </div>
            <div class="py-2 px-6">
              <div class="py-1">
                <v-divider color="grey" style="margin-left: 32px"></v-divider>
              </div>
              <div class="py-1" style="display: flex">
                <div style="width: 32px; flex: 0 0 auto"><v-icon>fa-calendar-alt</v-icon></div>
                <meeting-details :from-meeting="meeting" content="date" style="flex: 1 1 100%"></meeting-details>
              </div>
              <div class="py-1" style="display: flex">
                <div style="width: 32px; flex: 0 0 auto"><v-icon>fa-map-marker-alt</v-icon></div>
                <meeting-details :from-meeting="meeting" content="place" style="flex: 1 1 100%; white-space: normal"></meeting-details>
              </div>
              <div class="py-1">
                <v-divider color="grey" style="margin-left: 32px"></v-divider>
              </div>
              <div class="py-1" style="display: flex">
                <div style="width: 32px; flex: 0 0 auto"><v-icon>fa-users</v-icon></div>
                <meeting-details :from-meeting="meeting" content="attendees" style="flex: 1 1 100%"></meeting-details>
              </div>
              <div class="py-1">
                <v-divider color="grey" style="margin-left: 32px"></v-divider>
              </div>
              <div class="py-1" style="display: flex">
                <div style="width: 32px; flex: 0 0 auto"><v-icon>far fa-list-alt</v-icon></div>
                <meeting-details :from-meeting="meeting" content="description" style="flex: 1 1 100%"></meeting-details>
              </div>
            </div>
            <div class="text-center">
              <v-btn class="my-4 text-white" color="errorred" rounded @click="closeMeetingElement('delete')">{{ $t('MEETING.DETACH_MEETING') }}</v-btn>
            </div>
          </div>
        </v-window-item>
        <!--TAB ITEM REPORT -->
        <v-window-item value="report" class="ma-4">
          <meeting-details :from-meeting="meeting" content="summary"></meeting-details>
        </v-window-item>
        <!--TAB ITEM ACTIONS -->
        <v-window-item value="actions" class="ma-4">
          <meeting-actions :meeting-actions="meetingActions"></meeting-actions>
        </v-window-item>
      </v-window>
    </div>
    <div v-else class="text-center pa-4">
      <div v-if="error">
        <h4>{{ error }}</h4>
        <v-btn class="mt-3 text-white" color="errorred" rounded @click="closeMeetingElement('delete')">{{ $t('MEETING.DETACH_MEETING') }}</v-btn>
      </div>
      <v-icon v-else size="large" color="primary" class="far fa-spinner fa-spin fa-2x fa-fw"></v-icon>
    </div>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Action from '@/models/Action';
  import MeetingDetails from './MeetingDetails';
  import MeetingActions from './MeetingActions';

  export default {
    components: {
      MeetingDetails,
      MeetingActions,
    },
    props: {
      element: { type: Object, required: true },
    },
    emits: ['close'],
    data() {
      return {
        meetingToAttach: null,
        moveToAttachedMeetingDate: true,
        meeting: null,
        meetingActions: [],
        meetings: [],
        openedTabIndex: null,
        error: "",
      };
    },
    computed: {
      meetingId() {
        return this.element.getMeetingId();
      },
      inProgressActionsLength() {
        return this.meetingActions.filter(action => ! action.data.completed_at).length;
      },
      meetingToAttachFormatedDate() {
        if (! this.meetingToAttach) return '';
        return `: ${moment(this.meetingToAttach.date).format('ll')}`;
      },
      elementDateIsMeetingDate() {
        const elementDate = this.element.getStartTime();
        if (! elementDate) return false;
        const schedule = this.element.getSchedule();
        if (schedule.start) elementDate.hours(schedule.start.hours()).minutes(schedule.start.minutes());

        if (! elementDate.isSame(this.meeting.date, schedule.start ? 'minute' : 'day')) return false;

        if (schedule.end) {
          const meetingEnd = moment(this.meeting.date).add(this.meeting.duration, 'seconds');
          if (schedule.end.hours() != meetingEnd.hours() || schedule.end.minutes() != meetingEnd.minutes()) return false;
        }

        return true;
      },
      hasMeeting() { return this.accessRight.hasMeeting; },
      canCreateMeeting() { return this.accessRight.canCreateMeeting; },
      ...mapState('users', ['user', 'accessRight']),
    },
    created() {
      this.loadMeeting();
    },
    methods: {
      loadMeeting() {
        if (this.meetingId) {
          window.apiSrv.call('v2/meetings', 'show', this.meetingId).then((results) => {
            this.meeting = results && results.data && results.data.meeting;
            this.meetingActions = (this.meeting && this.meeting.actions || []).map(item => new Action({ type: 'meeting', item }));
          }).catch((error) => {
            this.error = error;
          });
        } else {
          window.apiSrv.call('v2/meetings', 'index').then((results) => {
            this.meetings = results && results.data && results.data.meetings && results.data.meetings.filter(meeting => meeting.title !== '') || [];
          });
        }
      },
      closeMeetingElement(result) {
        if (result == 'delete') {
          if (! this.element) return;
          this.startChangingElement();
          this.element.setMeetingId(null);
          this.element.setIsLocked(null);
          this.changingElement(this.element);
        }
        this.$emit('close');
      },
      setElementDateAndSchedule(meeting) {
        const start = moment(meeting.date);
        const end = moment(start).add(meeting.duration, 'seconds');
        this.element.setStartTime(start);
        this.element.setSchedule({ start, end });
        this.element.setIsLocked(true);
      },
      createMeeting() {
        const date = this.element.getStartTime();
        if (! date) return;
        date.seconds(0).minutes(Math.ceil(moment().minutes() / 30) * 30);
        const schedule = this.element.getSchedule();
        let duration = 3600;
        if (schedule.start) {
          date.hours(schedule.start.hours()).minutes(schedule.start.minutes());
          if (schedule.end) {
            duration = (schedule.end.hours() - schedule.start.hours()) * 3600 + (schedule.end.minutes() - schedule.start.minutes()) * 60;
            if (duration < 0) duration += 3600 * 24;
          }
        }

        const newMeeting = {
          date: date.format(),
          duration,
          privacy: 'private',
          users: [{ id: this.user.id, attendance: 'required', access_right: 'admin' }],
          status: 'draft',
        };
        window.apiSrv.call('v2/meetings', 'store', newMeeting).then((response) => {
          const meetingId = response.data && response.data.meeting && response.data.meeting.id;
          if (! meetingId) throw new Error('unknown error');
          this.startChangingElement();
          this.element.setMeetingId(meetingId);
          this.setElementDateAndSchedule(newMeeting);
          this.changingElement(this.element);
          this.loadMeeting();
        }).catch((error) => {
          this.error = error;
        });
      },
      attachMeeting() {
        if (! this.meetingToAttach) return;
        this.startChangingElement();
        this.element.setMeetingId(this.meetingToAttach.id);
        if (this.moveToAttachedMeetingDate) {
          this.setElementDateAndSchedule(this.meetingToAttach);
        }
        this.changingElement(this.element);
        this.loadMeeting();
      },
      moveToMeetingDate() {
        this.startChangingElement();
        this.setElementDateAndSchedule(this.meeting);
        this.changingElement(this.element);
      },
      ...mapActions('planning/elements', ['changingElement', 'startChangingElement']),
    },
  };
</script>
