import { defineAsyncComponent } from 'vue';
import MayotteRiskElBar from '@/client_customs/Mayotte/MayotteRiskElBar'; // initial el height problem with lazyloading
import dashboardImg from '@/client_customs/Mayotte/mayotte_tdb_puic.png';

const MayotteVolumetry = defineAsyncComponent(() => import('@/client_customs/Mayotte/MayotteVolumetry'));
const MayotteRisk = defineAsyncComponent(() => import('@/client_customs/Mayotte/MayotteRisk'));
const MayotteDashboard = () => import('@/client_customs/Mayotte/MayotteDashboard');

export default {
  clientCustomComponents: { MayotteVolumetry, MayotteRisk, MayotteRiskElBar },
  hideManageAccount: true,
  integrations: {
    availableIntegrations: [],
  },
  links: {
    hideOneDrive: true,
    hideDropbox: true,
    hideGoogleDrive: false,
    hideFileUpload: false,
  },
  dashboards: {
    router: [
      { path: '/dashboards/puic', component: MayotteDashboard },
    ],
    list: [
      { title: 'Tableau de bord PUIC', to: '/dashboards/puic', img: dashboardImg },
    ],
  },
  colors: [
    { label: "", sublabels: ["", "", "Pôle Emploi", "", ""] },
    { label: "", sublabels: ["", "", "Conseil Départemental", "", ""] },
    { label: "", sublabels: ["", "", "Carif Oref", "", ""] },
    { label: "", sublabels: ["", "DEETS", "", "", ""] },
    { label: "", sublabels: ["", "", "", "", ""] },
    { label: "", sublabels: ["", "", "", "PLIA", ""] },
  ],
  projects: {
    defaultPrivacy: 'company visible',
    showAdvancedOptions: true,
  },
  elements: {
    customFields: [
      { dataField: 'risk', component: 'MayotteRisk', elComponent: 'MayotteRiskElBar', label: "Risque", icon: 'far fa-exclamation', active: true, inMenu: true },
      { dataField: 'volumetry', component: 'MayotteVolumetry', label: "Indicateurs", icon: 'far fa-bullseye-arrow', active: true, inMenu: true },
    ],
  },
};
