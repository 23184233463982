<template>
  <div v-if="isKanbanMode" v-droppable="droppableConfig(lane)"
       :style="lane.hidden ? { height: `${hiddenLaneHeight}px` } : { 'min-height': `${kanbanLaneHeaderMinHeight}px` }"
       class="kanban-lane lane d-flex"
       @contextmenu="openNewElementMenu(lane, $event)">
    <div v-for="step in processSteps" :key="step.id" class="px-2 py-1" :style="{ width: `${step.width}px` }" style="height: 100%;">
      <planning-lane-view-content :planning="planning" :lane="lane" :lane-elements="laneElementsByProcessStep[step.id]"
                                  :dynamic="dynamic" :view="view" :options="options" :is-sub-lane="isSubLane" :events="events"
                                  v-on="{ openDependencies: $attrs.onOpenDependencies, openMeeting: $attrs.onOpenMeeting }">
      </planning-lane-view-content>
    </div>
  </div>
  <div v-else v-droppable="droppableConfig(lane)" :style="{ height: `${laneHeight}px` }" class="lane"
       @contextmenu="openNewElementMenu(lane, $event)">
    <!--    WORKLOADS     -->
    <lane-workloads :planning="planning" :lane="lane" :adapt-workloads-height="hideElements"></lane-workloads>
    <!--    END WORKLOADS     -->
    <planning-lane-view-content :planning="planning" :lane="lane" :lane-elements="laneElements" :dynamic="dynamic" :view="view"
                                :options="options" :is-sub-lane="isSubLane" :events="events"
                                v-on="{ openDependencies: $attrs.onOpenDependencies, openMeeting: $attrs.onOpenMeeting }">
    </planning-lane-view-content>
  </div>
</template>

<style lang="scss">
  .kanban-lane {
    .element.milestone:not(.is-dragging) .milestone-icon {
      position: static !important;
      display: inline-flex !important;
      vertical-align: middle !important;
      margin: 0 !important;
      min-height: 0 !important;

      .milestone-icon-dash {
        display: none !important;
      }
    }
    .milestone-icon + * {
      display: inline-flex !important;
      vertical-align: middle !important;
      max-width: 100%;
    }
    .element {
      position: relative;
      margin-top: 3px;
      border-bottom: 2px solid transparent;

      &.task, &.macro {
        border-bottom-width: 3px !important;
      }
    }
  }
</style>

<script>
  import constants from '@/js/constants.js';
  import LaneWorkloads from '@/components/Dashboards/PlanningUsers/LaneWorkloads.vue';
  import PlanningLaneViewContent from './PlanningLaneViewContent.vue';
  import laneHeight from './mixins/laneHeight';
  import droppable from './mixins/droppable';

  export default {
    components: {
      LaneWorkloads,
      PlanningLaneViewContent,
    },
    mixins: [laneHeight, droppable],
    props: {
      planning: { type: Object, required: true },
      lane: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      isSubLane: { type: Boolean, default: false },
      options: { type: Object, default: () => ({}) },
      events: { type: Object, default: () => ({}) },
    },
    data() {
      return {
        hiddenLaneHeight: constants.hiddenLaneHeight,
      };
    },
    computed: {
      processSteps() {
        // get planning from store because this.planning may be a subplanning
        return this.$store.state.planning.process.steps.concat(this.$store.getters['planning/process/subprojectsProcessSteps']);
      },
      laneElementsByProcessStep() {
        const laneElementsByProcessStep = {};
        if (this.lane.hidden && ! this.isSubLane || this.hideElements) return laneElementsByProcessStep;

        const laneElements = this.$store.getters['planning/lanes/getLaneElements']({ planning: this.planning, laneId: this.lane.id }).filter(el => ! el.filteredout);
        this.processSteps.forEach((step) => {
          if (step.width < constants.kanbanDefaultColWidth) { // xs step : hide elements
            laneElementsByProcessStep[step.id] = laneElements.filter(el => el.isDragPlaceholder && el.getProcessStep() == step.id); // show only drag placeholder
          } else if (step.id) {
            laneElementsByProcessStep[step.id] = laneElements.filter((el) => {
              let elProcessStepId = el.getProcessStep();
              if (el.project_id) {
                // merge subproject step
                elProcessStepId = this.$store.getters['planning/process/convertSubprojectStepId'](el.project_id, elProcessStepId);
              }
              return elProcessStepId == step.id;
            });
          } else { // backlog
            // eslint-disable-next-line no-lonely-if
            if (this.planning.process.config.getShowPlannedElements()) { // include planned elements
              laneElementsByProcessStep[step.id] = laneElements.filter(el => ! el.getProcessStep());
            } else { // only unplanned elements (default)
              laneElementsByProcessStep[step.id] = laneElements.filter(el => ! el.getProcessStep() && ! el.hasDates());
            }
          }
          laneElementsByProcessStep[step.id].sort((a, b) => {
            if ((a.getProcessPriority() || 0) != (b.getProcessPriority() || 0)) { // higher priority at top
              return (a.getProcessPriority() || 0) > (b.getProcessPriority() || 0) ? -1 : 1;
            }

            const [aEndTime, bEndTime] = [a.getEndTime(), b.getEndTime()];
            if (! aEndTime && ! bEndTime) { // if both have no endtime
              const aStartTime = a.getStartTime();
              return ! aStartTime ? 1 : (! b.getStartTime() ? -1 : aStartTime.diff(b.getStartTime())); // sort by starttime
            }
            return ! aEndTime ? 1 : (! bEndTime ? -1 : aEndTime.diff(bEndTime)); // sort by endtime
          });
        });
        return laneElementsByProcessStep;
      },
      laneElements() {
        if (this.lane.hidden && ! this.isSubLane || this.hideElements) return [];
        const laneElements = this.$store.getters['planning/lanes/getLaneElements']({ planning: this.planning, laneId: this.lane.id });
        return laneElements.filter(el => el.isVisibleOnPlanning());
      },
      hideElements() {
        return this.options.elements?.hideElements;
      },
    },
    mounted() {
      this.createLaneHeightObserver();
    },
    methods: {
      canModifyLane(lane) {
        return this.dynamic && (! this.view || lane.access_right == 'modify');
      },
      openNewElementMenu(lane, event) {
        if (event.shiftKey) return; // shift to shorcut the menu
        event.preventDefault();
        if (! this.canModifyLane(this.lane)) return;
        const x = event.pageX - $(window).scrollLeft();
        const y = event.pageY - $(window).scrollTop();

        this.$store.state.ui.planning.newElementMenuPosition = { x, y };
        this.$store.state.ui.planning.newElementMenuLane = lane;
        this.$store.state.ui.planning.newElementMenuIsOpened = true;
      },
    },
  };
</script>
