<template>
  <div :class="{ 'rounded-te': ! hasTitle }" class="bg-lightgrey rounded-be d-flex flex-column pb-2">
    <template v-if="planningDrawerTabIndex != '' && ! hasTitle">
      <v-btn-icon size="small" class="mx-auto" @click="close"><v-icon size="large">far fa-times</v-icon></v-btn-icon>
    </template>
    <v-slide-group direction="vertical" prev-icon="far fa-angle-up" next-icon="far fa-angle-down" class="fill-height">
      <div v-if="el" class="bg-secondary">
        <v-tooltip location="left">
          <template #activator="{ props }">
            <v-btn-icon :active="planningDrawerTabIndex == 'element'" class="rounded-0" v-bind="props"
                        @click="elementClick(el)">
              <v-icon>far fa-file-edit</v-icon>
            </v-btn-icon>
          </template>
          <span>{{ $t('PLANNINGDRAWER.CONTENT') }}</span>
        </v-tooltip>
        <v-tooltip location="left">
          <template #activator="{ props }">
            <v-btn-icon :active="planningDrawerTabIndex == 'color-picker'" class="rounded-0" v-bind="props"
                        @click="selectColor(el)">
              <v-icon>far fa-tint</v-icon>
            </v-btn-icon>
          </template>
          <span>{{ $t(el.isType('macro', 'task') ? 'PLANNING.SELECT_ICON_AND_COLOR' : 'PLANNING.SELECT_ICON') }}</span>
        </v-tooltip>
        <v-tooltip v-if="! el.isType('macro')" location="left">
          <template #activator="{ props }">
            <v-btn-icon :active="planningDrawerTabIndex == 'dependencies'" :disabled="! el.hasDates() || dashboard" class="rounded-0" v-bind="props"
                        @click="openDependencies(el)">
              <v-icon>far fa-link</v-icon>
            </v-btn-icon>
          </template>
          <span>{{ $t('PLANNING.DEPENDENCIES') }}</span>
        </v-tooltip>
        <v-tooltip v-if="el.isType('milestone') && el.getMeetingId()" location="left">
          <template #activator="{ props }">
            <v-btn-icon :active="planningDrawerTabIndex == 'meeting'" class="rounded-0" v-bind="props"
                        @click="openMeeting(el)">
              <v-icon>far fa-users</v-icon>
            </v-btn-icon>
          </template>
          <span>{{ $t('MEETING.BUBBLE_MEETING') }}</span>
        </v-tooltip>
      </div>

      <v-spacer></v-spacer>
      <v-btn-icon v-if="el && ! showBottomButtonsWithElOpened" class="rounded-b-0" style="height: auto" @click="showBottomButtonsWithElOpened = true">
        <v-icon class="py-4">far fa-angle-up</v-icon>
      </v-btn-icon>
      <div v-if="! el || showBottomButtonsWithElOpened" class="mt-4 bg-secondary">
        <template v-if="canModifyPlanning">
          <v-tooltip v-if="! hideExchangeCenter" location="left">
            <template #activator="{ props }">
              <v-btn-icon :active="planningDrawerTabIndex == 'exchanges'" class="rounded-0" v-bind="props"
                          @click="open('exchanges')">
                <v-icon>far fa-comments</v-icon>
                <span v-if="unread.exchanges" class="planningdrawer-badge bg-errorred">{{ unread.exchanges }}</span>
              </v-btn-icon>
            </template>
            <span>{{ $t('PLANNINGDRAWER.OPEN_EXCHANGECENTER') }}</span>
          </v-tooltip>
          <v-tooltip location="left">
            <template #activator="{ props }">
              <v-btn-icon :active="planningDrawerTabIndex == 'actions'" class="rounded-0" v-bind="props"
                          @click="open('actions')">
                <v-icon>far fa-check-square</v-icon>
                <div v-if="countLateActions" class="planningdrawer-badge bg-errorred">{{ countLateActions }}</div>
              </v-btn-icon>
            </template>
            <span>{{ $t('PLANNINGDRAWER.OPEN_ACTIONS') }}</span>
          </v-tooltip>
        </template>
        <v-tooltip v-if="canModifyPlanning || dashboard" location="left">
          <template #activator="{ props }">
            <v-btn-icon :active="planningDrawerTabIndex == 'config'" class="rounded-0 tour-timelineconfig" v-bind="props"
                        @click="openConfig">
              <v-icon>far fa-cog</v-icon>
            </v-btn-icon>
          </template>
          <span>{{ $t('PLANNING.PLANNING_CONFIG') }}</span>
        </v-tooltip>
      </div>
      <div>
        <v-tooltip location="left">
          <template #activator="{ props }">
            <v-btn-icon class="rounded-0" v-bind="props"
                        @click="openZohoChat">
              <v-icon>far fa-question</v-icon>
            </v-btn-icon>
          </template>
          <span>{{ $t('HELP.NEED_HELP') }}</span>
        </v-tooltip>
        <template v-if="logoutButton">
          <!-- MICROSOFT TEAMS WITH NO NAVBAR -->
          <v-divider></v-divider>
          <v-tooltip location="left">
            <template #activator="{ props }">
              <v-btn-icon class="rounded-0" v-bind="props" @click="logout">
                <v-icon>far fa-sign-out-alt</v-icon>
              </v-btn-icon>
            </template>
            <span>{{ $t('ACCOUNT.LOG_OUT') }}</span>
          </v-tooltip>
        </template>
      </div>
    </v-slide-group>
  </div>
</template>

<style lang="scss">
  #planningdrawer .planningdrawer-badge {
    position: absolute;
    padding: 4px 6px;
    top: 0;
    right: 0;
    border-radius: 50%;
    font-size: 75%;
  }
</style>

<script>
  import { mapState, mapActions } from 'vuex';
  import clientConfig from '@/client_customs/config';

  export default {
    props: {
      planning: { type: Object, required: true },
      events: { type: Object, default: () => ({}) },
      canModifyPlanning: { type: Boolean, default: false },
      hasTitle: { type: Boolean, default: false },
    },
    emits: ['open', 'close'],
    data() {
      return {
        showBottomButtonsWithElOpened: false,
        hideExchangeCenter: clientConfig.planningDrawer?.hideExchangeCenter || false,
        logoutButton: clientConfig.planningDrawer?.logoutButton || false,
      };
    },
    computed: {
      el() {
        if (this.planningDrawerTabIndex == 'element') return this.openedElement;
        if (this.planningDrawerTabIndex == 'color-picker') return this.openedColorPickerElement;
        if (this.planningDrawerTabIndex == 'dependencies') return this.openedDependenciesElement;
        if (this.planningDrawerTabIndex == 'meeting') return this.openedMeetingElement;
        return null;
      },
      notifications() {
        return this.$store.state.notifications.all;
      },
      countLateActions() {
        const me = this.$store.state.users.user;
        return this.planning.elements.reduce((count, el) => {
          if (! el.getChecklist()) return count;
          return count + el.getChecklist().filter(item => ! item.checked && item.user_id == me.id && moment(item.duedate || el.getEndTime()).isBefore()).length;
        }, 0);
      },
      unreadNotifications() {
        return this.notifications.filter(notif => notif.section_type == 'planning' && notif.section_id == this.planning.id && ! notif.seen_at);
      },
      unread() {
        return {
          exchanges: this.unreadNotifications.filter(notif => notif.target_type == 'exchangeCenter').length,
        };
      },
      ...mapState('ui/planning', ['dashboard', 'planningDrawerTabIndex', 'openedElement', 'openedColorPickerElement', 'openedDependenciesElement', 'openedMeetingElement']),
    },
    watch: {
      el() {
        this.showBottomButtonsWithElOpened = false;
      },
    },
    created() {
      window.zoho_donotshow = true; // block initial show
      if (window.$zoho?.salesiq?.floatbutton) window.$zoho.salesiq.floatbutton.visible('hide');
    },
    unmounted() {
      if (window.$zoho?.salesiq?.floatbutton) window.$zoho.salesiq.floatbutton.visible('show');
      if (window.$ZSIQWidgetUI) window.$ZSIQWidgetUI.minimizeSticker();
    },
    methods: {
      elementClick(el) {
        if (this.events.elementClick) {
          this.events.elementClick(el);
          return;
        }
        this.$store.dispatch('ui/planning/openElementDetails', el);
      },
      selectColor(el) {
        if (this.events.selectColor) {
          this.events.selectColor(el);
          return;
        }
        this.$store.dispatch('ui/planning/openColorPicker', el);
      },
      openZohoChat() {
        if (! window.$zoho) return;
        window.$zoho.salesiq.floatwindow.visible('show');
      },
      logout() {
        window.apiSrv.logout().then(() => {
          this.$store.commit('users/reset');
          window.location.reload();
        }).catch(() => {
          this.$store.commit('users/reset');
          window.location.reload();
        });
      },
      open(tab) {
        this.$emit('open', tab);
      },
      close() {
        this.$emit('close');
      },
      ...mapActions('ui/planning', ['openConfig', 'openDependencies', 'openMeeting']),
    },
  };
</script>
