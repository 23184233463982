<template>
  <v-card class="mt-2 mb-6">
    <div v-if="usersList.length" class="table-responsive">
      <table class="table">
        <thead class="bg-lightgrey">
          <tr>
            <th></th>
            <th>{{ $t('MANAGE.FIRSTNAME') }}</th>
            <th>{{ $t('MANAGE.LASTNAME') }}</th>
            <th>{{ $t('MANAGE.EMAIL') }}</th>
            <th class="text-center">{{ $t('MANAGE.ACCESS_RIGHT') }}</th>
            <th v-if="project.access_right == 'admin' && ! hideNotifications" class="text-center">
              {{ $t('NOTIFICATIONS.NOTIFICATIONS') }}&nbsp; <i :title="$t('NOTIFICATIONS.ACTIVATE_NOTIFICATIONS')" class="fa fa-info-circle text-medium-emphasis"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in usersList">
            <td class="text-center">
              <user-avatar :user="user" :size="32"></user-avatar>
            </td>
            <td>{{ $filters.lowercaseUcfirst(user.firstname) }}</td>
            <td>{{ $filters.lowercaseUcfirst(user.lastname) }}</td>
            <td style="word-break: break-word;">{{ user.email }}</td>
            <td class="text-center">
              <div v-if="user.special_role">
                {{ $t('ACR.' + $filters.uppercase(user.access_right)) }} <span class="nobr">({{ $t('ACR.' + $filters.uppercase(user.special_role)) }})</span>
              </div>
              <div v-if="project.access_right !== 'admin' && ! user.special_role" class="nobr">
                <i :title="$t('ACR.NONE')" :class="{ 'text-errorred': user.access_right == 'none' }"
                   :style="{ opacity: user.access_right == 'none' ? 1 : .5 }" class="far fa-ban mx-1">
                </i>
                <i :title="$t('ACR.CONSULT')" :class="{ 'text-successgreen': user.access_right == 'consult' }"
                   :style="{ opacity: user.access_right == 'consult' ? 1 : .5 }" class="far fa-eye mx-1">
                </i>
                <i :title="$t('ACR.MODIFY')" :class="{ 'text-successgreen': user.access_right == 'modify', invisible: userIsConsultOnly(user) }"
                   :style="{ opacity: user.access_right == 'modify' ? 1 : .5 }" class="far fa-pencil-alt mx-1">
                </i>
                <i :title="$t('ACR.ADMIN')" :class="{ 'text-warningorange': user.access_right == 'admin', invisible: userIsConsultOnly(user) }"
                   :style="{ opacity: user.access_right == 'admin' ? 1 : .5 }" class="far fa-unlock-alt mx-1">
                </i>
              </div>

              <div v-if="project.access_right == 'admin' && ! user.special_role" :class="{ disabled: planningAccessRightsDisabled[user.id] }" class="nobr">
                <i :class="{ 'selected text-errorred': user.access_right == 'none' }" :title="$t('ACR.NONE')" class="far fa-ban access-right-icon mx-1"
                   @click="! planningAccessRightsDisabled[user.id] && setPlanningUserRights(user, 'none')">
                </i>
                <i :class="{ 'selected text-successgreen': user.access_right == 'consult' }" :title="$t('ACR.CONSULT')" class="far fa-eye access-right-icon mx-1"
                   @click="! planningAccessRightsDisabled[user.id] && setPlanningUserRights(user, 'consult')">
                </i>
                <i :class="{ 'selected text-successgreen': user.access_right == 'modify', invisible: userIsConsultOnly(user) }" :title="$t('ACR.MODIFY')" class="far fa-pencil-alt access-right-icon mx-1"
                   @click="! planningAccessRightsDisabled[user.id] && setPlanningUserRights(user, 'modify')">
                </i>
                <i :class="{ 'selected text-warningorange': user.access_right == 'admin', invisible: userIsConsultOnly(user) }" :title="$t('ACR.ADMIN')" class="far fa-unlock-alt access-right-icon mx-1"
                   @click="! planningAccessRightsDisabled[user.id] && setPlanningUserRights(user, 'admin')">
                </i>
              </div>
            </td>
            <td v-if="project.access_right == 'admin' && ! hideNotifications" class="text-center">
              <i v-show="user.access_right != 'none'" :class="user.notifications ? 'fa-toggle-on text-successgreen' : 'fa-toggle-off'"
                 :style="{ opacity: notificationsDisabled[user.id] ? .3 : 1 }" class="far fa-lg pointer"
                 @click="! notificationsDisabled[user.id] && toggleNotifications(user)">
              </i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <slot name="footer"></slot>
  </v-card>
</template>

<script>
  export default {
    props: {
      project: { type: Object, required: true },
      usersList: { type: Array, required: true },
      hideNotifications: { type: Boolean, default: false },
      planningAccessRightsDisabled: { type: Object, required: true },
      notificationsDisabled: { type: Object, required: true },
    },
    emits: ['set-planning-user-rights', 'toggle-notifications'],
    methods: {
      setPlanningUserRights(user, newRight) {
        this.$emit('set-planning-user-rights', { user, newRight });
      },
      toggleNotifications(user) {
        this.$emit('toggle-notifications', { user });
      },
      userIsConsultOnly(user) {
        return (this.$store.getters['users/getUserById'](user && user.id) || {}).access_right == 'consult';
      },
    },
  };
</script>
