import groups from './users/groups';
import accessRight from './users/accessRight';

const onuserchange = {};
let firstlogin = true;
let resolveUserPromise;
const userPromise = new Promise(((resolve) => {
  resolveUserPromise = resolve;
}));
let organizationPromise;
let groupsPromise;

function reset(state) {
  state.user = {};
}

function setUser(state, data) {
  _.forEach(data, (val, key) => {
    state.user[key] = val;
  });

  // update user in organization and company
  const userData = _.pick(data, ['id', 'email', 'firstname', 'lastname', 'avatar', 'access_right', 'meeting_right']);
  if (state.accessRight.hasOrganization && state.user.organization) {
    const organizationUser = state.user.organization.getUsers().find(user => user.id == state.user.id);
    if (organizationUser) {
      _.forEach(userData, (val, key) => {
        organizationUser[key] = val;
      });
    }
  }
  const companyUser = state.user.company?.users.find(user => user.id == state.user.id);
  if (companyUser) {
    _.forEach(userData, (val, key) => {
      companyUser[key] = val;
    });
  }
}

function setGetMeError(state, message) {
  state.getMeError = message;
}

function getMe({ state, rootState, commit, dispatch }, silent) {
  return window.apiSrv.call('users/me', 'index', null, silent).then(async (result) => {
    commit('setGetMeError', "");
    const data = result && result.data || {};
    const userDefaults = { firstname: rootState.lang.i18n.global.t('GLOBAL.FIRSTNAME'), lastname: rootState.lang.i18n.global.t('GLOBAL.LASTNAME') };
    commit('setUser', _.extend({}, userDefaults, data.user));

    await dispatch('accessRight/load');

    if (state.accessRight.hasGroups) {
      groupsPromise = dispatch('groups/load');
    }

    if (state.accessRight.hasOrganization) {
      organizationPromise = window.apiSrv.call('organization', 'index').then((organizationResult) => {
        const organization = organizationResult?.data?.organization || {};
        const companies = [];
        const users = [];
        organization.forEachSubOrga = function (orga, callback, level = 0) {
          callback(orga, level);
          if (! orga.companies) return;
          orga.companies.forEach((subOrga) => {
            organization.forEachSubOrga(subOrga, callback, level + 1);
          });
        };

        organization.forEachSubOrga(organization, (orga, level) => {
          if (! orga || ! orga.company) return;
          orga.company.level = level;
          companies.push(orga.company);
          orga.company.users = orga.company.users.sort((a, b) => (window.app.config.globalProperties.$filters.username(a) < window.app.config.globalProperties.$filters.username(b) ? -1 : 1));
          users.push(...orga.company.users);
        });

        state.user.organization = organization;
        state.user.organization.getUsers = function () {
          return users;
        };
        state.user.organization.getCompanies = function () {
          return companies;
        };
        return state.user.organization;
      });
    }

    if (firstlogin) {
      resolveUserPromise(state.user);
    } else {
      Object.keys(onuserchange).forEach((id) => {
        console.log('onuserchange', id);
        onuserchange[id]();
      });
    }
    if (state.user.company?.isPremiumExpired) {
      commit('ui/setPremiumSuspended', true, { root: true });
    } else if (firstlogin) {
      const userCreatedAt = moment(state.user.created_at);
      const isUserFirstminutes = moment().add(-10, 'minutes').isBefore(userCreatedAt) && userCreatedAt.isBefore();
      if (isUserFirstminutes) commit('ui/setOnBoardingWelcomeModal', true, { root: true });
    }
    if (state.user.company?.isPremium && moment(state.user.company.premiumExpireDate).endOf('day').isBefore()) {
      commit('ui/setPremiumExpired', true, { root: true });
    }
    firstlogin = false;
    return state.user;
  });
}

function getMeOrRetry({ commit, dispatch }, retryDelay = 300) {
  dispatch('getMe', true).catch(async (message) => {
    const { default: vueApp } = await import('@/js/vueApp');
    const query = vueApp?.config.globalProperties.$route.query || {};
    if (query.rotoken || message == 'Not logged in' || message == 'Session expired') {
      commit('setGetMeError', message);
      return; // unlogued access
    }
    window.setTimeout(() => { dispatch('getMeOrRetry', Math.min(retryDelay * 2, 60000)); }, retryDelay);
  });
}

/** *************************** */
/** ** INIT + PING / REFRESH ** */
/** *************************** */
function load({ state, dispatch }) {
  dispatch('lang/loadLocale', undefined, { root: true });
  dispatch('getMeOrRetry');
  setInterval(() => {
    if (! state.user.id) return;
    dispatch('getMe', true).catch(() => {});
  }, 3600000);
}

/** ******************* */
/** ***** EVENTS ****** */
/** ******************* */
function onUserChange(context, callbacks) {
  _.extend(onuserchange, callbacks);
}

/** ******************* */
/** ***** GETTERS ****** */
/** ******************* */
function getCompanyUsers(state) {
  const { user } = state;
  if (! user.id) return [];
  return user.company && user.company.users || [_.pick(user, ['id', 'email', 'firstname', 'lastname', 'avatar', 'access_right', 'meeting_right'])];
}

function getOrganizationUsers(state, getters) {
  let organizationUsers;
  if (state.accessRight.hasOrganization && state.user.organization) {
    organizationUsers = state.user.organization.getUsers();
  } else {
    organizationUsers = getters.getCompanyUsers;
  }
  return organizationUsers.slice().sort((a, b) => (window.app.config.globalProperties.$filters.username(a) < window.app.config.globalProperties.$filters.username(b) ? -1 : 1));
}

function usersById(state, getters) {
  return getters.getOrganizationUsers.reduce((acc, item) => { acc[item.id] = item; return acc; }, {});
}

function getUserById(state, getters) {
  return id => getters.usersById[id] || null;
}

function getOrganizationUsernames(state, getters) {
  return getters.getOrganizationUsers.reduce((acc, user) => {
    acc[user.id] = window.app.config.globalProperties.$filters.username(user);
    return acc;
  }, {});
}

function getOrganizationShortUsernames(state, getters) {
  return getters.getOrganizationUsers.reduce((acc, user) => {
    acc[user.id] = window.app.config.globalProperties.$filters.username(user, 'short');
    return acc;
  }, {});
}

function getUsername(state, getters) {
  return (userObj, format) => {
    if (! userObj) return "";
    const username = format == 'short' ? getters.getOrganizationShortUsernames[userObj.id] : getters.getOrganizationUsernames[userObj.id];
    return username || window.app.config.globalProperties.$filters.username(userObj, format);
  };
}

function getOrganizationPromise(state) {
  return state.userPromise.then(() => {
    if (state.accessRight.hasOrganization) {
      return organizationPromise.catch(() => null);
    }
    return null;
  });
}

function getGroupsPromise(state) {
  return state.userPromise.then(() => {
    if (state.accessRight.hasGroups) {
      return groupsPromise.catch(() => null);
    }
    return null;
  });
}

function getOrganizationSubCompanies(state) {
  if (! state.user.organization) return state.user.company ? [state.user.company] : [];
  let myOrganization;
  state.user.organization.forEachSubOrga(state.user.organization, (orga) => {
    if (orga.company_id == state.user.company_id) myOrganization = orga;
  });
  if (! myOrganization) return state.user.company ? [state.user.company] : [];
  const subcompanies = [];
  state.user.organization.forEachSubOrga(myOrganization, (orga) => {
    if (orga && orga.company) subcompanies.push(orga.company);
  });
  return subcompanies;
}

export default {
  namespaced: true,
  modules: {
    groups,
    accessRight,
  },
  state: {
    user: {},
    userPromise,
    getMeError: "",
  },
  getters: {
    getCompanyUsers,
    getOrganizationUsers,
    getOrganizationUsernames,
    getOrganizationShortUsernames,
    usersById,
    getUserById,
    getUsername,
    getOrganizationPromise,
    getOrganizationSubCompanies,
    getGroupsPromise,
  },
  mutations: {
    reset,
    setUser,
    setGetMeError,
  },
  actions: {
    getMe,
    getMeOrRetry,
    onUserChange,
    load,
  },
};
