// conditional synchronous import (top level await) not working -> all the config files are imported into index.js
// keep the config files as light as possible and lazy load components
const configModules = import.meta.glob('./clients/*.js', { import: 'default', eager: true });
const integrationModules = import.meta.glob('./integrations/*.js', { import: 'default', eager: true });

let config = {};
const subdomain = window.location.hostname.replace(/(.*)\.bubbleplan\.net/i, '$1');
if (['localhost', 'wpd', 'univlyon3', 'mel', 'metro', 'puic', 'leroymerlin', 'horis', 'amm', 'baccarat', 'setram', 'maaf'].includes(subdomain)) {
  config = configModules[`./clients/${subdomain}.js`] || {};
}

const integration = decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]integration(?:\\=([^&]*))?)?.*$", "i"), "$1"));
if (integration == 'microsoftteams') {
  config = angular.merge({}, integrationModules['./integrations/microsoftteams'], config);
}

export default config;
