import { getDefaultConfig } from './helpers/defaultValues';
import { getDefaultColors, getDefaultIcons } from './helpers/defaultConfigData';
import helpers from './helpers/planningInitHelpers';
import '@/css/planningdesigns.scss';

class PlanningConfig {
  constructor(srcData) {
    const data = angular.copy(srcData) || {};

    if (data instanceof PlanningConfig) {
      _.extend(this, data);
    } else {
      helpers.updateLastVersionsConfig(data);
      angular.merge(this, getDefaultConfig(), data);
    }

    this.setColors();
  }

  set(data) {
    Object.keys(data || {}).forEach((key) => {
      this[key] = data[key];
    });
  }

  getAll() {
    const defaultColors = getDefaultColors();
    const defaultIcons = getDefaultIcons();
    const nonNullData = {};
    Object.keys(this).forEach((key) => { if (this[key] !== null) nonNullData[key] = this[key]; });
    nonNullData.colors = this.colors.map((item, index) => {
      if (! defaultColors[index]) return angular.copy(item);
      const destData = {};
      if (item.label != defaultColors[index].label) destData.label = item.label;
      if (! angular.equals(item.sublabels, defaultColors[index].sublabels)) destData.sublabels = item.sublabels.slice();
      return _.isEmpty(destData) ? null : destData; // do not send empty {} to api as it may be saved as []
    });
    nonNullData.icons = {};
    Object.keys(this.icons || {}).forEach((key) => {
      const icon = this.icons[key];
      if (! defaultIcons[key] || icon.label != defaultIcons[key].label) nonNullData.icons[key] = { label: icon.label };
    });
    if (_.isEmpty(nonNullData.icons)) delete nonNullData.icons;
    return nonNullData;
  }

  setColors() {
    const colors = getDefaultColors();
    colors.forEach((color, index) => {
      if (! this.colors || ! this.colors[index]) return;
      if (this.colors[index].label) color.label = this.colors[index].label;
      if (this.colors[index].sublabels && this.colors[index].sublabels.length == 5) color.sublabels = this.colors[index].sublabels;
    });
    this.colors = colors;

    const icons = getDefaultIcons();
    Object.keys(icons).forEach((key) => {
      const icon = icons[key];
      if (this.icons && this.icons[key] && this.icons[key].label) icon.label = this.icons[key].label;
    });
    this.icons = icons;
  }

  /** ***************** */
  /** *  CUSTOM CSS  ** */
  /** ***************** */
  setDesign({ designcolor, logoUrl } = {}) {
    this.designcolor = (typeof designcolor !== 'undefined') ? designcolor : this.designcolor || window.app.config.globalProperties.$store.getters['users/accessRight/config'].designcolor;
    this.logo = (typeof logoUrl !== 'undefined') ? logoUrl : this.logo || '';

    const rootStyle = document.querySelector(":root").style;
    let { r, g, b } = window.hexToRgb(this.designcolor.main);
    rootStyle.setProperty('--design-first-color-rgb', `${r}, ${g}, ${b}`);
    rootStyle.setProperty('--design-first-color-contrast', window.contrastedTextColor(this.designcolor.main));
    ({ r, g, b } = window.hexToRgb(this.designcolor.second));
    rootStyle.setProperty('--design-second-color-rgb', `${r}, ${g}, ${b}`);
    rootStyle.setProperty('--design-second-color-contrast', window.contrastedTextColor(this.designcolor.second));

    this.setColors();
  }
}

export default PlanningConfig;
