import lang from '@/store/lang';

const VueI18n = lang.state.i18n.global;

export default {
  timelineOptions: () => [
    { value: 'years', title: VueI18n.t('TIMELINE.YEARS') },
    { value: 'quarters', title: VueI18n.t('TIMELINE.QUARTERS') },
    { value: 'months', title: VueI18n.t('TIMELINE.MONTHS') },
    { value: 'weeks', title: VueI18n.t('TIMELINE.WEEKS') },
    { value: 'days', title: VueI18n.t('TIMELINE.DAYS') },
    { value: 'none', title: VueI18n.t('TIMELINE.NONE') },
  ],
  availableColWidthOptions: () => [
    { value: 300, title: VueI18n.t('TIMELINE.X-LARGE') },
    { value: 230, title: VueI18n.t('TIMELINE.LARGE') },
    { value: 160, title: VueI18n.t('TIMELINE.MEDIUM') },
    { value: 100, title: VueI18n.t('TIMELINE.SMALL') },
    { value: 50, title: VueI18n.t('TIMELINE.X-SMALL') },
  ],
  availablePrivacyOptions: () => [
    { value: 'company', title: `${VueI18n.t('ACR.COMPANY')[0].toUpperCase()}${VueI18n.t('ACR.COMPANY').slice(1)}`, icon: 'far fa-pencil-alt' },
    { value: 'company visible', title: `${VueI18n.t('ACR.COMPANY_VISIBLE')[0].toUpperCase()}${VueI18n.t('ACR.COMPANY_VISIBLE').slice(1)}`, icon: 'far fa-eye' },
    { value: 'private', title: `${VueI18n.t('ACR.PRIVATE')[0].toUpperCase()}${VueI18n.t('ACR.PRIVATE').slice(1)}`, icon: 'far fa-lock' },
  ],
  processSteps: () => [ // mode kanban
    { id: null, label: VueI18n.t('KANBAN.BACKLOG'), color: 5 },
    { id: window.uuid(), label: VueI18n.t('KANBAN.TODO'), icon: 'tasks' },
    { id: window.uuid(), label: VueI18n.t('KANBAN.DONE'), color: 1, icon: 'time' },
    { id: window.uuid(), label: VueI18n.t('KANBAN.VALIDATED'), color: 2, icon: 'done' },
  ],
  projectStatus() {
    return {
      consolidation: {
        label: VueI18n.t('PROJECTS.STATUS.CONSOLIDATIONS'),
        readonly: false,
        nodashboards: true,
        template: false,
      },
      template: {
        label: VueI18n.t('PROJECTS.STATUS.TEMPLATES'),
        readonly: true,
        nodashboards: true,
        template: true,
      },
      draft: {
        label: VueI18n.t('PROJECTS.STATUS.DRAFTS'),
        readonly: false,
        nodashboards: true,
        template: false,
      },
      completed: {
        label: VueI18n.t('PROJECTS.STATUS.COMPLETED'),
        readonly: true,
        nodashboards: false,
        template: false,
      },
    };
  },
  budgetStates() {
    return {
      draft: VueI18n.t('ELEMENT-DETAIL.DRAFT'),
      quotation: VueI18n.t('ELEMENT-DETAIL.QUOTATION'),
      charged: VueI18n.t('ELEMENT-DETAIL.CHARGED'),
      paid: VueI18n.t('ELEMENT-DETAIL.PAID'),
    };
  },
  timelineFormats() {
    return {
      en: [
        { years: 'YYYY', quarters: '[Q]Q YYYY', months: 'MMMM YY', weeks: '[W]w YYYY', days: 'dd DD-MM-YYYY' },
        { years: 'YYYY', quarters: '[Q]Q YYYY', months: 'MMM', weeks: '[W]w', days: 'dd DD' },
      ],
      fr: [
        { years: 'YYYY', quarters: '[T]Q YYYY', months: 'MMMM YY', weeks: '[S]w YYYY', days: 'dd DD-MM-YYYY' },
        { years: 'YYYY', quarters: '[T]Q YYYY', months: 'MMM', weeks: '[S]w', days: 'dd DD' },
      ],
    };
  },
};
