import * as jsonpatch from 'fast-json-patch';
import ProjectSrv from '@/components/Projects/ProjectSrv';
import PlanningMeta from '@/models/PlanningMeta';
import PlanningConfig from '@/models/PlanningConfig';
import PlanningTimeline from '@/models/PlanningTimeline';
import PlanningLane from '@/models/PlanningLane';
import PlanningElement from '@/models/PlanningElement';
import PlanningProcessConfig from '@/models/PlanningProcessConfig';
import PlanningProcessStep from '@/models/PlanningProcessStep';
import { getDefaultProject } from '@/models/helpers/defaultValues';
import { setSubLaneId, setSubElId, setSubElLaneId } from '@/store/planning/subprojects';

class Planning {
  constructor(srcData) {
    let data;
    if (srcData instanceof Planning) {
      data = angular.copy(srcData) || {};
      _.extend(this, data);
    } else {
      data = { ...getDefaultProject(), ...srcData }; // avoiding bad performing angular.copy(srcData). srcData is still angular.copy in each submodel
      const topLevelProps = _.omit(data, 'meta', 'config', 'timeline', 'lanes', 'elements', 'process', 'lastSavedProjectVersion'); // keep id, projectsheet, ...
      _.extend(this, angular.copy(topLevelProps));
    }

    this.meta = new PlanningMeta(data.meta);
    this.config = new PlanningConfig(data.config);
    this.timeline = new PlanningTimeline(data.timeline);

    this.lanes = [];
    data.lanes?.forEach((lane) => {
      this.lanes.push(new PlanningLane(this, lane));
    });

    this.elements = [];
    data.elements?.forEach((elData) => {
      this.elements.push(new PlanningElement(this, elData));
    });

    this.process = { config: new PlanningProcessConfig(this, data.process?.config), steps: [] };
    data.process?.steps?.forEach((step) => {
      this.process.steps.push(new PlanningProcessStep(this, step));
    });

    this.lastSavedProjectVersion = this.toProject();
    if (! (data instanceof Planning)) {
      _.extend(this.lastSavedProjectVersion.content, {
        config: data.config, timeline: data.timeline, lanes: data.lanes, elements: data.elements, process: data.process,
      });
    }
  }

  applyWorkingView(workingView, { isSubproject = false } = {}) {
    // Lanes
    const lanes = [];
    const workingViewLanesIds = new Set(_.intersection(workingView.getLanes(), this.lanes.map(item => item.id)));
    const sublanesMatchIndex = {};
    this.lastSavedProjectVersion.content.lanes.forEach((laneData) => {
      const lane = new PlanningLane(this, laneData);
      if (isSubproject) {
        setSubLaneId(lane);
        sublanesMatchIndex[lane.o_id] = lane.id;
      }
      lane.filteredout = false;
      if (workingViewLanesIds.size && ! isSubproject) { // do not filter subproject lanes
        if (! workingViewLanesIds.has(lane.id)) lane.filteredout = true;
      }
      lanes.push(lane);
    });
    // Elements
    const elements = [];
    const {
      description, progress, checklist, users, dates, links, budgets,
    } = workingView.getSelectedDisplay();
    this.lastSavedProjectVersion.content.elements.forEach((elData) => {
      const el = new PlanningElement(this, elData);
      if (isSubproject) {
        setSubElId(el, this);
        setSubElLaneId(el, sublanesMatchIndex);
      }
      el.setConfig({
        ...el.getConfig(),
        'show-description': description === false || description === true ? description : el.getConfig('show-description'),
        'show-progress': progress === false || progress === true ? progress : el.getConfig('show-progress'),
        'show-checklist': checklist === false || checklist === true ? checklist : el.getConfig('show-checklist'),
        'show-users': users === false || users === true ? users : el.getConfig('show-users'),
        'show-dates': dates === false || dates === true ? dates : el.getConfig('show-dates'),
        'show-links': links === false || links === true ? links : el.getConfig('show-links'),
        'show-budgets': budgets === false || budgets === true ? budgets : el.getConfig('show-budgets'),
      });
      el.filteredout = false;
      if (workingView.getElementsColors().length && ! workingView.getElementsColors().some(x => x.color === el.getColorId() && x.colorShade === el.getColorShadeId())) {
        el.filteredout = true;
      } else if (workingView.getElementsIcons().length && ! workingView.getElementsIcons().includes(el.getIconId())) {
        el.filteredout = true;
      } else {
        const { selectedCompanyUsers = [], selectedVirtualParticipants = [], unassigned = false } = workingView.getElementsUsers();
        const allSelectedUsers = new Set(selectedCompanyUsers.concat(selectedVirtualParticipants));
        if (allSelectedUsers.size || unassigned) {
          const elUsers = el.getUsers();
          if (elUsers && elUsers.some(user => ! user.group_id)) { // exclude groups from considered users
            if (! elUsers.find(user => allSelectedUsers.has(user.id || user.username))) el.filteredout = true;
          } else if (! unassigned) {
            el.filteredout = true;
          }
        }
      }
      elements.push(el);
    });
    this.timeline = new PlanningTimeline(workingView.getTimeline());
    this.lanes = lanes;
    this.elements = elements;
    this.workingView = workingView;
  }

  applyLastSavedProjectVersion({ isSubproject = false } = {}) {
    // Lanes
    const lanes = this.lastSavedProjectVersion.content.lanes.map(lane => new PlanningLane(this, lane));
    const sublanesMatchIndex = {};
    if (isSubproject) {
      lanes.forEach((lane) => {
        setSubLaneId(lane);
        sublanesMatchIndex[lane.o_id] = lane.id;
      });
    }
    // Elements
    const elements = [];
    this.lastSavedProjectVersion.content.elements.forEach((elData) => {
      const el = new PlanningElement(this, elData);
      if (isSubproject) {
        setSubElId(el, this);
        setSubElLaneId(el, sublanesMatchIndex);
      }
      el.setConfig({
        ...el.getConfig(),
        'show-description': el.getConfig('show-description'),
        'show-progress': el.getConfig('show-progress'),
        'show-checklist': el.getConfig('show-checklist'),
        'show-users': el.getConfig('show-users'),
        'show-dates': el.getConfig('show-dates'),
        'show-links': el.getConfig('show-links'),
        'show-budgets': el.getConfig('show-budgets'),
      });
      elements.push(el);
    });
    const { process, timeline } = this.lastSavedProjectVersion.content;
    this.process = { config: new PlanningProcessConfig(this, process.config), steps: process.steps.map(step => new PlanningProcessStep(this, step)) };
    this.timeline = new PlanningTimeline(timeline);
    this.lanes = lanes;
    this.elements = elements;
    this.workingView = null;
  }

  applyContentPatch(contentPatch, updateLastSavedProject = true) {
    // updateLastSavedProject : apply content patch also to lastSavedProjectVersion, otherwise it will be detected as a modification in next save
    // Warning : function may throw an exception if contentPatch is inconsistent with planning data
    const modifications = { elements: {}, lanes: {} };
    const processStepsIdRegex = /process\/steps\/id=([^/]*)([/]?.*)/;
    const elementIdRegex = /elements\/id=([^/]*)([/]?.*)/;
    const laneIdRegex = /lanes\/id=([^/]*)([/]?.*)/;
    const jsonPatchPlanningBase = {
      config: this.config,
      timeline: this.timeline,
      process: { config: this.process.config, steps: {} },
      elements: {},
      lanes: {},
    };
    const lastSavedContent = this.lastSavedProjectVersion.content;
    const jsonPatchLastSavedContentBase = {
      config: lastSavedContent.config,
      timeline: lastSavedContent.timeline,
      process: { config: lastSavedContent.process.config, steps: {} },
      elements: {},
      lanes: {},
    };
    contentPatch.forEach((patch) => {
      if (patch.path.startsWith('/config/')) {
        if (patch.op == 'replace' && patch.path.match(/^\/config\/colors\/[0-9]+$/) && patch.value === null) return; // ignore model change {}->null
        if (patch.op == 'remove' && patch.path == '/config/icons') return; // ignore model change {}->undefined
        jsonpatch.applyOperation(jsonPatchPlanningBase, patch);
        if (updateLastSavedProject) jsonpatch.applyOperation(jsonPatchLastSavedContentBase, patch);
        modifications.config = true;
        return;
      }

      if (patch.path.startsWith('/timeline/')) {
        jsonpatch.applyOperation(jsonPatchPlanningBase, patch);
        if (updateLastSavedProject) jsonpatch.applyOperation(jsonPatchLastSavedContentBase, patch);
        modifications.timeline = true;
        return;
      }

      if (patch.path.startsWith('/elements/')) {
        const [, id, propertyPath] = elementIdRegex.exec(patch.path);
        if (! id) return;
        if (propertyPath) { // update el
          const element = this.elements.find(item => item.id == id);
          jsonPatchPlanningBase.elements[`id=${id}`] = element.getAll();
          // if (patch.op == 'remove') _.extend(patch, { op: 'replace', value: null }); // keep vuejs reactivity -> BUG in arrays
          jsonpatch.applyOperation(jsonPatchPlanningBase, patch);
          element.set(jsonPatchPlanningBase.elements[`id=${id}`]);

          if (updateLastSavedProject) {
            jsonPatchLastSavedContentBase.elements[`id=${id}`] = lastSavedContent.elements.find(item => item.id == id);
            jsonpatch.applyOperation(jsonPatchLastSavedContentBase, patch);
          }
        } else if (patch.op == 'remove') { // remove el
          const elementIndex = this.elements.findIndex(item => item.id == id);
          if (elementIndex > -1) this.elements.splice(elementIndex, 1);

          if (updateLastSavedProject) {
            const lastSavedContentElementIndex = lastSavedContent.elements.findIndex(item => item.id == id);
            if (lastSavedContentElementIndex > -1) lastSavedContent.elements.splice(lastSavedContentElementIndex, 1);
          }
        } else if (patch.op == 'add') { // add el
          if (this.elements.find(item => item.id == id)) return; // prevent duplicate element
          this.elements.push(new PlanningElement(this, patch.value));

          if (updateLastSavedProject) {
            lastSavedContent.elements.push((new PlanningElement(this, patch.value)).getAll());
          }
        }
        modifications.elements[id] = true;
        return;
      }

      if (patch.path.startsWith('/lanes/')) {
        const [, id, propertyPath] = laneIdRegex.exec(patch.path);
        if (! id) return;
        if (propertyPath) { // update lane
          const lane = this.lanes.find(item => item.id == id);
          jsonPatchPlanningBase.lanes[`id=${id}`] = lane.getAll();
          // if (patch.op == 'remove') _.extend(patch, { op: 'replace', value: null }); // keep vuejs reactivity -> BUG in arrays
          jsonpatch.applyOperation(jsonPatchPlanningBase, patch);
          lane.set(jsonPatchPlanningBase.lanes[`id=${id}`]);

          if (updateLastSavedProject) {
            jsonPatchLastSavedContentBase.lanes[`id=${id}`] = lastSavedContent.lanes.find(item => item.id == id);
            jsonpatch.applyOperation(jsonPatchLastSavedContentBase, patch);
          }
        } else if (patch.op == 'remove') { // remove lane
          const laneIndex = this.lanes.findIndex(item => item.id == id);
          if (laneIndex > -1) this.lanes.splice(laneIndex, 1);

          if (updateLastSavedProject) {
            const lastSavedContentLaneIndex = lastSavedContent.lanes.findIndex(item => item.id == id);
            if (lastSavedContentLaneIndex > -1) lastSavedContent.lanes.splice(lastSavedContentLaneIndex, 1);
          }
        } else if (patch.op == 'add') { // add lane
          this.lanes.push(new PlanningLane(this, patch.value));

          if (updateLastSavedProject) {
            lastSavedContent.lanes.push((new PlanningLane(this, patch.value)).getAll());
          }
        }
        modifications.lanes[id] = true;
        return;
      }

      if (patch.op == 'order' && patch.path == '/lanes') {
        this.lanes.sort((a, b) => {
          const indexA = patch.value.indexOf(a.id);
          const indexB = patch.value.indexOf(b.id);
          if (indexA == -1 || indexB == -1) return 0;
          return indexA < indexB ? -1 : 1;
        });

        if (updateLastSavedProject) {
          lastSavedContent.lanes.sort((a, b) => {
            const indexA = patch.value.indexOf(a.id);
            const indexB = patch.value.indexOf(b.id);
            if (indexA == -1 || indexB == -1) return 0;
            return indexA < indexB ? -1 : 1;
          });
        }
        return;
      }

      if (patch.path.startsWith('/process/config/')) {
        jsonpatch.applyOperation(jsonPatchPlanningBase, patch);
        if (updateLastSavedProject) jsonpatch.applyOperation(jsonPatchLastSavedContentBase, patch);
        modifications.process = true;
        return;
      }

      if (patch.path.startsWith('/process/steps/')) {
        const [, stepId, propertyPath] = processStepsIdRegex.exec(patch.path);
        if (! stepId) return;
        const id = (stepId == 'null') ? null : stepId; // id null results as string 'null'
        if (propertyPath) { // update step
          const processStep = this.process.steps.find(item => item.id == id);
          jsonPatchPlanningBase.process.steps[`id=${id}`] = processStep.getAll();
          jsonpatch.applyOperation(jsonPatchPlanningBase, patch);
          processStep.set(jsonPatchPlanningBase.process.steps[`id=${id}`]);

          if (updateLastSavedProject) {
            jsonPatchLastSavedContentBase.process.steps[`id=${id}`] = lastSavedContent.process.steps.find(item => item.id == id);
            jsonpatch.applyOperation(jsonPatchLastSavedContentBase, patch);
          }
        } else if (patch.op == 'remove') { // remove step
          const laneIndex = this.process.steps.findIndex(item => item.id == id);
          if (laneIndex > -1) this.process.steps.splice(laneIndex, 1);

          if (updateLastSavedProject) {
            const lastSavedContentProcessStepIndex = lastSavedContent.process.steps.findIndex(item => item.id == id);
            if (lastSavedContentProcessStepIndex > -1) lastSavedContent.process.steps.splice(lastSavedContentProcessStepIndex, 1);
          }
        } else if (patch.op == 'add') { // add step
          this.process.steps.push(new PlanningProcessStep(this, patch.value));

          if (updateLastSavedProject) {
            lastSavedContent.process.steps.push((new PlanningProcessStep(this, patch.value)).getAll());
          }
        }
        return;
      }

      if (patch.op == 'order' && patch.path == '/process/steps') {
        this.process.steps.sort((a, b) => {
          const indexA = patch.value.indexOf(a.id);
          const indexB = patch.value.indexOf(b.id);
          if (indexA == -1 || indexB == -1) return 0;
          return indexA < indexB ? -1 : 1;
        });

        if (updateLastSavedProject) {
          lastSavedContent.process.steps.sort((a, b) => {
            const indexA = patch.value.indexOf(a.id);
            const indexB = patch.value.indexOf(b.id);
            if (indexA == -1 || indexB == -1) return 0;
            return indexA < indexB ? -1 : 1;
          });
        }
      }
    });
    return modifications;
  }

  update(content) {
    if (! content) return {};
    if (content.id != this.id) {
      console.error(`trying to update planning ${this.id} with planning ${content.id}`);
      return {};
    }
    if (content.meta) angular.merge(this.meta, content.meta);
    const newProject = (new Planning(content)).toProject(); // ensure consistent format for lastSavedProjectVersion
    const contentPatch = this.getLastSavedVersionDiff(newProject);
    const modifications = this.applyContentPatch(contentPatch, false);

    this.lastSavedProjectVersion = newProject; // must be data from content as planning (this) can be different (eg element opened and in modification)
    return modifications;
  }

  clone() {
    return new Planning(this);
  }

  /** ******** */
  /* ACTIONS */
  /** ******** */
  toProject() {
    return {
      id: this.id,
      title: this.getTitle(),
      content: {
        config: angular.copy(this.config.getAll()),
        timeline: angular.copy(this.timeline.getAll()),
        lanes: this.lanes.map(lane => angular.copy(lane.getAll())),
        elements: this.elements.filter(el => ! el.isDragPlaceholder && ! el.isResizePlaceholder).map(el => angular.copy(el.getAll())),
        process: { config: angular.copy(this.process.config.getAll()), steps: this.process.steps.map(step => angular.copy(step.getAll())) },
      },
    };
  }

  getLastSavedVersionDiff(currentProject) {
    const beforeVersion = angular.copy(this.lastSavedProjectVersion);
    const currentVersion = currentProject ? angular.copy(currentProject) : this.toProject();

    const beforeVersionLanesIds = beforeVersion.content.lanes.map(item => item.id);
    const currentVersionLanesIds = currentVersion.content.lanes.map(item => item.id);
    const beforeVersionProcessStepsIds = beforeVersion.content.process.steps.map(item => item.id);
    const currentVersionProcessStepsIds = currentVersion.content.process.steps.map(item => item.id);
    [beforeVersion, currentVersion].forEach((version) => {
      let acc = {};
      for (let i = 0, n = version.content.elements.length; i < n; i++) {
        const item = version.content.elements[i];
        if (item.type == 'task') delete item.width;
        acc[`id=${item.id}`] = item;
      }
      version.content.elements = acc;
      acc = {};
      for (let i = 0, n = version.content.lanes.length; i < n; i++) {
        const item = version.content.lanes[i];
        acc[`id=${item.id}`] = item;
      }
      version.content.lanes = acc;
      acc = {};
      for (let i = 0, n = version.content.process.steps.length; i < n; i++) {
        const item = version.content.process.steps[i];
        acc[`id=${item.id}`] = item;
      }
      version.content.process.steps = acc;
    });

    const patch = jsonpatch.compare(beforeVersion.content, currentVersion.content);
    if (! angular.equals(beforeVersionLanesIds, currentVersionLanesIds)) patch.push({ op: "order", path: "/lanes", value: currentVersionLanesIds });
    if (! angular.equals(beforeVersionProcessStepsIds, currentVersionProcessStepsIds)) patch.push({ op: "order", path: "/process/steps", value: currentVersionProcessStepsIds });
    return patch;
  }

  save() {
    this.elements.forEach((el) => {
      (el.alerts || []).forEach((alert) => {
        alert.updateAndSave(el);
      });
    });

    const currentProject = this.toProject();
    if (this.workingView?.id) { // update timeline for saved working view (no id = newView)
      if (! angular.equals(this.workingView.config.timeline, currentProject.content.timeline)) {
        this.workingView.config.timeline = new PlanningTimeline(currentProject.content.timeline);
        this.workingView.save();
      }
      currentProject.content.timeline = this.lastSavedProjectVersion.content.timeline;
    }
    let contentPatch = this.getLastSavedVersionDiff(currentProject);
    if (this.workingView) { // don't save elements config
      contentPatch = contentPatch.filter(patch => ! patch.path.match(/\/elements\/id=[^/]*\/config\//i));
    }

    if (! contentPatch.length && this.lastSavedProjectVersion.title == currentProject.title) return Promise.resolve(null);

    const save = { id: currentProject.id, title: currentProject.title, contentPatch };
    if (! this.workingView) save.content = currentProject.content;
    return ProjectSrv.save(save).then(() => {
      if (this.workingView) { // add lastSavedProject elements config to currentProject elements
        currentProject.content.elements.forEach((el) => {
          const elSaved = this.lastSavedProjectVersion.content.elements.find(item => item.id == el.id);
          if (elSaved) {
            el.config = {
              'show-description': elSaved.config['show-description'],
              'show-progress': elSaved.config['show-progress'],
              'show-checklist': elSaved.config['show-checklist'],
              'show-users': elSaved.config['show-users'],
              'show-dates': elSaved.config['show-dates'],
              'show-links': elSaved.config['show-links'],
              'show-budgets': elSaved.config['show-budgets'],
            };
          }
        });
      }
      this.lastSavedProjectVersion = currentProject;
      return contentPatch;
    });
  }

  saveElement(el, oldState, { props, forDeletion = false } = {}) {
    // save planning with json patch only for this el ; use in dashboards with altered plannings
    const contentPatch = el.generateContentPatch(oldState, props, forDeletion);
    if (! contentPatch.length) return Promise.resolve(null);

    const currentProject = this.toProject();
    return ProjectSrv.save({ id: currentProject.id, title: currentProject.title, contentPatch }).then(() => {
      this.lastSavedProjectVersion = currentProject;
      return contentPatch;
    });
  }

  saveView(viewId) {
    const promises = [];
    const currentProject = this.toProject();
    const contentPatch = this.getLastSavedVersionDiff(currentProject);

    const elementIdRegex = /elements\/id=([^/]*)([/]?.*)/;
    contentPatch.forEach((patch) => {
      if (patch.path.startsWith('/elements/')) {
        const [, id, propertyPath] = elementIdRegex.exec(patch.path);
        if (! id) return;

        if (propertyPath) { // update el
          const element = this.elements.find(item => item.id == id);
          if (element.access_right != 'modify') return;
          const promise = element.saveView(viewId, 'update').then(() => {
            const elInCurrentProject = currentProject.content.elements.find(item => item.id == id);
            if (elInCurrentProject) _.extend(elInCurrentProject, element.getAll()); // element.saveView may update the element with the api response
          });
          promises.push(promise);
        } else if (patch.op == 'remove') { // remove el
          const element = new PlanningElement(this, this.lastSavedProjectVersion.content.elements.find(item => item.id == id));
          const promise = element.saveView(viewId, 'destroy');
          promises.push(promise);
        } else if (patch.op == 'add') { // add el
          const element = this.elements.find(item => item.id == id);
          if (element.access_right != 'modify') return;
          const promise = element.saveView(viewId, 'store').then(() => {
            const elInCurrentProject = currentProject.content.elements.find(item => item.id == id);
            if (elInCurrentProject) _.extend(elInCurrentProject, element.getAll()); // element.saveView may update the element with the api response
          });
          promises.push(promise);
        }
      }
    });

    if (! promises.length) return Promise.resolve(false);

    return Promise.all(promises).then(() => {
      this.lastSavedProjectVersion = currentProject;
      return true;
    });
  }

  saveAdmin(data) {
    return ProjectSrv.saveAdmin(_.extend(angular.copy(data), { id: this.id }));
  }

  getTitle() {
    return this.meta && this.meta.title;
  }

  getUrl() {
    const title = this.getTitle() || "New project";
    const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname || '/'}`;
    return `${baseUrl}#/planning/${this.id}/${window.slugify(title)}`;
  }

  toCsv(fields, separator = ';') {
    const lanes = {};
    const lanesIndex = {};
    this.lanes.forEach((lane, index) => {
      lanes[lane.id] = lane;
      lanesIndex[lane.id] = index;
    });
    let csvContent = "";
    const elements = this.elements.filter(el => lanes[el.getLaneId()]).sort(
      (a, b) => (lanesIndex[a.getLaneId()] - lanesIndex[b.getLaneId()]) || (a.getStartTime() < b.getStartTime() ? -1 : 1),
    );
    elements.forEach((el) => {
      const dataString = fields.map((field) => {
        let val = field.split('.').reduce((acc, subfield) => acc && acc[subfield] || "", el.data);
        if (field == 'lane') val = (lanes[el.getLaneId()] || {}).label || "";
        if (field == 'starttime' || field == 'endtime') val = val && moment(val).format('L');
        if (el.isType('milestone') && field == 'endtime') val = "";
        if (field.startsWith('schedule.')) val = val && moment(val).format('HH:mm');
        if (field == 'checklist' && val) val = val.map(a => a && a.title && `- ${a.title}` || '').join('\n');
        if (field == 'users' && val) val = val.map(a => a && a.email || a.title || '').join('\n');
        if (field == 'links' && val) val = val.map(a => a && a.url || '').join('\n');
        if (field == 'budgets' && val) {
          val = val.map((a) => {
            const inProgress = a && a.amount_inprogress && `${a.amount_inprogress}/` || '';
            return a && (inProgress + (a.amount || '') + (a.icon ? ` ${a.icon}` : '') + (a.state ? ` (${a.state})` : '')) || '';
          }).join('\n');
        }
        val = val.toString();
        return `"${["-", "+"].indexOf(val[0]) > -1 ? ' ' : ''}${val.replace(/"/g, '""')}"`;
      }).join(separator);
      csvContent += `${dataString}\n`;
    });
    return csvContent;
  }
}

export default Planning;
