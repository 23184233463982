<template>
  <div class="d-flex gap-4 mb-4 flex-wrap">
    <v-card class="py-4 px-6 d-flex align-center overflow-visible">
      <div class="text-h6">
        <div class="text-h2 text-primary">{{ $filters.number(filteredProjects.length) }}</div>
        {{ $t('PORTFOLIO.PROJECTS_PLURAL', filteredProjects.length) }}
      </div>
      <div v-if="viewIsReady && allStats.moodData.total" class="ml-4 portfolio-stats-chart-wrapper">
        <chart-js :config="moodChart" :width="150" :height="150"></chart-js>
        <div style="position: absolute; top: 0; left: 0; width: 100px; height: 100px; pointer-events: none">
          <div v-for="(moodIcon, filteredMoodIndex) in filteredMoodIconOptions" :key="filteredMoodIndex"
               :style="chartMoodLabelPosition(filteredMoodIndex)" style="position: absolute; width: 32px; height: 32px; padding: 0px 2px 2px">
            <div :class="`mood-icon-${moodIcon}`"></div>
          </div>
        </div>
      </div>
      <template v-if="viewIsReady && allStats.endtimeData.total">
        <div class="px-4"></div>
        <div class="text-h6">
          <div class="text-h2 text-primary">{{ $filters.number(allStats.endtimeData.overdue || 0) }}</div>
          {{ $t('PORTFOLIO.OVERDUES_PLURAL', allStats.endtimeData.overdue) }}
        </div>
        <div class="ml-2 portfolio-stats-chart-wrapper">
          <chart-js :config="endtimeChart" :width="150" :height="150"></chart-js>
        </div>
      </template>
      <template v-if="viewIsReady && allStats.budgetData.total">
        <div class="px-4"></div>
        <div class="text-h6">
          <div class="text-h2 text-primary">{{ $filters.number(allStats.budgetData.out || 0) }}</div>
          {{ $t('PORTFOLIO.OUT_OF_BUDGET') }}
        </div>
        <div class="ml-2 portfolio-stats-chart-wrapper">
          <chart-js :config="budgetChart" :width="150" :height="150"></chart-js>
        </div>
      </template>
    </v-card>
    <v-card class="py-4 px-6 d-flex align-center overflow-visible">
      <div class="text-h6">
        <div class="text-h2 text-primary">{{ $filters.number(allStats.actionsData.total || 0) }}</div>
        {{ $t('PORTFOLIO.ACTIONS_PLURAL', allStats.actionsData.total) }}
      </div>
      <div v-if="viewIsReady && allStats.actionsData.total" class="ml-2 portfolio-stats-chart-wrapper">
        <chart-js :config="actionsChart" :width="150" :height="150"></chart-js>
      </div>
    </v-card>
    <v-card class="py-4 px-6 d-flex align-center overflow-visible">
      <div class="text-h6">
        <div class="text-h2 text-primary">{{ $filters.number(allStats.progressData.total || 0) }}</div>
        {{ $t('PORTFOLIO.BUBBLES_PLURAL', allStats.progressData.total) }}
      </div>
      <div v-if="viewIsReady && allStats.progressData.total" class="ml-2 portfolio-stats-chart-wrapper">
        <chart-js :config="progressChart" :width="150" :height="150"></chart-js>
      </div>
    </v-card>
  </div>
</template>

<style>
  .portfolio-stats-chart-wrapper {
    position: relative;
    width: 150px;
    height: 150px;
    margin-right: -50px;
    margin-bottom: -50px;
  }
</style>

<script>
  import ChartJs from '@/components/Reusables/ChartJs';

  export default {
    components: {
      ChartJs,
    },
    props: {
      filteredProjects: { type: Array, required: true },
      viewIsReady: { type: Boolean, default: true },
    },
    data() {
      return {
        moodIconOptions: ['sun', 'suncloud', 'cloud', 'storm'].reverse(),
        globalOptions: {
          layout: {
            padding: {
              right: 50,
              bottom: 50,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              color: 'white',
              formatter(value, context) {
                const percent = Math.round(value / context.dataset.total * 100);
                return percent > 9 ? `${percent}%` : '';
              },
            },
          },
        },
      };
    },
    computed: {
      allStats() {
        return this.filteredProjects.reduce((acc, project) => {
          if (project.moodIcon) {
            if (! acc.moodData[project.moodIcon]) acc.moodData[project.moodIcon] = 0;
            if (! acc.moodData.total) acc.moodData.total = 0;
            acc.moodData[project.moodIcon]++;
            acc.moodData.total++;
          }
          if (project.endtimeTarget && project.datesData.end) {
            if (! acc.endtimeData.total) acc.endtimeData.total = 0;
            const status = project.datesData.end.isAfter(project.endtimeTarget) ? 'overdue' : 'intime';
            acc.endtimeData[status] = (acc.endtimeData[status] || 0) + 1;
            acc.endtimeData.total = (acc.endtimeData.total || 0) + 1;
          }
          if (project.budgetTarget) {
            const maxBudget = Object.keys(project.budgetData).reduce((maxVal, icon) => Math.max(maxVal, project.budgetData[icon].amount_inprogress, project.budgetData[icon].amount), 0);
            const status = maxBudget <= project.budgetTarget ? 'in' : 'out';
            acc.budgetData[status] = (acc.budgetData[status] || 0) + 1;
            acc.budgetData.total = (acc.budgetData.total || 0) + 1;
          }
          Object.keys(project.actionsData).forEach((key) => {
            if (! acc.actionsData[key]) acc.actionsData[key] = 0;
            acc.actionsData[key] += project.actionsData[key];
          });
          Object.keys(project.progressData).forEach((key) => {
            if (! acc.progressData[key]) acc.progressData[key] = 0;
            acc.progressData[key] += project.progressData[key];
          });
          return acc;
        }, { moodData: {}, endtimeData: {}, budgetData: {}, actionsData: {}, progressData: {} });
      },
      filteredMoodIconOptions() {
        return this.moodIconOptions.filter(moodIcon => this.allStats.moodData[moodIcon]);
      },
      moodChart() {
        return {
          type: 'pie',
          data: {
            labels: this.moodIconOptions.map(moodIcon => this.$t(`MOOD.${moodIcon.toUpperCase()}`)),
            datasets: [{
              data: this.moodIconOptions.map(moodIcon => this.allStats.moodData[moodIcon] || 0),
              backgroundColor: [
                this.$vuetify.theme.current.colors.errorred,
                this.$vuetify.theme.current.colors.warningorange,
                this.$vuetify.theme.current.colors.warningorange,
                this.$vuetify.theme.current.colors.successgreen,
              ],
              borderWidth: 0,
            }],
          },
          options: angular.merge({}, this.globalOptions, {
            plugins: {
              datalabels: {
                display: false,
              },
            },
          }),
        };
      },
      endtimeChart() {
        return {
          type: 'pie',
          data: {
            labels: ['PROJECT_SHEET.OVERDUE', 'PORTFOLIO.IN_TIME'].map((item) => { const text = this.$t(item); return text.charAt(0).toUpperCase() + text.slice(1); }),
            datasets: [{
              data: [this.allStats.endtimeData.overdue, this.allStats.endtimeData.intime],
              total: this.allStats.endtimeData.total,
              backgroundColor: [
                this.$vuetify.theme.current.colors.errorred,
                this.$vuetify.theme.current.colors.successgreen,
              ],
              borderWidth: 0,
            }],
          },
          options: angular.copy(this.globalOptions),
        };
      },
      budgetChart() {
        return {
          type: 'pie',
          data: {
            labels: ['PORTFOLIO.OUT_OF_BUDGET', 'PORTFOLIO.IN_BUDGET'].map((item) => { const text = this.$t(item); return text.charAt(0).toUpperCase() + text.slice(1); }),
            datasets: [{
              data: [this.allStats.budgetData.out, this.allStats.budgetData.in],
              total: this.allStats.budgetData.total,
              backgroundColor: [
                this.$vuetify.theme.current.colors.errorred,
                this.$vuetify.theme.current.colors.successgreen,
              ],
              borderWidth: 0,
            }],
          },
          options: angular.copy(this.globalOptions),
        };
      },
      actionsChart() {
        return {
          type: 'pie',
          data: {
            labels: ['OVERDUE', 'URGENT', 'FINISHED', 'FUTURE'].map((item) => { const text = this.$t(`PROJECT_SHEET.${item}`); return text.charAt(0).toUpperCase() + text.slice(1); }),
            datasets: [{
              data: [this.allStats.actionsData.overdue, this.allStats.actionsData.urgent, this.allStats.actionsData.finished, this.allStats.actionsData.future],
              total: this.allStats.actionsData.total,
              backgroundColor: [
                this.$vuetify.theme.current.colors.errorred,
                this.$vuetify.theme.current.colors.warningorange,
                this.$vuetify.theme.current.colors.successgreen,
                this.$vuetify.theme.current.colors.midgrey,
              ],
              borderWidth: 0,
            }],
          },
          options: angular.copy(this.globalOptions),
        };
      },
      progressChart() {
        return {
          type: 'pie',
          data: {
            labels: ['OVERDUE', 'IN_PROGRESS', 'FINISHED', 'FUTURE'].map((item) => { const text = this.$t(`PROJECT_SHEET.${item}`); return text.charAt(0).toUpperCase() + text.slice(1); }),
            datasets: [{
              data: [this.allStats.progressData.overdue, this.allStats.progressData.urgent, this.allStats.progressData.finished, this.allStats.progressData.future],
              total: this.allStats.progressData.total,
              backgroundColor: [
                this.$vuetify.theme.current.colors.errorred,
                this.$vuetify.theme.current.colors.warningorange,
                this.$vuetify.theme.current.colors.successgreen,
                this.$vuetify.theme.current.colors.midgrey,
              ],
              borderWidth: 0,
            }],
          },
          options: angular.copy(this.globalOptions),
        };
      },
    },
    methods: {
      chartLabelPosition(start, percent) {
        const angle = Math.PI * (0.5 - 2 * start - percent);
        const x = Math.cos(angle) / 2;
        const y = Math.sin(angle) / 2;
        return { top: `calc(${(1 - y) * 50}% - 16px)`, left: `calc(${(x + 1) * 50}% - 16px)` };
      },
      chartMoodLabelPosition(filteredMoodIndex) {
        const { moodData } = this.allStats;
        const moodIcon = this.filteredMoodIconOptions[filteredMoodIndex];
        const start = this.filteredMoodIconOptions.slice(0, filteredMoodIndex).reduce((tot, icon) => tot + (moodData[icon] || 0), 0) / moodData.total;
        const percent = moodData[moodIcon] / moodData.total;
        return this.chartLabelPosition(start, percent);
      },
    },
  };
</script>
