<template>
  <msg-box :backdrop-close="true" @close="close">
    <template #title>
      <div class="d-flex align-center text-center" style="width: 100%">
        <div class="flex-grow-1">
          <v-btn-icon class="ml-auto" @click="currentIndex = (currentIndex - 1 + slides.length) % slides.length">
            <v-icon>far fa-chevron-left</v-icon>
          </v-btn-icon>
        </div>
        <div class="flex-grow-1">
          {{ $t('HELP.TIP') }} {{ (currentIndex + 1) + ' / ' + slides.length }}
        </div>
        <div class="flex-grow-1">
          <v-btn-icon @click="currentIndex = (currentIndex + 1) % slides.length">
            <v-icon>far fa-chevron-right</v-icon>
          </v-btn-icon>
        </div>
      </div>
    </template>

    <div class="text-center">
      <img :src="slides[currentIndex].url" style="max-width:100%">
    </div>

    <template #buttons>
      <div class="text-center">
        <v-btn size="large" variant="outlined" rounded style="min-width: 100px" @click="close">{{ $t('GLOBAL.CLOSE') }}</v-btn>
      </div>
    </template>
  </msg-box>
</template>

<script>
  import tips1 from './img/tips/1.jpg';
  import tips2 from './img/tips/2.jpg';
  import tips3 from './img/tips/3.jpg';
  import tips4 from './img/tips/4.jpg';
  import tips5 from './img/tips/5.jpg';
  import tips6 from './img/tips/6.jpg';

  export default {
    emits: ['close'],
    data() {
      return {
        currentIndex: 0,
        slides: [{
          url: tips1,
        }, {
          url: tips2,
        }, {
          url: tips3,
        }, {
          url: tips4,
        }, {
          url: tips5,
        }, {
          url: tips6,
        }],
      };
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
