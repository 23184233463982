import constants from '@/js/constants';
import PlanningLane from '@/models/PlanningLane';

export default {
  data() {
    return {
      laneHeaderHeight: 0,
      resizeObserver: null,
    };
  },
  beforeUnmount() {
    if (this.resizeObserver) this.resizeObserver.disconnect();
  },
  computed: {
    isKanbanMode() {
      return this.$store.state.ui.planning.mode == 'kanban';
    },
    laneContentHeight() {
      const laneId = this.lane.id;
      let lane = this.$store.getters['planning/lanes/lanesMap'].get(laneId);
      let els = this.laneElements;
      if (! lane) {
        const subprojectLane = this.$store.getters['subprojects/getLaneSubProject'](laneId);
        if (! subprojectLane || ! subprojectLane.lane) return 0;
        lane = subprojectLane.lane;
        els = this.$store.getters['planning/lanes/getLaneElements']({ planning: subprojectLane.planning, laneId });
      }
      if (lane.hidden) {
        lane.contentHeight = constants.hiddenLaneHeight;
        this.lane.contentHeight = lane.contentHeight;
        return this.lane.contentHeight;
      }
      const minheight = this.$store.state.planning.lanes.length > 1 ? constants.laneMinHeight : 3 * constants.laneMinHeight; // default min value / tripled when only lane
      let height = 0;
      els = els.filter(el => el.isVisibleOnPlanning() && el.height);
      if (! els.length) { // lane empty
        height = minheight;
      } else {
        const yPositions = PlanningLane.getAllYposition(els);
        els.forEach((el) => {
          _.extend(el, yPositions.get(el.id));
        });
        height = Math.max.apply(null, els.map(o => (o.yposition || 0) + (o.height || 0)));
      }
      lane.contentHeight = Math.ceil(Math.max(minheight, height) + constants.lanePadding + 1);
      this.lane.contentHeight = lane.contentHeight;
      return this.lane.contentHeight;
    },
    laneHeight() {
      if (! this.laneHeaderHeight) this.updateLaneHeaderHeight();
      this.lane.height = Math.max(this.laneContentHeight, this.laneHeaderHeight);
      return this.lane.height;
    },
    kanbanLaneHeaderMinHeight() {
      const laneMinHeight = constants.laneMinHeight + constants.lanePadding + 1;
      return Math.max(laneMinHeight, this.laneHeaderHeight);
    },
    laneHeaderHeightReactiveProperties() {
      return { label: this.lane.label, monitoringplan: this.lane.monitoringplan, hidden: this.lane.hidden };
    },
  },
  watch: {
    laneHeaderHeightReactiveProperties: {
      handler() { this.updateLaneHeaderHeight(); },
      deep: true,
    },
  },
  methods: {
    updateLaneHeaderHeight() {
      this.$nextTick(() => {
        this.laneHeaderHeight = this.$store.getters['planning/lanes/getLaneHeaderHeight'](this.lane.id);
      });
    },
    createLaneHeightObserver() {
      if (! this.laneHeaderHeight) this.updateLaneHeaderHeight();
      const laneContent = document.getElementById(`lane${this.lane.id}`);
      if (! laneContent) return;
      this.resizeObserver = new ResizeObserver(() => {
        if (this.isKanbanMode) {
          this.lane.height = laneContent.offsetHeight;
        }
      });
      this.resizeObserver.observe(laneContent);
    },
  },
};
