<template>
  <div class="px-4 d-flex">
    <div class="hidden-md-and-down mr-2" style="width: 250px; flex: 0 0 250px">
      <div style="position: fixed; top: calc(var(--v-layout-top) + 30px); bottom: 0; width: 250px">
        <v-fade-transition>
          <div v-if="user.id" class="px-2 d-flex flex-column h-100">
            <div class="text-h6 d-flex">
              <user-avatar :user="user" :title="$t('ACCOUNT.CHANGE_AVATAR')" class="mr-4" :size="32"></user-avatar>
              {{ $t('HOME.WELCOME') }}, {{ user.firstname }} !
            </div>
            <div class="text-medium-emphasis mt-2">
              {{ $t('HOME.WELCOME_MESSAGE') }}
            </div>
            <div class="mt-auto"></div>
            <div class="hidden-small-height position-relative" style="flex: 1 1 100%; max-height: 400px">
              <div class="text-center" style="position: absolute; inset: 24px 0">
                <img v-reload-src-on-mount src="@/img/animations/illustration-home.gif" style="max-height: 100%; max-width: 100%">
              </div>
            </div>
            <div v-if="user.company && user.company.logo" class="mb-3 text-center" style="height: 50px; line-height: 50px">
              <img :src="user.company.logo" style="height: 100%; max-width: 200px">
            </div>
          </div>
        </v-fade-transition>
      </div>
    </div>
    <div class="home-user pb-6 pl-2 flex-grow-1 overflow-hidden">
      <div v-if="! dashboardInfo && ! errors" class="pa-4 text-center" style="font-size:18px"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
      <div v-if="errors">{{ errors }}</div>
      <v-fade-transition>
        <v-row v-if="dashboardInfo" class="mt-0">
          <v-col v-if="! dashboardInfo.statistics.plannings_count && ! dashboardInfo.statistics.bubble_meetings_count" cols="12">
            <v-card class="pa-6">
              <v-card-title class="d-flex justify-center">
                <h3>{{ $t('HOME.FIRST_STEPS') }}</h3>
              </v-card-title>
              <v-card-text class="mx-auto" style="max-width: 400px">
                <p>{{ $t('HOME.FIRST_STEPS_MESSAGE') }}</p>
                <div class="text-center mt-4">
                  <v-btn color="primary" rounded size="large" class="text-uppercase" @click="openOnBoardingWelcomeModal">{{ $t('HOME.OPEN_ONBOARDING') }}</v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col :style="{ opacity: (dashboardInfo.statistics.plannings_count + dashboardInfo.statistics.bubble_meetings_count) ? '' : .5 }" sm="6" cols="12" class="pr-lg-5">
            <template v-if="hasMeeting && dashboardBubbleMeetings.length">
              <div class="d-flex align-center ml-2 mb-2">
                <a href="./meeting/" class="text-h6 text-meetingprimary">{{ $t('MEETING.BUBBLE_MEETING') }}</a>
                <v-tooltip location="left" max-width="200">
                  <template #activator="{ props }">
                    <v-icon color="info" size="small" class="ml-2" v-bind="props">far fa-question-circle</v-icon>
                  </template>
                  <span>{{ $t('HOME.BUBBLE_MEETING_EXPLANATIONS') }}</span>
                </v-tooltip>
              </div>
              <div>
                <v-card v-if="! dashboardBubbleMeetings.length" class="pa-6 text-medium-emphasis">
                  {{ $t('HOME.NO_MEETING_ACTIVITY') }}
                </v-card>
                <v-row v-else class="light-scrollbar mb-0" style="max-height: 400px; overflow: auto">
                  <v-col v-for="meeting in dashboardBubbleMeetings" :key="meeting.id" md="6" cols="12" class="text-center">
                    <v-card-clickable :href="`./meeting/#/meeting/${meeting.id}`" style="height: 100%">
                      <v-card-text>
                        {{ meeting.title || $t('MEETING.UNNAMED_MEETING') }}
                        <div v-if="meeting.date" class="text-medium-emphasis">{{ $filters.moment(meeting.date, 'datetime') }}</div>
                        <div>
                          <a v-if="meeting.actions_count" :href="`./meeting/#/meeting/${meeting.id}`" :title="$t('HOME.MEETINGACTIONS_ICON_EXPLANATION')" class="link">
                            <span style="color: #484848">{{ meeting.actions_count }} <i class="far fa-check-square"></i></span>
                          </a>
                        </div>
                      </v-card-text>
                    </v-card-clickable>
                  </v-col>
                </v-row>
              </div>
              <div class="my-6"></div>
            </template>

            <home-user-projects :plannings="dashboardInfo.plannings"></home-user-projects>
            <div class="my-12"></div>
            <home-user-tasks :elements="dashboardInfo.elements" :non-assigned-tasks="dashboardInfo.non_assigned_tasks" @save-el="saveEl"></home-user-tasks>
          </v-col>
          <v-col :style="{ opacity: (dashboardInfo.statistics.plannings_count + dashboardInfo.statistics.bubble_meetings_count) ? '' : .5 }" sm="6" cols="12" class="pl-lg-5">
            <home-user-actions :actions="dashboardInfo.actions" :dashboard-elements="dashboardInfo.elements" @save-el="saveEl"></home-user-actions>
            <div class="my-12"></div>
            <home-user-milestones :elements="dashboardInfo.elements"></home-user-milestones>
          </v-col>
        </v-row>
      </v-fade-transition>
    </div>
  </div>
</template>

<style>
  .home-user ul {
    margin-bottom: 0;
    padding: 0 0 16px !important;
    list-style: none;
  }
  .home-user li {
    padding: 2px 0;
  }

  .home-user .link {
    padding: 0 2px;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: all .3s;
  }

  .home-user .link:hover {
    text-decoration: none;
    background-color: #e6e6e6;
    border-color: #ddd;
  }

  @media (max-height: 600px) {
    .hidden-small-height {
      display: none;
    }
  }
</style>

<script>
  import { mapState } from 'vuex';
  import Planning from '@/models/Planning';
  import PlanningElement from '@/models/PlanningElement';
  import HomeUserProjects from './HomeUserProjects.vue';
  import HomeUserTasks from './HomeUserTasks.vue';
  import HomeUserMilestones from './HomeUserMilestones.vue';
  import HomeUserActions from './HomeUserActions.vue';

  function projectUrl(projectId, projectTitle, isConsult) {
    return `/${isConsult ? 'viewer/' : 'planning/'}${projectId}/${window.slugify(projectTitle || "New project")}`;
  }

  export default {
    components: {
      HomeUserProjects,
      HomeUserTasks,
      HomeUserMilestones,
      HomeUserActions,
    },
    data() {
      return {
        dashboardInfo: null,
        errors: "",
      };
    },
    computed: {
      dashboardBubbleMeetings() {
        return this.dashboardInfo.bubble_meetings;
      },
      hasMeeting() { return this.accessRight.hasMeeting; },
      ...mapState('users', ['user', 'accessRight']),
    },
    created() {
      window.apiSrv.call('dashboards/home', 'index').then((result) => {
        this.dashboardInfo = result.data.dashboard;
        (this.dashboardInfo.plannings || []).forEach((planning) => {
          planning.imgurl = ((planning.imageexport_name || '').startsWith('http') ? '' : window.apiSrv.snapBaseUrl) + planning.imageexport_name;
          planning.url = projectUrl(planning.id, planning.title, planning.access_right == 'consult');
          planning.priority = 1000 * (planning.notifications_count || 0) + 100 * (planning.actions_late_count || 0) + 10 * (planning.actions_urgent_count || 0);
          planning.priority += (planning.milestones_count || 0) + (planning.tasks_count || 0);
        });

        const plannings = {};
        ['elements', 'non_assigned_tasks'].forEach((prop) => {
          if (! this.dashboardInfo[prop]) return;
          this.dashboardInfo[prop] = this.dashboardInfo[prop].reduce((acc, item) => {
            if (! item.starttime || ! item.endtime) return acc;
            const meta = item.meta || {}; // project_id, project_title, project_access_right, lane_label, status
            if (! plannings[meta.project_id]) plannings[meta.project_id] = new Planning();
            const planning = plannings[meta.project_id];
            planning.id = meta.project_id;
            planning.meta = { ...planning.meta, title: meta.project_title, access_right: meta.project_access_right };
            const element = new PlanningElement(planning, item);
            delete element.data.meta;
            Object.keys(meta).forEach((metaProp) => { element[metaProp] = meta[metaProp]; });
            element.id = `${element.id}.${window.uuid()}`;
            element.setConfig({
              ...element.getConfig(),
              "show-description": false,
              "show-dates": false,
              "show-progress": true,
              "show-checklist": true,
              "show-users": false,
              "show-links": false,
              "show-budgets": false,
              "show-projecttitle": false,
            });
            acc.push(element);
            return acc;
          }, []);
        });
      }).catch((message) => {
        this.errors = message;
      });
    },
    methods: {
      openOnBoardingWelcomeModal() {
        this.$store.commit('ui/setOnBoardingWelcomeModal', true);
      },
      saveEl({ el, props = ['checklist', 'progress'], oldStateParam }) {
        const planning = el.getPlanning();
        const newState = _.extend(new PlanningElement(planning, el), { id: parseInt(el.id, 10) });
        planning.saveElement(newState, oldStateParam, { props }).then((contentPatch) => {
          if (contentPatch) window.notificationsSrv.callEvent('projectSaved', { contentPatch, planning_id: planning.id, planning_title: planning.meta?.title });
        });
      },
    },
  };
</script>
