import io from 'socket.io-client';
import store from '@/store';

const socket = io('https://socket.bubbleplan.net', { // 'http://localhost:3003'
  autoConnect: false,
});

store.state.users.userPromise.then(() => {
  socket.io.opts.query = {
    token: store.state.users.user.jwt,
  };
  socket.open();
});

socket.on('welcome', (message) => {
  console.log(message);
});

socket.on('error', (message) => {
  console.error(message);
});

export default socket;
