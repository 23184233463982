export default {
  namespaced: true,
  state: {
    all: [],
  },
  mutations: {
    set(state, values) {
      state.all = values;
    },
  },
  getters: {
    unseen: state => state.all.filter(notif => ! notif.seen_at),
  },
  actions: {
    load({ commit, rootGetters }) {
      return window.apiSrv.call('notifications', 'index').then((response) => {
        const notifications = response?.data?.notifications;
        if (! notifications) throw new Error('invalid notifications');
        notifications.forEach((notif) => {
          notif.user = rootGetters['users/getUserById'](notif.created_by);
        });
        commit('set', notifications);
      });
    },
    seen({ state, commit, rootGetters }, { filters } = {}) {
      if (! state.all.filter(notif => ! notif.seen_at).length) return Promise.resolve('nothing to do');
      return window.apiSrv.call('notifications/seen', 'store', filters || {}).then((response) => {
        const notifications = response?.data?.notifications;
        if (! notifications) throw new Error('invalid notifications');
        notifications.forEach((notif) => {
          notif.user = rootGetters['users/getUserById'](notif.created_by);
        });
        commit('set', notifications);
      });
    },
  },
};
