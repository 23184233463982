/* eslint-disable import/prefer-default-export */
/* Creates a listener for the beforeunload event to alert the user if they try to leave the page with unsaved changes */
import { onBeforeUnmount } from 'vue';

export function useAlertBeforeUnload(element, callback) {
  function alertBeforeUnload(event) {
    const oldState = element?.getPlanning()?.lastSavedProjectVersion?.content.elements.find(el => el.id === element.id);
    const isNotDirty = oldState && element.generateContentPatch(oldState).length === 0;
    if (isNotDirty) return;

    event.preventDefault();
    event.returnValue = true; // legacy support Chrome/Edge < 119
    if (callback) callback();
  }

  window.addEventListener('beforeunload', alertBeforeUnload);
  onBeforeUnmount(() => window.removeEventListener('beforeunload', alertBeforeUnload));
}
