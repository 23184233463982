<template>
  <v-autocomplete v-model="selectedAllUsers"
                  :items="allUsers"
                  :placeholder="active && selectedAllUsers.length == 1 ? $t('PERSONAL_CALENDAR.PARTICIPANT') : $t('MULTIPROJECTS.PARTICIPANTS')"
                  item-title="username" item-value="id"
                  variant="plain" density="compact" multiple
                  return-object :menu-props="{ location: 'bottom' }"
                  :class="{ 'autocomplete-active': active }"
                  class="working-views-filter-users rounded"
                  @update:model-value="update">
    <template #prepend-item>
      <v-list-item @click.stop>
        <v-switch v-model="configUsers.unassigned" :label="$t('MULTIPROJECTS.INCLUDE_UNASSIGNED')" @update:model-value="update">
        </v-switch>
      </v-list-item>
      <filter-toggle :selected-ids="selectedCompanyUsers" :sublist-ids="companyUserIds"
                     @update="selectedCompanyUsers = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_COMPANY_USERS') }} ({{ companyUserIds.length }})
      </filter-toggle>
      <filter-toggle :selected-ids="selectedVirtualParticipants" :sublist-ids="virtualParticipantIds"
                     @update="selectedVirtualParticipants = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_VIRTUAL_PARTICIPANTS') }} ({{ virtualParticipantIds.length }})
      </filter-toggle>
      <v-divider></v-divider>
      <v-list-group v-if="companyGroups.length" fluid>
        <template #activator="{ props }">
          <v-list-item v-bind="props">
            <v-list-item-title class="ml-4">{{ $t('GLOBAL.GROUPS') }}</v-list-item-title>
          </v-list-item>
        </template>
        <filter-toggle v-for="group in companyGroups" :key="group.id"
                       :selected-ids="selectedCompanyUsers" :sublist-ids="userIdsByGroupId[group.id]" indent
                       @update="selectedCompanyUsers = $event; update()">
          {{ group.title }} ({{ group.users.length }})
        </filter-toggle>
        <v-divider></v-divider>
      </v-list-group>
    </template>
    <template #item="{ props, item: { raw: item } }">
      <v-divider v-if="item.divider"></v-divider>
      <v-list-subheader v-else-if="item.header" :title="item.header"></v-list-subheader>
      <v-list-item v-else v-bind="props">
        <template #prepend>
          <user-avatar :user="item" :size="32"></user-avatar>
        </template>
        <template #title>
          {{ item.username }}
        </template>
      </v-list-item>
    </template>
    <template #selection="{ index }">
      <span v-if="active && index === 0" class="text-white small nobr">{{ selectedAllUsers.length }}&nbsp;<i class="far fa-user"></i></span>
    </template>
  </v-autocomplete>
</template>

<style lang="scss">
  .working-views-filter-users {
    cursor: pointer;
    width: 150px;
    padding-right: 6px;

    input {
      cursor: pointer;
    }

    .v-field__input {
      height: 36px;
      min-height: 36px;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 12px;
    }

    .v-field__input, .v-field__append-inner > .v-icon {
      opacity: 1;
    }

    input::placeholder {
      font-size: 14px;
      opacity: 1;
    }

    &.autocomplete-active {
      background-color: rgb(var(--v-theme-successgreen)) !important;
      color: rgb(var(--v-theme-on-successgreen)) !important;
    }
  }
</style>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
      configElementsUsers: { type: Object, default: () => ({}) },
      active: { type: Boolean, default: false },
    },
    emits: ['update:config-elements-users'],
    data() {
      return {
        configUsers: {},
        subprojectsUsers: { companyUsersById: {}, virtualParticipantsById: {} },
      };
    },
    computed: {
      selectedCompanyUsers: {
        get() {
          return this.configUsers.selectedCompanyUsers;
        },
        set(newVal) {
          this.configUsers.selectedCompanyUsers.splice(0, this.configUsers.selectedCompanyUsers.length, ...newVal);
        },
      },
      selectedVirtualParticipants: {
        get() {
          return this.configUsers.selectedVirtualParticipants;
        },
        set(newVal) {
          this.configUsers.selectedVirtualParticipants.splice(0, this.configUsers.selectedVirtualParticipants.length, ...newVal);
        },
      },
      selectedAllUsers: {
        get() {
          const selectedAllUsers = [];
          this.companyUsers.forEach((user) => {
            if (this.selectedCompanyUsers.indexOf(user.id) == -1) return;
            selectedAllUsers.push(user);
          });
          this.virtualParticipants.forEach((user) => {
            if (this.selectedVirtualParticipants.indexOf(user.username) == -1) return;
            selectedAllUsers.push(user);
          });
          return selectedAllUsers;
        },
        set(newVal) {
          const newValArray = newVal === "" ? this.selectedAllUsers : newVal;
          this.selectedCompanyUsers = [];
          this.selectedVirtualParticipants = [];
          newValArray.forEach((user) => {
            if (user.id) {
              this.selectedCompanyUsers.push(user.id);
            } else {
              this.selectedVirtualParticipants.push(user.username);
            }
          });
        },
      },
      allUsers() {
        return this.companyUsers.concat(this.virtualParticipants);
      },
      allUsersCount() {
        return this.allUsers.length;
      },
      projectUsers() {
        const companyUsersById = {};
        const virtualParticipantsById = {};
        this.planning.elements.forEach((item) => {
          (item.getUsers() || []).forEach((user) => {
            if (user.id) {
              if (! companyUsersById[user.id]) {
                const username = this.$store.getters['users/getUsername'](user);
                companyUsersById[user.id] = { ...user, username };
              }
            } else {
              const username = user.username || user.email;
              if (username && ! virtualParticipantsById[username]) {
                virtualParticipantsById[username] = { username };
              }
            }
          });
        });
        return { companyUsersById, virtualParticipantsById };
      },
      companyUsers() {
        const companyUsers = Object.values(this.projectUsers.companyUsersById);
        Object.keys(this.subprojectsUsers.companyUsersById).forEach((subUserId) => {
          if (! this.projectUsers.companyUsersById[subUserId]) {
            companyUsers.push(this.subprojectsUsers.companyUsersById[subUserId]);
          }
        });
        return companyUsers.sort((a, b) => (a.username < b.username ? -1 : 1));
      },
      companyUserIds() {
        return this.companyUsers.map(item => item.id);
      },
      virtualParticipants() {
        const virtualParticipants = Object.values(this.projectUsers.virtualParticipantsById);
        Object.keys(this.subprojectsUsers.virtualParticipantsById).forEach((subUsername) => {
          if (! this.projectUsers.virtualParticipantsById[subUsername]) {
            virtualParticipants.push(this.subprojectsUsers.virtualParticipantsById[subUsername]);
          }
        });
        return virtualParticipants.sort((a, b) => (a.username < b.username ? -1 : 1));
      },
      virtualParticipantIds() {
        return this.virtualParticipants.map(item => item.username);
      },
      companyGroups() {
        const companyGroups = [];
        (this.$store.getters['users/groups/getCompanyGroups'] || []).forEach((group) => {
          const groupUsers = group.users.filter(user => this.companyUserIds.includes(user.id)); // keep only projectUsers
          if (groupUsers.length) companyGroups.push({ ...group, users: groupUsers });
        });
        return companyGroups;
      },
      userIdsByGroupId() {
        return this.companyGroups.reduce((acc, group) => {
          acc[group.id] = group.users.map(user => user.id);
          return acc;
        }, {});
      },
    },
    watch: {
      configElementsUsers: {
        handler() {
          this.configUsers = {
            selectedCompanyUsers: this.configElementsUsers.selectedCompanyUsers,
            selectedVirtualParticipants: this.configElementsUsers.selectedVirtualParticipants,
            unassigned: this.configElementsUsers.unassigned,
          };
          // Initialize the data of the users filter
          // known bug : this filters out selected subprojects users when you close then reopen
          this.configUsers.selectedCompanyUsers = _.intersection(this.configUsers.selectedCompanyUsers, this.companyUserIds); // handle deleted
          this.configUsers.selectedVirtualParticipants = _.intersection(this.configUsers.selectedVirtualParticipants, this.virtualParticipantIds); // handle deleted
        },
        immediate: true,
      },
    },
    created() {
      if (this.$store.state.subprojects.promises.length) { // load subprojects users to add in filter
        Promise.all(this.$store.state.subprojects.promises).then(() => {
          const companyUsersById = {};
          const virtualParticipantsById = {};
          this.$store.getters['subprojects/getAll'].forEach((subproject) => {
            subproject.elements.forEach((item) => {
              (item.getUsers() || []).forEach((user) => {
                if (user.id) {
                  if (! companyUsersById[user.id]) {
                    const username = this.$store.getters['users/getUsername'](user);
                    companyUsersById[user.id] = { ...user, username };
                  }
                } else {
                  const username = user.username || user.email;
                  if (username && ! virtualParticipantsById[username]) {
                    virtualParticipantsById[username] = { username };
                  }
                }
              });
            });
          });
          this.subprojectsUsers = { companyUsersById, virtualParticipantsById };
        });
      }
    },
    methods: {
      update() {
        this.$emit('update:config-elements-users', this.configUsers);
      },
    },
  };
</script>
