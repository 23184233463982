/* eslint-disable object-property-newline */
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { VBtn, VBtnToggle, VTextField, VSelect, VAutocomplete, VCombobox, VTabs, VCard } from 'vuetify/components';
import { aliases, fa } from 'vuetify/iconsets/fa';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { useI18n } from 'vue-i18n';
import MomentAdapter from '@date-io/moment';
import i18n from '@/js/plugins/i18n';

const fieldDefaults = {
  hideDetails: 'auto', variant: 'outlined', density: 'comfortable', itemColor: 'primary',
  VIcon: { size: 'small', class: 'mr-.5' },
};

const filterFieldDefaults = {
  hideDetails: 'auto', variant: 'solo', flat: true, bgColor: 'secondary', density: 'compact', itemColor: 'primary',
  VIcon: { size: 'small', class: 'mr-.5' },
};

export default createVuetify({
  date: {
    adapter: MomentAdapter,
  },
  aliases: {
    BtnToggleFilter: VBtnToggle,
    TextFilter: VTextField,
    SelectFilter: VSelect,
    AutocompleteFilter: VAutocomplete,
    ComboboxFilter: VCombobox,

    TabsVertical: VTabs,
    VCardClickable: VCard,
    VBtnIcon: VBtn,
  },
  defaults: {
    VBtn: { variant: 'elevated' },
    VBtnIcon: {
      variant: 'text', icon: true,
    },
    VCard: { flat: true },
    VCardClickable: { hover: true, ripple: false, class: 'color-inherit text-decoration-none' },
    VSwitch: { hideDetails: 'auto', color: 'primary', density: 'comfortable' },
    VCheckbox: { hideDetails: 'auto', color: 'primary', density: 'comfortable' },
    VCheckboxBtn: { color: 'primary' },
    VRadioGroup: { hideDetails: 'auto', color: 'primary', density: 'comfortable' },
    VTextField: fieldDefaults,
    VTextarea: fieldDefaults,
    VSelect: fieldDefaults,
    VAutocomplete: fieldDefaults,
    VCombobox: fieldDefaults,
    VToolbar: {
      VBtn: { variant: 'elevated' },
    },
    VCardActions: {
      VBtn: { variant: 'elevated' },
    },
    VPagination: { density: 'comfortable', rounded: true },
    VDataTable: { sortAscIcon: 'fas fa-caret-up ml-1', sortDescIcon: 'fas fa-caret-down ml-1', itemsPerPage: '-1', mustSort: true },
    BtnToggleFilter: {
      density: 'comfortable', divided: true,
      class: 'filter-secondary',
      VBtn: { size: 'large' },
    },
    TextFilter: filterFieldDefaults,
    SelectFilter: filterFieldDefaults,
    AutocompleteFilter: filterFieldDefaults,
    ComboboxFilter: filterFieldDefaults,
    TabsVertical: {
      direction: 'vertical', hideSlider: true, class: 'tabs-vertical-secondary',
      VTab: { density: 'comfortable', size: 'large' },
      VTabs: { // nested tabs
        direction: 'vertical', hideSlider: true,
        VTab: { density: 'compact', size: 'default', class: 'my-.5' },
      },
    },
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    themes: {
      light: {
        colors: {
          background: '#f7f7fa', // hsl(240deg, 23%, 97%)
          white: "#fff",
          primary: "#0169C1",
          secondary: '#DCE4F2', // "#859AB5",
          accent: "#43A047",
          successgreen: '#43A047', // "#00b100", // charted : #43c63e
          warningorange: '#FF7F11', // "#ff9b1d",
          errorred: '#D32F2F', // "#f82223",
          info: '#8057D5', // "#8a92eb",
          meetingprimary: "#ff9b1d",
          midgrey: "#9e9e9e",
          lightgrey: "#ececec",
          darkgrey: "#666",
        },
      },
    },
  },
});
