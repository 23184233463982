<template>
  <div class="home-user-tasks">
    <div class="d-flex align-center ml-2 mb-2">
      <template v-if="myTasks.length || ! showNonAssignedTasks">
        <span class="text-h6">{{ $t('HOME.MY_BUBBLES_NEWS') }}</span>
        <v-tooltip location="left" max-width="200">
          <template #activator="{ props }">
            <v-icon color="info" size="small" class="ml-2" v-bind="props">far fa-question-circle</v-icon>
          </template>
          <span>{{ $t('HOME.BUBBLES_EXPLANATIONS') }}</span>
        </v-tooltip>
      </template>
      <template v-else>
        <span class="text-h6">{{ $t('HOME.NON_ASSIGNED_BUBBLES_NEWS') }}</span>
        <v-tooltip location="left" max-width="200">
          <template #activator="{ props }">
            <v-icon color="info" class="ml-auto" v-bind="props">far fa-question-circle</v-icon>
          </template>
          <span>{{ $t('HOME.NON_ASSIGNED_BUBBLES_EXPLANATIONS') }}</span>
        </v-tooltip>
      </template>
    </div>
    <div>
      <v-card v-if="! myTasks.length && ! showNonAssignedTasks" class="pa-6">
        <div class="text-medium-emphasis">
          {{ $t('HOME.NO_ASSIGNED_BUBBLE_ACTIVITY') }}
        </div>
        <div class="py-2"><a class="pointer" @click="showNonAssignedTasks = true">{{ $t('HOME.SHOW_NON_ASSIGNED_BUBBLES_NEWS') }}</a></div>
      </v-card>
      <v-card v-else-if="! tasks.length" class="pa-6 text-medium-emphasis">
        {{ $t('HOME.NO_BUBBLE_ACTIVITY') }}
      </v-card>
      <v-row v-else class="flex-nowrap overflow-x-auto">
        <v-col v-for="col in filteredTaskLists" :key="col.id" :col="12 / filteredTaskLists.length" style="min-width: 260px">
          <v-card class="h-100 overflow-visible">
            <div :class="`text-${col.color}`" class="px-3 py-2 mb-2 bg-lightgrey rounded-t d-flex align-center">
              <v-icon class="mr-2" size="small">{{ col.icon }}</v-icon>
              {{ $t(col.label) }}
            </div>
            <div class="px-2 pb-2">
              <template v-for="(el, index) in filteredOrderedTasksPerCol[col.id]" :key="el.id">
                <div v-if="! index || el.project_id != filteredOrderedTasksPerCol[col.id][index - 1].project_id"
                     class="mb-1 text-medium-emphasis">
                  <v-icon size="small" class="mr-1 mb-.5">far fa-folder-open</v-icon>
                  <router-link :to="projectUrl(el)" style="color: inherit">
                    <i class="small">{{ el.project_title }}</i>
                  </router-link>
                </div>
                <div class="text-center mb-2">
                  <v-row no-gutters>
                    <v-col :class="{ strong: col.id == 'starts_soon' }" cols="2" class="nobr" style="font-size: 75%">
                      {{ $filters.moment(el.getStartTime(), 'mediumDateNoYear') }}
                    </v-col>
                    <v-col cols="8">
                      <planning-element-view :id="`el${el.id}`" :el="el" :title="el.display_title || ''" :class="{ 'can-edit': canEditEl(el) }"
                                             style="cursor: default; position: relative; font-size: 75%" disable-mini-element
                                             @checklist-click="checklistClick(el, $event)" @progress-click="progressClick(el, $event)">
                        <template v-if="canEditEl(el)" #hover-menu>
                          <div class="element-menu d-flex align-end" style="left: 100%; width:52px">
                            <v-btn v-if="el.getProgress() != 100" :title="$t('MONITORING_PROGRESS.VALIDATE_PROGRESS')"
                                   icon size="x-small" variant="outlined" style="margin-right: 2px !important"
                                   @click="validateProgress(el)">
                              <v-icon color="accent" size="large">far fa-check</v-icon>
                            </v-btn>
                            <v-btn :title="$t('HOME.OPEN_IN_PLANNING')" :to="elementUrl(el)"
                                   icon size="x-small" variant="outlined">
                              <v-icon color="primary" size="large">far fa-external-link</v-icon>
                            </v-btn>
                          </div>
                        </template>
                      </planning-element-view>
                    </v-col>
                    <v-col :class="{ strong: col.id == 'ends_soon' }" cols="2" class="nobr" style="font-size: 75%">
                      {{ $filters.moment(el.getEndTime(), 'mediumDateNoYear') }}
                    </v-col>
                  </v-row>
                </div>
              </template>
              <div v-if="tasksPerCol[col.id].length > showItemsLimit" class="pointer mt-4 text-center text-primary"
                   @click="showAllItems[col.id] = ! showAllItems[col.id]">
                <i :class="showAllItems[col.id] ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'" class="far"></i>
                {{ showAllItems[col.id] ? $t('HOME.REDUCE') : $t('HOME.SEE_MORE', tasksPerCol[col.id].length - showItemsLimit) }}
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style>
  .home-user-tasks .element.can-edit .checklist .far, .home-user-tasks .element.can-edit .users-bar .v-progress-linear {
    cursor: pointer;
  }
</style>

<script>
  import PlanningElementView from '@/components/Planning/PlanningElementView';

  export default {
    components: {
      PlanningElementView,
    },
    props: {
      elements: { type: Array, required: true },
      nonAssignedTasks: { type: Array, default: () => ([]) },
    },
    emits: ['save-el'],
    data() {
      return {
        taskLists: [
          { id: 'starts_soon', label: "HOME.STARTS_SOON", color: 'successgreen', icon: 'far fa-rocket', orderBy: 'data.starttime' },
          { id: 'ends_soon', label: "HOME.ENDS_SOON", color: 'warningorange', icon: 'far fa-clock', orderBy: 'data.endtime' },
          { id: 'current', label: "HOME.CURRENT", color: 'primary', icon: 'far fa-hourglass-half', orderBy: 'data.endtime' },
        ],
        showAllItems: { starts_soon: false, ends_soon: false, current: false },
        showItemsLimit: 3,
        showNonAssignedTasks: false,
      };
    },
    computed: {
      myTasks() {
        return this.elements.filter(item => item.isType('task'));
      },
      tasks() {
        return this.myTasks.length ? this.myTasks : this.nonAssignedTasks;
      },
      filteredTaskLists() {
        return this.taskLists.filter(col => this.tasksPerCol[col.id].length);
      },
      tasksPerCol() {
        return this.taskLists.reduce((acc, col) => {
          acc[col.id] = this.tasks.filter(item => item.status == col.id).sort((a, b) => {
            if (col.orderBy == 'data.starttime') return (a.getStartTime().isBefore(b.getStartTime())) ? -1 : 1;
            if (col.orderBy == '-data.starttime') return (b.getStartTime().isBefore(a.getStartTime())) ? -1 : 1;
            if (col.orderBy == 'data.endtime') return (a.getEndTime().isBefore(b.getEndTime())) ? -1 : 1;
            if (col.orderBy == '-data.endtime') return (b.getEndTime().isBefore(a.getEndTime())) ? -1 : 1;
            return 0;
          });
          return acc;
        }, {});
      },
      filteredOrderedTasksPerCol() {
        return this.taskLists.reduce((acc, col) => {
          acc[col.id] = this.showAllItems[col.id] ? this.tasksPerCol[col.id].slice() : this.tasksPerCol[col.id].slice(0, this.showItemsLimit);
          const projectIds = acc[col.id].map(item => item.project_id);
          acc[col.id].sort((a, b) => (projectIds.indexOf(a.project_id) < projectIds.indexOf(b.project_id) ? -1 : 1));
          return acc;
        }, {});
      },
    },
    created() {
      if (this.tasks.length) {
        const planning = this.tasks[0].getPlanning();
        if (planning) planning.config.setDesign();

        this.tasks.forEach((el) => {
          el.display_title = `${el.project_title}\n↪ ${el.lane_label}`;
          if (this.$store.state.users.user.id) el.filter_user = this.$store.state.users.user.id; // used to hide other users actions
        });
      }
    },
    methods: {
      canEditEl(el) {
        const planning = el.getPlanning();
        return planning?.meta?.access_right && planning.meta.access_right != 'consult';
      },
      checklistClick(el, item) {
        if (! this.canEditEl(el)) return;
        const oldStateParam = { checklist: angular.copy(el.getChecklist()), progress: el.getProgress() };
        item.checked = ! item.checked;
        el.updateChecklistProgress();
        this.$emit('save-el', { el, props: ['checklist', 'progress'], oldStateParam });
      },
      progressClick(el, event) {
        if (! this.canEditEl(el)) return;
        const oldStateParam = { progress: el.getProgress() };
        const fullWidth = $(event.currentTarget).width();
        if (! fullWidth) return;
        el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
        if (el.getProgress() != oldStateParam.progress) {
          this.$emit('save-el', { el, props: ['progress'], oldStateParam });
        }
      },
      validateProgress(el) {
        if (! this.canEditEl(el)) return;
        const oldStateParam = { progress: el.getProgress() };
        el.setProgress(100);
        this.$emit('save-el', { el, props: ['progress'], oldStateParam });
      },
      projectUrl(el) {
        const { project_id: projectId, project_title: projectTitle, project_access_right: projectAccessRight } = el;
        const isConsult = projectAccessRight == 'consult';
        return `/${isConsult ? 'viewer/' : 'planning/'}${projectId}/${window.slugify(projectTitle || "New project")}`;
      },
      elementUrl(el) {
        return `${this.projectUrl(el)}?el=${parseInt(el.id, 10)}`;
      },
    },
  };
</script>
