<template>
  <div id="table-header" v-fix-to-top="{ placeholder: 'table-header-placeholder', topbar: '#topbar', xScroll: '#table-wrapper' }"
       class="notextselect"
       @contextmenu="showContextMenu">
    <div :style="{ 'margin-top': snapshot ? '8px' : '' }" :class="{ 'single-timeline': ! visibleTimeline.subtimelinecols.length }" class="timeline">
      <div v-for="(col, colIndex) in visibleTimeline.timelinecols" :style="{ width: `${col.width}px` }" :title="datesTitle(col)" class="col-header bg-design-first-color">
        <span>{{ col.width >= 80 ? col.label : '&nbsp;' }}</span>
        <div v-if="canModifyPlanning" class="col-menu">
          <div v-if="! colIndex" :title="$t('PLANNING.ADD_NEW_COLUMN_BEFORE')" class="col-context-menu rounded-s-sm" @click="addCol({ where: 'first', stepIndex: 0 })">
            <span class="far fa-plus iconshadow"></span>
          </div>
          <div v-if="! colIndex" :title="$t('PLANNING.HIDE_COLUMN')" class="col-context-menu col-context-menu-hide rounded-e-sm ml-auto" @click="hideCol({ where: 'first', stepIndex: 0 })">
            <span class="far fa-minus iconshadow"></span>
          </div>
          <div v-if="colIndex == visibleTimeline.timelinecols.length - 1"
               :title="$t('PLANNING.HIDE_COLUMN')" class="col-context-menu col-context-menu-hide rounded-s-sm"
               @click="hideCol({ where: 'last', stepIndex: 0 })">
            <span class="far fa-minus iconshadow"></span>
          </div>
          <div v-if="colIndex == visibleTimeline.timelinecols.length - 1"
               :title="$t('PLANNING.ADD_NEW_COLUMN_AFTER')" class="col-context-menu rounded-e-sm ml-auto"
               @click="addCol({ where: 'last', stepIndex: 0 })">
            <span class="far fa-plus iconshadow"></span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="visibleTimeline.subtimelinecols.length" style="height: 2px; background: white;"></div> <!-- white divider -->
    <div class="subtimeline">
      <div v-for="(subcol, subColIndex) in visibleTimeline.subtimelinecols" :style="{ width: `${subcol.width}px` }" :title="datesTitle(subcol)" class="col-header bg-design-second-color">
        <span>{{ (! subcol.colspan || subcol.colspan > .6) ? subcol.label : '&nbsp;' }}</span>
        <div v-if="canModifyPlanning" class="col-menu">
          <div v-if="! subColIndex" :title="$t('PLANNING.ADD_NEW_COLUMN_BEFORE')" class="col-context-menu rounded-s-sm" @click="addCol({ where: 'first', stepIndex: 1 })">
            <span class="far fa-plus iconshadow"></span>
          </div>
          <div v-if="! subColIndex" :title="$t('PLANNING.HIDE_COLUMN')" class="col-context-menu col-context-menu-hide rounded-e-sm ml-auto" @click="hideCol({ where: 'first', stepIndex: 1 })">
            <span class="far fa-minus iconshadow"></span>
          </div>
          <div v-if="subColIndex == visibleTimeline.subtimelinecols.length - 1"
               :title="$t('PLANNING.HIDE_COLUMN')" class="col-context-menu col-context-menu-hide rounded-s-sm"
               @click="hideCol({ where: 'last', stepIndex: 1 })">
            <span class="far fa-minus iconshadow"></span>
          </div>
          <div v-if="subColIndex == visibleTimeline.subtimelinecols.length - 1"
               :title="$t('PLANNING.ADD_NEW_COLUMN_AFTER')" class="col-context-menu rounded-e-sm ml-auto"
               @click="addCol({ where: 'last', stepIndex: 1 })">
            <span class="far fa-plus iconshadow"></span>
          </div>
        </div>
      </div>
    </div>
    <planning-timeline-scrollbar v-if="! snapshot" :visible-timeline="visibleTimeline"></planning-timeline-scrollbar>
    <planning-timeline-context-menu :context-menu="contextMenu" :timeline="planning.timeline" :can-open-config="canModifyPlanning || dashboard">
    </planning-timeline-context-menu>
  </div>
</template>

<style lang="scss">
  @import 'vuetify/lib/styles/tools/_elevation.sass';

  #table-header {
    background: white;
    text-align: center;
    position: relative;
    cursor: grab;

    &.fixed {
      position: fixed;
      top: 0px;
      z-index: 300;
      width: inherit;
      @include elevation(4, $important: true);
      transform: translateZ(0);
      padding-top: 1px; /* prevent half pixel appearing behind timeline */
      margin-top: -1px;
    }
  }

  .col-header {
    font-weight: bold;
    display: inline-block;
    position: relative;
    border: none;
    border-radius: 4px;
    white-space: nowrap;
    border-right: 1px solid white !important;

    &:last-child {
      border-right: none;
    }
  }

  .timeline {
    height: 22px;

    .col-header {
      height: 22px;
      line-height: 24px;
    }

    &.single-timeline {
      height: 40px;

      .col-header {
        height: 40px;
        line-height: 40px;
      }
    }
  }

  .subtimeline {
    .col-header {
      height: 28px;
      line-height: 28px;
    }
  }

  /* Col Menu */
  .col-menu {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }

  .col-context-menu {
    cursor: pointer;
    font-size: 12px;
    background-color: rgba(0,0,0,.25);
    width: 20px;
    height: 100%;
    transition: .3s;

    &:hover {
      background-color: rgba(0,0,0,.6);
    }
  }

  .col-header:only-of-type .col-context-menu-hide {
    display:none;
  }

  .timeline:not(.single-timeline) .col-context-menu {
    line-height: 23px;
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import drag2scrolltimelineMixin from './PlanningTimeline/drag2scrolltimeline';
  import PlanningTimelineScrollbar from './PlanningTimeline/PlanningTimelineScrollbar';
  import PlanningTimelineContextMenu from './PlanningTimeline/PlanningTimelineContextMenu';

  export default {
    components: {
      PlanningTimelineScrollbar,
      PlanningTimelineContextMenu,
    },
    mixins: [drag2scrolltimelineMixin],
    props: {
      planning: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      dashboard: { type: Boolean, default: false },
    },
    data() {
      return {
        contextMenu: { show: false, x: 0, y: 0 },
      };
    },
    computed: {
      visibleTimeline() {
        return this.planning.visibleTimeline;
      },
      snapshot() {
        return this.$store.state.ui.planning.snapshot;
      },
      canModifyPlanning() {
        return this.dynamic && ! this.view;
      },
    },
    watch: {
      'contextMenu.show': function (newVal) {
        if (newVal) {
          window.addEventListener('scroll', this.hideContextMenu);
        } else {
          window.removeEventListener('scroll', this.hideContextMenu);
        }
      },
    },
    methods: {
      datesTitle(col) {
        const planningLocale = this.planning.timeline.getTimelocale();
        const start = this.$filters.moment(col.starttime, 'mediumDateNoYear', planningLocale);
        const end = this.$filters.moment(moment(col.endtime).add(-1), 'mediumDateNoYear', planningLocale);
        return start == end ? start : `${start} - ${end}`;
      },
      showContextMenu(e) {
        if (e.shiftKey) return; // shift to shorcut the menu
        e.preventDefault();
        _.extend(this.contextMenu, {
          show: false,
          x: e.clientX,
          y: document.querySelector('#table-header').getBoundingClientRect().y + 7,
        });
        this.$nextTick(() => {
          this.contextMenu.show = true;
        });
      },
      hideContextMenu() {
        this.contextMenu.show = false;
      },
      ...mapActions('planning/timeline', ['addCol', 'hideCol']),
    },
  };
</script>
