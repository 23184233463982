import axios from 'axios';
import DebugSrv from '@/components/Debug/DebugSrv';

let host = 'https://api.bubbleplan.net/';
const snapBaseUrl = 'https://snap2.bubbleplan.net/export/';
let liveSnapServer = 'https://snap3.bubbleplan.net/';
let integrationsServer = 'https://integrations.bubbleplan.net/';
if (DebugSrv.local) {
  host = `http://${window.location.hostname}:8080/api/`;
  //    snapBaseUrl = 'https://snap2.bubbleplan.net:3443/export/';
  liveSnapServer = 'http://localhost:3001/';
  integrationsServer = 'http://localhost:3002/';
}

function url(apiParam, action, data) {
  let api = apiParam;
  if (action == "show" || action == "destroy") {
    if (typeof data === 'object') {
      api = `${api}/${data.id}`;
    } else {
      api = `${api}/${data}`;
    }
  }
  if (action == 'update' && data && data.id) api = `${api}/${data.id}`;
  if (api == 'share') api = `plannings/${data.id}/${action}`;
  if (action == 'export') return api == 'view' ? `${liveSnapServer}view/${data.id}` : liveSnapServer + data.id;
  if (api == 'pdf') return `${liveSnapServer}pdf/`;
  if (typeof api == 'string' && api.substr(0, 'integrations'.length) === 'integrations') return integrationsServer + api.substr(13);
  if (api.startsWith('v2/')) return `${host}${api}`;
  return `${host}v1/${api}`;
}

function method(api, action) {
  if (action == 'store') return "POST";
  if (action == 'destroy') return "DELETE";
  if (action == 'update') return "PUT";
  return "GET";
}

/* auth token source : mobile, integrations, ... */
const validAuthSources = new Set(['mobileapp', 'sso_anonymous', 'microsoftteams']);

function getAuthSource() {
  const authSource = window.localStorageWrapper.getItem('auth_source');
  return validAuthSources.has(authSource) ? authSource : undefined;
}
function setAuthSource(source) {
  window.localStorageWrapper.setItem('auth_source', source);
}
function deleteAuthSource() {
  window.localStorageWrapper.removeItem('auth_source');
}

/* auth token */
function getAuthToken(source) {
  return window.localStorageWrapper.getItem(`${source || 'api'}_token`);
}
function setAuthToken(token, source) {
  if (validAuthSources.has(source)) {
    setAuthSource(source);
  } else {
    deleteAuthSource();
  }
  window.localStorageWrapper.setItem(`${source || 'api'}_token`, token);
}
function deleteAuthToken(source) {
  deleteAuthSource();
  window.localStorageWrapper.removeItem(`${source || 'api'}_token`);
  window.localStorageWrapper.clear(); // delete all local user data
}

function getTokenHeaders() {
  const headers = {};
  const authSource = getAuthSource();
  const authToken = getAuthToken(authSource);
  if (authToken) {
    if (authSource) headers['X-Auth-Source'] = authSource;
    headers['X-Auth-Token'] = authToken;
  }
  return headers;
}

async function handleAxiosError(axiosError, silent = false) {
  const { default: store } = await import('@/store');
  if (axiosError.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    const { response } = axiosError;

    let errorMessage = response && response.data && response.data.message || response && response.data && response.data.error && response.data.error.message;
    errorMessage = errorMessage || response.statusText || (store.state.lang.i18n.global.t("API.SOMETHING_WRONG") + response.status);
    if (! response.status || response.status == -1) errorMessage = store.state.lang.i18n.global.t("API.SERVER_NOT_RESPONDING");
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    console.log("Error : ", errorMessage);
    if (response.status == 401 && ! silent) {
      store.commit('ui/setLoginWindow', true);
    }
    return Promise.reject(errorMessage);
  }
  if (axiosError.request) {
    // The request was made but no response was received. `error.request` is an instance of XMLHttpRequest
    console.log(axiosError.request);
    return Promise.reject(store.state.lang.i18n.global.t("API.SERVER_NOT_RESPONDING"));
  }
  // Something happened in setting up the request that triggered an Error
  console.log('Error', axiosError.message);
  return Promise.reject(axiosError.message);
}

async function call(api, action, data, silent) {
  const mymethod = method(api, action);
  const myurl = url(api, action, data);
  const { default: router } = await import('@/js/router');
  const myparams = angular.copy(router.currentRoute && router.currentRoute.query) || {};
  if (mymethod == "GET" && data !== null && typeof data === 'object') _.extend(myparams, data);
  if (api == 'pdf' && data && data.html) {
    const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname || '/'}`;
    data.html = data.html.replace(/>[\n\t ]+</g, "><").replace(/url\(\/?static\//g, `url(${baseUrl}static/`).replace(/"\/?static\//g, `"${baseUrl}static/`)
                .replace(/url\(\/?next-assets\//g, `url(${baseUrl}next-assets/`).replace(/"\/?next-assets\//g, `"${baseUrl}next-assets/`);
  }
  if (DebugSrv.debug) console.log(`calling api ${mymethod} ${myurl}`, myparams);

  return axios({
    method: mymethod,
    url: myurl,
    data,
    params: myparams,
    headers: getTokenHeaders(),
  }).catch(axiosError => handleAxiosError(axiosError, silent));
}

function uploadResource(file, api = 'files') {
  if (DebugSrv.debug) console.log('upload file', file);
  const filename = file.name.replace(/'/g, "’");

  const data = new FormData();
  data.append('name', filename);
  data.append('file', file, filename);
  return axios({
    method: 'POST',
    url: url(api, 'store'),
    data,
    headers: { ...getTokenHeaders(), 'Content-Type': `multipart/form-data; boundary=${data._boundary}` },
    onUploadProgress(progressEvent) {
      file.progress = Number(100.0 * progressEvent.loaded / progressEvent.total);
    },
  }).then((response) => {
    if (DebugSrv.debug) console.log('file uploaded', response);
    return response;
  }).catch(axiosError => handleAxiosError(axiosError));
}

function login({ captcha, source, ...params }) {
  // WARNING : Unlike call, login error returns an Error object
  return axios({
    method: 'POST',
    url: url('users/login'),
    data: { 'g-recaptcha-response': captcha, source, ...params },
  }).then((response) => {
    console.log("login success");
    setAuthToken(response.data && response.data.token, source);
    window.location.reload();
  }).catch(axiosError => handleAxiosError(axiosError, true).catch((message) => {
      const error = new Error(message);
      error.status = axiosError.response && axiosError.response.status;
      return Promise.reject(error);
    }));
}

function logout() {
  window.sessionStorageWrapper.clear();
  const token = getAuthToken();
  if (! token) {
    deleteAuthToken();
    return Promise.reject("Already logged out");
  }
  return call('users/logout', 'index').then(deleteAuthToken);
}

function ssoLogin(params) {
  return call('users/login/sso', 'store', params).then((response) => {
    console.log("sso login success");
    const { token, source } = response.data || {};
    setAuthToken(token, source);
  });
}

window.apiSrv = {
  host,
  snapBaseUrl,
  integrationsServer,
  url,
  call,
  uploadResource,
  setAuthToken,
  getAuthToken,
  getTokenHeaders,
  login,
  logout,
  ssoLogin,
};
export default window.apiSrv;
