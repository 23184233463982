import PlanningElement from '@/models/PlanningElement';

export default {
  namespaced: true,
  state: {
    openedDuplicateElement: null,
  },
  mutations: {
    openDuplicateElement(state, el = null) {
      state.openedDuplicateElement = el;
    },
  },
  actions: {
    openDuplicate({ commit }, el) {
      commit('openDuplicateElement', el);
    },
    closeDuplicate({ state, commit }) {
      if (! state.openedDuplicateElement) return;
      commit('openDuplicateElement', null);
    },
    duplicateElement({ dispatch, rootState: { planning } }, { element, duplicateOptions }) {
      const type = element.getType();
      const lane = planning.lanes.find(item => item.id == element.getLaneId());
      const tabOfElements = [];
      for (let i = 0; i < duplicateOptions.numberOfOccurrences; i++) tabOfElements.push({ type, lane, el: new PlanningElement(element.getPlanning(), element) });
      if (duplicateOptions.repeatFrequencyPeriod != 'months' || duplicateOptions.monthlyRepeatOption == 'dayOfMonth') {
        tabOfElements.forEach(({ el: newel }, index) => {
          const duration = newel.getEndTime().diff(newel.getStartTime(), 'milliseconds');
          newel.setStartTime(newel.getStartTime().add((index + 1) * duplicateOptions.repeatFrequencyNumber, duplicateOptions.repeatFrequencyPeriod));
          if (! newel.isType('milestone')) newel.setEndTime(newel.getStartTime().add(duration, 'milliseconds'));
          if (newel.getChecklist()) {
            newel.getChecklist().forEach((action) => {
              if (action.duedate) action.duedate = newel.getStartTime().add(moment(action.duedate).diff(element.getStartTime(), 'milliseconds'), 'milliseconds').format();
              action.checked = false;
              action.workload_used = null;
            });
          }
        });
      } else { // dayOfWeekInMonth == every Xth weekday of the month
        const dayInfos = {
          week: Math.ceil(element.getStartTime().format('D') / 7),
          day: element.getStartTime().isoWeekday(),
        };
        tabOfElements.forEach(({ el: newel }, index) => {
          const duration = newel.getEndTime().diff(newel.getStartTime(), 'milliseconds');
          const nextMonth = newel.getStartTime().add((index + 1) * duplicateOptions.repeatFrequencyNumber, 'months');

          let inc = dayInfos.day;
          if (dayInfos.week <= 4) { // original month contains at most 4 weeks
            const monthFirstDay = nextMonth.startOf('month');
            if (monthFirstDay.isoWeekday() > dayInfos.day) {
              inc += 7;
            }
            inc += (dayInfos.week - 1) * 7;
            monthFirstDay.isoWeekday(inc);
            newel.setStartTime(monthFirstDay);
          } else { // original month contains 5 weeks
            const monthLastDay = nextMonth.endOf('month');
            if (monthLastDay.isoWeekday() < dayInfos.day) {
              inc -= 7;
            }
            monthLastDay.isoWeekday(inc);
            newel.setStartTime(monthLastDay.startOf('day'));
          }
          if (! newel.isType('milestone')) newel.setEndTime(newel.getStartTime().add(duration, 'milliseconds'));
          if (newel.getChecklist()) {
            newel.getChecklist().forEach((action) => {
              if (action.duedate) action.duedate = newel.getStartTime().add(moment(action.duedate).diff(element.getStartTime(), 'milliseconds'), 'milliseconds').format();
              action.checked = false;
              action.workload_used = null;
            });
          }
          newel.update();
        });
      }
      dispatch('planning/elements/addElements', { argstab: tabOfElements }, { root: true });
    },
    deleteElements({ dispatch }, elements) {
      dispatch('planning/elements/deleteElements', { els: elements }, { root: true });
    },
  },
};
