export default {
  methods: {
    selectableStart(event) {
      if (! event.ctrlKey) {
        this.$store.dispatch('selection/resetSelection');
      }
    },
    selectableSelected(event, ui) {
      const id = parseInt($(ui.selected).attr('id').replace("el", ""), 10);
      this.$store.state.ui.planning.doNotResetSelection = true;
      if (this.$store.state.selection.indexOf(id) == -1) this.$store.commit('selection/add', id);
      setTimeout(() => { this.$store.state.ui.planning.doNotResetSelection = false; });
    },
    selectableClick(event, el) {
      if (this.$store.state.ui.planning.doNotUpdateSelection) return;
      if (! this.isDynamic(el)) return;
      const { id } = el;
      if (event.ctrlKey) { // Add/remove to/from selection
        const pos = this.$store.state.selection.indexOf(id);
        if (pos == -1) {
          this.$store.commit('selection/add', id);
        } else {
          this.$store.commit('selection/remove', id);
        }
      } else {
        this.$store.dispatch('selection/resetSelection', [id]);
      }
    },
  },
};
