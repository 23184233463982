<template>
  <section>
    <div v-if="loading" class="text-center"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-else class="monitoring-plan build" style="margin: 8px auto 60px; max-width: 1000px">
      <div class="mb-4" style="font-size: 1.5em">
        <router-link to="/dashboards/monitoringplan" style="color: inherit">
          <button type="button"><i class="fa fa-arrow-left"></i> &nbsp;{{ $t('MONITORING_PLAN.CONSULTATION') }}</button>
        </router-link>
      </div>
      <v-card class="mt-2 pa-6">
        <h3 style="margin-top: 0">{{ $t('MONITORING_PLAN.GENERAL_TITLE') }}</h3>
        <input v-model="title" class="form-control input-lg w-100" type="text" @change="save">
        <h3>{{ $t('MONITORING_PLAN.COVER_IMAGE') }} <small>{{ $t('MONITORING_PLAN.LEAVE_EMPTY') }}</small></h3>
        <input v-model="img" class="form-control input-lg w-100" type="text" placeholder="https://..." @change="save">
      </v-card>
      <v-card class="mt-2 py-4">
        <draggable v-model="block" :item-key="article => getUuid(article)" handle=".grab" @sort="save">
          <template #item="{ element: article }">
            <div style="padding: 10px 25px;">
              <div :style="{ 'margin-left': `${(article.level || 0) * 30}px` }" class="block-article">
                <div class="grab" style="position: absolute; top:0; left: -10px; height: 100%; width: 20px"></div>
                <div class="block-article-menu" style="position: absolute; top: -5px; left: 2px">
                  <i v-if="article.level > 0" class="fa fa-lg fa-caret-left pointer" @click="addLevel(article, -1)"></i>
                  <i v-if="(article.level || 0) < 3" class="fa fa-lg fa-caret-right pointer" @click="addLevel(article)"></i>
                </div>
                <div v-if="article.type == 'title'">
                  <input v-model="article.value" :placeholder="$t('MONITORING_PLAN.HEADLINE')" style="font-size: 20px" class="form-control" @change="save">
                </div>
                <div v-if="article.type == 'text'">
                  <div class="pointer" @click="editTextArticle(article)"
                       v-html="article.value || $t('MONITORING_PLAN.CLICK_TO_EDIT')">
                  </div>
                </div>
                <div v-if="article.type == 'planning'">
                  <monitoring-plan-edit-planning :article="article" :plannings="plannings"
                                                 @update-lanes="updateLanes(article, $event)" @update-showhead="updateShowhead(article, $event)"
                                                 @update-showprojectsheet="updateShowprojectsheet(article, $event)"
                                                 @update-projectsheetdisplay="updateProjectSheetDisplay(article, $event)"
                                                 @update-kanbandisplay="updateKanbanDisplay(article, $event)">
                  </monitoring-plan-edit-planning>
                </div>
                <v-btn-icon :title="$t('MONITORING_PLAN.DELETE_ITEM')" color="errorred" style="position: absolute; right: 0; top: 0"
                            @click="deleteBlockArticle(block, article)">
                  <v-icon size="large">fa fa-times</v-icon>
                </v-btn-icon>
              </div>
            </div>
          </template>
        </draggable>
        <div class="pa-6 d-flex">
          <select :ref="`selectPlanningBlock${getUuid(block)}`" style="min-width: 0">
            <option v-for="planning in sortedPlannings" :key="planning.id" :value="planning.id"
                    :style="{ color: usedPlanningIds[planning.id] ? '#ccc' : null }">
              {{ planning.title || $t('PLANNING.UNNAMED_PROJECT') }}
            </option>
          </select>
          <v-btn color="lightgrey" class="ml-2" @click="addBlockArticle(block, 'planning')">
            <i class="far fa-plus"></i> {{ $t('MONITORING_PLAN.PROJECT') }}
          </v-btn>
          <v-btn color="lightgrey" class="ml-2" @click="addBlockArticle(block, 'title')">
            <i class="far fa-plus"></i> {{ $t('MONITORING_PLAN.HEADLINE') }}
          </v-btn>
          <v-btn color="lightgrey" class="ml-2" @click="addBlockArticle(block, 'text')">
            <i class="far fa-plus"></i> {{ $t('MONITORING_PLAN.PARAGRAPH') }}
          </v-btn>
        </div>
      </v-card>

      <modal v-if="editingTextArticle" :backdrop-close="false" content-class="overflow-visible" @close="editingTextArticle = null">
        <v-container>
          <quill v-model:content="editingTextArticle.value" :config="{ height: '150px', placeholder: $t('MONITORING_PLAN.FREE_TEXT') }"></quill>
          <div class="text-center">
            <v-btn rounded size="large" variant="outlined" @click="editingTextArticle = null">{{ $t('GLOBAL.CANCEL') }}</v-btn>
            <v-btn color="primary" rounded size="large" @click="saveTextArticle">{{ $t('GLOBAL.SAVE') }}</v-btn>
          </div>
        </v-container>
      </modal>
    </div>
  </section>
</template>

<style scoped>
  .block-article {
    position: relative;
    padding: 10px 30px 10px 10px;
    border-left: 2px solid #999;
    transition: .3s;
  }
  div:hover > .block-article {
    background: rgba(0,0,0,.05)
  }

  .block-article-menu {
    visibility: hidden;
    transition: .3s;
    opacity: 0;
  }
  div:hover > .block-article .block-article-menu {
    visibility: visible;
    opacity: 1;
  }
</style>

<script>
  import draggable from 'vuedraggable';
  import Quill from '@/components/Reusables/Quill';
  import MonitoringPlanEditPlanning from './MonitoringPlanEditPlanning';

  function deepcopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  export default {
    components: {
      draggable,
      Quill,
      'monitoring-plan-edit-planning': MonitoringPlanEditPlanning,
    },
    data() {
      return {
        structure: {},
        plannings: [],
        title: "",
        img: "",
        block: [],
        sortedPlannings: [],
        editingTextArticle: null,
        loading: null,
      };
    },
    computed: {
      usedPlanningIds() {
        const usedPlanningIds = {};
        (this.block || []).forEach((article) => {
          if (article.type == 'planning') usedPlanningIds[article.planning_id] = true;
        });
        return usedPlanningIds;
      },
    },
    watch: {
      structure: {
        handler(structure) {
          this.title = structure.title || "";
          this.img = structure.img || "";
          this.block = deepcopy((this.structure.blocks || [])[0] || []);
        },
        deep: true,
      },
      plannings: {
        handler(plannings) {
          this.sortedPlannings = plannings.slice().sort((a, b) => (a.title < b.title ? -1 : 1));
        },
        deep: true,
      },
    },
    created() {
      this.loading = true;
      window.apiSrv.call('dashboards/monitoringplan', 'index').then((response) => {
        this.$store.getters['users/getOrganizationPromise'].then(() => {
          const { structure, plannings } = response && response.data || {};
          this.structure = structure || {};
          this.plannings = plannings || [];
          this.loading = false;
        });
      });
    },
    methods: {
      updateLanes(article, lanes) {
        article.lanes = lanes;
        this.save();
      },
      updateShowhead(article, value) {
        article.showhead = value;
        this.save();
      },
      updateShowprojectsheet(article, value) {
        article.showprojectsheet = value;
        this.save();
      },
      updateProjectSheetDisplay(article, value) {
        article.projectsheet_display = value;
        this.save();
      },
      updateKanbanDisplay(article, value) {
        article.kanban_display = value;
        this.save();
      },
      addBlockArticle(block, type) {
        const article = { type };
        if (type == 'title' || type == 'text') {
          article.value = "";
        } else if (type == 'planning') {
          article.planning_id = parseInt(this.$refs[`selectPlanningBlock${this.getUuid(block)}`].value, 10);
          article.showhead = true;
          article.showprojectsheet = true;
          article.lanes = [];
        }
        block.push(article);
        this.save();
      },
      deleteBlockArticle(block, article) {
        const index = block.indexOf(article);
        if (index > -1) block.splice(index, 1);
        this.save();
      },
      addLevel(article, amount = 1) {
        article.level = (article.level || 0) + amount;
        this.save();
      },
      editTextArticle(article) {
        this.editingTextArticle = { article, value: article.value };
      },
      saveTextArticle() {
        this.editingTextArticle.article.value = this.editingTextArticle.value;
        this.editingTextArticle = null;
        this.save();
      },
      getUuid(item) {
        if (! item.uuid) item.uuid = window.uuid();
        return item.uuid;
      },
      update: _.debounce(function (data) {
        if (! data.structure) return Promise.reject(new Error('Missing structure'));
        return window.apiSrv.call('dashboards/monitoringplan', 'store', { structure: data.structure }).catch((error) => {
          this.$store.dispatch('ui/msgbox/open', { title: this.$t('MONITORING_PLAN.SAVE_ERROR'), body: error || this.$t('MONITORING_PLAN.CHANGES_NOT_SAVED') });
        });
      }, 1000),
      save() {
        this.update({
          structure: deepcopy({
            title: this.title,
            img: this.img,
            blocks: [this.block],
          }),
        });
      },
    },
  };
</script>
