<template>
  <p class="mb-2">{{ $t('LOGIN.REMIND_PASSWORD_DESC') }}</p>
  <form @submit.prevent="submitRemindPassword">
    <v-text-field id="emailField" v-model="email" :placeholder="$t('GLOBAL.EMAIL')" :error-messages="error" :messages="success" prepend-inner-icon="far fa-at"
                  required autofocus>
    </v-text-field>
    <div class="text-center mt-6">
      <v-btn :disabled="isSubmittingRemind" :loading="isSubmittingRemind" color="primary" rounded size="large" type="submit" class="px-12">
        {{ $t('LOGIN.REMIND_PASSWORD') }}
      </v-btn>
    </div>
  </form>
  <div class="mt-12"></div>
  <div class="mt-auto text-center">
    <v-btn variant="text" @click.prevent="$emit('navigate-section', 'register')">{{ $t('LOGIN.CREATE_ACCOUNT') }}</v-btn>
  </div>
</template>

<script>
  export default {
    emits: ['navigate-section'],
    data() {
      return {
        email: "",
        error: "",
        success: "",
        isSubmittingRemind: false,
      };
    },
    methods: {
      submitRemindPassword() {
        this.error = "";
        this.success = "";
        if (! window.isValidEmailAddress(this.email)) {
          this.error = this.$t('LOGIN.ERROR_INVALID_EMAIL');
          return;
        }

        this.isSubmittingRemind = true;
        window.apiSrv.call('password/remind', 'store', { email: this.email }).then((response) => {
          this.success = response && response.data && response.data.message;
          this.email = "";
          this.isSubmittingRemind = false;
        }).catch((error) => {
          if (typeof error == 'object') {
            this.error = Object.keys(error).map((key) => {
              const messages = error[key];
              if (Array.isArray(messages)) return `- ${messages.join('\n- ')}`;
              return messages;
            }).join("\n");
          } else {
            this.error = error;
          }
          this.isSubmittingRemind = false;
        });
      },
    },
  };
</script>
