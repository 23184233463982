import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import store from '@/store';
import i18n from '@/js/plugins/i18n';
import VueApp from '@/components/App.vue';
import vuetify from './plugins/vuetify';
import router from './router';

import vuefilters from './vuefilters.js';
import * as vuedirectives from './vuedirectives.js';
import vuecomponents from './vuecomponents.js';

const pinia = createPinia();
const app = createApp(VueApp);

/* SENTRY */
if (window.location.hostname != 'localhost') {
  const maximizePerformance = window.location.hostname.replace(/(.*)\.bubbleplan\.net/i, '$1') == 'horis';

  Sentry.init({
    app,
    dsn: 'https://840ce42962d84f0f9ef35ed5441bf12b@o120980.ingest.sentry.io/266350',
    release: 'planning@2024-09',
    integrations: maximizePerformance ? null : [
      // Sentry.browserTracingIntegration({ router }), // warning: has huge impact on performance (+540% on dashboard Kanban)
      Sentry.replayIntegration({ // warning: has impact on performance (+30% on dashboard Kanban without these options)
        mutationLimit: 2000, // default 10000
        block: ['#progress-dashboard-cols-wrapper', '#actionsdashboard', '#personal-calendar', '#planningusers', '#portfolio-body'],
      }),
    ],
    logErrors: true,
    ignoreErrors: [
      "_clickOutside", "ShadowRoot", "lastMousedownWasOutside",
      "ResizeObserver loop limit exceeded", "ResizeObserver loop completed with undelivered notifications.", // caused by simplebar ResizeObserver
      "WeakMap keys must be objects or non-registered symbols", // safari warning
      "Non-Error promise rejection captured with value: Session expired", "Non-Error promise rejection captured with value: Not logged in", // needs a fix : login popup not displayed on dashboard page
    ],
    denyUrls: [/graph\.facebook\.com/i, /connect\.facebook\.net\/en_US\/all\.js/i, /eatdifferent\.com\.woopra-ns\.com/i, /static\.woopra\.com\/js\/woopra\.js/i,
      /extensions\//i, /safari-extension:/i, /chrome-extension:/i, /^chrome:\/\//i, /127\.0\.0\.1:4001\/isrunning/i, /webappstoolbarba\.texthelp\.com\//i, /metrics\.itunes\.apple\.com\.edgesuite\.net\//i],
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring. We recommend adjusting this value in production (limit 10k)
    // tracesSampleRate: 1.0,
    // Capture Replay for 1% of all sessions, plus for 100% of sessions with an error (limit 50)
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,

    trackComponents: true, // track vue components 'activate', 'mount', 'update'
  });
}

app.use(vuetify);
app.use(router);
app.use(store);
app.use(pinia);
app.use(i18n);

app.config.globalProperties.$filters = vuefilters;
Object.entries(vuedirectives).forEach(([key, value]) => {
  app.directive(key, value);
});
Object.entries(vuecomponents).forEach(([key, value]) => {
  app.component(key, value);
});

store.dispatch('users/load');

let mountApp = true;
if (window.location.hostname == 'app.bubbleplan.net') {
  // redirect to legacy version if needed
  if (window.localStorageWrapper.getItem('version') == 'legacy') {
    window.location.href = `legacy.html${window.location.search || ''}${window.location.hash || ''}`;
    mountApp = false;
  } else {
    store.state.users.userPromise.then((user) => {
      if (user.company?.config?.version == 'legacy') {
        window.localStorageWrapper.setItem('version', 'legacy');
        window.location.href = `legacy.html${window.location.search || ''}${window.location.hash || ''}`;
      }
    });
  }
}

if (mountApp) app.mount('#vue-app-wrapper');

window.app = app;
export default app;
