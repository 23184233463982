export default {
  fr: {
    "ELEMENT-DETAIL": {
      TITLE: "Titre (libelle explicite du dossier)",
      ENTER_TITLE: "Saisir le libelle explicite du dossier",
      DESCRIPTION: "Description (message principal/levier)",
      ENTER_DESCRIPTION: "Saisir message principal/levier",
      DATES: "Dates de routage",
      DURATION: "Durée du routage :",
      CHECKLIST: "Volumes",
      NEW_CHECKLIST_ITEM: "Volume des envois",
      DELETE_CHECKLIST_ITEM: "Supprimer le volume",
      USERS: "Tags spécifiques (cibles) - Pilote projet (CPM)",
      ADD_USER_HINT: "Sélectionnez dans la liste",
      ATTACHMENTS: "Pièces jointes (ex : les créas, la fiche synthèse, fiche bilan)",
    },
    KANBAN: {
      STEP: "Période (mois de l'activation)",
      NEXT_STEP: "Période suivante",
    },
  },
};
