<template>
  <div class="action-item text-left hovervisible-activator">
    <v-icon size="small" :class="isDraggable && canModifyAction ? 'hovervisible-item' : 'invisible'" style="height: 40px"
            class="checklist-draggable-anchor grab text-medium-emphasis">
      far fa-grip-vertical
    </v-icon>
    <i :class="[action.getChecked() ? 'fa-check-square' : 'fa-square', { pointer: canModifyAction }]" class="far fa-2x fa-fw" style="font-size: 14px"
       @click="checkAction">
    </i>
    <div :class="{ pointer: canModifyAction }" class="px-2" style="flex-shrink: 2; width: 40%" @click.stop="startEditTitle(action.id)">
      <span v-if="editing.title != action.id" :title="laneLabelTooltip" :class="`text-${action.getColor()}`" class="action-item-text">{{ actionTitle || '&nbsp;' }}</span>
      <div v-else class="d-flex align-center">
        <v-text-field v-model="actionTitle" autofocus class="no-inner-input-padding" style="min-width: 140px"
                      @keyup.enter="endEditTitle" @blur="endEditTitle">
        </v-text-field>
      </div>
      <div v-if="showActionSubtitle" class="text-medium-emphasis small mb-1" @click.stop>
        <a :href="actionElement?.getPlanning() ? `${actionElement.getPlanning().getUrl()}?el=${parseInt(actionElement.id, 10)}` : ''" target="_blank" rel="noopener" style="color: inherit">
          <i>{{ actionElement ? `${actionElement.getTitle()} (${actionElement.getEndTime().locale('fr').format('D MMM')})` : '' }}</i>
        </a>
      </div>
    </div>
    <div class="text-center hide-on-drag" style="width: 120px">
      <date-field v-if="canModifyAction" v-model="actionDueDate" :placeholder="actionEndTime" variant="plain" input-class="no-inner-input-padding" style="height: 40px">
      </date-field>
      <span v-else :class="actionDueDate ? '' : 'text-medium-emphasis'">{{ actionEndTime }}</span>
    </div>
    <div :class="{ pointer: canModifyAction }" class="col-xs-3 d-flex align-center px-2 hide-on-drag">
      <div class="d-flex align-center" @click.stop="startEditWorkload(action.id)">
        <i :title="workloadTitle" class="far fa-weight-hanging mr-1"></i>
        <span v-if="editing.workload != action.id" :title="workloadTitle">
          {{ $filters.number(actionWorkload || 0, '0.[00]') }}
        </span>
        <v-text-field v-else v-model.number="actionWorkload" autofocus type="number" step="any" min="0"
                      class="no-inner-input-padding" style="min-width: 40px; width: 90px" @keyup.enter="endEditWorkload" @blur="endEditWorkload">
        </v-text-field>
      </div>

      <div class="d-flex align-center" @click.stop="startEditWorkloadUsed(action.id)">
        <i :title="workloadTitle" class="ml-2 far fa-stopwatch mr-1"></i>
        <span v-if="editing.workloadUsed != action.id" :title="workloadTitle">
          {{ $filters.number(actionWorkloadUsed || 0, '0.[00]') }}
        </span>
        <v-text-field v-else v-model.number="actionWorkloadUsed" autofocus type="number" step="any" min="0"
                      class="no-inner-input-padding" style="min-width: 40px; width: 90px" @keyup.enter="endEditWorkloadUsed" @blur="endEditWorkloadUsed">
        </v-text-field>
      </div>
    </div>
    <v-btn-icon v-if="canModifyAction" size="x-small" class="hovervisible-item hide-on-drag" @click.stop="$emit('open-action')">
      <v-icon>far fa-pencil-alt</v-icon>
    </v-btn-icon>
  </div>
</template>

<style>
  #actionsdashboard .sortable-drag.action-item, #actionsdashboard .sortable-ghost.action-item {
    justify-content: flex-start;
  }
  .sortable-drag .hide-on-drag, .sortable-ghost .hide-on-drag {
    display: none !important;
  }

  #actionsdashboard .action-item {
    display: flex;
    align-items: center;
    position: relative;
    line-height: 1.2;
  }

  #actionsdashboard .v-input.no-inner-input-padding input {
    padding: 0 4px;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import workloadMixin from '@/components/Workload/workloadMixin';

  export default {
    mixins: [workloadMixin],
    props: {
      action: { type: Object, required: true },
      editing: { type: Object, default: () => ({}) },
      isDraggable: { type: Boolean, default: false },
    },
    emits: ['open-action', 'set-editing'],
    data() {
      return {
        showActionSubtitle: clientConfig.dashboards?.actionsDashboard?.showActionSubtitle || false,
      };
    },
    computed: {
      workloadTitle() {
        const { workloadInHours } = this.$store.getters['users/accessRight/config'];
        return workloadInHours ? this.$t('HR.WORKLOAD_HOURS_INFORMATION') : this.$t('HR.WORKLOAD_DAYS_INFORMATION');
      },
      actionTitle: {
        get() { return this.action.getTitle(); },
        set(newVal) { this.action.setTitle(newVal); },
      },
      actionWorkload: {
        get() {
          return this.workloadToDisplay(this.action.getWorkload());
        },
        set(newVal) {
          this.action.setWorkload(this.workloadToStore(newVal));
        },
      },
      actionWorkloadUsed: {
        get() {
          return this.workloadToDisplay(this.action.getWorkloadUsed());
        },
        set(newVal) {
          this.action.setWorkloadUsed(this.workloadToStore(newVal));
        },
      },
      locale() {
        return this.$store.state.lang.lang;
      },
      actionEndTime() {
        if (! this.action.getEndTime()) return null; // off-project actions
        return this.action.getEndTime().format(this.locale == 'en' ? 'YYYY-MM-DD' : 'DD/MM/YYYY'); // cf DateField format
      },
      actionDueDate: {
        get() { return this.action.getDuedate(); },
        set(newVal) {
          if (! newVal) return;
          this.action.setDuedate(newVal.format());
          this.saveAction();
        },
      },
      actionElement() {
        return this.action.getElement();
      },
      canModifyAction() {
        return this.action.getAcr() != 'consult';
      },
      laneLabelTooltip() {
        if (! this.actionElement) return null;
        const project = this.actionElement.getPlanning();
        if (! project) return null;
        const lane = (project.lanes || []).find(item => item.id == this.actionElement.getLaneId());
        if (lane && lane.label) return `↪ ${lane.label}\n${this.actionElement.getTitle() || ''}`;
        return null;
      },
    },
    watch: {
      'editing.title': function (newId, oldId) {
        if (oldId == this.action.id) this.action.reset();
      },
      'editing.workload': function (newId, oldId) {
        if (oldId == this.action.id) this.action.reset();
      },
    },
    methods: {
      startEditWorkload(id) {
        if (! this.canModifyAction) return;
        this.$emit('set-editing', { prop: 'workload', id });
      },
      endEditWorkload() {
        this.$emit('set-editing', { prop: 'workload', id: null });
        this.saveAction();
      },
      startEditWorkloadUsed(id) {
        if (! this.canModifyAction) return;
        this.$emit('set-editing', { prop: 'workloadUsed', id });
      },
      endEditWorkloadUsed() {
        this.$emit('set-editing', { prop: 'workloadUsed', id: null });
        this.saveAction();
      },
      startEditTitle(id) {
        if (! this.canModifyAction) return;
        this.$emit('set-editing', { prop: 'title', id });
      },
      endEditTitle() {
        this.$emit('set-editing', { prop: 'title', id: null });
        this.saveAction();
      },
      checkAction() {
        if (! this.canModifyAction) return;
        this.action.setChecked(! this.action.getChecked());
        this.saveAction();
      },
      saveAction() {
        this.action.save().catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : action was not saved", body: message });
        });
      },
    },
  };
</script>
