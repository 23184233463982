<template>
  <v-card class="pa-6">
    <b>{{ $t('MANAGE.CONFIG.DEFAULT_PROJECT_PRIVACY') }}</b>
    <div class="pt-2" style="max-width: 360px;">
      <v-select v-model="defaultProjectPrivacy" :items="availablePrivacyOptions"></v-select>
    </div>
    <div class="py-4"></div>
    <b>{{ $t('MANAGE.CONFIG.CATEGORY_COLORS') }}</b>
    <div class="pt-2">
      <manage-config-project-settings-categories v-if="showCategories"></manage-config-project-settings-categories>
      <v-btn v-else size="small" color="primary" variant="outlined" rounded class="mt-1" @click="showCategories = true">{{ $t('MANAGE.CONFIG.CUSTOM_CATEGORY_COLORS') }}</v-btn>
    </div>
    <div class="py-4"></div>
    <b>{{ $t('MANAGE.CONFIG.VIRTUAL_PARTICIPANT', 2) }}</b>
    <div class="pt-1">
      <v-row style="line-height: 1.3;">
        <v-col :cols="6">
          <small>{{ $t('MANAGE.CONFIG.BUSINESS_PARTICIPANT_DESC') }}</small>
          <v-textarea v-model="textUsers" variant="filled" class="pt-2" style="border-radius: 4px 4px 0 0; flex: 0 1 50%"></v-textarea>
        </v-col>
        <v-col v-if="clientConfigTextUsers" :cols="6">
          <small>{{ $t('MANAGE.CONFIG.CLIENTCONFIG_PARTICIPANT_DESC') }}</small>
          <v-textarea :model-value="clientConfigTextUsers" variant="filled" readonly class="mr-4 pt-2" style="border-radius: 4px 4px 0 0; flex: 0 1 50%"></v-textarea>
        </v-col>
      </v-row>
    </div>
    <div class="py-4"></div>
    <b>{{ $t('MANAGE.CONFIG.SUBPROJECTS_DISPLAYED_CONTENT') }}</b>
    <div class="pt-2">
      <div class="d-flex flex-wrap">
        <v-checkbox v-model="subprojectsVisibleDetails" value="synthesis">
          <template #label>{{ $t('MANAGE.CONFIG.SYNTHESIS') }}</template>
        </v-checkbox>
        <v-checkbox v-model="subprojectsVisibleDetails" value="progress" class="ml-4">
          <template #label>{{ $t('ELEMENT-DETAIL.PROGRESS') }}</template>
        </v-checkbox>
        <v-checkbox v-model="subprojectsVisibleDetails" value="process" class="ml-4">
          <template #label>{{ $t('KANBAN.STEP') }}</template>
        </v-checkbox>
        <v-checkbox v-model="subprojectsVisibleDetails" value="subTasks" class="ml-4">
          <template #label>{{ $t('ELEMENT-DETAIL.SUB_TASKS') }}</template>
        </v-checkbox>
      </div>
      <div class="d-flex flex-wrap">
        <v-checkbox v-model="subprojectsVisibleDetails" value="date">
          <template #label>{{ $t('MANAGE.CONFIG.MILESTONE_DATE') }}</template>
        </v-checkbox>
        <v-checkbox v-model="subprojectsVisibleDetails" value="dates" class="ml-4">
          <template #label>{{ $t('MANAGE.CONFIG.TASK_DATES') }}</template>
        </v-checkbox>
        <v-checkbox v-model="subprojectsVisibleDetails" value="links" class="ml-4">
          <template #label>{{ $t('ELEMENT-DETAIL.ATTACHMENTS') }}</template>
        </v-checkbox>
        <v-checkbox v-model="subprojectsVisibleDetails" value="budgets" class="ml-4">
          <template #label>{{ $t('ELEMENT-DETAIL.BUDGET') }}</template>
        </v-checkbox>
      </div>
      <v-switch v-model="subprojectsSynthesisPopover" :disabled="! subprojectsVisibleDetails.includes('synthesis')">
        <template #label>{{ $t('MANAGE.CONFIG.DISPLAY_SYNTHESIS_POPOVER') }}</template>
      </v-switch>
    </div>
    <div class="py-4"></div>
    <b>{{ $t('ELEMENT-DETAIL.CHECKLIST') }}</b>
    <div class="pt-2">
      <v-switch v-model="assignActionsToCreator">
        <template #label>{{ $t('MANAGE.CONFIG.ASSIGN_ACTIONS_TO_CREATOR') }}</template>
      </v-switch>
    </div>
    <div class="py-4"></div>
    <b>{{ $t('HR.WORKLOAD') }}</b>
    <div class="pt-2 d-flex align-center">
      <v-switch v-model="workloadInHours" class="mr-2 flex-grow-0">
        <template #label>{{ $t('MANAGE.CONFIG.DISPLAY_WORKLOAD_IN_HOURS') }}</template>
      </v-switch>
      <div class="ml-4">
        <v-text-field v-model.number="workloadHoursPerDay" :disabled="! workloadInHours"
                      :label="$t('MANAGE.CONFIG.NUMBER_OF_HOURS')" :title="$t('MANAGE.CONFIG.NUMBER_OF_HOURS')"
                      type="number" step="any" min="0" :max="maxHoursPerDay"
                      @change="setParam('workloadHoursPerDay', workloadHoursPerDay)">
        </v-text-field>
      </div>
    </div>
    <div class="py-4"></div>
    <b>{{ $t('MANAGE.CONFIG.BUSINESS_HOURS') }}</b>
    <div class="pt-1"><small>{{ $t('MANAGE.CONFIG.BUSINESS_HOURS_DESC') }}</small></div>
    <div class="pt-2 d-flex align-center">
      <v-switch v-model="activateBusinessHours" class="mr-2 flex-grow-0">
        <template #label>{{ $t('MANAGE.CONFIG.ACTIVATE_BUSINESS_HOURS') }}</template>
      </v-switch>
      <div class="ml-4">
        <v-text-field v-model.number="businessHoursStart" :disabled="! activateBusinessHours" :label="$t('ELEMENT-DETAIL.SCHEDULE_START')"
                      type="number" step="any" min="0" max="24"
                      @change="updateBusinessHours">
        </v-text-field>
      </div>
      <div class="ml-4">
        <v-text-field v-model.number="businessHoursEnd" :disabled="! activateBusinessHours" :label="$t('ELEMENT-DETAIL.SCHEDULE_END')"
                      type="number" step="any" min="0" max="24"
                      @change="updateBusinessHours">
        </v-text-field>
      </div>
    </div>
    <div class="py-4"></div>
    <b>{{ $t('MANAGE.CONFIG.BUDGET_STATES') }}</b>
    <div class="pt-2">
      <manage-config-project-settings-budget></manage-config-project-settings-budget>
    </div>
  </v-card>
</template>

<script>
  import defaultConfig from '@/js/defaultConfig';
  import ManageConfigProjectSettingsCategories from './ManageConfigProjectSettingsCategories.vue';
  import ManageConfigProjectSettingsBudget from './ManageConfigProjectSettingsBudget.vue';

  export default {
    components: {
      ManageConfigProjectSettingsCategories,
      ManageConfigProjectSettingsBudget,
    },
    data() {
      return {
        maxHoursPerDay: 24,
        workloadHoursPerDay: null,
        businessHoursStart: null,
        businessHoursEnd: null,
        availablePrivacyOptions: defaultConfig.availablePrivacyOptions(),
        showCategories: false,
      };
    },
    computed: {
      defaultProjectPrivacy: {
        get() {
          return this.computedConfig.defaultProjectPrivacy;
        },
        set(newVal) {
          this.setParam('defaultProjectPrivacy', newVal);
        },
      },
      textUsers: {
        get() {
          return (this.computedConfig.businessTextUsers || []).join("\n");
        },
        set(newVal) {
          const textUsers = newVal.split('\n').map(item => item.trim()).filter(item => item);
          this.debounceSetParam('textUsers', textUsers.length ? textUsers : null);
        },
      },
      clientConfigTextUsers() {
        return (this.computedConfig.clientConfigTextUsers || []).join("\n");
      },
      subprojectsVisibleDetails: {
        get() {
          return this.computedConfig.subprojectsVisibleDetails;
        },
        set(newVal) {
          this.setParam('subprojectsVisibleDetails', newVal);
        },
      },
      subprojectsSynthesisPopover: {
        get() {
          return this.computedConfig.subprojectsSynthesisPopover;
        },
        set(newVal) {
          this.setParam('subprojectsSynthesisPopover', newVal);
        },
      },
      assignActionsToCreator: {
        get() {
          return this.computedConfig.assignActionsToCreator;
        },
        set(newVal) {
          this.setParam('assignActionsToCreator', newVal);
        },
      },
      workloadInHours: {
        get() {
          return this.computedConfig.workloadInHours;
        },
        set(newVal) {
          this.setParam('workloadInHours', newVal);
        },
      },
      activateBusinessHours: {
        get() {
          return !! this.computedConfig.businessHours;
        },
        set(newVal) {
          if (newVal) {
            this.updateBusinessHours();
          } else {
            this.setParam('businessHours', null);
          }
        },
      },
      computedConfig() {
        return this.$store.getters['users/accessRight/config'];
      },
      clientConfig() {
        return this.$store.state.users.accessRight.clientConfig;
      },
    },
    watch: {
      workloadHoursPerDay(newVal) {
        if (newVal > this.maxHoursPerDay) this.$nextTick(() => { this.workloadHoursPerDay = this.maxHoursPerDay; });
      },
      'computedConfig.businessHours.start': {
        handler(newVal) { this.businessHoursStart = (typeof newVal == 'number') ? newVal / 60 : 7; },
        immediate: true,
      },
      'computedConfig.businessHours.end': {
        handler(newVal) { this.businessHoursEnd = (typeof newVal == 'number') ? newVal / 60 : 19; },
        immediate: true,
      },
    },
    created() {
      this.workloadHoursPerDay = this.computedConfig.workloadHoursPerDay;
    },
    methods: {
      updateBusinessHours() {
        if (! this.activateBusinessHours) this.setParam('businessHours', null);
        let start = Math.max(Math.min(this.businessHoursStart, this.businessHoursEnd), 0);
        let end = Math.min(Math.max(this.businessHoursStart, this.businessHoursEnd), 24);
        if (end - start < 1) { // 1 hour minimum gap
          end = Math.min(start + 1, 24);
          start = end - 1;
        }
        this.setParam('businessHours', { start: start * 60, end: end * 60 });
      },
      setParam(prop, val) {
        this.$store.dispatch('users/accessRight/updateBusinessConfig', { [prop]: val });
      },
      debounceSetParam: _.debounce(function (prop, val) {
        this.setParam(prop, val);
      }, 5000),
    },
  };
</script>
