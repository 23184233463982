<template>
  <div v-if="dataCount" :class="isSmallWidth ? 'small-width' : null" style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center; padding: 0">
    <div v-if="dataCount.description">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ props }">
          <div v-bind="props">
            <div class="synthesis-item"><i class="far fa-align-center fa-lg"></i></div>
          </div>
        </template>
        <div class="synthesis-popover">
          <span class="pre">{{ description }}</span>
        </div>
      </v-menu>
    </div>
    <div v-if="dataCount.customDescription">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ props }">
          <div v-bind="props">
            <div class="synthesis-item"><i class="far fa-align-center fa-lg"></i></div>
          </div>
        </template>
        <div class="synthesis-popover" v-html="customDescription"></div>
      </v-menu>
    </div>
    <div v-if="el.isType('macro') && dataCount.subTasks">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ props }">
          <div v-bind="props">
            <div class="synthesis-item">{{ dataCount.subTasks }}&nbsp;<i class="far fa-sitemap"></i></div>
          </div>
        </template>
        <div class="synthesis-popover">
          <div v-for="subTask in subTasks" :key="subTask.id">
            <span v-if="subTask.isType('macro')" class="mr-1">(macro)</span>
            <span class="text-truncate" style="max-width: 200px">{{ subTask.getTitle() || defaultTitle(subTask) }}</span>
            <small class="ml-2" style="color: #C5C5C5">
              {{ $filters.moment(subTask.getStartTime(), 'mediumDateNoYear', planningLocale) }}
            </small>
            <small v-if="! subTask.isType('milestone')" style="color: #C5C5C5">
              - {{ $filters.moment(subTask.getEndTime(), 'mediumDateNoYear', planningLocale) }}
            </small>
          </div>
        </div>
      </v-menu>
    </div>
    <div v-if="dataCount.checklist">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ props }">
          <div v-bind="props">
            <div class="synthesis-item">{{ dataCount.checklist }}&nbsp;<i class="far fa-check-square"></i></div>
          </div>
        </template>
        <div class="synthesis-popover">
          <div v-for="item in filteredChecklist">
            <i :class="el.getChecklistItemClass(item)" class="far fa-square mr-1"></i>
            <span class="ml-1">{{ item.title }}</span>
          </div>
        </div>
      </v-menu>
    </div>
    <div v-if="dataCount.users">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ props }">
          <div v-bind="props">
            <div class="synthesis-item">{{ dataCount.users }}&nbsp;<i class="far fa-user" style="margin-left: -1px;"></i></div>
          </div>
        </template>
        <div class="synthesis-popover">
          <div v-for="user in users" class="nobr my-1">
            <user-avatar :user="user" show-username username-format="short" class="mr-1"></user-avatar>
          </div>
        </div>
      </v-menu>
    </div>
    <div v-if="dataCount.attachments">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ props }">
          <div v-bind="props">
            <div class="synthesis-item">{{ dataCount.attachments }}&nbsp;<i class="far fa-paperclip fa-lg"></i></div>
          </div>
        </template>
        <div class="synthesis-popover">
          <div v-for="link in attachments" class="nobr text-ellipsis" style="max-width:120px">
            <a v-if="link.url" :href="subdomainLinkUrl(link.url)" target="_blank" rel="noopener" class="text-white d-flex align-center" @click.stop>
              <link-icon :link="link" class="mr-1"></link-icon>
              {{ link.name || link.url }}
            </a>
          </div>
        </div>
      </v-menu>
    </div>
    <div v-if="dataCount.budgets" class="d-flex">
      <v-menu :disabled="! hasPopover" :close-on-content-click="false" open-on-hover
              z-index="130" open-delay="150" content-class="synthesis-popover-container">
        <template #activator="{ props }">
          <div v-bind="props">
            <div class="synthesis-item">{{ dataCount.budgets }}&nbsp;<i :class="`budget-${budgets[0].icon || ''} fa-lg`"></i></div>
          </div>
        </template>
        <div class="synthesis-popover">
          <template v-for="(elBudgets, state) in elBudgetsByStates">
            <div v-for="(budget, icon) in elBudgets" class="nobr">
              <span :class="`budget-${icon || ''}`" class="mr-1"></span>
              <span v-if="budget.amount_inprogress && budget.amount">{{ $filters.number(budget.amount_inprogress) }}&nbsp;/</span>
              <span v-if="budget.amount_inprogress && ! budget.amount">{{ $filters.number(budget.amount_inprogress) }}</span>
              <span v-if="budget.amount">{{ $filters.number(budget.amount) }}</span>
              <span v-if="budgetStates[state]">({{ $filters.lowercase(budgetStates[state]) }})</span>
            </div>
          </template>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<style>
  .element .synthesis-item {
    margin: 2px 3px;
    font-size: 9px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1px;
    transition: all .3s;
  }

  .element .small-width .synthesis-item {
    margin: 2px 1px;
  }

  .synthesis-popover-container {
    padding-top: 4px;
    box-shadow: none;
  }

  .synthesis-popover {
    max-width: 300px;
    background: #616161;
    color: #fff;
    font-size: 12px;
    padding: 5px 8px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    border-radius: 2px;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import LinkIcon from '@/components/Planning/partials/LinkIcon.vue';

  function hasNonFalseKey(object) {
    return Object.keys(object).reduce((prev, key) => ((object[key] || prev)), false);
  }

  export default {
    components: {
      LinkIcon,
    },
    props: {
      planning: { type: Object, required: true },
      el: { required: true, type: Object },
    },
    computed: {
      description() { return this.el.getDescription(); },
      customDescription() {
        return clientConfig.elements && clientConfig.elements.customDescription && this.el.data.customFields && this.el.data.customFields[clientConfig.elements.customDescription.dataField];
      },
      subTaskIds() {
        return new Set(this.el.getSubTasks());
      },
      subTasks() {
        if (! this.subTaskIds.size) return null;
        return this.el.getPlanning().elements.filter(item => this.subTaskIds.has(parseInt(item.id, 10))).sort((a, b) => {
          if (a.isType('macro')) {
            if (! b.isType('macro')) return -1;
          } else if (b.isType('macro')) {
            return 1;
          }
          return (a.getStartTime() < b.getStartTime() ? -1 : 1);
        });
      },
      checklist() { return this.el.getChecklist(); },
      filteredChecklist() { return (this.checklist || []).filter(item => ! item.checked); },
      users() { return this.el.getUsers(); },
      attachments() { return this.el.getLinks(); },
      budgets() { return this.el.getBudgets() && this.el.getBudgets().filter(budget => budget.amount || budget.amount_inprogress); },
      dataCount() {
        const ret = {
          description: !! this.description,
          customDescription: !! this.customDescription,
          subTasks: this.subTasks?.length,
          checklist: this.filteredChecklist.length,
          users: this.users && this.users.length,
          attachments: this.attachments && this.attachments.length,
          budgets: this.budgets && this.budgets.length,
        };
        const hiddenFields = clientConfig.subprojects && clientConfig.subprojects.synthesisHidden;
        (hiddenFields || []).forEach((hiddenField) => { ret[hiddenField] = false; });
        return hasNonFalseKey(ret) ? ret : null;
      },
      budgetStates() {
        return this.$store.getters['users/accessRight/config'].budgetStates;
      },
      elBudgetsByStates() {
        const elBudgets = this.budgets;
        if (! elBudgets) return null;
        const budgetStateKeysSet = new Set(Object.keys(this.budgetStates));
        const budgetsByStates = {};
        elBudgets.forEach((budget) => {
          if (! budget.amount && ! budget.amount_inprogress) return;
          const state = budgetStateKeysSet.has(budget.state) ? budget.state : '';
          const icon = budget.icon || '';
          if (! budgetsByStates[state]) budgetsByStates[state] = {};
          if (! budgetsByStates[state][icon]) budgetsByStates[state][icon] = { amount: 0, amount_inprogress: 0 };
          budgetsByStates[state][icon].amount += budget.amount || 0;
          budgetsByStates[state][icon].amount_inprogress += budget.amount_inprogress || 0;
        });
        return budgetsByStates;
      },
      isSmallWidth() {
        return this.el.getWidth() < 60;
      },
      hasPopover() {
        return this.planning.meta.companyconfig.subprojectsSynthesisPopover || this.$store.getters['users/accessRight/config'].subprojectsSynthesisPopover;
      },
      planningLocale() {
        return this.planning.timeline.getTimelocale();
      },
    },
    methods: {
      defaultTitle(el) {
        if (el.isType('macro')) return this.$t('PLANNING.NEW_MACRO_BUBBLE');
        return el.isType('milestone') ? this.$t('PLANNING.MILESTONE') : this.$t('PLANNING.NEW_BUBBLE');
      },
      subdomainLinkUrl(url) {
        return clientConfig.subdomain && url?.startsWith('https://api.bubbleplan.net') ? `${url}?subdomain=${clientConfig.subdomain}` : url;
      },
    },
  };
</script>
