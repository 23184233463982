<template>
  <v-pagination v-if="items.length > itemsPerPage" v-model="currentPage" :length="paginationLength" :total-visible="11">
  </v-pagination>
</template>

<script>
  export default {
    props: {
      items: { type: Array, required: true },
      itemsPerPage: { type: Number, default: 10 },
    },
    emits: ['update'],
    data() {
      return {
        currentPage: 1,
      };
    },
    computed: {
      paginationLength() {
        return Math.ceil(this.items.length / this.itemsPerPage);
      },
      boundaryIndexes() {
        return { start: this.itemsPerPage * (this.currentPage - 1), end: this.itemsPerPage * this.currentPage }; // for using with slice()
        // end might be > items.length but returning items.length would create unnecessary update
      },
    },
    watch: {
      paginationLength() {
        if (this.currentPage > this.paginationLength) this.currentPage = 1;
      },
      boundaryIndexes: {
        handler() {
          this.$emit('update', this.boundaryIndexes);
        },
        immediate: true,
      },
    },
  };
</script>
