import clientConfig from '@/client_customs/config';
import defaultConfig from '@/js/defaultConfig';

/* ********* */
/* MUTATIONS */
/* ********* */
function set(state, values) {
  Object.keys(values).forEach((prop) => {
    state[prop] = values[prop];
  });
}

function setBusinessConfig(state, values) {
  if (! state.isBusiness) return;
  Object.keys(values).forEach((prop) => {
    state.businessConfig[prop] = values[prop];
  });
}

/* ******* */
/* GETTERS */
/* ******* */
function config(state) {
  const { isBusiness } = state;
  const companyConfig = state.companyConfig || {};
  const businessConfig = isBusiness && state.businessConfig || {};

  return {
    hasViews: !! (businessConfig.hasViews != undefined ? businessConfig.hasViews : isBusiness || companyConfig.views),
    views: companyConfig.views,
    hideExtenalLink: !! (businessConfig.hideExtenalLink || clientConfig.planningShare && clientConfig.planningShare.hideExtenalLink),
    meetingactions_extrafields: companyConfig.meetingactions_extrafields,
    projectsheet_steps_extrafields: companyConfig.projectsheet_steps_extrafields,
    links: _.extend({ hideOneDrive: false, hideGoogleDrive: false, hideDropbox: false, hideFileUpload: false }, businessConfig.links, clientConfig.links),
    availableIntegrations: businessConfig.availableIntegrations || clientConfig.integrations && clientConfig.integrations.availableIntegrations || ['microsoft', 'google'],
    clientConfigTextUsers: clientConfig.textUsers,
    businessTextUsers: businessConfig.textUsers,
    processSteps: defaultConfig.processSteps(), // mode kanban
    textUsers: companyConfig.textUsers?.map(item => ({ username: item }))
      || (clientConfig.textUsers || businessConfig.textUsers) && (businessConfig.textUsers || []).concat(clientConfig.textUsers || []).map(item => ({ username: item })),
    subprojectsVisibleDetails: businessConfig.subprojectsVisibleDetails || clientConfig.subprojects && clientConfig.subprojects.visibleDetails || ['progress', 'synthesis'],
    subprojectsSynthesisPopover: businessConfig.subprojectsSynthesisPopover || clientConfig.subprojects && clientConfig.subprojects.synthesisPopover || false,
    assignActionsToCreator: isBusiness ? businessConfig.assignActionsToCreator : false,
    budgetStates: Object.keys(businessConfig.budgetStates || {}).length ? businessConfig.budgetStates : defaultConfig.budgetStates(),
    workloadInHours: isBusiness ? businessConfig.workloadInHours : false,
    workloadHoursPerDay: isBusiness ? businessConfig.workloadHoursPerDay || 8 : null, // default = 8 hours.
    projectStatus: isBusiness ? defaultConfig.projectStatus() : null,
    defaultProjectPrivacy: businessConfig.defaultProjectPrivacy || clientConfig.projects?.defaultPrivacy || 'company',
    timelineFormats: angular.merge(defaultConfig.timelineFormats(), businessConfig.timelineFormats || clientConfig.timeline?.timelineFormats),
    businessHours: businessConfig.businessHours ? { start: 7 * 60, end: 19 * 60, ...businessConfig.businessHours } : null,
    businessHoursRatio: businessConfig.businessHours ? 24 * 60 / (businessConfig.businessHours.end - businessConfig.businessHours.start) : 1,
    designcolor: clientConfig.designcolor || { main: '#0169C1', second: '#777777' },
    // functions
    getCategoryColor(category) {
      if (! category) return 'fff';
      return businessConfig.categoryColors?.[category] || clientConfig.categoryColors?.[category] || window.str2color(category);
    },
    elTitleTooltip: clientConfig.elements?.elTitleTooltip || companyConfig.elTitleTooltipField && (el => el.data[companyConfig.elTitleTooltipField]) || null,
  };
}

/* ******* */
/* ACTIONS */
/* ******* */
function getCompanyConfigItem(user, configProp) {
  return user.company && user.company.config && user.company.config[configProp];
}
// End helpers

function load({ rootState, commit }) {
  const { user } = rootState.users;
  const isTrial = user.company && user.company.isTrial && user.company.premiumExpireDate;
  const isPremium = user.company && user.company.isPremium;
  const isBusiness = isPremium && user.company.isBusiness;
  commit('set', {
    isTrial,
    isPremium,
    isBusiness,
    clientConfig,
    companyConfig: user.company && user.company.config || {},
    businessConfig: user.company && user.company.business_config || {},
    isAdmin: ['superadmin', 'admin', 'company_admin'].indexOf(user.access_right) > -1,
    isConsultOnly: user.access_right == 'consult',
    maxprojects: Math.max((isPremium ? user.company.maxprojects : user.maxprojects) || 0, 1),
    hasMonitoringPlan: getCompanyConfigItem(user, 'monitoringplan'),
    hasOrganization: user.hasOrganization,
    hasGroups: isBusiness || getCompanyConfigItem(user, 'hasGroups'),
    canAssignGroups: isBusiness || getCompanyConfigItem(user, 'canAssignGroups'),
    hasUserWorkloadIndicator: isBusiness || getCompanyConfigItem(user, 'hasUserWorkloadIndicator'),
    hasMacroTasks: isBusiness || getCompanyConfigItem(user, 'hasMacroTasks'),
    hasWorkingViews: isBusiness || getCompanyConfigItem(user, 'hasWorkingViews'),
    hasProcessDashboard: isBusiness || getCompanyConfigItem(user, 'hasProcessDashboard'),
    hasImportTemplate: isBusiness || getCompanyConfigItem(user, 'hasImportTemplate'),
    hideBubbleMeeting: getCompanyConfigItem(user, 'bubblemeeting') === false,
    hasMeeting: user.company && user.company.hasMeeting || false,
    canReduceElPredecessorsToMinimum: getCompanyConfigItem(user, 'reduceElPredecessorsToMinimum'), /* Espace et vie */
    hasHerblayTicketing: getCompanyConfigItem(user, 'herblayTicketing'), /* Herblay */
    hasLocabriImport: getCompanyConfigItem(user, 'locabriImport') && user.access_right != 'consult', /* Locabri */
    canCreateMeeting: (() => { // boolean
      if (isPremium && user.meeting_right == 'organizer') return true; // isOrganizer
      const trialEnd = getCompanyConfigItem(user, 'meeting_trial_end');
      return trialEnd && moment().isBefore(trialEnd);
    })(),
  });
}

function updateBusinessConfig({ state, commit, rootState }, values) {
  commit('setBusinessConfig', values);
  return window.apiSrv.call(`companies/${rootState.users.user.company_id}`, 'update', { business_config: state.businessConfig });
}

export default {
  namespaced: true,
  state: {
  },
  getters: {
    config,
  },
  mutations: {
    set,
    setBusinessConfig,
  },
  actions: {
    load,
    updateBusinessConfig,
  },
};
