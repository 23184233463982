<template>
  <div class="d-flex align-center ml-2 mb-2">
    <span class="text-h6">{{ $t('HOME.MY_CURRENT_PROJECTS') }}</span>
    <v-tooltip location="left" max-width="200">
      <template #activator="{ props }">
        <v-icon color="info" size="small" class="ml-2" v-bind="props">far fa-question-circle</v-icon>
      </template>
      <span>{{ $t('HOME.PROJECTS_EXPLANATIONS') }}</span>
    </v-tooltip>
    <router-link to="/projects" class="ml-auto">{{ $t('HOME.SEE_ALL_PROJECTS') }}</router-link>
  </div>
  <div>
    <v-row class="flex-nowrap overflow-x-auto">
      <v-col v-for="project in orderedPlannings" :key="project.id" style="min-width: 260px; max-width: 260px;">
        <router-link :to="project.url" style="color:inherit; text-decoration: none">
          <project-preview :project="project" :thumbnail="project.imgurl">
            <template #notifications>
              <div class="d-flex align-center" style="height: 40px; padding-top: 2px">
                <router-link v-if="project.notifications_count" :to="`${project.url}?open=notifications`" :title="$t('HOME.NOTIFICATION_ICON_EXPLANATION')" class="link">
                  <span class="text-errorred"><i class="far fa-bell"></i> {{ project.notifications_count }}</span>
                </router-link>
                <router-link v-if="project.actions_late_count" :to="`${project.url}?open=actions`" :title="$t('HOME.LATE_ICON_EXPLANATION')" class="link">
                  <span class="text-errorred"><i class="far fa-check-square"></i> {{ project.actions_late_count }}</span>
                </router-link>
                <router-link v-if="project.actions_urgent_count" :to="`${project.url}?open=actions`" :title="$t('HOME.URGENT_ICON_EXPLANATION')" class="link">
                  <span class="text-warningorange"><i class="far fa-check-square"></i> {{ project.actions_urgent_count }}</span>
                </router-link>
                <router-link v-if="project.tasks_count" :to="project.url" :title="$t('HOME.TASKS_ICON_EXPLANATION')" class="link">
                  <span class="text-primary"><i class="far fa-calendar-alt"></i> {{ project.tasks_count }}</span>
                </router-link>
                <router-link v-if="project.milestones_count" :to="project.url" :title="$t('HOME.MILESTONES_ICON_EXPLANATION')" class="link">
                  <span class="text-primary"><i class="fa icon-diamonds"></i> {{ project.milestones_count }}</span>
                </router-link>
              </div>
            </template>
          </project-preview>
        </router-link>
      </v-col>
      <v-col v-if="! orderedPlannings.length" style="min-width: 260px; max-width: 260px;">
        <v-card class="h-100 pa-12 flex-center-center text-center text-medium-emphasis">
          {{ $t('HOME.NO_PLANNING_ACTIVITY') }}
        </v-card>
      </v-col>
      <v-col v-if="! isConsultOnly" style="min-width: 260px; max-width: 260px;">
        <router-link to="/projects#new" style="color:inherit; text-decoration: none">
          <v-card-clickable class="h-100 bg-lightgrey pa-6 flex-center-center flex-column text-center">
            <v-icon size="x-large">far fa-plus</v-icon>
            <span class="mt-4" style="line-height: normal">{{ $t('PROJECTS.START_A_NEW_PROJECT') }}</span>
          </v-card-clickable>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import ProjectPreview from '@/components/Projects/ProjectPreview.vue';

  export default {
    components: {
      ProjectPreview,
    },
    props: {
      plannings: { type: Array, required: true },
    },
    computed: {
      orderedPlannings() {
        return this.plannings.slice().sort((a, b) => (a.priority < b.priority ? 1 : -1));
      },
      isConsultOnly() { return this.$store.state.users.accessRight.isConsultOnly; },
    },
  };
</script>
