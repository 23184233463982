<template>
  <div>
    <div v-if="isMiniElement">
      <i class="far fa-user"></i> {{ elUsers.length }}
    </div>
    <template v-else>
      <div v-for="user in elUsers" class="d-inline-flex align-center mr-1 mt-.5" style="vertical-align: middle">
        <user-avatar :user="user" :show-username="! user.id" tooltip :class="{ 'mr-.5': user.group_id }"></user-avatar>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      el: { type: Object, required: true },
      isMiniElement: { type: Boolean, default: false },
    },
    computed: {
      elUsers() {
        return this.el.getUsers() || [];
      },
    },
  };
</script>
