<template>
  <v-menu v-model="dropdownIsOpen" location="bottom center" transition="slide-y-transition" offset="4">
    <template #activator="{ props: onMenu }">
      <v-tooltip :disabled="dropdownIsOpen" location="bottom">
        <template #activator="{ props: onTooltip }">
          <v-btn v-if="! userConsultOnly" variant="text" icon size="small"
                 v-bind="mergeProps(onMenu, onTooltip)">
            <v-icon>far fa-cog</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('NAV.MANAGE') }}</span>
      </v-tooltip>
    </template>

    <v-list density="compact">
      <v-list-item to="/manage#company" :active="false" class="px-3">
        <div class="px-1 d-flex align-center">
          <v-icon size="small" class="mr-2">far fa-building</v-icon>
          {{ $t('MANAGE.ACCOUNT') }}
        </div>
      </v-list-item>
      <v-list-item to="/manage#projects" :active="false" class="px-3">
        <div class="px-1 d-flex align-center">
          <v-icon size="small" class="mr-2">far fa-folder-open</v-icon>
          {{ $t('MANAGE.PROJECTS') }}
        </div>
      </v-list-item>
      <v-list-item to="/manage#profile" :active="false" class="px-3">
        <div class="px-1 d-flex align-center">
          <v-icon size="small" class="mr-2">far fa-user</v-icon>
          {{ $t('ACCOUNT.PROFILE') }}
        </div>
      </v-list-item>
      <v-list-item v-if="isAdmin" to="/manage#config" :active="false" class="px-3">
        <div class="px-1 d-flex align-center">
          <v-icon size="small" class="mr-2">far fa-cog</v-icon>
          {{ $t('MANAGE.CONFIGURATION') }}
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { mergeProps } from 'vue';

  export default {
    data() {
      return {
        dropdownIsOpen: null,
      };
    },
    computed: {
      isAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      userConsultOnly() { return this.$store.state.users.accessRight.userConsultOnly; },
    },
    methods: {
      mergeProps,
    },
  };
</script>
