import { mapActions } from 'vuex';
import constants from '@/js/constants.js';

/** ***************************** */
/** *     ELEMENTS RESIZE      ** */
/** ***************************** */
function cleanResizePlaceholders(planning) {
  planning.elements.filter(item => item.isResizePlaceholder).forEach((resizePlaceholder) => {
    resizePlaceholder.isResizePlaceholder = false;
  });
}

let lastResize = 0;
let resizeThrottledTimeout;

function doResize(el, elWidth, handle) {
  lastResize = Date.now();
  el.resize(elWidth, handle);
}

const resizeThrottled = _.throttle((el, elWidth, handle) => {
  if (resizeThrottledTimeout) clearTimeout(resizeThrottledTimeout);
  if (Date.now() - lastResize < 3000) {
    resizeThrottledTimeout = setTimeout(() => doResize(el, elWidth, handle));
    return;
  }
  doResize(el, elWidth, handle);
}, 100);

export default {
  methods: {
    resizableConfig(el) {
      if (! this.isDynamic(el)) return { disabled: true, handles: {} };
      const vm = this;
      return {
        disabled: el.isType('macro') || (el.isType('task') && el.getIsLocked()) || (this.planning.config.lockDragAndDrop && this.planning.meta.access_right != 'admin'),
        handles: 'e, w',
        minWidth: el.isType('milestone') ? constants.milestoneMinWidth : constants.taskMinWidth,
        resize(event, ui) {
          vm.onResize(event, ui, el);
        },
        start(event, ui) {
          vm.onResizeStart(event, ui, el);
        },
        stop(event, ui) {
          vm.onResizeStop(event, ui, el);
        },
      };
    },

    onResizeStart(event, ui, el) {
      setTimeout(() => { ui.helper.addClass('is-resizing'); });
      this.startChangingElement();
      this.$store.dispatch('selection/resetSelection', [el.id]);

      cleanResizePlaceholders(this.planning);
      el.isResizePlaceholder = true;

      if (el.isType('task')) this.setSnapBoundaries(ui);
    },

    onResize(event, ui, el) {
      if (el.isType('task')) this.snapToBoundaries(ui);
      if (el.isType('milestone')) {
        const gap = ui.size.width - ui.originalSize.width;
        ui.size.width = ui.originalSize.width + 2 * gap;
        ui.position.left = ui.originalPosition.left - gap + 3;

        if (ui.size.width < constants.milestoneMinWidth) { // min width
          ui.position.left -= (constants.milestoneMinWidth - ui.size.width) / 2;
          ui.size.width = constants.milestoneMinWidth;
        }
      }
      const handle = (ui.position.left !== ui.originalPosition.left) ? 'w' : 'e';
      const elWidth = Math.max(ui.size.width, el.isType('milestone') ? constants.milestoneMinWidth : constants.taskMinWidth);
      resizeThrottled(el, elWidth, handle);
    },

    onResizeStop(event, ui, el) {
      setTimeout(() => { ui.helper.removeClass('is-resizing'); }, 300);
      cleanResizePlaceholders(this.planning);
      const handle = (ui.position.left !== ui.originalPosition.left) ? 'w' : 'e';
      const elWidth = Math.max(ui.size.width, el.isType('milestone') ? constants.milestoneMinWidth : constants.taskMinWidth);
      el.resize(elWidth, handle);
      this.changingElement(el);
    },

    /** KANBAN */
    kanbanResizableConfig() {
      return { disabled: true, handles: {} };
    },

    ...mapActions('planning/elements', ['setSnapBoundaries', 'snapToBoundaries', 'changingElement']),
  },
};
