<template>
  <v-card class="pa-6">
    <div v-if="isLoading" class="text-center" style="height: 270px">
      <i class="far fa-spinner fa-spin fa-2x fa-fw"></i>
    </div>
    <template v-else>
      <b>{{ $t('NOTIFICATIONS.ACTIVATION') }}</b>
      <v-switch v-model="receiveNotifications" :label="$t('NOTIFICATIONS.RECEIVE_EMAILS')"></v-switch>
      <div class="py-4"></div>
      <div :class="{ 'events-disabled opacity-low': ! receiveNotifications }">
        <b>{{ $t('NOTIFICATIONS.EMAILS_FREQUENCY') }}</b>
        <v-radio-group v-model="frequency">
          <v-radio :label="$t('NOTIFICATIONS.DAILY')" value="daily"></v-radio>
          <v-radio :label="$t('NOTIFICATIONS.HOURLY')" value="hourly"></v-radio>
          <v-radio :label="$t('NOTIFICATIONS.IMMEDIATELY')" value="immediately"></v-radio>
        </v-radio-group>
      </div>
    </template>
  </v-card>
</template>

<script>
  export default {
    data() {
      return {
        receiveNotifications: true,
        frequency: 'daily',
        isLoading: true,
      };
    },
    created() {
      window.apiSrv.call('useroptions', 'show', 'notifications-email-frequency').then((response) => {
        const notificationsEmailFrequency = response && response.data && response.data.useroption && response.data.useroption.value || 'daily';
        this.receiveNotifications = notificationsEmailFrequency != 'none';
        if (this.receiveNotifications) this.frequency = notificationsEmailFrequency;
        this.isLoading = false;
      });
    },
    watch: {
      receiveNotifications: {
        handler() {
          this.save();
        },
        immediate: false,
      },
      frequency() {
        if (this.receiveNotifications) {
          this.save();
        }
      },
    },
    methods: {
      save() {
        const notificationsEmailFrequency = this.receiveNotifications ? this.frequency : 'none';
        window.apiSrv.call('useroptions', 'update', { id: 'notifications-email-frequency', value: notificationsEmailFrequency }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error: Notification parameters were not modified", body: message || "" });
        });
      },
    },
  };
</script>
