<template>
  <div class="py-6 px-12">
    <!-- Title -->
    <div class="d-flex align-center">
      <v-select v-model="icon" :items="iconOptions" item-title="label" item-value="id" class="mr-5" style="max-width: 80px">
        <template #item="{ props, item: { raw: item } }">
          <v-list-item v-bind="props">
            <template #title>
              <v-icon class="mr-1" style="width: 24px">{{ item.name }}</v-icon> {{ item.label }}
            </template>
          </v-list-item>
        </template>
        <template #selection="{ item: { raw: item } }">
          <v-icon style="width: 100%">{{ item.name }}</v-icon>
        </template>
      </v-select>
      <v-text-field v-model="title" :placeholder="$t('PERSONAL_CALENDAR.ADD_TITLE')" autofocus></v-text-field>
    </div>

    <!-- Dates & Occupancy -->
    <div class="d-flex align-center mt-4 mb-1">
      <div class="mr-2">
        <v-icon>far fa-calendar-alt</v-icon>
      </div>
      <b>{{ $t('ELEMENT-DETAIL.DATES') }}</b>
    </div>
    <div>
      <div class="d-flex align-center">
        <date-field v-model="startTime" :label="$t('ELEMENT-DETAIL.FROM')" no-null class="ml-1"></date-field>
        <div style="flex: 0 1 16px"></div>
        <date-field v-model="endTime" :label="$t('ELEMENT-DETAIL.TO')" no-null class="ml-1"></date-field>
      </div>
      <div class="d-flex align-center my-2">
        {{ $t('PERSONAL_CALENDAR.OCCUPANCY') }}
        <v-text-field v-model.number="occupancyPercent" type="number" min="0" max="100" step="10"
                      suffix="%" class="ml-1" style="max-width: 100px">
        </v-text-field>
      </div>
    </div>

    <!-- Description -->
    <div class="d-flex align-center mt-4 mb-1">
      <div class="mr-2">
        <v-icon>far fa-align-justify</v-icon>
      </div>
      <b>{{ $t('PERSONAL_CALENDAR.HOLIDAY_DESCRIPTION') }}</b>
    </div>
    <div>
      <v-textarea v-model="description" rows="2" class="pt-0"></v-textarea>
    </div>

    <!-- Btns Cancel & Save -->
    <div class="mt-4 d-flex align-center">
      <v-menu>
        <template #activator="{ props }">
          <v-btn icon size="small" variant="outlined" color="errorred" v-bind="props">
            <v-icon>far fa-trash-alt</v-icon>
          </v-btn>
        </template>
        <div class="bg-white">
          <v-btn rounded color="errorred text-white" class="ma-4" @click="removeHoliday()">
            {{ $t('PERSONAL_CALENDAR.DELETE_ELEMENT_PERMANENTLY') }}
          </v-btn>
        </div>
      </v-menu>
      <v-spacer></v-spacer>
      <v-btn rounded size="large" variant="outlined" @click="close()">
        {{ $t('GLOBAL.CANCEL') }}
      </v-btn>
      <v-btn color="primary" rounded size="large" class="ml-4" @click="saveHoliday()">
        {{ $t('GLOBAL.VALIDATE') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      holiday: { type: Object, default: () => ({}) },
    },
    emits: ['close'],
    data() {
      return {
        allDay: true,
        icon: this.holiday.icon || 'holiday',
        title: this.holiday.title || '',
        startTime: moment(this.holiday.start),
        endTime: moment(this.holiday.end),
        occupancy: typeof this.holiday.occupancy == 'number' ? this.holiday.occupancy : 1,
        description: this.holiday.description || '',
        iconOptions: this.$store.getters['holidays/icons'],
      };
    },
    computed: {
      occupancyPercent: {
        get() {
          return this.occupancy * 100;
        },
        set(newVal) {
          this.occupancy = (newVal || 0) / 100;
        },
      },
    },
    watch: {
      startTime(newValue, oldValue) {
        if (! newValue) return;
        if (moment(this.endTime).isBefore(newValue)) this.endTime = moment(newValue).add(this.endTime.diff(oldValue));
      },
      endTime(newValue, oldValue) {
        if (! newValue) return;
        if (newValue.isBefore(this.startTime)) this.startTime = moment(newValue).add(this.startTime.diff(oldValue));
      },
    },
    methods: {
      saveHoliday() {
        this.$emit('close', 'save', {
          id: this.holiday.id || window.uuid(),
          icon: this.icon,
          title: this.title,
          start: this.startTime.format('YYYY-MM-DD'),
          end: this.endTime.format('YYYY-MM-DD'),
          occupancy: this.occupancy,
          description: this.description,
        });
      },
      removeHoliday() {
        this.$emit('close', 'delete');
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
