<template>
  <div class="d-flex">
    <div class="mr-8 responsive-lateral-menu" :class="{ 'is-mobile': $vuetify.display.mobile }" style="width: 250px; flex: 0 0 250px">
      <div class="overflow-y-auto overflow-x-hidden light-scrollbar overscroll-behavior-y-contain pr-2"
           style="position: fixed; top: calc(var(--v-layout-top) + 16px); bottom: 16px; width: 250px">
        <tabs-vertical v-model="openedTabIndex">
          <v-tab value="projectsheet">
            <v-icon start>far fa-flag</v-icon>
            {{ $t('PROJECT_SHEET.PROJECT_SHEET') }}
          </v-tab>
          <v-tab v-for="customTab in customTabs" :key="customTab.id" :value="customTab.id">
            <v-icon v-if="customTab.icon" start>{{ customTab.icon }}</v-icon>
            {{ customTab.label }}
          </v-tab>
          <v-tab value="files">
            <v-icon start>far fa-paperclip</v-icon>
            {{ $t('PROJECT_SHEET.FILES') }}
          </v-tab>
          <v-tab value="budget">
            <v-icon start>far fa-euro-sign</v-icon>
            {{ $t('PROJECT_SHEET.BUDGET') }}
          </v-tab>
        </tabs-vertical>
      </div>
    </div>

    <div v-if="planning.visibleTimeline" class="flex-grow-1 d-flex pr-4" :style="{ 'max-width': openedTabIndex == 'projectsheet' ? null : '800px' }" style="min-height: 70vh; overflow: hidden">
      <v-window v-model="openedTabIndex" :touch="false" class="flex-grow-1">
        <!-- TAB USERS -->
        <v-window-item :transition="false" :reverse-transition="false" value="projectsheet">
          <project-sheet :planning="planning" @update="updateProjectSheet" @reload-planning="$store.commit('eventbus/call', { event: 'update-project' })"></project-sheet>
        </v-window-item>

        <!-- TAB PROJECTS-->
        <v-window-item :transition="false" :reverse-transition="false" value="files">
          <project-report-files :planning="planning" @open-element="openElement"></project-report-files>
        </v-window-item>

        <!-- TAB CONFIGURATION -->
        <v-window-item :transition="false" :reverse-transition="false" value="budget">
          <project-report-budget :planning="planning" @open-element="openElement"></project-report-budget>
        </v-window-item>

        <!--    CUSTOM : HORIS MILESTONES SYNTHESIS -->
        <v-window-item v-for="customTab in customTabs" :key="customTab.id" :transition="false" :reverse-transition="false" :value="customTab.id">
          <client-custom-component :name="customTab.component" :planning="planning" :custom-tab="customTab"></client-custom-component>
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import ProjectSheet from '@/components/ProjectSheet/ProjectSheet.vue';
  import ProjectReportFiles from './ProjectReportFiles.vue';
  import ProjectReportBudget from './ProjectReportBudget.vue';

  export default {
    components: {
      ProjectSheet,
      ProjectReportFiles,
      ProjectReportBudget,
    },
    props: {
      planning: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
    },
    emits: ['close', 'open-element'],
    data() {
      return {
        openedTabIndex: null,
        customTabs: clientConfig.projectsheet?.customTabs,
      };
    },
    watch: {
      openedTabIndex() {
        window.sessionStorageWrapper.setItem('option.projectreportTab', this.openedTabIndex);
      },
    },
    created() {
      const configTab = window.sessionStorageWrapper.getItem('option.projectreportTab');
      if (configTab) this.openedTabIndex = configTab;
    },
    methods: {
      close() {
        this.$emit('close');
      },
      updateProjectSheet(values) {
        _.extend(this.planning.projectsheet, values);
        this.planning.saveAdmin({ projectsheet: this.planning.projectsheet });
      },
      openElement(el) {
        this.$store.dispatch('ui/planning/scrollToEl', { el, open: this.dynamic });
      },
    },
  };
</script>
