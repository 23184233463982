<template>
  <v-menu v-model="dropdownIsOpen" location="bottom center" transition="slide-y-transition" offset="4">
    <template #activator="{ props }">
      <v-btn variant="text" rounded class="tour-help" style="height: 40px" v-bind="props">
        <v-icon>far fa-question-circle</v-icon>
        <span class="ml-2">{{ $t('NAV.HELP') }}</span>
      </v-btn>

      <!--  MODALS -->
      <help-tips v-if="helpTipsIsOpened" @close="closeModals"></help-tips>
      <help-videos v-if="helpVideosIsOpened" @close="closeModals"></help-videos>
    </template>
    <v-list density="compact">
      <v-list-item :href="`https://bubbleplan.net/auth.php?token=${api_token}&redirect=questions-reponses-logiciel-gestion-projet`" target="_blank" rel="noopener">
        {{ $t('HELP.FAQ') }}
      </v-list-item>
      <v-list-item @click="openTips()">{{ $t('HELP.TIPS') }}</v-list-item>
      <v-list-item @click="openVideoTutos()">{{ $t('HELP.VIDEO_TUTOS') }}</v-list-item>
      <v-list-item target="_blank" rel="noopener" href="https://bubbleplan.net/assets/files/Bubble Plan - Guide rapide d'utilisation.pdf">{{ $t('HELP.USER_GUIDE') }}</v-list-item>
      <v-divider></v-divider>
      <v-list-item v-if="config == 'planning'" @click="playTour(1)">{{ $t('HELP.REPLAY_TUTORIAL') }}</v-list-item>
      <v-list-item v-if="config == 'planning'" @click="playTour(2)">{{ $t('HELP.ADVANCED_TUTORIAL') }}</v-list-item>
      <v-divider v-if="config == 'planning'"></v-divider>
      <v-list-item @click="openNewVersionMsgBox">{{ $t('HELP.NEW_FEATURES') }}</v-list-item>
      <v-divider></v-divider>
      <v-list-item href="mailto:contact@bubbleplan.net" target="_blank" rel="noopener">
        <div>
          <div>{{ $t('HELP.CONTACT_US') }}</div>
          <v-list-item-subtitle>contact@bubbleplan.net</v-list-item-subtitle>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>

  <!-- MODALS -->
  <new-versions-modal></new-versions-modal>
</template>

<script>
  import HelpTips from '@/components/Help/HelpTips.vue';
  import HelpVideos from '@/components/Help/HelpVideos.vue';
  import NewVersionsModal from '@/components/Help/NewVersionsModal.vue';

  export default {
    components: {
      HelpTips,
      HelpVideos,
      NewVersionsModal,
    },
    props: {
      config: { type: String, default: '' }, // planning, projects, manage
    },
    data() {
      return {
        dropdownIsOpen: null,
        api_token: window.apiSrv.getAuthToken(),
      };
    },
    computed: {
      helpTipsIsOpened() {
        return this.$store.state.ui.navigation.openedModal == 'help-tips';
      },
      helpVideosIsOpened() {
        return this.$store.state.ui.navigation.openedModal == 'help-videos';
      },
    },
    methods: {
      openTips() {
        this.$store.commit('ui/navigation/setOpenedModal', 'help-tips');
      },
      openVideoTutos() {
        this.$store.commit('ui/navigation/setOpenedModal', 'help-videos');
      },
      closeModals() {
        this.$store.commit('ui/navigation/setOpenedModal', null);
      },
      async playTour(level) {
        await this.$router.replace({ query: { tour: level || 1 } });
        this.$router.go();
      },
      openNewVersionMsgBox() {
        this.$store.commit('ui/setDisplayNewVersionsWindow', true);
      },
    },
  };
</script>
