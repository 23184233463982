<template>
  <div>
    <v-window v-model="manageTabIndex" :mandatory="false" :touch="false">
      <!-- TAB ACCOUNT USERS -->
      <v-window-item :transition="false" :reverse-transition="false" value="company-users" eager>
        <manage-users-list @remove-user="openRemoveUserModal" @on-update-user-rights="onUpdateUserRights"></manage-users-list>
      </v-window-item>

      <!-- TAB ORGANIZATION -->
      <v-window-item v-if="hasOrganization" :transition="false" :reverse-transition="false" value="company-organization" eager>
        <manage-users-organization @remove-user="openRemoveUserModal" @on-update-user-rights="onUpdateUserRights"></manage-users-organization>
      </v-window-item>

      <!-- TAB GROUPS -->
      <v-window-item :transition="false" :reverse-transition="false" value="company-groups" eager>
        <v-alert v-if="! hasGroups" color="warningorange" type="warning" class="mt-4">
          {{ $t('PREMIUM.BUSINESS.SECTION_IS_BUSINESS') }} &nbsp;
        </v-alert>
        <manage-users-groups v-else></manage-users-groups>
      </v-window-item>

      <!-- TAB OPEN USERS -->
      <v-window-item v-for="user in openedUsers" :key="user.id" :transition="false" :reverse-transition="false" :value="`company-user${user.id}`">
        <manage-users-user-acr ref="userTabs" :user="user"></manage-users-user-acr>
      </v-window-item>
    </v-window>

    <!-- MODALS -->
    <manage-users-user-remove v-if="userToRemove" :user-to-remove="userToRemove" @close="userToRemove = null"></manage-users-user-remove>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ManageUsersList from './ManageUsersList';
  import ManageUsersOrganization from './ManageUsersOrganization';
  import ManageUsersGroups from './ManageUsersGroups';
  import ManageUsersUserAcr from './ManageUsersUserAcr';
  import ManageUsersUserRemove from './ManageUsersUserRemove';

  export default {
    components: {
      ManageUsersList,
      ManageUsersOrganization,
      ManageUsersGroups,
      ManageUsersUserAcr,
      ManageUsersUserRemove,
    },
    data() {
      return {
        userToRemove: null,
      };
    },
    computed: {
      manageTabIndex: {
        get() {
          return this.$store.state.manage.manageTabIndex;
        },
        set(newVal) {
          this.$store.state.manage.manageTabIndex = newVal;
        },
      },
      hasGroups() { return this.$store.state.users.accessRight.hasGroups; },
      hasOrganization() { return this.$store.state.users.accessRight.hasOrganization; },
      ...mapState('manage', ['openedUsers']),
    },
    created() {
      this.$store.commit('eventbus/register', {
        event: 'update-user-tabs',
        listener: 'manage-users',
        cb: (userInfo) => {
          this.onUpdateProjectRightsForUser(userInfo);
        },
      });
    },
    methods: {
      onUpdateUserRights({ userId, newRight }) {
        const userIndex = this.openedUsers.findIndex(item => item.id == userId);
        if (userIndex == -1) return;
        this.openedUsers[userIndex].access_right = newRight;
        const userTab = this.$refs.userTabs[userIndex];
        if (userTab.onUpdateUserRights) userTab.onUpdateUserRights();
      },
      onUpdateProjectRightsForUser({ userId, projectId, data }) {
        const userIndex = this.openedUsers.findIndex(item => item.id == userId);
        if (userIndex == -1) return;
        const userTab = this.$refs.userTabs[userIndex];
        if (userTab.onUpdateProjectRightsForUser) userTab.onUpdateProjectRightsForUser({ userId, projectId, data });
      },
      openRemoveUserModal(user) {
        this.userToRemove = user;
      },
    },
  };
</script>
