<template>
  <tr>
    <td class="w-0">
      <v-btn-icon size="small" color="errorred" @click="$emit('remove-row')">
        <v-icon>far fa-trash-alt</v-icon>
      </v-btn-icon>
    </td>
    <template v-for="csvHeader in csvHeaders">
      <td v-if="csvHeader.mapping == 'schedulestart' || csvHeader.mapping == 'scheduleend'">
        <time-field v-model="dataDest[csvHeader.id]" variant="solo" flat style="pointer-events: none"></time-field>
      </td>
      <td v-else-if="csvHeader.mapping == 'starttime' || csvHeader.mapping == 'endtime'">
        <date-field v-model="dataDest[csvHeader.id]" variant="solo" flat style="pointer-events: none"></date-field>
      </td>
      <td v-else-if="['checklist', 'links', 'users', 'budgets', 'dependencies'].includes(csvHeader.mapping)">
        <div class="overflow-y-auto overflow-x-hidden light-scrollbar overscroll-behavior-y-contain" style="max-height: 80px">
          <div class="text-ellipsis" v-for="line in dataDest[csvHeader.id]">
            <template v-if="csvHeader.mapping == 'checklist'"><i class="far fa-square mr-1"></i>{{ line.title }}</template>
            <template v-else-if="csvHeader.mapping == 'links'"><i class="far fa-external-link mr-1"></i>{{ line.name || line.url }}</template>
            <template v-else-if="csvHeader.mapping == 'users'"><user-avatar :user="line" show-username class="mr-1"></user-avatar></template>
            <template v-else>{{ line }}</template>
          </div>
        </div>
      </td>
      <td v-else-if="! csvHeader.mapping" class="bg-lightgrey"></td>
      <td v-else>
        <!-- <v-textarea v-model="dataDest[csvHeader.id]" variant="solo" flat auto-grow rows="1"></v-textarea> -->
        {{ dataDest[csvHeader.id] }}
      </td>
    </template>
  </tr>
</template>

<script>
  import { getDefaultChecklistItem } from '@/models/helpers/defaultElements';

  // const getDefaultBudgetItem = function () { return { amount: null, amount_inprogress: null, icon: '', state: '' }; };

  export default {
    props: {
      csvHeaders: { type: Object, required: true },
      csvRow: { type: Object, required: true },
    },
    emits: ['remove-row'],
    data() {
      return {
        dataDest: {},
      };
    },
    computed: {
      companyUsersByEmail() {
        return new Map(this.$store.getters['users/getOrganizationUsers'].map(user => [user.email, user]));
      },
    },
    created() {
      this.csvHeaders.forEach((csvHeader, index) => {
        this.$watch(`csvHeaders.${index}.mapping`, () => {
          this.dataDest[csvHeader.id] = this.parseRowData(this.csvRow[csvHeader.id], csvHeader);
        }, { immediate: true });
      });
    },
    methods: {
      parseRowData(val, header) {
        if (header.mapping === 'starttime' || header.mapping === 'endtime') {
          const parsedDate = moment(val, 'DD/MM/YYYY');
          return parsedDate.isValid() ? parsedDate.format() : null;
        }
        if (header.mapping === 'schedulestart' || header.mapping === 'scheduleend') {
          const [h, m] = this.getScheduleElements(val);
          if (h && m) return moment().set('hour', h).set('minute', m).set('second', 0).format();
          return val;
        }
        const splitVal = val.split('\n').filter(item => item);
        if (header.mapping == 'checklist') return this.getChecklist(splitVal);
        if (header.mapping == 'links') return this.getLinks(splitVal);
        if (header.mapping == 'users') return this.getUsers(splitVal);
        // if (header.mapping == 'budgets') return this.getBudgets(splitVal);
        // if (header.mapping == 'dependencies') return this.getDependencies(val, header);
        if (header.mapping == 'title' || header.mapping == 'html') return val || '';
        if (header.mapping == 'progress') return val || 0;
        return val;
      },
      getChecklist(rowDataArray) {
        const checklist = rowDataArray.map(action => ({ ...getDefaultChecklistItem(), title: action.replace('-', '').trim() }));
        return checklist.length ? checklist : null;
      },
      getLinks(rowDataArray) {
        const links = rowDataArray.map(url => ({ type: "file", url, name: '', mime: '' }));
        return links.length ? links : null;
      },
      getUsers(rowDataArray) {
        const users = rowDataArray.map((email) => {
          if (this.companyUsersByEmail.has(email)) return _.pick(this.companyUsersByEmail.get(email), ['id', 'email', 'firstname', 'lastname', 'avatar']);
          return { username: email };
        });
        return users.length ? users : null;
      },
      // getBudgets(rowDataArray) {
      //   const budgets = rowDataArray.map((budgetStr) => {
      //     const regex = /\/| /g; // split e.g: 50/500 gbp (quotation) => ['50', '500', 'gbp', '(quotation)']
      //     const budgetStrArray = budgetStr.split(regex).filter(item => item);
      //     const state = budgetStrArray.find(item => item?.startsWith('(')) || '';
      //     const stateIndex = budgetStrArray.indexOf(state);
      //     if (stateIndex > -1) budgetStrArray.splice(stateIndex, 1);
      //     const icon = budgetStrArray.find(item => this.budgetIcons.includes(item)) || '';
      //     const iconIndex = budgetStrArray.indexOf(icon);
      //     if (iconIndex > -1) budgetStrArray.splice(iconIndex, 1);

      //     let amountInprogress = null;
      //     let amount = null;
      //     if (budgetStrArray.length == 2) {
      //       amountInprogress = parseInt(budgetStrArray[0], 10);
      //       amount = parseInt(budgetStrArray[1], 10);
      //     } else if (budgetStrArray.length == 1) { // It's amount or amount_inprogress
      //       if (budgetStr.endsWith('/')) { // It's amount_inprogress
      //         amountInprogress = parseInt(budgetStrArray[0], 10);
      //       } else {
      //         amount = parseInt(budgetStrArray[0], 10);
      //       }
      //     }
      //     return { ...getDefaultBudgetItem(), amount_inprogress: amountInprogress, amount, state: state.replace('(', '').replace(')', ''), icon };
      //   });
      //   return budgets.length ? budgets : null;
      // },
      // getDependencies(rowData, header) {
      //   const dependencies = [];
      //   const msProjectDependencies = rowData.split(/,|;/g).filter(item => item); // split e.g: [2FF,1FS+4days] => ['2FF', '1FS+4days']
      //   msProjectDependencies.forEach((dependencyString) => {
      //     const dependencyArray = dependencyString.split(/\+|-/g); // split e.g: [1FS+4days] => ['1FS', '4days']
      //     const id = parseInt(dependencyArray[0], 10);
      //     if (id) {
      //       const delay = dependencyArray[1] ? parseInt(dependencyArray[1], 10) : 0;
      //       const linkType = dependencyArray[0].replace(/[0-9]/g, ''); // FS (finish-to-start), FF (finish-to-finish), SS (start-to-start), and SF (start-to-finish)
      //       dependencies.push({
      //         target_id: id,
      //         successor: (header.name == 'Successors'),
      //         delay: dependencyString.includes('-') ? -delay : delay,
      //       });
      //     }
      //   });
      //   return dependencies.length ? dependencies : null;
      // },
      getScheduleElements(datetime) {
        const separators = [';', ':', 'h', 'H'];

        const separatorsRegExp = new RegExp(`[${separators.join('')}]`);
        const elements = datetime.split(separatorsRegExp);

        return elements;
      },
      getDataDest() {
        const keysMapping = new Map(this.csvHeaders.map(item => [item.mapping, item.id]));

        const startHour = moment(this.dataDest[keysMapping.get('schedulestart')]).hours();
        const startHourMinute = moment(this.dataDest[keysMapping.get('schedulestart')]).minutes();
        const endHour = moment(this.dataDest[keysMapping.get('scheduleend')]).hours();
        const endHourMinute = moment(this.dataDest[keysMapping.get('scheduleend')]).minutes();

        if (this.dataDest[keysMapping.get('starttime')] && startHour && startHourMinute) {
          this.dataDest[keysMapping.get('starttime')] = moment(this.dataDest[keysMapping.get('starttime')]).set('hour', startHour).set('minute', startHourMinute).set('second', 0).format();
        }
        if (this.dataDest[keysMapping.get('endtime')] && endHour && endHourMinute) {
          this.dataDest[keysMapping.get('endtime')] = moment(this.dataDest[keysMapping.get('endtime')]).set('hour', endHour).set('minute', endHourMinute).set('second', 0).format();
        }

        return this.csvHeaders.reduce((acc, header) => {
          if (header.mapping === 'schedulestart' || header.mapping === 'scheduleend') {
            if (this.dataDest[header.id]) {
              if (! acc.schedule) acc.schedule = {};
              acc.schedule[header.mapping.replace('schedule', '')] = this.dataDest[header.id];
            }
          } else if (header.mapping) {
            acc[header.mapping] = this.dataDest[header.id];
          }
          return acc;
        }, {});
      },
    },
  };
</script>
