export default {
  computed: {
    macroElements() {
      // get all descendants tasks
      return this.getSubTasksRecursive(this.el, new Set()) || [];
    },
    macroStartTime() {
      if (! this.el.isType('macro')) return null;
      if (! this.macroElements.length) return this.el.getStartTime(); // macro has no subtasks
      const subTasksStartTime = this.macroElements.map(subTask => subTask.getStartTime()).filter(item => item);
      return moment.min(subTasksStartTime);
    },
    macroEndTime() {
      if (! this.el.isType('macro')) return null;
      if (! this.macroElements.length) return this.el.getEndTime();
      const subTasksEndTime = this.macroElements.map(subTask => subTask.getEndTime()).filter(item => item);
      return moment.max(subTasksEndTime);
    },
    macroProgress() {
      if (! this.el.isType('macro')) return null;
      let sum = 0;
      let count = 0;
      this.macroElements.forEach((subTask) => {
        if (! subTask.isType('macro', 'task')) return;
        sum += subTask.getProgress();
        count++;
      });
      return count ? Math.round(sum / count) : 0; // 0 is default value
    },
    macroUsers() {
      if (! this.el.isType('macro')) return null;
      if (! this.macroElements.length) return null;
      const subTasksUsers = this.macroElements.map(subTask => subTask.getUsers());
      const subTasksAllUsers = [].concat(...subTasksUsers.filter(users => users && users.length));
      // Users
      const users = subTasksAllUsers.filter(user => user.id);
      const uniqueUsers = {};
      const usersOccurence = {};
      users.forEach((user) => {
        if (! uniqueUsers[user.id]) {
          uniqueUsers[user.id] = angular.copy(user);
          usersOccurence[user.id] = 0;
        }
        usersOccurence[user.id] += 1;
      });
      // GroupUsers
      const groupUsers = subTasksAllUsers.filter(user => user.group_id);
      const uniqueGroupUsers = {};
      const groupUsersOccurence = {};
      groupUsers.forEach((user) => {
        if (! uniqueGroupUsers[user.group_id]) {
          uniqueGroupUsers[user.group_id] = angular.copy(user);
          groupUsersOccurence[user.group_id] = 0;
        }
        groupUsersOccurence[user.group_id] += 1;
      });
      // FreeUsers
      const freeUsers = subTasksAllUsers.filter(user => user.username);
      const uniqueFreeUsers = {};
      const freeUsersOccurence = {};
      freeUsers.forEach((user) => {
        if (! uniqueFreeUsers[user.username]) {
          uniqueFreeUsers[user.username] = angular.copy(user);
          freeUsersOccurence[user.username] = 0;
        }
        freeUsersOccurence[user.username] += 1;
      });

      const uniqueUsersVals = Object.values(uniqueUsers);
      const uniqueGroupUsersVals = Object.values(uniqueGroupUsers);
      const uniqueFreeUsersVals = Object.values(uniqueFreeUsers);
      const allUniqueUsers = uniqueUsersVals.concat(uniqueGroupUsersVals).concat(uniqueFreeUsersVals);
      return allUniqueUsers;
    },
    macroLinks() {
      if (! this.el.isType('macro')) return null;
      if (! this.macroElements.length) return null;
      const subTasksLinks = this.macroElements.map(subTask => subTask.getLinks());
      const subTasksAllLinks = [].concat(...subTasksLinks.filter(links => links && links.length));
      const linksName = subTasksAllLinks.map(link => link.name);
      const uniqueLinks = subTasksAllLinks.filter(({ name }, index) => ! linksName.includes(name, index + 1));
      return uniqueLinks;
    },
    macroBudgets() {
      if (! this.el.isType('macro')) return null;
      if (! this.macroElements.length) return null;
      const subTasksBudgets = this.macroElements.map(subTask => subTask.getBudgets());
      const subTasksAllBudgets = [].concat(...subTasksBudgets.filter(budgets => budgets && budgets.length));
      return subTasksAllBudgets;
    },
  },
  watch: {
    macroStartTime: {
      handler() {
        if (! this.el.isType('macro')) return;
        this.el.setStartTime(this.macroStartTime);
        this.el.update();
      },
    },
    macroEndTime: {
      handler() {
        if (! this.el.isType('macro')) return;
        this.el.setEndTime(this.macroEndTime);
        this.el.update();
      },
    },
    macroProgress: {
      handler() {
        if (! this.el.isType('macro')) return;
        this.el.setProgress(this.macroProgress);
        this.el.update();
      },
      immediate: true,
    },
    macroUsers: {
      handler() {
        if (! this.el.isType('macro')) return;
        this.el.setUsers(this.macroUsers);
        this.el.update();
      },
      immediate: true,
    },
    macroLinks: {
      handler() {
        if (! this.el.isType('macro')) return;
        this.el.setLinks(this.macroLinks);
        this.el.update();
      },
      immediate: true,
    },
    macroBudgets: {
      handler() {
        if (! this.el.isType('macro')) return;
        this.el.setBudgets(this.macroBudgets);
        this.el.update();
      },
      immediate: true,
    },
  },
  methods: {
    getSubTasksRecursive(el, macroSubEls) {
      if (macroSubEls.has(el.id)) return null; // browse node once
      macroSubEls.add(el.id);

      const subEls = [];
      const subTasksId = new Set(el.getSubTasks());
      const subTasks = this.planning.elements.filter(item => subTasksId.has(parseInt(item.id, 10)));
      subTasks.forEach((subTask) => {
        if (macroSubEls.has(subTask.id)) return; // browse node once

        if (! subTask.getSubTasks()) {
          macroSubEls.add(subTask.id);
          subEls.push(subTask);
        } else {
          subEls.push(...this.getSubTasksRecursive(subTask, macroSubEls));
        }
      });
      return subEls;
    },
  },
};
