<template>
  <div>
    <div v-if="isMiniElement">
      <i class="fa fa-paperclip fa-lg"></i> {{ elLinks.length }}
    </div>
    <template v-else>
      <span v-for="link in elLinks" class="mr-1">
        <span class="text-ellipsis mr-1" style="display: inline-block; max-width: min(100%, 120px)">
          <a v-if="link.url" :href="subdomainLinkUrl(link.url)" :title="link.name" target="_blank" rel="noopener" @click.stop>
            <link-icon :link="link" class="mr-.5"></link-icon>
            {{ link.name || link.url }}
          </a>

          <v-menu v-if="isPicture(link)" content-class="ml-2 rounded-sm" open-on-hover location="right" offset="8" transition="scale-transition" activator="parent">
            <div class="bg-white" style="max-width: 200px">
              <img v-if="link.url" :src="link.url" :alt="$t('PLANNING.LINK_IMAGE_UNAVAILABLE')" style="width: 100%;">
            </div>
          </v-menu>
        </span>
        <span v-if="! isLast(link, elLinks)"></span>
      </span>
    </template>
  </div>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import LinkIcon from '@/components/Planning/partials/LinkIcon.vue';

  export default {
    components: {
      LinkIcon,
    },
    props: {
      el: { type: Object, required: true },
      isMiniElement: { type: Boolean, default: false },
    },
    computed: {
      elLinks() {
        return this.el.getLinks();
      },
    },
    methods: {
      isPicture(link) {
        if (link.mime && link.mime.includes('image')) return true;
        return /(http)s?:(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg)$)/i.test(link.url);
      },
      subdomainLinkUrl(url) {
        return clientConfig.subdomain && url?.startsWith('https://api.bubbleplan.net') ? `${url}?subdomain=${clientConfig.subdomain}` : url;
      },
      isLast(item, tab) {
        return item == tab[tab.length - 1];
      },
    },
  };
</script>
