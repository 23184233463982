<template>
  <div class="d-flex align-center flex-grow-1">
    <btn-toggle-filter v-model="portfolioOptions.portfolioView" mandatory="force" class="mr-3">
      <v-btn value="table-view" :title="$t('PORTFOLIO.TABLE_VIEW')">
        <v-icon>far fa-table</v-icon>
      </v-btn>
      <v-btn value="planning-view" :title="$t('PORTFOLIO.PLANNING_VIEW')">
        <v-icon>svgicon-planning</v-icon>
      </v-btn>
    </btn-toggle-filter>

    <select-filter v-model="selectedColsList" :items="headers" item-value="key" :label="portfolioOptions.portfolioView == 'table-view' ? $t('MULTIPROJECTS.COLUMNS') : $t('MULTIPROJECTS.DISPLAY')"
                   multiple :menu-props="{ location: 'bottom' }">
      <template v-if="portfolioOptions.portfolioView == 'planning-view'" #prepend-item>
        <v-switch v-model="portfolioOptions.groupByCategory" class="mx-4" :label="$t('MULTIPROJECTS.GROUP_BY_CATEGORY')"></v-switch>
        <v-divider></v-divider>
      </template>
      <template #selection="{ index }">
        <span v-if="index === 0">{{ selectedColsList.length }}/{{ headers.length }}</span>
      </template>
    </select-filter>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      headers: { type: Array, default: () => [] },
    },
    computed: {
      selectedColsList: {
        get() {
          return this.headers.filter(col => this.portfolioOptions.selectedCols[col.key]);
        },
        set(newVal) {
          this.headers.forEach((col) => {
            this.portfolioOptions.selectedCols[col.key] = newVal.includes(col.key);
          });
        },
      },
      ...mapState('multiprojects', ['portfolioOptions']),
    },
    watch: {
      headers: {
        handler() {
          this.headers.forEach((col) => {
            if (! Object.prototype.hasOwnProperty.call(this.portfolioOptions.selectedCols, col.key)) {
              this.portfolioOptions.selectedCols[col.key] = true;
            }
          });
        },
        immediate: true,
      },
    },
  };
</script>
