<template>
  <v-dialog v-if="openedElement" :model-value="!! openedElement" width="462" transition="slide-x-reverse-transition"
            content-class="dashboards-element-modal-position ma-0" class="dashboards-element-modal" @update:model-value="! $event && closeElementDetails()">
    <v-card class="h-100 rounded-e-0 pb-1 d-flex flex-column">
      <div id="planning-drawer-title" :class="titleColorClass" class="rounded-t-s flex-center-center">
        <div style="position: absolute; right: 4px; top: 4px; bottom: 0; z-index: 1" class="flex-center-center">
          <v-btn-icon size="small" @click="closeElementDetails"><v-icon size="large">far fa-times</v-icon></v-btn-icon>
        </div>
      </div>
      <div id="planning-drawer-scroll-container-parent" class="flex-grow-1 overflow-hidden fill-height">
        <div id="planning-drawer-scroll-container" class="fill-height overflow-y-auto light-scrollbar overscroll-behavior-y-contain">
          <v-fade-transition>
            <element-details v-if="mountElementDetails" :element="openedElement" :options="{ breadcrumb: true }" @close="closeElementDetails($event)"></element-details>
          </v-fade-transition>
        </div>
      </div>
    </v-card>
  </v-dialog>

  <v-dialog v-if="openedColorPickerElement" :model-value="!! openedColorPickerElement" width="462" transition="slide-x-reverse-transition"
            content-class="dashboards-element-modal-position ma-0" class="dashboards-element-modal" @update:model-value="! $event && closeColorPicker()">
    <v-card class="h-100 rounded-e-0 pb-1 d-flex flex-column">
      <div id="planning-drawer-title" :class="titleColorClass" class="rounded-t-s flex-center-center">
        <div style="position: absolute; right: 4px; top: 4px; bottom: 0; z-index: 1" class="flex-center-center">
          <v-btn-icon size="small" @click="closeColorPicker"><v-icon size="large">far fa-times</v-icon></v-btn-icon>
        </div>
      </div>
      <div id="planning-drawer-scroll-container-parent" class="flex-grow-1 overflow-hidden fill-height">
        <div id="planning-drawer-scroll-container" class="fill-height overflow-y-auto light-scrollbar overscroll-behavior-y-contain">
          <v-fade-transition>
            <color-picker v-if="mountColorPicker" :target="openedColorPickerElement" :planning="openedColorPickerElement.getPlanning()" disable-renaming @close="closeColorPicker"></color-picker>
          </v-fade-transition>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
  .dashboards-element-modal-position {
    bottom: 0;
    right: 0;
    height: 100%;
  }
</style>

<script>
  import { mapState, mapActions } from 'vuex';
  import ElementDetails from '@/components/ElementDetails/ElementDetails';
  import ColorPicker from '@/components/ColorPicker/ColorPicker';

  export default {
    components: {
      ElementDetails,
      ColorPicker,
    },
    data() {
      return {
        mountElementDetails: false,
        mountColorPicker: false,
      };
    },
    computed: {
      titleColorClass() {
        const el = this.openedElement || this.openedColorPickerElement;
        if (! el || ! el.isType) return 'bg-secondary';
        if (el.isType('milestone')) return 'bg-lightgrey';
        return `${el.getColorClass()} bg-el-color`;
      },
      ...mapState('ui/dashboards', ['openedElement', 'openedColorPickerElement']),
    },
    watch: {
      openedElement(newVal) {
        if (! newVal) {
          this.mountElementDetails = false;
          return;
        }
        setTimeout(() => {
          // wait until parent with #planning-drawer-title is mounted before using teleport in subcomponent
          this.mountElementDetails = true;
        }, 0);
      },
      openedColorPickerElement(newVal) {
        if (! newVal) {
          this.mountColorPicker = false;
          return;
        }
        setTimeout(() => {
          // wait until parent with #planning-drawer-title is mounted before using teleport in subcomponent
          this.mountColorPicker = true;
        }, 0);
      },
    },
    created() {
      this.$store.commit('ui/dashboards/reset');
    },
    methods: {
      ...mapActions('ui/dashboards', ['closeElementDetails', 'closeColorPicker']),
    },
  };
</script>
