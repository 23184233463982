import navigation from './navigation';
import planning from './planning';
import subplanning from './subplanning';
import workingview from './workingview';
import dashboards from './dashboards';
import msgbox from './msgbox';
import saving from './saving';

export default {
  namespaced: true,
  modules: {
    navigation,
    planning,
    subplanning,
    workingview,
    dashboards,
    msgbox,
    saving,
  },
  state: {
    mobileview: null,
    fullscreenMode: false,
    displayLoginWindow: false,
    displayOnBoardingWelcomeModal: false,
    displayPremiumSuspended: false,
    displayPremiumExpired: false,
    displayNewVersionsWindow: false,
  },
  mutations: {
    setLoginWindow(state, status) {
      state.displayLoginWindow = status;
    },
    setOnBoardingWelcomeModal(state, status) {
      state.displayOnBoardingWelcomeModal = status;
    },
    setPremiumSuspended(state, status) {
      state.displayPremiumSuspended = status;
    },
    setPremiumExpired(state, status) {
      state.displayPremiumExpired = status;
    },
    setDisplayNewVersionsWindow(state, value) {
      state.displayNewVersionsWindow = value;
    },
  },
};
