<template>
  <exchange-center v-if="isPremium" :options="{ planning_id: planning.id, element_id: el.id, search: false, files: false }"></exchange-center>
  <div v-if="! isPremium">
    {{ $t('PREMIUM.COMMENTS_IS_PREMIUM') }}<br>
    <a :href="$t('PREMIUM.OFFERS_LINK')" target="_blank" rel="noopener">{{ $t('PREMIUM.GO_PREMIUM') }}</a>
  </div>
</template>

<script>
  import ExchangeCenter from '@/components/ExchangeCenter/ExchangeCenter';

  export default {
    components: {
      ExchangeCenter,
    },
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        planning: this.el.getPlanning(),
      };
    },
    computed: {
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
  };
</script>
