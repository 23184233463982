<template>
  <div v-if="isAdmin" style="max-width: 700px">
    <v-alert v-if="! isBusiness" color="warningorange" type="warning">
      {{ $t('PREMIUM.BUSINESS.SECTION_IS_BUSINESS') }} &nbsp;
    </v-alert>

    <v-window v-else v-model="manageTabIndex" :mandatory="false" :touch="false">
      <!-- TAB PROJECT SETTINGS -->
      <v-window-item :transition="false" :reverse-transition="false" value="config-projectsettings">
        <manage-config-project-settings></manage-config-project-settings>
      </v-window-item>

      <!-- TAB FEATURES -->
      <v-window-item :transition="false" :reverse-transition="false" value="config-integrations">
        <manage-config-integrations></manage-config-integrations>
      </v-window-item>

      <!-- TAB TOOLS -->
      <v-window-item :transition="false" :reverse-transition="false" value="config-tools">
        <div class="pb-6" style="position: relative">
          <manage-config-tools-replace-users></manage-config-tools-replace-users>
          <div class="pt-6"></div>
          <manage-config-tools-split-planning></manage-config-tools-split-planning>
        </div>
      </v-window-item>
    </v-window>
  </div>
  <v-alert v-else color="warningorange" type="warning">
    {{ $t('PREMIUM.ONLY_FOR_ADMIN') }} &nbsp;
  </v-alert>
</template>

<script>
  import ManageConfigProjectSettings from './ManageConfigProjectSettings.vue';
  import ManageConfigIntegrations from './ManageConfigIntegrations.vue';
  import ManageConfigToolsReplaceUsers from './ManageConfigToolsReplaceUsers.vue';
  import ManageConfigToolsSplitPlanning from './ManageConfigToolsSplitPlanning.vue';

  export default {
    components: {
      ManageConfigProjectSettings,
      ManageConfigIntegrations,
      ManageConfigToolsReplaceUsers,
      ManageConfigToolsSplitPlanning,
    },
    computed: {
      manageTabIndex: {
        get() {
          return this.$store.state.manage.manageTabIndex;
        },
        set(newVal) {
          this.$store.state.manage.manageTabIndex = newVal;
        },
      },
      isAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
  };
</script>
