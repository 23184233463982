<template>
  <div v-if="! userLoaded && ! getMeError" class="text-center pa-4"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
  <div v-if="! userLoaded && getMeError" class="bg-white pa-4">{{ $t('GLOBAL.ERROR') }} : {{ getMeError }}</div>
  <v-fade-transition>
    <div v-if="userLoaded" class="pa-4">
      <v-alert v-if="! isPremium" color="warningorange" type="warning" class="my-4">
        {{ $t('PREMIUM.DASHBOARDS_IS_PREMIUM') }} &nbsp;
        <v-btn color="successgreen" class="text-uppercase" @click="openPremium()">{{ $t('PREMIUM.SUBSCRIBE_TO_OFFER') }}</v-btn>
      </v-alert>

      <!-- Custom dashboards -->
      <v-row v-if="customDashboards">
        <v-col v-for="dashboard in customDashboards" :key="dashboard.to" xl="3" lg="4" md="6" sm="6" cols="12" class="d-flex flex-column">
          <v-card-clickable :to="dashboard.to" rounded class="mt-2 flex-grow-1">
            <div class="bg-lightgrey px-4 py-2"><b>{{ dashboard.title }}</b></div>
            <div class="pa-2"><img :src="dashboard.img" style="width: 100%"></div>
          </v-card-clickable>
        </v-col>
      </v-row>

      <!-- Premium dashboards -->
      <v-row>
        <v-col v-for="dashboard in dashboards" :key="dashboard.path" xl="3" lg="4" md="6" sm="6" cols="12" class="d-flex flex-column position-relative">
          <v-card-clickable :disabled="! isPremium || dashboard.business && ! isBusiness" :to="dashboard.path" rounded class="mt-2 flex-grow-1 d-flex flex-column">
            <div class="bg-lightgrey px-4 py-2"><b>{{ dashboard.title }}</b></div>
            <div class="pa-2"><img :src="dashboard.img" style="width: 100%"></div>
            <div class="px-4 py-2 flex-grow-1 d-flex align-center"><div v-html="dashboard.description"></div></div>
          </v-card-clickable>
          <v-alert v-if="isPremium && dashboard.business && ! isBusiness" color="warningorange" type="warning" style="position: absolute; top: 52px; left: 0; right: 0">
            {{ $t('PREMIUM.BUSINESS.DASHBOARD_IS_BUSINESS') }} &nbsp;
            <account-link-to-website section="premium" class="mt-2">{{ $t('PREMIUM.MANAGE_MY_OFFER') }}</account-link-to-website>
          </v-alert>
        </v-col>
      </v-row>

      <!-- Home user dashboard -->
      <v-row>
        <v-col :key="homeUserDashboard.path" xl="3" lg="4" md="6" sm="6" cols="12" class="d-flex flex-column">
          <v-card-clickable :to="homeUserDashboard.path" rounded class="flex-grow-1 d-flex flex-column">
            <div class="bg-lightgrey px-4 py-2"><b>{{ homeUserDashboard.title }}</b></div>
            <div class="pa-2"><img :src="homeUserDashboard.img" style="width: 100%"></div>
            <div class="px-4 py-2 flex-grow-1 d-flex align-center"><div v-html="homeUserDashboard.description"></div></div>
          </v-card-clickable>
        </v-col>
      </v-row>
    </div>
  </v-fade-transition>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import AccountLinkToWebsite from '@/components/Account/AccountLinkToWebsite';
  import imgMonitoringProgress from '@/img/dashboards/monitoringprogress.png';
  import imgPlanningUsers from '@/img/dashboards/planningusers.png';
  import imgMonitoringActions from '@/img/dashboards/monitoringactions.png';
  import imgPortfolio from '@/img/dashboards/portfolio.png';
  import imgPersonalCalendar from '@/img/dashboards/personalcalendar.png';
  import imgMonitoringPlan from '@/img/dashboards/monitoringplan.png';
  import imgHomeUser from '@/img/dashboards/homeuser.png';

  export default {
    components: {
      AccountLinkToWebsite,
    },
    data() {
      return {
        loadingError: '',
        customDashboards: clientConfig.dashboards?.list,
      };
    },
    computed: {
      dashboards() {
        const dashboards = [
          { path: '/analysis/progress', title: this.$t('MONITORING_PROGRESS.TITLE'), img: imgMonitoringProgress, description: this.$t('MONITORING_PROGRESS.DESCRIPTION') },
          { path: '/analysis/planningusers', title: this.$t('PLANNINGUSERS.USERS_PLANNING'), img: imgPlanningUsers, description: this.$t('PLANNINGUSERS.USERS_PLANNING_DESCRIPTION_TEXT') },
          { path: '/analysis/actions', title: this.$t('MONITORING_ACTIONS.TITLE'), img: imgMonitoringActions, description: this.$t('MONITORING_ACTIONS.DESCRIPTION') },
          { path: '/analysis/portfolio', title: this.$t('PORTFOLIO.TITLE'), img: imgPortfolio, description: this.$t('PORTFOLIO.DESCRIPTION') },
          { path: '/analysis/personalcalendar', business: true, title: this.$t('PERSONAL_CALENDAR.TITLE'), img: imgPersonalCalendar, description: this.$t('PERSONAL_CALENDAR.DESCRIPTION') },
        ];
        if (this.hasMonitoringPlan) {
          dashboards.push({ path: '/dashboards/monitoringplan', title: this.$t('MONITORING_PLAN.TITLE'), img: imgMonitoringPlan, description: this.$t('MONITORING_PLAN.DESCRIPTION')});
        }
        return dashboards;
      },
      homeUserDashboard() {
        return { path: '/home', title: this.$t('HOME.PERSONAL_DASHBOARD'), img: imgHomeUser, description: this.$t('HOME.PERSONAL_DASHBOARD_DESCRIPTION_TEXT') };
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      hasMonitoringPlan() { return this.$store.state.users.accessRight.hasMonitoringPlan; },
      userLoaded() { return this.$store.state.users.user.id > 0; },
      getMeError() { return this.$store.state.users.getMeError; },
    },
    methods: {
      openPremium() {
        this.$store.commit('ui/navigation/setOpenedModal', 'premium-window');
      },
    },
  };
</script>
