<template>
  <form @submit.prevent="submitRegister">
    <div class="d-flex column-gap-4">
      <v-text-field id="firstnameField" v-model="firstname" :placeholder="$t('GLOBAL.FIRSTNAME')" prepend-inner-icon="far fa-user" autofocus required style="flex: 1 1 50%">
      </v-text-field>
      <v-text-field id="lastnameField" v-model="lastname" :placeholder="$t('GLOBAL.LASTNAME')" required style="flex: 1 1 50%">
      </v-text-field>
    </div>
    <v-text-field id="emailField" v-model="email" :placeholder="$t('GLOBAL.EMAIL')" prepend-inner-icon="far fa-at" autocomplete="email" required class="mt-2">
    </v-text-field>
    <v-text-field id="phoneField" v-model="phone" :placeholder="$t('LOGIN.PHONE')" prepend-inner-icon="far fa-phone" required class="mt-2">
    </v-text-field>
    <input v-model="lang" type="hidden">
    <v-text-field id="passwordField" v-model="password" :placeholder="$t('GLOBAL.PASSWORD')" type="password" prepend-inner-icon="far fa-lock" required class="mt-2">
    </v-text-field>
    <v-text-field id="passwordConfirmationField" v-model="passwordConfirmation" :placeholder="$t('LOGIN.PASSWORD_CONFIRM')" type="password" prepend-inner-icon="far fa-lock" required class="mt-2">
    </v-text-field>
    <v-checkbox required class="my-2">
      <template #label>
        <i18n-t keypath="LOGIN.ACCEPT_CGV" tag="span">
          <a href="https://bubbleplan.net/cgv" target="_blank" rel="noopener" style="text-decoration:underline" tabindex="-1" @click.stop>{{ $t('LOGIN.CGV') }}</a>
        </i18n-t>
      </template>
    </v-checkbox>

    <v-alert :model-value="!! error" type="error" class="mb-2">{{ error }}</v-alert>
    <div class="text-center mt-6">
      <v-btn :disabled="isSubmittingRegister" :loading="isSubmittingRegister" color="primary" rounded size="large" type="submit" class="px-12">
        {{ $t('LOGIN.REGISTER') }}
      </v-btn>
    </div>
  </form>
  <div class="mt-12"></div>
  <div class="mt-auto text-center">
    <v-btn variant="text" @click.prevent="$emit('navigate-section', 'login')">{{ $t('LOGIN.ALREADY_HAVE_ACCOUNT') }}</v-btn>
  </div>
</template>

<script>
  export default {
    emits: ['navigate-section'],
    data() {
      return {
        firstname: "",
        lastname: "",
        email: this.$route.query.registeremail,
        phone: "",
        password: "",
        passwordConfirmation: "",
        error: "",
        isSubmittingRegister: false,
      };
    },
    computed: {
      lang() {
        return this.$store.state.lang.lang;
      },
    },
    methods: {
      submitRegister() {
        this.error = "";
        if (! window.isValidEmailAddress(this.email)) {
          this.error = this.$t('LOGIN.ERROR_INVALID_EMAIL');
          return;
        }
        if (this.password !== this.passwordConfirmation) {
          this.error = this.$t('LOGIN.ERROR_PASSWORD_MATCH');
          return;
        }

        this.isSubmittingRegister = true;
        window.apiSrv.call('users', 'store', {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
          lang: this.lang,
          password: this.password,
        }).then((response) => {
          const token = response && response.data && response.data.user && response.data.user.remember_token;
          window.apiSrv.setAuthToken(token);
          setTimeout(() => { window.location.href = ""; });
        }).catch((error) => {
          if (typeof error == 'object') {
            this.error = Object.keys(error).map((key) => {
              const messages = error[key];
              if (Array.isArray(messages)) return `- ${messages.join('\n- ')}`;
              return messages;
            }).join("\n");
          } else {
            this.error = error;
          }
          this.isSubmittingRegister = false;
        });
      },
    },
  };
</script>
