<template>
  <div v-if="groupsLoaded">
    <div class="d-flex" style="position: relative">
      <div>
        <v-card class="mb-6">
          <div class="table-responsive">
            <table v-if="companyGroups.length" class="table">
              <thead class="bg-lightgrey">
                <tr>
                  <th v-if="hasAGroupToAdmin"></th>
                  <th>{{ $t('MANAGE.GROUP') }}</th>
                  <th v-if="hasSpecialName">{{ $t('MANAGE.GROUP_SPECIAL_NAME') }}</th>
                  <th class="text-center">{{ $t('MANAGE.MEMBERS') }}</th>
                  <th v-if="hasAGroupToAdmin" class="text-center">{{ $t('MANAGE.DELETE') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="group in sortedCompanyGroups" :key="group.id">
                  <td v-if="hasAGroupToAdmin" class="text-center">
                    <i class="far fa-pencil-alt access-right-icon" :title="$t('MANAGE.RENAME_GROUP')" @click="editGroupTitle(group)"></i>
                  </td>
                  <td class="pointer" @click="openTheGroup(group)">{{ group.title }}</td>
                  <td v-if="hasSpecialName" class="pointer" @click="openTheGroup(group)">{{ group.special_name }}</td>
                  <td class="text-center pointer" @click="openTheGroup(group)">{{ group.users.length }}</td>
                  <td v-if="hasAGroupToAdmin" class="text-center">
                    <i v-if="canIAdminCompany[group.company_id]" :title="$t('MANAGE.DELETE')" class="far fa-trash-alt access-right-icon" @click="deleteGroup(group)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pa-4 d-flex align-center">
              <v-text-field v-model="newGroupName" :label="$t('MANAGE.GROUP_NAME')" style="min-width: 200px"></v-text-field>
              <v-btn :disabled="! newGroupName" color="primary" rounded size="large" class="mx-2" @click="createGroup(newGroupName); newGroupName = ''">
                {{ $t('MANAGE.CREATE_GROUP') }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>

      <v-card v-if="openedGroup" class="ml-10 pa-6 mb-6" style="position: relative; flex-grow: 1">
        <button type="button" class="close" style="font-size: 2.2em;top: 0;right: 12px;position: absolute;" @click="openedGroup = null">&times;</button>
        <b v-if="openedGroup">{{ $t('MANAGE.GROUP') }} : {{ openedGroup.title }}</b>
        <div v-if="openedGroup" class="small text-medium-emphasis">{{ $t('MANAGE.ACCOUNT') }} {{ openedGroup.getCompanyName() }}</div>
        <div class="table-responsive mt-2">
          <table v-if="openedGroup && openedGroup.users.length" class="table table-striped" style="width:100%">
            <tbody>
              <tr v-for="user in openedGroup.users" :key="user.id">
                <td :class="{ pointer: openable_user = canIOpenUser(user) }" style="width: 1px" @click="openUser(user)"><img :src="user.avatar" style="height:2em; border-radius: 50%;"></td>
                <td :class="{ 'strong pointer': openable_user }" @click="openUser(user)">{{ $filters.username(user) }}</td>
                <td v-if="canIAdminCompany[openedGroup.company_id]" class="text-center">
                  <i :title="$t('MANAGE.REMOVE')" class="fa fa-fw fa-user-times access-right-icon" @click="removeMemberForGroup(user.id)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="canIAdminCompany[openedGroup.company_id]" class="mt-4 d-flex align-center">
          <select-user v-model="userToAddInGroup.user" :excludes="openedGroupUserIds" :placeholder="$t('MANAGE.CHOOSE_NEW_USER_GROUP')"></select-user>
          <v-btn :disabled="! (userToAddInGroup.user && userToAddInGroup.user.id) || userToAddInGroup.sending" color="primary" rounded size="large" class="mx-2"
                 @click="addUserInGroup()">
            {{ $t('MANAGE.ADD_MEMBER_IN_GROUP') }}
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        newGroupName: '',
        userToAddInGroup: { user: null, sending: false },
      };
    },
    computed: {
      openedGroup: {
        get() {
          return this.$store.state.manage.openedGroup;
        },
        set(newVal) {
          this.$store.state.manage.openedGroup = newVal;
        },
      },
      groupsLoaded() {
        return this.$store.state.users.groups.loaded;
      },
      companyGroups() {
        return this.$store.state.users.groups.companyGroups;
      },
      sortedCompanyGroups() {
        return _.sortBy(this.companyGroups, 'title');
      },
      openedGroupUserIds() {
        return this.openedGroup && this.openedGroup.users.map(user => user.id) || [];
      },
      hasSpecialName() {
        return this.companyGroups.some(item => item.special_name);
      },
      canIOpenUser() {
        return this.$store.getters['manage/canIOpenUser'];
      },
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      organizationCompanies() { return this.$store.state.users.user.organization && this.$store.state.users.user.organization.getCompanies(); },
      canIAdminCompany() {
        return (this.organizationCompanies || [this.$store.state.users.user.company]).reduce((acc, company) => {
          acc[company.id] = this.userIsAdmin && !! this.$store.getters['users/getOrganizationSubCompanies'].find(item => item.id == company.id);
          return acc;
        }, {});
      },
      hasAGroupToAdmin() {
        return this.companyGroups.some(item => this.canIAdminCompany[item.company_id]);
      },
    },
    methods: {
      createGroup(title) {
        if (! title) return;
        this.$store.dispatch('users/groups/createGroup', title);
      },
      deleteGroup(group) {
        if (this.openedGroup && this.openedGroup.id == group.id) this.openedGroup = null;
        this.$store.dispatch('users/groups/deleteGroup', group.id).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Group was not deleted", body: message || "" });
        });
      },
      editGroupTitle(group) {
        const newTitle = prompt(this.$t('MANAGE.RENAME_GROUP'), group.title);
        if (newTitle === null || newTitle === group.title) return;
        this.$store.dispatch('users/groups/updateGroup', { groupId: group.id, title: newTitle }).catch((message) => {
          this.$store.dispatch('ui/msgbox/open', { title: "Error : Group title could not be edited", body: message || "" });
        });
      },
      openTheGroup(group) {
        this.openedGroup = this.openedGroup == group ? null : group;
      },
      removeMemberForGroup(userId) {
        this.openedGroup.removeUser({ id: userId }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : User was not removed", body: message || "" });
        });
      },
      addUserInGroup() {
        const { user } = this.userToAddInGroup;
        const group = this.openedGroup;
        this.userToAddInGroup.sending = true;
        group.addUser(user).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : User was not added", body: message || "" });
        }).then(() => {
          this.userToAddInGroup.sending = false;
        });
        this.userToAddInGroup = { user: null, sending: false };
      },
      openUser(user) {
        this.$store.dispatch('manage/openUserFromId', user.id);
      },
      filterUsername(users, search) {
        const key = search.toLowerCase();
        return users.filter(user => user.username.toLowerCase().indexOf(key) > -1);
      },
    },
  };
</script>
