<template>
  <b>{{ $t('INTEGRATIONS.CONNECT_TO_OTHER_APPLICATIONS') }}</b>
  <div class="mt-4">
    <v-card v-for="(integrationName, integrationIndex) in availableIntegrations" :key="integrationIndex" class="pa-6 mb-6">
      <div class="d-flex align-center">
        <img v-if="integrationName == 'microsoft'"
             :style="{
               filter: integrations[integrationName].state == 'connected' ? '' : 'grayscale(100%)',
               opacity: ['loading', 'logging'].indexOf(integrations[integrationName].state) > -1 ? .5 : 1,
             }"
             src="../../../img/integrations/outlook.png" style="width: 40px">
        <img v-else
             :style="{
               filter: integrations[integrationName].state == 'connected' ? '' : 'grayscale(100%)',
               opacity: ['loading', 'logging'].indexOf(integrations[integrationName].state) > -1 ? .5 : 1,
             }"
             src="../../../img/integrations/gcal.png" style="width: 40px">
        <b class="ml-2">{{ $t(`INTEGRATIONS.${integrationName == 'microsoft' ? 'MICROSOFT_OUTLOOK' : 'GOOGLE_CALENDAR'}`) }}</b>
      </div>
      <div class="mt-4 mb-2">
        <div v-if="integrations[integrationName].state == 'inactive'">
          <v-btn color="primary" rounded @click="loginIntegration(integrationName)">
            <v-icon class="mr-2">far fa-plus</v-icon> {{ $t(`INTEGRATIONS.CONNECT_${integrationName == 'microsoft' ? 'MICROSOFT' : 'GOOGLE'}`) }}
          </v-btn>
        </div>
        <div v-if="integrations[integrationName].state == 'loading'">
          <v-icon color="primary">far fa-spinner fa-spin fa-2x fa-fw</v-icon>
        </div>
        <div v-if="integrations[integrationName].state == 'logging'" class="d-flex align-center">
          <v-icon color="primary">far fa-spinner fa-spin fa-2x fa-fw</v-icon>
          <b class="mx-2">{{ $t('INTEGRATIONS.CONNECT_IN_NEW_WINDOW') }}</b> <a class="pointer" @click="loadIntegration(integrationName)">{{ $t('INTEGRATIONS.REFRESH') }}</a>
        </div>
        <div v-if="integrations[integrationName].state == 'connected'" class="d-flex align-center">
          <v-icon color="successgreen">fa fa-check</v-icon>
          <span class="ml-2">{{ $t('INTEGRATIONS.YOU_ARE_CONNECTED') }}</span>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn-icon size="small" class="ml-auto" v-bind="props" @click="logoutIntegration(integrationName)">
                <v-icon color="errorred">far fa-sign-out-alt</v-icon>
              </v-btn-icon>
            </template>
            {{ $t('INTEGRATIONS.DISCONNECT') }}
          </v-tooltip>
        </div>
        <div v-if="integrations[integrationName].state == 'error'" class="d-flex align-center">
          <v-icon color="errorred">far fa-exclamation-triangle</v-icon>
          <span class="ml-2" style="line-height: 1.2;">{{ integrations[integrationName].error }}</span>
        </div>
      </div>
      <div v-if="isConnected[integrationName]">
        <div class="mb-1"><b>{{ $t('GLOBAL.OPTIONS') }}</b></div>
        <div v-for="syncElementType in ['sync_milestones', 'sync_tasks']" class="d-flex align-center">
          <div :style="{ opacity: isLoading[integrationName] ? '.5' : '' }" class="pointer"
               @click="! isLoading[integrationName] && toggleInteractionOption(integrationName, syncElementType)">
            <i :class="integrationConfig[integrationName][syncElementType] ? 'fa-toggle-on text-successgreen' : 'fa-toggle-off'" class="far fa-lg"></i>
            <span class="ml-2" v-html="$t(`INTEGRATIONS.${syncElementType.toUpperCase()}`)"></span> <!-- INTEGRATIONS.SYNC_MILESTONES / INTEGRATIONS.SYNC_TASKS -->
          </div>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn-icon :style="{ visibility: integrationConfig[integrationName][syncElementType] ? 'visible' : 'hidden' }" size="x-small" class="ml-auto mr-1" v-bind="props"
                          @click="syncAllProjects = { isOpened: true, integrationName, syncElementType }">
                <v-icon>far fa-sync</v-icon>
              </v-btn-icon>
            </template>
            {{ $t('INTEGRATIONS.SYNC_ALL_PROJECTS') }}
          </v-tooltip>
        </div>
        <div class="mt-2">{{ $t('INTEGRATIONS.SYNC_DESCRIPTION') }}</div>
      </div>
      <div v-else class="pt-2">
        {{ $t(`INTEGRATIONS.${integrationName == 'microsoft' ? 'MICROSOFT_BENEFITS' : 'GOOGLE_BENEFITS'}`) }}
        <div v-if="! isPremium" class="mt-2 strong" v-html="$t('PREMIUM.INTEGRATIONS_IS_PREMIUM')"></div>
      </div>
    </v-card>
    <v-card class="pa-6">
      <web-calendar></web-calendar>
    </v-card>
    <div class="py-4"></div>
    <v-dialog v-model="syncAllProjects.isOpened" width="auto" persistent>
      <manage-profile-integrations-sync-projects v-if="syncAllProjects.isOpened" v-bind="syncAllProjects" @close="syncAllProjects.isOpened = false"></manage-profile-integrations-sync-projects>
    </v-dialog>
  </div>
</template>

<script>
  import WebCalendar from '@/components/Integrations/WebCalendar.vue';
  import ManageProfileIntegrationsSyncProjects from '@/components/Manage/ManageProfile/ManageProfileIntegrationsSyncProjects.vue';

  export default {
    components: {
      WebCalendar,
      ManageProfileIntegrationsSyncProjects,
    },
    data() {
      return {
        integrations: [],
        syncAllProjects: {
          isOpened: false,
          integrationName: null,
          syncElementType: null,
        },
      };
    },
    computed: {
      availableIntegrations() {
        return this.$store.getters['users/accessRight/config'].availableIntegrations;
      },
      isConnected() {
        return {
          microsoft: this.$store.state.integrations.microsoft.outlook.connected,
          google: this.$store.state.integrations.google.gcal.connected,
        };
      },
      isLoading() {
        return {
          microsoft: this.$store.state.integrations.microsoft.outlook.loading,
          google: this.$store.state.integrations.google.gcal.loading,
        };
      },
      integrationConfig() {
        return {
          microsoft: this.$store.state.integrations.microsoft.outlook.config,
          google: this.$store.state.integrations.google.gcal.config,
        };
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    created() {
      this.integrations = this.availableIntegrations.reduce((acc, integrationName) => { acc[integrationName] = this.newIntegration(); return acc; }, {});
      this.$store.state.users.userPromise.then(() => {
        if (this.isPremium) {
          this.integrations = this.availableIntegrations.reduce((acc, integrationName) => { acc[integrationName] = this.newIntegration(); return acc; }, {});
          _.each(this.integrations, (integration, integrationName) => { this.loadIntegration(integrationName); });
        }
      });
    },
    unmounted() {
      _.each(this.integrations, (integration) => {
        if (integration.pollWindowClosing) clearInterval(integration.pollWindowClosing);
      });
    },
    methods: {
      loadIntegration(integrationName) {
        const integration = this.integrations[integrationName];
        if (integration.pollWindowClosing) {
          clearInterval(integration.pollWindowClosing);
          integration.pollWindowClosing = null;
        }
        integration.state = 'loading';

        this.$store.dispatch(`integrations/${integrationName}/refreshConnection`).then((responseData) => {
          this.integrations[integrationName] = this.newIntegration({
            state: responseData.connected ? 'connected' : 'inactive',
            url: responseData.url,
          });
        }).catch((error) => {
          this.integrations[integrationName] = this.newIntegration({
            state: 'error',
            error,
          });
        });
      },
      loginIntegration(integrationName) {
        const integration = this.integrations[integrationName];
        if (integration.url) {
          integration.state = 'logging';
          const loginWindow = window.open(integration.url, "_blank");
          integration.pollWindowClosing = setInterval(() => {
            if (loginWindow && loginWindow.closed) this.loadIntegration(integrationName);
          }, 1000);
        }
      },
      toggleInteractionOption(integrationName, optionName) {
        const newConfig = { ...this.integrationConfig[integrationName] };
        newConfig[optionName] = ! newConfig[optionName];
        this.$store.dispatch(`integrations/${integrationName}/setConfig`, newConfig).catch((error) => {
          console.log(error);
        });
      },
      newIntegration(params) {
        return _.extend({ state: null, url: null, error: "", pollWindowClosing: null }, params);
      },
      logoutIntegration(integrationName) {
        this.integrations[integrationName].state = 'loading';
        this.$store.dispatch(`integrations/${integrationName}/logout`).then(() => {
          this.loadIntegration(integrationName);
        }).catch((error) => {
          this.integrations[integrationName] = this.newIntegration({
            state: 'error',
            error,
          });
        });
      },
    },
  };
</script>
