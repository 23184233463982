<!-- eslint-disable vue/max-len max-len -->
<template>
  <v-dialog v-model="$store.state.ui.displayNewVersionsWindow" :width="1000" height="100%" persistent @keydown.esc="close">
    <v-card class="pa-6 pa-sm-12 pr-md-0 light-scrollbar h-100">
      <div class="d-flex flex-wrap flex-md-nowrap gap-4">
        <div>
          <template v-if="$i18n.locale == 'fr'">
            <div class="text-h4">Nouveautés pour la rentrée !</div>
            <div class="py-4 d-flex flex-column gap-4">
              <div>Découvrez les dernières améliorations de Bubble Plan qui boostent votre productivité et simplifient encore votre organisation.</div>
              <v-expansion-panels v-model="openedPanel" flat static class="ml-n4">
                <v-expansion-panel>
                  <v-expansion-panel-title>
                    <div>
                      <b>🚀 Mode Kanban optimisé</b>
                      <div class="mt-2"><i>Profitez d'une expérience Kanban plus fluide et intuitive</i></div>
                    </div>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <ul class="ml-8">
                      <li class="mt-1"><b>Drag & drop intelligent</b> : déplacez vos tâches entre les colonnes avec une précision accrue.</li>
                      <li class="mt-1"><b>Priorisation des tâches</b> : réorganisez vos bulles et jalons d'un simple glisser-déposer pour ajuster vos priorités.</li>
                      <li class="mt-1"><b>Colonnes flexibles</b> : ajustez facilement la taille des colonnes et déplacez des éléments même vers des colonnes réduites, par exemple pour les sortir de votre process.</li>
                      <li class="mt-1"><b>Backlog personnalisable</b> : option pour afficher toutes vos bulles planifiées dans le backlog, pour une vue complète.</li>
                      <li class="mt-1"><b>Exports et partage adaptés</b> : vos exports et liens de partage s'adaptent automatiquement à votre mode d'affichage (Planning ou Kanban).</li>
                    </ul>
                  </v-expansion-panel-text>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-title>
                    <div>
                      <b>⏱️ Planification récurrente (BUSINESS)</b>
                      <div class="mt-2"><i>Gérez vos tâches ou événements répétitifs en toute simplicité</i></div>
                    </div>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <ul class="ml-8">
                      <li class="mt-1"><b>Paramétrage avancé</b> : personnalisez la fréquence de récurrence <b>dans les options avancées des dates</b>.</li>
                      <li class="mt-1"><b>Affichage maîtrisé</b> : définissez le nombre d'occurrences futures visibles. Les nouvelles se créent automatiquement à mesure que le temps passe.</li>
                      <li class="mt-1"><b>Évolution flexible</b> : modifiez vos éléments récurrents à tout moment et appliquez les changements aux occurrences futures.</li>
                    </ul>
                  </v-expansion-panel-text>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-title>
                    <div>
                      <b>💡 Et encore plus...</b>
                      <div class="mt-2"><i>Comme toujours, nous continuons de peaufiner votre interface</i></div>
                    </div>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <ul class="ml-8">
                      <li class="mt-1">Affichage de la date de fin cible dans la vue planning pour une meilleure vision d'ensemble</li>
                      <li class="mt-1">Améliorations ergonomiques pour une expérience utilisateur optimisée</li>
                    </ul>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </template>
          <template v-else>
            <div class="text-h4">New for September!</div>
            <div class="py-4 d-flex flex-column gap-4">Discover the latest Bubble Plan enhancements that boost your productivity and simplify your organization even further.</div>
            <v-expansion-panels v-model="openedPanel" flat static class="ml-n4">
              <v-expansion-panel>
                <v-expansion-panel-title>
                  <div>
                    <b>🚀 Enhanced Kanban mode</b>
                    <div class="mt-2"><i>Enjoy a more fluid and intuitive Kanban experience</i></div>
                  </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <ul class="ml-8">
                    <li class="mt-1"><b>Intelligent drag & drop</b>: move your tasks between columns with increased precision.</li>
                    <li class="mt-1"><b>Task prioritization</b>: rearrange your bubbles and milestones with a simple drag-and-drop to adjust your priorities.</li>
                    <li class="mt-1"><b>Flexible columns</b>: easily adjust the size of columns and move elements even to reduced columns, for example to remove them from your process.</li>
                    <li class="mt-1"><b>Customizable backlog</b>: option to display all your scheduled bubbles in the backlog, for a complete view.</li>
                    <li class="mt-1"><b>Adapted exports and sharing</b>: your exports and sharing links automatically adapt to your display mode (Planning or Kanban).</li>
                  </ul>
                </v-expansion-panel-text>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-title>
                  <div>
                    <b>⏱️ Recurrent planification (BUSINESS)</b>
                    <div class="mt-2"><i>Manage your repetitive tasks or events with ease</i></div>
                  </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <ul class="ml-8">
                    <li class="mt-1"><b>Advanced settings</b>: customize the recurrence frequency <b>in the advanced date options</b>.</li>
                    <li class="mt-1"><b>Controlled display</b>: set the number of future occurrences visible. New ones are created automatically as time passes.</li>
                    <li class="mt-1"><b>Flexible evolution</b>: modify your recurring items at any time and apply changes to future occurrences.</li>
                  </ul>
                </v-expansion-panel-text>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-title>
                  <div>
                    <b>💡 And even more...</b>
                    <div class="mt-2"><i>As always, we continue to refine your interface</i></div>
                  </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <ul class="ml-8">
                    <li class="mt-1">Display of the target end date in the planning view for a better overview</li>
                    <li class="mt-1">Ergonomic improvements for an optimized user experience</li>
                  </ul>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </div>
        <div class="w-100 text-center">
          <img src="@/img/animations/illustration-news.gif" class="hidden-sm-and-down mb-12" style="max-width: 350px">
          <v-btn color="primary" size="large" rounded @click="close">
            {{ $t('HELP.NEW_VERSION_BUTTON') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data() {
      return {
        openedPanel: null,
      };
    },
    mounted() {
      setTimeout(() => {
        if (! this.openedPanel) this.openedPanel = 0;
      }, 600);
    },
    methods: {
      close() {
        this.$store.commit('ui/setDisplayNewVersionsWindow', false);
      },
    },
  };
</script>
