<template>
  <div class="d-flex">
    <div class="mr-8 responsive-lateral-menu" :class="{ 'is-mobile': $vuetify.display.mobile }" style="width: 250px; flex: 0 0 250px">
      <div class="overflow-y-auto overflow-x-hidden light-scrollbar overscroll-behavior-y-contain pr-2"
           style="position: fixed; top: 40px; bottom: 16px; width: 250px">
        <div class="mb-1 font-weight-bold text-ellipsis">{{ $t('NAV.SHARE') }}</div>
        <tabs-vertical v-model="exportImportTabIndex" :mandatory="false">
          <v-tab value="share">
            <v-icon start>far fa-share-alt</v-icon>
            {{ $t('SHARE.CONTRIBUTORS') }}
          </v-tab>
          <v-tab v-if="hasReadOnlyLink" value="share-link">
            <v-icon start>far fa-link</v-icon>
            {{ $t('SHARE.INVITE_CUSTOMERS') }}
          </v-tab>
          <v-tab v-if="hasViews" value="share-views">
            <v-icon start>far fa-eye</v-icon>
            {{ $t('SHARE.CUSTOM_VIEWS') }}
          </v-tab>
        </tabs-vertical>
        <div class="mt-4 mb-1 font-weight-bold text-ellipsis">{{ $t('NAV.EXPORT') }}</div>
        <tabs-vertical v-model="exportImportTabIndex" :mandatory="false">
          <v-tab value="export-image">
            <v-icon start>far fa-image</v-icon>
            {{ $t('EXPORT.IMAGE_EXPORT') }}
          </v-tab>
          <v-tab value="export-csv">
            <v-icon start>far fa-file-csv</v-icon>
            {{ $t('EXPORT.CSV_EXPORT') }}
          </v-tab>
        </tabs-vertical>
        <div class="mt-4 mb-1 font-weight-bold text-ellipsis">{{ $t('NAV.IMPORT') }}</div>
        <tabs-vertical v-model="exportImportTabIndex" :mandatory="false">
          <v-tab value="import-template">
            <v-icon start>far fa-file-import</v-icon>
            {{ $t('PLANNING.IMPORT_TEMPLATE') }}
          </v-tab>
          <v-tab value="import-csv">
            <v-icon start>far fa-file-csv</v-icon>
            {{ $t('IMPORT_CSV.CSV_IMPORT') }}
          </v-tab>
        </tabs-vertical>
      </div>
    </div>

    <div class="flex-grow-1" style="min-width: 0">
      <!-- TAB SHARE -->
      <share-window v-if="section == 'share'" :planning="planning" :opened-tab-index="exportImportTabIndex" @close="close"></share-window>

      <!-- TAB EXPORT-->
      <keep-alive>
        <export-window v-if="section == 'export'" :planning="planning" :planning-view-id="planningViewId" :opened-tab-index="exportImportTabIndex" @close="close"></export-window>
      </keep-alive>

      <!-- TAB IMPORT TEMPLATE -->
      <template v-if="isBusiness">
        <import-template v-if="exportImportTabIndex == 'import-template'" :planning="planning" @close="close"></import-template>
        <keep-alive>
          <import-csv v-if="exportImportTabIndex == 'import-csv'" :planning="planning" @close="close"></import-csv>
        </keep-alive>
      </template>
      <template v-else-if="hasImportTemplate && exportImportTabIndex == 'import-template'">
        <import-template :planning="planning" @close="close"></import-template>
      </template>
      <v-alert v-else-if="section == 'import'" color="warningorange" type="warning" style="max-width: 600px">
        <b>{{ $t('PREMIUM.BUSINESS_FEATURE') }}</b>
        <div class="mt-1">{{ $t('PREMIUM.BUSINESS.IMPORT_IS_BUSINESS') }}</div>
      </v-alert>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import ShareWindow from '@/components/Share/ShareWindow.vue';
  import ExportWindow from './ExportWindow.vue';
  import ImportTemplate from './ImportTemplate.vue';
  import ImportCsv from './ImportCsv.vue';

  export default {
    components: {
      ShareWindow,
      ExportWindow,
      ImportTemplate,
      ImportCsv,
    },
    props: {
      planning: { type: Object, required: true },
      planningViewId: { type: Number, default: 0 },
    },
    data() {
      return {
        exportImportTabIndex: 'share',
      };
    },
    computed: {
      section() {
        return (this.exportImportTabIndex || '').split('-')[0];
      },
      hasReadOnlyLink() { return ! this.$store.getters['users/accessRight/config'].hideExtenalLink; },
      hasViews() { return this.$store.getters['users/accessRight/config'].hasViews; },
      hasImportTemplate() { return this.$store.state.users.accessRight.hasImportTemplate; },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      ...mapState('ui/planning', ['exportImportOpeningTab']),
    },
    watch: {
      exportImportOpeningTab: {
        handler(newVal) {
          if (newVal) this.exportImportTabIndex = newVal;
        },
        immediate: true,
      },
      exportImportTabIndex(newVal, oldVal) {
        if (! newVal && oldVal) this.exportImportTabIndex = oldVal; // prevent empty tab when clicking on selected tab
      },
    },
    methods: {
      close() {
        this.openExportImport(null);
      },
      ...mapMutations('ui/planning', ['openExportImport']),
    },
  };
</script>
