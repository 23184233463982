<template>
  <div>
    <!-- TITLE -->
    <div class="d-flex align-center mb-1">
      <label for="title" class="d-flex align-center">
        <span class="mr-2" style="font-family: Georgia, serif; font-size:20px">T</span>
        {{ $t('ELEMENT-DETAIL.TITLE') }}
      </label>
      <v-spacer></v-spacer>
      <btn-toggle-filter :model-value="titleStyle['font-weight']" class="h-auto"
                         @update:model-value="changeTitleStyle('font-weight', $event)">
        <v-btn-icon value="bold" size="small" density="comfortable" tabindex="-1">
          <v-icon>far fa-bold</v-icon>
        </v-btn-icon>
      </btn-toggle-filter>
      <btn-toggle-filter :model-value="titleStyle['font-style']" class="ml-2 h-auto"
                         @update:model-value="changeTitleStyle('font-style', $event)">
        <v-btn-icon value="italic" size="small" density="comfortable" tabindex="-1">
          <v-icon>far fa-italic</v-icon>
        </v-btn-icon>
      </btn-toggle-filter>
    </div>
    <div>
      <v-combobox v-if="titlesList" id="title" v-model="elTitle" :items="titlesList" :placeholder="$t('ELEMENT-DETAIL.ENTER_TITLE')"></v-combobox>
      <v-text-field v-else id="title" v-model="elTitle" :placeholder="$t('ELEMENT-DETAIL.ENTER_TITLE')"></v-text-field>
    </div>

    <!-- DESCRIPTION -->
    <client-custom-component v-if="customDescription" :name="customDescription.component" :el="el" :custom-field="customDescription" class="mt-6"></client-custom-component>
    <template v-else>
      <div class="d-flex align-center mt-6 mb-1">
        <label for="description" class="d-flex align-center"><v-icon start>far fa-align-justify</v-icon>{{ $t('ELEMENT-DETAIL.DESCRIPTION') }}</label>
        <template v-if="el.isType('macro', 'task')">
          <v-spacer></v-spacer>
          <btn-toggle-filter :model-value="descriptionStyle['font-size'] || '1em'" mandatory="force" class="bg-lightgrey h-auto flex-shrink-0"
                             @update:model-value="changeDescriptionStyle('font-size', $event)">
            <v-btn-icon value=".8em" size="small" density="comfortable" tabindex="-1">
              <v-icon style="font-size: .8em">far fa-font</v-icon>
            </v-btn-icon>
            <v-btn-icon value="1em" size="small" density="comfortable" tabindex="-1">
              <v-icon style="font-size: 1em">far fa-font</v-icon>
            </v-btn-icon>
            <v-btn-icon value="1.2em" size="small" density="comfortable" tabindex="-1">
              <v-icon style="font-size: 1.2em">far fa-font fa-lg</v-icon>
            </v-btn-icon>
          </btn-toggle-filter>
          <btn-toggle-filter :model-value="descriptionStyle['text-align'] || 'left'" mandatory="force" class="mx-2 bg-lightgrey h-auto flex-shrink-0"
                             @update:model-value="changeDescriptionStyle('text-align', $event)">
            <v-btn-icon value="left" size="small" density="comfortable" tabindex="-1">
              <v-icon>far fa-align-left</v-icon>
            </v-btn-icon>
            <v-btn-icon value="center" size="small" density="comfortable" tabindex="-1">
              <v-icon>far fa-align-center</v-icon>
            </v-btn-icon>
            <v-btn-icon value="right" size="small" density="comfortable" tabindex="-1">
              <v-icon>far fa-align-right</v-icon>
            </v-btn-icon>
          </btn-toggle-filter>
          <element-details-config-button :el="el" config="show-description"></element-details-config-button>
        </template>
      </div>
      <div>
        <v-textarea id="description" v-model="elDescription" :placeholder="$t('ELEMENT-DETAIL.ENTER_DESCRIPTION')" auto-grow hide-details rows="2" class="pt-0">
        </v-textarea>
      </div>
    </template>

    <!-- DATES -->
    <element-details-header-dates :el="el" @close="close"></element-details-header-dates>
  </div>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import ElementDetailsHeaderDates from './ElementDetailsHeaderDates.vue';
  import ElementDetailsConfigButton from './partials/ElementDetailsConfigButton.vue';

  export default {
    components: {
      ElementDetailsHeaderDates,
      ElementDetailsConfigButton,
    },
    props: {
      el: { type: Object, required: true },
    },
    emits: ['close'],
    data() {
      return {
        customDescription: clientConfig.elements && clientConfig.elements.customDescription,
        titlesList: clientConfig.elements && clientConfig.elements.titlesList,
      };
    },
    computed: {
      elTitle: {
        get() { return this.el.getTitle(); },
        set(newVal) {
          this.el.setTitle(newVal);
        },
      },
      titleStyle() {
        return this.el.getTitleStyle() || {};
      },
      elDescription: {
        get() { return this.el.getDescription(); },
        set(newVal) {
          this.el.setDescription(newVal);
        },
      },
      descriptionStyle() {
        return this.el.getDescriptionStyle() || {};
      },
    },
    created() {
      if (this.titlesList) {
        // vuetify search-input does not keep initial value
        const titleInitialValue = this.elTitle;
        setTimeout(() => {
          this.elTitle = titleInitialValue;
        });
      }
    },
    mounted() {
      setTimeout(() => {
        const titleInput = document.getElementById('title');
        if (titleInput) titleInput.focus(); // replace autofocus which is triggered unexpectedly
      });
    },
    methods: {
      close() {
        this.$emit('close');
      },
      changeTitleStyle(prop, newVal) {
        const titleStyle = this.el.getTitleStyle() || {};
        titleStyle[prop] = newVal;
        this.el.setTitleStyle(titleStyle);
      },
      changeDescriptionStyle(prop, newVal) {
        const descriptionStyle = this.el.getDescriptionStyle() || {};
        descriptionStyle[prop] = newVal;
        this.el.setDescriptionStyle(descriptionStyle);
      },
    },
  };
</script>
