<template>
  <div>
    <home-projects-filters :filters="filters" :projects="projects"></home-projects-filters>
    <div v-if="! dataLoaded.projects && ! errors.loadProjects" style="text-align:center; width: 100%"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-if="! dataLoaded.projects && errors.loadProjects">{{ errors.loadProjects }}</div>
    <div v-if="dataLoaded.projects && ! projects.length">{{ $t('PROJECTS.NO_PROJECT_AVAILABLE') }}</div>
    <div v-show="dataLoaded.projects && projects.length">
      <v-container class="pa-0 mt-n2 mb-3" style="max-width: calc(100% - 36px)">
        <!-- only way to add margin around pagination ? -->
        <v-pagination v-if="filteredProjects.length > projectsPerPage" v-model="projectsCurrentPage" :length="projectsPaginationLength"></v-pagination>
      </v-container>
      <!-- GRID -->
      <v-container v-if="filters.display == 'grid'" fluid class="pa-0">
        <v-row class="pb-1">
          <transition-group name="list-fade" tag="div" class="d-flex" style="flex-wrap: wrap; width: 100%">
            <v-col v-for="project in displayedFilteredProjects" :key="`project${project.id}`" lg="3" sm="6" cols="12" class="projectbox list-fade-item">
              <v-lazy v-if="vLazyAvailable" :options="{ rootMargin: '280px' }" style="height: 100%; min-height: 270px">
                <home-projects-project :project="project" :status-info="statusInfo"
                                       @duplicate="duplicateProject(project)"
                                       @archive="archiveProject(project)">
                </home-projects-project>
              </v-lazy>
              <home-projects-project v-else :project="project" :status-info="statusInfo"
                                     @duplicate="duplicateProject(project)"
                                     @archive="archiveProject(project)">
              </home-projects-project>
            </v-col>
          </transition-group>
        </v-row>
      </v-container>
      <!-- LIST -->
      <v-card v-if="filters.display == 'list'">
        <div class="table-responsive">
          <table class="table text-left">
            <thead class="bg-lightgrey">
              <tr>
                <th class="nobr">{{ $t('NEWPROJECT.PROJECT_NAME') }}</th>
                <th class="nobr">{{ $t('GLOBAL.LAST_MODIFICATION') }}</th>
                <th class="nobr">{{ $t('GLOBAL.OWNER') }}</th>
                <th class="nobr">{{ $t('GLOBAL.CREATION_DATE') }}</th>
                <th class="nobr">{{ $t('GLOBAL.ACCESS_RIGHT') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="project in displayedFilteredProjects" :key="`project${project.id}`" class="pointer" @click="goToProject(project)">
                <td>{{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}</td>
                <td>{{ $filters.moment(project.date_of_modification, 'calendar') }} {{ $t('GLOBAL.BY') }} {{ translateApiText(project.last_modification_user_name) }}</td>
                <td>{{ translateApiText(project.owner_name) }}</td>
                <td>{{ $filters.moment(project.date_of_creation, 'datetime') }}</td>
                <td>{{ $t($filters.uppercase(`ACR.${project.access_right}`)) }}</td>
                <td class="nobr" @click.stop>
                  <v-btn-icon :to="projectLink(project, true)" :title="$t('PROJECTS.READ_ONLY')" size="small" class="my-0">
                    <v-icon>far fa-eye</v-icon>
                  </v-btn-icon>
                  <v-btn-icon :to="`manage?open=${project.id}`" :title="$t('PROJECTS.MANAGE_ACCESS')" size="small" class="my-0">
                    <v-icon color="warningorange">far fa-lock</v-icon>
                  </v-btn-icon>
                  <v-btn-icon v-if="isBusiness" :to="projectHistoryLink(project)" :title="$t('PROJECTS.HISTORY')" size="small" class="my-0">
                    <v-icon>far fa-history</v-icon>
                  </v-btn-icon>
                  <template v-if="project.access_right == 'admin'">
                    <v-btn-icon :title="$t('PROJECTS.DUPLICATE')" size="small" class="my-0" @click="duplicateProject(project)">
                      <v-icon>far fa-copy</v-icon>
                    </v-btn-icon>
                    <v-btn-icon :title="$t('PROJECTS.ARCHIVE')" size="small" class="my-0" @click="archiveProject(project)">
                      <v-icon color="errorred">far fa-archive</v-icon>
                    </v-btn-icon>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
  import HomeProjectsFilters from './HomeProjectsFilters.vue';
  import HomeProjectsProject from './HomeProjectsProject.vue';
  import HomeProjectsMixin from './HomeProjectsMixin';

  export default {
    components: {
      HomeProjectsProject,
      HomeProjectsFilters,
    },
    mixins: [HomeProjectsMixin],
    props: {
      dataLoaded: { type: Object, default: () => {} },
      errors: { type: Object, default: () => {} },
      filters: { type: Object, default: () => {} },
      projects: { type: Array, default: () => [] },
      statusInfo: { type: Object, default: () => {} },
    },
    emits: ['duplicate-project', 'archive-project'],
    data() {
      return {
        projectsPerPage: 50,
        projectsCurrentPage: 1,
      };
    },
    computed: {
      filteredProjects() {
        const filtered = this.projects.filter(project => this.filterProjectByCompany(project) && this.filterProjectByCategory(project)
          && this.filterProjectByTitle(project) && this.filterProjectByUser(project));
        if (this.filters.sort) {
          let { sort } = this.filters;
          let reverse = false;
          if (sort.startsWith('-')) {
            sort = sort.slice(1);
            reverse = true;
          }
          if (sort == 'title') {
            filtered.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
          } else {
            filtered.sort((a, b) => (a[sort] < b[sort] ? -1 : 1));
          }
          if (reverse) filtered.reverse();
        }
        return filtered;
      },
      displayedFilteredProjects() {
        return this.filteredProjects.slice(this.projectsPerPage * (this.projectsCurrentPage - 1), this.projectsPerPage * this.projectsCurrentPage);
      },
      projectsPaginationLength() {
        return Math.ceil(this.filteredProjects.length / this.projectsPerPage);
      },
      vLazyAvailable() {
        return 'IntersectionObserver' in window;
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
    watch: {
      projectsPaginationLength() {
        if (this.projectsCurrentPage > this.projectsPaginationLength) this.projectsCurrentPage = 1;
      },
    },
    methods: {
      duplicateProject(project) {
        this.$emit('duplicate-project', project);
      },
      archiveProject(project) {
        this.$emit('archive-project', project);
      },
      goToView(view) {
        const path = `/planningview/${view.id}/${window.slugify(view.title || this.$t('PLANNING.UNNAMED_VIEW'))}`;
        this.$router.push({ path, query: {} });
      },
      goToProject(project, isConsult = this.statusInfo.readonly || project.access_right == 'consult') {
        const path = `/${isConsult ? 'viewer' : 'planning'}/${project.id}/${window.slugify(project.title || this.$t('PLANNING.UNNAMED_PROJECT'))}`;
        this.$router.push({ path, query: {} });
      },
      projectHistoryLink(project) {
        return `/analysis/planningversions/${project.id}/${window.slugify(project.title || this.$t('PLANNING.UNNAMED_PROJECT'))}`;
      },
    },
  };
</script>
