<template>
  <div>
    <canvas ref="myChart" :width="width" :height="height"></canvas>
  </div>
</template>

<script>
  export default {
    props: {
      config: { type: Object, required: true },
      height: { type: Number, default: 400 },
      width: { type: Number, default: 400 },
    },
    data() {
      return {
        Chart: null,
        plugins: [],
      };
    },
    watch: {
      config() {
        if (this.Chart) {
          const ctx = this.$refs.myChart.getContext('2d');
          const chart = this.getChart();
          if (chart) chart.destroy();
          const newchart = new this.Chart(ctx, {
            plugins: this.plugins,
            ...this.config,
          });
          this.getChart = () => newchart;
        }
      },
    },
    mounted() {
      import('chart.js/auto').then(({ Chart }) => { // import whole package -> todo : use tree shaking
        import('chartjs-plugin-datalabels').then((plugin) => {
          this.Chart = Chart;
          this.plugins = [plugin.default];
          const ctx = this.$refs.myChart.getContext('2d');
          const chart = new this.Chart(ctx, {
            plugins: this.plugins,
            ...this.config,
          });
          this.getChart = () => chart; // not adding chart directly to data because it creates conflicts with vue reactivity system
        });
      });
    },
  };
</script>
