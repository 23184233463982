<template>
  <div v-if="planning.visibleTimeline" class="lane-header-column">
    <template v-if="canModifyPlanning">
      <draggable :list="planning.lanes" item-key="id" handle=".draggable-anchor"
                 @start="startSortLanes()" @end="updateSortLanes()">
        <template #item="{ element: lane }">
          <div v-if="visibleLanesSet.has(lane)" :id="`laneheader${lane.id}`"
               :class="lane.getColorClass()" class="lane-header bg-el-color"
               :style="{ height: `${lane.height}px` }">
            <div :style="{ width: `${laneLevelWidth * lane.level}px` }" class="lane-level-gap"></div>
            <div :title="$t('PLANNING.DRAG_AND_DROP_LANES')" class="draggable-anchor grab"></div>
            <v-hover v-if="! lane.hidden" v-slot="{ isHovering: hoverLaneLabel, props: hoverProps }">
              <div class="lane-label" v-bind="hoverProps">
                <planning-lane-header-menu :lane="lane" :visible="hoverLaneLabel && ! laneLabelSelected[lane.id]" @select-color="selectLaneColor(lane)">
                </planning-lane-header-menu>
                <v-fade-transition v-if="hasMonitoringPlan">
                  <div v-if="hoverLaneLabel && ! laneLabelSelected[lane.id]" class="lane-menu" style="left:100%; top: 0; padding: 4px; z-index: 201">
                    <div class="text-white pointer" style="min-width: 44px" @click="monitoringPlanEditingLane = lane">
                      {{ $t('MONITORING_PLAN.TITLE') }}
                    </div>
                  </div>
                </v-fade-transition>
                <monitoring-plan-in-planning-meteo :lane="lane" class="pointer"
                                                   @click="monitoringPlanEditingLane = lane">
                </monitoring-plan-in-planning-meteo>
                <textarea-autosize v-if="! lane.hidden" v-model="lane.label" v-contentautoselect :style="lane.getLabelStyle()" spellcheck="false" rows="1"
                                   style="width: 100%; background: transparent; color: inherit; resize: none; border: none; text-align: center"
                                   @focus="startChangingContent(lane); setLaneLabelSelected(lane.id, true)"
                                   @blur="changingContent(lane); setLaneLabelSelected(lane.id, false)">
                </textarea-autosize>
                <router-link v-if="lane.project_id" :to="subprojectPath(lane)">
                  <div class="subprojects-header-chip">
                    {{ $t('PLANNING.SUBPROJECT') }}
                  </div>
                </router-link>
              </div>
            </v-hover>
            <v-hover v-if="lane.hidden" v-slot="{ isHovering: hoverLaneLabel, props: hoverProps }">
              <div :title="lane.label" class="lane-label" style="padding: 0 0 0 5px" v-bind="hoverProps">
                <div class="text-ellipsis" style="align-self: flex-start; width: 100%">{{ lane.label }}</div>
                <planning-lane-header-menu :lane="lane" :visible="hoverLaneLabel"></planning-lane-header-menu>
              </div>
            </v-hover>
          </div>
        </template>
      </draggable>

      <!-- ADD LANE-->
      <div>
        <div class="lane-header el-color-0- bg-el-color rounded-bs overflow-hidden">
          <v-btn-icon class="tour-addlane" size="large" rounded="0" block @click="addLane(null)">
            <v-icon size="large">far fa-plus</v-icon>
          </v-btn-icon>
        </div>
      </div>

      <!-- MODALS -->
      <v-menu v-if="laneColorEditingLane.id" :model-value="!! laneColorEditingLane.id" :close-on-content-click="false"
              :target="`#laneheader${laneColorEditingLane.id}`" location="end" offset="4"
              @update:model-value="closeColorEditingModal">
        <v-card>
          <color-picker :planning="planning" :target="laneColorEditingLane" compact @close="closeColorEditingModal"></color-picker>
        </v-card>
      </v-menu>
      <monitoring-plan-in-planning-modal v-if="hasMonitoringPlan" :planning_id="planning.id" :lane="monitoringPlanEditingLane"
                                         @close="monitoringPlanEditingLane = {}">
      </monitoring-plan-in-planning-modal>
      <!-- END MODALS-->
    </template>

    <template v-if="! canModifyPlanning">
      <div v-for="lane in visibleLanesSet" :id="`laneheader${lane.id}`" :key="lane.id"
           :class="lane.getColorClass()" class="lane-header bg-el-color"
           :style="{ height: `${lane.height}px` }">
        <div :style="{ width: `${laneLevelWidth * lane.level}px` }" class="lane-level-gap"></div>
        <div class="draggable-anchor"></div>
        <div v-if="! lane.hidden" class="lane-label">
          <monitoring-plan-in-planning-meteo :lane="lane"></monitoring-plan-in-planning-meteo>
          <div :style="lane.getLabelStyle()" class="pre" style="width: 100%; word-wrap: break-word;">{{ lane.label }}</div>
        </div>
        <div v-if="lane.hidden" :title="lane.label" class="lane-label" style="padding: 0 0 0 5px">
          <div class="text-ellipsis" style="align-self: flex-start; width: 100%">{{ lane.label }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
  .lane-header-column {
    width: 155px;
    position: relative;

    .lane-header {
      text-align: center;
      font-weight: initial;
      position: relative;
      display: flex;
      border-radius: 0 4px 4px 0;
      border-right: 1px solid #eee;

      .lane-label {
        flex: 1 1 0;
        min-width: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 5px;
        position: relative;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
      }
    }

    .lane-level-gap {
      flex-shrink: 0;
      background: white;
      transition: width .3s;
    }

    .subprojects-header-chip {
      background: rgb(100, 100, 100);
      padding: 3px 7px;
      border-radius: 30px;
      display: inline-block;
      color: white;
      width: auto;
    }
  }
</style>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import draggable from 'vuedraggable';
  import constants from '@/js/constants.js';
  import TextareaAutosize from '@/components/Reusables/TextareaAutosize.vue';
  import ColorPicker from '@/components/ColorPicker/ColorPicker.vue';
  import MonitoringPlanInPlanningMeteo from '@/components/MonitoringPlan/MonitoringPlanInPlanningMeteo.vue';
  import MonitoringPlanInPlanningModal from '@/components/MonitoringPlan/MonitoringPlanInPlanningModal.vue';
  import PlanningLaneHeaderMenu from './PlanningLaneHeaderMenu.vue';
  import monitoringplan from '../mixins/monitoringplan';

  export default {
    components: {
      draggable,
      TextareaAutosize,
      ColorPicker,
      MonitoringPlanInPlanningMeteo,
      MonitoringPlanInPlanningModal,
      PlanningLaneHeaderMenu,
    },
    mixins: [monitoringplan],
    props: {
      planning: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
    },
    data() {
      return {
        laneLabelSelected: {},
        laneColorEditingLane: {},
        monitoringPlanEditingLane: {},
        laneLevelWidth: constants.laneLevelWidth,
      };
    },
    computed: {
      visibleLanesSet() {
        return new Set(this.$store.getters['planning/lanes/getVisibleLanes']({ planning: this.planning }));
      },
      canModifyPlanning() {
        return this.dynamic && ! this.view;
      },
      ...mapGetters('planning', ['hasSubprojects']),
    },
    methods: {
      selectLaneColor(lane) {
        this.startChangingConfig();
        this.startChangingContent(lane);
        this.laneColorEditingLane = lane;
      },
      closeColorEditingModal() {
        this.changingConfig();
        this.changingContent(this.laneColorEditingLane);
        this.laneColorEditingLane = {};
      },
      setLaneLabelSelected(laneId, val) {
        this.laneLabelSelected[laneId] = val;
      },
      subprojectPath(lane) {
        const planningTitle = this.$store.getters['subprojects/getSubprojectByLaneId'](lane.id)?.getTitle() || lane.label;
        return `/planning/${lane.project_id}/${window.slugify(planningTitle)}`;
      },
      ...mapActions('planning/lanes', ['startChangingContent', 'changingContent', 'addLane', 'startSortLanes', 'updateSortLanes']),
      ...mapActions('planning/config', ['startChangingConfig', 'changingConfig']),
    },
  };
</script>
