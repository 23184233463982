export const contentautoselect = {
  mounted(el) {
    const element = $(el);
    element.bind("focus", () => {
      if (element.is("input") || element.is("textarea")) {
        element.select();
      } else {
        const range = document.createRange();
        range.selectNodeContents(element[0]);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      }
      // prevent mouseup from unselecting
      element.one("mouseup", (e) => {
        e.preventDefault();
      });
      element.one("mousedown", () => {
        element.off("mouseup");
      });
    });
  },
};

// reload gif animation on element mount
export const reloadSrcOnMount = {
  mounted(el) {
    setTimeout(() => {
      el.src = el.getAttribute('src');
    });
  },
};

export const draggable = {
  mounted(el, binding) {
    const element = $(el);
    element.draggable(binding.value || {});
  },
  updated(el, binding) {
    if (binding.value && binding.oldValue && binding.value.disabled != binding.oldValue.disabled) {
      const element = $(el);
      element.draggable(binding.value.disabled ? "disable" : "enable");
    }
  },
};

export const droppable = {
  mounted(el, binding) {
    const element = $(el);
    element.droppable(binding.value || {});
  },
  updated(el, binding) {
    if (binding.value && binding.oldValue && binding.value.disabled != binding.oldValue.disabled) {
      const element = $(el);
      element.droppable(binding.value.disabled ? "disable" : "enable");
    }
  },
};

export const resizable = {
  mounted(el, binding) {
    const element = $(el);
    element.resizable(binding.value || {});
  },
  updated(el, binding) {
    if (binding.value && binding.oldValue && binding.value.disabled != binding.oldValue.disabled) {
      const element = $(el);
      element.resizable(binding.value.disabled ? "disable" : "enable");
    }
  },
};

export const selectableArea = {
  mounted(el, binding) {
    let startX;
    const element = $(el);
    const options = binding.value || {};
    element.selectable(_.extend({}, options, {
      filter: options.filter || "*",
      distance: 5,
      start: (event, ui) => {
        startX = event.pageX;
        if (options.start) options.start(event, ui);
      },
      selecting(event) {
        if (event.pageX >= startX) {
          // left to right
          element.selectable("option", "tolerance", "fit");
        } else {
          element.selectable("option", "tolerance", "touch");
        }
      },
      stop() { element.selectable("option", "tolerance", "touch"); },
      selected: options.selected,
    }));
    element.mousedown(() => {
      if (document.activeElement) document.activeElement.blur();
    });
  },
  updated(el, binding) {
    if (binding.value && binding.oldValue && binding.value.disabled != binding.oldValue.disabled) {
      const element = $(el);
      element.selectable(binding.value.disabled ? "disable" : "enable");
    }
  },
};

/**
* Create and drag element from button inside navigation
*/
export const draggableButton = {
  mounted(el, binding) {
    const element = $(el);
    const options = binding.value || {};
    const type = options.type || "task";
    const cursorAt = { top: (type == 'milestone') ? 12 : 5, left: (type == 'milestone') ? 27 : 53 };

    function $t(key) {
      if (! binding.instance.$t) return key;
      return binding.instance.$t.call(binding.instance, key);
    }

    function createHelper(elType) {
      const width = (elType == 'milestone') ? 70 : 130;
      const helper = $(`<article class="element ${elType}" style="z-index:9999; width:${width}px; background: none">`);
      let content;
      if (elType == 'milestone') {
        content = $(`<div class="fa fa-fw icon-diamonds milestone-default-blue" style="font-size: 16px; min-height: 20px"></div><div style="font-weight:bold">${$t('PLANNING.MILESTONE')}</div>`);
      } else {
        content = $(`<div class="element-title task-default-blue"><div style="font-weight:bold">${$t('PLANNING.NEW_BUBBLE')}</div></div>`);
      }
      helper.append(content);
      return helper;
    }

    element.draggable({
      helper() { return createHelper(type); },
      appendTo: 'body',
      cursorAt,
      cancel: false,
      start(event, ui) {
        if (options.start) options.start(event, ui, type);
      },
      stop(event, ui) {
        if (options.stop) options.stop(event, ui, type);
      },
    });
  },
};

export const inputAutoWidth = {
  mounted(el, binding) {
    const options = binding.value || {};
    el.$span = $('<span style="visibility: hidden; white-space: pre;"></span>');

    el.updateElementWidth = function (text) {
      const element = $(el);
      el.$span.html(text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;'));
      element.append(el.$span);
      element.css('width', el.$span.width() + 30);
      el.$span.remove();
    };
    el.updateElementWidth(options.text);
  },
  updated(el, binding) {
    const options = binding.value || {};
    const oldOptions = binding.oldValue || {};
    if (! options.text || options.text == oldOptions.text) return;
    el.updateElementWidth(options.text);
  },
};

export const fixToElement = {
  mounted(element, binding) {
    const options = binding.value || {};
    let target;

    const updateElementPosition = () => {
      if (! target) return;
      const targetRect = target.getBoundingClientRect();
      if (typeof options.top == 'number') {
        const topbarMarginIfTopbarExists = 'min(var(--v-layout-top), 1px) * 15'; // 0px (if --v-layout-top == 0px) or 15px (navigation pb-4 - 1px for overlap)
        element.style.top = `max(calc(var(--v-layout-top) + ${topbarMarginIfTopbarExists}), ${targetRect.top + options.top}px)`;
      }
      if (typeof options.left == 'number') element.style.left = `${targetRect.left + options.left}px`;
      if (typeof options.right == 'number') element.style.right = `${targetRect.right - targetRect.width + options.right}px`;
      if (typeof options.bottom == 'number') element.style.bottom = `calc(100vh - min(${targetRect.bottom - options.bottom}px, 100vh))`;
    };

    element.style.position = 'fixed';

    setTimeout(() => {
      target = document.querySelector(options.target);
      if (! target) return;
      updateElementPosition();
    });

    setTimeout(() => { // second call to make sure all animations have finished
      if (! target) target = document.querySelector(options.target);
      if (! target) return;
      updateElementPosition();

      // set up observers
      element.onScroll = updateElementPosition;
      window.addEventListener('scroll', updateElementPosition);

      // height observer for target
      element.heightObserver = new ResizeObserver(updateElementPosition);
      element.heightObserver.observe(target);
    }, 1000);
  },
  unmounted(element) {
    if (element.onScroll) window.removeEventListener('scroll', element.onScroll);
    if (element.heightObserver) element.heightObserver.disconnect();
  },
};

export const fixToTop = {
  mounted(element, binding) {
    let offsetTop;
    let startTop;
    let height;
    let width;
    let placeholder;
    const options = binding.value || {};
    const xScrollElement = options.xScroll && document.querySelector(options.xScroll);

    if (options.placeholder) placeholder = element.parentNode.querySelector(`.${options.placeholder}`);
    let elementReady = false;

    function updateClip() {
      if (element.classList.contains('fixed') && xScrollElement) {
        const xscroll = xScrollElement.scrollLeft;
        const marginLeft = xscroll + document.documentElement.scrollLeft;
        element.style.transform = `translate3d(${-marginLeft}px, 0, 0)`;
        element.style['clip-path'] = `polygon(${xscroll}px 0, ${width + xscroll}px 0, ${width + xscroll}px ${height + 10}px, ${xscroll}px ${height + 10}px)`;
      } else {
        element.style.transform = 'none';
        element.style['clip-path'] = 'none';
      }
      if (element.classList.contains('fixed')) {
        element.style.top = `${offsetTop}px`;
      } else {
        element.style.top = null;
      }
    }

    function updateElementVars() {
      const elementHeight = element.offsetHeight;
      if (! elementHeight) return; // planning not visible on screen
      offsetTop = options.topbar && document.querySelector(options.topbar)?.offsetHeight || 0;
      const elementOffsetTop = element.getBoundingClientRect().top + document.documentElement.scrollTop; // element.offset().top
      startTop = Math.max(0, elementOffsetTop - offsetTop);
      height = elementHeight;
      width = xScrollElement ? Math.min(xScrollElement.offsetWidth, element.offsetWidth) : element.offsetWidth;
      if (placeholder) {
        placeholder.style.display = 'none';
        placeholder.style.height = `${height}px`;
      }
      elementReady = height > 0;
    }

    if (xScrollElement) {
      xScrollElement.addEventListener('scroll', updateClip);
    }

    function onScroll() {
      if (! elementReady) return;
      if (document.documentElement.scrollTop > startTop) {
        if (! element.classList.contains('fixed')) updateElementVars();
        element.classList.add('fixed');
        if (placeholder) placeholder.style.display = 'block';
      } else {
        element.classList.remove('fixed');
        if (placeholder) placeholder.style.display = 'none';
      }
      updateClip();
    }

    window.addEventListener('scroll', onScroll);

    function init() {
      updateElementVars();
      if (elementReady) {
        onScroll();
      } else {
        setTimeout(init, 300);
      }
    }
    setTimeout(init);
    element.onScroll = onScroll;
  },
  unmounted(element) {
    window.removeEventListener('scroll', element.onScroll);
  },
};
