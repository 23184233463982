<template>
  <div>
    <h3 style="margin: 0">{{ $t('MONITORING_PLAN.PROJECT') }} : {{ title }}</h3>
    <label class="showhead-label">
      <input v-model="article.showhead" type="checkbox" @change="$emit('update-showhead', $event.target.checked)">
      &nbsp;<span style="vertical-align: top">{{ $t('MONITORING_PLAN.DISPLAY_PROJECT_HEADER') }}</span>
    </label>
    <span class="ml-2"></span>
    <label :class="{ 'text-medium-emphasis': ! article.showhead }" class="showhead-label">
      <input v-model="article.showprojectsheet" :disabled="! article.showhead" type="checkbox" @change="$emit('update-showprojectsheet', $event.target.checked)">
      &nbsp;<span style="vertical-align: top">{{ $t('MONITORING_PLAN.OPEN_AT_STARTUP') }}</span>
    </label>
    <div :class="{ 'text-medium-emphasis': ! article.showhead }" style="margin-left: 4px; margin-top: -4px; cursor: default">
      <span style="vertical-align: text-bottom">{{ $t('PROJECT_SHEET.PROJECT_SHEET') }} : </span>&nbsp;
      <label class="showhead-label">
        <input v-model="projectsheetDisplay.description" :disabled="! article.showhead" type="checkbox" @change="$emit('update-projectsheetdisplay', projectsheetDisplay)">
        &nbsp;<span style="vertical-align: top">{{ $t('PROJECT_SHEET.DESCRIPTION') }}</span>
      </label>
      <label class="showhead-label">
        <input v-model="projectsheetDisplay.steps" :disabled="! article.showhead" type="checkbox" @change="$emit('update-projectsheetdisplay', projectsheetDisplay)">
        &nbsp;<span style="vertical-align: top">{{ $t('PROJECT_SHEET.WAYPOINTS') }}</span>
      </label>
      <label class="showhead-label">
        <input v-model="projectsheetDisplay.files" :disabled="! article.showhead" type="checkbox" @change="$emit('update-projectsheetdisplay', projectsheetDisplay)">
        &nbsp;<span style="vertical-align: top">{{ $t('PROJECT_SHEET.PROJECT_SHEET_FILES') }}</span>
      </label>
      <label class="showhead-label">
        <input v-model="projectsheetDisplay.project_users" :disabled="! article.showhead" type="checkbox" @change="$emit('update-projectsheetdisplay', projectsheetDisplay)">
        &nbsp;<span style="vertical-align: top">{{ $t('PROJECT_SHEET.USERS') }}</span>
      </label>
      <label class="showhead-label">
        <input v-model="projectsheetDisplay.activity" :disabled="! article.showhead" type="checkbox" @change="$emit('update-projectsheetdisplay', projectsheetDisplay)">
        &nbsp;<span style="vertical-align: top">{{ $t('PROJECT_SHEET.ACTIVITY') }}</span>
      </label>
    </div>
    <div class="mt-2">
      <a class="pointer" @click="showLanes = ! showLanes">
        <i :class="showLanes ? 'fa-caret-down' : 'fa-caret-right'" class="fa fa-fw"></i>&nbsp;{{ $t('MONITORING_PLAN.LANE_WATCH') }}
      </a>
    </div>
    <div v-if="showLanes" class="monitoringlanes" style="padding: 0 20px">
      <div style="padding: 12px 10px 0 0; border-bottom: 2px solid #ccc">
        <label><input v-model="checkAllLanes" type="checkbox" @change="toggleAllLanes"> &nbsp; <span style="vertical-align: top">{{ $t('MONITORING_PLAN.ALL_LANES') }}</span></label>
      </div>
      <div v-for="(lane, laneIndex) in lanes" :key="laneIndex" style="display: flex" class="monitoringlane">
        <div style="padding: 12px 10px 0 0">
          <input v-model="lane.checked" type="checkbox" @change="updateLanes">
        </div>
        <div style="font-weight: bold; width:280px; flex-shrink: 0;">
          <div :style="{ 'margin-left': `${(lane.level || 0) * 30}px` }" style="padding: 10px 0; height: 100%; display: flex; flex-direction: column;" class="monitoring-plan-cell">
            <b @click="lane.checked = ! lane.checked; updateLanes()">{{ lane.label || " - " }}</b>
          </div>
        </div>
        <div class="monitoring-plan-cell" style="width: 30px; flex-shrink: 0; display: flex;">
          <div v-if="lane.currentStep && lane.currentStep.mood != 'none'" :class="`mood-icon-${lane.currentStep.mood}`"></div>
        </div>
        <div class="monitoring-plan-cell" style="padding: 10px; flex-grow: 1">
          <div v-if="lane.currentStep && lane.currentStep.description">
            <b>{{ $t('MONITORING_PLAN.WAYPOINT') }} :</b>{{ $filters.truncate(lane.currentStep.description, 35, '...', true) }}
          </div>
        </div>
        <div class="monitoring-plan-cell" style="padding: 10px; flex-grow: 1">
          <div v-if="lane.currentStep && lane.currentStep.issues">
            <b>{{ $t('MONITORING_PLAN.BLOCKING_POINTS') }} :</b> {{ $filters.truncate(lane.currentStep.issues, 35, '...', true) }}
          </div>
        </div>
      </div>
    </div>

    <div class="ml-1 mt-2" style="cursor: default">
      <span style="vertical-align: text-bottom">{{ $t('MONITORING_PROGRESS.TITLE') }} : </span>&nbsp;
      <label class="showhead-label">
        <input v-model="kanbanDisplay.finished" type="checkbox" @change="$emit('update-kanbandisplay', kanbanDisplay)">
        &nbsp;<span style="vertical-align: top">{{ $t('MONITORING_PROGRESS.FINISHED') }}</span>
      </label>
      <label class="showhead-label">
        <input v-model="kanbanDisplay.inprogress" type="checkbox" @change="$emit('update-kanbandisplay', kanbanDisplay)">
        &nbsp;<span style="vertical-align: top">{{ $t('MONITORING_PROGRESS.IN_PROGRESS') }}</span>
      </label>
      <label class="showhead-label">
        <input v-model="kanbanDisplay.overdue" type="checkbox" @change="$emit('update-kanbandisplay', kanbanDisplay)">
        &nbsp;<span style="vertical-align: top">{{ $t('MONITORING_PROGRESS.OVERDUE') }}</span>
      </label>
      <label class="showhead-label">
        <input v-model="kanbanDisplay.future" type="checkbox" @change="$emit('update-kanbandisplay', kanbanDisplay)">
        &nbsp;<span style="vertical-align: top">{{ $t('MONITORING_PROGRESS.FUTURE') }}</span>
      </label>
    </div>
  </div>
</template>

<style scoped>
  .monitoringlanes {
    cursor: default;
  }

  .monitoringlane + .monitoringlane .monitoring-plan-cell {
    border-top: 2px solid #ccc;
  }

  .showhead-label {
    font-weight: normal;
    margin-left: 4px;
    margin-top: 4px;
    margin-bottom: 0;
  }
</style>

<script>
  export default {
    props: {
      article: { type: Object, required: true },
      plannings: { type: Array, required: true },
    },
    emits: ['update-showhead', 'update-showprojectsheet', 'update-projectsheetdisplay', 'update-kanbandisplay', 'update-lanes'],
    data() {
      const planning = this.plannings.filter(item => item.id == this.article.planning_id)[0] || {};
      const lanes = (planning.monitoring_lanes || []).map(lane => ({
        id: lane.id,
        level: lane.level,
        label: lane.label,
        currentStep: (lane.steps || []).at(-1),
        checked: this.article.lanes.indexOf(lane.id) > -1,
      }));
      return {
        title: planning.title || this.$t('PLANNING.UNNAMED_PROJECT'),
        projectsheetDisplay: this.article.projectsheet_display || { description: true, steps: true, files: false, project_users: false, activity: false },
        lanes,
        showLanes: false,
        checkAllLanes: false,
        kanbanDisplay: this.article.kanban_display || { finished: false, inprogress: false, overdue: false, future: false },
      };
    },
    computed: {
    },
    watch: {
      plannings: {
        handler(plannings) {
          const planning = plannings.filter(item => item.id == this.article.planning_id)[0] || {};
          this.title = planning.title || this.$t('PLANNING.UNNAMED_PROJECT');
          this.lanes = (planning.monitoring_lanes || []).map(lane => ({
            id: lane.id,
            level: lane.level,
            label: lane.label,
            currentStep: (lane.steps || []).at(-1),
            checked: this.article.lanes.indexOf(lane.id) > -1,
          }));
        },
        deep: true,
      },
    },
    methods: {
      updateLanes() {
        this.$emit('update-lanes', this.lanes.filter(item => item.checked).map(item => item.id));
      },
      toggleAllLanes() {
        this.lanes.forEach((item) => { item.checked = this.checkAllLanes; });
        this.updateLanes();
      },
    },
  };
</script>
