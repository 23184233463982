<template>
  <div class="mt-6">
    <div class="d-flex align-center" style="column-gap: 24px">
      <div class="flex-1-1-100 text-center">
        <label for="inputMainColor">
          {{ $t('CONFIG.MAIN_COLOR') }}
        </label>
        <v-tooltip :disabled="isPremium" location="bottom">
          <template #activator="{ props }">
            <input id="inputMainColor" v-model="designcolorFirst" :disabled="! isPremium" type="color" class="pointer" v-bind="props" @change="update">
          </template>
          <span>{{ $t('PREMIUM.PREMIUM_FEATURE') }}</span>
        </v-tooltip>
      </div>
      <div class="flex-1-1-100 text-center">
        <label for="inputSecondColor">
          {{ $t('CONFIG.SECOND_COLOR') }}
        </label>
        <v-tooltip :disabled="isPremium" location="bottom">
          <template #activator="{ props }">
            <input id="inputSecondColor" v-model="designcolorSecond" :disabled="! isPremium" type="color" class="pointer" v-bind="props" @change="update">
          </template>
          <span>{{ $t('PREMIUM.PREMIUM_FEATURE') }}</span>
        </v-tooltip>
      </div>
      <v-btn icon class="ml-4" size="small" @click="resetDesignColors"><v-icon>far fa-undo</v-icon></v-btn>
    </div>
    <div class="py-4">
      <label for="logoUrl">{{ $t('CONFIG.CUSTOM_LOGO') }}</label>
      <v-tooltip :disabled="isPremium" location="bottom">
        <template #activator="{ props }">
          <v-text-field id="logoUrl" v-model="logoUrl" :hint="$t('CONFIG.OVERRIDE_LOGO')" :disabled="! isPremium" persistent-hint
                        type="url" placeholder="https://" class="mt-1" v-bind="props"
                        @change="update">
          </v-text-field>
        </template>
        <span>{{ $t('PREMIUM.PREMIUM_FEATURE') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        designcolorFirst: this.planning.config.designcolor.main,
        designcolorSecond: this.planning.config.designcolor.second,
        logoUrl: this.planning.config.logo,
      };
    },
    computed: {
      defaultDesignColor() { return this.$store.getters['users/accessRight/config'].designcolor; },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    methods: {
      resetDesignColors() {
        this.designcolorFirst = this.defaultDesignColor.main;
        this.designcolorSecond = this.defaultDesignColor.second;
        this.update();
      },
      update() {
        this.startChangingConfig();
        this.planning.config.setDesign({
          designcolor: { main: this.designcolorFirst, second: this.designcolorSecond },
          logoUrl: this.logoUrl,
        });
        this.changingConfig();
      },
      ...mapActions('planning/config', ['startChangingConfig', 'changingConfig']),
    },
  };
</script>
