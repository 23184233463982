<template>
  <div class="d-flex align-center">
    <select-filter v-model="selectedStepsList" :items="steps" :label="$t('KANBAN.STEP', 2)" item-title="label" item-value="id"
                   multiple :menu-props="{ offsetY: true }">
      <template #selection="{ index }">
        <span v-if="index === 0">{{ selectedStepsList.length }}/{{ steps.length }}</span>
      </template>
    </select-filter>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      steps: { type: Array, default: () => [] },
    },
    computed: {
      selectedStepsList: {
        get() {
          return this.steps.filter(step => this.processOptions.selectedSteps[step.id]);
        },
        set(newVal) {
          this.steps.forEach((step) => {
            this.processOptions.selectedSteps[step.id] = newVal.includes(step.id);
          });
        },
      },
      ...mapState('multiprojects', ['processOptions']),
    },
    watch: {
      steps: {
        handler() {
          this.steps.forEach((step) => {
            if (! Object.prototype.hasOwnProperty.call(this.processOptions.selectedSteps, step.id)) {
              this.processOptions.selectedSteps[step.id] = true;
            }
          });
        },
        immediate: true,
      },
    },
  };
</script>
