<template>
  <div id="manage" class="pa-4 d-flex">
    <div class="mr-8 responsive-lateral-menu" :class="{ 'is-mobile': $vuetify.display.mobile }" style="width: 250px; flex: 0 0 250px;">
      <div class="overflow-y-auto overflow-x-hidden light-scrollbar overscroll-behavior-y-contain pr-2"
           style="position: fixed; top: calc(var(--v-layout-top) + 16px + 16px); bottom: 16px; width: 250px">
        <div class="mb-1 font-weight-bold text-ellipsis">{{ $t('MANAGE.ACCOUNT') }}</div>
        <tabs-vertical v-model="manageTabIndex" :mandatory="false">
          <v-tab value="company-users">
            <v-icon start>far fa-building</v-icon>
            {{ $t('MANAGE.COMPANY_USERS') }}
          </v-tab>
          <v-tab v-if="hasOrganization" value="company-organization">
            <v-icon start>far fa-sitemap</v-icon>
            {{ $t('MANAGE.ORGANIZATION_USERS') }}
          </v-tab>
          <v-tab value="company-groups">
            <v-icon start>far fa-users</v-icon>
            {{ $t('MANAGE.ACCOUNT_GROUPS') }}
          </v-tab>
          <v-tab v-for="user in openedUsers" :key="user.id" :value="`company-user${user.id}`">
            <user-avatar :user="user" show-username username-format="short" style="margin-inline: -7px 9px"></user-avatar>
            <v-btn-icon size="x-small" style="position: absolute; right: 0" color="darkgrey" @click="closeUser(user)"><v-icon size="large">far fa-times</v-icon></v-btn-icon>
          </v-tab>
        </tabs-vertical>

        <div class="mt-4 mb-1 font-weight-bold text-ellipsis">{{ $t('MANAGE.PROJECTS') }}</div>
        <tabs-vertical v-model="manageTabIndex" :mandatory="false">
          <v-tab value="projects-projects">
            <v-icon start>far fa-folder-open</v-icon>
            {{ $t('MANAGE.PROJECTS') }}
          </v-tab>
          <v-tab v-for="project in openedProjects" :key="project.id" :value="`projects-project${project.id}`" :title="project.title">
            <v-icon start>far fa-file</v-icon>
            {{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}
            <v-btn-icon size="x-small" style="position: absolute; right: 0" color="darkgrey" @click="closeProject(project)"><v-icon size="large">far fa-times</v-icon></v-btn-icon>
          </v-tab>
        </tabs-vertical>

        <div class="mt-4 mb-1 font-weight-bold text-ellipsis">{{ $t('ACCOUNT.PROFILE') }}</div>
        <tabs-vertical v-model="manageTabIndex" :mandatory="false">
          <v-tab value="profile-myprofile">
            <v-icon start>far fa-user</v-icon>
            {{ $t('ACCOUNT.CHANGE_PROFILE') }}
          </v-tab>
          <v-tab value="profile-notifications">
            <v-icon start>far fa-bell</v-icon>
            {{ $t('NOTIFICATIONS.NOTIFICATIONS') }}
          </v-tab>
          <v-tab value="profile-integrations">
            <v-icon start>far fa-link</v-icon>
            {{ $t('ACCOUNT.INTEGRATIONS') }}
          </v-tab>
        </tabs-vertical>

        <template v-if="isAdmin">
          <div class="mt-4 mb-1 font-weight-bold text-ellipsis">{{ $t('MANAGE.CONFIGURATION') }}</div>
          <tabs-vertical v-model="manageTabIndex" :mandatory="false">
            <v-tab value="config-projectsettings">
              <v-icon start style="position: relative; top: 1px">far fa-folder-open</v-icon>
              <i class="far fa-cog bg-secondary rounded-circle" style="position: absolute; left: 12px; top: 4px; font-size: 14px"></i>
              {{ $t('MANAGE.CONFIG.PROJECTSETTINGS') }}
            </v-tab>
            <v-tab value="config-integrations">
              <v-icon start>far fa-share-alt</v-icon>
              {{ $t('MANAGE.CONFIG.INTEGRATIONS') }}
            </v-tab>
            <v-tab value="config-tools">
              <v-icon start>far fa-broom</v-icon>
              {{ $t('MANAGE.CONFIG.TOOLS') }}
            </v-tab>
          </tabs-vertical>
        </template>
      </div>
    </div>

    <div class="flex-grow-1" style="min-height: 70vh">
      <!-- TAB USERS -->
      <manage-users v-show="section == 'company'"></manage-users>

      <!-- TAB PROJECTS-->
      <manage-projects v-show="section == 'projects'"></manage-projects>

      <!-- TAB PROFILE -->
      <manage-profile v-show="section === 'profile'"></manage-profile>

      <!-- TAB CONFIGURATION -->
      <keep-alive>
        <manage-config v-if="section == 'config'"></manage-config>
      </keep-alive>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ManageUsers from './ManageUsers/ManageUsers.vue';
  import ManageProjects from './ManageProjects/ManageProjects.vue';
  import ManageProfile from './ManageProfile/ManageProfile.vue';
  import ManageConfig from './ManageConfig/ManageConfig.vue';

  export default {
    components: {
      ManageUsers,
      ManageProjects,
      ManageProfile,
      ManageConfig,
    },
    data() {
      return {
        manageTabIndexAllowedValues: [
          'company-users',
          'company-organization',
          'company-groups',
          'projects-projects',
          'profile-myprofile',
          'profile-notifications',
          'profile-integrations',
          'config-projectsettings',
          'config-integrations',
          'config-tools',
        ],
      };
    },
    computed: {
      manageTabIndex: {
        get() {
          return this.$store.state.manage.manageTabIndex;
        },
        set(newVal) {
          this.$store.state.manage.manageTabIndex = newVal;
        },
      },
      section() {
        return (this.manageTabIndex || '').split('-')[0];
      },
      isAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      hasOrganization() { return this.$store.state.users.accessRight.hasOrganization; },
      ...mapState('manage', ['dataLoaded', 'projects', 'openedProjects', 'openedUsers']),
    },
    watch: {
      $route: {
        handler() {
          if (this.$route.query.open) {
            setTimeout(() => {
              this.$store.dispatch('manage/openProjectFromId', this.$route.query.open);
              this.$router.replace({ query: {}, hash: `#${this.manageTabIndex}` });
              $(window).scrollTop(0);
            }, 300);
            return;
          }

          const newHash = this.$route.hash.replace('#', '');
          this.setManageTabIndexFromString(newHash);
          $(window).scrollTop(0);
        },
        immediate: true,
      },
      manageTabIndex(newVal, oldVal) {
        if (this.$route.query.open) return; // handle query first
        if (! newVal && oldVal) this.manageTabIndex = oldVal; // prevent empty tab when clicking on selected tab
        if (newVal) this.$router.replace({ hash: `#${newVal}` });
      },
    },
    created() {
      this.$store.dispatch('manage/loadCompany');
      this.$store.dispatch('manage/loadProjects');
    },
    beforeUnmount() {
      this.$store.commit('eventbus/remove'); // remove all events listeners
      this.$store.commit('manage/reset');
    },
    methods: {
      setManageTabIndexFromString(newVal) {
        if (this.manageTabIndexAllowedValues.includes(newVal)) {
          this.manageTabIndex = newVal;
        } else if (/^company-user\d+$/.test(newVal)) {
          this.$store.dispatch('manage/openUserFromId', newVal.replace('company-user', ''));
        } else if (/^projects-project\d+$/.test(newVal)) {
          this.$store.dispatch('manage/openProjectFromId', newVal.replace('projects-project', ''));
        } else if (newVal.startsWith('company')) {
          this.manageTabIndex = 'company-users';
        } else if (newVal.startsWith('projects')) {
          this.manageTabIndex = 'projects-projects';
        } else if (newVal.startsWith('profile')) {
          this.manageTabIndex = 'profile-myprofile';
        } else if (newVal.startsWith('config')) {
          this.manageTabIndex = 'config-projectsettings';
        } else {
          this.manageTabIndex = this.manageTabIndexAllowedValues[0];
        }
      },
      closeUser(user) {
        setTimeout(() => {
          const userIndex = this.openedUsers.indexOf(user);
          if (userIndex != -1) this.openedUsers.splice(userIndex, 1);
          if (this.openedUsers[userIndex]) {
            this.manageTabIndex = `company-user${this.openedUsers[userIndex].id}`;
          } else if (this.openedUsers[userIndex - 1]) {
            this.manageTabIndex = `company-user${this.openedUsers[userIndex - 1].id}`;
          } else {
            this.manageTabIndex = `company-users`;
          }
        });
      },
      closeProject(project) {
        setTimeout(() => {
          const projectIndex = this.openedProjects.indexOf(project);
          if (projectIndex != -1) this.openedProjects.splice(projectIndex, 1);
          if (this.openedProjects[projectIndex]) {
            this.manageTabIndex = `projects-project${this.openedProjects[projectIndex].id}`;
          } else if (this.openedProjects[projectIndex - 1]) {
            this.manageTabIndex = `projects-project${this.openedProjects[projectIndex - 1].id}`;
          } else {
            this.manageTabIndex = `projects-projects`;
          }
        });
      },
    },
  };
</script>
