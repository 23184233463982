export default [
  "ABIES",
  "ACOUSTEX",
  "ACOUSTIC DIA",
  "ACWA PAYSAGISTES",
  "ADEV ENVIRONNEMENT",
  "AEPE GINGKO",
  "AGENCE FOLLEA - GAUTIER",
  "AGENCE RADAR PAYSAGISTES",
  "ALHYANGE",
  "ALISE ENVIRONNEMENT",
  "ALTHIS",
  "AMIKIRO",
  "AMURE",
  "ARC EN TERRE",
  "ATELIER AVENA",
  "ATELIER DE L'ILE",
  "ATELIER DE L'ISTHME",
  "ATELIER DE PAYSAGE BERTRAND MASSE",
  "ATELIER DES AMENITES",
  "ATELIER DES PAYSAGES",
  "ATELIER MAA",
  "ATELIER MATHILDE MARTIN PAYSAGISTE",
  "ATELIER TANGENTE",
  "ATER ENVIRONNEMENT",
  "AUDDICE / AIRELE",
  "AUTAN ENVIRONNEMENT",
  "AXECO",
  "BCEOM",
  "BEGEAT",
  "BIOTOPE",
  "BLEU PAROLES",
  "BOCAGE / EPURE",
  "BRACE INGENIERIE",
  "BURGEAP",
  "CABINET ECTARE",
  "CAEI",
  "CALIDRIS",
  "CARAMBOLE",
  "CEOB - L'AILE BRISEE",
  "CERA ENVIRONNEMENT",
  "CERE",
  "CESAME ENVIRONNEMENT",
  "CHAMP LIBRE",
  "CORIEAULYS",
  "CPIE MAYENNE BAS MAINE",
  "CPIE VALLEE DE LA SOMME",
  "CREMEL ET GUITTON",
  "DAMEE, VALLET ET ASSOCIES PAYSAGISTES",
  "DELHOM ACOUSTIQUE",
  "DERVENN",
  "DESORMEAUX RENNES",
  "DESPAYSAGES",
  "DEWI",
  "ECE ENVIRONNEMENT",
  "ECHOPSY",
  "ECOLOR",
  "ECOSPHERE",
  "ECO-STRATEGIE",
  "ECOSYSTEMES",
  "ECR ENVIRONNEMENT",
  "ENCIS ENVIRONNEMENT",
  "ENET DOLOWY",
  "ENVIROSCOP",
  "ENVOL",
  "EOLE PAYSAGE",
  "EOLETECH",
  "EPYCART",
  "EREA",
  "EREA INGENIERIE",
  "ERELIS",
  "ETD (ENERGIES ET TERRITOIRES DEVELOPPEMENT)",
  "EURO2C",
  "EXEN",
  "EXEN",
  "FOLIUS ECOPAYSAGE",
  "FOUILLET ECOLOGIE",
  "GAMBA ACOUSTIQUE",
  "GARRAD HASSAN",
  "GEODEVELOPPEMENT",
  "GEOKOS CONSULTANTS",
  "GEONOMIE",
  "GEOVISION",
  "GINGER ENVIRONNEMENT",
  "GMHL (GROUPE MAMMALOGIQUE ET HERPETOLOGIQUE DU LIMOUSIN)",
  "GMN (GROUPE MAMMOLOGIQUE NORMAND)",
  "GODS (GROUPE ORNITHOLOGIQUE DES DEUX-SEVRES)",
  "GONM (GROUPE ORNITHOLOGIQUE NORMAND)",
  "GROUPE ORNITHOLOGIQUE PICARD",
  "IDYLLIS",
  "IEA (INSTITUT D'ECOLOGIE APPLIQUEE)",
  "IMPACT ET ENVIRONNEMENT",
  "INGEROP",
  "INGETEC",
  "IREG",
  "IXSANE",
  "JACQUEL ET CHATILLON",
  "JLBI CONSEILS",
  "KJM CONSEIL ENVIRONNEMENT",
  "ATELIER COUASNON",
  "LIONEL JACQUEY PAYSAGISTE",
  "LOIRET NATURE ENVIRONNEMENT",
  "LPO",
  "LPO ANJOU",
  "LPO ILE-DE-FRANCE",
  "LPO VENDEE",
  "MATUTINA",
  "NCA ENVIRONNEMENT",
  "NEOMYS",
  "NICOLAS ARTEMON",
  "OCTA PAYSAGE",
  "OGE (OFFICE DE GENIE ECOLOGIQUE)",
  "ONF (OFFICE NATIONAL DES FORETS)",
  "ORA ENVIRONNEMENT",
  "ORFEA",
  "ORNITHOBS",
  "OSER",
  "OUEST'AM",
  "PHILIPPE LUSTRAT",
  "PHOTOMONTAGES DU NORD",
  "PICARDIE NATURE",
  "RENARD",
  "SAUNIER ET ASSOCIES",
  "SCIENCES ENVIRONNEMENT",
  "SECHAUD ENVIRONNEMENT",
  "SIXENSE / SOLDATA ACOUSTIC",
  "SEPOL (SOCIETE POUR L'ETUDE ET LE PROTECTION DES OISEAUX EN LIMOUSIN)",
  "SOMIVAL",
  "TAUW ENVIRONNEMENT",
  "THEMA ENVIRONNEMENT",
  "VALERIE ZABORSKI PAYSAGISTE",
  "VENATHEC",
  "VU D'ICI",
  "wpd CARTOGRAPHIE",
  "wpd DESSINATEURS",
  "wpd GENIE CIVIL",
  "wpd JURIDIQUE",
  "wpd RACCORDEMENT",
  "wpd VERIF FONCIERE",
];
