<template>
  <div class="element-details-alert mt-2">
    <div v-for="(alert, alertIndex) in el.alerts" class="hovervisible-activator d-flex align-start mb-1">
      <v-icon size="small" class="mr-2 mt-1" style="color: inherit">far fa-alarm-exclamation</v-icon>
      <div>
        {{ $t('GLOBAL.DAYS_COUNT', alert.days) }}
        {{ $t(alert.relative == 'before' ? 'ELEMENT-DETAIL.BEFORE' : 'ELEMENT-DETAIL.AFTER') }}
        {{ $t(alert.relativeto == 'starttime' ? 'ELEMENT-DETAIL.START' : 'ELEMENT-DETAIL.END') }}
        {{ $t('ELEMENT-DETAIL.AT') }} {{ alert.hour }}{{ $t('ELEMENT-DETAIL.OCLOCK') }}
        <small class="ml-1 font-italic">({{ alert.getSendDate().format('llll') }})</small>
        <v-switch v-model="alert.sendothers" :label="$t('ELEMENT-DETAIL.SEND_OTHERS')" class="shrinked-component" tabindex="-1"
                  @click="toggleAlertSendothers(alert)">
        </v-switch>
      </div>
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-btn-icon size="small" color="errorred" class="hovervisible-item ml-auto" v-bind="props" @click="removeAlert(alertIndex)">
            <v-icon>far fa-trash-alt</v-icon>
          </v-btn-icon>
        </template>
        <span>{{ $t('ELEMENT-DETAIL.REMOVE_ALERT') }}</span>
      </v-tooltip>
    </div>
    <div class="d-flex align-center">
      <v-text-field v-model.number="newalert.days" type="number" min="0" max="99" style="min-width: min-content; max-width: 55px"></v-text-field>
      <span class="mx-1">{{ $t('GLOBAL.DAYS', newalert.days) }}</span>
      <v-select v-model="newalert.relative" :items="[{ title: $t('ELEMENT-DETAIL.BEFORE'), value: 'before' }, { title: $t('ELEMENT-DETAIL.AFTER'), value: 'after' }]">
      </v-select>
      <v-select v-if="! el.isType('milestone')" v-model="newalert.relativeto"
                :items="[{ title: $t('ELEMENT-DETAIL.START'), value: 'starttime' }, { title: $t('ELEMENT-DETAIL.END'), value: 'endtime' }]"
                class="ml-1">
      </v-select>
      <span class="mx-1">{{ $t('ELEMENT-DETAIL.AT') }}</span>
      <v-text-field v-model.number="newalert.hour" type="number" min="0" max="23" style="min-width: min-content; max-width: 55px"></v-text-field>
      <span class="ml-1">{{ $t('ELEMENT-DETAIL.OCLOCK') }}</span>
    </div>
    <div class="align-center mt-2" style="display: flex">
      <strong class="mx-2">↪</strong>{{ newalertTime }}
      <v-btn color="primary" size="small" rounded class="ml-auto" @click="addAlert()">{{ $t('ELEMENT-DETAIL.ADD') }}</v-btn>
    </div>
  </div>
</template>

<style>
  .element-details-alert .v-input--switch, .element-details-alert .v-input--switch .theme--light.v-label {
    color: #333;
    font-size: inherit;
  }
</style>

<script>
  import Alert from '@/models/Alert';

  export default {
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        newalert: new Alert(this.el),
      };
    },
    computed: {
      newalertTime() {
        return this.newalert.getSendDate().format('llll');
      },
    },
    watch: {
      newalert: {
        handler() {
          this.newalert.updateEl(this.el);
        },
        deep: true,
      },
      'el.data.starttime': function () {
        this.newalert.updateEl(this.el);
      },
      'el.data.endtime': function () {
        this.newalert.updateEl(this.el);
      },
    },
    methods: {
      removeAlert(index) {
        if (index > -1 && index < this.el.alerts.length) {
          this.el.alerts[index].remove();
          this.el.alerts.splice(index, 1);
        }
      },
      toggleAlertSendothers(alert) {
        alert.save();
      },
      addAlert() {
        const newAlert = angular.copy(this.newalert);
        if (! this.el.alerts) this.el.alerts = [];
        this.el.alerts.push(newAlert);
        newAlert.save();
      },
    },
  };
</script>
