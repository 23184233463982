<template>
  <v-toolbar density="compact" class="toolbar-scrollable mb-4">
    <template v-if="filters.company">
      <btn-toggle-filter v-model="filters.company" mandatory="force">
        <v-btn :title="$t('PROJECTS.FILTER_MY_COMPANY')" value="mine">
          {{ $t('PROJECTS.MY_COMPANY') }}
        </v-btn>
        <v-btn :title="$t('PROJECTS.FILTER_ORGANIZATION')" value="all">
          {{ $t('PROJECTS.ORGANIZATION') }}
        </v-btn>
      </btn-toggle-filter>
      <select-filter v-if="filters.company == 'all'" v-model="filters.company_id" :items="organizationCompanies"
                     item-title="name" item-value="id" class="ml-2" style="flex-basis: 100px; max-width: 250px">
      </select-filter>
      <div class="mx-2" style="flex: 0 1 16px"></div>
    </template>
    <btn-toggle-filter v-model="filters.display" mandatory="force">
      <v-btn :title="$t('PROJECTS.VIEW_GRID')" value="grid">
        <v-icon>far fa-th</v-icon>
      </v-btn>
      <v-btn :title="$t('PROJECTS.VIEW_TABLE')" value="list">
        <v-icon>far fa-list-ul</v-icon>
      </v-btn>
    </btn-toggle-filter>
    <div class="mx-2"></div>
    <btn-toggle-filter v-model="filters.sort" mandatory="force">
      <v-btn :title="$t('PROJECTS.SORT_BY_MODIFICATION')" value="-date_of_modification">
        <v-icon>far fa-clock</v-icon>
      </v-btn>
      <v-btn :title="$t('PROJECTS.SORT_BY_TITLE_ASC')" value="title">
        <v-icon>far fa-sort-alpha-down</v-icon>
      </v-btn>
      <v-btn :title="$t('PROJECTS.SORT_BY_TITLE_DESC')" value="-title">
        <v-icon>far fa-sort-alpha-up</v-icon>
      </v-btn>
    </btn-toggle-filter>
    <div class="mx-2" style="flex: 0 1 16px"></div>
    <template v-if="projectCategories.length">
      <autocomplete-filter v-model="filters.categories" :items="projectCategories" :label="$t('PROJECTS.CATEGORY', 2)"
                           prepend-inner-icon="far fa-tags"
                           multiple clearable class="ml-auto" style="flex-basis: 150px; max-width: 250px">
        <template #selection="{ item: { value }, index }">
          <project-category v-if="filters.categories.length == 1" :category="value" class="pr-2" style="max-width: 100%; margin: -2px"></project-category>
          <span v-else-if="index === 0" class="pr-2">{{ filters.categories.length }}/{{ projectCategories.length }}</span>
        </template>
        <template #item="{ props, item: { value } }">
          <v-list-item v-bind="props">
            <template #title>
              <project-category :category="value" pointer v-bind="props"></project-category>
            </template>
          </v-list-item>
        </template>
      </autocomplete-filter>
      <div class="mx-2"></div>
    </template>
    <combobox-filter v-model="filters.title" :items="filteredProjetsTitles" :label="$t('PROJECTS.SEARCH_PROJECT_TITLE')"
                     prepend-inner-icon="far fa-search" clearable
                     style="flex-basis: 150px; max-width: 250px">
    </combobox-filter>
    <div class="mx-2"></div>
    <select-user v-model="filters.user" :items="projectOwners" :label="$t('PROJECTS.SEARCH_PROJECT_OWNER')" filter
                 style="flex-basis: 150px; max-width: 250px">
    </select-user>
  </v-toolbar>
</template>

<script>
  import ProjectCategory from '@/components/Projects/ProjectCategory';
  import HomeProjectsMixin from './HomeProjectsMixin';

  export default {
    components: {
      ProjectCategory,
    },
    mixins: [HomeProjectsMixin],
    props: {
      filters: { type: Object, required: true },
      projects: { type: Array, default: () => ([]) },
    },
    computed: {
      projectOwners() {
        return (this.filters.company == 'all' ? this.organizationUsers : this.companyUsers).filter(user => this.projects.find(item => item.owner_id == user.id))
          .map(user => ({
            id: user.id,
            avatar: user.avatar,
            username: this.$store.getters['users/getUsername'](user),
          }));
      },
      companyUsers() { return this.$store.getters['users/getCompanyUsers']; },
      organizationUsers() { return this.$store.getters['users/getOrganizationUsers']; },
      organizationCompanies() {
        const { organization } = this.$store.state.users.user;
        if (! organization) return [];
        const mylevel = (organization.getCompanies().find(item => item.id == this.$store.state.users.user.company_id) || {}).level;
        return [{ id: null, name: this.$t('GLOBAL.ALL') }].concat(organization.getCompanies().filter(item => item.level >= mylevel));
      },
      filteredProjetsTitles() {
        const titles = new Set();
        this.projects.forEach((project) => {
          if (project.title && this.filterProjectByCompany(project) && this.filterProjectByUser(project)) titles.add(project.title);
        });
        return [...titles];
      },
      projectCategories() {
        const projectCategories = _.union(
          ...this.projects.map(project => project.category && project.category.split(',')),
          this.filters.categories || [], // add categories saved in filter
        );
        return projectCategories.sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
      },
    },
  };
</script>
