import DebugSrv from '@/components/Debug/DebugSrv';
import { loadRemoteScript } from './helpers';

/** ******** */
/*  STRIPE  */
/** ******** */
function initLoad(stripeConfig) {
  return loadRemoteScript('https://js.stripe.com/v3/', 'lib-stripe', { removeOnError: true }).then(() => {
    let stripeKey = 'pk_live_WzXkaTeYtwqEh1PUC357fQus';
    if (DebugSrv.local) {
      stripeKey = 'pk_test_ZSvv85IquUi9RIVLObsc4lmw';
    }
    // Initialize Stripe
    const stripeData = {};
    stripeData.stripe = window.Stripe(stripeKey);
    const elements = stripeData.stripe.elements();

    Object.keys(stripeConfig).forEach((key) => {
      if (key == 'cvc') {
        stripeData[key] = elements.create(stripeConfig[key], { placeholder: '123' });
      } else if (key == 'cardNumber') {
        stripeData[key] = elements.create(stripeConfig[key], { showIcon: true, disableLink: true }); // showIcon shoud show card brand icon but is not working...
      } else {
        stripeData[key] = elements.create(stripeConfig[key]);
      }
      stripeData[key].mount(`#${key}`);
    });

    return stripeData;
  });
}

export default {
  initLoad,
};
