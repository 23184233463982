export default {
  data() {
    return {
      tableWrapper: null,
      pos: { left: 0, x: 0, velX: 0 },
      momentumID: null,
    };
  },
  mounted() {
    this.tableWrapper = document.getElementById('table-wrapper');
    this.$el.addEventListener('mousedown', this.dragScroll_mouseDownHandler);
  },
  unmounted() {
    this.$el.removeEventListener('mousedown', this.dragScroll_mouseDownHandler);
    this.dragScroll_cancelMomentumTracking();
  },
  methods: {
    dragScroll_mouseDownHandler(e) {
      if (e.button) return; // left click only

      this.$el.style.cursor = 'grabbing';
      this.$el.style.userSelect = 'none';

      _.extend(this.pos, {
        left: this.tableWrapper.scrollLeft,
        x: e.clientX, // current mouse position
        velX: 0,
      });

      document.addEventListener('mousemove', this.dragScroll_mouseMoveHandler);
      document.addEventListener('mouseup', this.dragScroll_mouseUpHandler);
    },
    dragScroll_mouseMoveHandler(e) {
      const dx = e.clientX - this.pos.x; // how far the mouse has been moved

      // Scroll velocity
      this.pos.velX = (this.pos.left - dx) - this.tableWrapper.scrollLeft;
      this.pos.time = Date.now();

      this.tableWrapper.scrollLeft = this.pos.left - dx; // scroll
    },
    dragScroll_mouseUpHandler() {
      this.$el.style.cursor = 'grab';
      this.$el.style.removeProperty('user-select');

      document.removeEventListener('mousemove', this.dragScroll_mouseMoveHandler);
      document.removeEventListener('mouseup', this.dragScroll_mouseUpHandler);

      if (Date.now() - this.pos.time < 300) this.dragScroll_beginMomentumTracking(); // start slowing down
    },

    dragScroll_beginMomentumTracking() {
      if (Math.abs(this.pos.velX) < 3) return;
      this.dragScroll_cancelMomentumTracking();
      this.momentumID = requestAnimationFrame(this.dragScroll_momentumLoop);

      // listen for mouse events to stop the drag momentum loop
      window.addEventListener('scroll', this.dragScroll_cancelMomentumTracking);
      window.addEventListener('mousedown', this.dragScroll_cancelMomentumTracking);
    },
    dragScroll_cancelMomentumTracking() {
      cancelAnimationFrame(this.momentumID);
      window.removeEventListener('scroll', this.dragScroll_cancelMomentumTracking);
      window.removeEventListener('mousedown', this.dragScroll_cancelMomentumTracking);
    },
    dragScroll_momentumLoop() {
      this.tableWrapper.scrollLeft += this.pos.velX; // apply the velocity to the scroll position
      this.pos.velX *= 0.95; // slow the velocity slightly
      if (Math.abs(this.pos.velX) > 0.5) { // still moving ?
        this.momentumID = requestAnimationFrame(this.dragScroll_momentumLoop); // keep looping
      }
    },
  },
};
