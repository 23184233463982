import clientConfig from '@/client_customs/config';
import constants from '@/js/constants.js';
import { getDefaultLane } from './helpers/defaultValues';
import helpers from './helpers/planningInitHelpers';

class PlanningLane {
  constructor(planning, srcData) {
    const data = angular.copy(srcData) || {};

    if (data instanceof PlanningLane) {
      _.extend(this, data);
    } else {
      helpers.updateLastVersionsLanes(data);
      angular.merge(this, getDefaultLane(), data);
    }

    this.getPlanning = function () {
      return planning;
    };
  }

  getAll() {
    const nonNullData = {};
    const excludedKeys = ['height', 'contentHeight', 'getPlanning', 'filteredout'];
    Object.keys(this).forEach((key) => { if (this[key] !== null && ! excludedKeys.includes(key)) nonNullData[key] = this[key]; });
    return _.extend({ id: this.id }, nonNullData);
  }

  set(data) {
    angular.merge(this, data);
    return this;
  }

  getLabelStyle() {
    return this.labelStyle;
  }
  setLabelStyle(newval) {
    this.labelStyle = newval;
    return this;
  }

  getColorId() {
    return this.color;
  }
  setColorId(value) {
    this.color = (typeof value == 'number') ? value : 0;
    return this;
  }
  getColorShadeId() {
    return this.colorShade;
  }
  setColorShadeId(value) {
    this.colorShade = (typeof value == 'number') ? value : '';
    return this;
  }

  getHidden() {
    return this.hidden;
  }
  setHidden(newval) {
    this.hidden = newval;
    return this;
  }

  isVisible() {
    return ! this.filteredout;
  }

  /** ******** */
  /* DISPLAY */
  /** ******** */
  getColorClass() {
    return `el-color-${this.getColorId()}-${this.getColorShadeId()}`;
  }

  static getAllYposition(els) {
    const yPositions = new Map();
    if (! els || ! els.length) return yPositions;
    const sortedEls = els.filter(el => el.visible && el.hasDates()).sort((a, b) => {
      if ((a.getPriority() || 0) != (b.getPriority() || 0)) { // higher priority at top
        return (a.getPriority() || 0) > (b.getPriority() || 0) ? -1 : 1;
      }
      if (a.getType() !== b.getType()) { // milestones at top, then macros
        if (b.isType('milestone')) return 1;
        if (a.isType('milestone', 'macro')) return -1;
        return 1;
      }
      return a.getStartTime().isSameOrAfter(b.getStartTime()) ? 1 : -1;
    });
    const prevEls = [];
    const { lanePadding, elementTopMargin, milestoneIconHeight } = constants;
    sortedEls.forEach((el) => {
      let ytextposition = milestoneIconHeight; // relative to milestone icon top
      let yposition = (el.isType('milestone') ? milestoneIconHeight : 0) + (lanePadding - elementTopMargin); // relative to lane top
      prevEls.sort((a, b) => yPositions.get(a.id).yposition - yPositions.get(b.id).yposition); // Possible colliders from top to bottom
      for (let index = 0; index < prevEls.length; index++) {
        const prevEl = prevEls[index];
        let prevElWidth = prevEl.getWidth();
        if (clientConfig.elements?.miniElementFullTitle && prevElWidth <= constants.taskMinWidth) {
          // width to consider for collision must include the title written on planning
          prevElWidth = 21 + 4.77 * prevEl.getTitle().length; // rough estimation of the width of the dot + title
        }
        if ((prevEl.xposition <= el.xposition && prevEl.xposition + prevElWidth >= el.xposition) || (el.xposition <= prevEl.xposition && el.xposition + el.getWidth() >= prevEl.xposition)) {
          // Collider
          if ((prevEl.getType() == el.getType()) && (yposition + el.height + 2 * elementTopMargin <= yPositions.get(prevEl.id).yposition)) break; // free gap
          yposition = Math.max(yposition, yPositions.get(prevEl.id).yposition + prevEl.height);

          if (el.isType('milestone')) {
            if (prevEl.isType('milestone')) { // milestone grouping
              ytextposition = Math.max(ytextposition, yPositions.get(prevEl.id).ytextposition + prevEl.height + elementTopMargin);
            } else { // milestone under task : add space for icon
              yposition += milestoneIconHeight;
            }
          }
        }
      }
      yPositions.set(el.id, {
        yposition: yposition + elementTopMargin,
        ytextposition: el.isType('milestone') ? ytextposition : undefined,
      });
      prevEls.push(el);
    });
    return yPositions;
  }
}

export default PlanningLane;
