<template>
  <v-btn :disabled="typeof initialState.timeBarColorId != 'number'" block size="small" rounded variant="outlined" color="errorred"
         @mouseover="updateTimebar('')" @mouseout="resetTimebar()" @click="applySelection()">
    {{ $t('COLORPICKER.REMOVE_VERTICAL_MARKER') }}
  </v-btn>
  <div class="pt-2 d-flex align-center flex-wrap">
    <div v-for="colorIndex in [0, 1, 2, 3, 4, 5, 6, 7]" :class="[`el-color-${colorIndex}- border-el-color`]" class="timebar-color-block ma-1"
         @mouseover="updateTimebar(colorIndex)" @mouseout="resetTimebar()" @click="applySelection()">
      <div class="bg-el-color flex-center-center">
        <v-icon v-if="initialState.timeBarColorId === colorIndex" class="mt-.5">far fa-check</v-icon>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .timebar-color-block {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-left: 1px solid;
    border-right: 1px solid;

    > div {
      height: 100%;
      opacity: .45;
    }
  }
</style>

<script>
  export default {
    props: {
      timeBarColorId: { required: true }, // v-model
      target: { type: Object, required: true }, // element or lane or kanbanHeader
    },
    emits: ['update:timeBarColorId', 'selected'],
    computed: {
      initialState() {
        return {
          timeBarColorId: this.target.getTimeBar?.().colorId,
        };
      },
    },
    methods: {
      updateTimebar(colorIndex) {
        this.$emit('update:timeBarColorId', colorIndex);
      },
      resetTimebar() {
        this.updateTimebar(this.initialState.timeBarColorId);
      },
      applySelection() {
        this.target.setTimeBar(typeof this.timeBarColorId == 'number' ? { colorId: this.timeBarColorId } : null);
        this.$emit('selected');
      },
    },
  };
</script>
