<template>
  <div v-if="label && ! filter" class="mb-1"><label :for="inputId">{{ label }}</label></div>
  <component :is="filter ? 'AutocompleteFilter' : 'VAutocomplete'" :id="inputId" v-bind="$attrs" :model-value="modelValue" :items="dataList" :item-title="getListItemLabel" return-object clearable
             :label="filter && label || null"
             @update:model-value="$emit('update:modelValue', $event)">
    <template v-if="! noIcon" #prepend-inner>
      <user-avatar v-if="modelValue" :user="modelValue" :size="24"></user-avatar>
      <v-icon v-else>far fa-user</v-icon>
    </template>
    <template #item="{ props, item: { raw: item } }">
      <v-divider v-if="item.divider"></v-divider>
      <v-list-subheader v-else-if="item.header" :title="item.header"></v-list-subheader>
      <v-list-item v-else v-bind="props">
        <template #prepend>
          <user-avatar :user="item" :size="32"></user-avatar>
        </template>
        <template #append>
          <user-workload-indicator v-if="hasUserWorkloadIndicator && workloads && (item.id || item.group_id || item.username)" :user="item" :starttime="workloads.starttime" :endtime="workloads.endtime"></user-workload-indicator>
        </template>
      </v-list-item>
    </template>
  </component>
</template>

<script>
  import { VAutocomplete } from 'vuetify/components';
  import UserWorkloadIndicator from '@/components/Workload/UserWorkloadIndicator';

  export default {
    components: {
      VAutocomplete,
      UserWorkloadIndicator,
    },
    inheritAttrs: false,
    props: {
      modelValue: { default: null },
      items: { type: Array, default: null },
      label: { type: String, default: '' },
      excludes: { type: Array, default: () => [] },
      workloads: { type: Object, default: null },
      withGroups: { type: Boolean, default: false },
      withTextUsers: { type: Boolean, default: false },
      noIcon: { type: Boolean, default: false },
      filter: { type: Boolean, default: false }, // style as filter
    },
    emits: ['update:modelValue'],
    data() {
      return {
        selectedUser: null,
        newUserTypeahead: null,
        inputId: `selectuser${this.$.uid}`,
      };
    },
    computed: {
      companyUsers() {
        if (this.items) return this.items;
        return this.$store.getters['users/getOrganizationUsers'];
      },
      dataList() {
        let dataList = this.companyUsers.filter(user => ! this.excludes.includes(user.id));
        if (this.withGroups && this.groups) {
          const groupsList = this.groups.map(item => ({
            group_id: item.id,
            title: item.title,
            icon: 'far fa-users',
          }));
          dataList = groupsList.concat(dataList);
        }
        if (this.isBusiness && this.withTextUsers) {
          const { textUsers } = this.$store.getters['users/accessRight/config'];
          if (textUsers) dataList = dataList.concat(textUsers.sort((a, b) => (a.username < b.username ? -1 : 1)));
        }
        return dataList;
      },
      groups() {
        return this.canAssignGroups && this.$store.getters['users/groups/getCompanyGroups'];
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      canAssignGroups() { return this.$store.state.users.accessRight.canAssignGroups; },
      hasUserWorkloadIndicator() { return this.$store.state.users.accessRight.hasUserWorkloadIndicator; },
    },
    methods: {
      getListItemLabel(item) {
        if (item.group_id) return item.title;
        if (item.id) return this.$store.getters['users/getUsername'](item);
        return item.username;
      },
    },
  };
</script>
