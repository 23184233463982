export default {
  // sso: {
    // protocol: 'openIDConnect',
    // login: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=cecbf658-70d8-47b8-b387-b060679cc91a&response_type=code&scope=openid%20email%20profile&redirect_uri=http%3A%2F%2Flocalhost%3A8081',
    // response_type: 'code',

    // protocol: 'PKCE',
    // login: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=7a798761-28c5-4382-8192-48b131d68105&response_type=code&scope=openid%20email%20profile&redirect_uri=http%3A%2F%2Flocalhost%3A8081',
    // response_type: 'code',
    // validate: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
    // client_id: '7a798761-28c5-4382-8192-48b131d68105',
    // redirect_uri: 'http%3A%2F%2Flocalhost%3A8081',

  //   protocol: 'jwt',
  //   login: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=7a798761-28c5-4382-8192-48b131d68105&response_type=id_token&scope=openid%20email%20profile&redirect_uri=http%3A%2F%2Flocalhost%3A8081&nonce=1234',
  //   response_type: 'id_token',
  // },
};
