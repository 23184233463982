/** ******** */
/* MUTATIONS */
/** ******** */
function commitAdd(selection, elId) {
  selection.push(elId);
}

function commitRemove(selection, elId) {
  const index = selection.indexOf(elId);
  if (index > -1) selection.splice(index, 1);
}

function commitEmpty(selection) {
  if (selection.length) selection.splice(0, selection.length); // do not update components when already empty
}

/** ******** */
/* ACTIONS */
/** ******** */
function resetSelection({ commit, rootState }, newsel) {
  if (rootState.ui.planning.doNotResetSelection) return;
  // Newsel array containing new values for selection
  commit('empty');
  if (newsel) {
    newsel.forEach((elId) => {
      commit('add', elId);
    });
  }
}

function duplicateSelection({ state: selection, rootState, dispatch }) {
  const duplicateTab = [];
  const lane = rootState.planning.lanes[0];

  for (let i = 0; i < selection.length; i++) {
    const id = selection[i];
    const el = rootState.planning.elements.find(item => item.id == id);
    if (el) duplicateTab.push({ type: el.getType(), lane, el });
  }

  dispatch('planning/elements/addElements', { argstab: duplicateTab }, { root: true }).then((newels) => {
    const newselect = newels.map(newel => newel.id);
    dispatch('resetSelection', newselect);
  });
}

function deleteSelection({ state: selection, rootState, dispatch }) {
  const deleteTab = [];
  for (let i = 0; i < selection.length; i++) {
    const id = selection[i];
    const el = rootState.planning.elements.find(item => item.id == id);
    if (el) {
      deleteTab.push(el);
    }
  }
  dispatch('planning/elements/deleteElements', { els: deleteTab }, { root: true });
  dispatch('resetSelection');
}

function removeFromSelection({ commit }, elId) {
  commit('remove', elId);
}

export default {
  namespaced: true,
  state: [],
  mutations: {
    add: commitAdd,
    remove: commitRemove,
    empty: commitEmpty,
  },
  getters: {
  },
  actions: {
    duplicateSelection,
    deleteSelection,
    resetSelection,
    removeFromSelection,
  },
};
