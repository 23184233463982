export default {
  hideManageAccount: true,
  sso: {
    protocol: 'cas',
    login: 'https://cas.univ-lyon3.fr/cas/login?service=https%3A%2F%2Funivlyon3.bubbleplan.net%2F',
    response_type: 'ticket',
  },
  integrations: {
    availableIntegrations: [],
  },
  links: {
    hideOneDrive: true,
    hideDropbox: true,
    hideGoogleDrive: true,
  },
};
