import * as jsonpatch from 'fast-json-patch';

function getElementsDiff(oldStateElements, newStateElements) {
  // This function takes project data as input (not planning data)
  const beforeVersion = { elements: oldStateElements };
  const currentVersion = { elements: newStateElements };

  [beforeVersion, currentVersion].forEach((version) => {
    const acc = {};
    version.elements.forEach((item) => {
      if (item.type == 'task') delete item.width;
      acc[`id=${item.id}`] = item;
    });
    version.elements = acc;
  });

  return jsonpatch.compare(beforeVersion, currentVersion);
}

export default {
  getElementsDiff,
};
