<template>
  <div class="content" :class="{ snapshot }">
    <!-- .content is snap selector-->
    <div v-if="! planning.visibleTimeline && ! errors" class="pa-6 text-center" style="position: absolute; top: var(--v-layout-top); left: 0; width: 100%; font-size: 18px">
      <i class="far fa-spinner fa-spin fa-2x fa-fw"></i>
    </div>
    <div v-if="errors" style="padding: 15px 20px;">
      {{ errors }}
    </div>
    <div v-if="! userConnected && planning.visibleTimeline" class="text-h6 font-weight-bold pa-3 bg-white">{{ planning.getTitle() }}</div> <!-- when title in navigation is not visible -->
    <client-custom-component v-if="customHeaderComponent" :name="customHeaderComponent" :planning="planning"></client-custom-component>
    <v-fade-transition>
      <div v-show="planning.visibleTimeline" class="planning-body"
           :class="{ 'kanban-body': isKanbanMode, 'single-timeline': ! planning.visibleTimeline?.subtimelinecols.length, 'rounded-s': userConnected }"
           style="display: flex">
        <!--  LANE HEADERS COLUMN-->
        <div>
          <div v-if="planning.visibleTimeline" class="customlogo rounded-ts pa-2" :style="{ 'max-width': `${hasSubprojects ? 310 : 155}px` }">
            <img v-if="planning.meta.companyconfig.premium" :src="planning.config.logo || planning.meta.companyconfig.logo">
            <a v-else href="https://bubbleplan.net" target="_blank" rel="noopener">
              <img src="../../img/bubble-plan-logo-color.png">
            </a>
          </div>
          <div class="d-flex">
            <planning-lane-headers :planning="planning" :dynamic="dynamic" :view="view"></planning-lane-headers>
            <planning-sub-lane-headers v-if="hasSubprojects" :planning="planning"></planning-sub-lane-headers>
          </div>
        </div>
        <!--  END LANE HEADERS COLUMN-->

        <!--  TABLE WRAPPER-->
        <div id="table-wrapper" class="light-scrollbar">
          <!-- KANBAN VIEW -->
          <div v-if="isKanbanMode && planning.visibleTimeline" id="planning-table" :style="{ width: `${processStepsLength}px` }">
            <!-- KANBAN HEADER -->
            <planning-kanban-headers ref="kanbanColHeaders" :planning="planning" :dynamic="dynamic" :view="view" :process-steps-length="processStepsLength"></planning-kanban-headers>
            <div class="table-header-placeholder"></div>
            <!--      TABLE BODY      -->
            <planning-pool class="mt-1" :planning="planning" :dynamic="dynamic" :options="options" :events="events" :view="view">
            </planning-pool>
            <!--      END TABLE BODY-->
          </div>
          <!-- PLANNING VIEW -->
          <div v-else-if="planning.visibleTimeline" id="planning-table" :style="{ width: `${planning.visibleTimeline.pxwidth}px` }">
            <!--      TABLE HEADER-->
            <planning-timeline :planning="planning" :dynamic="dynamic" :view="view" :dashboard="dashboard"></planning-timeline>
            <div class="table-header-placeholder"></div>
            <!--      END TABLE HEADER-->
            <!--      TABLE BODY      -->
            <planning-pool :planning="planning" :dynamic="dynamic" :options="options" :events="events" :view="view">
            </planning-pool>
            <!--      END TABLE BODY-->
          </div>
        </div>
        <!--  END TABLE WRAPPER-->

        <v-menu v-model="$store.state.ui.planning.newElementMenuIsOpened" :target="[$store.state.ui.planning.newElementMenuPosition.x, $store.state.ui.planning.newElementMenuPosition.y]"
                scroll-strategy="close" content-class="new-element-menu">
          <v-list density="compact">
            <v-list-item @click.prevent="addElementFromMenu('task')">{{ $t('PLANNING.NEW_BUBBLE') }}</v-list-item>
            <v-list-item @click.prevent="addElementFromMenu('milestone')">{{ $t('PLANNING.NEW_MILESTONE') }}</v-list-item>
            <template v-if="hasMacroTasks">
              <v-list-item v-if="! isKanbanMode" @click.prevent="addElementFromMenu('macro')">{{ $t('PLANNING.NEW_MACRO_BUBBLE') }}</v-list-item>
            </template>
            <template v-if="isBusiness">
              <v-divider></v-divider>
              <v-list-item @click.prevent="addElementFromMenu('template')">{{ $t('IMPORT_TEMPLATE.LIBRARY') }}</v-list-item>
            </template>
          </v-list>
        </v-menu>
      </div>
    </v-fade-transition>

    <new-subproject-button v-if="canModifyPlanning && planning.visibleTimeline" :planning="planning"></new-subproject-button>

    <!-- ADD TEMPLATE FROM MENU -->
    <modal v-if="importTemplate.isOpened" @close="importTemplate.isOpened = false">
      <import-template :planning="planning" :fixed-lane="importTemplate.destinationLane" :fixed-date="importTemplate.destinationDate" class="pa-6"
                       @close="importTemplate.isOpened = false">
      </import-template>
    </modal>

    <debug-zone v-if="! dashboard && planning.visibleTimeline" :planning="planning" :subprojects="subprojects" style="margin-top: 30px"></debug-zone>
  </div>

  <!-- LATERAL DRAWER MENU -->
  <planning-drawer v-if="! view && planning.visibleTimeline && ! snapshot" :planning="planning" :options="options" :events="events" :can-modify-planning="canModifyPlanning"></planning-drawer>
</template>

<style lang="scss">
  .content {
    margin-right: 48px;

    &.snapshot {
      margin-right: 0;
    }
  }

  .planning-body {
    background-color: white;
  }

  .new-element-menu {
    z-index: 200 !important;
  }

  .customlogo {
    height: 60px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    > a {
      height: 100%;
    }

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .single-timeline {
    #planning-table {
      padding-top: 12px; // ensure 60px header height
    }
  }

  .kanban-body {
    #planning-table {
      padding-top: 16px; // ensure 60px header height
    }
  }
</style>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import clientConfig from '@/client_customs/config';
  import DebugZone from '@/components/Debug/DebugZone.vue';
  import PlanningDrawer from '@/components/PlanningDrawer/PlanningDrawer.vue';
  import ImportTemplate from '@/components/Export/ImportTemplate.vue';
  import NewSubprojectButton from '@/components/Subprojects/NewSubprojectButton.vue';
  import PlanningLaneHeaders from './PlanningLaneHeaders/PlanningLaneHeaders.vue';
  import PlanningSubLaneHeaders from './PlanningLaneHeaders/PlanningSubLaneHeaders.vue';
  import PlanningTimeline from './PlanningTimeline.vue';
  import PlanningKanbanHeaders from './PlanningKanban/PlanningKanbanHeaders.vue';
  import PlanningPool from './PlanningPool.vue';

  export default {
    components: {
      DebugZone,
      PlanningDrawer,
      ImportTemplate,
      PlanningLaneHeaders,
      PlanningSubLaneHeaders,
      PlanningTimeline,
      PlanningKanbanHeaders,
      PlanningPool,
      NewSubprojectButton,
    },
    props: {
      planning: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      dashboard: { type: Boolean, default: false },
      options: { type: Object, default: undefined },
      events: { type: Object, default: undefined },
    },
    data() {
      return {
        importTemplate: {
          isOpened: false,
          destinationLane: null,
          destinationDate: null,
        },
      };
    },
    computed: {
      isKanbanMode() {
        return this.$store.state.ui.planning.mode == 'kanban';
      },
      processStepsLength() {
        const steps = this.planning.process.steps.concat(this.$store.getters['planning/process/subprojectsProcessSteps']);
        return steps.reduce((total, item) => total + item.width, 0);
      },
      canModifyPlanning() {
        return this.dynamic && ! this.view;
      },
      errors() {
        return this.$store.state.ui.planning.errors;
      },
      customHeaderComponent() {
        return clientConfig.planningContent && clientConfig.planningContent.customHeaderComponent;
      },
      subprojects() {
        return this.$store.getters['subprojects/getAll'];
      },
      userConnected() {
        return this.$store.state.users.user.id > 0;
      },
      snapshot() {
        return this.$store.state.ui.planning.snapshot;
      },
      hasMacroTasks() { return this.$store.state.users.accessRight.hasMacroTasks; },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      ...mapGetters('planning', ['hasSubprojects']),
    },
    created() {
      this.$store.commit('ui/planning/reset', { view: this.view, dynamic: this.dynamic, dashboard: this.dashboard });
      this.$store.dispatch('ui/planning/resetSaving');
      this.$store.dispatch('undoredo/reset');
      this.$store.commit('subprojects/reset');
      this.$store.dispatch('selection/resetSelection');
    },
    mounted() {
      const wrapper = $("#table-wrapper");
      wrapper.on('scroll', _.debounce(() => {
        this.$nextTick(() => this.$store.commit('ui/planning/updateWrapperData', wrapper));
      }, 300));
    },
    beforeUnmount() {
      this.$store.state.ui.planning.mode = null;
      this.$store.dispatch('ui/planning/closeProjectReport');
    },
    methods: {
      addElementFromMenu(type) {
        const menuOffset = this.$store.state.ui.planning.newElementMenuPosition;
        const laneId = this.$store.state.ui.planning.newElementMenuLane?.id;
        if (type == 'template') {
          if (! laneId) return;
          const positionLeft = menuOffset.x - $(`#lane${laneId}`).offset().left;
          if (! positionLeft) return;
          const { visibleTimeline } = this.planning;

          this.importTemplate.isOpened = true;
          this.importTemplate.destinationLane = laneId;
          this.importTemplate.destinationDate = moment(visibleTimeline.starttime).addWithWorkdays(positionLeft * visibleTimeline.minuteperpx, 'minutes', visibleTimeline.workdays);
          return;
        }
        this.addElement({ type, x: menuOffset.x, y: menuOffset.y + window.scrollY, laneId });
      },
      ...mapActions('ui/planning', ['addElement']),
    },
  };
</script>
