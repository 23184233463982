import ProjectSrv from '@/components/Projects/ProjectSrv';

function getInitialState() {
  return {
    dataLoaded: { company: null, company_error: "", projects: null, projects_error: "" },
    projects: [],
    openedProjects: [],
    openedUsers: [],
    openedGroup: null,
    manageTabIndex: null,
  };
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset(state, values) {
      _.extend(state, getInitialState(), values);
    },
  },
  getters: {
    canIOpenUser: (state, getters, rootState, rootGetters) => (user) => {
      if (user.id == rootState.users.user.id) return true;
      if (! rootState.users.accessRight.isAdmin) return false;
      if (! rootState.users.user.organization) return true;
      return rootGetters['users/getOrganizationSubCompanies'].find(company => (company.users || []).find(item => item.id == user.id)) && true || false;
    },
    canIEditUserRights: (state, getters, rootState) => (user) => {
      if (['consult', 'user', 'company_admin'].indexOf(user.access_right) === -1) return false;
      return (rootState.users.accessRight.isAdmin && getters.canIOpenUser(user));
    },
  },
  actions: {
    loadCompany({ state, dispatch, rootState }) {
      return dispatch('users/getMe', null, { root: true }).then(() => {
        state.dataLoaded.company = true;
      }).catch((message) => {
        state.dataLoaded.company_error = message || rootState.lang.i18n.global.t("GLOBAL.ERROR");
      });
    },
    loadProjects({ state, rootState }) {
      ProjectSrv.list().then((response) => {
        state.projects = response;
        state.dataLoaded.projects = true;
      }).catch((message) => {
        state.dataLoaded.projects_error = message || rootState.lang.i18n.global.t("GLOBAL.ERROR");
      });
    },
    reload({ state, dispatch }) {
      state.openedProjects = [];
      state.openedUsers = [];
      dispatch('loadCompany');
      dispatch('loadProjects');
      dispatch('users/groups/load', null, { root: true });
      state.openedGroup = null;
    },
    openProject({ state }, project) {
      $(window).scrollTop(0);
      const projectIndex = state.openedProjects.indexOf(project);
      if (projectIndex == -1) state.openedProjects.push(project);
      setTimeout(() => {
        state.manageTabIndex = `projects-project${project.id}`;
      });
      state.openedGroup = null;
    },
    openProjectFromId({ state, dispatch }, projectId) {
      state.manageTabIndex = `projects-projects`;
      if (state.dataLoaded.projects_error) return;
      if (! state.dataLoaded.projects) {
        setTimeout(() => { dispatch('openProjectFromId', projectId); }, 300);
        return;
      }
      const project = state.projects.find(item => item.id == projectId);
      if (project) dispatch('openProject', project);
    },
    openUser({ state, getters }, user) {
      if (! getters.canIOpenUser(user)) return;
      $(window).scrollTop(0);
      state.openedGroup = null;
      const userIndex = state.openedUsers.indexOf(user);
      if (userIndex == -1) state.openedUsers.push(user);
      setTimeout(() => {
        state.manageTabIndex = `company-user${user.id}`;
      });
    },
    openUserFromId({ dispatch, rootGetters }, userId) {
      const user = rootGetters['users/getOrganizationUsers'].find(item => item.id == userId);
      if (user) dispatch('openUser', user);
    },
  },
};
