<template>
  <planning-element-view-selected :el="el" class="element" :class="[elType, elementClass, el.getColorClass(), { 'mini-element': isMiniElement }]"
                                  :style="{ 'z-index': hoverElement ? 110 : null }"
                                  v-bind="$attrs" @mouseenter="hoverElement = true" @mouseleave="hoverElement = false">
    <div v-if="elType == 'task' || elType == 'macro'">
      <!--ICON-->
      <client-custom-component v-if="customIconComponent" :name="customIconComponent" :el="el" @select-color="$emit('select-color')"></client-custom-component>
      <div v-else-if="elIcon" :class="elType == 'macro' ? 'macro-icon' : 'task-icon'">
        <v-icon :color="elIcon.color" :title="elIconLabel" size="16" class="fa-fw iconshadow" style="vertical-align: initial; cursor: inherit"
                @click.stop="$emit('select-color')">
          {{ elIcon.name }}
        </v-icon>
      </div>

      <div class="elementmain">
        <!--HEADER-->
        <div :style="elTitleStyle" :title="elTitleTooltip?.(el)" class="element-title">
          <!-- span needed for z-index with macro -->
          <span>{{ elTitle }}</span>
          <div v-if="showDetails.projecttitle && el.display_title" class="font-italic small mt-1 text-ellipsis">{{ el.display_title }}</div>
        </div>

        <!--CONTENT-->
        <div v-if="showDetails.description || showDetails.subTasks || showDetails.checklist" class="elementcontent">
          <div v-if="showDetails.description" :style="elDescriptionStyle" class="pre-wrap shrink-when-mini">
            <div v-if="isMiniElement"><i class="far fa-lg fa-align-center"></i></div>
            <div v-else>{{ elDescription }}</div>
          </div>
          <!-- Macro Sub tasks -->
          <div v-if="elType == 'macro' && showDetails.subTasks" class="d-flex flex-wrap shrink-when-mini">
            <div v-if="isMiniElement"><i class="far fa-sitemap"></i> {{ elSubTaskIds.size }}</div>
            <template v-else>
              <div v-for="subTask in elSubTasks" class="ma-1">
                <planning-element-view :el="subTask" class="macro-sub-task" style="position: static; background: none; max-width: 100px"
                                       @click.stop="$emit('click-subelement', subTask.id)">
                </planning-element-view>
              </div>
            </template>
          </div>
          <!-- Task checklist -->
          <planning-element-view-checklist v-if="elType == 'task' && showDetails.checklist" :el="el" :el-checklist-filtered="elChecklistFiltered"
                                           :is-mini-element="isMiniElement" class="shrink-when-mini"
                                           @checklist-click="$emit('checklist-click', $event)">
          </planning-element-view-checklist>
        </div>

        <!--FOOTER-->
        <planning-element-view-dates v-if="showDetails.dates" :planning="planning" :el="el" class="users-bar">
        </planning-element-view-dates>
        <planning-element-view-users v-if="showDetails.users" :el="el" :is-mini-element="isMiniElement" class="users-bar shrink-when-mini">
        </planning-element-view-users>
        <planning-element-view-links v-if="showDetails.links" :el="el" :is-mini-element="isMiniElement" class="users-bar shrink-when-mini">
        </planning-element-view-links>
        <planning-element-view-budget v-if="showDetails.budgets" :el="el" :is-mini-element="isMiniElement" class="users-bar shrink-when-mini">
        </planning-element-view-budget>

        <planning-element-view-synthesis-bar v-if="showDetails.synthesis" :planning="planning" :el="el" class="users-bar"></planning-element-view-synthesis-bar>

        <planning-element-view-process v-if="showDetails.process && ! hideProgressBarAndProcess" :planning="planning" :el="el"
                                       class="users-bar" :class="{ 'pb-0': showDetails.progress && ! hideProgressBar }">
        </planning-element-view-process>

        <planning-element-view-progress v-if="showDetails.progress && ! hideProgressBar" :el="el"
                                        @progress-click="$emit('progress-click', $event)">
        </planning-element-view-progress>

        <client-custom-component v-for="customField in customFields" :key="`cf_${customField.dataField}`"
                                 :name="customField.elComponent" :el="el" :custom-field="customField">
        </client-custom-component>
        <slot name="footer"></slot>
      </div>
    </div>

    <div v-if="elType == 'milestone'">
      <div :style="{ 'min-height': `${el.ytextposition}px`, 'margin-top': `${-el.ytextposition}px` }" class="milestone-icon">
        <v-icon :color="elIcon.color" :title="elIconLabel" size="16" class="fa-fw iconshadow" style="vertical-align: initial; cursor: inherit"
                @click.stop="$emit('select-color')">
          {{ elIcon.name }}
        </v-icon>
        <div :class="`text-${elIcon.color}`" class="milestone-icon-dash"></div>
      </div>
      <planning-element-view-dates v-if="showDetails.date" :planning="planning" :el="el">
      </planning-element-view-dates>
      <div :style="elTitleStyle">
        <div class="element-title">
          {{ elTitle }}
          <div v-if="showDetails.projecttitle && el.display_title" class="font-italic small mt-1 text-ellipsis">{{ el.display_title }}</div>
        </div>
      </div>
      <!--FOOTER-->
      <div>
        <planning-element-view-users v-if="showDetails.users" :el="el" :is-mini-element="isMiniElement" class="users-bar shrink-when-mini">
        </planning-element-view-users>
        <planning-element-view-links v-if="showDetails.links" :el="el" :is-mini-element="isMiniElement" class="users-bar shrink-when-mini">
        </planning-element-view-links>
        <div v-if="el.getMeetingId()" class="users-bar font-weight-bold text-uppercase text-center">
          <a style="color: inherit" @click.stop="$emit('open-meeting', el)">{{ $t('PLANNING.MEETING') }}</a>
        </div>

        <planning-element-view-process v-if="showDetails.process && ! hideProgressBarAndProcess" :planning="planning" :el="el" class="users-bar"></planning-element-view-process>

        <client-custom-component v-for="customField in customFields" :key="`cf_${customField.dataField}`"
                                 :name="customField.elComponent" :el="el" :custom-field="customField">
        </client-custom-component>
        <slot name="footer"></slot>
      </div>
    </div>
    <slot></slot>
    <v-scale-transition origin="top left" hide-on-leave>
      <slot v-if="hoverElement" name="hover-menu"></slot>
    </v-scale-transition>
  </planning-element-view-selected>
</template>

<style lang="scss">
  .element.task.mini-element .element-title, .element.macro.mini-element .element-title {
    font-size: 9px;
    white-space: nowrap;
    padding: 0 2px;
  }

  article.element.mini-element .shrink-when-mini {
    min-height: 16px;
    padding: 3px 0;
    text-align: center !important;
    font-size: 11px !important;
  }

  .element .element-menu.scale-transition-enter-active, .element .element-menu.scale-transition-leave-active {
    transition-duration: .2s !important;
  }

  .element.macro .element.macro-sub-task.milestone {
    .milestone-icon {
      position: static;
      display: inline-block;
      vertical-align: middle;
      margin: 0 !important;
      min-height: 0 !important;
    }
    .milestone-icon .milestone-icon-dash {
      display: none;
    }
    .element-title {
      background: unset;
      color: unset;
      padding: 0;
    }
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import constants from '@/js/constants.js';
  import PlanningElementViewSelected from './PlanningElementView/PlanningElementViewSelected.vue';
  import PlanningElementViewBudget from './PlanningElementView/PlanningElementViewBudget.vue';
  import PlanningElementViewChecklist from './PlanningElementView/PlanningElementViewChecklist.vue';
  import PlanningElementViewDates from './PlanningElementView/PlanningElementViewDates.vue';
  import PlanningElementViewLinks from './PlanningElementView/PlanningElementViewLinks.vue';
  import PlanningElementViewProgress from './PlanningElementView/PlanningElementViewProgress.vue';
  import PlanningElementViewProcess from './PlanningElementView/PlanningElementViewProcess.vue';
  import PlanningElementViewUsers from './PlanningElementView/PlanningElementViewUsers.vue';
  import PlanningElementViewSynthesisBar from './PlanningElementView/PlanningElementViewSynthesisBar.vue';
  import macroElement from './mixins/macroElement';

  export default {
    name: 'PlanningElementView',
    components: {
      PlanningElementViewSelected,
      PlanningElementViewBudget,
      PlanningElementViewChecklist,
      PlanningElementViewDates,
      PlanningElementViewLinks,
      PlanningElementViewProgress,
      PlanningElementViewProcess,
      PlanningElementViewUsers,
      PlanningElementViewSynthesisBar,
    },
    mixins: [macroElement],
    props: {
      el: { type: Object, required: true },
      showDetailsItems: { type: Array, default: null },
      disableMiniElement: { type: Boolean, default: false },
    },
    emits: ['select-color', 'open-element', 'checklist-click', 'progress-click', 'open-meeting', 'click-subelement'],
    data() {
      return {
        planning: this.el.getPlanning(),
        hoverElement: false,
        lastHeightUpdateElData: null,
        customFields: clientConfig.elements?.customFields?.filter(customField => customField.elComponent),
        hideProgressBar: clientConfig.elements?.hideProgressBar || false,
        customIconComponent: clientConfig.icons?.customIconComponent,
      };
    },
    computed: {
      elType() {
        return this.el.getType();
      },
      isKanbanMode() {
        return this.$store.state.ui.planning.mode == 'kanban';
      },
      elementClass() {
        if (this.isKanbanMode) return null;
        if (this.elType == 'milestone' || ! this.planning?.visibleTimeline) return null;
        const { starttime: timelineStart, endtime: timelineEnd } = this.planning.visibleTimeline;
        if (this.el.getStartTime()?.isBefore(timelineStart)) return 'element-overflow-start';
        if (this.el.getEndTime()?.isAfter(timelineEnd)) return 'element-overflow-end';
        return null;
      },
      defaultTitle() {
        if (this.elType == 'macro') return this.$t('PLANNING.NEW_MACRO_BUBBLE');
        return this.elType == 'milestone' ? this.$t('PLANNING.MILESTONE') : this.$t('PLANNING.NEW_BUBBLE');
      },
      elTitle() {
        return this.el.getTitle() || this.defaultTitle;
      },
      elTitleStyle() {
        return this.el.getTitleStyle();
      },
      elDescriptionStyle() {
        return this.el.getDescriptionStyle();
      },
      elSubTaskIds() {
        return new Set(this.el.getSubTasks());
      },
      elSubTasks() {
        if (! this.elSubTaskIds.size) return null;
        return this.planning.elements.filter(item => this.elSubTaskIds.has(parseInt(item.id, 10))).map((el) => {
          const subEl = angular.copy(el);
          subEl.setConfig({
            "show-description": false,
            "show-progress": true,
            "show-checklist": false,
            "show-users": false,
            "show-dates": true,
            "show-date": true,
            "show-links": false,
            "show-budgets": false,
            "show-subTasks": false,
            "show-projecttitle": false,
          });
          if (! subEl.isType('milestone')) subEl.setIconId(null);
          return subEl;
        }).sort((a, b) => {
          if (a.isType('macro')) {
            if (! b.isType('macro')) return -1;
          } else if (b.isType('macro')) {
            return 1;
          }
          return (a.getStartTime() < b.getStartTime() ? -1 : 1);
        });
      },
      elIcon() {
        return this.el.hasIcon() && this.el.getIcon();
      },
      elIconLabel() {
        return this.elIcon ? (this.elIcon.label || this.$t(`ICONS.${this.el.getIconId().toUpperCase()}`)) : '';
      },
      elDescription() {
        return this.el.getDescription();
      },
      elChecklistFiltered() {
        let checklist = this.el.getChecklist() || [];
        if (this.el.filter_user) {
          checklist = checklist.filter((item) => { // remove other users actions
            if (! item.user_id && ! item.group_id && ! item.username) return true; // unassigned action
            if (item.user_id) return item.user_id == this.el.filter_user;
            if (item.group_id) return `group${item.group_id}` == this.el.filter_user;
            return `vp${item.username}` == this.el.filter_user;
          });
        }
        return checklist;
      },
      elLinks() {
        return this.el.getLinks();
      },
      elUsers() {
        return this.el.getUsers();
      },
      elBudgets() {
        return this.el.getBudgets() && this.el.getBudgets().filter(budget => budget.amount || budget.amount_inprogress);
      },
      elProcessStep() {
        return this.el.getProcessStep();
      },
      elProgress() {
        return this.el.getProgress();
      },
      showDetails() {
        let keys = ['description', 'subTasks', 'checklist', 'users', 'links', 'budgets', 'process', 'progress', 'dates', 'date', 'projecttitle'];
        const showDetails = {};
        if (this.showDetailsItems) keys = this.showDetailsItems;
        keys.forEach((key) => {
          const config = this.showDetailsItems ? true : this.el.getConfig(`show-${key}`);
          if (key == 'description') showDetails[key] = config && this.elDescription;
          else if (key == 'subTasks') showDetails[key] = config && this.elSubTasks && this.elSubTasks.length;
          else if (key == 'checklist') showDetails[key] = config && this.elChecklistFiltered.length;
          else if (key == 'users') showDetails[key] = config && this.elUsers && this.elUsers.length;
          else if (key == 'links') showDetails[key] = config && this.elLinks && this.elLinks.length;
          else if (key == 'budgets') showDetails[key] = config && this.elBudgets && this.elBudgets.length;
          else if (key == 'process') showDetails[key] = ! this.isKanbanMode && this.elProcessStep;
          else if (key == 'progress') showDetails[key] = (config || config === undefined) && this.elProgress;
          else if (key == 'dates' || key == 'date') showDetails[key] = this.isKanbanMode || config;
          else if (['projecttitle', 'synthesis'].includes(key)) showDetails[key] = config;
        });
        return showDetails;
      },
      isMiniElement() {
        return ! this.disableMiniElement && this.el.getWidth() <= constants.miniElementBreakWidth;
      },
      elTitleTooltip() {
        return this.$store.getters['users/accessRight/config'].elTitleTooltip;
      },
      hideProgressBarAndProcess() {
        if (! this.hideProgressBar) return false;
        const processConfig = this.el.getConfig(`show-progress`); // when clientCustom hideProgress, use show-progress to hide process (maaf specific)
        return ! (processConfig || processConfig === undefined);
      },
    },
    watch: {
      'el.data': {
        handler() {
          this.$nextTick(() => this.$nextTick(() => this.throttledUpdateHeight(this.el))); // double nextTick to wait for subcomponents like v-menu in links
        },
        deep: true,
      },
    },
    methods: {
      throttledUpdateHeight: _.throttle(function (el) {
        const elData = _.extend(el.getAll(), { starttime: null, endtime: null });
        if (angular.equals(this.lastHeightUpdateElData, elData)) return;
        this.lastHeightUpdateElData = angular.copy(elData);
        el.updateHeight();
      }, 300),
    },
  };
</script>
