import { defineAsyncComponent } from 'vue';
import titlesList from '@/client_customs/Horis/titlesList';
import lang from '@/client_customs/Horis/lang.json';
import textUsers from '@/client_customs/Horis/textUsers';

const HorisElementsSynthesis = defineAsyncComponent(() => import('@/client_customs/Horis/HorisElementsSynthesis'));
const HorisEmail = defineAsyncComponent(() => import('@/client_customs/Horis/HorisEmail'));
const HorisProgressDashboard = () => import('@/client_customs/Horis/HorisProgressDashboard');

export default {
  subdomain: 'horis', // will add subdomain in links url
  clientCustomComponents: { HorisElementsSynthesis, HorisEmail },
  lang,
  hideManageAccount: true,
  router: {
    beforeEach(to, from, next) {
      const store = window.app.config.globalProperties.$store;

      let loopForGetMeError = 50; // 10s
      const interval = setInterval(() => {
        if (store.state.users.getMeError || loopForGetMeError <= 0) {
          clearInterval(interval);
          next();
          return;
        }
        loopForGetMeError--;
      }, 200);

      store.state.users.userPromise.then((user) => {
        clearInterval(interval);
        if (to.path != '/dashboards/horis/kanban' && user.groups.findIndex(group => group.special_name == 'installateurs') > -1) {
          next('/dashboards/horis/kanban');
        } else {
          next();
        }
      });
    },
  },
  dashboards: {
    router: [
      { path: '/dashboards/horis/kanban', component: HorisProgressDashboard },
    ],
  },
  integrations: {
    availableIntegrations: [],
  },
  links: {
    hideOneDrive: true,
    hideDropbox: true,
    hideGoogleDrive: true,
  },
  projectsheet: {
    customTabs: [
      { id: 'horis-elementssynthesis', component: 'HorisElementsSynthesis', label: "Étapes", icon: 'far fa-tasks' },
    ],
  },
  colors: [
    { label: "ADV" },
    { label: "BUREAU ÉTUDE" },
    { label: "CONDUCTEUR TRAVAUX" },
    { label: "COMMERCE" },
    { label: "SUPPORTS (DOE - CONSEILLER CULINAIRE)" },
    { label: "CO-TRAITANCE" },
  ],
  icons: {
    replace: {
      users: { name: 'fas fa-users', color: 'successgreen', label: 'Visite ou convocation de chantier' },
      truck: { name: 'fas fa-truck', color: 'warningorange', label: 'Réception matériel (date non calée ou prévisionnelle)' },
      'horis-truck-green': { name: 'fas fa-truck', color: 'successgreen', label: 'Réception matériel (date calée avec le client / fournisseur)' },
      'horis-truck-blue': { name: 'fas fa-truck', color: 'primary', label: 'Réception matériel (date imposée)' },
      warning: { name: 'fas fa-exclamation-triangle', color: 'errorred', label: 'Point bloquant' },
      help: { name: 'fas fa-hands-helping', color: 'info', label: 'Visite du Conseiller culinaire' },
      document: { name: 'far fa-file-alt', color: 'info', label: 'Réalisation DOE' },
      presentation: { name: 'fas fa-presentation', color: 'errorred', label: 'Présentation du Projet' },
      send: { name: 'fas fa-paper-plane', color: 'primary', label: 'Passage des OA' },
      happy: { name: 'far fa-smile', color: 'errorred', label: 'Signature de l\'OS' },
      'horis-measure': { name: 'far fa-ruler-combined', color: 'successgreen', label: 'Prise de cote sur chantier' },
      digging: { name: 'fas fa-digging', color: 'warningorange', label: 'Installation (date non calée ou prévisionnelle)' },
      'horis-digging-green': { name: 'fas fa-digging', color: 'successgreen', label: 'Installation (date calée avec le client / fournisseur)' },
      'horis-digging-blue': { name: 'fas fa-digging', color: 'primary', label: 'Installation (date imposée)' },
      euro: { name: 'fas fa-euro-sign', color: 'primary', label: 'Facturation' },
      alarm: { name: 'fas fa-alarm-clock', color: 'errorred', label: 'Date imposée' },
      appreciation: { name: 'fas fa-thumbs-up', color: 'successgreen', label: 'Tout va bien' },
      milestone0: { name: 'fa icon-diamonds', color: 'primary', label: 'Jalon' },
    },
    disableRenaming: true,
    colorPickerIconCol: 3,
  },
  textUsers,
  elements: {
    titlesList,
    customFields: [
      { dataField: 'email', component: 'HorisEmail', label: "E-mail", icon: 'far fa-envelope', active: true, inMenu: true },
    ],
  },
  elementDetails: {
    display: {
      progress: false, checklist: true, users: true, links: true, budgets: true, alerts: false,
    },
  },
  availableTemplates: [
    { id: 'horis_type1', title: "1. Gré à Gré" },
    { id: 'horis_type4', title: "2. Projet Complexe Light" },
    { id: 'horis_type2', title: "3. Projet Complexe" },
  ],
};
