import { mapActions } from 'vuex';
import constants from '@/js/constants.js';
import PlanningElement from '@/models/PlanningElement';

let lastPlaceholderMove = 0;
let livedragThrottledTimeout;

function movePlaceholder(planning, ui) {
  lastPlaceholderMove = Date.now();
  const placeholderEl = planning.elements.find(el => el.id == ui.helper.data('placeholder_id'));
  if (! placeholderEl) return;
  if (ui.helper.data('mode') == 'kanban') {
    const referenceXPosition = ui.position.left + ui.helper.width() / 2;
    const referenceYPosition = ui.offset.top + ui.helper.height() / 2;
    placeholderEl.kanbanMove(placeholderEl.getLaneId(), referenceXPosition, referenceYPosition);
  } else {
    placeholderEl.move(placeholderEl.getLaneId(), ui.position.left);
  }
}

const livedragThrottled = _.throttle((planning, ui) => {
  if (livedragThrottledTimeout) clearTimeout(livedragThrottledTimeout);
  if (Date.now() - lastPlaceholderMove < 3000) {
    livedragThrottledTimeout = setTimeout(() => movePlaceholder(planning, ui));
    return;
  }
  movePlaceholder(planning, ui);
}, 150);

export default {
  computed: {
    selection() { return this.$store.state.selection; },
  },
  methods: {
    draggableConfig(el) {
      if (! this.isDynamic(el)) return { disabled: true };
      const vm = this;
      return {
        disabled: el.isType('macro') || el.getIsLocked() || (this.planning.config.lockDragAndDrop && this.planning.meta.access_right != 'admin'),
        cursor: 'move',
        revert: 'invalid',
        cancel: '.element-menu',
        distance: 3,
        helper: 'clone',
        start(event, ui) {
          vm.dragStart(event, ui, el);
          const livedragActive = vm.selection.length <= 1;
          if (livedragActive) { // only when dragging 1 element
            vm.livedragStart(event, ui, el);
          } else {
            setTimeout(() => setTimeout(() => $(`#el${el.id}`).css('left', '-1000px'))); // hide original element
          }
        },
        drag(event, ui) {
          vm.dragSelection(event, ui, el);
        },
        stop(event, ui) {
          setTimeout(() => { ui.helper.removeClass('is-dragging'); }, 300);
          vm.removeDragPlaceholders(event, ui, el);
          const selection = ui.helper.data('ui-drag-selection');
          if (selection?.length) {
            const selectionSet = new Set(selection);
            vm.planning.elements.forEach((selectedEl) => { // force refresh of css prop changed by jqueryui
              if (! selectionSet.has(selectedEl.id)) return;
              selectedEl.xposition--;
              selectedEl.yposition--;
              vm.$nextTick(() => {
                selectedEl.update();
              });
            });
          }
          vm.$store.state.ui.planning.doNotUpdateSelection = false;
        },
      };
    },

    dragStart(event, ui, el) {
      this.startChangingElement();
      ui.helper.addClass('is-dragging');
      ui.helper.data('id', el.id);
      ui.helper.data('ui-drag-prevPosition', ui.originalPosition);

      const notLockedSelection = this.selection.filter((selectedId) => {
        const selectedEl = this.planning.elements.find(item => item.id == selectedId);
        return selectedEl && ! selectedEl.getIsLocked();
      });
      if (notLockedSelection.length != this.selection.length) this.$store.dispatch('selection/resetSelection', notLockedSelection);
      if (this.selection.indexOf(el.id) == -1) this.$store.dispatch('selection/resetSelection', [el.id]);
      ui.helper.data('ui-drag-selection', angular.copy(this.selection));
      if (el.isType('task')) this.setSnapBoundaries(ui);

      this.removeDragPlaceholders();
    },

    livedragStart(event, ui, el) {
      ui.helper.data('livedrag-active', true);
      const placeholderEl = new PlanningElement(this.planning, el); // create placeholder synchronously
      placeholderEl.setDependencies(null);
      placeholderEl.id = this.$store.getters['planning/elements/nextId'];
      this.$store.commit('planning/elements/add', placeholderEl);
      placeholderEl.isDragPlaceholder = true;
      el.visible = false;
      ui.helper.data('placeholder_id', placeholderEl.id);
      return placeholderEl;
    },

    dragSelection(event, ui, el) {
      if (el.isType('task')) this.snapToBoundaries(ui);

      if (ui.helper.data('livedrag-active')) {
        livedragThrottled(this.planning, ui);
      }

      if (this.selection.length <= 1) return;

      // selection drag
      const prevPosition = ui.helper.data('ui-drag-prevPosition');
      const newPosition = ui.position;
      const offsetleft = newPosition.left - prevPosition.left;
      const offsettop = newPosition.top - prevPosition.top;

      this.selection.forEach((id) => {
        if (id == el.id) return;
        const $selectedElement = $(`#el${id}`);
        if ($selectedElement) {
          $selectedElement.css('left', `+=${offsetleft}`);
          $selectedElement.css('top', `+=${offsettop}`);
        }
      });
      ui.helper.data('ui-drag-prevPosition', newPosition);
      this.$store.state.ui.planning.doNotUpdateSelection = true;
    },

    removeDragPlaceholders() {
      const dragPlaceholders = this.planning.elements.filter(item => item.isDragPlaceholder);
      if (dragPlaceholders.length) this.deleteElements({ els: dragPlaceholders, silent: true });
    },

    /** KANBAN */
    kanbanDraggableConfig(el) {
      if (! this.isDynamic(el)) return { disabled: true };
      const vm = this;
      return {
        disabled: el.isType('macro') || el.getIsLocked() || (this.planning.config.lockDragAndDrop && this.planning.meta.access_right != 'admin'),
        cursor: 'move',
        revert: 'invalid',
        cancel: '.element-menu',
        distance: 3,
        helper: 'clone',
        start(event, ui) {
          vm.dragStart(event, ui, el);

          const livedragActive = vm.selection.length <= 1;
          if (livedragActive) { // only when dragging 1 element
            ui.helper.css('width', `${constants.kanbanDragHelperWidth}px`); // set to smaller size to fit better in columns
            ui.helper.data('mode', 'kanban');
            const placeholderEl = vm.livedragStart(event, ui, el);
            placeholderEl.setStartTime(null); // to appear when over backlog
            placeholderEl.setEndTime(null);

            ui.helper.data('original-step', el.getProcessStep());
            el.setProcessStep('livedraghiding'); // hide original element
          } else {
            const $el = $(`#el${el.id}`);
            ui.helper.css('width', `${$el.width()}px`); // keep original width for consistency with other dragged elements
            setTimeout(() => setTimeout(() => $(`#el${el.id}`).css('visibility', 'hidden'))); // hide original element
          }
        },
        drag(event, ui) {
          vm.dragSelection(event, ui, el);
        },
        stop(event, ui) {
          setTimeout(() => { ui.helper.removeClass('is-dragging'); }, 300);
          vm.removeDragPlaceholders(event, ui, el);
          if (el.getProcessStep() == 'livedraghiding') el.setProcessStep(ui.helper.data('original-step'));
          const selection = ui.helper.data('ui-drag-selection');
          if (selection?.length) {
            const selectionSet = new Set(selection);
            vm.planning.elements.forEach((selectedEl) => { // force refresh of css prop changed by jqueryui
              if (! selectionSet.has(selectedEl.id)) return;
              // For when you leave the drop zone
              $(`#el${selectedEl.id}`).css({ left: '', top: '', visibility: '' });
            });
          }
          vm.$store.state.ui.planning.doNotUpdateSelection = false;
        },
      };
    },

    ...mapActions('planning/elements', ['setSnapBoundaries', 'snapToBoundaries', 'deleteElements']),
  },
};
