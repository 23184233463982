<template>
  <section class="exchangecenter">
    <div v-if="config.search" class="mt-1 mb-2 position-relative">
      <text-filter ref="searchBar" v-model="searchFilter" :placeholder="$t('EXCHANGECENTER.SEARCH')" clearable prepend-inner-icon="far fa-search" rounded="pill"
                   class="exchangecenter-searchbar">
      </text-filter>
      <div class="exchangecenter-desc small text-medium-emphasis px-4 flex-center-center" style="position: absolute; left: 40px; top: 0; bottom: 0; line-height: 1.2;">
        {{ $t('EXCHANGECENTER.HEADER_DESC') }}
      </div>
    </div>
    <div class="d-flex flex-column flex-1-1-100 overflow-y-auto overflow-x-hidden">
      <div ref="chatContent" class="chat-content light-scrollbar overscroll-behavior-y-contain">
        <div v-if="loading.inprogress && ! exchanges.length" style="text-align:center;"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
        <div v-if="loading.error" style="padding:15px">{{ loading.error }}</div>
        <div v-show="! loading.inprogress && ! loading.error && ! exchanges.length" class="small text-medium-emphasis">{{ $t('EXCHANGECENTER.BE_FIRST_TO_LET_MESSAGE') }}</div>
        <exchange-center-line v-for="(exchange, index) in filteredExchanges" :key="index"
                              :exchange="exchange" :prev-exchange="filteredExchanges[index - 1]" :next-exchange="filteredExchanges[index + 1]"
                              @edit="editComment(exchange)" @delete="deleteComment(exchange)" @select-at="selectAt($event)" @select-hashtag="selectHashtag($event)" @autoscroll="autoscroll">
        </exchange-center-line>
      </div>

      <!-- New msg input bar -->
      <div class="mr-4 mt-2 mb-1">
        <form @submit.prevent="postMessage()">
          <v-text-field ref="newMsgInput" v-model="newMsg" :placeholder="$t('EXCHANGECENTER.ENTER_YOUR_MESSAGE_HERE')" variant="outlined" density="comfortable" autocomplete="off">
            <template v-if="config.files" #prepend-inner>
              <template v-if="newFiles.length && newFiles[0].success">
                <div v-for="file in newFiles">
                  <v-chip v-if="file.success" color="successgreen" size="small" closable :title="file.name" class="text-ellipsis" @click:close="newFiles = []">
                    <span class="text-ellipsis" style="max-width: 60px">{{ file.name }}</span>
                  </v-chip>
                </div>
              </template>
              <v-btn-icon v-else :title="! isPremium ? $t('EXCHANGECENTER.UPLOAD_FILE_FOR_PREMIUM') : $t('EXCHANGECENTER.UPLOAD_FILE')" size="small" @click="openFileDialog">
                <vue-upload-component ref="upload" v-model="newFiles" :post-action="uploadFileUrl" :headers="tokenHeaders"
                                      :multiple="false" :thread="5" :size="20 * 1024 * 1024" :disabled="! isPremium"
                                      :class="{ pointer: isPremium }" input-id="file-exchangecenter"
                                      @input-file="inputFile" @click.stop>
                  <v-icon size="large">far fa-paperclip</v-icon>
                </vue-upload-component>
              </v-btn-icon>
            </template>
            <template #append>
              <v-btn icon :title="$t('EXCHANGECENTER.SEND')" :disabled="! newMsg.length && ! (newFiles.length && newFiles[0].success)" color="primary" size="small"
                     @click="postMessage">
                <v-icon size="large">far fa-paper-plane</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <v-menu :model-value="!! atUsersOptions.length" :activator="newMsgInputNode" :open-on-click="false" :max-height="120" location="top">
            <v-list density="compact">
              <v-list-item v-for="atUser in atUsersOptions" :key="atUser.id" @click="onSelectAtUser(atUser); setFocus();">{{ atUser.atvalue }}</v-list-item>
            </v-list>
          </v-menu>
          <div class="text-medium-emphasis small">
            <div v-for="file in newFiles" class="d-flex" style="overflow: hidden">
              <v-progress-linear v-if="file.error || file.progress != 100" :color="file.error ? 'errorred' : 'successgreen'"
                                 :title="fileErrorMessage(file) || (Math.round(file.progress * 1) + ' %')"
                                 :model-value="file.error ? 100 : (file.progress < 100 ? file.progress : 100)" height="16" rounded
                                 class="ml-2 text-white text-center" style="flex: 1 1 auto; min-width: 150px">
                {{ file.name }}<span class="ml-1">{{ fileErrorMessage(file) || (Math.round(file.progress * 1) + ' %') }}</span>
              </v-progress-linear>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  .exchangecenter {
    height: 100%;
    display: flex;
    flex-direction: column;

    .exchangecenter-searchbar {
      width: 40px;
      transition: width .3s ease-in-out;

      &:hover, &:focus-within {
        width: calc(100% - 16px) !important;
      }

      + .exchangecenter-desc {
        opacity: 1;
        transition: opacity .3s ease-in-out;
        z-index: -1;
      }

      &:hover + .exchangecenter-desc {
        opacity: 0;
      }
    }

    .chat-content {
      flex-grow: 1;
      flex-basis: 100%;
      overflow-y: auto;
      padding-right: 8px;
    }
  }

  .file-uploads.file-uploads-html5 label {
    cursor: pointer;
  }
</style>

<script>
  import { reactive } from 'vue';
  import VueUploadComponent from 'vue-upload-component';
  import { deepcopy } from '@/components/Reusables/utils';
  import ExchangeCenterLine from './ExchangeCenterLine';
  import ApiStoredComment from './ApiStoredComment';
  import LocalStoredComment from './LocalStoredComment';

  import '@/css/emojis/emoji.min.css';

  export default {
    components: {
      ExchangeCenterLine,
      VueUploadComponent,
    },
    props: {
      options: { type: Object, default: () => {} },
      localcomments: { type: Array, default: null },
      parentvisible: { type: Boolean, default: true },
    },
    emits: ['add', 'update', 'delete'],
    data() {
      const config = deepcopy(this.options) || {};
      if (typeof config.search == 'undefined') config.search = true;
      if (typeof config.files == 'undefined') config.files = true;
      const apiStored = !! config.planning_id;
      return {
        config,
        planning_id: config.planning_id,
        element_id: config.element_id,
        apiStored,
        Comment: apiStored ? ApiStoredComment : LocalStoredComment,
        exchanges: [],
        me: null,
        searchFilter: "",
        newMsg: "",
        preventPostMessage: false,
        newFiles: [],
        uploadFileUrl: `${window.apiSrv.host}v1/files`,
        tokenHeaders: window.apiSrv.getTokenHeaders(),
        loading: { inprogress: false, success: false, error: false },
        newMsgInputNode: null,
      };
    },
    computed: {
      filteredExchanges() {
        const filter = (this.searchFilter || '').toLowerCase().trim();
        if (! filter) return this.exchanges;
        return this.exchanges.filter(item => JSON.stringify(_.pick(item, ['user', 'date', 'text', 'file', 'edition_date'])).toLowerCase().indexOf(filter) > -1);
      },
      companyusers() {
        const { user } = this.$store.state.users;
        if (! (user && user.company && user.company.users)) return [];
        return user.company.users.map(item => _.extend(angular.copy(item), { atvalue: `@${(item.firstname || item.email).toLowerCase()}` }));
      },
      atUsersOptions() {
        let lastword = this.newMsg.split(" ").at(-1);
        if (! lastword || lastword.charAt(0) != '@') return [];
        lastword = lastword.toLowerCase();
        return this.companyusers.filter(user => user.atvalue.startsWith(lastword));
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    watch: {
      parentvisible(newVal, oldVal) {
        if (newVal && ! oldVal) {
          this.autoscroll();
          if (this.config.autofocus) this.setFocus();
        }
      },
      filteredExchanges() {
        this.autoscroll();
      },
      localcomments() {
        this.loadExchanges();
      },
    },
    created() {
      this.loadExchanges();

      // real time updates
      if (this.apiStored) {
        const params = { target_type: 'exchangeCenter' };
        if (this.planning_id && this.element_id) {
          _.extend(params, { section_type: 'planning', section_id: this.planning_id, target_type: 'element', target_id: this.element_id, field_type: 'comment' });
        } else if (this.planning_id) {
          _.extend(params, { section_type: 'planning', section_id: this.planning_id, field_type: 'comment' });
        }
        window.notificationsSrv.checkExchangeCenterUpdates(params, (comment) => {
          if (comment?.id) {
            const exchange = _.extend(new this.Comment(), comment);
            const index = this.exchanges.findIndex(item => item.id == comment.id);
            if (index > -1) {
              this.exchanges.splice(index, 1, exchange);
            } else {
              this.exchanges.push(exchange);
            }
          } else {
            this.loadExchanges();
          }
        });
      }
    },
    mounted() {
      this.newMsgInputNode = this.$refs.newMsgInput.$el;
      if (this.config.autofocus) this.setFocus();
    },
    beforeUnmount() {
      if (this.apiStored) {
        const params = { target_type: 'exchangeCenter' };
        if (this.planning_id && this.element_id) {
          _.extend(params, { section_type: 'planning', section_id: this.planning_id, target_type: 'element', target_id: this.element_id, field_type: 'comment' });
        } else if (this.planning_id) {
          _.extend(params, { section_type: 'planning', section_id: this.planning_id, field_type: 'comment' });
        }
        window.notificationsSrv.stopCheckingExchangeCenterUpdates(params);
      }
    },
    methods: {
      setFocus() {
        setTimeout(() => { if (this.$refs.newMsgInput) this.$refs.newMsgInput.focus(); });
      },
      autoscroll() {
        this.$nextTick(() => { $(this.$refs.chatContent).scrollTop(this.$refs.chatContent.scrollHeight); });
      },
      openFileDialog() {
        /* if you miss the upload component inside the button (mostly IE), open file dialog in js */
        if (! this.isPremium) return;
        const input = this.$refs.upload.$el.querySelector('input');
        if (input) input.click();
      },
      selectAt(at) {
        if (! this.config.search) return;
        this.searchFilter = at.substr(1);
        this.$refs.searchBar.focus();
      },
      selectHashtag(hashtag) {
        if (! this.config.search) return;
        this.searchFilter = hashtag;
        this.$refs.searchBar.focus();
      },
      onSelectAtUser(user) {
        const words = this.newMsg.split(" ");
        if (! words.length) return;
        words[words.length - 1] = user.atvalue;
        this.newMsg = words.join(" ");
        this.preventPostMessage = true;
        setTimeout(() => { this.preventPostMessage = false; }, 300);
      },
      loadExchanges() {
        this.loading = { inprogress: true };
        return this.Comment.loadExchanges(this.config, this.localcomments).then((response) => {
          this.exchanges = response?.data?.exchanges || [];
          this.loading = { inprogress: false, success: true };
        }).catch((message) => {
          this.loading = { inprogress: false, error: message || "Unknown error" };
        });
      },
      postMessage() {
        if (this.preventPostMessage) return;
        const file = this.newFiles[0];
        if (! this.newMsg && ! file) return;

        const comment = reactive(new this.Comment({
          planning_id: this.planning_id,
          element_id: this.element_id,
          text: this.newMsg,
          user_id: this.$store.state.users.user.id,
          waiting: true,
        }));

        if (file) {
          if (! file.success) return; // upload in progress
          comment.file_id = file.response?.file?.id || 0;
        }
        comment.store().then(() => {
          comment.waiting = false;
          this.$emit('add', comment.getData());
          if (comment.planning_id) this.sendSocketNotif(comment);
        }).catch((message) => {
          this.$store.dispatch('ui/msgbox/open', { title: "Error : message could not be sent", body: message || "" });
          const lastComment = this.exchanges.pop();
          this.newMsg = lastComment.text;
          if (file) this.newFiles = [file];
          comment.waiting = false;
        });

        this.exchanges.push(comment);
        this.newMsg = "";
        this.newFiles = [];
      },
      editComment(comment) {
        if (! comment.id) return;
        const newtext = prompt(this.$t('EXCHANGECENTER.EDIT_MESSAGE'), comment.text);
        if (newtext === null || newtext === comment.text) return;
        const originalComment = { ...comment };
        _.extend(comment, {
          text: newtext,
          edition_date: moment().format(),
          waiting: true,
        });
        comment.editMessage(newtext).then(() => {
          comment.waiting = false;
          this.$emit('update', comment.getData());
          if (comment.planning_id) this.sendSocketNotif(comment);
        }).catch((message) => {
          this.$store.dispatch('ui/msgbox/open', { title: "Error : message could not be edited", body: message || "" });
          _.extend(comment, {
            text: originalComment.text,
            edition_date: originalComment.edition_date,
            waiting: false,
          });
        });
      },
      deleteComment(comment) {
        if (! comment.id) return;
        this.$store.dispatch('ui/msgbox/open', {
          title: this.$t('EXCHANGECENTER.DELETE_MESSAGE_CONFIRMATION'),
          body: comment.text,
          buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
        }).then(() => {
          comment.waiting = true;
          comment.delete().then(() => {
            const index = this.exchanges.findIndex(item => item.id == comment.id);
            if (index > -1) this.exchanges.splice(index, 1);
            this.$emit('delete', comment.getData());
          }).catch((message) => {
            this.$store.dispatch('ui/msgbox/open', { title: "Error : message could not be deleted", body: message || "" });
          }).finally(() => {
            comment.waiting = false;
          });
        }).catch(() => {});
      },
      inputFile(newFile, oldFile) {
        // Automatic upload
        if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
          if (! this.$refs.upload.active) this.$refs.upload.active = true;
        }
      },
      fileErrorMessage(file) {
        if (! file.error) return "";
        if (file.error === 'size') return this.$t('EXCHANGECENTER.FILE_SIZE_LIMITED');
        if (file.response) return file.response.message || file.response.status;
        return file.error;
      },
      sendSocketNotif(comment) {
        if (comment.planning_id && comment.element_id) {
          const el = this.$store.state.planning.elements.find(item => item.id == comment.element_id);
          window.notificationsSrv.callEvent('planning.commentElement', { comment, el });
        } else if (comment.planning_id) {
          window.notificationsSrv.callEvent('exchangeCenter.newMessage', {
            comment, section_type: 'planning', section_id: comment.planning_id,
          });
        }
      },
    },
  };
</script>
