<template>
  <div class="dependencies-manager pa-2">
    <v-row class="flex-nowrap">
      <v-col cols="4" style="position: relative; flex-shrink: 1">
        <div class="text-center nobr" style="margin-bottom: 3px">
          <strong>{{ $t('DEPENDENCIES.PREDECESSORS') }} ({{ validDependencies.predecessors.length || 0 }})</strong>
        </div>
        <div v-for="relatedEl in validDependencies.predecessors" style="margin-bottom: 7px">
          <planning-element-view :el="displayableEl(relatedEl)" disable-mini-element
                                 @click="setMainEl(relatedEl)" @select-color="setMainEl(relatedEl)">
            <template #hover-menu>
              <div class="element-menu nobr" style="right: 0; padding: 0" @click.stop>
                <v-btn :title="$t('DEPENDENCIES.EDIT')" icon size="x-small" variant="outlined" @click="editDependency(relatedEl)">
                  <v-icon color="info" size="large">far fa-pencil-alt</v-icon>
                </v-btn>
                <v-btn :title="$t('DEPENDENCIES.REMOVE')" icon size="x-small" variant="outlined" @click="deletePredecessor(relatedEl)">
                  <v-icon color="errorred" size="large">far fa-trash-alt</v-icon>
                </v-btn>
              </div>
            </template>
          </planning-element-view>
        </div>
      </v-col>
      <v-col class="px-0"><v-icon color="medium-emphasis">fas fa-chevron-right</v-icon></v-col>
      <v-col cols="4" class="mb-2" style="max-height:100px; overflow:hidden; flex-shrink: 1">
        <div class="text-center" style="margin-bottom: 3px"><strong>&nbsp;</strong></div>
        <planning-element-view :el="displayedEl" disable-mini-element style="cursor: default">
        </planning-element-view>
      </v-col>
      <v-col class="px-0"><v-icon color="medium-emphasis">fas fa-chevron-right</v-icon></v-col>
      <v-col cols="4" style="flex-shrink: 1">
        <div class="text-center nobr" style="margin-bottom: 3px">
          <strong>{{ $t('DEPENDENCIES.SUCCESSORS') }} ({{ validDependencies.successors.length || 0 }})</strong>
        </div>
        <div v-for="relatedEl in validDependencies.successors" style="margin-bottom:7px">
          <planning-element-view :el="displayableEl(relatedEl)" disable-mini-element
                                 @click="setMainEl(relatedEl)" @select-color="setMainEl(relatedEl)">
            <template #hover-menu>
              <div class="element-menu nobr" style="right: 0; padding: 0" @click.stop>
                <v-btn :title="$t('DEPENDENCIES.EDIT')" icon size="x-small" variant="outlined" @click="editDependency(relatedEl)">
                  <v-icon color="info" size="large">far fa-pencil-alt</v-icon>
                </v-btn>
                <v-btn :title="$t('DEPENDENCIES.REMOVE')" icon size="x-small" variant="outlined" @click="deleteSuccessor(relatedEl)">
                  <v-icon color="errorred" size="large">far fa-trash-alt</v-icon>
                </v-btn>
              </div>
            </template>
          </planning-element-view>
        </div>
      </v-col>
    </v-row>
    <div class="my-4"></div>
    <div>
      <b class="text-primary">{{ $t('DEPENDENCIES.ADD_NEW') }}</b>
      <div class="my-2 text-medium-emphasis">{{ $t('DEPENDENCIES.AUTOMATIC_UPDATE') }}</div>
      <v-autocomplete v-model="newTarget" :placeholder="$t('DEPENDENCIES.LOOK_FOR_BUBBLE')"
                      :items="otherElements" :item-title="(el) => el.getTitle() || defaultTitle(el)" item-value="id" return-object
                      @update:model-value="onNewTargetSelected">
        <template #item="{ props, item: { raw: item } }">
          <v-divider v-if="item.divider"></v-divider>
          <v-list-subheader v-else-if="item.header" :title="item.header"></v-list-subheader>
          <v-list-item v-else v-bind="props">
            <template #title>
              <span class="text-truncate" style="max-width: 200px">
                {{ props.title }}
              </span>
              <small class="ml-2 text-medium-emphasis">
                {{ $filters.moment(item.getStartTime(), 'mediumDateNoYear', planningLocale) }}
              </small>
              <small v-if="! item.isType('milestone')" class="text-medium-emphasis">
                &nbsp;- {{ $filters.moment(item.getEndTime(), 'mediumDateNoYear', planningLocale) }}
              </small>
            </template>
          </v-list-item>
        </template>
      </v-autocomplete>
      <div class="pt-2 d-flex align-center">
        <v-select v-model="isSuccessor" :items="[{ value: false, title: $t('DEPENDENCIES.PREDECESSOR') }, { value: true, title: $t('DEPENDENCIES.SUCCESSOR') }]"
                  class="mr-4">
        </v-select>
        <span>{{ $t('DEPENDENCIES.DELAY') }} :</span>
        <v-text-field v-model.number="newTargetDelay" type="number" class="mx-2" style="flex: 0 0 70px"></v-text-field>
        <span>{{ $t('GLOBAL.DAYS', newTargetDelay) }}</span>
      </div>
      <v-btn :disabled="! newTarget" color="primary" class="mt-2" rounded size="large" @click="addDependency()">
        {{ $t(newTargetIsAlreadyDependency ? 'DEPENDENCIES.MODIFY' : 'DEPENDENCIES.ADD') }}
      </v-btn>
    </div>

    <div class="my-4"></div>
    <div><b class="text-primary">{{ $t('GLOBAL.OPTIONS') }}</b></div>
    <div class="ml-6">
      <v-switch v-model="planning.config.displayDependencies" :label="$t('DEPENDENCIES.DISPLAY_ON_PLANNING')"></v-switch>
      <v-switch v-model="planning.config.displayCriticalPath" :disabled="! planning.config.displayDependencies" :label="$t('DEPENDENCIES.DISPLAY_CRITICAL_PATH')"></v-switch>
    </div>

    <div class="my-4"></div>
    <div><b class="text-primary">{{ $t('DEPENDENCIES.OPTIMIZATION') }}</b></div>
    <div class="my-2">
      <v-btn :disabled="! elHasDependencies" variant="outlined" color="primary" rounded @click="reduceElDependenciesToMinimum()">
        {{ $t('DEPENDENCIES.REDUCE_ELEMENT_SUCCESSORS') }}
      </v-btn>
      <v-btn v-if="canReduceElPredecessorsToMinimum" :disabled="! elHasDependencies" variant="outlined" color="primary" rounded class="mt-4" @click="reduceElDependenciesToMinimum('predecessors')">
        {{ $t('DEPENDENCIES.REDUCE_ELEMENT_PREDECESSORS') }}
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss">
  .dependencies-manager .element {
    position: relative;
    box-shadow: none;
    border-bottom: none !important;

    &.milestone {
      padding-top: 20px;
    }
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import DependenciesSrv from '@/components/Dependencies/DependenciesSrv';
  import PlanningElementView from '@/components/Planning/PlanningElementView';

  export default {
    components: {
      PlanningElementView,
    },
    props: {
      planning: { type: Object, required: true },
      element: { type: Object, required: true },
    },
    emits: ['close'],
    data() {
      return {
        el: this.element,
        newTarget: null,
        isSuccessor: false,
        newTargetDelay: 0,
      };
    },
    computed: {
      displayedEl() {
        return this.displayableEl(this.el);
      },
      validDependencies() {
        const validDependencies = { predecessors: [], successors: [] };
        (this.el.getDependencies() || []).forEach((dependency) => {
          const targetEl = this.planning.elements.find(el => el.id == dependency.target_id);
          if (! targetEl) {
            this.deleteDependency(this.el, { id: dependency.target_id }, dependency.successor);
          } else if (dependency.successor) {
            validDependencies.successors.push(targetEl);
          } else {
            validDependencies.predecessors.push(targetEl);
          }
        });
        return validDependencies;
      },
      newTargetIsAlreadyDependency() {
        if (! this.newTarget || typeof this.newTarget !== 'object') return false;
        return (this.el.getDependencies() || []).findIndex(dependency => dependency.target_id == this.newTarget.id) > -1;
      },
      otherElements() {
        return this.planning.elements.filter((item) => {
          return item.id != this.el.id && item.hasDates() && ! item.isType('macro');
        });
      },
      elHasDependencies() {
        return !! this.el.getDependencies()?.length;
      },
      canReduceElPredecessorsToMinimum() {
        return this.$store.state.users.accessRight.canReduceElPredecessorsToMinimum;
      },
      planningLocale() {
        return this.planning.timeline.getTimelocale();
      },
    },
    methods: {
      displayableEl(el) {
        const displayableEl = angular.copy(el);
        displayableEl.ytextposition = 20;
        displayableEl.setConfig({ 'show-date': displayableEl.getConfig('show-date') });
        return displayableEl;
      },
      deleteDependency(fromEl, relatedEl, isSuccessor) {
        const dependencies = fromEl.getDependencies() || [];
        const index = dependencies.findIndex(dependency => dependency.target_id == relatedEl.id && ! dependency.successor == ! isSuccessor);
        if (index > -1) {
          dependencies.splice(index, 1);
          fromEl.setDependencies(dependencies);
        }
      },
      deletePredecessor(relatedEl) {
        this.deleteDependency(this.el, relatedEl, false);
        this.deleteDependency(relatedEl, this.el, true);
      },
      deleteSuccessor(relatedEl) {
        this.deleteDependency(this.el, relatedEl, true);
        this.deleteDependency(relatedEl, this.el, false);
      },
      addDependency() {
        if (! this.newTarget || typeof this.newTarget !== 'object' || this.newTarget.id == this.el.id) return;

        const elDependencies = this.el.getDependencies() || [];
        const newTargetDependencies = this.newTarget.getDependencies() || [];

        let index = elDependencies.findIndex(item => item.target_id == this.newTarget.id);
        if (index > -1) elDependencies.splice(index, 1);
        index = newTargetDependencies.findIndex(item => item.target_id == this.el.id);
        if (index > -1) newTargetDependencies.splice(index, 1);

        elDependencies.push({ target_id: this.newTarget.id, successor: this.isSuccessor, delay: this.newTargetDelay });
        this.el.setDependencies(elDependencies);
        newTargetDependencies.push({ target_id: this.el.id, successor: ! this.isSuccessor, delay: this.newTargetDelay });
        this.newTarget.setDependencies(newTargetDependencies);
        this.newTarget = null;
      },
      editDependency(relatedEl) {
        const dependencies = this.el.getDependencies() || [];
        const index = dependencies.findIndex(item => item.target_id == relatedEl.id);
        if (index == -1) return;
        this.newTarget = relatedEl;
        this.isSuccessor = dependencies[index].successor || false;
        this.newTargetDelay = dependencies[index].delay || 0;
      },
      setMainEl(newel) {
        this.el = newel;
      },
      defaultTitle(el) {
        if (el.isType('macro')) return this.$t('PLANNING.NEW_MACRO_BUBBLE');
        return el.isType('milestone') ? this.$t('PLANNING.MILESTONE') : this.$t('PLANNING.NEW_BUBBLE');
      },
      onNewTargetSelected(newTarget) {
        this.isSuccessor = newTarget.getStartTime().isAfter(this.el.getStartTime());
      },
      reduceElDependenciesToMinimum(direction = 'successors') {
        const relatedEls = this.planning.elements.filter(item => item.getDependencies()?.length && item.hasDates()).map(el => angular.copy(el));
        const relatedEl = relatedEls.find(item => item.id === this.el.id);
        if (! relatedEl) return;
        this.$emit('close');

        let reducedElements;
        let title;
        let body;
        if (direction == 'predecessors') {
          reducedElements = DependenciesSrv.calculateElPredecessors(new Set(), relatedEls, relatedEl);
          title = this.$t('DEPENDENCIES.REDUCE_ELEMENT_PREDECESSORS_CONFIRMATION_TITLE', reducedElements.size);
          body = this.$t('DEPENDENCIES.NO_PREDECESSORS_TO_REDUCE');
        } else { // successors
          reducedElements = DependenciesSrv.calculateElSuccessors(new Set(), relatedEls, relatedEl);
          title = this.$t('DEPENDENCIES.REDUCE_ELEMENT_SUCCESSORS_CONFIRMATION_TITLE', reducedElements.size);
          body = this.$t('DEPENDENCIES.NO_SUCCESSORS_TO_REDUCE');
        }

        if (! reducedElements.size) { // nothing to change
          this.$store.dispatch('ui/msgbox/open', {
            title,
            body,
            buttons: { ok: 'GLOBAL.OK' },
          });
          return;
        }

        body = [...reducedElements].map((el) => {
          const elementTypeText = this.$t(el.isType('milestone') ? 'GLOBAL.MILESTONE' : 'GLOBAL.BUBBLE');
          return `<div>- ${elementTypeText} « ${el.getTitle() || this.defaultTitle(el)} »</div>`;
        }).join('');
        this.$store.dispatch('ui/msgbox/open', {
          title,
          body,
          buttons: { ok: 'GLOBAL.OK', cancel: 'GLOBAL.CANCEL' },
        }).then(() => { // apply modifications
          this.startChangingElement();
          reducedElements.forEach((el) => {
            const realEl = this.$store.getters['planning/elements/getElement'](el.id);
            realEl.setStartTime(el.getStartTime());
            realEl.setEndTime(el.getEndTime());
            realEl.update();
          });
          this.changingElement();
        }).catch(() => {});
      },
      ...mapActions('planning/elements', ['startChangingElement', 'changingElement']),
    },
  };
</script>
