<template>
  <modal v-if="lane.id" :backdrop="false" :target="`#laneheader${target_id}`" movable @close="closeModal">
    <monitoring-plan-lane-modal :monitoring-lane="monitoringLane" @close="closeModal" @update="saveEditingLane"></monitoring-plan-lane-modal>
  </modal>
</template>

<script>
  import { mapState } from 'vuex';
  import MonitoringPlanLaneModal from './MonitoringPlanLaneModal';

  export default {
    components: {
      MonitoringPlanLaneModal,
    },
    props: {
      planning_id: { type: Number, required: true },
      lane: { type: Object, required: true },
    },
    emits: ['close'],
    data() {
      return {
      };
    },
    computed: {
      monitoringLane() {
        return this.monitoringPlanByPlanningsByLanes[this.lane.getPlanning().id]
          && this.monitoringPlanByPlanningsByLanes[this.lane.getPlanning().id][this.lane.id] || {};
      },
      target_id() {
        return this.lane.id;
      },
      ...mapState('monitoringplan', ['monitoringPlanByPlanningsByLanes']),
    },
    methods: {
      saveEditingLane(data) {
        if (data.steps) this.monitoringLane.steps = data.steps;
        if (data.comments) this.monitoringLane.comments = data.comments;
        window.apiSrv.call("dashboards/monitoringplan", 'store', {
          planning_id: this.planning_id,
          lane_id: this.lane.id,
          steps: this.monitoringLane.steps,
          comments: this.monitoringLane.comments,
        });
        this.$store.commit('monitoringplan/setMonitoringLane', { lane: this.lane, monitoringLane: this.monitoringLane });
        this.closeModal();
      },
      closeModal() {
        this.$emit('close');
      },
    },
  };
</script>
