<template>
  <div>
    <navigation></navigation>

    <div v-if="isPremium">
      <v-progress-linear v-if="! loading.complete" :model-value="loading.counter.toLoad && loading.counter.loaded ? loading.counter.loaded / loading.counter.toLoad * 100 : 2"
                         color="accent" rounded>
      </v-progress-linear>

      <dashboards-filters v-if="loading.complete" :filters="['Projects', 'Dates']">
        <template #append-projects>
          <v-switch v-model="portfolioOptions.displayArchived" class="ml-4" style="flex: 1 0 100px">
            <template #label><small style="line-height: 1.2em">{{ $t('MULTIPROJECTS.DISPLAY_ARCHIVED_PROJECTS') }}</small></template>
          </v-switch>
          <div class="dashboard-filters-spacer"></div>
          <project-owners-filter :archived-projects="archivedProjects"></project-owners-filter>
        </template>
        <template #default>
          <div class="dashboard-filters-spacer"></div>
          <portfolio-display-filter :headers="headers"></portfolio-display-filter>
        </template>
        <template #append>
          <v-spacer style="flex-grow: 2; min-width: 8px"></v-spacer>
          <dashboards-filters-export :exporting="exporting" pdf csv @pdf-export="pdfExport" @csv-export="csvExport"></dashboards-filters-export>
        </template>
      </dashboards-filters>

      <div v-if="loading.complete" v-show="viewIsReady" id="portfolio-body">
        <!-- Table view -->
        <template v-if="portfolioOptions.portfolioView == 'table-view'">
          <portfolio-stats :filtered-projects="filteredProjects" :view-is-ready="viewIsReady"></portfolio-stats>
          <v-data-table v-model:expanded="expanded" :headers="filteredHeaders" :items="filteredProjects" :custom-sort="customSort" :options="tableOptions" show-expand
                        @update:options="savePortfolioTableOptions">
            <template #item="{ item: project, internalItem, isExpanded, toggleExpand }">
              <portfolio-row :filtered-headers="filteredHeaders" :row-data="project" :is-expanded="isExpanded(internalItem)" @expand="toggleExpand(internalItem)">
              </portfolio-row>
            </template>
            <template #expanded-row="{ item: project }">
              <portfolio-row v-for="lane in projectLanes(project.id)" :key="lane.id" :filtered-headers="filteredHeaders" :row-data="lane" is-lane>
              </portfolio-row>
            </template>
            <template #bottom></template>
          </v-data-table>
        </template>
        <!-- Planning view -->
        <portfolio-planning-view v-else :filtered-headers="filteredHeaders" :filtered-projects="filteredProjects"></portfolio-planning-view>
      </div>
      <div v-if="loading.complete && ! viewIsReady" class="text-center pa-4" style="font-size: 18px">
        <i class="far fa-spinner fa-spin fa-2x fa-fw"></i>
      </div>
    </div>

    <div v-if="userLoaded && ! isPremium">{{ $t('PREMIUM.SECTION_IS_PREMIUM') }}</div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState } from 'vuex';
  import workloadMixin from '@/components/Workload/workloadMixin';
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import Navigation from '@/components/Navigation/Navigation.vue';
  import DashboardsFilters from '../DashboardsFilters/DashboardsFilters.vue';
  import ProjectOwnersFilter from '../DashboardsFilters/ProjectOwnersFilter.vue';
  import PortfolioDisplayFilter from '../DashboardsFilters/PortfolioDisplayFilter.vue';
  import DashboardsFiltersExport from '../DashboardsFilters/DashboardsFiltersExport.vue';
  import PortfolioStats from './PortfolioStats.vue';
  import PortfolioRow from './PortfolioRow.vue';

  export default {
    components: {
      Navigation,
      DashboardsFilters,
      ProjectOwnersFilter,
      PortfolioDisplayFilter,
      DashboardsFiltersExport,
      PortfolioStats,
      PortfolioRow,
      PortfolioPlanningView: defineAsyncComponent(() => import('./PortfolioPlanningView.vue')),
    },
    mixins: [workloadMixin],
    data() {
      const portfolioTableOptions = window.safeParseJSON(window.localStorageWrapper.getItem('portfolioTableOptions')) || {};

      return {
        tableHeaders: [
          { title: this.$t('PORTFOLIO.MOOD'), key: 'mood', width: '0px', csvCols: ['moodIcon', 'waypoint'] },
          { title: this.$t('PORTFOLIO.CATEGORY'), key: 'category', csvCols: ['category'] },
          { title: this.$t('PORTFOLIO.PROJECT'), key: 'title', csvCols: ['title'] },
          { title: this.$t('PORTFOLIO.OWNER'), key: 'owner', csvCols: ['company', 'ownerUsername'] },
          { title: this.$t('PORTFOLIO.STATUS'), key: 'status', csvCols: ['updated_at', 'archived_at'] },
          { title: this.$t('PORTFOLIO.DURATION'), key: 'dates', csvCols: ['datesData.start', 'datesData.end'] },
          { title: this.$t('PORTFOLIO.ENDTIME'), key: 'endtime', csvCols: [] },
          { title: this.$t('PORTFOLIO.ACTIONS'), key: 'actions', csvCols: ['actionsData.total', 'actionsData.finished', 'actionsData.urgent', 'actionsData.overdue', 'actionsData.future'] },
          { title: this.$t('PORTFOLIO.WORKLOAD'), key: 'workload', csvCols: ['workloadData.estimated', 'workloadData.used'] },
          { title: this.$t('PORTFOLIO.PROGRESS'), key: 'progress', csvCols: ['progressData.total', 'progressData.finished', 'progressData.inprogress', 'progressData.overdue', 'progressData.future'] },
          { title: this.$t('PORTFOLIO.USERS'), key: 'users', csvCols: ['users'] },
          { title: this.$t('PORTFOLIO.BUDGET'), key: 'budget', csvCols: ['budget'] },
        ],
        expanded: [],
        archivedProjects: [],
        viewIsReady: false,
        tableOptions: {
          itemsPerPage: -1,
          sortBy: portfolioTableOptions.sortBy || ['title'],
          sortDesc: portfolioTableOptions.sortDesc || [false],
        },
        exporting: { inprogress: false, success: false, error: false },
      };
    },
    computed: {
      headers() {
        if (this.portfolioOptions.portfolioView == 'table-view') return this.tableHeaders;
        const planningHeadersValues = new Set(['dates', 'actions', 'progress', 'users', 'budget']);
        return this.tableHeaders.filter(col => planningHeadersValues.has(col.key)); // planning-view
      },
      filteredHeaders() {
        return [{ key: 'expand', sortable: false }, ...this.headers.filter(col => this.portfolioOptions.selectedCols[col.key])];
      },
      allProjects() {
        return this.projects.concat(this.archivedProjects).map((project) => {
          const projectsheetCurrentStep = project.projectsheet?.steps?.at(-1);
          const ownerData = this.$store.getters['users/getUserById'](project.meta ? project.meta.owner_id : project.owner_id);
          const company = this.company(project);
          const datesData = this.dates(project.elements);
          const actionsData = this.actions(project.elements);
          const workloadData = this.workload(project.elements);
          const progressData = this.progress(project.elements);
          const progressAverage = this.progressAverage(project.elements);
          const usersData = this.users(project.elements);
          const budgetData = this.budget(project.elements);
          return {
            id: project.id,
            title: (project.getTitle ? project.getTitle() : project.title) || this.$t('PLANNING.UNNAMED_PROJECT'),
            category: project.meta?.category || project.category || null, // project.category for archived projects
            access_right: project.meta ? project.meta.access_right : project.access_right,
            owner: ownerData ? `${company}.${this.$store.getters['users/getUsername'](ownerData)}` : null,
            ownerData,
            ownerUsername: ownerData ? this.$store.getters['users/getUsername'](ownerData) : null,
            company,
            mood: (['storm', 'cloud', 'suncloud', 'sun'].indexOf(projectsheetCurrentStep && projectsheetCurrentStep.mood) + 1) || null,
            moodIcon: projectsheetCurrentStep && projectsheetCurrentStep.mood,
            waypoint: projectsheetCurrentStep && projectsheetCurrentStep.waypoint,
            status: project.deleted_at ? `0-${moment(project.deleted_at).format('YYYY-MM-DD')}` : moment(project.meta && project.meta.date_of_modification || null).format('YYYY-MM-DD'),
            updated_at: moment(project.meta && project.meta.date_of_modification || null),
            archived_at: project.deleted_at ? moment(project.deleted_at) : '',
            dates: datesData.start && datesData.end && moment.duration(datesData.end.diff(datesData.start)) || null,
            duration: datesData.start && datesData.end && moment.duration(datesData.end.diff(datesData.start)) || null,
            datesData,
            starttime: datesData.start && moment(datesData.start).format('YYYY-MM-DD') || null,
            endtime: datesData.end && moment(datesData.end).format('YYYY-MM-DD') || null,
            endtimeTarget: project.projectsheet && project.projectsheet.target_endtime,
            actions: actionsData.total ? actionsData.finished / actionsData.total : null,
            actionsData,
            workload: workloadData.estimated ? workloadData.used / workloadData.estimated : null,
            workloadData,
            progress: progressData.total ? progressData.finished / progressData.total : null,
            progressData,
            progressAverage,
            users: Object.keys(usersData).length,
            usersData,
            budget: Object.keys(budgetData).reduce((acc, icon) => acc + (budgetData[icon].amount || budgetData[icon].amount_inprogress), 0),
            budgetData,
            budgetTarget: project.projectsheet && project.projectsheet.target_budget,
          };
        });
      },
      filteredProjects() {
        return this.allProjects.filter((project) => {
          if (! project.archived_at && ! this.selectedProjects.find(item => item == project.id)) return false;
          if (this.selectedDates.starttime || this.selectedDates.endtime) {
            if (project.archived_at && this.selectedDates.starttime && project.archived_at.isBefore(this.selectedDates.starttime)) return false;
            if (project.archived_at && this.selectedDates.endtime && project.archived_at.isAfter(this.selectedDates.endtime)) return false;
            let projectEndTime = project.endtime && moment(project.endtime);
            if (project.endtimeTarget) projectEndTime = projectEndTime ? moment.max(projectEndTime, moment(project.endtimeTarget)) : moment(project.endtimeTarget);
            if (! projectEndTime) return false;
            if (this.selectedDates.starttime && projectEndTime.isBefore(this.selectedDates.starttime)) return false;
            if (this.selectedDates.endtime && projectEndTime.startOf('day').isAfter(this.selectedDates.endtime)) return false;
          }
          if (project.archived_at && ! this.portfolioOptions.displayArchived) return false;
          if (project.ownerData && project.ownerData.id && ! this.portfolioOptions.selectedProjectOwners.find(item => item == project.ownerData.id)) return false;
          return true;
        });
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      userLoaded() { return this.$store.state.users.user.id > 0; },
      ...mapState('multiprojects', ['loading', 'projects', 'selectedProjects', 'selectedDates', 'portfolioOptions']),
    },
    watch: {
      'loading.complete': function (newVal) {
        if (! newVal) {
          this.viewIsReady = false;
        } else {
          this.$store.state.users.userPromise.then(() => {
            this.viewIsReady = true;
          });
        }
      },
    },
    created() {
      this.$store.commit('multiprojects/loadFilters', { dashboardName: this.$route.path.slice(1).replace(/\//g, '_') });
      this.$store.dispatch('multiprojects/load');
      this.loadArchivedProjects();
    },
    methods: {
      projectLanes(projectId) {
        const project = this.projects.find(item => item.id == projectId);
        return (project && project.lanes || []).map((lane) => {
          const laneElements = this.laneElements(project, lane);
          const datesData = this.dates(laneElements);
          const actionsData = this.actions(laneElements);
          const workloadData = this.workload(laneElements);
          const progressData = this.progress(laneElements);
          const usersData = this.users(laneElements);
          const budgetData = this.budget(laneElements);
          return {
            id: lane.id,
            project_id: project.id,
            title: lane.label,
            duration: datesData.start && datesData.end && moment.duration(datesData.end.diff(datesData.start)) || null,
            datesData,
            endtime: datesData.end && moment(datesData.end).format('YYYY-MM-DD') || null,
            actionsData,
            workloadData,
            progressData,
            usersData,
            budgetData,
          };
        });
      },
      customSort(items, [index], [isDescending]) {
        // https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/mixins/data-iterable.js
        if (index === null) return items;
        return items.sort((a, b) => {
          let sortA = a[index];
          let sortB = b[index];
          // Check if both cannot be evaluated
          if (sortA === null && sortB === null) {
            return 0;
          }
          if (sortA === null) return 1;
          if (sortB === null) return -1;
          if (isDescending) {
            [sortA, sortB] = [sortB, sortA];
          }
          // Check if both are numbers
          /* eslint no-restricted-globals:0 */
          if (! isNaN(sortA) && ! isNaN(sortB)) {
            return sortA - sortB;
          }
          [sortA, sortB] = [sortA, sortB].map(s => (
            (s || '').toString().toLocaleLowerCase()
          ));
          if (sortA > sortB) return 1;
          if (sortA < sortB) return -1;
          return 0;
        });
      },
      dates(elements) {
        let projectstart;
        let projectend;
        (elements || []).forEach((el) => {
          const elStartTime = el.getStartTime();
          if (elStartTime) projectstart = projectstart ? moment.min(projectstart, elStartTime) : elStartTime;
          const elEndTime = el.getEndTime();
          if (elEndTime) projectend = projectend ? moment.max(projectend, elEndTime) : elEndTime;
        });
        return { start: projectstart, end: projectend };
      },
      company(project) {
        if (! this.$store.state.users.user.organization) return null;
        if (project.company_id) {
          return (this.$store.state.users.user.organization.getCompanies().find(item => item.id == project.company_id) || {}).name || '';
        }

        const ownerId = project.meta && project.meta.owner_id;
        if (! ownerId) return '';
        let companyName = '';
        this.$store.state.users.user.organization.forEachSubOrga(this.$store.state.users.user.organization, (orga) => {
          if (! orga || ! orga.company) return;
          if (orga.company.users && orga.company.users.find(user => user.id == ownerId)) {
            companyName = orga.company.name;
          }
        });
        return companyName;
      },
      actions(elements) {
        const stats = {
          finished: 0,
          urgent: 0,
          overdue: 0,
          future: 0,
          total: 0,
        };
        (elements || []).forEach((el) => {
          if (! el.getChecklist()) return;
          el.getChecklist().forEach((action) => {
            const color = el.getChecklistItemColor(action);
            if (action.checked) {
              stats.finished++;
            } else if (color === 'errorred') {
              stats.overdue++;
            } else if (color === 'warningorange') {
              stats.urgent++;
            } else {
              stats.future++;
            }
            stats.total++;
          });
        });
        return stats.total ? stats : {};
      },
      workload(elements) {
        const stats = {
          estimated: 0,
          used: 0,
        };
        (elements || []).forEach((el) => {
          if (! el.getChecklist()) return;
          el.getChecklist().forEach((action) => {
            stats.used += this.workloadToDisplay(action.workload_used || 0);
            stats.estimated += this.workloadToDisplay(action.workload || action.workload_used || 0);
          });
        });
        stats.estimated = Math.round(stats.estimated * 100) / 100;
        stats.used = Math.round(stats.used * 100) / 100;
        return (stats.estimated || stats.used) ? stats : {};
      },
      progress(elements) {
        const stats = {
          finished: 0,
          inprogress: 0,
          overdue: 0,
          future: 0,
          total: 0,
        };
        (elements || []).forEach((el) => {
          if (! el.isType('task')) return;
          let id;
          if (el.getProgress() == 100) {
            id = 'finished';
          } else if (el.getEndTime()?.isBefore()) {
            id = 'overdue';
          } else if (el.getStartTime()?.isAfter()) {
            id = 'future';
          } else {
            id = 'inprogress';
          }
          stats[id]++;
          stats.total++;
        });
        return stats.total ? stats : {};
      },
      progressAverage(elements) {
        let sum = 0;
        let count = 0;
        (elements || []).forEach((el) => {
          if (! el.isType('macro', 'task')) return;
          sum += el.getProgress();
          count++;
        });
        return count ? Math.round(sum / count) : 0;
      },
      users(elements) {
        const users = {};
        (elements || []).forEach((item) => {
          if (! item.getUsers()) return;
          item.getUsers().forEach((user) => {
            if (! user.id) return;
            users[user.id] = this.$store.getters['users/getUserById'](user.id) || user;
          });
        });
        return users;
      },
      budget(elements) {
        const filteredBudgetElements = (elements || []).filter((el) => {
          const elBudgets = el.getBudgets();
          if (! elBudgets) return false;
          if (! elBudgets.some(budget => budget.amount || budget.amount_inprogress)) return false;
          return true;
        });

        const budgetByIcons = {};
        filteredBudgetElements.forEach((el) => {
          (el.getBudgets() || []).forEach((budget) => {
            const icon = budget.icon || '';
            if (! budgetByIcons[icon]) budgetByIcons[icon] = { amount: 0, amount_inprogress: 0 };
            budgetByIcons[icon].amount += +budget.amount || 0;
            budgetByIcons[icon].amount_inprogress += +budget.amount_inprogress || 0;
          });
        });
        return budgetByIcons;
      },
      loadArchivedProjects() {
        this.archivedProjects = [];
        ProjectSrv.listArchived().then((results) => {
          this.archivedProjects = results;
        });
      },
      laneElements(project, lane) {
        return this.$store.getters['planning/lanes/getLaneElements']({ planning: project, laneId: lane.id });
      },
      savePortfolioTableOptions(options) {
        window.localStorageWrapper.setItem('portfolioTableOptions', JSON.stringify({ sortBy: options.sortBy, sortDesc: options.sortDesc }));
      },
      csvExport() {
        const separator = ';';
        const filename = `${this.$t('PORTFOLIO.TITLE')}.csv`;
        const csvCols = this.filteredHeaders.map(col => col.csvCols || []).reduce((acc, val) => acc.concat(val), []); // reduce(...) could be replaced by flat() with ie polyfill

        let csvContent = `${csvCols.map(name => this.$t(`PORTFOLIO.CSV.${name.toUpperCase()}`)).join(separator)}\n`;
        this.filteredProjects.forEach((project) => {
          const dataString = csvCols.map((key) => {
            const subKeys = key.split('.');
            let val = project[subKeys[0]];
            for (let i = 1; i < subKeys.length; i++) {
              val = val[subKeys[i]];
            }
            if (moment.isMoment(val)) return val.isValid() ? val.format('L') : '';
            if (key == 'moodIcon' && val) return this.$t(`MOOD.${val.toUpperCase()}`);
            if (key == 'waypoint' && val) return window.html2text(val.replace(/(<\/p\s*[/]?>)/gi, '$1 ')).trim();
            return val;
          }).join(separator).replace(/"/g, '""');
          csvContent += `${dataString}\n`;
        });
        if ((navigator.appName == 'Microsoft Internet Explorer') || ((navigator.appName == 'Netscape') && (new RegExp("Trident/.*rv:([0-9]{1,}[.0-9]{0,})").exec(navigator.userAgent) != null))) {
          // IE WORKAROUND
          const IEwindow = window.open();
          IEwindow.document.write(`sep=${separator}\r\n${csvContent}`);
          IEwindow.document.close();
          IEwindow.document.execCommand('SaveAs', true, filename);
          IEwindow.close();
        } else {
          const encodedUri = encodeURI(`data:text/csv;charset=utf-8,\uFEFF${csvContent}`).replace(/#/g, '%23');
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", filename);
          document.body.appendChild(link); // Required for FF
          link.click();
          document.body.removeChild(link);
        }
      },
      pdfExport() {
        _.extend(this.exporting, { inprogress: true, success: false, error: false });
        const $html = document.querySelector("html").cloneNode(true);

        // Clone the canvas
        const originalCanvas = document.querySelectorAll('canvas');
        $html.querySelectorAll("canvas").forEach((el, index) => {
          const image = new Image();
          image.src = originalCanvas[index].toDataURL("image/png");
          image.style.width = '100%';
          el.outerHTML = image.outerHTML;
        });

        $html.querySelector('head').innerHTML += '<style>body { width: 1400px ! important; }</style>';
        const queryEl = $html.querySelector('#portfolio-body') || document.createElement("div");
        $html.querySelector('body').innerHTML = `<div style="padding: 0 20px;">${queryEl.innerHTML}</div>`;
        $html.querySelector('body').classList.add('v-application');
        $html.querySelectorAll(".export-hidden").forEach((el) => { el.parentNode.removeChild(el); });
        $html.querySelectorAll(".v-data-table thead th:first-child").forEach(el => el.style.setProperty('padding', '0px')); // first column (expand)
        $html.querySelectorAll("tr").forEach(el => el.style.setProperty('break-inside', 'avoid'));
        $html.querySelectorAll(".warningorange").forEach(el => el.style.setProperty('background', '#ff9b1d'));
        $html.querySelectorAll(".successgreen").forEach(el => el.style.setProperty('background', '#00b100'));

        window.apiSrv.call('pdf', 'store', { html: $html.innerHTML, orientation: "landscape", footer: this.$t('PORTFOLIO.TITLE') }).then((response) => {
          if (response && response.data && response.data.pdfurl) {
            this.exporting.inprogress = false;
            this.exporting.success = true;
            setTimeout(() => { this.exporting.success = false; }, 3000);
            window.open(`${response.data.pdfurl}/${this.$t('PORTFOLIO.TITLE')}.pdf`, "_blank");
          }
        }).catch((message) => {
          this.exporting.inprogress = false;
          this.exporting.error = message || "Error : not exported";
        });
      },
    },
  };
</script>
