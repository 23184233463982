<template>
  <v-row id="projectsheet">
    <v-col cols="6" style="min-width: min(100%, 600px); max-width: 800px">
      <div>
        <div class="d-flex align-center">
          <b class="text-h6 text-primary">{{ planning.getTitle() || $t('PLANNING.UNNAMED_PROJECT') }}</b>
          <template v-if="isPremium">
            <v-btn-icon :disabled="exporting.inprogress" size="small" class="ml-auto export-hidden" @click="! exporting.inprogress && pdfExport()">
              <v-icon>{{ exporting.inprogress ? 'far fa-spinner fa-spin' : 'far fa-file-pdf' }}</v-icon>
            </v-btn-icon>
          </template>
        </div>
        <v-card class="pa-6 mt-1 d-flex">
          <project-sheet-thumbnail :planning="planning"></project-sheet-thumbnail>
          <div class="ml-4 flex-grow-1">
            <div :title="$t('MANAGE.OWNER')" class="d-flex align-center">
              <img v-if="owner && owner.avatar" :src="owner.avatar" class="mr-2" style="max-height: 20px;border-radius: 50%;">
              <span>{{ $filters.username(owner) }}</span>
            </div>
            <project-sheet-category :planning="planning" class="mt-1"></project-sheet-category>
          </div>
        </v-card>
      </div>

      <div class="py-4"></div>

      <project-sheet-description :planning="planning" @update="$emit('update', $event)"></project-sheet-description>

      <div class="py-4"></div>

      <project-sheet-waypoints :planning="planning" @update="$emit('update', $event)"></project-sheet-waypoints>

      <div class="py-4"></div>

      <project-sheet-links :planning="planning" @update="$emit('update', $event)"></project-sheet-links>
    </v-col>

    <v-col cols="6" class="mt-2" style="min-width: min(100%, 600px); max-width: 800px">
      <div class="d-flex align-center mb-2">
        <v-icon class="mr-2" style="flex: 0 0 24px">far fa-chart-bar</v-icon>
        <b>{{ $t('PROJECT_SHEET.ACTIVITY') }}</b>
      </div>
      <v-card class="pa-6">
        <project-sheet-activity :planning="planning"></project-sheet-activity>
      </v-card>

      <div class="py-4"></div>

      <project-sheet-users :planning="planning" @reload-planning="$emit('reload-planning')"></project-sheet-users>
    </v-col>
  </v-row>
</template>

<script>
  import ProjectSheetThumbnail from './ProjectSheetThumbnail.vue';
  import ProjectSheetCategory from './ProjectSheetCategory.vue';
  import ProjectSheetDescription from './ProjectSheetDescription.vue';
  import ProjectSheetWaypoints from './ProjectSheetWaypoints.vue';
  import ProjectSheetLinks from './ProjectSheetLinks.vue';
  import ProjectSheetUsers from './ProjectSheetUsers.vue';
  import ProjectSheetActivity from './ProjectSheetActivity.vue';

  export default {
    components: {
      ProjectSheetThumbnail,
      ProjectSheetCategory,
      ProjectSheetDescription,
      ProjectSheetWaypoints,
      ProjectSheetLinks,
      ProjectSheetUsers,
      ProjectSheetActivity,
    },
    props: {
      planning: { type: Object, required: true },
    },
    emits: ['update', 'reload-planning'],
    data() {
      return {
        exporting: { inprogress: false, success: false, error: false },
      };
    },
    computed: {
      owner() {
        return this.$store.getters['users/getUserById'](this.planning.meta.owner_id);
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    methods: {
      pdfExport() {
        _.extend(this.exporting, { inprogress: true, success: false, error: false });
        const $html = document.querySelector('html').cloneNode(true);
        const queryEl = $html.querySelector('#projectsheet') || document.createElement('div');
        $html.querySelector('body').innerHTML = `<div class="v-application mx-4"><div class="container container--fluid pa-4">${queryEl.innerHTML}</div></div>`;
        $html.querySelectorAll(".export-hidden").forEach((el) => { el.parentNode.removeChild(el); });
        $html.querySelectorAll(".v-card").forEach(el => el.style.setProperty('break-inside', 'avoid'));

        window.apiSrv.call('pdf', 'store', { html: $html.innerHTML, orientation: "portrait", footer: `${this.$t('PROJECT_SHEET.PROJECT_SHEET')} - ${this.planning.getTitle()}` }).then((response) => {
          if (response && response.data && response.data.pdfurl) {
            this.exporting.inprogress = false;
            this.exporting.success = true;
            setTimeout(() => { this.exporting.success = false; }, 3000);
            window.open(`${response.data.pdfurl}/${this.$t('PROJECT_SHEET.PROJECT_SHEET')}.pdf`, "_blank");
          }
        }).catch((message) => {
          this.exporting.inprogress = false;
          this.exporting.error = message || "Error : not exported";
        });
      },
    },
  };
</script>
