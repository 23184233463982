<template>
  <v-tooltip :disabled="workingViewDropdownIsOpen" location="bottom">
    <template #activator="{ props: onTooltip }">
      <v-menu v-model="workingViewDropdownIsOpen" :close-on-content-click="false" location="start center" z-index="1020">
        <template #activator="{ props: onMenu }">
          <v-btn-icon size="small" v-bind="mergeProps(onMenu, onTooltip)">
            <v-icon :color="planningHasActiveWorkingView ? 'successgreen' : ''" size="large">{{ planningHasActiveWorkingView ? 'fas' : 'far' }} fa-filter</v-icon>
          </v-btn-icon>
        </template>
        <div>
          <v-toolbar color="white" class="rounded pl-2">
            <v-switch v-model="workingViewActivationSwitch" :title="workingViewActivationSwitch ? $t('WORKING_VIEWS.DISABLE_VIEW') : $t('WORKING_VIEWS.ACTIVATE_VIEW')"
                      class="ml-2"
                      @update:model-value="toggleWorkingView()">
            </v-switch>
            <v-select v-model="selectedView" :items="viewsOptions" item-value="id" return-object density="compact"
                      :menu-props="{ location: 'bottom' }" class="ml-3" style="max-width: 200px"
                      @update:model-value="updatePlanning(selectedView, true)">
              <template #selection="{ item: { raw: item } }">
                <div :class="item == newView ? 'text-medium-emphasis font-italic' : null" class="v-select__selection v-select__selection--comma">{{ item.title }}</div>
              </template>
              <template #item="{ props, item: { raw: item } }">
                <v-divider v-if="item.divider"></v-divider>
                <v-list-subheader v-else-if="item.header" :title="item.header"></v-list-subheader>
                <v-list-item v-else v-bind="props">
                  <template #title>
                    <span :class="item == newView ? 'text-medium-emphasis font-italic' : null">{{ item.title }}</span>
                  </template>
                </v-list-item>
              </template>
            </v-select>
            <v-divider vertical class="mx-4"></v-divider>
            <div class="d-flex align-center justify-space-between" style="column-gap: 8px;">
              <!-- Lanes -->
              <v-menu :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn :color="laneFilterActive ? 'successgreen' : null" variant="flat" v-bind="props">
                    {{ $t('WORKING_VIEWS.LANES') }}<v-icon end>fas fa-caret-down</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <working-views-lanes-selection :planning="planning" :config-lanes="selectedView.config.lanes"
                                                 @update:config-lanes="selectedView.config.lanes = $event; updatePlanning(selectedView)">
                  </working-views-lanes-selection>
                </v-card>
              </v-menu>
              <!-- Colors -->
              <v-menu :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn :color="colorFilterActive ? 'successgreen' : null" variant="flat" v-bind="props">
                    {{ $t('WORKING_VIEWS.COLORS') }}<v-icon end>fas fa-caret-down</v-icon>
                  </v-btn>
                </template>
                <v-card class="pa-4">
                  <working-views-elements-color :planning="planning" :config-elements-colors="selectedView.config.elementsColors"
                                                @update:config-elements-colors="selectedView.config.elementsColors = $event; updatePlanning(selectedView)">
                  </working-views-elements-color>
                </v-card>
              </v-menu>
              <!-- Icons -->
              <v-menu :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn :color="iconFilterActive ? 'successgreen' : null" variant="flat" v-bind="props">
                    {{ $t('WORKING_VIEWS.ICONS') }}<v-icon end>fas fa-caret-down</v-icon>
                  </v-btn>
                </template>
                <v-card class="pa-4">
                  <working-views-elements-icon :planning="planning" :config-elements-icons="selectedView.config.elementsIcons"
                                               @update:config-elements-icons="selectedView.config.elementsIcons = $event; updatePlanning(selectedView)">
                  </working-views-elements-icon>
                </v-card>
              </v-menu>
              <!-- Users -->
              <working-views-elements-users :planning="planning" :config-elements-users="selectedView.config.elementsUsers" :active="userFilterActive"
                                            @update:config-elements-users="selectedView.config.elementsUsers = $event; updatePlanning(selectedView)">
              </working-views-elements-users>
              <!-- Display -->
              <v-menu :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn :color="displayFilterActive ? 'successgreen' : null" variant="flat" v-bind="props">
                    {{ $t('WORKING_VIEWS.DISPLAY') }}<v-icon end>fas fa-caret-down</v-icon>
                  </v-btn>
                </template>
                <v-card class="pa-4">
                  <working-views-elements-display v-model:config-selected-display="selectedView.config.selectedDisplay"
                                                  @update:config-selected-display="updatePlanning(selectedView)">
                  </working-views-elements-display>
                </v-card>
              </v-menu>
            </div>
            <v-divider vertical class="ml-4"></v-divider>
            <working-views-navigation-save :planning="planning" :selected-view="selectedView"
                                           @update-view-title="updateViewTitle" @update-views-list="updateViewsList">
            </working-views-navigation-save>
          </v-toolbar>
        </div>
      </v-menu>
    </template>
    {{ activeWorkingView ? activeWorkingView.title : $t('NAV.WORKING_VIEWS') }}
  </v-tooltip>
</template>

<script>
  import { mergeProps } from 'vue';
  import WorkingView from '@/models/WorkingView';
  import WorkingViewsLanesSelection from './Config/WorkingViewsLanesSelection';
  import WorkingViewsElementsColor from './Config/WorkingViewsElementsColor';
  import WorkingViewsElementsIcon from './Config/WorkingViewsElementsIcon';
  import WorkingViewsElementsDisplay from './Config/WorkingViewsElementsDisplay';
  import WorkingViewsElementsUsers from './Config/WorkingViewsElementsUsers';
  import WorkingViewsNavigationSave from './WorkingViewsNavigationSave';

  export default {
    components: {
      WorkingViewsLanesSelection,
      WorkingViewsElementsColor,
      WorkingViewsElementsIcon,
      WorkingViewsElementsDisplay,
      WorkingViewsElementsUsers,
      WorkingViewsNavigationSave,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      const newView = new WorkingView({
        title: this.$t('CUSTOM_VIEWS.NEW_VIEW'),
        planning_id: this.planning.id,
        config: {
          timeline: this.planning.lastSavedProjectVersion.content.timeline || {},
          lanes: [],
          elementsColors: [],
          elementsIcons: [],
          elementsUsers: { selectedCompanyUsers: [], selectedVirtualParticipants: [], unassigned: false },
          selectedDisplay: {
            description: null, checklist: null, progress: null, users: null, dates: null, links: null, budgets: null,
          },
        },
      });

      return {
        newView,
        selectedView: newView,
        planningLaneIds: null,
        workingViewDropdownIsOpen: null,
        workingViewActivationSwitch: true,
      };
    },
    computed: {
      views() {
        return this.$store.state.ui.workingview.currentPlanningWorkingViews;
      },
      viewsOptions() {
        const options = [this.newView];
        if (this.views?.length) options.push({ divider: true }, ...this.views);
        return options;
      },
      activeWorkingView() {
        return this.$store.getters['ui/workingview/getActiveWorkingView'];
      },
      planningHasActiveWorkingView() {
        return this.$store.getters['ui/workingview/planningHasActiveWorkingView'](this.planning);
      },
      laneFilterActive() {
        return this.workingViewActivationSwitch && this.selectedView.config.lanes.length;
      },
      colorFilterActive() {
        return this.workingViewActivationSwitch && this.selectedView.config.elementsColors.length;
      },
      iconFilterActive() {
        return this.workingViewActivationSwitch && this.selectedView.config.elementsIcons.length;
      },
      displayFilterActive() {
        if (! this.workingViewActivationSwitch || ! this.selectedView.config.selectedDisplay) return false;
        return Object.values(this.selectedView.config.selectedDisplay).some(val => val === true || val === false);
      },
      userFilterActive() {
        const configUser = this.workingViewActivationSwitch && this.selectedView.config.elementsUsers;
        return configUser && (configUser.unassigned || configUser.selectedCompanyUsers?.length || configUser.selectedVirtualParticipants?.length) && true || false;
      },
    },
    watch: {
      workingViewDropdownIsOpen(newVal) {
        if (newVal && ! this.views) {
          this.initLoad();
        }
      },
      selectedView() {
        this.selectedView.config.lanes = _.intersection(this.selectedView.config.lanes, this.planningLaneIds); // handle deleted lane
      },
      'planning.lanes': function (newVal) {
        const newValIds = newVal.map(item => item.id);
        const oldValIds = this.planningLaneIds;
        if (oldValIds && this.activeWorkingView && this.activeWorkingView.config.lanes.length) {
          const newLaneIds = _.difference(newValIds, oldValIds);
          if (newLaneIds.length) {
            this.activeWorkingView.config.lanes.push(...newLaneIds);
            this.activeWorkingView.save();
          }
        }
        this.planningLaneIds = newValIds;

        this.selectedView.config.lanes = _.intersection(this.selectedView.config.lanes, this.planningLaneIds); // handle deleted lane
      },
    },
    created() {
      this.$store.commit('ui/workingview/reset');
      if (this.$store.state.ui.workingview.activeWorkingViewByPlanning[this.planning.id]) {
        this.initLoad().then(() => {
          if (this.activeWorkingView) {
            this.selectedView = this.activeWorkingView;
            this.updatePlanning(this.selectedView, true);
          }
        });
      }
    },
    methods: {
      initLoad() {
        return this.$store.dispatch('ui/workingview/loadPlanningViews', this.planning.id);
      },
      toggleWorkingView() {
        this.updatePlanning(this.workingViewActivationSwitch ? this.selectedView : null);
      },
      updatePlanning(view, forceSwitch) {
        if (forceSwitch) this.workingViewActivationSwitch = true;
        if (view && ! this.workingViewActivationSwitch) return;
        if (view && ! view.id) { // newView : check if active, ie has filter selected
          if (! this.laneFilterActive && ! this.colorFilterActive && ! this.iconFilterActive && ! this.displayFilterActive && ! this.userFilterActive) {
            // supposes view == this.selectedView which should always be the case
            this.$store.dispatch('ui/workingview/updatePlanning', { planning: this.planning, view: null });
            return;
          }
        }
        this.$store.dispatch('ui/workingview/updatePlanning', { planning: this.planning, view });
      },
      updateViewTitle(newTitle) {
        this.selectedView.title = newTitle;
        this.$store.commit('ui/workingview/sort');
      },
      updateViewsList(reason, viewId) {
        if (! viewId) return;
        if (reason == 'delete') { // delete
          const index = this.views.findIndex(item => item.id == viewId);
          if (index > -1) {
            this.views.splice(index, 1);
            this.selectedView = this.newView;
            this.updatePlanning(this.selectedView);
          }
        } else { // create
          this.initLoad().then(() => {
            this.selectedView = this.views.find(item => item.id == viewId);
            this.updatePlanning(this.selectedView, true); // activate new view
          });
        }
      },
      mergeProps,
    },
  };
</script>
