<template>
  <!-- TITLE -->
  <teleport to="#planning-drawer-title">
    <div class="macro-element-details-title flex-center-center">
      <div v-if="el.hasIcon()" class="bg-white rounded-circle mr-2 flex-center-center" style="padding: 6px">
        <v-icon :color="el.getIcon().color" size="x-small">{{ el.getIcon().name }}</v-icon>
      </div>
      {{ elTitle }}
    </div>
  </teleport>

  <div class="element-details">
    <!-- MENU -->
    <div :class="{ invisible: ! menu.show }" class="element-details-menu d-flex justify-center" style="pointer-events: none" v-scroll:#planning-drawer-scroll-container="onScroll">
      <div class="d-inline-flex bg-secondary rounded-b elevation-2 px-3" style="pointer-events: all">
        <v-tooltip v-for="card in elementCards" :key="card.id" location="bottom">
          <template #activator="{ props }">
            <v-btn-icon size="x-small" v-bind="props" @click="displayCard(card.id)">
              <v-icon size="large">{{ card.icon }}</v-icon>
            </v-btn-icon>
          </template>
          {{ card.label }}
        </v-tooltip>

        <v-divider vertical class="my-2"></v-divider>

        <v-menu :close-on-content-click="false" :min-width="200" location="top end" offset="4">
          <template #activator="{ props: onMenu }">
            <v-tooltip location="bottom">
              <template #activator="{ props: onTooltip }">
                <v-btn-icon size="x-small" v-bind="mergeProps(onMenu, onTooltip)">
                  <v-icon size="large">far fa-cog</v-icon>
                </v-btn-icon>
              </template>
              {{ $t('ELEMENT-DETAIL.LANE_PRIORITY_DELETE') }}
            </v-tooltip>
          </template>
          <element-details-positioning-menu :el="el" :options="options" @close="close($event)"></element-details-positioning-menu>
        </v-menu>
      </div>
    </div>

    <!-- CONTENT -->
    <div class="element-details-content">
      <div class="small text-medium-emphasis text-center mb-1">{{ $t('ELEMENT-DETAIL.CHANGES_ARE_SAVED_AUTOMATICALLY') }}</div>
      <template v-for="card in elementCards" :key="card.id">
        <div :id="`edcard-${card.id}`" class="element-details-card">
          <h3 class="text-primary d-inline-flex align-center">
            <v-icon size="small" style="width: 32px">{{ card.icon }}</v-icon>
            {{ card.label }}
          </h3>
          <v-expand-transition>
            <div class="element-details-card-content">
              <element-details-header v-if="card.id == 'header'" :el="el" @close="close"></element-details-header>
              <macro-element-details-sub-tasks v-else-if="card.id == 'sub-tasks'" :el="el"></macro-element-details-sub-tasks>
              <element-details-progress v-else-if="card.id == 'progress'" :el="el"></element-details-progress>
              <element-details-users v-else-if="card.id == 'users'" :el="el"></element-details-users>
              <element-details-links v-else-if="card.id == 'links'" :el="el"></element-details-links>
              <element-details-alerts v-else-if="card.id == 'alerts'" :el="el"></element-details-alerts>
              <element-details-budget v-else-if="card.id == 'budgets'" :el="el"></element-details-budget>
              <element-details-comments v-else-if="card.id == 'comments'" :el="el"></element-details-comments>
            </div>
          </v-expand-transition>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
  .macro-element-details-title::before {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    background-image: url('@/img/macro-background.svg');
    background-size: auto 100%;
    background-repeat: repeat-x;
    pointer-events: none;
  }

  .element-details-content .sub-task-menu {
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
  }
  .element-details-content tr:hover > td > .sub-task-menu {
    visibility: visible;
    opacity: 1;
  }
</style>

<script>
  import { mergeProps } from 'vue';
  import clientConfig from '@/client_customs/config';
  import macroElement from '@/components/Planning/mixins/macroElement';
  import { useAlertBeforeUnload } from '../partials/alertBeforeUnload';
  import ElementDetailsHeader from '../ElementDetailsHeader.vue';
  import ElementDetailsAlerts from '../ElementDetailsAlerts.vue';
  import ElementDetailsComments from '../ElementDetailsComments.vue';
  import MacroElementDetailsSubTasks from './MacroElementDetailsSubTasks.vue';
  import ElementDetailsProgress from '../ElementDetailsProgress.vue';
  import ElementDetailsUsers from '../ElementDetailsUsers.vue';
  import ElementDetailsLinks from '../ElementDetailsLinks.vue';
  import ElementDetailsBudget from '../ElementDetailsBudget.vue';
  import ElementDetailsPositioningMenu from '../ElementDetailsPositioningMenu.vue';

  export default {
    components: {
      ElementDetailsHeader,
      ElementDetailsAlerts,
      ElementDetailsComments,
      MacroElementDetailsSubTasks,
      ElementDetailsProgress,
      ElementDetailsUsers,
      ElementDetailsLinks,
      ElementDetailsBudget,
      ElementDetailsPositioningMenu,
    },
    mixins: [macroElement],
    props: {
      element: { type: Object, required: true },
      options: { type: Object, default: () => ({}) },
    },
    emits: ['close'],
    setup(props, { emit }) {
      useAlertBeforeUnload(props.element, () => emit('close'));
    },
    data() {
      const elementCards = clientConfig.elements?.macroElementCards || [
        { id: 'header', icon: 'far fa-info-circle', label: this.$t('ELEMENT-DETAIL.HEADER') },
        { id: 'sub-tasks', icon: 'far fa-sitemap', label: this.$t('ELEMENT-DETAIL.SUB_TASKS') },
        { id: 'progress', icon: 'far fa-long-arrow-alt-right', label: this.$t('ELEMENT-DETAIL.PROGRESS') },
        { id: 'users', icon: 'far fa-user', label: this.$t('ELEMENT-DETAIL.USERS') },
        { id: 'links', icon: 'far fa-paperclip', label: this.$t('ELEMENT-DETAIL.ATTACHMENTS') },
        { id: 'alerts', icon: 'far fa-alarm-exclamation', label: this.$t('ELEMENT-DETAIL.ALERTS') },
        { id: 'budgets', icon: 'far fa-euro-sign', label: this.$t('ELEMENT-DETAIL.BUDGET') },
        { id: 'comments', icon: 'far fa-comment-dots', label: this.$t('ELEMENT-DETAIL.COMMENTS') },
      ];

      return {
        el: angular.copy(this.element),
        elementCards,
        menu: {
          scrollTop: 0,
          show: true,
          clickTime: 0,
        },
        initialStateEl: angular.copy(this.element),
        planning: this.element.getPlanning(),
      };
    },
    computed: {
      elTitle() {
        return this.el.getTitle() || this.$t('PLANNING.NEW_MACRO_BUBBLE');
      },
    },
    watch: {
      el: {
        handler: _.debounce(function () {
          this.element.reset(this.el.getAll());
        }, 100),
        deep: true,
      },
    },
    created() {
      this.$store.commit('eventbus/register', { event: 'project-updated', listener: 'macro-element-details', cb: this.onProjectUpdate });
    },
    beforeUnmount() {
      this.$store.commit('eventbus/remove', { event: 'project-updated', listener: 'macro-element-details' });
    },
    methods: {
      close(reason) {
        this.$emit('close', reason);
      },
      onScroll(e) {
        const scrollDiff = e.target.scrollTop - this.menu.scrollTop;
        if (scrollDiff > 0 || scrollDiff < -70) {
          if (Date.now() > this.menu.clickTime + 1000) this.menu.show = scrollDiff < 0; // do not show menu if scroll is due to click on menu
          this.menu.scrollTop = e.target.scrollTop;
        }
      },
      onProjectUpdate(modifications) { // project updated in background by another user or instance
        if (! modifications.elements[this.element.id]) return;
        const updatedElement = this.element;
        if (! updatedElement.getPlanning().elements.find(item => item.id == updatedElement.id)) {
          this.close();
          return;
        }
        if (angular.equals(updatedElement.getAll(), this.initialStateEl.getAll())) {
          updatedElement.set(this.el.getAll());
          updatedElement.modificationUser = null;
        } else {
          this.initialStateEl = angular.copy(updatedElement);
          this.el = this.initialStateEl;
        }
      },
      displayCard(prop) {
        const displayNode = document.getElementById(`edcard-${prop}`);
        if (displayNode && displayNode.scrollIntoView) {
          this.menu.show = false;
          this.menu.clickTime = Date.now();
          setTimeout(() => displayNode.scrollIntoView({ behavior: 'smooth' }));
        }
      },
      mergeProps,
    },
  };
</script>
