<template>
  <div style="max-width: 1000px">
    <div v-show="! dataLoaded.company && ! dataLoaded.company_error" class="text-center"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-if="! dataLoaded.company && dataLoaded.company_error">{{ dataLoaded.company_error }}</div>
    <div v-if="dataLoaded.company">
      <!-- Manage invites to join a company -->
      <manage-company-invites v-if="dataLoaded.company && userCanBeInvited" :company-users-count="companyUsers.length" class="mb-6"></manage-company-invites>

      <!-- Manage Subscriptions -->
      <manage-subscriptions class="mb-8"></manage-subscriptions>

      <!-- Manage Account Users -->
      <b>{{ $t('MANAGE.ACCOUNT_USERS') }}</b>
      <v-card class="mt-2 mb-6">
        <div class="table-responsive">
          <table class="table">
            <thead class="bg-lightgrey">
              <tr>
                <th></th>
                <th>{{ $t('MANAGE.FIRSTNAME') }}</th>
                <th>{{ $t('MANAGE.LASTNAME') }}</th>
                <th>{{ $t('MANAGE.EMAIL') }}</th>
                <th class="text-center">{{ $t('MANAGE.ROLE') }}</th>
                <th v-show="userIsAdmin && companyUsers.length > 1" class="text-center">{{ $t('MANAGE.REMOVE') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in sortedCompanyUsers" :key="user.id">
                <td :class="{ pointer: openable_user = canIOpenUser(user) }" class="text-center" @click="openUser(user)">
                  <user-avatar :user="user" :size="32"></user-avatar>
                </td>
                <td :class="{ 'strong pointer': openable_user }" @click="openUser(user)">{{ $filters.lowercaseUcfirst(user.firstname) }}</td>
                <td :class="{ 'strong pointer': openable_user }" @click="openUser(user)">{{ $filters.lowercaseUcfirst(user.lastname) }}</td>
                <td :class="{ pointer: openable_user }" @click="openUser(user)">{{ user.email }}</td>
                <td class="text-center">
                  <div v-if="companyUsers.length > 1 && canIEditUserRights(user)" :class="{ disabled: accessRightsDisabled[user.id] }">
                    <i :class="{ 'selected text-successgreen': user.access_right == 'consult' }" :title="$t('ACR.CONSULT')" class="far fa-eye access-right-icon mx-1"
                       @click="! accessRightsDisabled[user.id] && setUserRights(user, 'consult')">
                    </i>
                    <i :class="{ 'selected text-successgreen': user.access_right == 'user' }" :title="$t('ACR.USER')" class="far fa-pencil-alt access-right-icon mx-1"
                       @click="! accessRightsDisabled[user.id] && setUserRights(user, 'user')">
                    </i>
                    <i :class="{ 'selected text-warningorange': user.access_right == 'company_admin' }" :title="$t('ACR.COMPANY_ADMIN')" class="far fa-unlock-alt access-right-icon mx-1"
                       @click="! accessRightsDisabled[user.id] && setUserRights(user, 'company_admin')">
                    </i>
                  </div>
                  <template v-else>
                    {{ $t(`ACR.${$filters.uppercase(user.access_right)}`) }}
                  </template>
                </td>
                <td v-show="userIsAdmin && companyUsers.length > 1" class="text-center">
                  <i :title="$t('MANAGE.REMOVE_USER')" class="far fa-ban access-right-icon" @click="$emit('remove-user', user)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ManageCompanyInvites from './ManageCompanyInvites.vue';
  import ManageSubscriptions from './ManageSubscriptions.vue';

  export default {
    components: {
      ManageCompanyInvites,
      ManageSubscriptions,
    },
    emits: ['remove-user', 'on-update-user-rights'],
    data() {
      return {
        accessRightsDisabled: [],
      };
    },
    computed: {
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      userCanBeInvited() {
        return ! this.$store.state.users.accessRight.isPremium || this.$store.state.users.accessRight.isTrial;
      },
      sortedCompanyUsers() {
        return this.companyUsers.slice().sort((a, b) => (this.$filters.username(a) < this.$filters.username(b) ? -1 : 1));
      },
      companyUsers() { return this.$store.getters['users/getCompanyUsers']; },
      canIOpenUser() {
        return this.$store.getters['manage/canIOpenUser'];
      },
      canIEditUserRights() {
        return this.$store.getters['manage/canIEditUserRights'];
      },
      ...mapState('manage', ['dataLoaded', 'projects', 'openedUsers']),
    },
    methods: {
      openUser(user) {
        this.$store.dispatch('manage/openUserFromId', user.id);
      },
      setUserRights(user, newRight) {
        let confirmPromise = Promise.resolve();
        if (user.id == this.$store.state.users.user.id) {
          confirmPromise = this.$store.dispatch('ui/msgbox/open', {
            title: user.email,
            body: `<i class="far fa-2x fa-exclamation-triangle text-errorred"></i> &nbsp;${this.$t('MANAGE.LOSING_SPECIAL_RIGHTS')}`,
            buttons: { ok: 'GLOBAL.OK', cancel: 'GLOBAL.CANCEL' },
          });
        }
        confirmPromise.then(() => {
          this.accessRightsDisabled[user.id] = true;
          const oldValue = user.access_right;
          user.access_right = newRight;
          window.apiSrv.call('users', 'update', { id: user.id, access_right: newRight }).then(() => {
            this.accessRightsDisabled[user.id] = false;

            this.$emit('on-update-user-rights', { userId: user.id, newRight });
            if (user.id == this.$store.state.users.user.id) window.location.reload();
          }).catch((message) => {
            user.access_right = oldValue;
            this.accessRightsDisabled[user.id] = false;
            if (message) this.$store.dispatch('ui/msgbox/open', { title: `${this.$t('GLOBAL.ERROR')} : ${this.$t('MANAGE.USER_RIGHT_NOT_MODIFIED')}`, body: message || "" });
          });
        }).catch(() => {});
      },
    },
  };
</script>
