<template>
  <div v-if="pictos.length" class="flex-center-center flex-wrap-reverse py-1" style="gap: 4px">
    <div v-for="iconCode in pictos" class="pa-2 rounded-circle bg-lightgrey" @click.stop="$emit('select-color')">
      <v-icon size="14" :color="pictosList[iconCode]?.color" :title="pictosList[iconCode]?.label">
        {{ pictosList[iconCode]?.name }}
      </v-icon>
    </div>
  </div>
</template>

<script>
  const dataField = 'pictos';

  export default {
    props: {
      el: { type: Object, required: true },
    },
    emits: ['select-color'],
    data() {
      return {
        pictosList: {},
      };
    },
    computed: {
      pictos() {
        return this.el.data?.customFields?.[dataField] || [];
      },
    },
    created() {
      // lazy load pictosList as component can not be lazy loaded
      import('@/client_customs/Maaf/pictos').then(({ default: pictosList }) => {
        this.pictosList = pictosList;
      });
    },
  };
</script>
