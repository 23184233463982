<template>
  <div>
    <navigation></navigation>

    <div v-if="isPremium" id="planningusers">
      <v-progress-linear v-if="! loading.complete" :model-value="loading.counter.toLoad && loading.counter.loaded ? loading.counter.loaded / loading.counter.toLoad * 100 : 2"
                         color="accent" rounded>
      </v-progress-linear>

      <dashboards-filters v-if="loading.complete" :filters="dashboardFilters" can-hide-elements
                          @update="computeUserProject" @update-display="updateDisplay">
        <template v-if="hasProcessDashboard" #prepend-display>
          <btn-toggle-filter model-value="planning" mandatory class="mr-3">
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn value="planning" v-bind="props">
                  <v-icon>svgicon-planning</v-icon>
                </v-btn>
              </template>
              {{ $t('PLANNINGUSERS.USERS_PLANNING') }}
            </v-tooltip>
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn value="kanban" to="/analysis/process" v-bind="props">
                  <v-icon>svgicon-kanban</v-icon>
                </v-btn>
              </template>
              {{ $t('PLANNINGUSERS.USERS_KANBAN') }}
            </v-tooltip>
          </btn-toggle-filter>
        </template>

        <template #append>
          <v-spacer style="flex-grow: 2; min-width: 8px"></v-spacer>
          <dashboards-filters-export :exporting="exporting" pdf @pdf-export="pdfExport"></dashboards-filters-export>
        </template>
      </dashboards-filters>
      <div v-if="planning && planning.elements && planning.elements.length" v-show="viewIsReady">
        <!-- viewIsReady in v-show to keep timeline scrolling on refresh -->
        <div id="viewer" style="background: transparent;">
          <planning-content :planning="planning"
                            :options="{
                              elements: { canEditEl, hideElements: filterOptions.hide_elements },
                              elementDetails: { canDuplicateEl: true, canSetPriority: true, breadcrumb: true },
                            }"
                            :events="{ elementClick, closeElementDetails, selectColor, closeColorPicker, checklistClick, progressClick }"
                            dashboard>
          </planning-content>
        </div>
      </div>
      <div v-if="loading.complete && ! viewIsReady" style="text-align: center; padding: 15px; font-size: 18px">
        <i class="far fa-spinner fa-spin fa-2x fa-fw"></i>
      </div>

      <v-card v-if="viewIsReady && ! (planning && planning.elements && planning.elements.length)" class="py-6 px-12">
        <span v-if="useGroups && ! companyGroups.length">
          {{ $t('PLANNINGUSERS.CREATE_TEAMS_IN_SECTION') }} <router-link to="/manage">{{ $t('NAV.MANAGE') }}</router-link>
        </span>
        <span v-else>{{ $t('PLANNINGUSERS.SELECT_PROJECTS_WITH_USERS') }}</span>
      </v-card>
      <div v-show="planning && viewIsReady">
        <list-pagination :items="planning ? allLanes : []" :items-per-page="20" class="d-inline-block" @update="lanesPaginationBoundaries = $event; computeUserProject()"></list-pagination>
      </div>
    </div>

    <div v-if="userLoaded && ! isPremium">{{ $t('PREMIUM.SECTION_IS_PREMIUM') }}</div>
  </div>
</template>

<style>
  #planningusers .content#viewer {
    padding-top: 0 !important;
  }
</style>

<script>
  import { mapState, mapGetters } from 'vuex';
  import Planning from '@/models/Planning';
  import Navigation from '@/components/Navigation/Navigation.vue';
  import PlanningContent from '@/components/Planning/PlanningContent.vue';
  import DashboardsFilters from '../DashboardsFilters/DashboardsFilters.vue';
  import DashboardsFiltersExport from '../DashboardsFilters/DashboardsFiltersExport.vue';

  export default {
    components: {
      Navigation,
      PlanningContent,
      DashboardsFilters,
      DashboardsFiltersExport,
    },
    data() {
      return {
        planning: null,
        viewIsReady: false,
        planningUsersOptions: { timeline: null, config: null, ...window.safeParseJSON(window.localStorageWrapper.getItem('planningUsersOptions')) },
        lanesPaginationBoundaries: { start: undefined, end: undefined },
        exporting: { inprogress: false, success: false, error: false },
      };
    },
    computed: {
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      userLoaded() { return this.$store.state.users.user.id > 0; },
      timelineBoundaries() {
        // min starttime and max endtime according to filters and steps -> will keep only elements inside this range
        const { starttime, endtime } = this.computeTimelineDates();
        return { minStart: starttime, maxEnd: endtime };
      },
      allPlanningElements() {
        // selected plannings -> elements inside timelineBoundaries range

        const filteredElements = [];
        const selectedProjectsSet = new Set(this.selectedProjects);
        this.projects.forEach((projectData) => {
          if (! projectData.elements || ! selectedProjectsSet.has(projectData.id)) return;

          const { minStart, maxEnd } = this.timelineBoundaries;
          projectData.elements.forEach((el) => {
            if (! el.hasDates()) return;
            if (el.getEndTime().isAfter(minStart) && el.getStartTime().isBefore(maxEnd)) {
              filteredElements.push(el);
            }
          });
        });

        if (! filteredElements.length) {
          // no element in the present -> keep all elements to adjust timeline
          this.projects.forEach((projectData) => {
            if (! projectData.elements || ! selectedProjectsSet.has(projectData.id)) return;

            projectData.elements.forEach((el) => {
              if (! el.hasDates()) return;
              filteredElements.push(el);
            });
          });
        }

        const elements = [];
        filteredElements.forEach((el) => {
          if (! el.getUsers() || ! el.getUsers().length) {
            const newel = angular.copy(el);
            newel.id = `${newel.id}.${window.uuid()}`;
            newel.setLaneId(-1);
            elements.push(newel);
            return;
          }

          (el.getUsers() || []).forEach((user) => {
            let laneIds = [user.id || `vp${user.username}`]; // no grouping : 1 lane = 1 user
            if (this.useGroups) {
              if (user.group_id) { // user is a group
                laneIds = [`group${user.group_id}`];
              } else { // add to all user groups
                laneIds = (this.getUserGroups(user) || []).map(group => `group${group.id}user${user.id}`);
              }
            }

            laneIds.forEach((laneId) => {
              const newel = angular.copy(el);
              newel.id = `${newel.id}.${window.uuid()}`;
              newel.setLaneId(laneId);
              if (user.id || user.group_id || user.username) {
                // hide other users actions
                newel.filter_user = user.id || (user.group_id && `group${user.group_id}`) || `vp${user.username}`;
              }
              elements.push(newel);
            });
          });
        });
        return elements;
      },
      allGroupsLanes() {
        if (! this.useGroups) return null;
        let lanes = [];
        this.companyGroups.forEach((group) => {
          const groupLanes = [];
          (group.users || []).forEach((groupUser) => {
            if (this.groupsOptions.hideUsers) return;
            const user = this.companyUsers.find(item => item.id == groupUser.id);
            if (! user) return;

            groupLanes.push({
              id: `group${group.id}user${user.id}`,
              label: this.$store.getters['users/getUsername'](user),
              level: 1,
            });
          });
          if (this.selectedCompanyGroups.includes(group.id)) {
            lanes.push({
              id: `group${group.id}`,
              label: group.title,
              color: 2,
            });
            lanes = lanes.concat(groupLanes);
          }
        });
        return lanes;
      },
      allUsersLanes() {
        // selected users -> lanes
        const lanes = [];
        this.companyUsers.concat(this.deletedUsers, this.virtualParticipants).forEach((user) => {
          if (user.id) {
            if (! user.isDeleted) {
              if (this.selectedCompanyUsers.indexOf(user.id) == -1) return;
            } else if (this.selectedDeletedUsers.indexOf(user.id) == -1) {
              return;
            }
          } else if (this.selectedVirtualParticipants.indexOf(user.username) == -1) {
            return;
          }

          const laneId = user.id || `vp${user.username}`;
          lanes.push({
            id: laneId,
            label: this.$store.getters['users/getUsername'](user),
            color: user.id ? null : user.group_id ? 2 : 5,
          });
        });
        return lanes;
      },
      allLanes() {
        return this.allGroupsLanes || this.allUsersLanes;
      },
      mergedWorkloadsByLaneId() {
        /* prepare workloadsByLaneId */
        const workloadsByLaneId = {};
        this.planning.elements.forEach((el) => {
          if (el.filter_user) {
            const elWorkloads = this.$store.getters['workloads/getElUserWorkloads'](el.project_id, el.o_id, el.filter_user);
            const laneId = el.getLaneId();
            if (! workloadsByLaneId[laneId]) workloadsByLaneId[laneId] = [];
            workloadsByLaneId[laneId].push(...elWorkloads);
          }
        });

        // add holidays
        this.planning.lanes.forEach((lane) => {
          const laneId = lane.id;
          const elWorkloads = this.$store.getters['workloads/getElUserWorkloads'](undefined, undefined, laneId);
          if (! workloadsByLaneId[laneId]) workloadsByLaneId[laneId] = [];
          workloadsByLaneId[laneId].push(...elWorkloads);
        });

        if (this.useGroups) {
          this.companyGroups.forEach((group) => {
            if (! group.users?.length) return;
            const groupUsersWorkloads = group.users.reduce((acc, user) => {
              const workloads = (workloadsByLaneId[`group${group.id}user${user.id}`] || []).map(workload => ({
                starttime: moment(workload.starttime),
                endtime: moment(workload.endtime),
                daily_workload: workload.daily_workload / group.users.length,
              }));
              return acc.concat(workloads);
            }, []);
            if (! workloadsByLaneId[`group${group.id}`]) workloadsByLaneId[`group${group.id}`] = [];
            workloadsByLaneId[`group${group.id}`].push(...groupUsersWorkloads);
          });
        }

        /* compile workloads */
        const mergedWorkloadsByLaneId = {};
        this.planning.lanes.forEach((lane) => {
          const laneWorkloads = workloadsByLaneId[lane.id] || [];
          mergedWorkloadsByLaneId[lane.id] = this.$store.getters['workloads/mergeWorkloads'](laneWorkloads);
        });
        return mergedWorkloadsByLaneId;
      },
      useGroups() {
        return this.canAssignGroups && !! (this.groupsOptions.useGroups && this.companyGroups);
      },
      companyGroups() {
        return this.$store.getters['users/groups/getCompanyGroups'];
      },
      getUserGroups() {
        return this.$store.getters['users/groups/getUserGroups'];
      },
      dashboardFilters() {
        return ['Projects', 'Groups', this.useGroups ? 'GroupUsers' : 'Users', 'Dates', 'Display'];
      },
      filtersManualUpdate() {
        return this.projects.length > 200;
      },
      canAssignGroups() { return this.$store.state.users.accessRight.canAssignGroups; },
      hasProcessDashboard() { return this.$store.state.users.accessRight.hasProcessDashboard; },
      ...mapState('multiprojects', ['projects', 'selectedProjects', 'companyUsers', 'selectedCompanyUsers', 'selectedCompanyGroups',
                                    'virtualParticipants', 'selectedVirtualParticipants', 'deletedUsers', 'selectedDeletedUsers']),
      ...mapState('multiprojects', ['loading', 'selectedDates', 'filterOptions', 'groupsOptions']),
      ...mapGetters('multiprojects', ['getOriginalPlanningEl']),
    },
    watch: {
      'loading.complete': function (newVal) {
        if (! newVal) {
          this.viewIsReady = false;
        } else {
          this.$store.state.users.userPromise.then(() => {
            this.computeUserProject();
          });
        }
      },
      'planning.timeline': {
        handler(newVal) {
          this.planningUsersOptions.timeline = newVal;
          window.localStorageWrapper.setItem('planningUsersOptions', JSON.stringify(this.planningUsersOptions));
        },
        deep: true,
      },
      'planning.config': {
        handler(newVal) {
          this.planningUsersOptions.config = { ...newVal, colors: undefined, icons: undefined };
          window.localStorageWrapper.setItem('planningUsersOptions', JSON.stringify(this.planningUsersOptions));
        },
        deep: true,
      },
      'planning.timeline.hidden.before': function (newVal) {
        if (! this.planning.elements.length) return;
        if (newVal && moment(newVal).isBefore(this.timelineBoundaries.minStart)) {
          this.selectedDates.starttime = moment(newVal);
          this.debouncedComputeUserProject(); // refresh with new timelineBoundaries
        } else if (this.selectedDates.starttime) {
          this.selectedDates.starttime = newVal ? moment(newVal) : null;
        }
      },
      'planning.timeline.hidden.after': function (newVal) {
        if (! this.planning.elements.length) return;
        if (newVal && moment(newVal).isAfter(this.timelineBoundaries.maxEnd)) {
          this.selectedDates.endtime = moment(newVal);
          this.debouncedComputeUserProject(); // refresh with new timelineBoundaries
        } else if (this.selectedDates.endtime) {
          this.selectedDates.endtime = newVal ? moment(newVal) : null;
        }
      },
      'filterOptions.hide_elements': function (newVal) {
        if (newVal && this.planning?.elements) {
          this.planning.elements.forEach((el) => { el.height = null; });
        }
      },
    },
    created() {
      this.$store.commit('multiprojects/loadFilters', {
        dashboardName: this.$route.path.slice(1).replace(/\//g, '_'),
        selectedDisplay: { checklist: true },
      });
      this.$store.dispatch('multiprojects/load').then(() => {
        if (this.filtersManualUpdate && ! this.planningUsersOptions.timeline) {
          // small initial timeline range when many plannings (eg horis)
          this.planningUsersOptions.timeline = { steps: ['weeks', 'days'] };
        }
      });
      this.$store.dispatch('holidays/load');
    },
    methods: {
      canEditEl(el) {
        const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](el.project_id);
        return originalPlanning && originalPlanning.meta && originalPlanning.meta.access_right && originalPlanning.meta.access_right != 'consult';
      },
      saveEl(params) {
        const { el } = params;
        this.$store.dispatch('ui/dashboards/saveEl', params).then((result) => {
          if (result == 'nothing to save') return;
          // update source planning element (for el copies & workloads)
          const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](el.project_id);
          const originalPlanningElement = originalPlanning && (originalPlanning.elements || []).find(item => item.o_id == el.o_id);
          if (originalPlanningElement) {
            const originalElementUsers = angular.copy(originalPlanningElement.getUsers());
            this.$store.commit('multiprojects/updateOriginalEl', { el: originalPlanningElement, data: el.getAll() });
            if (! angular.equals(originalElementUsers, originalPlanningElement.getUsers())) {
              // users was changed -> need full refresh to update lanes
              this.computeUserProject();
              return;
            }
          }

          // update element copies on planningusers
          const planningUsersElements = this.planning.elements.filter(item => item.project_id == el.project_id && item.o_id == el.o_id);
          planningUsersElements.forEach((element) => {
            this.$store.commit('multiprojects/updateOriginalEl', { el: element, data: el.getAll() });
            element.update();
          });
        });
      },
      deleteElement(el) {
        const { openedElement } = this.$store.state.ui.planning;
        if (openedElement && openedElement.id == el.id) this.$store.commit('ui/planning/openElement', null);
        this.$store.dispatch('ui/dashboards/deleteElement', el);
        const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](el.project_id);
        const originalPlanningElementIndex = originalPlanning && (originalPlanning.elements || []).findIndex(item => item.o_id == el.o_id);
        if (originalPlanningElementIndex > -1) {
          originalPlanning.elements.splice(originalPlanningElementIndex, 1);
          this.computeUserProject();
        }
      },
      elementClick(el) {
        this.$store.commit('ui/planning/openElement', this.getOriginalPlanningEl(el));
      },
      closeElementDetails(reason) {
        const { openedElement } = this.$store.state.ui.planning;
        if (! openedElement) return;
        if (reason == 'delete') {
          this.deleteElement(openedElement.dashboard_el);
        } else {
          this.saveEl({ el: openedElement.dashboard_el, newState: openedElement.getAll() });
          if (reason == 'duplicate') {
            this.duplicateElement(openedElement.dashboard_el);
          }
        }
        this.$store.commit('ui/planning/openElement', null);
      },
      selectColor(el) {
        this.$store.commit('ui/planning/openColorPickerElement', this.getOriginalPlanningEl(el));
      },
      closeColorPicker() {
        const { openedColorPickerElement } = this.$store.state.ui.planning;
        if (! openedColorPickerElement) return;
        this.saveEl({ el: openedColorPickerElement.dashboard_el, newState: openedColorPickerElement.getAll() });
        this.$store.commit('ui/planning/openColorPickerElement', null);
      },
      checklistClick(el, item) {
        const oldState = { checklist: angular.copy(el.getChecklist()), progress: el.getProgress() };
        item.checked = ! item.checked;
        el.updateChecklistProgress();
        this.saveEl({ el, props: ['checklist', 'progress'], oldState });
      },
      progressClick(el, event) {
        const oldState = { progress: el.getProgress() };
        const fullWidth = $(event.currentTarget).width();
        if (! fullWidth) return;
        el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
        this.saveEl({ el, props: ['progress'], oldState });
      },
      duplicateElement(el) {
        const originalPlanning = this.$store.getters['multiprojects/getPlanningById'](el.project_id); // with original lane_id
        const originalPlanningElement = originalPlanning && (originalPlanning.elements || []).find(item => item.o_id == el.o_id);
        if (! originalPlanningElement) return;
        const newEl = angular.copy(originalPlanningElement);
        newEl.setDependencies(null);
        newEl.create().then(() => { // create a new element via api / id is updated by api response
          // Send notification (no planningPatch, recipient will reload full planning)
          window.notificationsSrv.callEvent('projectSaved', { planning_id: originalPlanning.id, planning_title: originalPlanning.getTitle() });

          // Add element and reload
          this.$store.commit('multiprojects/plannings/addElementToPlanning', { planningId: originalPlanning.id, el: newEl });
          this.computeUserProject();
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: this.$t('MONITORING_PROGRESS.ERROR_NOT_MODIFIED'), body: message || "" }, { root: true });
        });
      },
      computeUserProject() {
        if (! this.loading.complete) return;
        if (! this.projects.length) return;
        const scrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.viewIsReady = false;

        setTimeout(() => {
          let lanes = this.allLanes.slice(this.lanesPaginationBoundaries.start || undefined, this.lanesPaginationBoundaries.end || undefined);
          if (! this.useGroups && this.filterOptions.unassigned) lanes.unshift({ id: -1, label: this.$t('PLANNINGUSERS.UNASSIGNED') });

          /* list elements */
          const elements = [];
          const laneIds = new Set(lanes.map(lane => lane.id));
          const nonEmptyLaneIds = new Set();
          this.allPlanningElements.forEach((el) => {
            if (this.filterOptions.el_type && this.filterOptions.el_type != 'both' && ! el.isType(this.filterOptions.el_type)) return;
            if (! laneIds.has(el.getLaneId())) return;
            this.$store.dispatch('multiprojects/formatEl', { el });
            elements.push(el);
            if (this.filtersManualUpdate) nonEmptyLaneIds.add(el.getLaneId());
          });

          /* filter empty lanes */
          if (this.filtersManualUpdate) {
            lanes = lanes.filter(lane => nonEmptyLaneIds.has(lane.id));
          }

          /* workloads */
          lanes.forEach((lane) => {
            lane.getWorkloads = () => this.mergedWorkloadsByLaneId[lane.id];
          });

          /* timeline */
          let elsStart;
          let elsEnd;
          elements.forEach((el) => {
            elsStart = elsStart ? moment.min(el.getStartTime(), elsStart) : el.getStartTime();
            elsEnd = elsEnd ? moment.max(el.getEndTime(), elsEnd) : el.getEndTime();
          });
          elsStart = elsStart || moment();
          elsEnd = elsEnd || moment();
          const { starttime, endtime } = this.computeTimelineDates(elsStart, elsEnd);

          const defaultProjectData = new Planning();
          _.extend(defaultProjectData.timeline, {
            colwidth: defaultProjectData.timeline.bestColWidth(starttime, endtime),
            workdays: true,
          }, this.planningUsersOptions.timeline);
          const timeline = _.extend(this.planning?.timeline || defaultProjectData.timeline, {
            hidden: {
              before: starttime.format(),
              after: endtime.format(),
            },
          });

          /* meta, config */
          _.extend(defaultProjectData.meta, this.projects[0].meta, {
            title: this.$t('PLANNINGUSERS.USERS_PLANNING'),
            category: '',
            companyconfig: { premium: true },
          });
          _.extend(defaultProjectData.config, this.planningUsersOptions.config);

          this.planning = new Planning({
            config: this.planning && this.planning.config || defaultProjectData.config,
            meta: defaultProjectData.meta,
            timeline,
            lanes,
            elements,
          });

          setTimeout(() => {
            this.viewIsReady = true;
            this.$store.dispatch('planning/set', { planning: this.planning });
            setTimeout(() => window.scrollTo(0, scrollY));
          });
        });
      },
      debouncedComputeUserProject: _.debounce(function () { this.computeUserProject(); }, 10),
      updateDisplay() {
        // update existing planning without recomputing
        this.planning.elements.forEach((el) => {
          this.$store.dispatch('multiprojects/formatEl', { el });
        });
        this.$nextTick(() => {
          this.planning.elements.forEach((el) => { el.updateHeight(); });
        });
      },
      computeTimelineDates(elsStart, elsEnd) {
        // return timeline { starttime, endtime } according to filter selectedDates and planning timeline steps
        // use with or without parameters. with parameters, adapt to first element start and last element end
        const defaultProjectData = new Planning();

        const timelineStep = _.last(this.planning?.timeline?.steps || this.planningUsersOptions.timeline?.steps || defaultProjectData.timeline.steps);

        let starttime;
        let endtime;

        if (this.selectedDates.starttime && this.selectedDates.endtime) {
          starttime = moment(this.selectedDates.starttime);
          endtime = moment(this.selectedDates.endtime);
          if (endtime.isSameOrBefore(starttime)) {
            endtime = moment(starttime).add(2, timelineStep);
          }
        } else if (this.selectedDates.starttime) {
          starttime = moment(this.selectedDates.starttime);
          endtime = moment(starttime).add(30, timelineStep); // prevent very long planning
          if (elsEnd) {
            endtime = moment.min(moment(moment.max(starttime, elsEnd)), endtime);
          }
          endtime.add(1, timelineStep);
        } else if (this.selectedDates.endtime) {
          endtime = moment(this.selectedDates.endtime);
          if (timelineStep == 'days') {
            starttime = moment(moment.min(moment(endtime).add(-7, timelineStep), moment.max(elsStart || moment(), moment())));
          } else {
            starttime = moment(moment.min(moment(endtime).add(-2, timelineStep), moment.max(elsStart || moment(), moment())));
          }
          starttime = moment.max(starttime, moment(endtime).add(-30, timelineStep)); // prevent very long planning
          starttime.add(-1, timelineStep);
        } else {
          if (! elsEnd || moment().isBefore(elsEnd)) {
            if (timelineStep == 'days') {
              starttime = moment().add(-7, timelineStep);
            } else {
              starttime = moment().add(-1, timelineStep);
            }
            if (elsStart) starttime = moment.max(moment(elsStart), starttime);
            endtime = moment(starttime).add(30, timelineStep); // prevent very long planning
            if (elsEnd) endtime = moment.min(moment(elsEnd), endtime);
          } else {
            starttime = moment(elsEnd).add(-6, timelineStep);
            endtime = moment(elsEnd);
          }
          starttime.add(-1, timelineStep);
          endtime.add(1, timelineStep);
        }
        starttime.startOf(timelineStep);
        endtime.endOf(timelineStep);
        return { starttime, endtime };
      },
      pdfExport() {
        _.extend(this.exporting, { inprogress: true, success: false, error: false });
        const $html = document.querySelector('html').cloneNode(true);
        let queryEl = $html.querySelector('#viewer .content') || document.createElement("div");
        $html.querySelector('body').innerHTML = `<div style="padding: 0 20px">${queryEl.innerHTML}</div>`;
        $html.querySelector('body').classList.add('v-application');
        $html.querySelector('body').style.cssText += $html.style.cssText; // copy html styles (color vars) to body
        $html.querySelectorAll(".export-hidden").forEach((el) => { el.parentNode.removeChild(el); });
        $html.querySelectorAll(".lane-header, .lane").forEach(el => el.style.setProperty('break-inside', 'avoid'));
        queryEl = document.querySelector("#planning-table");
        const planningWidth = queryEl ? parseFloat(getComputedStyle(queryEl, null).width.replace("px", "")) + 180 : 0;
        if (planningWidth) $html.querySelector("body").style.setProperty('width', `${planningWidth}px`);
        // $html.querySelectorAll(".bg-warningorange").forEach(el => el.style.setProperty('background', '#ff9b1d'));
        // $html.querySelectorAll(".bg-successgreen").forEach(el => el.style.setProperty('background', '#00b100'));

        window.apiSrv.call('pdf', 'store', { html: $html.innerHTML, orientation: "landscape", fullwidth: true, footer: this.$t('PLANNINGUSERS.USERS_PLANNING') }).then((response) => {
          if (response && response.data && response.data.pdfurl) {
            this.exporting.inprogress = false;
            this.exporting.success = true;
            setTimeout(() => { this.exporting.success = false; }, 3000);
            window.open(`${response.data.pdfurl}/${this.$t('PLANNINGUSERS.USERS_PLANNING')}.pdf`, "_blank");
          }
        }).catch((message) => {
          this.exporting.inprogress = false;
          this.exporting.error = message || "Error : not exported";
        });
      },
    },
  };
</script>
