<template>
  <div class="slide-become-premium">
    <div class="d-flex">
      <div class="d-flex flex-column">
        <div v-if="isExpired" class="mt-8">
          <div>
            <b>{{ isTrial ? $t('PREMIUM.SUSPENDED.TRIAL_EXPIRED_SINCE') : $t('PREMIUM.SUSPENDED.ACCOUNT_EXPIRED_SINCE') }} <span class="nobr">{{ $filters.moment(premiumExpireDate, 'date') }}</span>.</b>
          </div>
          <b class="text-successgreen">
            {{ $t('PREMIUM.SUSPENDED.TO_KEEP_WORKING') }}
            {{ isTrial ? $t('PREMIUM.SUSPENDED.SUBSCRIBE_PREMIUM') : $t('PREMIUM.SUSPENDED.RENEW_PREMIUM') }}
          </b>
        </div>
        <div v-else-if="isTrial" class="mt-8">
          <div><b class="nobr">{{ $t('PREMIUM.ACCOUNT_IS_TRIAL') }} {{ $filters.moment(premiumExpireDate, 'date') }}</b></div>
          {{ $t('PREMIUM.TO_CONTINUE_PREMIUM') }}
        </div>
        <div v-else class="mt-8">
          <div><b class="nobr">{{ $t('PREMIUM.ACCOUNT_IS_FREE') }}</b></div>
          {{ $t('PREMIUM.BECOME_PREMIUM') }}
        </div>
        <!-- PACKAGES TOGGLE -->
        <div class="mt-auto mb-4" style="font-size: 28px;"><b>{{ $t('PREMIUM.SUBSCRIPTION.PREMIUM_SUBSCRIPTION') }}</b></div>
        <btn-toggle-filter v-model="planPeriod" mandatory class="mb-5">
          <v-btn value="yearly" class="px-2 py-1 h-100">
            <span class="text-uppercase">{{ $t('PREMIUM.SUBSCRIPTION.ANNUAL') }}</span><span class="ml-1">{{ $t('PREMIUM.SUBSCRIPTION.PRICING_OFFER') }}</span>
          </v-btn>
          <v-btn value="monthly" class="text-uppercase px-4 py-1 h-100">
            {{ $t('PREMIUM.SUBSCRIPTION.MONTHLY') }}
          </v-btn>
        </btn-toggle-filter>
      </div>
      <div class="hidden-sm-and-down">
        <img v-reload-src-on-mount src="@/img/animations/illustration-subscription.gif" alt="Premium" class="ml-6 my-6" style="width: 250px">
      </div>
    </div>
    <!-- PACKAGES -->
    <div class="d-flex flex-wrap flex-md-nowrap gap-8 text-center">
      <!-- TEAM -->
      <div class="pricing rounded overflow-hidden elevation-2" style="flex: 1 1 50%">
        <form class="bg-background h-100 mb-5" @submit.prevent="sendTeamDataCheckout()">
          <!-- Team header -->
          <div class="pa-4 text-white" style="background-color: #278bff;">
            <div class="h3 text-uppercase mb-0">{{ $t('PREMIUM.SUBSCRIPTION.TEAM') }}</div>
            <div v-if="! hidePackageDescription" class="mt-2">{{ $t('PREMIUM.SUBSCRIPTION.TEAM_DESCRIPTION') }}</div>
            <div style="margin-bottom: 12px"></div>
          </div>
          <div class="price mt-5">
            <span class="font-weight-bold" style="font-size: 20px">{{ planPeriod == 'yearly' ? '12€' : '14,40€' }} HT</span>
            {{ $t('PREMIUM.SUBSCRIPTION.PER_USER_PER_MONTH') }}
          </div>
          <div class="my-2">{{ planPeriod == 'yearly' ? $t('PREMIUM.SUBSCRIPTION.BILLED_ANNUALLY') : $t('PREMIUM.SUBSCRIPTION.NO_TIME_COMMITMENT') }}</div>
          <!-- Select Users -->
          <v-select v-model="quantityUsersTeam" class="mb-2 pa-3" :items="usersList"></v-select>
          <!-- Btn Choose -->
          <v-btn type="submit" color="#278bff" size="large" rounded class="text-white text-uppercase" style="min-width: 50%">{{ $t('PREMIUM.SUBSCRIPTION.CHOOSE_PLAN') }}</v-btn>
          <p class="text-left px-4 mt-6" v-html="$t('PREMIUM.SUSPENDED.TEAM_PACKAGE_INCLUDED')"></p>
        </form>
      </div>
      <!-- BUSINESS -->
      <div class="pricing rounded overflow-hidden elevation-4" style="flex: 1 1 50%">
        <form class="bg-background h-100 mb-5" @submit.prevent="sendBusinessDataCheckout()">
          <!-- Business header -->
          <div class="pa-4 text-white" style="background-color: #3cd458;">
            <div class="h3 text-uppercase mb-0">{{ $t('PREMIUM.SUBSCRIPTION.BUSINESS') }}</div>
            <div v-if="! hidePackageDescription" class="mt-2">{{ $t('PREMIUM.SUBSCRIPTION.BUSINESS_DESCRIPTION') }}</div>
            <div style="margin-bottom: 12px"></div>
          </div>
          <div class="price mt-5">
            <span class="font-weight-bold" style="font-size: 20px">{{ planPeriod == 'yearly' ? '22€' : '26,40€' }} HT</span>
            {{ $t('PREMIUM.SUBSCRIPTION.PER_USER_PER_MONTH') }}
          </div>
          <div class="my-2">{{ planPeriod == 'yearly' ? $t('PREMIUM.SUBSCRIPTION.BILLED_ANNUALLY') : $t('PREMIUM.SUBSCRIPTION.NO_TIME_COMMITMENT') }}</div>
          <!-- Select Users -->
          <v-select v-model="quantityUsersBusiness" class="mb-2 pa-3" :items="usersList"></v-select>
          <!-- Btn Choose -->
          <v-btn type="submit" color="#3cd458" size="large" rounded class="text-white text-uppercase" style="min-width: 50%">{{ $t('PREMIUM.SUBSCRIPTION.CHOOSE_PLAN') }}</v-btn>
          <p class="text-left px-4 mt-6" v-html="$t('PREMIUM.SUSPENDED.BUSINESS_PACKAGE_INCLUDED')"></p>
        </form>
      </div>
    </div>
    <div class="d-flex flex-wrap flex-md-nowrap gap-4">
      <!-- Free Offer -->
      <div v-if="! isFree" class="my-12">
        <b>{{ $t('PREMIUM.SUSPENDED.FREE_OFFER') }}</b>
        <div class="mt-2" v-html="$t('PREMIUM.SUSPENDED.FREE_DESC')"></div>
        <div class="mt-2 d-flex">
          <div>
            <v-btn :loading="resetingFree.inprogress" rounded variant="outlined" @click="resetFreeAccount()">{{ $t('PREMIUM.SUSPENDED.ACTIVATE_FREE') }}</v-btn>
            <p v-show="resetingFree.error">{{ resetingFree.error }}</p>
          </div>
          <v-spacer class="mx-1"></v-spacer>
          <v-btn v-if="! isExpired" rounded variant="outlined" @click="$emit('close')">{{ $t('PREMIUM.SUBSCRIPTION.KEEP_TRIAL') }}</v-btn>
        </div>
      </div>
      <div>
        <p class="rounded pa-4 mt-8" v-html="$t('PREMIUM.SUSPENDED.CONTACT_US')" style="border: 2px solid rgb(var(--v-theme-lightgrey));"></p>
      </div>
    </div>
  </div>
</template>

<style lang='scss'>
  @import 'animate.css/source/attention_seekers/tada.css';

  .slide-become-premium {
    .pricing:hover {
      button {
        animation-name: tada;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-iteration-count: 1;
      }
    }
  }
</style>

<script>
  export default {
    props: {
      hidePackageDescription: { type: Boolean, default: false },
    },
    emits: ['send-data-checkout', 'close'],
    data() {
      return {
        planPeriod: 'yearly',
        quantityUsersTeam: 10,
        quantityUsersBusiness: 10,
        usersList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 30, 40, 50].map((nbUsers) => {
          return { value: nbUsers, title: `${nbUsers} ${this.$t('PREMIUM.SUBSCRIPTION.USERS', nbUsers)}` };
        }),
        resetingFree: { inprogress: false, error: "" },
      };
    },
    computed: {
      user() { return this.$store.state.users.user; },
      isFree() { return ! this.user.company?.premiumExpireDate; },
      premiumExpireDate() {
        return this.user.company.premiumExpireDate;
      },
      isTrial() { return this.$store.state.users.accessRight.isTrial; },
      isExpired() { return this.$store.state.users.user.company?.isPremiumExpired; },
    },
    methods: {
      sendTeamDataCheckout() {
        const plan = this.planPeriod == 'yearly' ? 'team12m' : 'team';
        this.$emit('send-data-checkout', { plan, users: this.quantityUsersTeam });
      },
      sendBusinessDataCheckout() {
        const plan = this.planPeriod == 'yearly' ? 'business12m' : 'business';
        this.$emit('send-data-checkout', { plan, users: this.quantityUsersBusiness });
      },
      resetFreeAccount() {
        if (! window.confirm(this.$t('PREMIUM.SUSPENDED.ACTIVATE_FREE_CONFIRM'))) return;
        this.resetingFree = { inprogress: true, error: "" };
        window.apiSrv.call(`companies/${this.$store.state.users.user.company_id}/resetfree`, 'index').then(() => {
          window.location.href = ""; // reload
        }).catch((message) => {
          this.resetingFree = { inprogress: false, error: `Error : ${message}` };
        });
      },
    },
  };
</script>
