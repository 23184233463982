<template>
  <div>
    <div class="d-flex align-center">
      <b>{{ $t('PLANNINGDRAWER.FIND_HERE_ACTIONS') }}</b>
      <template v-if="isPremium">
        <v-spacer></v-spacer>
        <btn-toggle-filter v-model="actionsScope.onlyMine" mandatory="force" class="mr-2">
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn :value="false" v-bind="props">
                <v-icon>far fa-building</v-icon>
              </v-btn>
            </template>
            {{ $t('PLANNINGDRAWER.ALL_ACTIONS') }}
          </v-tooltip>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn :value="true" size="small" class="px-1" v-bind="props">
                <user-avatar :user="me" :size="24"></user-avatar>
              </v-btn>
            </template>
            {{ $t('PLANNINGDRAWER.ONLY_MY_ACTIONS') }}
          </v-tooltip>
        </btn-toggle-filter>
      </template>
    </div>
    <div v-if="isPremium">
      <div v-for="el in actionsElements" style="margin-top: 10px;">
        <div class="small">
          <strong :class="el.getChecklistItemClass()">{{ $filters.moment(el.getEndTime(), 'mediumDateNoYear') }}</strong>
          <span :title="`↪ ${laneLabels[el.getLaneId()] || ''}`" style="font-style: italic;" class="pointer" @click="$emit('open-element', el)">
            &nbsp;{{ el.getTitle() || $t('PLANNING.NEW_BUBBLE') }}
          </span>
        </div>
        <div v-for="item in elChecklist(el)" class="d-flex align-center" style="margin-left: 28px;">
          <user-avatar :user="{ id: item.user_id, group_id: item.group_id, username: item.username }" tooltip class="mr-2" style="width: 20px; margin-left: -28px"></user-avatar>
          <i class="far fa-square pointer mr-2" @click="checklistClick(el, item)"></i>
          <strong v-if="item.duedate" :class="el.getChecklistItemClass(item)" class="small mr-2">{{ $filters.moment(item.duedate, 'mediumDateNoYear') }}</strong>
          {{ item.title }}
        </div>
      </div>
    </div>
    <div v-if="! isPremium" style="margin-top: 15px;">
      <span>{{ $t('PREMIUM.SECTION_IS_PREMIUM') }}</span><br>
      <a :href="$t('PREMIUM.OFFERS_LINK')" target="_blank" rel="noopener">{{ $t('PREMIUM.GO_PREMIUM') }}</a>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    props: {
      planning: { type: Object, required: true },
    },
    emits: ['open-element'],
    data() {
      return {
        actionsScope: { onlyMine: false },
      };
    },
    computed: {
      actionsElements() {
        const checklistItemIsActive = item => ! item.checked && (! this.actionsScope.onlyMine || item.user_id == this.me.id);
        return this.planning.elements.filter(el => el.getChecklist() && el.getChecklist().filter(checklistItemIsActive).length).sort((a, b) => (a.getEndTime() < b.getEndTime() ? -1 : 1));
      },
      laneLabels() {
        return this.planning.lanes.reduce((acc, lane) => {
          acc[lane.id] = lane.label;
          return acc;
        }, {});
      },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      me() { return this.$store.state.users.user; },
    },
    methods: {
      elChecklist(el) {
        const checklist = el.getChecklist().filter(item => ! item.checked).sort((a, b) => (a.duedate < b.duedate ? -1 : 1));
        return ! this.actionsScope.onlyMine ? checklist : checklist.filter(item => item.user_id == this.me.id);
      },
      checklistClick(el, item) {
        this.startChangingElement();
        item.checked = ! item.checked;
        el.updateChecklistProgress();
        this.changingElement(el);
      },
      ...mapActions('planning/elements', ['startChangingElement', 'changingElement']),
    },
  };
</script>
