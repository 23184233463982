<template>
  <div class="mx-auto" style="width: 700px; max-width: 100%; min-height: calc(100vh - 60px)">
    <!-- STEP 1: BECOME PREMIUM -->
    <premium-subscription-packages v-show="slideName == 'become-premium'" :hide-package-description="hidePackageDescription"
                                   @send-data-checkout="checkout = $event; goToNextSlide()" @close="$emit('close')">
    </premium-subscription-packages>

    <!-- STEP 2: BILLING ADDRESS -->
    <premium-subscription-address v-show="slideName == 'billing-address'"
                                  @update:vat-rate="vatRate = $event" @go-to-next-slide="goToNextSlide()" @go-to-previous-slide="goToPreviousSlide()">
    </premium-subscription-address>

    <!-- STEP 3: CHECKOUT -->
    <premium-subscription-checkout v-if="slideName == 'checkout'" :plan="checkout.plan" :users="checkout.users" :vat-rate="vatRate"
                                   @go-to-previous-slide="goToPreviousSlide()">
    </premium-subscription-checkout>
  </div>
</template>

<script>
  import PremiumSubscriptionPackages from './PremiumSubscriptionPackages.vue';
  import PremiumSubscriptionAddress from './PremiumSubscriptionAddress.vue';
  import PremiumSubscriptionCheckout from './PremiumSubscriptionCheckout.vue';

  export default {
    components: {
      PremiumSubscriptionPackages,
      PremiumSubscriptionAddress,
      PremiumSubscriptionCheckout,
    },
    props: {
      hidePackageDescription: { type: Boolean, default: false },
    },
    emits: ['set-backdrop-close', 'close'],
    data() {
      return {
        slideIndex: 0,
        slides: ['become-premium', 'billing-address', 'checkout'],
        vatRate: 0.2,
        checkout: { plan: '', users: 1 },
      };
    },
    computed: {
      slideName() { return this.slides[this.slideIndex]; },
      isAdmin() { return this.$store.state.users.accessRight.isAdmin; },
    },
    watch: {
      slideName: {
        handler(newVal) {
          this.$emit('set-backdrop-close', (newVal == 'become-premium')); // allow or block backdrop-close
          this.$nextTick(() => { document.querySelector('.premium-subscription-scroll-container').scrollTop = 0; });
        },
        immediate: true,
      },
    },
    created() {
      this.$store.state.users.userPromise.then((user) => {
        if (! this.isAdmin || ! user.company?.id) {
          this.slides = ['become-premium', 'checkout'];
        }
      });
    },
    methods: {
      goToPreviousSlide() {
        this.slideIndex = this.slideIndex > 0 ? this.slideIndex - 1 : 0;
      },
      goToNextSlide() {
        this.slideIndex = this.slideIndex < (this.slides.length - 1) ? this.slideIndex + 1 : (this.slides.length - 1);
      },
    },
  };
</script>
