<template>
  <v-menu v-model="isOpened" :close-on-content-click="false" :target="`#laneheader${lane.id}`" location="end" offset="4" max-width="250">
    <template #activator="{ props }">
      <v-btn-icon :title="$t('PLANNING.DELETE_LANE')" size="small" density="compact" v-bind="props">
        <v-icon color="errorred">far fa-trash-alt</v-icon>
      </v-btn-icon>
    </template>
    <v-card class="pa-2">
      <div>{{ deleteConfirmationMessage }}</div>
      <div class="py-4 flex-center-center gap-4">
        <v-btn rounded variant="outlined" @click="isOpened = false">{{ $t('GLOBAL.CANCEL') }}</v-btn>
        <v-btn rounded color="errorred text-white" @click="$emit('delete')">
          <v-icon class="mr-2">far fa-trash-alt</v-icon>
          {{ $t('PLANNING.DELETE') }}
        </v-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
  export default {
    props: {
      lane: { type: Object, required: true },
    },
    emits: ['delete'],
    data() {
      return {
        isOpened: false,
      };
    },
    computed: {
      laneElementsCount() {
        const laneElements = this.$store.getters['planning/lanes/getLaneElements']({ laneId: this.lane.id });
        return laneElements.length;
      },
      deleteConfirmationMessage() {
        if (this.lane.project_id) return this.$t('PLANNING.DELETE_LANE_SUBPROJECT');
        return this.$t('PLANNING.DELETE_LANE_CONFIRMATION', { count: this.laneElementsCount, label: this.lane.label });
      },
    },
  };
</script>
