<template>
  <msg-box :backdrop-close="true" @close="close">
    <template #title>
      {{ $t('MANAGE.REMOVE_USER_FROM_COMPANY', { username: userToRemove.email }) }}
    </template>

    <template #default>
      <template v-if="userToRemoveIsCurrentUser">
        <i class="far fa-2x fa-exclamation-triangle text-errorred mr-1"></i>
        <span v-html="$t('MANAGE.LOSING_SPECIAL_RIGHTS')"></span>
      </template>
      <template v-else-if="userProjects.length">
        <div v-html="onRemoveConfirmMsg"></div>
        <v-list v-model:selected="selectedUserProjects" select-strategy="classic" variant="elevated">
          <template v-if="userProjects.length > 1">
            <filter-toggle class="remove-user-projects-toggle font-weight-bold" :selected-ids="selectedUserProjects" :sublist-ids="userProjectsIds"
                           @update="selectedUserProjects = $event">
              {{ $t('MULTIPROJECTS.ALL_PROJECTS') }} ({{ userProjectsIds.length }})
            </filter-toggle>
            <v-divider></v-divider>
          </template>
          <div style="max-height: 40vh; min-height: 100px; flex: 1 1 auto; overflow-y: auto;">
            <v-list-item v-for="project in userProjects" :key="project.id" :value="project.id">
              <template #prepend="{ isActive }">
                <v-checkbox-btn :model-value="isActive"></v-checkbox-btn>
              </template>

              <v-list-item-title>{{ project.title || $t('PLANNING.UNNAMED_PROJECT') }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
      </template>
      <div v-else v-html="$t('MANAGE.USER_WILL_BE_REMOVED_FROM_COMPANY')"></div>
    </template>

    <template #buttons>
      <div class="d-flex mt-5" style="justify-content: flex-end">
        <v-btn rounded size="large" variant="outlined" :disabled="removeUserInprogress" @click="close()">{{ $t('GLOBAL.CANCEL') }}</v-btn>
        <v-btn color="primary" rounded size="large" class="ml-4" :loading="removeUserInprogress" @click="removeUser()">{{ $t('MANAGE.REMOVE_USER') }}</v-btn>
      </div>
    </template>
  </msg-box>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      userToRemove: { type: Object, required: true },
    },
    emits: ['close'],
    data() {
      return {
        selectedUserProjects: [],
        removeUserInprogress: false,
        hasError: false,
      };
    },
    computed: {
      userToRemoveIsCurrentUser() { return this.userToRemove.id == this.$store.state.users.user.id; },
      userProjects() { return this.projects.filter(item => item.owner_id == this.userToRemove.id); },
      userProjectsIds() { return this.userProjects.map(item => item.id); },
      onRemoveConfirmMsg() {
        const projectsCount = this.$t('MANAGE.PROJECTS_COUNT', { count: this.userProjects.length });
        const projectsCountThese = this.$t('MANAGE.PROJECTS_COUNT_THESE', this.userProjects.length);
        const projectsCountThem = this.$t('MANAGE.PROJECTS_COUNT_THEM', this.userProjects.length);
        const projectsCountTheir = this.$t('MANAGE.PROJECTS_COUNT_THEIR', this.userProjects.length);
        const projectsCountThe = this.$t('MANAGE.PROJECTS_COUNT_THE', this.userProjects.length);
        return this.$t('MANAGE.USER_IS_PROJECT_OWNER', { projectsCount, projectsCountThese, projectsCountThem, projectsCountTheir, projectsCountThe });
      },
      ...mapState('manage', ['dataLoaded', 'projects']),
    },
    methods: {
      async removeUser() {
        this.removeUserInprogress = true;
        this.dataLoaded.company = false;
        if (this.selectedUserProjects.length) {
          await this.setNewOwner();
          if (this.hasError) {
            this.removeUserInprogress = false;
            this.$store.dispatch('manage/reload'); // update projects whose owner has been changed
            this.close();
            return;
          }
        }
        window.apiSrv.call('companies/users', 'destroy', this.userToRemove.id).then(() => {
          if (this.userToRemoveIsCurrentUser) {
            window.location.reload();
            return;
          }
          this.removeUserInprogress = false;
          this.$store.dispatch('manage/reload');
          this.close();
        }).catch((message) => {
          this.removeUserInprogress = false;
          this.dataLoaded.company = true;
          this.close();
          if (message) this.$store.dispatch('ui/msgbox/open', { title: `${this.$t('GLOBAL.ERROR')} : ${this.$t('MANAGE.USER_NOT_REMOVED')}`, body: message || "" });
        });
      },
      async setNewOwner() {
        const newOwnerId = this.$store.state.users.user.id;
        if (! newOwnerId) return;
        const promises = [];
        let errorMsg = "";
        let unchangedProjects = 0;
        this.selectedUserProjects.forEach((projectId) => {
          const newOwnerPromise = window.apiSrv.call(`plannings/${projectId}/admin`, 'update', { owner_id: newOwnerId }).catch((message) => {
            this.hasError = true;
            errorMsg = message;
            unchangedProjects++;
          });
          promises.push(newOwnerPromise);
        });
        if (promises.length) {
          await Promise.all(promises).then(() => {
            if (this.hasError) {
              this.$store.dispatch('ui/msgbox/open', { title: `Error : Owner of ${unchangedProjects} project${unchangedProjects > 1 ? 's' : ''} was not modified`, body: errorMsg || "" });
            }
          }).catch((message) => {
            this.hasError = true;
            this.$store.dispatch('ui/msgbox/open', { title: "Error : Owner was not modified", body: message || "" });
          });
        }
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
