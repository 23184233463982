<template>
  <!-- Projects & Groups Filters -->
  <dashboards-filters :filters="['Projects', 'Groups']" @update="update">
    <!-- GroupUsers Filter -->
    <template v-if="useGroups">
      <autocomplete-filter v-model="selectedGroup" :label="$t('GLOBAL.GROUP')" :items="groupUsers" item-title="title" item-value="group_id"
                           prepend-inner-icon="far far fa-users" :menu-props="{ location: 'bottom' }" return-object
                           @update:model-value="update">
      </autocomplete-filter>
      <div class="dashboard-filters-spacer"></div>
    </template>
    <!-- Users Filter -->
    <template v-else>
      <autocomplete-filter v-model="selectedUser" :label="$t('PERSONAL_CALENDAR.PARTICIPANT')" :items="allUsers" item-title="username" item-value="id"
                           prepend-inner-icon="far fa-user" :menu-props="{ location: 'bottom' }" return-object
                           @update:model-value="update">
        <template #item="{ props, item: { raw: item } }">
          <v-divider v-if="item.divider"></v-divider>
          <v-list-subheader v-else-if="item.header" :title="item.header"></v-list-subheader>
          <v-list-item v-else v-bind="props">
            <template #prepend>
              <user-avatar :user="item" :size="32"></user-avatar>
            </template>
          </v-list-item>
        </template>
      </autocomplete-filter>
      <div class="dashboard-filters-spacer"></div>
    </template>
    <!-- Display Filter -->
    <select-filter v-model="selectedDisplayList" :items="selectedDisplayOptions"
                   :label="$t('MULTIPROJECTS.DISPLAY')" :placeholder="selectedDisplayList.length ? '' : $t('PERSONAL_CALENDAR.NO_DISPLAY_SELECTED')"
                   prepend-inner-icon="far fa-eye" multiple :menu-props="{ location: 'bottom' }"
                   @update:model-value="update">
      <template #selection="{ item: { title }, index }">
        <v-chip v-if="selectedDisplayList.length <= 3">{{ title }}</v-chip>
        <span v-else-if="index === 0">{{ selectedDisplayList.length }}/{{ selectedDisplayOptions.length }}</span>
      </template>
    </select-filter>
  </dashboards-filters>
</template>

<script>
  import { mapState } from 'vuex';
  import DashboardsFilters from '../DashboardsFilters/DashboardsFilters';

  export default {
    components: {
      DashboardsFilters,
    },
    emits: ['update'],
    computed: {
      selectedUser: {
        get() {
          for (let i = 0; i < this.selectedCompanyUsers.length; i++) {
            const user = this.companyUsers.find(item => item.id == this.selectedCompanyUsers[i]);
            if (user) return user;
          }
          for (let i = 0; i < this.selectedVirtualParticipants.length; i++) {
            const user = this.virtualParticipants.find(item => item.username == this.selectedVirtualParticipants[i]);
            if (user) return user;
          }
          return this.noParticipants;
        },
        set(newVal) {
          this.selectedCompanyUsers = [];
          this.selectedVirtualParticipants = [];
          if (! newVal) return;
          if (newVal.id) {
            this.selectedCompanyUsers.push(newVal.id);
          } else {
            this.selectedVirtualParticipants.push(newVal.username);
          }
        },
      },
      selectedCompanyUsers: {
        get() {
          return this.$store.state.multiprojects.selectedCompanyUsers;
        },
        set(newVal) {
          this.$store.state.multiprojects.selectedCompanyUsers.splice(0, this.$store.state.multiprojects.selectedCompanyUsers.length, ...newVal);
        },
      },
      allUsers() {
        return this.companyUsers.concat(this.virtualParticipants).concat(this.noParticipants);
      },
      selectedVirtualParticipants: {
        get() {
          return this.$store.state.multiprojects.selectedVirtualParticipants;
        },
        set(newVal) {
          this.$store.state.multiprojects.selectedVirtualParticipants.splice(0, this.$store.state.multiprojects.selectedVirtualParticipants.length, ...newVal);
        },
      },
      noParticipants() {
        return { username: this.$t('PERSONAL_CALENDAR.UNASSIGNED') };
      },
      selectedGroup: {
        get() {
          return this.selectedCompanyGroups[0];
        },
        set(newVal) {
          this.selectedCompanyGroups = [];
          this.selectedCompanyGroups.push(newVal.group_id);
        },
      },
      selectedCompanyGroups: {
        get() {
          return this.$store.state.multiprojects.selectedCompanyGroups;
        },
        set(newVal) {
          this.$store.state.multiprojects.selectedCompanyGroups.splice(0, this.$store.state.multiprojects.selectedCompanyGroups.length, ...newVal);
        },
      },
      groupUsers() {
        return (this.$store.getters['users/groups/getCompanyGroups'] || []).map(item => ({
          group_id: item.id,
          title: item.title,
          icon: 'far fa-users',
        }));
      },
      useGroups() {
        return this.canAssignGroups && !! (this.groupsOptions.useGroups && this.companyGroups);
      },
      companyGroups() {
        return this.$store.getters['users/groups/getCompanyGroups'];
      },
      canAssignGroups() { return this.$store.state.users.accessRight.canAssignGroups; },
      selectedDisplayOptions() {
        return [
          { value: 'milestone', title: this.$t('PERSONAL_CALENDAR.MILESTONE') },
          { value: 'shorttask', title: this.$t('PERSONAL_CALENDAR.SHORTTASK') },
          { value: 'longtask', title: this.$t('PERSONAL_CALENDAR.LONGTASK') },
          { value: 'planningactions', title: this.$t('PERSONAL_CALENDAR.PLANNING_ACTIONS') },
          { value: 'meetingactions', title: this.$t('PERSONAL_CALENDAR.MEETING_ACTIONS') },
          { value: 'holidays', title: this.$t('PERSONAL_CALENDAR.HOLIDAYS') },
        ];
      },
      selectedDisplayList: {
        get() {
          return Object.keys(this.calendarDisplay).filter(key => this.calendarDisplay[key]);
        },
        set(newVal) {
          Object.keys(this.calendarDisplay).forEach((key) => {
            this.calendarDisplay[key] = newVal.includes(key);
          });
        },
      },
      ...mapState('multiprojects', ['filterOptions', 'companyUsers', 'virtualParticipants', 'calendarDisplay', 'groupsOptions']),
    },
    methods: {
      update() {
        this.filterOptions.unassigned = (this.selectedUser.username === this.noParticipants.username);
        this.$emit('update');
      },
    },
  };
</script>
