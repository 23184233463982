<template>
  <v-card :link="false" class="actions-group" style="page-break-inside: avoid;" @click="closeAllEditing">
    <!-- CARD TITLE  -->
    <draggable :disabled="! dragEnabled" handle="nope" item-key="id" :group="{ name: 'draggableActions', put: true }">
      <template #header>
        <div class="bg-lightgrey py-2 pl-4 pr-2">
          <div class="d-flex align-center">
            <user-avatar v-if="groupByUser && firstLevelUser" :user="firstLevelUser" size="32" class="mr-2"></user-avatar>
            <span class="text-subtitle-1">{{ firstLevelTitle }}</span>
            <v-spacer></v-spacer>
            <template v-if="groupByUser && ! isActionVirtualUser && ! isDeletedUser || firstLevelId == 'none'">
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <v-btn-icon class="export-hidden" v-bind="props" size="small"
                              @click="$emit('create-action', { userId: groupByUser && firstLevelId != 'none' && firstLevelId })">
                    <v-icon color="primary">far fa-plus</v-icon>
                  </v-btn-icon>
                </template>
                {{ $t(firstLevelId == 'none' ? 'MONITORING_ACTIONS.ADD_ACTION' : 'MONITORING_ACTIONS.ADD_ACTION_TO_USER') }}
              </v-tooltip>
            </template>
            <template v-if="groupByUser || firstLevelId != 'none'">
              <v-btn-icon class="export-hidden" size="small" @click="hideCard">
                <v-icon color="primary">far fa-eye-slash</v-icon>
              </v-btn-icon>
            </template>
          </div>
          <v-expand-transition>
            <div v-if="dragEnabled && selectedAction && selectedActionUserId != firstLevelId" class="drop-zone text-center bg-white text-primary mb-4 mr-4 mt-4">
              <div style="position: absolute;">
                <i class="far fa-arrow-right"></i><i :class="groupByGroup ? 'far fa-users fa-lg ml-2' : 'far fa-user fa-lg ml-2'"></i>
              </div>
              <draggable :group="{ name: 'draggableActions', put: true }" item-key="id" :data-id="firstLevelId" class="drop-zone-item"
                         @add="groupByGroup ? dropActionOnGroup($event) : dropActionOnUser($event)">
                <template #item></template>
              </draggable>
            </div>
          </v-expand-transition>
        </div>
      </template>
      <template #item></template>
    </draggable>

    <!-- ACTIONS LIST -->
    <div class="pb-2 h-100 overflow-y-auto light-scrollbar bg-white">
      <div v-for="(secondLevelActions, secondLevelId) in actionsGroup" :key="secondLevelId" class="bg-white pt-2 px-1">
        <div class="py-1 px-1">
          <span class="pointer" @click="hiddenTiles[secondLevelId] = ! hiddenTiles[secondLevelId]">
            <i :class="hiddenTiles[secondLevelId] ? 'fa-plus-square' : 'fa-minus-square'" class="far fa-fw pointer"></i>
            {{ groupByPlanning ? userTitle(secondLevelId) : planningTitle(secondLevelId) }}
          </span>
        </div>
        <draggable v-if="! hiddenTiles[secondLevelId]" :list="draggableCopy(secondLevelActions)" item-key="id"
                   :disabled="! dragEnabled" handle=".checklist-draggable-anchor" :group="{ name: 'draggableActions', put: false }" :sort="false"
                   style="min-height: 10px"
                   @start="startDragAction($event)" @end="endDragAction()">
          <template #item="{ element: action }">
            <actions-dashboard-card-item :action="action" :editing="editing" :is-draggable="dragEnabled"
                                         :data-id="action.id" style="page-break-inside: avoid;"
                                         @set-editing="setEditing" @open-action="$emit('open-action', action)">
            </actions-dashboard-card-item>
          </template>
        </draggable>
        <div v-if="groupStats.workload_used || groupStats.workload" class="mt-2 mx-auto" style="width: 90%;">
          <div class="text-primary text-right small pt-1" style="border-top: 1px solid #ccc">
            {{ $t('GLOBAL.SUBTOTAL') }} : {{ $filters.number(groupStats[secondLevelId].workload || 0, '0.[00]') }}
            &nbsp;<i class="fas fa-caret-right"></i>&nbsp;
            {{ $filters.number(groupStats[secondLevelId].workload_used || 0, '0.[00]') }}
          </div>
        </div>
      </div>
      <div v-if="! hasActions" class="text-center text-medium-emphasis pa-4">{{ $t('MONITORING_ACTIONS.NO_ACTION') }}</div>
    </div>

    <!-- GROUP USERS LIST -->
    <draggable v-if="groupByGroup && usersByGroup.length && ! groupsOptions.hideUsers" item-key="id"
               :disabled="! dragEnabled" handle="nope" :group="{ name: 'draggableActions', put: true }">
      <template #header>
        <v-divider class="mx-4"></v-divider>
        <div class="d-flex align-center flex-wrap">
          <div v-for="user in usersByGroup" :key="user.id">
            <v-expand-transition>
              <div :class="dragEnabled && selectedAction ? 'drop-zone' : ''" class="bg-white my-1 mx-2 pa-1">
                <draggable :group="{ name: 'draggableActions', put: true }" item-key="id" :data-id="user.id" class="drop-zone-item" style="height: 35px;"
                           @add="dropActionOnUser">
                  <template #header>
                    <div class="drop-zone-username d-flex align-center">
                      <span :style="{ visibility: dragEnabled && selectedAction ? 'visible' : 'hidden' }" class="mr-1"><i class="far fa-arrow-right"></i></span>
                      <user-workload-indicator :user="user" :starttime="selectedAction ? selectedAction.getStartTime() : null" :endtime="selectedAction ? selectedAction.getEndTime() : null"
                                               small bottom hide-zero class="mr-1">
                      </user-workload-indicator>
                      <user-avatar :user="user" :size="32" show-username username-format="short" class="mr-1"></user-avatar>
                    </div>
                  </template>
                  <template #item></template>
                </draggable>
              </div>
            </v-expand-transition>
          </div>
        </div>
      </template>
      <template #item></template>
    </draggable>

    <!-- TOTAL WORKLOAD -->
    <div class="mx-4">
      <v-divider></v-divider>
      <div class="text-primary text-right py-2">
        <strong>{{ $filters.uppercase($t('GLOBAL.TOTAL')) }} : {{ $filters.number(groupStats.workload || 0, '0.[00]') }}</strong>
        <i class="mx-1 fas fa-caret-right"></i>
        <strong>{{ $filters.number(groupStats.workload_used || 0, '0.[00]') }}</strong>
      </div>
    </div>
  </v-card>
</template>

<style lang="scss">
  #actionsdashboard {
    .actions-group {
      width: 460px;
      max-width: 600px;
      margin-right: 15px;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
    }
    .actions-group:last-child {
      margin-right: 0;
    }

    .drop-zone {
      display: flex;
      align-items: center;
      justify-content: center;
      outline: 2px dashed #999;
    }
    .drop-zone-item {
      height: 50px;
      flex: 1 0 100%;
      display: flex;
      align-items: center;
    }
    .drop-zone-item > * {
      flex: 1 0 100%;
      background: white;
      z-index: 1;
    }
    .actions-group > div > .sortable-ghost {
      display: none !important;
    }
    .drop-zone-item .sortable-ghost + .drop-zone-username {
      display: none !important;
    }
    .drop-zone-item .action-item .action-item-text {
      white-space: nowrap;
    }
  }
</style>

<script>
  import { mapState } from 'vuex';
  import draggable from 'vuedraggable';
  import workloadMixin from '@/components/Workload/workloadMixin';
  import UserWorkloadIndicator from '@/components/Workload/UserWorkloadIndicator.vue';
  import ActionsDashboardCardItem from './ActionsDashboardCardItem.vue';

  export default {
    components: {
      draggable,
      UserWorkloadIndicator,
      ActionsDashboardCardItem,
    },
    mixins: [workloadMixin],
    props: {
      planningActions: { type: Array, required: true },
      firstLevelId: { type: String, required: true },
      groupBy: { type: String, default: 'user' },
      selectedAction: { type: Object, default: null },
    },
    emits: ['create-action', 'open-action', 'selected-action', 'update-original-el'],
    data() {
      return {
        hiddenTiles: {},
        editing: { title: null, workload: null, workloadUsed: null },
      };
    },
    computed: {
      groupByUser() {
        return this.groupBy == 'user';
      },
      groupByPlanning() {
        return this.groupBy == 'planning';
      },
      groupByGroup() {
        return this.groupBy == 'group';
      },
      isActionVirtualUser() {
        return this.selectedActionVirtualParticipants.includes(this.firstLevelId);
      },
      isDeletedUser() {
        return this.selectedActionDeletedUsers.includes(+this.firstLevelId);
      },
      firstLevelUser() {
        if (this.firstLevelId == 'none') return null;
        return this.isActionVirtualUser ? { username: this.firstLevelId } : { id: this.firstLevelId };
      },
      firstLevelTitle() {
        if (this.groupByUser) return this.userTitle(this.firstLevelId);
        if (this.groupByGroup) return this.companyGroupTitle(this.firstLevelId);
        return this.planningTitle(this.firstLevelId);
      },
      usersByGroup() {
        return this.getGroupById(this.firstLevelId).users;
      },
      selectedActionUserId() {
        return this.selectedAction ? this.selectedAction.getUserId() || this.selectedAction.getGroupId() : null;
      },
      actionsGroup() {
        const colorsOrder = ['warningorange', 'errorred'];
        const actions = {};
        this.planningActions.filter((action) => {
          if (this.groupByUser) {
            if (action.getGroupId()) return false;
            const userId = action.getUserId() || action.getUsername() || 'none';
            return this.firstLevelId == userId;
          }
          if (this.groupByGroup) {
            if (! action.getGroupId()) return false;
            const groupId = action.getGroupId() || 'none';
            return this.firstLevelId == groupId;
          }
          if (action.getGroupId()) return false;
          const planningId = action.getPlanningId() || 'none';
          return this.firstLevelId == planningId;
        }).forEach((action) => {
          const userId = action.getUserId() || action.getUsername() || 'none';
          const planningId = action.getPlanningId() || 'none';
          const secondLevelId = this.groupByPlanning ? userId : planningId;
          if (! actions[secondLevelId]) actions[secondLevelId] = [];
          actions[secondLevelId].push(action);
        });
        Object.keys(actions).forEach((key) => {
          actions[key].sort((actionA, actionB) => {
            const colorSort = colorsOrder.indexOf(actionB.getColor()) - colorsOrder.indexOf(actionA.getColor());
            if (colorSort) return colorSort;
            const actionBEndTime = actionB.getEndTime();
            const actionAEndTime = actionA.getEndTime();
            if (! actionBEndTime) return -1;
            if (! actionAEndTime) return 1;
            return actionBEndTime.isAfter(actionAEndTime) ? -1 : 1;
          });
        });
        return actions;
      },
      hasActions() {
        return ! _.isEmpty(this.actionsGroup);
      },
      groupStats() {
        const stats = { workload: 0, workload_used: 0 };
        Object.keys(this.actionsGroup).forEach((secondLevelKey) => {
          stats[secondLevelKey] = { workload: 0, workload_used: 0 };
          this.actionsGroup[secondLevelKey].forEach((action) => {
            stats[secondLevelKey].workload += parseFloat(this.workloadToDisplay(action.getWorkload()), 10) || 0;
            stats[secondLevelKey].workload_used += parseFloat(this.workloadToDisplay(action.getWorkloadUsed()), 10) || 0;
          });
          stats.workload += stats[secondLevelKey].workload;
          stats.workload_used += stats[secondLevelKey].workload_used;
        });
        return stats;
      },
      dragEnabled() {
        return ! this.groupByPlanning;
      },
      getGroupById() {
        return this.$store.getters['users/groups/getGroupById'];
      },
      ...mapState('multiprojects', ['projects', 'selectedProjects', 'companyUsers', 'actionDeletedUsers', 'selectedCompanyUsers', 'selectedActionDeletedUsers',
                                    'selectedCompanyGroups', 'selectedVirtualParticipants', 'selectedActionVirtualParticipants', 'filterOptions', 'groupsOptions']),
    },
    created() {
      this.setHiddenTiles();
    },
    updated() {
      this.setHiddenTiles();
    },
    methods: {
      userTitle(id) {
        if (id == 'none') return this.$t('MONITORING_ACTIONS.NOT_ASSIGNED');
        let companyUser = this.companyUsers.find(item => item.id == id);
        if (! companyUser) companyUser = this.actionDeletedUsers.find(item => item.id == id);
        return companyUser ? companyUser.username : id;
      },
      companyGroupTitle(id) {
        const companyGroup = this.getGroupById(id);
        return companyGroup ? companyGroup.title : id;
      },
      planningTitle(id) {
        if (id == 'none') return this.$t('MONITORING_ACTIONS.NOT_IN_PLANNING');
        const project = this.projects.find(item => item.id == id);
        return project ? (project.getTitle() || this.$t('PLANNING.UNNAMED_PROJECT')) : this.$t('MONITORING_ACTIONS.NOT_IN_PLANNING');
      },
      setHiddenTiles() {
        Object.keys(this.actionsGroup).forEach((secondLevelKey, index) => {
          if (typeof this.hiddenTiles[secondLevelKey] == 'undefined') {
            this.hiddenTiles[secondLevelKey] = index && true || false;
          }
        });
      },
      setEditing({ prop, id }) {
        this.closeAllEditing();
        this.editing[prop] = id;
      },
      closeAllEditing() {
        Object.keys(this.editing).forEach((key) => { this.editing[key] = null; });
      },
      hideCard() {
        if (this.groupByUser) {
          if (this.firstLevelId == 'none') {
            this.filterOptions.unassigned = false;
            return;
          }
          let index = this.selectedCompanyUsers.findIndex(item => item == this.firstLevelId);
          if (index > -1) {
            this.selectedCompanyUsers.splice(index, 1);
            return;
          }
          index = this.selectedActionVirtualParticipants.findIndex(item => item == this.firstLevelId);
          if (index > -1) {
            this.selectedActionVirtualParticipants.splice(index, 1);
            return;
          }
          index = this.selectedActionDeletedUsers.findIndex(item => item == this.firstLevelId);
          if (index > -1) this.selectedActionDeletedUsers.splice(index, 1);
        } else if (this.groupByGroup) {
          const index = this.selectedCompanyGroups.findIndex(item => item == this.firstLevelId);
          if (index > -1) this.selectedCompanyGroups.splice(index, 1);
        } else {
          const index = this.selectedProjects.findIndex(item => item == this.firstLevelId);
          if (index > -1) this.selectedProjects.splice(index, 1);
        }
      },
      draggableCopy(array) {
        return array.slice();
      },
      startDragAction(event) {
        const actionId = event.item.dataset && event.item.dataset.id;
        const action = this.planningActions.find(item => item.id == actionId);
        this.$emit('selected-action', action);
      },
      endDragAction() {
        this.$emit('selected-action', null);
      },
      dropActionOnUser(event) {
        const actionId = event.item.dataset && event.item.dataset.id;
        const action = this.planningActions.find(item => item.id == actionId);
        const ownerId = event.target.dataset && event.target.dataset.id;
        if (this.selectedActionVirtualParticipants.includes(ownerId)) { // drop on virtual participant
          action.setUsername(ownerId);
        } else {
          action.setUser({ id: ownerId, username: this.$store.getters['users/getUsername']({ id: ownerId }, 'short') });
        }
        this.saveAction(action);
      },
      dropActionOnGroup(event) {
        const actionId = event.item.dataset && event.item.dataset.id;
        const action = this.planningActions.find(item => item.id == actionId);
        const ownerId = event.target.dataset && event.target.dataset.id;
        action.setGroup({ id: ownerId, username: this.getGroupById(ownerId)?.title });
        this.saveAction(action);
      },
      saveAction(action) {
        action.save().then(() => {
          this.$emit('update-original-el', action);
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : action was not saved", body: message });
        });
      },
    },
  };
</script>
