<template>
  <div class="config-lanes lane-config-header-column py-6">
    <!-- APPLY BOUTON-->
    <v-expand-transition>
      <div v-if="showSaveBtn" class="mb-4 text-center">
        <v-btn color="primary" rounded size="large" class="px-6" @click="update()">{{ $t('GLOBAL.APPLY_MODIFICATIONS') }}</v-btn>
      </div>
    </v-expand-transition>
    <!-- CHECKBOX ALL LANES-->
    <v-checkbox v-model="allLanesSelected" :label="$t('CONFIG.SELECT_ALL_LANES')" true-icon="far fa-check-square"
                class="mb-4" style="display: inline-flex"
                @update:model-value="selectConfigLanes()">
    </v-checkbox>
    <!-- DRAG LANES GROUP-->
    <draggable :list="lanesGroup" :item-key="parent => parent.lane.id" handle=".draggable-anchor" @end="updateLanesOrder()">
      <template #item="{ element: parent }">
        <div :class="parent.lane.getColorClass()" class="lane-header bg-el-color mb-2 mr-4">
          <div :style="{ width: `${laneLevelWidth * parent.lane.level}px` }" class="lane-level-gap"></div>
          <div :title="$t('PLANNING.DRAG_AND_DROP_LANES')" class="draggable-anchor grab"></div>
          <div style="flex-grow: 1;">
            <config-lanes-lane :lane="parent.lane" :last-of-block="! parent.children.length"
                               @checkbox-update="checkboxUpdate(parent.lane)" @select-config-lane-color="selectConfigLaneColor(parent.lane)"
                               @add-config-lane="addConfigLane(parent.lane)" @delete-config-lane="deleteConfigLane(parent.lane)">
            </config-lanes-lane>
            <draggable :list="parent.children" :item-key="child => child.lane.id" handle=".draggable-anchor" @end="updateLanesOrder()">
              <template #item="{ element: child, index: childIndex }">
                <div :class="child.lane.getColorClass()" class="lane-header bg-el-color">
                  <div :style="{ width: `${laneLevelWidth * child.lane.level}px` }" class="lane-level-gap"></div>
                  <div :title="$t('PLANNING.DRAG_AND_DROP_LANES')" class="draggable-anchor grab"></div>
                  <div style="flex-grow: 1;">
                    <config-lanes-lane :lane="child.lane" :last-of-block="! child.grandchildren.length && childIndex == parent.children.length - 1"
                                       @checkbox-update="checkboxUpdate(child.lane)" @select-config-lane-color="selectConfigLaneColor(child.lane)"
                                       @add-config-lane="addConfigLane(child.lane)" @delete-config-lane="deleteConfigLane(child.lane)">
                    </config-lanes-lane>
                    <draggable :list="child.grandchildren" item-key="id" handle=".draggable-anchor" @end="updateLanesOrder()">
                      <template #item="{ element: grandchild, index: grandchildIndex }">
                        <div :class="grandchild.getColorClass()" class="lane-header bg-el-color">
                          <div :style="{ width: `${laneLevelWidth * (grandchild.level - 1)}px` }" class="lane-level-gap"></div>
                          <div :title="$t('PLANNING.DRAG_AND_DROP_LANES')" class="draggable-anchor grab"></div>
                          <config-lanes-lane :lane="grandchild" :last-of-block="grandchildIndex == child.grandchildren.length - 1"
                                             @checkbox-update="checkboxUpdate(grandchild)" @select-config-lane-color="selectConfigLaneColor(grandchild)"
                                             @add-config-lane="addConfigLane(grandchild)" @delete-config-lane="deleteConfigLane(grandchild)">
                          </config-lanes-lane>
                        </div>
                      </template>
                    </draggable>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </template>
    </draggable>
    <!-- MODALS LANE COLOR-->
    <v-menu v-if="laneColorEditingLane" :model-value="!! laneColorEditingLane" :close-on-content-click="false"
            :target="`.config-lanes-lane-${laneColorEditingLane.id}-select-color`" offset="4"
            @update:model-value="closeColorEditingModal">
      <v-card>
        <color-picker :planning="planning" :target="laneColorEditingLane" compact @close="closeColorEditingModal"></color-picker>
      </v-card>
    </v-menu>
  </div>
</template>

<style>
  .config-lanes.lane-config-header-column .lane-level-gap {
    flex-shrink: 0;
    background: white;
    transition: width .3s;
  }
  .config-lanes.lane-config-header-column .lane-header {
    display: flex;
    border: none;
  }

  .config-lanes.lane-config-header-column .draggable-anchor {
    border: none;
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import draggable from 'vuedraggable';
  import constants from '@/js/constants.js';
  import PlanningLane from '@/models/PlanningLane';
  import ColorPicker from '@/components/ColorPicker/ColorPicker.vue';
  import ConfigLanesLane from './ConfigLanesLane.vue';

  export default {
    components: {
      draggable,
      ColorPicker,
      ConfigLanesLane,
    },
    props: {
      planning: { type: Object, required: true },
    },
    emits: ['close'],
    data() {
      return {
        initialLanes: this.planning.lanes.map(lane => angular.copy(lane)),
        lanes: this.planning.lanes.map(item => new PlanningLane(this.planning, item)),
        idCounter: Math.max(...this.planning.lanes.map(item => item.id), 0),
        laneLevelWidth: constants.laneLevelWidth,
        allLanesSelected: false,
        laneColorEditingLane: false,
        showSaveBtn: false,
      };
    },
    computed: {
      lanesGroup() {
        const lanesGroupArray = [];
        const laneUsed = this.lanes.map(item => item.id).reduce((acc, item) => { acc[item] = false; return acc; }, {});
        for (let i = 0; i < this.lanes.length; i++) {
          if (! laneUsed[this.lanes[i].id]) {
            const parent = {
              lane: this.lanes[i],
              children: [],
            };
            for (let j = this.lanes.indexOf(this.lanes[i]) + 1; j < this.lanes.length; j++) {
              if (this.lanes[j].level <= this.lanes[i].level) break;
              if (! laneUsed[this.lanes[j].id]) {
                laneUsed[this.lanes[j].id] = true;
                const grandchildren = [];
                for (let k = this.lanes.indexOf(this.lanes[j]) + 1; k < this.lanes.length; k++) {
                  if (this.lanes[k].level <= this.lanes[j].level) break;
                  grandchildren.push(this.lanes[k]);
                  laneUsed[this.lanes[k].id] = true;
                }
                parent.children.push({
                  lane: this.lanes[j],
                  grandchildren,
                });
              }
            }
            lanesGroupArray.push(parent);
          }
        }
        return lanesGroupArray;
      },
      modelIsDirty() {
        if (this.showSaveBtn) return true;
        return this.lanes.length != this.initialLanes.length || this.lanes.some((lane, index) => ! angular.equals(lane.getAll(), this.initialLanes[index].getAll()));
      },
    },
    watch: {
      modelIsDirty(newVal) {
        if (newVal) this.showSaveBtn = true;
      },
    },
    methods: {
      addConfigLane(previousLane) {
        const lane = new PlanningLane(this.planning);
        this.lanes.splice(this.lanes.indexOf(previousLane) + 1, 0, lane);
        lane.label = this.$t('PLANNING.NEW_LANE');
        this.idCounter = Math.max(this.idCounter, Math.max(...this.lanes.map(item => item.id), 0)) + 1;
        lane.id = this.idCounter;
        return lane;
      },
      deleteConfigLane(lane) {
        this.lanes.splice(this.lanes.indexOf(lane), 1);
      },
      checkboxUpdate(lane) {
        const laneIndex = this.lanes.indexOf(lane);
        if (lane.hidden) {
          for (let i = laneIndex + 1; i < this.lanes.length; i++) {
            if (this.lanes[i].level <= this.lanes[laneIndex].level) break;
            this.lanes[i].hidden = true;
          }
        } else {
          for (let i = laneIndex + 1; i < this.lanes.length; i++) {
            if (this.lanes[i].level <= this.lanes[laneIndex].level) break;
            this.lanes[i].hidden = false;
          }
        }
      },
      selectConfigLaneColor(lane) {
        this.laneColorEditingLane = lane;
      },
      closeColorEditingModal() {
        this.laneColorEditingLane = false;
      },
      selectConfigLanes() {
        if (this.allLanesSelected) {
          for (let i = 0; i < this.lanes.length; i++) {
            this.lanes[i].hidden = false;
          }
        } else {
          for (let i = 0; i < this.lanes.length; i++) {
            this.lanes[i].hidden = true;
          }
        }
      },
      updateLanesOrder() {
        const { lanesGroup } = this;
        const newLanes = [];
        for (let i = 0; i < lanesGroup.length; i++) {
          newLanes.push((lanesGroup[i].lane));
          if (lanesGroup[i].children != 0) {
            for (let j = 0; j < lanesGroup[i].children.length; j++) {
              newLanes.push((lanesGroup[i].children[j].lane));
              if (lanesGroup[i].children[j].grandchildren != 0) {
                for (let k = 0; k < lanesGroup[i].children[j].grandchildren.length; k++) {
                  newLanes.push((lanesGroup[i].children[j].grandchildren[k]));
                }
              }
            }
          }
        }
        this.lanes = newLanes;
      },
      update() {
        const addedLanes = [];
        const deletedLanes = [];
        const updatedLanes = [];
        const configLanesIds = this.lanes.map(item => item.id);
        const configLanesObjects = this.lanes.reduce((acc, obj) => { acc[obj.id] = obj; return acc; }, {});
        const initialLanesIds = this.initialLanes.map(item => item.id);
        const InitialLanesObjects = this.initialLanes.reduce((acc, obj) => { acc[obj.id] = obj; return acc; }, {});
        // forEach lane in initialLanes
        for (let j = 0; j < initialLanesIds.length; j++) {
          if (configLanesIds.indexOf(initialLanesIds[j]) === -1) { // if lane not exists in configLanes
            deletedLanes.push(InitialLanesObjects[initialLanesIds[j]]);
          } else if (! angular.equals(InitialLanesObjects[initialLanesIds[j]].getAll(), configLanesObjects[initialLanesIds[j]].getAll())) {
            updatedLanes.push(configLanesObjects[initialLanesIds[j]]);
          }
        }
        // forEach lane in configLanes
        for (let i = 0; i < configLanesIds.length; i++) {
          if (initialLanesIds.indexOf(configLanesIds[i]) === -1) { // if lane not exists in initialLanes
            addedLanes.push(configLanesObjects[configLanesIds[i]]);
          }
        }

        this.updateAllLanes({
          lanesToAdd: addedLanes, // add
          lanesToDelete: deletedLanes, // delete
          lanesToUpdate: updatedLanes, // update
          lanesIdsOrder: configLanesIds, // sort
        });

        this.$emit('close');
      },
      ...mapActions('planning/lanes', ['updateAllLanes']),
    },
  };
</script>
