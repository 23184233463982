<template>
  <!-- Budgets Header -->
  <div class="element-details-card-header">
    <template v-if="! isConsult">
      <v-btn-icon :title="$t('ELEMENT-DETAIL.ADD_BUDGET_ITEM')" size="small" class="mr-2" tabindex="-1" @click="addBudgetItem()">
        <v-icon>far fa-plus</v-icon>
      </v-btn-icon>
    </template>
    <element-details-config-button :el="el" config="show-budgets"></element-details-config-button>
  </div>

  <!-- Budgets Items -->
  <div class="mt-2"></div>
  <template v-if="! isConsult">
    <div v-for="(budget, index) in budgets" :key="index" class="d-flex align-center mb-2 hovervisible-activator">
      <v-text-field v-model.number="budget.amount_inprogress" :placeholder="$t('ELEMENT-DETAIL.AMOUNT_INPROGRESS')" type="number"
                    @change.once="updateBudgets">
      </v-text-field>
      <span class="mx-1">/</span>
      <v-text-field v-model.number="budget.amount" :placeholder="$t('ELEMENT-DETAIL.AMOUNT')" type="number"
                    @change.once="updateBudgets">
      </v-text-field>
      <v-select v-model="budget.icon" :items="budgetIcons" class="mx-1" style="min-width: max-content" @update:model-value.once="updateBudgets">
      </v-select>
      <v-select v-model="budget.state" :items="budgetStatesItems" style="min-width: max-content" @update:model-value.once="updateBudgets">
      </v-select>
      <v-tooltip v-if="budgets.length > 1" location="bottom">
        <template #activator="{ props }">
          <v-btn-icon size="small" color="errorred" class="hovervisible-item ml-1" v-bind="props"
                      @click="removeBudgetItem(index)">
            <v-icon>far fa-trash-alt</v-icon>
          </v-btn-icon>
        </template>
        <span>{{ $t('ELEMENT-DETAIL.DELETE_BUDGET_ITEM') }}</span>
      </v-tooltip>
    </div>
  </template>
  <template v-else>
    <div v-for="(elBudgets, state) in elBudgetsByStates">
      <div v-for="(budget, icon) in elBudgets">
        <span>
          <span :class="`budget-${icon || ''}`" class="mr-.5"></span>
          <span v-if="budget.amount_inprogress && budget.amount">{{ $filters.number(budget.amount_inprogress) }}&nbsp;/</span>
          <span v-if="budget.amount_inprogress && ! budget.amount">{{ $filters.number(budget.amount_inprogress) }}</span>
          <span v-if="budget.amount">{{ $filters.number(budget.amount) }}</span>
        </span>
        <span v-if="budgetStates[state]">&nbsp;({{ $filters.lowercase(budgetStates[state]) }})</span>
      </div>
    </div>
  </template>
</template>

<script>
  import ElementDetailsConfigButton from './partials/ElementDetailsConfigButton';

  const newDefaultBudgetItem = function () { return { amount: null, amount_inprogress: null, icon: '', state: '' }; };

  export default {
    components: {
      ElementDetailsConfigButton,
    },
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        budgets: this.el.getBudgets() || [newDefaultBudgetItem()],
        budgetIcons: [{ title: '€', value: '' }, { title: '$', value: 'usd' }, { title: '£', value: 'gbp' }, { title: 'F CFP', value: 'xpf' }, { title: this.$t('ELEMENT-DETAIL.UNIT'), value: 'unit' }],
      };
    },
    computed: {
      isConsult() { return this.el.isType('macro'); },
      budgetStates() {
        return this.$store.getters['users/accessRight/config'].budgetStates;
      },
      budgetStatesItems() {
        return [{ title: '', value: '' }, ...Object.keys(this.budgetStates).map(key => ({ title: this.budgetStates[key], value: key }))];
      },
      elBudgetsByStates() {
        // compute el budgets to organize as { [state]: { [icon] : budgetObj }}
        const elBudgets = this.el.getBudgets();
        if (! elBudgets) return null;
        const budgetsByStates = {};
        elBudgets.forEach((budget) => {
          if (! budget.amount && ! budget.amount_inprogress) return;
          const { state } = budget;
          if (! budgetsByStates[state]) budgetsByStates[state] = {};
          const icon = budget.icon || '';
          if (! budgetsByStates[state][icon]) budgetsByStates[state][icon] = { amount: 0, amount_inprogress: 0 };
          budgetsByStates[state][icon].amount += budget.amount || 0;
          budgetsByStates[state][icon].amount_inprogress += budget.amount_inprogress || 0;
        });
        return budgetsByStates;
      },
    },
    methods: {
      addBudgetItem() {
        this.budgets.push(newDefaultBudgetItem());
        this.updateBudgets();
      },
      removeBudgetItem(index) {
        if (index > -1 && index < this.budgets.length) {
          this.budgets.splice(index, 1);
          this.updateBudgets();
        }
      },
      updateBudgets() {
        this.el.setBudgets(this.budgets);
      },
    },
  };
</script>
