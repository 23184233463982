<template>
  <div v-if="label" class="mb-1"><label :for="inputId">{{ label }}</label></div>
  <slot :id="inputId"></slot>
</template>

<script>
  // inputId will be accessible via <template #default="{ id }"> in parent component

  export default {
    inheritAttrs: false,
    props: {
      label: { type: String, default: '' },
      id: { type: String, default: '' },
    },
    data() {
      return {
        inputId: this.id || `fieldlabel${this.$.uid}`,
      };
    },
  };
</script>
