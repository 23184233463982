<template>
  <div class="file-upload" @dragenter="mouseOverDropbox = true" @dragleave="mouseOverDropbox = false" @drop="mouseOverDropbox = false">
    <vue-upload-component ref="upload" v-model="files" :drop="true" :post-action="upload ? uploadFileUrl : null" :headers="upload ? tokenHeaders : null"
                          :multiple="multiple" :accept="acceptMimes" :thread="5" :size="20 * 1024 * 1024" :input-id="inputId"
                          :class="mouseOverDropbox && $refs.upload?.dropActive ? 'on-dragover' : null" class="filedropbox"
                          :style="{ 'pointer-events': $refs.upload?.dropActive ? 'none' : 'all' }" style="width: 100%"
                          @input-file="inputFile">
      <div v-if="upload" class="px-3">
        <div v-for="file in files" :key="file.id" class="d-flex">
          <i :class="$filters.mimeIcon(file.type)" style="margin-top: 3px" class="fa-fw"></i>
          <span class="ml-2 text-left" style="max-width: calc(100% - 150px - 20px); overflow-wrap: break-word;">{{ file.name }}</span>
          <div class="ml-2 progress flex-grow-1" style="min-width: 150px">
            <div v-if="file.error" :title="errorMessage(file)" class="progress-bar progress-bar-danger"
                 style="width: 100%; position: relative; z-index: 10; cursor: default;" role="progressbar">
              {{ errorMessage(file) }}
            </div>
            <div v-else class="progress-bar progress-bar-success" :style="{ width: `${file.progress < 100 ? file.progress : 100}%` }" role="progressbar">
              {{ (`${Math.round(file.progress * 1)} %`) }}
            </div>
          </div>
        </div>
      </div>
      <slot name="default">
        <i class="far fa-plus-circle"></i>&nbsp; {{ $t('PROJECT_SHEET.ADD_FILE') }}
      </slot>
      <i v-if="upload" v-show="files.length" class="far fa-lg fa-trash-alt" style="position: absolute; bottom: 20px; right: 10px; opacity: .6; z-index: 10;"
         @click.stop.prevent="$refs.upload.clear()">
      </i>
    </vue-upload-component>
  </div>
</template>

<style lang="scss">
  .file-upload {
    .filedropbox {
      background: #f9f9f9;
      border: 3px dashed #ddd;
      text-align: center;
      padding: 20px 0;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .on-dragover {
      border-color: #0169C1;
    }
  }
</style>

<script>
  import VueUploadComponent from 'vue-upload-component';

  export default {
    components: {
      VueUploadComponent,
    },
    props: {
      inputId: { type: String, default: null },
      imageOnly: { type: Boolean, default: false },
      accept: { type: String, default: undefined },
      multiple: { type: Boolean, default: true },
      upload: { type: Boolean, default: true },
    },
    emits: ['uploaded', 'input-file'],
    data() {
      return {
        files: [],
        mouseOverDropbox: false,
        uploadFileUrl: `${window.apiSrv.host}v1/files`,
        tokenHeaders: window.apiSrv.getTokenHeaders(),
      };
    },
    computed: {
      acceptMimes() {
        return this.imageOnly ? 'image/*' : this.accept;
      },
      isDropActive() {
        return this.$refs.upload?.dropActive;
      },
    },
    methods: {
      inputFile(newFile, oldFile) {
        if (! this.upload) { // keep file for local use
          this.$emit('input-file', newFile, oldFile);
          return;
        }

        if (newFile && ! oldFile) {
          // Add file
          if (this.imageOnly && ! newFile.type.startsWith('image/')) {
            this.$refs.upload.update(newFile, { error: 'typeimage' });
            return;
          }
        }
        // Automatic upload
        if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
          if (newFile?.error == 'abort') return; // user left the page before upload finished
          if (newFile) this.$refs.upload.update(newFile, { name: newFile.name.replace(/'/g, "’") });
          if (! this.$refs.upload.active) this.$refs.upload.active = true;
        }
        // File upload is success
        if (newFile?.success && ! oldFile?.success) {
          this.$emit('uploaded', newFile);
          this.$refs.upload.remove(newFile);
        }
      },
      errorMessage(file) {
        if (! file.error) return "";
        if (file.error == 'size') return this.$t('EXCHANGECENTER.FILE_SIZE_LIMITED');
        if (file.error == 'typeimage') return this.$t('EXCHANGECENTER.IMG_EXPECTED');
        if (file.response) return file.response.message || file.response.status;
        return file.error;
      },
    },
  };
</script>
