import { mapActions } from 'vuex';

export default {
  computed: {
    selection() { return this.$store.state.selection; },
  },
  methods: {
    droppableConfig(lane) {
      if (! this.canModifyLane(lane)) return { disabled: true };
      const vm = this;
      return {
        disabled: lane.hidden,
        accept: '.element',
        drop(event, ui) {
          vm.dropSelection(event, ui, lane);
        },
        over(event, ui) {
          vm.dropOver(event, ui, lane);
        },
        out(event, ui) {
          vm.dropOut(event, ui, lane);
        },
      };
    },

    dropOver(event, ui, lane) {
      if (ui.helper.data('livedrag-active')) {
        $(`#el${ui.helper.data('placeholder_id')}`).show();
        const placeholderEl = this.planning.elements.find(el => el.id == ui.helper.data('placeholder_id'));
        if (placeholderEl) placeholderEl.setLaneId(lane.id);
      }
    },

    dropOut(event, ui, lane) {
      if (ui.helper.data('livedrag-active')) {
        const placeholderEl = this.planning.elements.find(el => el.id == ui.helper.data('placeholder_id'));
        if (placeholderEl && placeholderEl.getLaneId() == lane.id) $(`#el${ui.helper.data('placeholder_id')}`).hide();
      }
    },

    dropSelection(event, ui, lane) {
      const draggedEl = this.planning.elements.find(el => el.id == ui.helper.data('id'));
      if (! draggedEl) return;

      const initialState = { laneId: draggedEl.getLaneId(), processStep: draggedEl.getProcessStep() };
      let saveAllElements = false;

      const isKanbanMode = this.$store.state.ui.planning.mode == 'kanban';

      if (isKanbanMode) {
        const referenceXPosition = ui.position.left + ui.helper.width() / 2;
        const referenceYPosition = ui.offset.top + ui.helper.height() / 2;
        const modifiedElements = draggedEl.kanbanMove(lane.id, referenceXPosition, referenceYPosition);
        if (modifiedElements && modifiedElements.length > 1) saveAllElements = true;
      } else {
        draggedEl.move(lane.id, ui.position.left);
      }

      if (this.selection.length > 1) {
        // other elements in selection drag
        let offsetLane = 0;
        if (lane.id != initialState.laneId) offsetLane = this.planning.lanes.findIndex(item => item.id == lane.id) - this.planning.lanes.findIndex(item => item.id == initialState.laneId);
        let offsetProcessStep = 0;
        if (draggedEl.getProcessStep() != initialState.processStep) { // draggedEl already updated above -> compare with initialState.processStep
          const planningSteps = this.planning.process.steps;
          offsetProcessStep = planningSteps.findIndex(item => item.id == draggedEl.getProcessStep()) - planningSteps.findIndex(item => item.id == initialState.processStep);
        }

        this.selection.forEach((selectedId) => {
          const selectedEl = this.planning.elements.find(el => el.id == selectedId);
          if (! selectedEl || selectedEl.id == draggedEl.id) return;

          let newlaneId = selectedEl.getLaneId();
          if (offsetLane) {
            let newlaneIndex = this.planning.lanes.findIndex(item => item.id === selectedEl.getLaneId()) + offsetLane;
            // if getting out of visible, stop to last lane
            while (! this.planning.lanes[newlaneIndex]) newlaneIndex -= offsetLane / Math.abs(offsetLane);
            newlaneId = this.planning.lanes[newlaneIndex].id;
          }

          if (isKanbanMode) {
            selectedEl.setLaneId(newlaneId);
            if (offsetProcessStep) {
              let newProcessStepIndex = this.planning.process.steps.findIndex(item => item.id === selectedEl.getProcessStep()) + offsetProcessStep;
              // if getting out of visible, stop to last step
              while (! this.planning.process.steps[newProcessStepIndex]) newProcessStepIndex -= offsetProcessStep / Math.abs(offsetProcessStep);
              selectedEl.setProcessStep(this.planning.process.steps[newProcessStepIndex].id);
            }
          } else {
            const selectedElLeftPosition = $(`#el${selectedEl.id}`).position().left;
            selectedEl.move(newlaneId, selectedElLeftPosition);
          }
        });
        saveAllElements = true;
      }

      if (saveAllElements) {
        setTimeout(() => { this.changingElement(); });
      } else {
        setTimeout(() => { this.changingElement(draggedEl); }); // timeout needed when xposition is programmaticaly set back to initial value
      }
      this.$store.state.ui.planning.doNotUpdateSelection = false;
    },
    ...mapActions('planning/elements', ['changingElement']),
  },
};
