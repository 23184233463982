import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import clientConfig from '@/client_customs/config';
import Navigation from '@/components/Navigation/Navigation';
import Home from '@/components/Home/Home';
import Planning from '@/components/Planning/Planning';
import PlanningUsers from '@/components/Dashboards/PlanningUsers/Planningusers';
import ProgressDashboard from '@/components/Dashboards/Progress/ProgressDashboard';
import ActionsDashboard from '@/components/Dashboards/Actions/ActionsDashboard';
import ProcessDashboard from '@/components/Dashboards/Process/ProcessDashboard';
import Portfolio from '@/components/Dashboards/Portfolio/Portfolio';
import PersonalCalendar from '@/components/Dashboards/PersonalCalendar/PersonalCalendar';
import Manage from '@/components/Manage/Manage';
import MonitoringPlanConsult from '@/components/MonitoringPlan/MonitoringPlanConsult';
import MonitoringPlanEdit from '@/components/MonitoringPlan/MonitoringPlanEdit';
import MicrosoftTeamsConfig from '@/components/Integrations/MicrosoftTeams/MicrosoftTeamsConfig';
import MicrosoftTeamsDeletion from '@/components/Integrations/MicrosoftTeams/MicrosoftTeamsDeletion';
import PlanningVersions from '@/components/Dashboards/PlanningVersions/PlanningVersions';

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
  routes: [
    {
      path: '/auth_token/:auth_token',
      beforeEnter(to, from, next) {
        const token = to.params.auth_token;
        if (token) {
          window.apiSrv.setAuthToken(token);
          window.location.href = "";
        } else {
          next('/');
        }
      },
      component: { render: () => null },
    },
    {
      path: '/home',
      components: { default: Home, navigation: Navigation },
      props: { default: { section: 'home' }, navigation: { config: 'home' } },
    },
    {
      path: '/projects',
      components: { default: Home, navigation: Navigation },
      props: { default: { section: 'projects' }, navigation: { config: 'home', section: 'projects' } },
    },
    {
      path: '/dashboards',
      components: { default: Home, navigation: Navigation },
      props: { default: { section: 'dashboards' }, navigation: { config: 'home', section: 'dashboards' } },
    },
    {
      path: '/meetings',
      components: { default: Home, navigation: Navigation },
      props: { default: { section: 'meetings' }, navigation: { config: 'home', section: 'meetings' } },
    },

    { path: '/planning/:id/:prettyname?', name: 'Planning', component: Planning, props: { dynamic: true } },
    { path: '/viewer/:id/:prettyname?', name: 'PlanningViewer', component: Planning, props: {} },
    { path: '/planningview/:id/:prettyname?', component: Planning, props: { dynamic: true, view: true } },
    { path: '/mobilewebview/:id', component: Planning, props: {} },

    {
      path: '/analysis/planningusers',
      component: PlanningUsers,
      beforeEnter: (to, from, next) => {
        if (from.path == '/analysis/process') {
          window.localStorageWrapper.setItem('planningUsersKanbanMode', '');
          next();
        } else if (window.localStorageWrapper.getItem('planningUsersKanbanMode')) {
          next({ path: '/analysis/process', replace: true });
        } else {
          next();
        }
      },
    },
    {
      path: '/analysis/process',
      component: ProcessDashboard,
      beforeEnter: (to, from, next) => {
        window.localStorageWrapper.setItem('planningUsersKanbanMode', 1);
        next();
      },
    },
    { path: '/analysis/progress', component: ProgressDashboard },
    { path: '/analysis/actions', component: ActionsDashboard },
    { path: '/analysis/portfolio', component: Portfolio },
    { path: '/analysis/personalcalendar', component: PersonalCalendar },
    { path: '/analysis/planningversions/:id/:prettyname?', component: PlanningVersions },

    {
      path: '/manage',
      components: { default: Manage, navigation: Navigation },
      props: { navigation: { config: 'manage' } },
    },

    {
      path: '/dashboards/monitoringplan',
      components: { default: MonitoringPlanConsult, navigation: Navigation },
      props: { navigation: { config: 'manage' } },
    },
    {
      path: '/dashboards/monitoringplan/edit',
      components: { default: MonitoringPlanEdit, navigation: Navigation },
      props: { navigation: { config: 'manage' } },
    },
    ...(clientConfig.dashboards && clientConfig.dashboards.router || []),

    /* Herblay specifics */
    { path: '/dashboards/herblay/eventform', component: () => import(/* webpackChunkName: "herblay" */ '@/client_customs/Herblay/HerblayEventSubmission') },
    { path: '/dashboards/herblay/eventform/admin/:eventId?', component: () => import(/* webpackChunkName: "herblay" */ '@/client_customs/Herblay/HerblayEventAdmin') },

    /* Locabri specifics */
    { path: '/dashboards/locabri/import', component: () => import(/* webpackChunkName: "locabri" */ '@/client_customs/Locabri/LocabriImport') },

    { path: '/integrations/microsoftteams/config', component: MicrosoftTeamsConfig },
    { path: '/integrations/microsoftteams/deletion', component: MicrosoftTeamsDeletion },

    { path: '/id_token:catchAll(.*)', component: Home, props: { section: 'home' } }, // keep openid id_token in url (protocol jwt)
    { path: '/:catchAll(.*)', redirect: '/home' },
  ],
});

router.beforeEach((to, from, next) => {
  // Mobile api token
  const { mobiletoken } = to.query;
  if (mobiletoken) {
    window.apiSrv.setAuthToken(mobiletoken, 'mobileapp');
  }

  // mobileview
  if (to.query.mobileview || clientConfig.mobileview) {
    store.state.ui.mobileview = true;
    document.body.classList.add('mobileview');
  }

  if (clientConfig.router?.beforeEach) {
    clientConfig.router.beforeEach(to, from, next);
    return;
  }

  next();
});

export default router;
