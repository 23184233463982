<template>
  <v-dialog v-if="type == 'vdialog'" :model-value="true" persistent :max-width="600" @update:model-value="$emit('close')">
    <v-card class="py-6 px-10">
      <v-btn-icon color="darkgrey" style="position: absolute; top: 4px; right: 4px" @click="$emit('close')">
        <v-icon size="large">far fa-times</v-icon>
      </v-btn-icon>
      <div class="text-h6">
        <slot name="title"></slot>
      </div>
      <div class="py-4">
        <slot name="default"></slot>
      </div>
      <slot name="buttons"></slot>
    </v-card>
  </v-dialog>

  <modal v-else :backdrop-close="false" @close="$emit('close', $event)">
    <div class="py-6 px-10">
      <div class="text-h6">
        <slot name="title"></slot>
      </div>
      <div class="py-4">
        <slot name="default"></slot>
      </div>
      <slot name="buttons"></slot>
    </div>
  </modal>
</template>

<script>
  export default {
    props: {
      type: { type: String, default: 'modal' }, // when inside v-dialog, you need to use vdialog to keep correct z-index
    },
    emits: ['close'],
  };
</script>
