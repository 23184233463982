<template>
  <v-menu v-model="menuIsOpened" location="bottom start">
    <template #activator="{ props }">
      <v-btn :class="menuIsOpened ? 'is-opened' : ''" style="position: absolute; top: 16px; right: 16px" v-bind="props">
        <v-icon size="large" start>far fa-plus</v-icon>{{ $t('PERSONAL_CALENDAR.ADD_EVENT') }}
      </v-btn>
    </template>
    <v-list density="compact">
      <v-list-item class="px-2">
        <v-btn color="primary" rounded block @click.stop="createAction(date)">
          <v-icon class="mr-2">far fa-plus</v-icon>{{ $t('PERSONAL_CALENDAR.NEW_MEETING_ACTION') }}
        </v-btn>
      </v-list-item>
      <v-list-item class="px-2 mt-1">
        <v-btn color="warningorange" rounded block @click.stop="createHoliday(date)">
          <v-icon class="mr-2">far fa-plus</v-icon>{{ $t('PERSONAL_CALENDAR.NEW_HOLIDAY') }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { mapState } from 'vuex';
  import Action from '@/models/Action';

  export default {
    props: {
      date: { type: String, required: true },
    },
    emits: ['open-action', 'open-holiday'],
    data() {
      return {
        menuIsOpened: false,
      };
    },
    computed: {
      selectedUser() {
        return this.selectedCompanyUsers.concat(this.selectedVirtualParticipants)[0];
      },
      ...mapState('multiprojects', ['selectedCompanyUsers', 'selectedVirtualParticipants']),
    },
    methods: {
      createAction(date) {
        const newAction = new Action();
        if (this.selectedUser) {
          newAction.setUser({ id: this.selectedUser, username: this.$store.getters['users/getUsername']({ id: this.selectedUser }, 'short') });
        }
        if (date) newAction.setDuedate(date);
        this.$emit('open-action', newAction);
      },
      createHoliday(date) {
        this.$emit('open-holiday', {
          start: date,
          end: date,
        });
      },
    },
  };
</script>
