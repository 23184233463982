<template>
  <div class="pb-12">
    <div>
      <div class="position-relative">
        <v-btn-icon style="position: absolute" :style="$vuetify.display.xs ? 'top: -40px' : $vuetify.display.sm ? '' : 'left: -56px'" @click="$emit('go-to-previous-slide')">
          <v-icon>far fa-long-arrow-alt-left</v-icon>
        </v-btn-icon>
        <div class="text-primary text-center my-6" style="font-size: 32px;"><b>{{ $t('PREMIUM.SUBSCRIPTION.VALIDATE_ORDER') }}</b></div>
      </div>

      <v-card class="pa-6">
        <div class="text-primary text-center mb-2 font-weight-bold">{{ $t('PREMIUM.SUBSCRIPTION.CHECKOUT_SUMMARY') }} « <span class="text-uppercase">{{ checkoutPlanName }}</span> »</div>
        <table class="w-100">
          <tr>
            <td class="py-1 font-weight-bold">
              {{ $t('PREMIUM.SUBSCRIPTION.SUBSCRIPTION') }} {{ checkoutPlanPeriod }} {{ number_format(checkoutPrice) }} € * {{ users }} {{ $t('PREMIUM.SUBSCRIPTION.USERS', users) }}
            </td>
            <td class="nobr">{{ number_format(users * checkoutPrice) }} €</td>
          </tr>
          <tr>
            <td class="py-1">{{ $t('PREMIUM.SUBSCRIPTION.VAT', vatRate * 100) }}</td>
            <td class="nobr">{{ number_format(users * checkoutPrice * vatRate) }} €</td>
          </tr>
          <tr>
            <td class="py-1">{{ $t('PREMIUM.SUBSCRIPTION.TOTAL_TTC') }} {{ checkoutPlanPeriod }}</td>
            <td class="nobr">{{ number_format(users * checkoutPrice * (1 + vatRate)) }} €</td>
          </tr>
          <tr>
            <td colspan="2" class="py-2" style="line-height: 1.5">
              <span>{{ $t('PREMIUM.SUBSCRIPTION.RENEWAL') }}&nbsp;</span>
              <span v-if="checkoutPlanPeriod == $t('PREMIUM.SUBSCRIPTION.MONTHLY_PERIOD')">{{ $t('PREMIUM.SUBSCRIPTION.MONTHLY_ON') }} {{ checkoutTrialEnd.day }}</span>
              <span v-if="checkoutPlanPeriod == $t('PREMIUM.SUBSCRIPTION.ANNUAL_PERIOD')">{{ $t('PREMIUM.SUBSCRIPTION.ANNUAL_ON') }} {{ checkoutTrialEnd.dayAndMonth }}</span>
            </td>
          </tr>
        </table>
        <form v-show="! formCheckout.showSuccess" action="#" autocomplete="on">
          <div class="d-flex justify-center mt-2">
            <v-card class="mr-2" style="border-radius: 4px"><img src="../../img/cards/card-visa-logo.svg" alt="visa" style="height:30px"></v-card>
            <v-card class="mr-2" style="border-radius: 4px"><img src="../../img/cards/card-mastercard-logo.svg" alt="mastercard" style="height:30px"></v-card>
            <v-card class="mr-2" style="border-radius: 4px"><img src="../../img/cards/card-maestro-logo.svg" alt="maestro" style="height:30px"></v-card>
            <v-card class="mr-2" style="border-radius: 4px"><img src="../../img/cards/card-amex-logo.svg" alt="american express" style="height:30px"></v-card>
          </div>
          <div class="mt-3">
            <label class="font-weight-bold" for="number">{{ $t('PREMIUM.SUBSCRIPTION.CARD_NUMBER') }}</label>
            <v-field variant="outlined" class="mt-1 pa-3">
              <div id="number" class="py-1" style="width: 100%; height: 24px"></div>
            </v-field>
          </div>
          <div class="mt-3">
            <label class="font-weight-bold" for="cardholder-name">{{ $t('PREMIUM.SUBSCRIPTION.CARDHOLDER_NAME') }}</label>
            <v-text-field id="cardholder-name" v-model="formCheckout.cardholderName" variant="outlined" class="mt-1" placeholder="Jean Durand" style="width: 100%"></v-text-field>
          </div>
          <div class="mt-3 d-flex flex-wrap gap-6">
            <div>
              <label class="font-weight-bold" for="exp">{{ $t('PREMIUM.SUBSCRIPTION.EXPIRES_END') }}</label>
              <v-field variant="outlined" class="mt-1 pa-3" style="width: 180px">
                <div id="exp" class="py-1" style="width: 100%; height: 24px"></div>
              </v-field>
            </div>
            <div>
              <label class="font-weight-bold" for="cvc" :title="$t('PREMIUM.SUBSCRIPTION.CRYPTOGRAM_DESCRIPTION')">{{ $t('PREMIUM.SUBSCRIPTION.CRYPTOGRAM') }}</label>
              <v-field variant="outlined" class="mt-1 pa-3" style="width: 90px">
                <div id="cvc" class="py-1" style="width: 100%; height: 24px"></div>
              </v-field>
            </div>
          </div>
          <p class="text-errorred" style="margin-top: 10px">{{ formCheckout.error }}</p>
          <div class="mt-6 text-center">
            <v-btn :loading="formCheckout.submitDisabled" color="accent" rounded size="large" class="px-12" @click="submitCheckout">
              {{ $t('PREMIUM.SUBSCRIPTION.CHECKOUT_SUBSCRIBE') }}
            </v-btn>
          </div>
        </form>

        <!-- Payment success -->
        <div v-if="formCheckout.showSuccess">
          <p style="font-weight: bold;font-size: 32px;margin: 15px 0;">{{ $t('PREMIUM.SUBSCRIPTION.THANK_YOU') }}</p>
          <p>{{ $t('PREMIUM.SUBSCRIPTION.PREMIUM_ACCOUNT_ACTIVATED') }}</p>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
  import StripeSrv from '@/components/Integrations/StripeSrv';

  export default {
    props: {
      plan: { type: String, required: true }, // BUSINESS, BUSINESS12M, TEAM, TEAM12M
      users: { type: Number, required: true },
      vatRate: { type: Number, default: 0.2 },
    },
    emits: ['go-to-previous-slide'],
    data() {
      return {
        availablePlans: {
          BUSINESS: { name: this.$t('PREMIUM.SUBSCRIPTION.MONTHLY_BUSINESS'), period: this.$t('PREMIUM.SUBSCRIPTION.MONTHLY_PERIOD'), price: 26.4 },
          BUSINESS12M: { name: this.$t('PREMIUM.SUBSCRIPTION.ANNUAL_BUSINESS'), period: this.$t('PREMIUM.SUBSCRIPTION.ANNUAL_PERIOD'), price: 264 },
          TEAM: { name: this.$t('PREMIUM.SUBSCRIPTION.MONTHLY_TEAM'), period: this.$t('PREMIUM.SUBSCRIPTION.MONTHLY_PERIOD'), price: 14.4 },
          TEAM12M: { name: this.$t('PREMIUM.SUBSCRIPTION.ANNUAL_TEAM'), period: this.$t('PREMIUM.SUBSCRIPTION.ANNUAL_PERIOD'), price: 144 },
        },
        stripeData: {},
        formCheckout: {
          cardholderName: this.$store.state.users.user.lastname ? `${this.$store.state.users.user.firstname} ${this.$store.state.users.user.lastname}` : '',
          submitDisabled: false,
          error: "",
          showSuccess: false,
        },
      };
    },
    computed: {
      checkoutPlan() {
        return this.plan.toUpperCase();
      },
      checkoutPlanName() {
        return (this.availablePlans[this.checkoutPlan] || {}).name || "INVALID";
      },
      checkoutPlanPeriod() {
        return (this.availablePlans[this.checkoutPlan] || {}).period || "";
      },
      checkoutPrice() {
        return (this.availablePlans[this.checkoutPlan] || {}).price || 0;
      },
      checkoutTrialEnd() {
        const trialEndDate = new Date();
        return this.dateFormats(trialEndDate);
      },
    },
    created() {
      window.apiSrv.call('buy', 'index', { plan: this.checkoutPlan, quantity: this.users }); // do nothing, just for log
    },
    mounted() {
      // Init stripe form
      const stripeConfig = { number: 'cardNumber', exp: 'cardExpiry', cvc: 'cardCvc' };
      StripeSrv.initLoad(stripeConfig).then((data) => {
        this.stripeData = data;
      }).catch((e) => {
        console.error('Stripe not ready', e);
        this.$emit('go-to-previous-slide');
      });
    },
    methods: {
      submitCheckout() {
        if (! window.Stripe) { console.log("Stripe not ready"); return; }
        this.formCheckout.error = "";
        this.formCheckout.submitDisabled = true;

        if (! this.formCheckout.cardholderName) {
          this.formCheckout.error = this.$t('PREMIUM.SUBSCRIPTION.CARDHOLDER_NAME_ERROR');
          this.formCheckout.submitDisabled = false;
          return;
        }
        if (! this.users || ! this.availablePlans[this.checkoutPlan]) {
          this.formCheckout.error = this.$t('PREMIUM.SUBSCRIPTION.INVALID_PLAN');
          return;
        }
        this.stripeData.stripe.createToken(this.stripeData.number, { name: this.formCheckout.cardholderName }).then((response) => {
          if (response.error) {
            this.formCheckout.error = response.error.message;
            this.formCheckout.submitDisabled = false;
          } else {
            const token = response.token.id;
            window.apiSrv.call(`buy/plan/${this.checkoutPlan}/quantity/${this.users}`, 'store', { token }).then((apiResponse) => {
              const { data } = apiResponse;
              let pendingSetupIntent = data && data.subscription && data.subscription.pending_setup_intent;
              if (pendingSetupIntent && pendingSetupIntent.status === "requires_action") { // 3D secure for a first subscription
                this.stripeData.stripe.handleCardSetup(pendingSetupIntent.client_secret).then((result) => {
                  if (result.error) {
                    this.formCheckout.error = `Error : ${result.error.message}`;
                    this.formCheckout.submitDisabled = false;
                  } else {
                    // success with 3D secure
                    this.formCheckout.showSuccess = true;
                    this.reload();
                  }
                });
                return;
              }
              if (! pendingSetupIntent) pendingSetupIntent = data && data.subscription && data.subscription.latest_invoice && data.subscription.latest_invoice.payment_intent;
              if (pendingSetupIntent && pendingSetupIntent.status === "requires_action") { // 3D secure for a new subscription
                this.stripeData.stripe.confirmCardPayment(pendingSetupIntent.client_secret).then((result) => {
                  if (result.error) {
                    this.formCheckout.error = `Error : ${result.error.message}`;
                    this.formCheckout.submitDisabled = false;
                  } else {
                    // success with 3D secure
                    this.formCheckout.showSuccess = true;
                    this.reload();
                  }
                });
                return;
              }
              this.formCheckout.showSuccess = true;
              this.reload();
            }).catch((error) => {
              this.formCheckout.error = `Error : ${error}`;
              this.formCheckout.submitDisabled = false;
            });
          }
        });
      },
      reload() {
        setTimeout(() => { window.location.reload(); }, 5000);
      },
      number_format(nbr) {
        let nbrFixed = nbr.toFixed(2);
        if (nbrFixed.endsWith('.00')) nbrFixed = nbrFixed.slice(0, -3);
        const nbrFormated = nbrFixed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return nbrFormated.replace('.', ',');
      },
      dateFormats(date) {
        const d = new Date(date);
        let month = `${d.getMonth() + 1}`;
        let day = `${d.getDate()}`;

        if (month.length < 2) month = `0${month}`;
        if (day.length < 2) day = `0${day}`;

        return {
          dayAndMonth: [day, month].join('/'),
          day,
        };
      },
    },
  };
</script>
