/** ******** */
/* HELPERS */
/** ******** */

function updateColors(elData) {
  const colors = ["", "darkorange", "#2B8F57", "#ce584e"];
  if (elData.type == 'milestone' && elData.iconStyle && elData.iconStyle.color) {
    elData.color = colors.indexOf(elData.iconStyle.color);
  }
  if (elData.type == 'task' && elData.titleStyle && elData.titleStyle.background) {
    elData.color = colors.indexOf(elData.titleStyle.background);
  }
  if (elData.iconStyle) delete elData.iconStyle.color;
  if (elData.titleStyle) delete elData.titleStyle.background;
  if (! elData.color || elData.color < 0) elData.color = 0;

  // update timeBarColor, keeping the old color prop for compatibility with vue2 version
  if (elData.timeBar?.color && ! elData.timeBar.colorId) {
    const mainColors = ['#0169aa', '#e67e22', '#16a085', '#ce584e', '#8e44ad', '#7f8c8d', '#E4007C', '#AD4F09'];
    elData.timeBar.colorId = mainColors.indexOf(elData.timeBar.color);
    // delete elData.timeBar.color;
  }
}

function updateLastVersionsElements(elData) {
  if (! elData.config) elData.config = {};
  if (elData.type == 'milestone' && elData.config['show-date'] === undefined) elData.config['show-date'] = true;
  if (elData.type == 'task' && elData.config['show-description'] === undefined) elData.config['show-description'] = true;
  if (elData.type == 'task' && elData.config['show-progress'] === undefined) elData.config['show-progress'] = true;
  if (elData.type == 'task' && elData.config['show-checklist'] === undefined) elData.config['show-checklist'] = true;
  if (elData.type == 'task' && elData.config['show-budget'] !== undefined) {
    elData.config['show-budgets'] = elData.config['show-budget'];
    delete elData.config['show-budget'];
  }
  if (elData.type == 'task' && elData.config['show-budgets'] === undefined) elData.config['show-budgets'] = true;
  if (elData.config['show-users'] === undefined) elData.config['show-users'] = true;
  if (elData.config['show-links'] === undefined) elData.config['show-links'] = true;
  if (elData.config['description-style']) {
    elData.descriptionStyle = elData.config['description-style'];
    delete elData.config['description-style'];
  }

  if (elData.style) delete elData.style;

  if (! elData.progress) elData.progress = 0;
  if (typeof elData.progress === 'string') elData.progress = parseInt(elData.progress, 10);
  if (elData.type == 'milestone' && (elData.endtime != elData.starttime)) {
    elData.starttime = moment(elData.starttime).add(moment(elData.endtime).diff(elData.starttime) / 2).format();
    elData.endtime = elData.starttime;
  }
  if (elData.type == 'milestone' && (! elData.icon || elData.icon.indexOf("icon-diamonds") > -1)) {
    elData.icon = `milestone${elData.color || 0}`;
  }
  if (elData.type == 'milestone' && ! elData.width) elData.width = 70;
  if (typeof elData.color == 'undefined') updateColors(elData);
  if (elData.budget) {
    elData.budgets = Array.isArray(elData.budget) ? elData.budget : [elData.budget];
    delete elData.budget;
  }

  delete elData.xposition;
  delete elData.height;
  delete elData.visible;
  delete elData.yposition;

  (elData.users || []).forEach((user) => {
    if (user.id) {
      Object.keys(user).forEach((key) => {
        if (! ['id', 'email', 'firstname', 'lastname', 'avatar', 'percent'].includes(key)) delete user[key]; // note: keep old percent data just in case
      });
    } else if (user.email && ! user.username) {
      user.username = user.email;
      delete user.email;
    }
  });

  (elData.checklist || []).forEach((item) => {
    if (! item.id) item.id = window.uuid();
  });
}

function updateLastVersionsLanes(lane) {
  if (typeof lane.labelStyle === 'string') {
    // Convert to object format
    const labelStyle = {};
    lane.labelStyle.split(";").forEach((style) => {
      const prop = style.split(":");
      labelStyle[prop[0]] = prop[1];
    });
    lane.labelStyle = labelStyle;
  }
  if (Array.isArray(lane.labelStyle)) lane.labelStyle = null;
  if (lane.labelStyle && lane.labelStyle.color) delete lane.labelStyle.color;

  delete lane.monitoringLane;
}

function updateLastVersionsTimeline(timeline) {
  if (typeof timeline.show_presenttime == 'undefined') timeline.show_presenttime = true;
  if (! timeline.hidden || _.isEmpty(timeline.hidden)) {
    if (timeline.starttime && timeline.endtime) {
      timeline.hidden = {
        before: timeline.starttime,
        after: timeline.endtime,
      };
    } else {
      delete timeline.hidden;
    }
  }
  delete timeline.starttime;
  delete timeline.endtime;
}

function updateLastVersionsConfig(config) {
  config.colors = config.colors || [];
  delete config.lanePadding;

  if (config.designcolor?.main == '#0169aa') config.designcolor.main = '#0169C1';
}

export default {
  updateLastVersionsConfig,
  updateLastVersionsTimeline,
  updateLastVersionsLanes,
  updateLastVersionsElements,
};
