import constants from '@/js/constants.js';

const getDefaultTimeline = () => {
  const lang = window.localStorageWrapper.getItem('lang');
  const navlang = (window.navigator.language || '').toLowerCase();
  const timelocale = (navlang.slice(0, 2) == lang && ['en', 'en-gb', 'fr', 'fr-ca'].includes(navlang)) ? navlang : (lang || 'en-gb');

  return {
    hidden: {
      before: moment().startOf('year').format(),
      after: moment().format(),
    },
    steps: ['quarters', 'months'],
    timelocale,
    colwidth: 160,
    workdays: false,
    show_presenttime: true,
    show_timegrid: false,
  };
};

const getDefaultProcessConfig = () => ({
  showPlannedElements: null,
});

const getDefaultProcessStep = () => ({
  id: 1,
  label: "New step",
  color: 0,
  colorShade: '',
  icon: '',
  width: constants.kanbanDefaultColWidth,
});

const getDefaultLane = () => ({
  id: 1,
  label: "New lane",
  labelStyle: null,
  contentHeight: null,
  height: null,
  color: 0,
  colorShade: '',
  level: null,
  hidden: null,
  filteredout: null,
  project_id: null,
  project_rotoken: null,
  project_hidden_sublanes: null,
});

const getDefaultMeta = () => ({
  title: "",
  imgurl: "",
  category: "",
  status: "",
  companyconfig: {}, // { premium: true, logo: '', ...businessConfig }
  access_right: null,
  notifications: null,
  read_only_token: "",
  owner_id: null,
  updated_by: null,
});

const getDefaultConfig = () => ({
  nameStyle: "font-size:16px;font-weight:bold;",
  design: 'flat',
  designcolor: null,
  logo: "",
  displayDependencies: true,
  displayCriticalPath: true,
  lockDragAndDrop: null,
  recurrences: null,
  customFields: null,
  colors: null,
  icons: null,
});

const getDefaultProject = () => ({
  meta: getDefaultMeta(),
  config: getDefaultConfig(),
  timeline: getDefaultTimeline(),
  lanes: [getDefaultLane()],
  elements: [],
  process: { config: getDefaultProcessConfig(), steps: [] },
  workingView: null,
});

export { getDefaultTimeline, getDefaultProcessConfig, getDefaultProcessStep, getDefaultLane, getDefaultMeta, getDefaultConfig, getDefaultProject };
