<template>
  <div>
    <div v-show="! organizationLoaded && ! organizationLoadingError" class="text-center"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-if="! organizationLoaded && organizationLoadingError">{{ organizationLoadingError }}</div>
    <div style="display: inline-block; min-width: 50%">
      <template v-if="organizationLoaded && userIsAdmin">
        <b>{{ $t('MANAGE.CURRENT_SUBSCRIPTIONS') }}</b>
        <v-card class="mt-2 pa-6 mb-6">
          <div class="mt-2">
            <div>{{ $t('MANAGE.SUBSCRIPTIONS') }} {{ $t('ACR.USER') }} : <strong :class="{ 'text-errorred': maxUsersExceeded }">{{ organizationUsersCount }}/{{ organizationMaxUsers }}</strong></div>
            <div>
              {{ $t('MANAGE.SUBSCRIPTIONS') }} {{ $t('ACR.CONSULT') }} : <strong :class="{ 'text-errorred': maxConsultExceeded }">{{ organizationConsultsCount }}/{{ organizationMaxConsults }}</strong>
            </div>
          </div>
        </v-card>
      </template>
      <div v-for="(company, companyIndex) in organizationCompanies" :key="company.id" :style="{ 'margin-left': `${50 * company.level}px` }">
        <div v-if="companyIndex" class="py-6"></div>
        <div class="hovervisible-activator d-flex align-center" style="min-height: 40px">
          <b>{{ company.name }}</b>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn-icon v-if="canIAdminCompany[company.id]" size="small" class="ml-2 hovervisible-item" v-bind="props" @click="editCompanyName(company)">
                <v-icon>far fa-pencil-alt</v-icon>
              </v-btn-icon>
            </template>
            <span>{{ $t('GLOBAL.RENAME') }}</span>
          </v-tooltip>
        </div>
        <v-card>
          <div class="table-responsive">
            <table v-if="company.users.length" class="table">
              <thead class="bg-lightgrey">
                <tr>
                  <th></th>
                  <th>{{ $t('MANAGE.FIRSTNAME') }}</th>
                  <th>{{ $t('MANAGE.LASTNAME') }}</th>
                  <th>{{ $t('MANAGE.EMAIL') }}</th>
                  <th class="text-center">{{ $t('MANAGE.ROLE') }}</th>
                  <th v-if="canIAdminCompany[company.id]" class="text-center">{{ $t('MANAGE.ORGANIZATION.TRANSFER') }}</th>
                  <th v-if="canIAdminCompany[company.id]" class="text-center">{{ $t('MANAGE.REMOVE') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in company.users" :key="user.id">
                  <td :class="{ pointer: openable_user = canIOpenUser(user) }" class="text-center" @click="openUser(user)">
                    <user-avatar :user="user" :size="32"></user-avatar>
                  </td>
                  <td :class="{ 'strong pointer': openable_user }" @click="openUser(user)">{{ $filters.lowercaseUcfirst(user.firstname) }}</td>
                  <td :class="{ 'strong pointer': openable_user }" @click="openUser(user)">{{ $filters.lowercaseUcfirst(user.lastname) }}</td>
                  <td :class="{ pointer: openable_user }" @click="openUser(user)">{{ user.email }}</td>
                  <td class="text-center">
                    <div v-if="! canIEditUserRights(user)">
                      {{ $t(`ACR.${$filters.uppercase(user.access_right)}`) }}
                    </div>
                    <div v-else :class="{ disabled: accessRightsDisabled[user.id] }">
                      <i :class="{ 'selected text-successgreen': user.access_right == 'consult' }" :title="$t('ACR.CONSULT')" class="far fa-eye access-right-icon mx-1"
                         @click="! accessRightsDisabled[user.id] && setUserRights(user, 'consult')">
                      </i>
                      <i :class="{ 'selected text-successgreen': user.access_right == 'user' }" :title="$t('ACR.USER')" class="far fa-pencil-alt access-right-icon mx-1"
                         @click="! accessRightsDisabled[user.id] && setUserRights(user, 'user')">
                      </i>
                      <i :class="{ 'selected text-warningorange': user.access_right == 'company_admin' }" :title="$t('ACR.COMPANY_ADMIN')" class="far fa-unlock-alt access-right-icon mx-1"
                         @click="! accessRightsDisabled[user.id] && setUserRights(user, 'company_admin')">
                      </i>
                    </div>
                  </td>
                  <td v-if="canIAdminCompany[company.id]" class="text-center">
                    <v-menu v-model="transferPopover[user.id]" :close-on-content-click="false" location="left" offset="20">
                      <template #activator="{ props }">
                        <v-btn-icon :title="$t('MANAGE.ORGANIZATION.TRANSFER_USER')" size="small" v-bind="props">
                          <v-icon>far fa-exchange</v-icon>
                        </v-btn-icon>
                      </template>
                      <div class="bg-white px-4 py-2">
                        <p>{{ $t('MANAGE.ORGANIZATION.TRANSFER_USER_DESC') }}</p>
                        <div class="d-flex">
                          <v-select v-model="transferDestinationCompanyId" :items="organizationCompanies" :item-props="item => ! canIAdminCompany[item.id] ? { disabled: true } : null"
                                    item-title="name" item-value="id" :menu-props="{ location: 'bottom' }" style="max-width: 280px">
                          </v-select>
                          <v-btn color="successgreen" rounded class="ml-2" @click="transferPopover[user.id] = false; changeCompany(user, transferDestinationCompanyId)">
                            {{ $t('MANAGE.ORGANIZATION.TRANSFER') }}
                          </v-btn>
                        </div>
                      </div>
                    </v-menu>
                  </td>
                  <td v-if="canIAdminCompany[company.id]" class="text-center">
                    <i :title="$t('MANAGE.REMOVE_USER')" class="far fa-ban access-right-icon" @click="$emit('remove-user', user)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card>
        <v-card v-if="canIAdminCompany[company.id]" class="mt-4 pa-6">
          <b>{{ $t('MANAGE.ORGANIZATION.INVITE_USER_TO_JOIN_ORGANIZATION') }} {{ company.name }}</b>
          ({{ $t('MANAGE.ORGANIZATION.REMAINING_USERS', Math.max(0, Math.min(organizationMaxUsers - organizationUsersCount, company.maxusers - invitetojoinCompany[company.id].users_count))) }}) :
          <div class="d-flex mt-2">
            <v-text-field v-model="invitetojoinCompany[company.id].email" :label="$t('GLOBAL.EMAIL')" :disabled="maxConsultReachedForCompany(company)"
                          :messages="invitetojoinCompany[company.id].message" :error-messages="invitetojoinCompany[company.id].error"
                          type="email" style="max-width: 250px">
            </v-text-field>
            <v-select v-model="invitetojoinCompany[company.id].access_right" :label="$t('MANAGE.ACCESS_RIGHT')" :disabled="maxConsultReachedForCompany(company)"
                      :items="[{ value: 'consult', title: $t('ACR.CONSULT') },
                               { value: 'user', title: $t('ACR.USER'), disabled: maxUsersReachedForCompany(company) },
                               { value: 'company_admin', title: $t('ACR.COMPANY_ADMIN'), disabled: maxUsersReachedForCompany(company) }]"
                      class="ml-4" style="max-width: 200px">
            </v-select>
            <v-btn :disabled="! invitetojoinCompany[company.id].email || invitetojoinCompany[company.id].sending" color="primary" rounded size="large" class="ml-4"
                   @click="inviteUserToOrganizationCompany(company)">
              {{ $t('MANAGE.SEND_INVITE') }}
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    emits: ['remove-user', 'on-update-user-rights'],
    data() {
      return {
        organizationLoadingError: "",
        accessRightsDisabled: {},
        invitetojoinCompany: {},
        transferPopover: {},
        transferDestinationCompanyId: 0,
      };
    },
    computed: {
      user() { return this.$store.state.users.user; },
      organizationLoaded() { return !! this.user.organization; },
      organizationCompanies() { return this.user.organization && this.user.organization.getCompanies(); },
      organizationUsersCount() {
        return this.organizationCompanies.reduce((acc, item) => acc + (item.users || []).length, 0) - this.organizationConsultsCount;
      },
      organizationConsultsCount() {
        return this.organizationCompanies.reduce((acc, item) => acc + (item.users || []).filter(user => user.access_right == 'consult').length, 0);
      },
      organizationMaxUsers() { return this.organizationCompanies[0].maxusers; },
      maxUsersReached() { return this.organizationMaxUsers && this.organizationUsersCount >= this.organizationMaxUsers; },
      maxUsersExceeded() { return this.organizationMaxUsers && this.organizationUsersCount > this.organizationMaxUsers; },
      organizationMaxConsults() { return this.organizationCompanies[0].maxconsults; },
      maxConsultReached() {
        return (this.organizationConsultsCount >= this.organizationMaxConsults)
          && ((this.organizationUsersCount + this.organizationConsultsCount) >= (this.organizationMaxUsers + this.organizationMaxConsults));
      },
      maxConsultExceeded() {
        return (this.organizationConsultsCount > this.organizationMaxConsults)
          && ((this.organizationUsersCount + this.organizationConsultsCount) > (this.organizationMaxUsers + this.organizationMaxConsults));
      },
      canIAdminCompany() {
        return (this.organizationCompanies || []).reduce((acc, company) => {
          acc[company.id] = this.userIsAdmin && !! this.$store.getters['users/getOrganizationSubCompanies'].find(item => item.id == company.id);
          return acc;
        }, {});
      },
      canIOpenUser() {
        return this.$store.getters['manage/canIOpenUser'];
      },
      canIEditUserRights() {
        return this.$store.getters['manage/canIEditUserRights'];
      },
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
    },
    watch: {
      organizationCompanies: {
        handler() {
          this.invitetojoinCompany = (this.organizationCompanies || []).reduce((acc, company) => {
            acc[company.id] = {
              email: "",
              message: "",
              error: "",
              sending: null,
              access_right: 'user',
              consults_count: company.users.filter(item => item.access_right == 'consult').length,
              users_count: null,
            };
            acc[company.id].users_count = company.users.length - acc[company.id].consults_count;
            if (this.maxUsersReached || acc[company.id].users_count >= company.maxusers) acc[company.id].access_right = 'consult';
            return acc;
          }, {});
        },
        immediate: true,
      },
    },
    created() {
      this.$store.state.users.userPromise.then((user) => { this.transferDestinationCompanyId = user.company_id; });
      this.$store.getters['users/getOrganizationPromise'].catch((message) => {
        this.organizationLoadingError = message || this.$t('GLOBAL.ERROR');
      });
    },
    methods: {
      inviteUserToOrganizationCompany(company) {
        if (! this.invitetojoinCompany[company.id].email) return;
        this.invitetojoinCompany[company.id].message = "";
        this.invitetojoinCompany[company.id].error = "";
        if (! window.isValidEmailAddress(this.invitetojoinCompany[company.id].email)) {
          this.invitetojoinCompany[company.id].message = this.$t('MANAGE.INVALID_EMAIL');
          return;
        }
        this.invitetojoinCompany[company.id].sending = true;
        window.apiSrv.call('companyinvites/invite', 'store', {
          company_id: company.id,
          email: this.invitetojoinCompany[company.id].email,
          access_right: this.invitetojoinCompany[company.id].access_right,
        }).then((response) => {
          this.invitetojoinCompany[company.id].message = (response.data && response.data.message) ? response.data.message : this.$t('MANAGE.INVITATION_SENT');
          this.invitetojoinCompany[company.id].email = "";
          this.invitetojoinCompany[company.id].sending = false;
        }).catch((message) => {
          this.invitetojoinCompany[company.id].error = message || this.$t('MANAGE.INVITATION_NOT_SENT');
          this.invitetojoinCompany[company.id].sending = false;
        });
      },
      maxUsersReachedForCompany(company) {
        return this.maxUsersReached || this.invitetojoinCompany[company.id].users_count >= company.maxusers;
      },
      maxConsultReachedForCompany(company) {
        if (this.maxConsultReached) return true;
        const tooManyConsults = this.invitetojoinCompany[company.id].consults_count >= company.maxconsults;
        return tooManyConsults && ((this.invitetojoinCompany[company.id].users_count + this.invitetojoinCompany[company.id].consults_count) >= (company.maxusers + company.maxconsults));
      },
      openUser(user) {
        this.$store.dispatch('manage/openUserFromId', user.id);
      },
      setUserRights(user, newRight) {
        let confirmPromise = Promise.resolve();
        if (user.id == this.user.id) {
          confirmPromise = this.$store.dispatch('ui/msgbox/open', {
            title: user.email,
            body: `<i class="far fa-2x fa-exclamation-triangle text-errorred"></i> &nbsp;${this.$t('MANAGE.LOSING_SPECIAL_RIGHTS')}`,
            buttons: { ok: 'GLOBAL.OK', cancel: 'GLOBAL.CANCEL' },
          });
        }
        confirmPromise.then(() => {
          this.accessRightsDisabled[user.id] = true;
          const oldValue = user.access_right;
          user.access_right = newRight;
          window.apiSrv.call('users', 'update', { id: user.id, access_right: newRight }).then(() => {
            this.accessRightsDisabled[user.id] = false;

            this.$emit('on-update-user-rights', { userId: user.id, newRight });
            if (user.id == this.user.id) window.location.reload();
          }).catch((message) => {
            user.access_right = oldValue;
            this.accessRightsDisabled[user.id] = false;
            if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : User right was not modified", body: message || "" });
          });
        }).catch(() => {});
      },
      changeCompany(user, newCompanyId) {
        window.apiSrv.call('users', 'update', { id: user.id, company_id: newCompanyId }).then(() => {
          this.$store.dispatch('manage/reload');
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : User company was not modified", body: message || "" });
        });
      },
      editCompanyName(company) {
        const newName = window.prompt(this.$t('GLOBAL.RENAME'), company.name);
        if (! newName) return;
        window.apiSrv.call('companies', 'update', { id: company.id, name: newName }).then(() => {
          company.name = newName;
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Company name was not modified", body: message || "" });
        });
      },
    },
  };
</script>
