<template>
  <navigation :config="config" :planning="planning" extended>
    <template #extension>
      <v-text-field v-if="planning.meta" v-model="planning.meta.title" :placeholder="$t('PLANNING.UNNAMED_PROJECT')" spellcheck="false"
                    density="compact" :readonly="! canModifyPlanning" style="flex-grow: 3; min-width: 200px"
                    @focus="startChangingMeta" @blur="changingMeta">
      </v-text-field>
      <v-text-field v-else density="compact" readonly rounded style="flex-grow: 3; min-width: 200px"></v-text-field> <!-- placeholder -->

      <!-- Planning Mode -->
      <div class="mx-2"></div>
      <btn-toggle-filter :class="{ 'events-disabled': ! planningLoadedWithSubprojects }" class="rounded-pill">
        <v-tooltip location="bottom" :disabled="! $vuetify.display.mdAndDown">
          <template #activator="{ props }">
            <v-btn :active="planningLoaded && projectReportIsOpened" v-bind="props" @click="openProjectReport()">
              <v-icon>far fa-flag</v-icon>
              <span class="hidden-md-and-down ml-2">{{ $t('NAV.PROJECT_SHEET') }}</span>
            </v-btn>
          </template>
          {{ $t('NAV.PROJECT_SHEET') }}
        </v-tooltip>
        <v-tooltip location="bottom" :disabled="! $vuetify.display.mdAndDown">
          <template #activator="{ props }">
            <v-btn :active="planningLoaded && ! projectReportIsOpened && ! isKanbanMode" v-bind="props" @click="setPlanningMode(null)">
              <v-icon>svgicon-planning</v-icon>
              <span class="hidden-md-and-down ml-2">{{ $t('NAV.PLANNING_VIEW') }}</span>
            </v-btn>
          </template>
          {{ $t('NAV.PLANNING_VIEW') }}
        </v-tooltip>
        <v-tooltip location="bottom" :disabled="! $vuetify.display.mdAndDown">
          <template #activator="{ props }">
            <v-btn :active="planningLoaded && ! projectReportIsOpened && isKanbanMode" v-bind="props" @click="setPlanningMode('kanban')">
              <v-icon>svgicon-kanban</v-icon>
              <span class="hidden-md-and-down ml-2">{{ $t('NAV.KANBAN_VIEW') }}</span>
            </v-btn>
          </template>
          {{ $t('NAV.KANBAN_VIEW') }}
        </v-tooltip>
      </btn-toggle-filter>

      <v-spacer class="mx-2"></v-spacer>

      <!--    PLANNING TOOLBAR-->
      <template v-if="config == 'planning' || config == 'planningview'">
        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-btn v-draggable-button="{ type: 'task', start: draggableButtonStart, stop: draggableButtonStop }" size="small"
                   :ripple="false" icon elevation="3" :class="{ invisible: projectReportIsOpened }" class="mx-1 tour-add-bubble grab" v-bind="props">
              <div style="width: 24px; position: relative; border-radius: 4px; overflow: hidden">
                <div class="text-white task-default-blue" style="height: 8px; width: 100%; line-height: 0;">...</div>
                <div style="height: 14px; width: 100%; background: rgba(216, 216, 253, 0.7);"></div>
                <div></div>
              </div>
            </v-btn>
          </template>
          <span>{{ $t('NAV.DRAG_AND_DROP_TO_ADD_BUBBLE') }}</span>
        </v-tooltip>
        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-btn v-draggable-button="{ type: 'milestone', start: draggableButtonStart, stop: draggableButtonStop }" size="small"
                   :ripple="false" icon elevation="3" :class="{ invisible: projectReportIsOpened }" class="mx-1 tour-add-milestone grab" v-bind="props">
              <div class="milestone-default-blue">
                <v-icon size="x-large">fa icon-diamonds</v-icon>
              </div>
            </v-btn>
          </template>
          <span>{{ $t('NAV.DRAG_AND_DROP_TO_ADD_MILESTONE') }}</span>
        </v-tooltip>

        <div class="mx-2" style="flex: 0 1 16px"></div>

        <div :class="{ invisible: projectReportIsOpened }" class="bg-white px-2 rounded-pill" style="transition: .28s opacity cubic-bezier(0.4, 0, 0.2, 1)">
          <v-tooltip :model-value="saving.error || null" :color="saving.error ? 'errorred' : null" location="bottom">
            <template #activator="{ props }">
              <v-btn-icon class="tour-save" size="small" v-bind="props" @click="save()">
                <v-icon v-show="! saving.inprogress && ! saving.success && ! saving.error" size="large">far fa-save</v-icon>
                <v-icon v-show="saving.inprogress" size="large">far fa-spinner fa-spin</v-icon>
                <v-icon v-show="saving.success" size="large">far fa-check text-successgreen</v-icon>
                <v-icon v-show="saving.error" class="pointer">far fa-exclamation-triangle text-errorred</v-icon>
              </v-btn-icon>
            </template>
            <span>
              <span v-show="! saving.inprogress && ! saving.success && ! saving.error">{{ $t('NAV.AUTOSAVE') }}</span>
              <span v-show="saving.inprogress">{{ $t('AUTOSAVE.SAVING') }}</span>
              <span v-show="saving.success">{{ $t('AUTOSAVE.SAVED') }}</span>
              <span v-show="saving.error">{{ saving.error }}</span>
            </span>
          </v-tooltip>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn-icon :disabled="! undoable" size="small" v-bind="props" @click="undo()">
                <v-icon size="large">far fa-undo</v-icon>
              </v-btn-icon>
            </template>
            <span>{{ $t('NAV.UNDO') }}</span>
          </v-tooltip>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn-icon :disabled="! redoable" size="small" v-bind="props" @click="redo()">
                <v-icon size="large">far fa-redo</v-icon>
              </v-btn-icon>
            </template>
            <span>{{ $t('NAV.REDO') }}</span>
          </v-tooltip>
          <planning-navigation-search :planning="planning" :disabled="! planningLoaded" @open-element="openElement"></planning-navigation-search>
          <template v-if="config == 'planning' && hasWorkingViews">
            <working-views-navigation v-if="planningLoaded" :planning="planning"></working-views-navigation>
            <v-btn-icon v-else disabled size="small"><v-icon size="large">far fa-filter</v-icon></v-btn-icon> <!-- button placeholder -->
          </template>
          <v-tooltip v-else-if="config == 'planning'" location="bottom">
            <!-- if no working view, use the space to add an openConfig shortcut -->
            <template #activator="{ props }">
              <v-btn-icon :disabled="! planningLoaded" size="small" v-bind="props" @click="openConfig">
                <v-icon size="large">far fa-cog</v-icon>
              </v-btn-icon>
            </template>
            <span>{{ $t('PLANNING.PLANNING_CONFIG') }}</span>
          </v-tooltip>
        </div>
      </template>

      <v-spacer class="mx-2"></v-spacer>

      <!--    PLANNING ACTIONS-->
      <div style="height: 40px">
        <v-tooltip v-if="config == 'planning'" location="bottom">
          <template #activator="{ props }">
            <v-btn variant="flat" size="large" rounded class="tour-share h-100 mx-1" v-bind="props" @click="openShare()">
              <v-icon>far fa-share-square</v-icon>
              <span class="hidden-md-and-down ml-2">{{ $t('NAV.SHARE') }}</span>
            </v-btn>
          </template>
          {{ $t('NAV.SHARE_THIS_PROJECT') }}
        </v-tooltip>
        <v-tooltip v-if="config == 'planning' || config == 'planningview'" location="bottom">
          <template #activator="{ props }">
            <v-btn variant="flat" size="large" rounded class="tour-export h-100 mx-1" v-bind="props" @click="openExport()">
              <v-icon>far fa-image</v-icon>
              <span class="hidden-md-and-down ml-2">{{ $t('NAV.EXPORT') }}</span>
            </v-btn>
          </template>
          {{ $t('NAV.EXPORT_TO_IMAGE_FILE') }}
        </v-tooltip>
        <v-tooltip v-if="config == 'planning' || config == 'planningview'" location="bottom">
          <template #activator="{ props }">
            <v-btn variant="flat" size="large" rounded class="h-100 ml-1" v-bind="props" @click="openImport()">
              <v-icon>far fa-file-import</v-icon>
              <span class="hidden-md-and-down ml-2">{{ $t('NAV.IMPORT') }}</span>
            </v-btn>
          </template>
          {{ $t('PLANNING.IMPORT_TEMPLATE') }}
        </v-tooltip>
      </div>

      <!-- EXPORT / IMPORT MODAL : eager to preserve state of content -->
      <v-dialog v-if="planning.visibleTimeline" v-model="exportImportIsOpened" eager
                content-class="bg-background rounded" width="100%" height="100%">
        <div>
          <v-btn-icon size="small" color="medium-emphasis" class="float-right" style="margin-right: 1px" @click="openExportImport(null)">
            <v-icon size="24">far fa-times</v-icon>
          </v-btn-icon>
        </div>
        <div class="mr-3 px-6 pb-10 overflow-y-auto overflow-x-hidden overscroll-behavior-y-contain">
          <export-import-modal :planning="planning" :planning-view-id="planningViewId"></export-import-modal>
        </div>
      </v-dialog>
    </template>
  </navigation>
</template>

<script>
  import { mergeProps } from 'vue';
  import { mapState, mapActions, mapMutations } from 'vuex';
  import Navigation from '@/components/Navigation/Navigation.vue';
  import WorkingViewsNavigation from '@/components/WorkingViews/WorkingViewsNavigation.vue';
  import ExportImportModal from '@/components/Export/ExportImportModal.vue';
  import PlanningNavigationSearch from './PlanningNavigationSearch.vue';

  export default {
    components: {
      Navigation,
      WorkingViewsNavigation,
      ExportImportModal,
      PlanningNavigationSearch,
    },
    props: {
      planning: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      planningViewId: { type: Number, default: 0 },
    },
    data() {
      return {
        planningLoadedWithSubprojects: false, // switching to project report before subprojects are loaded causes height calculation issues in planning subprojects
      };
    },
    computed: {
      config() {
        return this.view ? 'planningview' : this.dynamic ? 'planning' : '';
      },
      canModifyPlanning() {
        return this.dynamic && ! this.view;
      },
      undoable() {
        return this.$store.getters['undoredo/isUndoable'];
      },
      redoable() {
        return this.$store.getters['undoredo/isRedoable'];
      },
      saving() {
        return this.$store.state.ui.planning.saving;
      },
      planningLoaded() {
        return this.planning.visibleTimeline;
      },
      isKanbanMode() {
        return this.$store.state.ui.planning.mode == 'kanban';
      },
      exportImportIsOpened: {
        get() {
          return !! this.$store.state.ui.planning.exportImportOpeningTab;
        },
        set(newVal) {
          if (! newVal) this.$store.state.ui.planning.exportImportOpeningTab = '';
        },
      },
      hasWorkingViews() { return this.$store.state.users.accessRight.hasWorkingViews; },
      ...mapState('ui/planning', ['projectReportIsOpened']),
    },
    watch: {
      planningLoaded(newVal) {
        this.planningLoadedWithSubprojects = false;
        if (! newVal) return;
        setTimeout(() => {
          Promise.all(this.$store.state.subprojects.promises).then(() => {
            setTimeout(() => {
              this.planningLoadedWithSubprojects = true;
            });
          });
        });
      },
    },
    mounted() {
      if (this.config == 'planning' || this.config == 'planningview') {
        window.Mousetrap.bind('mod+z', (event) => { event.preventDefault(); this.undo(); });
        window.Mousetrap.bind('mod+y', (event) => { event.preventDefault(); this.redo(); });
      }
    },
    unmounted() {
      const hotkeys = ['mod+z', 'mod+y'];
      for (let i = 0; i < hotkeys.length; i++) {
        window.Mousetrap.unbind(hotkeys[i]);
      }
    },
    methods: {
      undo() {
        if (! this.planningLoaded || ! this.undoable) return;
        this.$store.dispatch('undoredo/undo');
      },
      redo() {
        if (! this.planningLoaded || ! this.redoable) return;
        this.$store.dispatch('undoredo/redo');
      },
      setPlanningMode(newVal) {
        this.closeProjectReport();
        this.$store.state.ui.planning.mode = newVal;
        this.$store.dispatch('planning/timeline/set', this.planning.timeline);
        this.$store.commit('ui/planning/savePlanningMode', { mode: newVal, planningId: this.planning.id });
        window.scrollTo({ top: 0 });
      },
      openProjectReport() {
        this.$store.dispatch('ui/planning/openProjectReport');
        window.scrollTo({ top: 0 });
      },
      openShare() {
        if (! this.planningLoaded) return;
        this.openExportImport('share');
      },
      openExport() {
        if (! this.planningLoaded) return;
        this.openExportImport('export-image');
      },
      openImport() {
        if (! this.planningLoaded) return;
        this.openExportImport('import-template');
      },
      draggableButtonStart() {
        this.$store.dispatch('selection/resetSelection');
      },
      draggableButtonStop(event, ui, type) {
        this.addElement({ type, el: null, x: ui.offset.left + ui.helper.width() / 2, y: ui.offset.top + ui.helper.height() / 2 });
      },
      openElement(el) {
        this.$store.dispatch('ui/planning/scrollToEl', { el, open: true });
      },
      mergeProps,
      ...mapActions('planning', ['save']),
      ...mapActions('planning/meta', ['startChangingMeta', 'changingMeta']),
      ...mapActions('ui/planning', ['addElement', 'openConfig']),
      ...mapActions('ui/planning', ['closeProjectReport']),
      ...mapMutations('ui/planning', ['openExportImport']),
    },
  };
</script>
