<template>
  <div>
    <!-- NAVIGATION -->
    <navigation></navigation>

    <!-- FILTER -->
    <planning-versions-filter :project-versions-list="projectVersionsList" :initial-project="initialProject"
                              @update-display="updateDisplay" @update-project-version="updateProjectVersion"
                              @remove-project-version="removeProjectVersion" @restore-project-version="restoreProjectVersion">
    </planning-versions-filter>

    <!-- VERSION CONTENT -->
    <div v-if="! (dataLoaded.projectVersionsList && dataLoaded.versionToDisplay)" class="text-center ma-6" style="text-align: center;"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <v-container v-else fluid>
      <v-tabs v-model="openedTabIndex" slider-color="primary" style="margin-bottom: -2px;">
        <v-tab value="version">{{ planningTabTitle }}</v-tab>
        <v-tab value="comparative">{{ $t('PLANNING_VERSIONS.COMPARATIVE') }}</v-tab>
      </v-tabs>
      <v-window v-model="openedTabIndex" class="mt-6">
        <!-- TAB PLANNING -->
        <v-window-item :transition="false" :reverse-transition="false" value="version">
          <planning-content :planning="displayedPlanning"></planning-content>
        </v-window-item>

        <!-- TAB COMPARATIVE  -->
        <v-window-item :transition="false" :reverse-transition="false" value="comparative">
          <planning-versions-comparative :project-versions-list="projectVersionsList"
                                         :planning="finalPlanning"
                                         :initial-planning="planningVersionsCache[initialProject && initialProject.id] || initialPlanning">
          </planning-versions-comparative>
        </v-window-item>
      </v-window>
    </v-container>
  </div>
</template>

<script>
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import Planning from '@/models/Planning';
  import Navigation from '@/components/Navigation/Navigation';
  import PlanningContent from '@/components/Planning/PlanningContent';
  import PlanningVersionsFilter from './PlanningVersionsFilter';
  import PlanningVersionsComparative from './PlanningVersionsComparative';

  export default {
    components: {
      Navigation,
      PlanningContent,
      PlanningVersionsFilter,
      PlanningVersionsComparative,
    },
    data() {
      return {
        openedTabIndex: null,
        planningId: +this.$route.params.id,
        projectVersionsList: [],
        initialProject: null,
        initialPlanning: {},
        finalPlanning: {},
        displayedPlanning: {},
        planningVersionsCache: {},
        dataLoaded: { projectVersionsList: false, versionToDisplay: false },
      };
    },
    computed: {
      planningTabTitle() {
        let dateText;
        if (this.displayedPlanning == this.initialPlanning) {
          dateText = this.initialPlanning.meta?.version_name
            || `${this.$t('PLANNING_VERSIONS.AS_OF')} ${this.$filters.moment(this.initialPlanning.meta?.date_of_modification, 'L')}`;
        } else {
          dateText = this.$t('PLANNING_VERSIONS.CURRENT');
        }
        return `${this.$t('PLANNING_VERSIONS.VERSION')} (${dateText})`;
      },
    },
    watch: {
      openedTabIndex(newVal) {
        if (newVal == 'version' && this.displayedPlanning) {
          // update elements height
          setTimeout(() => { this.$store.dispatch('planning/timeline/set', this.displayedPlanning.timeline); });
        }
      },
    },
    created() {
      this.initLoad();
      this.loadProject();
    },
    methods: {
      initLoad() {
        this.dataLoaded.projectVersionsList = false;
        return window.apiSrv.call(`planningversions/planning/${this.planningId}`, 'index').then((response) => {
          const data = (response && response.data && response.data.planningversions || []).map(item => ({ ...item, isLocked: ! item.version_name }));
          this.projectVersionsList = data.sort((a, b) => moment(b?.date_of_modification) - moment(a?.date_of_modification));
          this.dataLoaded.projectVersionsList = true;
        });
      },
      loadProject() {
        this.dataLoaded.versionToDisplay = false;
        ProjectSrv.get(this.planningId).then((content) => {
          this.finalPlanning = new Planning(content);
          this.displayedPlanning = this.finalPlanning;
          this.onProjectLoaded();
          this.dataLoaded.versionToDisplay = true;
        }).catch((message) => {
          this.dataLoaded.versionToDisplay = true;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Planning was not loaded", body: `${message || "Something went wrong."}` });
        });
      },
      onProjectLoaded() {
        setTimeout(() => {
          this.$store.dispatch('planning/set', { planning: this.displayedPlanning });
          this.displayedPlanning = this.$store.state.planning;
        });
      },
      updateDisplay(versionInfo) {
        this.initialProject = null;
        this.initialPlanning = null;
        const { date, schedule } = versionInfo;
        if (date && schedule) { // if selected date & schedule
          this.initialProject = this.projectVersionsList.find((version) => {
            const versionDate = moment(version.date_of_modification);
            return versionDate.format('YYYY-MM-DD') === date && versionDate.format('HH:mm') === schedule;
          });
          this.loadInitialProjectVersion();
        } else if (date && ! schedule) { // if selected date
          this.initialProject = this.projectVersionsList.find(version => moment(version.date_of_modification).format('YYYY-MM-DD') === date);
          this.loadInitialProjectVersion();
        } else { // if clear date or saved version
          this.loadProject();
        }
      },
      loadInitialProjectVersion(id) {
        const versionId = id || this.initialProject?.id;
        if (this.planningVersionsCache[versionId]) { // initial version cached
          this.initialPlanning = this.planningVersionsCache[versionId];
          this.displayedPlanning = this.initialPlanning;
          this.onProjectLoaded();
          return;
        }
        this.dataLoaded.versionToDisplay = false;
        ProjectSrv.getProjectVersion(versionId).then((content) => {
          this.initialPlanning = new Planning(content);
          this.initialPlanning.meta.version_name = this.initialProject.version_name;
          this.initialProject.isLocked = ! this.initialProject.version_name;
          this.displayedPlanning = this.initialPlanning;
          this.planningVersionsCache[versionId] = this.initialPlanning;
          this.onProjectLoaded();
          this.dataLoaded.versionToDisplay = true;
        }).catch((message) => {
          this.dataLoaded.versionToDisplay = true;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Planning version was not loaded", body: message });
        });
      },
      updateProjectVersion() {
        const version = this.initialProject;
        if (! version.id) return Promise.reject(new Error("unsaved planning version"));
        if (this.planningVersionsCache[version.id].meta.version_name == version.version_name) return null;

        this.dataLoaded.versionToDisplay = false;
        return window.apiSrv.call('planningversions', 'update', version).then(() => {
          this.planningVersionsCache[version.id] = null;
          this.loadInitialProjectVersion();
        }).catch((error) => {
          return Promise.reject(error);
        });
      },
      removeProjectVersion() {
        this.initialProject.version_name = '';
        this.updateProjectVersion();
      },
      restoreProjectVersion() {
        this.$store.dispatch('ui/msgbox/open', {
          body: this.$t('PLANNING_VERSIONS.RESTORE_WARNING_MESSAGE'),
          buttons: { ok: 'GLOBAL.OK', cancel: 'GLOBAL.CANCEL' },
        }).then(() => {
          window.apiSrv.call(`planningversions/${this.initialProject.id}/restore`, 'index').then((response) => {
            const project = response && response.data && response.data.planning;
            this.goToProject(project);
          }).catch((message) => {
            if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Planning version has not been restored.", body: message });
          });
        }).catch(() => {});
      },
      goToProject(project, isConsult = project.access_right == 'consult') {
        const path = `/${isConsult ? 'viewer' : 'planning'}/${project.id}/${window.slugify(project.title || this.$t('PLANNING.UNNAMED_PROJECT'))}`;
        this.$router.push({ path, query: {} });
      },
    },
  };
</script>
