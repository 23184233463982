function calculate(elements, visibleTimeline, reverse = false) {
  // clean one-way dependencies (A related to B, but B not related to A)
  const dependenciesByElId = elements.reduce((acc, el) => {
    const elDependencies = el.getDependencies();
    if (elDependencies && elDependencies.length) acc.set(el.id, { el, targetIds: new Set(elDependencies.map(item => item.target_id)) });
    return acc;
  }, new Map());
  dependenciesByElId.forEach(({ el, targetIds }, elId) => {
    targetIds.forEach((targetId) => {
      if (! dependenciesByElId.get(targetId)?.targetIds.has(elId)) {
        const elDependencies = el.getDependencies();
        const index = elDependencies.findIndex(item => item.target_id == targetId);
        if (index > -1) {
          elDependencies.splice(index, 1);
          el.setDependencies(elDependencies);
        }
      }
    });
  });

  const relatedEls = elements.filter(item => item.getDependencies()?.length && item.hasDates()).map(el => angular.copy(el));
  const dependencies = {};
  relatedEls.forEach((el) => {
    el.predecessors = [];
    el.successors = [];
    el.getDependencies().forEach((dependency) => {
      const targetEl = relatedEls.find(item => item.id == dependency.target_id);
      if (targetEl && dependency.successor) el.successors.push(targetEl);
      if (targetEl && ! dependency.successor) el.predecessors.push(targetEl);
      if (! dependencies[el.id]) dependencies[el.id] = {};
      if (targetEl) dependencies[el.id][targetEl.id] = dependency;
    });
  });

  let lockedElsConflict = false;
  function setLockedElementAncestorConstraints(el, initialLockedEl) {
    const duration = el.getEndTime().diffWithWorkdays(el.getStartTime(), 'minutes', visibleTimeline.workdays);
    el.maxStart = moment(el.maxEnd).addWithWorkdays(-duration, 'minutes', visibleTimeline.workdays);
    if (el.maxStart.isBefore(el.getStartTime())) { // for dragged element
      if (el.getIsLocked()) { lockedElsConflict = true; return; }
      el.setStartTime(el.maxStart);
      el.setEndTime(el.getStartTime().addWithWorkdays(duration, 'minutes', visibleTimeline.workdays));
      el.constrainingLockedElement = initialLockedEl;
      el.changed = true;
    }
    el.predecessors.forEach((predecessor) => {
      const maxEnd = moment(el.maxStart).addWithWorkdays(-(dependencies[el.id][predecessor.id].delay || 0), 'days', visibleTimeline.workdays);
      predecessor.maxEnd = predecessor.maxEnd ? moment.min(predecessor.maxEnd, maxEnd) : maxEnd;
      setLockedElementAncestorConstraints(predecessor, initialLockedEl || el);
    });
  }

  function setLockedElementDescendantsConstraints(el, initialLockedEl) {
    const duration = el.getEndTime().diffWithWorkdays(el.getStartTime(), 'minutes', visibleTimeline.workdays);
    el.minEnd = moment(el.minStart).addWithWorkdays(duration, 'minutes', visibleTimeline.workdays);
    if (el.minStart.isAfter(el.getStartTime())) { // for dragged element
      if (el.getIsLocked()) { lockedElsConflict = true; return; }
      el.setStartTime(el.minStart);
      el.setEndTime(el.getStartTime().addWithWorkdays(duration, 'minutes', visibleTimeline.workdays));
      el.constrainingLockedElement = initialLockedEl;
      el.changed = true;
    }
    el.successors.forEach((successor) => {
      const minStart = moment(el.minEnd).addWithWorkdays(dependencies[el.id][successor.id].delay || 0, 'days', visibleTimeline.workdays);
      successor.minStart = successor.minStart ? moment.max(successor.minStart, minStart) : minStart;
      setLockedElementDescendantsConstraints(successor, initialLockedEl || el);
    });
  }

  relatedEls.filter(item => item.getIsLocked()).forEach((lockedEl) => {
    lockedEl.maxEnd = lockedEl.getEndTime();
    setLockedElementAncestorConstraints(lockedEl);
    lockedEl.minStart = lockedEl.getStartTime();
    setLockedElementDescendantsConstraints(lockedEl);
  });
  if (lockedElsConflict) {
    const store = window.app.config.globalProperties.$store;
    const $t = store.state.lang.i18n.global.t;
    store.dispatch('ui/msgbox/open', { title: $t('DEPENDENCIES.ERROR_WITH_DEPENDENCIES'), body: $t('DEPENDENCIES.CANNOT_BE_RESOLVED') });
    store.commit('ui/planning/setDisplayDependenciesErrors', true);
    return [];
  }

  function updateElementFromPredecessors(el) {
    if (el.getIsLocked()) return false; // should not be needed but as a security
    let elChanging = false;
    if (el.predecessors.length) {
      const duration = el.getEndTime().diffWithWorkdays(el.getStartTime(), 'minutes', visibleTimeline.workdays);
      el.predecessors.forEach((predecessor) => {
        const dependency = dependencies[el.id][predecessor.id];
        const limitTime = predecessor.getEndTime().addWithWorkdays(dependency.delay || 0, 'days', visibleTimeline.workdays);
        if (el.getStartTime().isBefore(limitTime)) {
          if (el.maxStart && ! el.getStartTime().isBefore(el.maxStart)) return;
          limitTime.add(2 * visibleTimeline.minuteperpx, 'minutes'); // small gap 2 px
          el.setStartTime(el.maxStart ? moment.min(el.maxStart, limitTime) : limitTime);
          el.setEndTime(el.getStartTime().addWithWorkdays(duration, 'minutes', visibleTimeline.workdays));
          el.changed = true;
          elChanging = true;
        }
      });
    }
    return elChanging;
  }

  function updateElementFromSuccessors(el) {
    if (el.getIsLocked()) return false; // should not be needed but as a security
    let elChanging = false;
    if (el.successors.length) {
      const duration = el.getEndTime().diffWithWorkdays(el.getStartTime(), 'minutes', visibleTimeline.workdays);
      el.successors.forEach((successor) => {
        const dependency = dependencies[el.id][successor.id];
        const limitTime = successor.getStartTime().addWithWorkdays(-(dependency.delay || 0), 'days', visibleTimeline.workdays);
        if (el.getEndTime().isAfter(limitTime)) {
          if (el.minEnd && ! el.getEndTime().isAfter(el.minEnd)) return;
          limitTime.add(-2 * visibleTimeline.minuteperpx, 'minutes'); // small gap 2 px
          el.setEndTime(el.minEnd ? moment.max(el.minEnd, limitTime) : limitTime);
          el.setStartTime(el.getEndTime().addWithWorkdays(-duration, 'minutes', visibleTimeline.workdays));
          el.changed = true;
          elChanging = true;
        }
      });
    }
    return elChanging;
  }

  let changing = true;
  let iterationsLimit = 100;
  while (changing && iterationsLimit) {
    changing = false;
    iterationsLimit--;
    for (let i = 0; i < relatedEls.length; i++) {
      const el = relatedEls[i];
      const elChanging = reverse ? updateElementFromSuccessors(el) : updateElementFromPredecessors(el);
      changing = changing || elChanging;
    }
  }
  const store = window.app.config.globalProperties.$store;
  const $t = store.state.lang.i18n.global.t;
  if (iterationsLimit === 0) {
    store.dispatch('ui/msgbox/open', { title: $t('DEPENDENCIES.ERROR_WITH_DEPENDENCIES'), body: $t('DEPENDENCIES.CANNOT_BE_RESOLVED') });
    store.commit('ui/planning/setDisplayDependenciesErrors', true);
    return [];
  }
  store.commit('ui/planning/setDisplayDependenciesErrors', false);
  return relatedEls.filter(item => item.changed);
}

function closestSuccessor(successors, successorsEls) { /* calculateElPredecessors helper */
  const minStartTime = moment.min(successorsEls.map(element => element.getStartTime())).format();
  const closestSuccessorEl = successorsEls.find(element => element.getStartTime().format() == minStartTime);
  const { delay } = successors.find(item => item.target_id == closestSuccessorEl.id);
  return {
    minEndtime: closestSuccessorEl.getStartTime().addWithWorkdays(-delay, 'days', closestSuccessorEl.getPlanning().visibleTimeline.workdays),
  };
}

function calculateElPredecessors(reducedElements /* Set */, relatedEls, relatedEl, depthLimit = 100) {
  // relatedEls: elements.filter(item => item.getDependencies()?.length && item.hasDates()).map(el => angular.copy(el));
  if (depthLimit <= 0) { // infinite loop
    reducedElements.clear();
    return reducedElements;
  }

  const dependencies = relatedEl.getDependencies();
  const successors = dependencies.filter(dependency => dependency.successor);
  const predecessors = dependencies.filter(dependency => ! dependency.successor);
  if (successors.length) {
    const successorsEls = successors.map(successor => relatedEls.find(item => item.id == successor.target_id));
    const { minEndtime } = closestSuccessor(successors, successorsEls);
    const { visibleTimeline } = relatedEl.getPlanning();
    minEndtime.add(2 * -visibleTimeline.minuteperpx, 'minutes'); // small gap 2 px
    if (! relatedEl.getIsLocked() && relatedEl.getEndTime().format() != minEndtime.format()) { // If no critical link with predecessor
      const duration = relatedEl.getEndTime().diffWithWorkdays(relatedEl.getStartTime(), 'minutes', visibleTimeline.workdays);
      const newStartTime = minEndtime.clone().addWithWorkdays(-duration, 'minutes', visibleTimeline.workdays);
      relatedEl.setStartTime(newStartTime);
      relatedEl.setEndTime(minEndtime);
      reducedElements.add(relatedEl);
    }
  }
  if (! predecessors.length) return reducedElements;
  const predecessorsEls = predecessors.map(predecessor => relatedEls.find(item => item.id == predecessor.target_id));
  predecessorsEls.forEach((predecessorEl) => {
    if (predecessorEl) calculateElPredecessors(reducedElements, relatedEls, predecessorEl, depthLimit - 1);
  });
  return reducedElements;
}

function closestPredecessor(predecessors, predecessorsEls) { /* calculateElSuccessors helper */
  const maxEndTime = moment.max(predecessorsEls.map(element => element.getEndTime())).format();
  const closestPredecessorEl = predecessorsEls.find(element => element.getEndTime().format() == maxEndTime);
  const { delay } = predecessors.find(item => item.target_id == closestPredecessorEl.id);
  return {
    minStarttime: closestPredecessorEl.getEndTime().addWithWorkdays(delay, 'days', closestPredecessorEl.getPlanning().visibleTimeline.workdays),
  };
}

function calculateElSuccessors(reducedElements /* Set */, relatedEls, relatedEl, depthLimit = 100) {
  // relatedEls: elements.filter(item => item.getDependencies()?.length && item.hasDates()).map(el => angular.copy(el));
  if (depthLimit <= 0) { // infinite loop
    reducedElements.clear();
    return reducedElements;
  }

  const dependencies = relatedEl.getDependencies();
  const successors = dependencies.filter(dependency => dependency.successor);
  const predecessors = dependencies.filter(dependency => ! dependency.successor);
  if (predecessors.length) {
    const predecessorsEls = predecessors.map(predecessor => relatedEls.find(item => item.id == predecessor.target_id));
    const { minStarttime } = closestPredecessor(predecessors, predecessorsEls);
    const { visibleTimeline } = relatedEl.getPlanning();
    minStarttime.add(2 * visibleTimeline.minuteperpx, 'minutes'); // small gap 2 px
    if (! relatedEl.getIsLocked() && relatedEl.getStartTime().format() != minStarttime.format()) { // If no critical link with predecessor
      const duration = relatedEl.getEndTime().diffWithWorkdays(relatedEl.getStartTime(), 'minutes', visibleTimeline.workdays);
      const newEndTime = minStarttime.clone().addWithWorkdays(duration, 'minutes', visibleTimeline.workdays);
      relatedEl.setStartTime(minStarttime);
      relatedEl.setEndTime(newEndTime);
      reducedElements.add(relatedEl);
    }
  }
  if (! successors.length) return reducedElements;
  const successorsEls = successors.map(successor => relatedEls.find(item => item.id == successor.target_id));
  successorsEls.forEach((successorEl) => {
    if (successorEl) calculateElSuccessors(reducedElements, relatedEls, successorEl, depthLimit - 1);
  });
  return reducedElements;
}

export default {
  calculate,
  calculateElPredecessors,
  calculateElSuccessors,
};
