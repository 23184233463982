<template>
  <div class="element-details-card-header">
    <element-details-config-button :el="el" config="show-users"></element-details-config-button>
  </div>
  <div class="element-details-users mt-2">
    <div v-for="(user, userIndex) in users" class="vcenter mr-2 mb-2 py-1 pl-2 pr-1 bg-background rounded hovervisible-activator">
      <user-avatar :user="user" show-username class="mr-2"></user-avatar>
      <v-tooltip v-if="! isConsult" location="bottom">
        <template #activator="{ props }">
          <v-btn-icon size="small" density="comfortable" color="errorred" class="hovervisible-item ml-1" v-bind="props" @click="removeUser(userIndex)">
            <v-icon>far fa-trash-alt</v-icon>
          </v-btn-icon>
        </template>
        <span>{{ $t('ELEMENT-DETAIL.REMOVE_USER') }}</span>
      </v-tooltip>
    </div>
    <div v-if="! isConsult" class="mt-1 d-flex align-start">
      <v-combobox ref="newUserAutocomplete" v-model="newUserInput" :items="filteredCompanyUsers" :item-title="getNewUserLabel"
                  :custom-filter="(value, queryText, item) => getNewUserLabel(item.raw).toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
                  :placeholder="$t('ELEMENT-DETAIL.ADD_USER')" :hint="$t('ELEMENT-DETAIL.ADD_USER_HINT')"
                  return-object clearable persistent-hint
                  @keyup.enter="addUser()">
        <template #item="{ props, item: { raw: item } }">
          <v-list-item v-bind="props">
            <template #prepend>
              <user-avatar :user="item" :size="32"></user-avatar>
            </template>
          </v-list-item>
        </template>
      </v-combobox>
      <v-btn :disabled="! newUserInput" color="primary" rounded class="ml-2" @click="addUser()">{{ $t('ELEMENT-DETAIL.ADD') }}</v-btn>
    </div>
  </div>
</template>

<script>
  import ElementDetailsConfigButton from './partials/ElementDetailsConfigButton';

  export default {
    components: {
      ElementDetailsConfigButton,
    },
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        planning: this.el.getPlanning(),
        newUserInput: '',
      };
    },
    computed: {
      isConsult() { return this.el.isType('macro'); },
      users() { return this.el.getUsers() || []; },
      organizationUsers() {
        return this.$store.getters['users/getOrganizationUsers'];
      },
      companyGroups() {
        return this.$store.getters['users/groups/getCompanyGroups'] || [];
      },
      getUserById() {
        return this.$store.getters['users/getUserById'];
      },
      getGroupById() {
        return this.$store.getters['users/groups/getGroupById'];
      },
      otherUsersList() {
        const otherUsers = [];
        this.planning.elements.forEach((element) => {
          (element.getUsers() || []).forEach((user) => {
            const username = user.username || user.email;
            if (! user.id && username && otherUsers.findIndex(item => item.username === username) == -1) {
              otherUsers.push({ username });
            }
          });
        });
        const { textUsers } = this.$store.getters['users/accessRight/config'];
        if (textUsers) {
          return otherUsers.concat(textUsers.filter(item => ! otherUsers.find(subitem => subitem.username == item.username)));
        }
        return otherUsers.sort((a, b) => (a.username < b.username ? -1 : 1));
      },
      groupUsersList() {
        if (! this.canAssignGroups) return [];
        return this.companyGroups.map(item => ({
          group_id: item.id,
          title: item.title,
        }));
      },
      filteredCompanyUsers() {
        return this.groupUsersList.concat(this.organizationUsers, this.otherUsersList).filter((user) => {
          if (user.group_id) return ! this.users.find(item => item.group_id == user.group_id);
          if (user.id) return ! this.users.find(item => item.id == user.id);
          return this.users.findIndex(item => item.username === user.username) == -1;
        });
      },
      usernames() {
        return this.organizationUsers.concat(this.otherUsersList).reduce((acc, user) => {
          acc[user.id || user.username] = this.$store.getters['users/getUsername'](user);
          return acc;
        }, {});
      },
      canAssignGroups() { return this.$store.state.users.accessRight.canAssignGroups; },
    },
    watch: {
      organizationUsers: {
        handler() { this.updateUsersData(); },
        immediate: true,
      },
      companyGroups: {
        handler() { this.updateUsersData(); },
        immediate: true,
      },
      newUserInput(newVal) {
        if (typeof newVal == 'object') {
          this.addUser();
        }
      },
    },
    methods: {
      addUser(specificUser) {
        const user = specificUser || this.newUserInput;
        if (! user) return;
        if (typeof user == 'object') { // user in company users or existing virtual participant
          if (! user.id && ! user.username && ! user.group_id) return;
          if (user.id && this.users.find(item => item.id == user.id)) return;
          if (user.username && this.users.find(item => item.username == user.username)) return;
          if (user.group_id && this.users.find(item => item.group_id == user.group_id)) return;
          let fields = ['username'];
          if (user.id) fields = ['id', 'email', 'firstname', 'lastname', 'avatar'];
          if (user.group_id) fields = ['group_id', 'title'];
          this.users.push({ ..._.pick(user, fields) });
        } else { // free text
          const username = this.newUserInput;
          if (! username) return;
          if (this.users.find(item => item.username == username)) return;
          this.users.push({ username });
        }
        this.el.setUsers(this.users);
        this.newUserInput = '';
        this.$nextTick(() => {
          this.$refs.newUserAutocomplete.isMenuActive = false;
        });
      },
      removeUser(index) {
        if (index > -1 && index < this.users.length) {
          this.users.splice(index, 1);
          this.el.setUsers(this.users);
        }
      },
      getNewUserLabel(item) {
        if (! item) return '';
        return item.group_id ? item.title : this.usernames[item.id || item.username];
      },
      updateUsersData() {
        if (! this.users.length) return;
        const users = this.users.map((user) => {
          const companyUser = user.id && this.getUserById(user.id);
          if (companyUser) {
            return {
              ...user,
              firstname: companyUser.firstname,
              lastname: companyUser.lastname,
              email: companyUser.email,
              avatar: companyUser.avatar,
            };
          }
          const companyGroup = user.group_id && this.getGroupById(user.group_id);
          if (companyGroup) {
            return {
              ...user,
              title: companyGroup.title,
            };
          }
          return user;
        });
        this.el.setUsers(users);
      },
    },
  };
</script>
