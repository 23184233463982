import clientConfig from '@/client_customs/config';

const getDefaultTask = () => angular.merge({
  id: null,
  xposition: null,
  visible: null,
  filteredout: null,
  height: null,
  yposition: null,
  alerts: null,
  data: {
    type: "task",
    lane_id: 1,
    title: "",
    titleStyle: { "font-weight": "bold" },
    icon: "",
    html: "",
    descriptionStyle: null,
    starttime: null,
    endtime: null,
    width: 130,
    priority: null,
    progress: 0,
    checklist: null,
    users: null,
    links: null,
    budgets: null,
    dependencies: null,
    color: 0,
    colorShade: "",
    isLocked: null,
    timeBar: null,
    schedule: null,
    recurrence_id: null,
    processStep: null,
    processPriority: null,
    config: {
      "show-description": true,
      "show-dates": false,
      "show-progress": true,
      "show-checklist": true,
      "show-users": true,
      "show-links": true,
      "show-budgets": true,
      "show-projecttitle": false, // multiprojects dashboards
    },
    customFields: null,
  },
  isDragPlaceholder: null,
  isResizePlaceholder: null,
  modificationUser: null,
}, clientConfig.elements?.defaultTask);

const getDefaultMacro = () => ({
  id: null,
  xposition: null,
  visible: null,
  filteredout: null,
  height: null,
  yposition: null,
  alerts: null,
  data: {
    type: "macro",
    lane_id: 1,
    title: "",
    titleStyle: { "font-weight": "bold" },
    icon: "",
    html: "",
    descriptionStyle: null,
    starttime: "2014-08-01T00:00:00+02:00",
    endtime: "2014-09-10T00:00:00+02:00",
    subTasks: null,
    width: 130,
    priority: null,
    progress: 0,
    checklist: null,
    users: null,
    links: null,
    budgets: null,
    dependencies: null,
    color: 0,
    colorShade: "",
    isLocked: null,
    timeBar: null,
    schedule: null,
    recurrence_id: null,
    config: {
      "show-description": true,
      "show-dates": false,
      "show-progress": true,
      "show-checklist": true,
      "show-users": true,
      "show-links": true,
      "show-budgets": true,
      "show-subTasks": true,
      "show-projecttitle": false, // multiprojects dashboards
    },
    customFields: null,
  },
  isDragPlaceholder: null,
  isResizePlaceholder: null,
  modificationUser: null,
});

const getDefaultMilestone = () => angular.merge({
  id: null,
  xposition: null,
  visible: null,
  filteredout: null,
  height: null,
  yposition: null,
  ytextposition: null,
  alerts: null,
  data: {
    type: "milestone",
    lane_id: 1,
    title: "",
    titleStyle: { "font-weight": "bold" },
    icon: "milestone0",
    html: "",
    starttime: null,
    endtime: null,
    width: 70,
    priority: null,
    progress: null,
    users: null,
    links: null,
    dependencies: null,
    color: 0,
    isLocked: null,
    timeBar: null,
    schedule: null,
    processStep: null,
    processPriority: null,
    config: {
      "show-date": true,
      "show-users": true,
      "show-links": true,
      "show-projecttitle": false, // multiprojects dashboards
    },
    meeting_id: null,
    customFields: null,
  },
  isDragPlaceholder: null,
  isResizePlaceholder: null,
  modificationUser: null,
}, clientConfig.elements?.defaultMilestone);

const getDefaultChecklistItem = () => ({
  id: window.uuid(),
  title: "",
  checked: false,
  duedate: null,
  user_id: null,
  group_id: null,
  username: null,
  workload: null,
  workload_used: null,
  weight: null,
});

export { getDefaultTask, getDefaultMacro, getDefaultMilestone, getDefaultChecklistItem };
