<template>
  <v-menu location="bottom center">
    <template #activator="{ props }">
      <v-btn :active="$route.path == '/dashboards'" variant="text" class="pl-1 pr-2" style="min-width: 0" v-bind="props">
        <v-icon>fas fa-caret-down</v-icon>
      </v-btn>
    </template>
    <v-list density="compact">
      <template v-if="customDashboards">
        <v-list-item v-for="dashboard in customDashboards" :key="dashboard.to" :to="dashboard.to">
          <template #prepend>
            <v-img :src="dashboard.img" :width="48" :max-height="40" cover position="0 0" class="mr-2"></v-img>
          </template>
          {{ dashboard.title }}
        </v-list-item>
        <v-divider></v-divider>
      </template>

      <v-list-item v-for="dashboard in dashboards" :key="dashboard.path" :to="dashboard.path">
        <template #prepend>
          <v-img :src="dashboard.img" :width="48" :max-height="40" cover position="0 0" class="mr-2"></v-img>
        </template>
        {{ dashboard.title }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import imgMonitoringProgress from '@/img/dashboards/monitoringprogress-sketch.svg';
  import imgPlanningUsers from '@/img/dashboards/planningusers-sketch.svg';
  import imgMonitoringActions from '@/img/dashboards/monitoringactions-sketch.svg';
  import imgPortfolio from '@/img/dashboards/portfolio-sketch.svg';
  import imgPersonalCalendar from '@/img/dashboards/personalcalendar-sketch.svg';
  import imgMonitoringPlan from '@/img/dashboards/monitoringplan.png';
  import imgHomeUser from '@/img/dashboards/homeuser-sketch.svg';

  export default {
    data() {
      return {
        customDashboards: clientConfig.dashboards?.list,
      };
    },
    computed: {
      dashboards() {
        const dashboards = [
          { path: '/analysis/progress', title: this.$t('MONITORING_PROGRESS.TITLE'), img: imgMonitoringProgress },
          { path: '/analysis/planningusers', title: this.$t('PLANNINGUSERS.USERS_PLANNING'), img: imgPlanningUsers },
          { path: '/analysis/actions', title: this.$t('MONITORING_ACTIONS.TITLE'), img: imgMonitoringActions },
          { path: '/analysis/portfolio', title: this.$t('PORTFOLIO.TITLE'), img: imgPortfolio },
        ];
        if (this.isBusiness) dashboards.push({ path: '/analysis/personalcalendar', title: this.$t('PERSONAL_CALENDAR.TITLE'), img: imgPersonalCalendar });
        if (this.accessRight.hasMonitoringPlan) dashboards.push({ path: '/dashboards/monitoringplan', title: this.$t('MONITORING_PLAN.TITLE'), img: imgMonitoringPlan });
        dashboards.push({ path: '/home', title: this.$t('HOME.PERSONAL_DASHBOARD'), img: imgHomeUser });
        return dashboards;
      },
      accessRight() {
        return this.$store.state.users.accessRight;
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
  };
</script>
