<template>
  <v-fade-transition v-if="! lane.hidden">
    <div v-show="visible" class="lane-menu align-center px-1" style="display: flex; width: 100%">
      <v-fade-transition>
        <div v-if="visible" class="d-flex align-center flex-grow-1">
          <v-btn-icon :title="$t('PLANNING.INDENT_LANE')" size="small" density="compact" style="width: 8px"
                      @click="setLaneLevel(lane.level ? lane.level - 1 : 1)">
            <v-icon color="white" style="width: 8px">{{ lane.level ? 'fas fa-caret-left' : 'fas fa-caret-right' }}</v-icon>
          </v-btn-icon>
          <v-spacer></v-spacer>
          <template v-if="lane.level == 1">
            <v-btn-icon :title="$t('PLANNING.INDENT_LANE')" size="small" density="compact" style="width: 8px"
                        @click="setLaneLevel(2)">
              <v-icon color="white" style="width: 8px">fas fa-caret-right</v-icon>
            </v-btn-icon>
            <v-spacer></v-spacer>
          </template>
          <v-btn-icon :title="$t('PLANNING.HIDE_LANE')" size="small" density="compact" class="ml-1"
                      @click="hideLane()">
            <v-icon color="white">far fa-eye-slash</v-icon>
          </v-btn-icon>
          <v-spacer></v-spacer>
          <v-btn-icon :title="$t('PLANNING.SELECT_COLOR')" size="small" density="compact" @click="selectLaneColor()">
            <v-icon color="successgreen">fas fa-tint</v-icon>
          </v-btn-icon>
          <v-spacer></v-spacer>
          <v-btn-toggle :model-value="(lane.getLabelStyle() || {})['font-weight']" style="height: 20px"
                        @update:model-value="toggleLaneLabelStyle('font-weight')">
            <v-btn value="bold" icon size="small" density="compact" style="min-width: 20px">
              <v-icon>far fa-bold</v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle :model-value="(lane.getLabelStyle() || {})['font-style']" class="ml-1" style="height: 20px"
                        @update:model-value="toggleLaneLabelStyle('font-style')">
            <v-btn value="italic" icon size="small" density="compact" style="min-width: 20px">
              <v-icon>far fa-italic</v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-spacer></v-spacer>
        </div>
      </v-fade-transition>
      <delete-lane-popover :lane="lane" @delete="deleteLane(lane.id)"></delete-lane-popover>
    </div>
  </v-fade-transition>

  <!-- HIDDEN LANE MENU -->
  <v-fade-transition v-else>
    <div v-show="visible" class="lane-menu px-1">
      <v-btn-icon :title="$t('PLANNING.SHOW_LANE')" size="small" density="compact" @click="showLane()">
        <v-icon color="white">far fa-eye</v-icon>
      </v-btn-icon>
    </div>
  </v-fade-transition>
</template>

<style lang="scss">
  .lane-menu {
    color: gray;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px 0;
    background: rgba(0,0,0,0.6);

    > * {
      cursor: pointer;
    }
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import DeleteLanePopover from './DeleteLanePopover';

  export default {
    components: {
      DeleteLanePopover,
    },
    props: {
      lane: { type: Object, required: true },
      visible: { type: Boolean, default: false },
    },
    emits: ['select-color'],
    methods: {
      selectLaneColor() {
        this.$emit('select-color');
      },
      setLaneLevel(level) {
        const { lane } = this;
        this.startChangingContent(lane);
        lane.level = level;
        this.changingContent(lane);
      },
      toggleLaneLabelStyle(prop) {
        const { lane } = this;
        const activeValue = { 'font-weight': 'bold', 'font-style': 'italic' }[prop] || '';
        this.startChangingContent(lane);
        const laneLabelStyle = lane.getLabelStyle() || {};
        laneLabelStyle[prop] = laneLabelStyle[prop] == activeValue ? 'normal' : activeValue;
        lane.setLabelStyle(laneLabelStyle);
        this.changingContent(lane);
      },
      hideLane() {
        const { lane } = this;
        this.startChangingContent(lane);
        this.setLaneHidden({ lane, hidden: true });
        this.changingContent(lane);
      },
      showLane() {
        const { lane } = this;
        this.startChangingContent(lane);
        this.setLaneHidden({ lane, hidden: false });
        this.changingContent(lane);
      },
      ...mapActions('planning/lanes', ['startChangingContent', 'changingContent', 'deleteLane', 'setLaneHidden']),
    },
  };
</script>
