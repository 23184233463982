import { defineAsyncComponent } from 'vue';
import lang from '@/client_customs/Metro/lang.json';

const MetroDuplicateElementModal = defineAsyncComponent(() => import('@/client_customs/Metro/MetroDuplicateElementModal'));
const MetroCiblage = defineAsyncComponent(() => import('@/client_customs/Metro/MetroCiblage'));
const MetroHtmlText = defineAsyncComponent(() => import('@/client_customs/Metro/MetroHtmlText'));
const MetroPlanningHeaderLink = defineAsyncComponent(() => import('@/client_customs/Metro/MetroPlanningHeaderLink'));

export default {
  clientCustomComponents: { MetroDuplicateElementModal, MetroCiblage, MetroHtmlText, MetroPlanningHeaderLink },
  sso: {
    protocol: 'jwt',
    login: 'https://adfs4int.fds.metro.info/adfs/oauth2/authorize?client_id=1062536242-2353865532-1100132520-1486348622&response_type=id_token&scope=openid%20email%20profile&redirect_uri=https%3A%2F%2Fmetro.bubbleplan.net&nonce=1234',
    response_type: 'id_token',
  },
  lang,
  dashboards: {
    actionsDashboard: {
      showActionSubtitle: true,
    },
  },
  icons: {
    add: {
      'metro-mail-received': { name: 'fas fa-envelope-open-text', color: 'primary', label: "Courrier reçu" },
      email: {},
      mobile: { label: 'Sms' },
      phone: {},
      'metro-coupon': { name: 'fas fa-money-bill-alt', color: 'successgreen', label: "Bon d'achat" },
      store: {},
      globe: { label: 'Multicanal' },
      rocket: {},
      target: {},
      share: {},
      lock: {},
      unlock: {},
    },
    remove: ['shareAlt', 'manager'],
    position: 0,
  },
  colors: [
    { label: "Courrier", sublabels: ["", "", "OS", "", "Réc"] },
    { label: "SMS", sublabels: ["Réc", "", "OS", "", ""] },
    { label: "E-mail", sublabels: ["", "OS", "Réc", "", ""] },
    { label: "", sublabels: ["", "", "", "", ""] },
    { label: "Phoning", sublabels: ["", "OS", "", "", "Réc"] },
    { label: "Store / FDV / BA", sublabels: ["", "", "", "Store / FDV / BA", ""] },
  ],
  timeline: {
    alwaysHighlighted: true,
    timelineFormats: {
      en: [{}, { days: 'dd DD/MM' }],
      fr: [{}, { days: 'dd DD/MM' }],
    },
  },
  subprojects: {
    visibleDetails: ['synthesis'],
    synthesisHidden: ['description', 'checklist', 'users', 'budgets'],
    synthesisPopover: true,
  },
  planningContent: {
    customHeaderComponent: 'MetroPlanningHeaderLink',
  },
  pool: {
    customComponentsList: ['MetroDuplicateElementModal'],
  },
  elements: {
    customDuplicate: {
      cb(el) {
        this.$store.dispatch('clientCustoms/metro/openDuplicate', el);
      },
    },
    customDescription: { dataField: 'description', component: 'MetroHtmlText', label: "Description", icon: 'far fa-align-justify', defaultValues: '<p><strong><u>Message :</u></strong>&nbsp;</p><p></p><p><strong><u>Dates offres :</u></strong>&nbsp;</p><p><u><strong>Cible :</strong></u>&nbsp;</p><p><strong><u>Nb clients :</u></strong>&nbsp;</p>' },
    customFields: [
      { dataField: 'ciblage', component: 'MetroCiblage', label: "Fiche ciblage", icon: 'far fa-bullseye-arrow', active: true, inMenu: true },
      { dataField: 'memo', component: 'MetroHtmlText', label: "Mémo", icon: 'far fa-clipboard', class: 'ml-6', active: true },
    ],
    defaultTask: {
      data: {
        config: {
          "show-progress": false,
          "show-checklist": false,
          "show-users": false,
        },
      },
    },
  },
  elementDetails: {
    display: {
      progress: false, checklist: true, users: false, links: false, budgets: false, alerts: false,
    },
  },
  hideSupportChat: true,
};
