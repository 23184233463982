<template>
  <div v-if="companyLoaded" class="d-flex flex-wrap gap-6">
    <v-card v-if="isTrial" class="pa-6 flex-grow-1 flex-center-center flex-column">
      <div>{{ $t('PREMIUM.ACCOUNT_IS_TRIAL') }} <b class="nobr">{{ $filters.moment(premiumExpireDate, 'll') }}</b></div>
      <v-btn color="accent" rounded size="large" class="mt-4 text-uppercase" @click="openPremium()">
        {{ $t('PREMIUM.SEE_AVAILABLE_OFFERS') }}
      </v-btn>
    </v-card>

    <v-card v-if="canInviteUsers && ! hasOrganization" class="pa-6 text-center">
      <b>{{ $t('MANAGE.CURRENT_SUBSCRIPTIONS') }}</b>
      <div :class="{ 'flex-column': ! (isPremium && ! maxUsersExceeded) }" class="mt-2 d-flex align-center">
        <div>
          <div>{{ $t('MANAGE.SUBSCRIPTIONS') }} {{ $t('ACR.USER') }} : <strong :class="{ 'text-errorred': maxUsersExceeded }">{{ users_count }}/{{ company.maxusers }}</strong></div>
          <div>{{ $t('MANAGE.SUBSCRIPTIONS') }} {{ $t('ACR.CONSULT') }} : <strong :class="{ 'text-errorred': maxConsultExceeded }">{{ consults_count }}/{{ company.maxconsults }}</strong></div>
        </div>
        <v-btn icon v-if="isPremium && ! maxUsersExceeded" :title="$t('PREMIUM.UPDATE_SUBSCRIPTION')" class="ml-4" @click.stop="openPremium()">
          <v-icon color="accent">fas fa-cart-plus</v-icon>
        </v-btn>
        <v-btn v-else color="accent" rounded size="large" class="mt-2" @click.stop="openPremium()">{{ $t('PREMIUM.UPDATE_SUBSCRIPTION') }}</v-btn>
      </div>
    </v-card>

    <!-- Change Offer Team or Business -->
    <v-card v-if="isTrial" class="pa-6 flex-grow-1 flex-center-center flex-column">
      <b>{{ $t('PREMIUM.PACKAGE.ACTIVE_OFFER') }}</b>
      <b :class="isBusiness ? 'text-businesscolor' : 'text-teamcolor'" class="ml-1">{{ isBusiness ? 'BUSINESS' : 'TEAM' }}</b>
      <div class="mt-2 text-center">
        <v-btn color="accent" rounded variant="outlined" @click="changeTrialPackage">{{ $t('PREMIUM.PACKAGE.CHANGE_YOUR_OFFER') }}</v-btn>
      </div>
    </v-card>

    <div v-if="canInviteUsers" class="w-100">
      <b>{{ $t('MANAGE.INVITE_USER') }}</b>
      <v-card class="pa-6 mt-2">
        <div class="mt-2 d-flex align-center">
          <v-text-field v-model="invitetojoin.email" :label="$t('GLOBAL.EMAIL')" :disabled="maxConsultReached" :messages="invitetojoin.message" :error-messages="invitetojoin.error"
                        type="email" class="mr-4" style="max-width: 250px">
          </v-text-field>
          <btn-toggle-field v-model="invitetojoin.access_right" :disabled="maxConsultReached"
                            :items="[{ value: 'consult', title: $filters.ucfirst($t('ACR.CONSULT')) },
                                     { value: 'user', title: $filters.ucfirst($t('ACR.USER')), disabled: maxUsersReached },
                                     { value: 'company_admin', title: $filters.ucfirst($t('ACR.COMPANY_ADMIN')), disabled: maxUsersReached }]">
          </btn-toggle-field>
          <v-btn :disabled="! invitetojoin.email || invitetojoin.sending" :loading="invitetojoin.sending" color="primary" rounded size="large" class="ml-4"
                 @click="inviteUser()">
            {{ $t('MANAGE.SEND_INVITE') }}
          </v-btn>
        </div>
        <div v-if="pending_invites.length" class="mt-2">
          <b class="text-primary pointer" @click="show_pending_invites = ! show_pending_invites">
            <i :class="show_pending_invites ? 'fa-caret-down' : 'fa-caret-right'" class="fas" style="min-width: 1em"></i>
            {{ $t('MANAGE.INVITES_PENDING', { count: pending_invites.length }) }}
          </b>
          <v-fade-transition hide-on-leave>
            <table v-if="show_pending_invites">
              <transition-group name="list-fade" tag="tbody">
                <tr v-for="invite in pending_invites" :key="invite.id" class="list-fade-item">
                  <td>{{ invite.email }}</td>
                  <td class="px-6">{{ { consult: $t('ACR.CONSULT'), user: $t('ACR.USER'), company_admin: $t('ACR.COMPANY_ADMIN') }[invite.access_right] }}</td>
                  <td>{{ $filters.moment(invite.date_of_creation, 'calendar') }}</td>
                  <td v-if="userIsAdmin" class="pl-6 pr-2">
                    <v-btn size="small" icon class="ma-0" @click="deletePendingInvite(invite)"><v-icon color="errorred">far fa-trash-alt</v-icon></v-btn>
                  </td>
                  <td v-if="userIsAdmin && invite.date_of_creation < today">
                    <v-btn :disabled="invitetojoin.sending" size="small" icon class="ma-0" @click="resendPendingInvite(invite)"><v-icon color="primary">far fa-paper-plane</v-icon></v-btn>
                  </td>
                </tr>
              </transition-group>
            </table>
          </v-fade-transition>
        </div>
      </v-card>
    </div>

    <div v-if="! isPremium" class="w-100 text-center">
      <div class="pointer" style="position: relative; display: inline-block;" @click="openPremium()">
        <img v-if="locale == 'fr'" :alt="$t('PREMIUM.INVITE_CONTRIBUTORS')" src="@/img/go-premium.jpg" style="width: 600px; max-width: 100%">
        <img v-else :alt="$t('PREMIUM.INVITE_CONTRIBUTORS')" src="@/img/go-premium-en.jpg" style="width: 600px; max-width: 100%">
        <div class="text-center" style="position: absolute; bottom: 7%; left: 0; right: 0;">
          <v-btn color="accent" rounded size="large" style="min-width: 220px">{{ $t('PREMIUM.SUBSCRIBE_TO_OFFER') }}</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .text-teamcolor {
    color: #278bff;
  }
  .text-businesscolor {
    color: #3cd458;
  }
</style>

<script>
  export default {
    data() {
      return {
        companyLoaded: null,
        company: {},
        company_users: [],
        pending_invites: [],
        invitetojoin: { email: "", access_right: "user", sending: null, message: "", error: "" },
        show_pending_invites: false,
        today: moment().format('YYYY-MM-DD'),
      };
    },
    computed: {
      consults_count() {
        return this.company_users.filter(item => item.access_right == 'consult').length;
      },
      users_count() {
        return this.company_users.length - this.consults_count;
      },
      maxUsersReached() {
        return this.users_count >= this.company.maxusers;
      },
      maxUsersExceeded() {
        return this.users_count > this.company.maxusers;
      },
      maxConsultReached() {
        return (this.consults_count >= this.company.maxconsults) && ((this.users_count + this.consults_count) >= (this.company.maxusers + this.company.maxconsults));
      },
      maxConsultExceeded() {
        return (this.consults_count > this.company.maxconsults) && ((this.users_count + this.consults_count) > (this.company.maxusers + this.company.maxconsults));
      },
      canInviteUsers() {
        return this.userIsAdmin || ! this.user.company;
      },
      locale() {
        return this.$store.state.lang.lang;
      },
      premiumExpireDate() {
        return this.user.company.premiumExpireDate;
      },
      user() { return this.$store.state.users.user; },
      userIsAdmin() { return this.$store.state.users.accessRight.isAdmin; },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      isTrial() { return this.$store.state.users.accessRight.isTrial; },
      hasOrganization() { return this.$store.state.users.accessRight.hasOrganization; },
    },
    watch: {
      maxUsersReached(newVal) {
        if (newVal) this.invitetojoin.access_right = "consult";
      },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        if (! this.user.company) {
          this.company = { maxusers: 1, maxconsults: 2 };
        } else {
          this.company = this.user.company;
          if (this.userIsAdmin) this.loadPendingInvites();
        }
        this.company_users = this.$store.getters['users/getCompanyUsers'];
        this.companyLoaded = true;
      });
    },
    methods: {
      inviteUser() {
        if (! this.invitetojoin.email) return;
        this.invitetojoin.message = "";
        this.invitetojoin.error = "";
        if (! window.isValidEmailAddress(this.invitetojoin.email)) {
          this.invitetojoin.error = this.$t('MANAGE.INVALID_EMAIL');
          return;
        }
        this.invitetojoin.sending = true;
        window.apiSrv.call('companyinvites/invite', 'store', { email: this.invitetojoin.email, access_right: this.invitetojoin.access_right }).then((response) => {
          this.invitetojoin.message = (response.data && response.data.message) ? response.data.message : this.$t('MANAGE.INVITATION_SENT');
          this.invitetojoin.email = "";
          this.invitetojoin.sending = false;
          this.loadPendingInvites();
        }).catch((message) => {
          this.invitetojoin.error = message || this.$t('MANAGE.INVITATION_NOT_SENT');
          this.invitetojoin.sending = false;
        });
      },
      loadPendingInvites() {
        window.apiSrv.call('companyinvites/pending', 'index').then((response) => {
          this.pending_invites = response && response.data && response.data.invites || [];
          this.pending_invites.sort((a, b) => (a.date_of_creation < b.date_of_creation ? 1 : -1));
        }).catch(() => {});
      },
      deletePendingInvite(invite) {
        window.apiSrv.call('companyinvites', 'destroy', invite.id).then(() => {
          const index = this.pending_invites.indexOf(invite);
          if (index > -1) this.pending_invites.splice(index, 1);
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Invitation was not deleted", body: message || "" });
        });
      },
      resendPendingInvite(invite) {
        this.invitetojoin.email = invite.email;
        this.invitetojoin.access_right = invite.access_right;
        this.inviteUser();
      },
      changeTrialPackage() {
        const packageText = this.isBusiness ? `<b class="text-teamcolor">TEAM</b>` : `<b class="text-businesscolor">BUSINESS</b>`;
        this.$store.dispatch('ui/msgbox/open', {
          body: `${this.$t('PREMIUM.PACKAGE.CHANGE_OFFER_WARNING_MESSAGE_1')} ${packageText} ${this.$t('PREMIUM.PACKAGE.CHANGE_OFFER_WARNING_MESSAGE_2')}`,
          buttons: { ok: 'GLOBAL.OK', cancel: 'GLOBAL.CANCEL' },
        }).then(() => {
          window.apiSrv.call(`companies/${this.user.company_id}`, 'update', { isBusiness: ! this.isBusiness }).then(() => {
            window.location.reload();
          }).catch((message) => {
            if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : Your offer has not been changed.", body: message });
          });
        }).catch(() => {});
      },
      openPremium() {
        this.$store.commit('ui/navigation/setOpenedModal', 'premium-window');
      },
    },
  };
</script>
