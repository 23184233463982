<template>
  <div class="py-6">
    <div class="d-flex align-center">
      <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important">
        <v-icon color="primary">far fa-arrows-alt-h</v-icon>
      </div>
      <div style="flex: 1 1 50%">
        {{ $t('TIMELINE.TIMELINE') }}
        <v-select v-model="timelineStep" :items="availableTimelineOptions"
                  @update:model-value="updateSubTimeline(); updateTimelineWidth(); update()">
        </v-select>
      </div>
      <div class="ml-2" style="flex: 1 1 50%">
        {{ $t('TIMELINE.SUB_TIMELINE') }}
        <v-select v-model="subtimelineStep" :items="availableSubtimelineOptions"
                  @update:model-value="updateTimelineWidth(); update()">
        </v-select>
      </div>
    </div>
    <div class="mt-2 d-flex">
      <div style="width: 32px; flex: 0 0 auto !important"></div>
      <div style="flex: 1 1 50%">
        <label for="hiddenBefore">{{ $t('ELEMENT-DETAIL.FROM') }}</label>
        <date-field id="hiddenBefore" v-model="hiddenBefore" no-null @update:model-value="updateTimelineWidth(); update()"></date-field>
      </div>
      <div class="ml-2" style="flex: 1 1 50%">
        <label for="hiddenAfter">{{ $t('ELEMENT-DETAIL.TO') }}</label>
        <date-field v-model="hiddenAfter" no-null @update:model-value="updateTimelineWidth(); update()"></date-field>
      </div>
    </div>
    <div class="mt-4 d-flex align-center">
      <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important">
        <v-icon color="primary">far fa-search</v-icon>
      </div>
      <div class="flex-grow-1">
        {{ $t('TIMELINE.TIMELINE_WIDTH') }}
        <v-select v-model.number="colwidth" :items="availableColWidthOptions" @update:model-value="update()"></v-select>
      </div>
    </div>
    <div class="mt-4 d-flex align-center">
      <div class="text-center mt-6" style="width: 32px; flex: 0 0 auto !important">
        <v-icon color="primary">far fa-globe-europe</v-icon>
      </div>
      <div class="flex-grow-1">
        {{ $t('CONFIG.TIME_FORMAT') }}
        <v-select v-model="timelocale" :items="timelocaleOptions" @update:model-value="update()"></v-select>
      </div>
    </div>

    <div class="mt-4 d-flex">
      <div style="width: 32px; flex: 0 0 auto !important"></div>
      <div>
        <v-checkbox v-model="workdays" :label="$t('CONFIG.USE_WORKDAYS')" density="compact" @update:model-value="update()"></v-checkbox>
        <v-checkbox v-model="show_presenttime" :label="$t('CONFIG.SHOW_PRESENTTIME')" density="compact" @update:model-value="update()"></v-checkbox>
        <v-checkbox v-model="show_timegrid" :label="$t('CONFIG.SHOW_TIMEGRID')" density="compact" @update:model-value="update()"></v-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
  import defaultConfig from '@/js/defaultConfig';

  export default {
    props: {
      timeline: { type: Object, required: true },
    },
    data() {
      const steps = this.timeline.getSteps();
      return {
        timelineStep: steps[0],
        subtimelineStep: steps.length >= 2 ? this.timeline.getLastStep() : 'none',
        hiddenBefore: this.timeline.getHiddenBefore(),
        hiddenAfter: this.timeline.getHiddenAfter(),
        timelocale: this.timeline.getTimelocale(),
        colwidth: this.timeline.getColWidth(),
        workdays: this.timeline.getWorkdays(),
        show_presenttime: this.timeline.getShowPresenttime(),
        show_timegrid: this.timeline.getShowTimegrid(),
        timelineOptions: defaultConfig.timelineOptions(),
        availableColWidthOptions: defaultConfig.availableColWidthOptions(),
        timelocaleOptions: [
          { value: 'en', title: "US English" },
          { value: 'en-gb', title: "UK English" },
          { value: 'fr', title: "Français" },
          { value: 'fr-ca', title: "Fr Canadien" },
        ],
      };
    },
    computed: {
      availableTimelineOptions() {
        return this.timelineOptions.filter(item => item.value != 'none');
      },
      availableSubtimelineOptions() {
        return this.timelineOptions.slice(this.timelineOptions.findIndex(item => item.value == this.timelineStep) + 1);
      },
    },
    watch: {
      hiddenBefore(newVal) {
        const newdateLimit = moment(newVal).startOf('day').add(1, this.timelineStep);
        if (this.hiddenAfter.isBefore(newdateLimit)) this.hiddenAfter = newdateLimit;
      },
      hiddenAfter(newVal) {
        const newdateLimit = moment(newVal).endOf('day').add(-1, this.timelineStep);
        if (newdateLimit.isBefore(this.hiddenBefore)) this.hiddenBefore = newdateLimit;
      },
    },
    methods: {
      updateSubTimeline() {
        this.subtimelineStep = this.availableSubtimelineOptions[0].value;
      },
      updateTimelineWidth() {
        const steps = [this.timelineStep];
        if (this.subtimelineStep !== 'none') steps.push(this.subtimelineStep);
        this.colwidth = this.timeline.bestColWidth(this.hiddenBefore, this.hiddenAfter, steps);
      },
      update() {
        const steps = [this.timelineStep];
        if (this.subtimelineStep !== 'none') steps.push(this.subtimelineStep);
        this.$store.dispatch('planning/timeline/updateTimeline', {
          hidden: { before: this.hiddenBefore.format(), after: this.hiddenAfter.format() },
          steps,
          timelocale: this.timelocale,
          colwidth: this.colwidth,
          workdays: this.workdays,
          show_presenttime: this.show_presenttime,
          show_timegrid: this.show_timegrid,
        });
      },
    },
  };
</script>
