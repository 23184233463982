<style>
  @media print {
    .zsiq_floatmain {
      display: none !important;
    }
  }

  .zsiq_floatmain.zsiq_theme4 {
    height: 150px;
    z-index: 200 !important;
  }
  .zsiq_theme4 :not(.zsiq_min) .zsiq_flt_rel {
    box-shadow: 0 1px 4px 0 #ccc;
    padding: 0;
    height: 150px;
  }
  .zsiq_theme4 .zsiq_flt_rel.zsiq_no_img div {
    margin-top: 20px !important;
  }
  .zsiq_theme4 .zsiq_no_img .zsiq_chatbtn {
    bottom: 15px !important;
  }

  .zsiq_theme4 .zsiq_min .zsiq_flt_rel {
    padding-bottom: 4px !important;
    box-shadow: none;
    background-color: #4da1c2;
    color: white;
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';

  export default {
    created() {
      setTimeout(() => {
        this.$store.state.users.userPromise.then(() => { // filter annymous
          if (this.$store.state.users.accessRight.isConsultOnly) return; // filter consults
          this.loadZohoChat();
        });
      }, 1000);
    },
    methods: {
      loadZohoChat() {
        if (clientConfig.hideSupportChat) return;

        if (! window.$zoho) {
          window.$zoho = {};
          window.$zoho.salesiq = window.$zoho.salesiq || { widgetcode: "c6e53a53be63381437b0cefaea160edfd4ece038b0cdab1eccad1892ca32bd6cf53ccfda64dae34d40dd8853453758b9", values: {}, ready() {} };
          const d = document;
          const s = d.createElement("script"); s.type = "text/javascript"; s.id = "zsiqscript"; s.defer = true;
          s.src = "https://salesiq.zoho.eu/widget";
          const t = d.getElementsByTagName("script")[0]; t.parentNode.insertBefore(s, t);
          const widgetDiv = d.createElement("div"); widgetDiv.id = 'zsiqwidget'; d.body.appendChild(widgetDiv);
          window.$zoho.salesiq.ready = () => {
            window.$zoho.salesiq.floatbutton.visible('hide'); // set visibility programmatically to control minimization
            window.$zoho.salesiq.chatbutton.texts([[this.$t('HELP.NEED_HELP')], [this.$t('HELP.NEED_HELP')]]);
            this.$store.state.users.userPromise.then((user) => {
              window.$zoho.salesiq.visitor.name(`${user.firstname} ${user.lastname}`);
              window.$zoho.salesiq.visitor.email(user.email);
              window.$zoho.salesiq.visitor.contactnumber(user.phone);
              window.$zoho.salesiq.visitor.info({ company: user.company && user.company.name || "" });
            });
            setTimeout(() => {
              if (this.$store.state.users.accessRight.isConsultOnly) return;
              const chatBtn = document.getElementById('zsiq_chatbtn');
              if (chatBtn) chatBtn.innerHTML = 'Chat';
              setTimeout(() => {
                if (! window.zoho_donotshow) {
                  window.$zoho.salesiq.floatbutton.visible('show');
                  if (window.$ZSIQWidgetUI) window.$ZSIQWidgetUI.minimizeSticker();
                }
              }, 24000); // total load time 1s + 5s + 24s
            }, 5000); // no idea of the real time needed
          };
        }
      },
    },
    render() { return null; },
  };
</script>
