<template>
  <v-container class="pa-6">
    <b v-if="isNewAction">{{ $t('MONITORING_ACTIONS.ADD_ACTION') }}</b>
    <b v-else-if="actionBreadcrumb" class="text-medium-emphasis">
      <v-icon class="mr-1" style="vertical-align: baseline;">far {{ isMeetingAction ? 'fa-users' : 'fa-folder-open' }}</v-icon>
      <template v-for="(breadcrumb, index) in actionBreadcrumb">
        <template v-if="index"> > </template>
        <a v-if="breadcrumb.url || breadcrumb.event" :href="breadcrumb.url" target="_blank" rel="noopener" class="pointer" style="color: inherit"
           @click="breadcrumb.event && $emit(breadcrumb.event, breadcrumb.eventParam)">
          {{ breadcrumb.label }}
        </a>
        <template v-else>{{ breadcrumb.label }}</template>
      </template>
    </b>
    <b v-else>&nbsp;</b>
    <v-row class="mt-0">
      <v-col md="6" cols="12">
        <label for="openActionTitle">
          <i :class="action.getChecked() ? 'fa-check-square' : 'fa-square'" class="far mx-1"></i>
          {{ $t('MONITORING_ACTIONS.ACTION') }}
        </label>
        <v-text-field id="openActionTitle" v-model="actionTitle" :placeholder="$t('MONITORING_ACTIONS.ACTION_TITLE')" class="mt-1">
        </v-text-field>
      </v-col>
      <v-col md="6" cols="12">
        <label for="openActionDueDate">
          <i class="far fa-calendar-plus mx-1"></i>
          {{ $t('MONITORING_ACTIONS.DUE_DATE') }}
        </label>
        <div class="mt-1 d-flex align-center">
          <date-field id="openActionDueDate" v-model="actionDueDate" :placeholder="actionEndTime" style="flex: 1 1 100%"></date-field>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn-icon :class="{ invisible: ! action.getDuedate() }" size="small" v-bind="props" @click="removeDueDate">
                <v-icon color="errorred">fa fa-times</v-icon>
              </v-btn-icon>
            </template>
            {{ $t('ELEMENT-DETAIL.REMOVE_DUE_DATE') }}
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" cols="12">
        <label for="openActionResponsible">
          <i class="far fa-user mx-1"></i>
          {{ $t('ELEMENT-DETAIL.RESPONSIBLE') }}
        </label>
        <select-user id="openActionResponsible" v-model="actionResponsible" :with-groups="! isMeetingAction" :with-text-users="! isMeetingAction" class="mt-1"
                     @update:model-value="setResponsible($event)">
        </select-user>
      </v-col>
      <v-col md="6" cols="12">
        <label for="openActionWorkload">
          <i class="far fa-weight-hanging mx-1"></i>
          {{ workloadInHours ? $t('HR.WORKLOAD_IN_HOURS') : $t('HR.WORKLOAD_IN_DAYS') }}
        </label>
        <div class="mt-1 d-flex align-center">
          <v-text-field v-model.number="actionWorkload" :label="$t('HR.WORKLOAD_ESTIMATED')" type="number" step="any" min="0">
          </v-text-field>
          <i class="fas fa-lg fa-caret-right mx-2"></i>
          <v-text-field id="openActionWorkload" v-model.number="actionWorkloadUsed" :label="$t('HR.WORKLOAD_USED')" type="number" step="any" min="0">
          </v-text-field>
        </div>
      </v-col>
    </v-row>
    <open-action-extra-fields v-if="hasExtraFields && isMeetingAction" :action="action"></open-action-extra-fields>
    <div class="mt-4 d-flex align-center">
      <v-menu v-if="! isNewAction">
        <template #activator="{ props }">
          <v-btn icon size="small" variant="outlined" color="errorred" v-bind="props">
            <v-icon>far fa-trash-alt</v-icon>
          </v-btn>
        </template>
        <div class="bg-white">
          <v-btn rounded color="errorred" class="ma-4" @click="deleteAction(); close('delete')">
            {{ $t('MONITORING_ACTIONS.DELETE_ACTION_PERMANENTLY') }}
          </v-btn>
        </div>
      </v-menu>
      <v-spacer></v-spacer>
      <v-btn rounded size="large" variant="outlined" class="ma-2" @click="close">{{ $t('GLOBAL.CANCEL') }}</v-btn>
      <v-btn color="primary" rounded size="large" class="ma-2" @click="saveAction(); close('save')">{{ $t('GLOBAL.VALIDATE') }}</v-btn>
    </div>
  </v-container>
</template>

<script>
  import workloadMixin from '@/components/Workload/workloadMixin';
  import OpenActionExtraFields from './OpenActionExtraFields';

  export default {
    components: {
      OpenActionExtraFields,
    },
    mixins: [workloadMixin],
    props: {
      action: { type: Object, required: true },
      users: { type: Array, default: () => [] },
    },
    emits: ['open-element', 'close'],
    data() {
      return {
        actionResponsible: null,
      };
    },
    computed: {
      workloadInHours() {
        return this.$store.getters['users/accessRight/config'].workloadInHours;
      },
      isMeetingAction() {
        return this.action.type == 'meeting';
      },
      isNewAction() {
        return this.action.isNew();
      },
      actionBreadcrumb() {
        if (this.action.type == 'meeting') {
          const meeting = this.action.getMeeting();
          if (! meeting) return null;
          const meetingId = this.action.getMeetingId();
          return [{ label: meeting.title, url: meetingId ? `./meeting/#/meeting/${meetingId}` : '' }];
        }
        const el = this.action.getElement();
        const planning = el && el.getPlanning();
        if (! planning) return null;
        return [
          { label: `${planning.getTitle() || this.$t('PLANNING.UNNAMED_PROJECT')}`, url: `${planning.getUrl()}?scrolltoel=${el.o_id || el.id}` },
          { label: `${el.getTitle() || this.$t('PLANNING.NEW_BUBBLE')}`, event: 'open-element', eventParam: el },
        ];
      },
      actionTitle: {
        get() { return this.action.getTitle(); },
        set(newVal) { this.action.setTitle(newVal); },
      },
      actionUserId: {
        get() { return this.action.getUserId(); },
        set(newVal) {
          this.action.setUser({ id: newVal, username: this.$store.getters['users/getUsername']({ id: newVal }, 'short') });
        },
      },
      actionGroupId: {
        get() { return this.action.getGroupId(); },
        set(newVal) {
          this.action.setGroup({ id: newVal, username: this.$store.getters['users/groups/getGroupById'](newVal)?.title });
        },
      },
      actionUsername: {
        get() { return this.action.getUsername(); },
        set(newVal) { this.action.setUsername(newVal); },
      },
      actionDueDate: {
        get() { return this.action.getDuedate(); },
        set(newVal) {
          if (! newVal) return;
          this.action.setDuedate(newVal.format());
        },
      },
      locale() {
        return this.$store.state.lang.lang;
      },
      actionEndTime() {
        if (! this.action.getEndTime()) return null; // off-project actions
        return this.action.getEndTime().format(this.locale == 'en' ? 'YYYY-MM-DD' : 'DD/MM/YYYY'); // cf DateField format
      },
      actionWorkload: {
        get() {
          return this.workloadToDisplay(this.action.getWorkload());
        },
        set(newVal) {
          this.action.setWorkload(this.workloadToStore(newVal));
        },
      },
      actionWorkloadUsed: {
        get() {
          return this.workloadToDisplay(this.action.getWorkloadUsed());
        },
        set(newVal) {
          this.action.setWorkloadUsed(this.workloadToStore(newVal));
        },
      },
      hasExtraFields() {
        return this.$store.getters['users/accessRight/config'].meetingactions_extrafields;
      },
    },
    created() {
      this.$store.state.users.userPromise.then(() => {
        if (this.actionResponsible) return;
        // init actionResponsible
        if (this.actionUserId) {
          this.actionResponsible = this.$store.getters['users/getUserById'](this.actionUserId);
        } else if (this.actionGroupId) {
          const group = this.$store.getters['users/groups/getGroupById'](this.actionGroupId);
          this.actionResponsible = { group_id: this.actionGroupId, title: group?.title || '', icon: group?.icon || '' };
        } else if (this.actionUsername) {
          this.actionResponsible = { username: this.actionUsername };
        }
      });
    },
    methods: {
      setResponsible(user) {
        if (user?.group_id) {
          this.actionGroupId = user.group_id;
        } else if (user?.id) {
          this.actionUserId = user.id;
        } else {
          this.actionUsername = user?.username || '';
        }
      },
      removeDueDate() {
        this.action.setDuedate(null);
      },
      saveAction() {
        this.action.save().catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : action was not saved", body: message });
        });
      },
      deleteAction() {
        this.action.delete().catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : action was not deleted", body: message });
        });
      },
      close(reason) {
        this.$emit('close', reason);
      },
    },
  };
</script>
