<template>
  <div class="pa-4" flat style="max-height: 40vh; flex: 1 1 auto; overflow-y: auto;">
    <div v-for="lane in planning.lanes" :key="lane.id">
      <v-checkbox :model-value="configLanes.includes(lane.id)" density="compact" :label="lane.label"
                  @click="setConfig(lane.id)">
      </v-checkbox>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
      configLanes: { type: Array, default: () => [] },
    },
    emits: ['update:config-lanes'],
    methods: {
      setConfig(laneId) {
        const configLanes = [...this.configLanes];
        const index = configLanes.indexOf(laneId);
        if (configLanes.includes(laneId)) {
          configLanes.splice(index, 1);
        } else {
          configLanes.splice(index, 0, laneId);
        }
        this.$emit('update:config-lanes', configLanes);
      },
    },
  };
</script>
