import store from '@/store';

class LocalStoredComment {
  constructor(data = {}) {
    this.id = data.id;
    this.user = store.getters['users/getUserById'](data.user_id) || { id: data.user_id };
    this.date = moment(data.created_at).format();
    this.text = data.text;
    this.file = data.file ? { name: data.file.display_filename, mime: data.file.mime, type: data.file.mime.split('/')[0], url: data.file.url } : null;
    this.edition_date = moment(data.updated_at).format();
    this.waiting = data.waiting || null;
  }

  getData() {
    return {
      id: this.id,
      user_id: this.user && this.user.id || this.user_id,
      text: this.text,
      file: this.file,
      created_at: this.date || this.created_at,
      updated_at: this.edition_date || this.updated_at,
    };
  }

  editMessage(newtext) {
    this.text = newtext;
    this.updated_at = moment().format();
    return Promise.resolve({ data: { comment: this } });
  }

  store() {
    _.extend(this, {
      id: Math.random().toString(36).substring(5),
      user_id: store.state.users.user.id,
      created_at: moment().format(),
      updated_at: moment().format(),
    });
    return Promise.resolve({ data: { comment: this } });
  }

  delete() {
    return Promise.resolve({ data: { comment: this } });
  }

  static loadExchanges(config, localcomments) {
    return Promise.resolve({ data: { exchanges: (localcomments || []).map(comment => new LocalStoredComment(comment)) } });
  }
}

export default LocalStoredComment;
