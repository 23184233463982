/*
    Get ACR for lanes and colors
    TO KEEP CONSISTENT WITH API RULES

    Requires : this.acr
*/

export default {
  methods: {
    getLaneAcr(lane) {
      const { acr } = this;
      let laneAcr = acr.lanes?.[lane.id]?.access_right;
      if (! laneAcr) laneAcr = this.laneColorAcr(lane.color || 0, lane.colorShade || null);
      if (! laneAcr) laneAcr = acr.lanes?.colors?.default?.access_right;
      if (! laneAcr) laneAcr = acr.lanes?.default?.access_right;
      if (! laneAcr) laneAcr = acr.default?.access_right;
      return laneAcr || 'consult';
    },
    colorAcr(type, colorIndex, shadeIndex) {
      if (! this.acr[type] || ! this.acr[type].colors) return undefined;
      const colorKey = `color${colorIndex}`;
      const selectedColorAcr = this.acr[type].colors[colorKey];
      if (shadeIndex == null) {
        return selectedColorAcr && selectedColorAcr.access_right;
      }
      const shadeKey = `shade${shadeIndex}`;
      return selectedColorAcr?.shades?.[shadeKey]?.access_right;
    },
    laneColorAcr(colorIndex, shadeIndex) {
      return this.colorAcr('lanes', colorIndex, shadeIndex);
    },
    elementColorAcr(colorIndex, shadeIndex) {
      return this.colorAcr('elements', colorIndex, shadeIndex);
    },
    elementIconAcr(iconCode) {
      const selectedIconAcr = this.acr.elements?.icons?.[iconCode];
      return selectedIconAcr && selectedIconAcr.access_right;
    },
  },
};
