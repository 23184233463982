<template>
  <div v-if="planning.visibleTimeline" class="lane-header-column">
    <template v-for="lane in visibleLanes" :key="lane.id">
      <v-hover v-slot="{ isHovering: hoverLaneLabel, props: hoverProps }">
        <div class="sublane-header" :class="[{ 'sublane-header-subproject border-el-color': lane.project_id }, lane.getColorClass()]"
             :style="{ height: `${lane.height}px` }"
             v-bind="hoverProps">
          <template v-if="lane.project_id">
            <template v-if="! lane.hidden && subLanesLoaded[lane.id]">
              <template v-for="sublane in selectedSublanesByLane[lane.id]" :key="sublane.id">
                <div v-if="! subsubLanesBySubLane[sublane.id]" :id="`laneheader${sublane.id}`"
                     :class="sublane.getColorClass()" class="lane-header bg-el-color"
                     :style="{ height: `${sublane.height}px` }">
                  <div :style="{ width: `${laneLevelWidth * sublane.level}px` }" class="lane-level-gap"></div>
                  <div class="lane-label">
                    <div :style="lane.labelStyle" class="pre" style="width: 100%; word-wrap: break-word;">{{ sublane.label }}</div>
                  </div>
                </div>

                <!-- replace by sub sub lanes headers -->
                <template v-else>
                  <div v-for="subsublane in subsubLanesBySubLane[sublane.id]" :id="`laneheader${subsublane.id}`"
                       :class="subsublane.getColorClass()" class="lane-header bg-el-color"
                       :style="{ height: `${subsublane.height}px` }">
                    <div :style="{ width: `${laneLevelWidth * subsublane.level}px` }" class="lane-level-gap"></div>
                    <div class="lane-label">
                      <div class="small font-italic">
                        <router-link :to="subprojectPath(sublane)" style="color: inherit">{{ sublane.label }}</router-link>
                      </div>
                      <div :style="lane.labelStyle" class="pre" style="width: 100%; word-wrap: break-word;">↪ {{ subsublane.label }}</div>
                      <router-link v-if="subsublane.project_id" :to="subprojectPath(subsublane)">
                        <div class="subprojects-header-chip">
                          {{ $t('PLANNING.SUBPROJECT') }}
                        </div>
                      </router-link>
                    </div>
                  </div>
                </template>
              </template>

              <v-menu v-model="laneDropdownIsOpened[lane.id]" :close-on-content-click="false" location="bottom" max-width="250">
                <template #activator="{ props }">
                  <v-fade-transition>
                    <v-btn v-show="hoverLaneLabel || laneDropdownIsOpened[lane.id]" size="small" variant="elevated"
                           color="rgba(0,0,0,0.6)" class="rounded-0" style="position: absolute; top:0; left: 0; width: 100%; color: white"
                           v-bind="props">
                      {{ $t('PLANNING.SELECT_LANES') }} <v-icon class="ml-1">fas fa-caret-down</v-icon>
                    </v-btn>
                  </v-fade-transition>
                </template>
                <v-list density="compact">
                  <v-list-item v-for="sublaneMeta in sublanesMetaByLane[lane.id]" :key="sublaneMeta.id" class="nobr">
                    <v-checkbox v-model="sublaneMeta.selected" :label="sublaneMeta.label" density="compact" @update:model-value="toggleSelectedSublane(lane, sublaneMeta)">
                    </v-checkbox>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <div v-else-if="lane.hidden" class="text-center">
              <router-link :to="subprojectPath(lane)">
                <div class="subprojects-header-chip">
                  {{ $t('PLANNING.SUBPROJECT') }}
                </div>
              </router-link>
            </div>
            <div v-else class="text-medium-emphasis" style="height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
              <i class="far fa-spinner fa-spin fa-2x fa-fw"></i>
            </div>
          </template>
        </div>
      </v-hover>
    </template>
  </div>
</template>

<style lang="scss">
  .sublane-header {
    position: relative;
    background: #eee;
    border-style: solid;
    border-width: 1px;
    border-right: none;
    border-color: white;
    border-left: 2px solid white;
    z-index: 200; /* for lane dropdown menu */

    &.sublane-header-subproject {
      border-top-width: 2px;
      border-bottom-width: 2px;
      border-left-color: white !important;

      .lane-header:first-child .lane-label {
        border-top-width: 2px;
      }
      .lane-header:last-child .lane-label {
        border-bottom-width: 2px;
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import constants from '@/js/constants.js';

  export default {
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        laneLevelWidth: constants.laneLevelWidth,
        laneDropdownIsOpened: {},
      };
    },
    computed: {
      visibleLanes() {
        return this.getVisibleLanes({ planning: this.planning });
      },
      sublanesByLane() {
        return this.visibleLanes.reduce((acc, lane) => {
          if (lane.project_id) {
            const subproject = this.getSubprojectByLaneId(lane.id);
            if (subproject?.lanes) acc[lane.id] = this.getVisibleLanes({ planning: subproject });
          }
          return acc;
        }, {});
      },
      subsubLanesBySubLane() {
        const subsubLanesBySubLane = {};
        Object.keys(this.sublanesByLane).forEach((laneId) => {
          this.sublanesByLane[laneId].forEach((sublane) => {
            if (sublane.project_id) {
              const subsubproject = this.getSubprojectByLaneId(sublane.id);
              if (subsubproject?.lanes) {
                const subsubLanes = this.getVisibleLanes({ planning: subsubproject }).filter((subsublane) => {
                  // keep subproject hidden sublanes configuration for subsublanes
                  const subsubprojectLaneOriginalId = parseInt(subsublane.id, 10);
                  return ! sublane.project_hidden_sublanes?.includes(subsubprojectLaneOriginalId);
                });
                if (subsubLanes.length) subsubLanesBySubLane[sublane.id] = subsubLanes;
              }
            }
          });
        });
        return subsubLanesBySubLane;
      },
      sublanesMetaByLane() {
        return Object.keys(this.sublanesByLane).reduce((acc, laneId) => {
          const lane = this.visibleLanes.find(item => item.id == laneId);
          acc[laneId] = this.sublanesByLane[laneId].map((sublane) => {
            const subprojectLaneOriginalId = parseInt(sublane.id, 10);
            return {
              id: sublane.id,
              label: sublane.label,
              selected: ! lane.project_hidden_sublanes?.includes(subprojectLaneOriginalId),
            };
          });
          return acc;
        }, {});
      },
      selectedSublanesByLane() {
        return Object.keys(this.sublanesByLane).reduce((acc, laneId) => {
          acc[laneId] = this.sublanesByLane[laneId].filter((sublane, sublaneIndex) => this.sublanesMetaByLane[laneId][sublaneIndex].selected);
          return acc;
        }, {});
      },
      subLanesLoaded() {
        return this.visibleLanes.reduce((acc, lane) => {
          acc[lane.id] = (this.sublanesByLane[lane.id] || []).find(item => item) && true || false;
          return acc;
        }, {});
      },
      ...mapGetters('planning/lanes', ['getVisibleLanes']),
      ...mapGetters('subprojects', ['getSubprojectByLaneId']),
    },
    methods: {
      toggleSelectedSublane(lane, sublaneMeta) {
        this.startChangingContent(lane);
        this.toggleHiddenSublane({ lane, sublane: this.sublanesByLane[lane.id].find(sublane => sublane.id == sublaneMeta.id) });
        this.changingContent(lane);
      },
      subprojectPath(lane) {
        const planningTitle = this.getSubprojectByLaneId(lane.id)?.getTitle() || lane.label;
        return `/planning/${lane.project_id}/${window.slugify(planningTitle)}`;
      },
      ...mapActions('subprojects', ['toggleHiddenSublane']),
      ...mapActions('planning/lanes', ['startChangingContent', 'changingContent']),
    },
  };
</script>
