<template>
  <div class="element-details-card-header">
    <element-details-config-button :el="el" config="show-progress"></element-details-config-button>
  </div>
  <div v-if="! clientConfigElementsHideProgressBar" class="mt-2 d-flex align-center">
    <div style="flex: 1 1 100%">
      <v-progress-linear :class="{ pointer: ! isConsult }" :model-value="progress" color="successgreen" height="16" rounded
                         @click="! isConsult && clickProgressBar($event)">
        <div v-show="progress > 0" :style="{ 'margin-right': `${100 - progress}%` }"
             class="text-white small flex-center-center" style="height: 100%; transition: all .3s">
          {{ progress }}%
        </div>
      </v-progress-linear>
    </div>
    <v-text-field v-if="! isConsult" v-model.number="progress" type="number" min="0" max="100" step="10" suffix="%"
                  class="ml-2" style="min-width: max-content">
    </v-text-field>
  </div>

  <!-- PROCESS STEP -->
  <template v-if="! isConsult && availableProcessSteps.length">
    <label for="processstep" class="mt-4 d-flex align-center mb-1"><v-icon start>svgicon-kanban</v-icon>{{ $t('KANBAN.STEP') }}</label>
    <div class="mb-4 d-flex align-center">
      <v-select id="processstep" v-model="processStep" :items="availableProcessSteps" item-title="label" item-value="id"
                clearable style="flex: 0 0 calc(100% - 70px)">
      </v-select>
      <!-- next step button -->
      <v-btn-icon v-if="processStepIndex < availableProcessSteps.length - 1" :title="$t('KANBAN.NEXT_STEP')" size="small" class="ml-2"
                  @click="processStep = availableProcessSteps[processStepIndex + 1].id">
        <v-icon>far fa-arrow-right</v-icon>
      </v-btn-icon>
    </div>
  </template>
</template>

<script>
  import clientConfig from '@/client_customs/config';
  import ElementDetailsConfigButton from './partials/ElementDetailsConfigButton';

  export default {
    components: {
      ElementDetailsConfigButton,
    },
    props: {
      el: { type: Object, required: true },
    },
    data() {
      return {
        clientConfigElementsHideProgressBar: clientConfig.elements?.hideProgressBar || false,
      };
    },
    computed: {
      isConsult() { return this.el.isType('macro'); },
      progress: {
        get() {
          return parseInt(this.el.getProgress(), 10) || 0;
        },
        set(newVal) {
          this.el.setProgress(newVal);
        },
      },
      processStep: {
        get() {
          return this.el.getProcessStep();
        },
        set(newVal) {
          this.el.setProcessStep(newVal);
        },
      },
      availableProcessSteps() {
        return this.el.getPlanning().process.steps.filter(step => step.id);
      },
      processStepIndex() {
        return this.availableProcessSteps.findIndex(step => step.id === this.processStep);
      },
    },
    methods: {
      clickProgressBar(event) {
        const fullWidth = event.currentTarget.clientWidth;
        if (! fullWidth) return;
        this.el.setProgress(Math.min(Math.round(event.offsetX / fullWidth * 10) * 10, 100));
      },
    },
  };
</script>
