import Planning from '@/models/Planning';
import ProjectSrv from '@/components/Projects/ProjectSrv';

let undoState = {};

function undoredoConfig({ state: config, dispatch }) {
  if (angular.equals(config, undoState)) return;
  const newState = angular.copy(config);
  const oldState = angular.copy(undoState);

  dispatch('undoredo/add', {
    action: () => {
      config.set(angular.copy(newState));
      config.setDesign();
    },
    rollback: () => {
      config.set(angular.copy(oldState));
      config.setDesign();
    },
  }, { root: true });
  dispatch('planning/save', null, { root: true });
}

function init({ state: config }) {
  config.setDesign();
}

async function updatePlanningRecurrences({ state: config, rootState, dispatch }) {
  const recurrences = Object.values(config.recurrences || {});
  if (! recurrences.length) return Promise.resolve(null);
  let configChanged = false;
  const promises = [];
  const elementsByRecurrenceId = _.groupBy(rootState.planning.elements, el => el.getRecurrenceId());
  recurrences.forEach((recurrence) => {
    const recurrenceElements = elementsByRecurrenceId[recurrence.id];
    if (! recurrenceElements?.length) {
      delete config.recurrences[recurrence.id];
      configChanged = true;
      return;
    }
    const referenceElement = _.max(recurrenceElements, el => el.getStartTime());
    promises.push(dispatch('generateRecurrenceElements', { recurrence, referenceElement }));
  });
  if (_.isEmpty(config.recurrences)) config.recurrences = null;
  return Promise.all(promises).then(results => configChanged || results.some(item => item.length)); // return true if need to save
}

function generateRecurrenceElements({ dispatch }, { recurrence, referenceElement }) {
  if (! referenceElement.hasDates()) return Promise.resolve([]);
  const templateContent = {
    elements: [{ ...referenceElement.getAll(), dependencies: null, recurrence_id: recurrence.id }],
  };
  let limitDate = moment().add(recurrence.occurrences * recurrence.interval, recurrence.frequency);
  if (recurrence.limitDate) limitDate = moment.min(limitDate, moment(recurrence.limitDate));

  const newElements = [];
  if (recurrence.frequency != 'months' || recurrence.monthlyOption == 'dayOfMonth') {
    let startdate;
    for (let i = 0; i < 20; i++) { // 20 is arbitrary safe limit
      startdate = referenceElement.getStartTime().add((i + 1) * recurrence.interval, recurrence.frequency); // this way handles 'every 31th'
      if (startdate.isAfter(limitDate)) break;
      const newel = ProjectSrv.projectFromTemplate({ template: templateContent, startdate });
      newElements.push(...newel.elements);
    }
  } else { // monthlyOption == 'dayOfWeek'
    const dayInfos = { week: Math.ceil(referenceElement.getStartTime().format('D') / 7), day: referenceElement.getStartTime().isoWeekday() };
    for (let i = 0; i < 20; i++) { // 20 is arbitrary safe limit
      let startdate;
      const nextMonth = referenceElement.getStartTime().add((i + 1) * recurrence.interval, 'months');
      let inc = dayInfos.day;
      if (dayInfos.week <= 4) { // original month contains at most 4 weeks
        const monthFirstDay = nextMonth.startOf('month');
        if (monthFirstDay.isoWeekday() > dayInfos.day) {
          inc += 7;
        }
        inc += (dayInfos.week - 1) * 7;
        monthFirstDay.isoWeekday(inc);
        startdate = monthFirstDay;
      } else { // original month contains 5 weeks
        const monthLastDay = nextMonth.endOf('month');
        if (monthLastDay.isoWeekday() < dayInfos.day) {
          inc -= 7;
        }
        monthLastDay.isoWeekday(inc);
        startdate = monthLastDay.startOf('day');
      }
      if (startdate.isAfter(limitDate)) break;
      const newel = ProjectSrv.projectFromTemplate({ template: templateContent, startdate });
      newElements.push(...newel.elements);
    }
  }

  const templatePlanning = new Planning({ elements: newElements });

  const argstab = templatePlanning.elements.map(templateEl => ({ type: templateEl.getType(), lane: { id: templateEl.getLaneId() }, el: templateEl }));
  return dispatch('planning/elements/addElements', { argstab, silent: true }, { root: true });
}

function startChangingConfig({ state: config }) {
  undoState = angular.copy(config);
}
function changingConfig(context) {
  const { state: config } = context;
  if (! angular.equals(config, undoState)) {
    undoredoConfig(context);
  }
}

export default {
  namespaced: true,
  state: {},
  actions: {
    init,
    updatePlanningRecurrences,
    generateRecurrenceElements,
    startChangingConfig,
    changingConfig,
  },
};
