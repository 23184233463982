<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition"
          max-width="290px" min-width="290px">
    <template #activator="{ props }">
      <v-text-field v-model="calendarTime" v-bind="{ ...$attrs, ...props }" :rules="[validateFormat]" autocomplete="off"></v-text-field>
    </template>
    <v-time-picker v-model="calendarTime" format="24hr" title="" scrollable color="primary" @update:minute="setUpdatedTime(updatedTime); closeMenu()"></v-time-picker>
  </v-menu>
</template>

<script>
  import { VTimePicker } from 'vuetify/labs/VTimePicker';
  /*  Input: v-model (modelValue) can be set to a moment or string
      Output: v-model (@update:modelValue) will be a moment or null

      null = empty date string // Invalid formats (ie 35/12/2018) are not emitted
  */

  export default {
    inheritAttrs: false,
    components: {
      VTimePicker,
    },
    props: {
      modelValue: { type: [String, Object], default: null },
      noNull: { type: Boolean, default: false },
    },
    emits: ['update:modelValue'],
    data() {
      return {
        updatedTime: null,
        menu: false,
        format: 'HH:mm',
        formatRegex: /\d\d:\d\d/gi,
      };
    },
    computed: {
      locale() {
        return this.$store.state.lang.lang;
      },
      calendarTime: {
        get() {
          return this.updatedTime ? this.updatedTime.format(this.format) : null;
        },
        set(newVal) {
          if (! newVal) {
            this.setUpdatedTime(null);
            return;
          }
          if (! newVal.match(this.formatRegex)) return;
          const [hours, minutes] = newVal.split(':');
          if (! (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59)) return;
          // const newdate = newVal ? moment(newVal) : moment();
          const newdate = moment();
          this.setUpdatedTime(newdate.hours(hours).minutes(minutes).seconds(0));
        },
      },
    },
    watch: {
      modelValue: {
        handler(newVal) {
          if (! newVal) { this.updatedTime = null; return; }
          this.updatedTime = moment(newVal).locale(this.locale);
        },
        immediate: true,
      },
    },
    methods: {
      setUpdatedTime(newVal) {
        this.updatedTime = newVal;
        if (this.noNull && newVal === null) return;
        this.$emit('update:modelValue', this.updatedTime);
      },
      closeMenu() {
        this.menu = false;
      },
      validateFormat(str) {
        if (! str) {
          return ! this.noNull || 'Time required';
        }
        if (! str.match(this.formatRegex)) return `Format : ${this.format}`;
        const [hours, minutes] = str.split(':');
        return (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) || 'Invalid time';
      },
    },
  };
</script>
