/* eslint-disable import/prefer-default-export */

export function loadRemoteScript(url, id, { removeOnError = false, appKey } = {}) {
  if (id && document.getElementById(id)) return Promise.resolve();
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = () => {
      resolve();
    };
    if (removeOnError) {
      script.onerror = () => {
        script.parentNode.removeChild(script); // if script not loaded, remove it from the DOM
        reject(new Error(`Error loading ${url}`));
      };
    }
    script.async = true;
    script.id = id;
    script.src = url;
    if (appKey) script.dataset.appKey = appKey;
    document.head.appendChild(script);
  });
}
