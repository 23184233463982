<template>
  <div style="max-width: 700px">
    <div class="flex-grow-1">
      <manage-profile-my-profile v-if="manageTabIndex == 'profile-myprofile'"></manage-profile-my-profile>

      <manage-profile-notifications v-if="manageTabIndex == 'profile-notifications'"></manage-profile-notifications>

      <manage-profile-integrations v-if="manageTabIndex == 'profile-integrations'"></manage-profile-integrations>
    </div>
  </div>
</template>

<script>
  import ManageProfileMyProfile from './ManageProfileMyProfile.vue';
  import ManageProfileNotifications from './ManageProfileNotifications.vue';
  import ManageProfileIntegrations from './ManageProfileIntegrations.vue';

  export default {
    components: {
      ManageProfileMyProfile,
      ManageProfileNotifications,
      ManageProfileIntegrations,
    },
    computed: {
      manageTabIndex: {
        get() {
          return this.$store.state.manage.manageTabIndex;
        },
        set(newVal) {
          this.$store.state.manage.manageTabIndex = newVal;
        },
      },
    },
  };
</script>
