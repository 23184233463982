<template>
  <div v-if="userIsProjectAdmin || category" :class="{ 'export-hidden': ! category }" class="mt-2 d-flex align-start">
    <v-icon :title="$t('PROJECTS.CATEGORY', 2)" size="small" class="mt-3 mr-2" style="margin-left: 2px">far fa-tags</v-icon>
    <!-- Edit Category -->
    <div v-if="categoryEditing" class="flex-grow-1 mt-2 ml-2">
      <project-category-edit v-model="category" :project-categories="projectCategories" prepend-icon></project-category-edit>
      <v-btn rounded variant="outlined" class="mx-2 mt-2" style="min-width: 0" @click="resetCategory">{{ $t('GLOBAL.CANCEL') }}</v-btn>
      <v-btn color="primary" rounded class="mx-2 mt-2" @click="saveCategory">{{ $t('GLOBAL.SAVE') }}</v-btn>
    </div>
    <!-- Category List -->
    <template v-else-if="category">
      <project-category :category="planning.meta.category" class="mt-2 ml-2"></project-category>
      <v-btn v-if="userIsProjectAdmin" icon size="small" class="ml-auto export-hidden" @click="categoryEditing = ! categoryEditing">
        <v-icon>far fa-pencil-alt</v-icon>
      </v-btn>
    </template>
    <!-- No category -->
    <template v-else>
      <v-btn size="small" rounded variant="outlined" class="my-1" @click="categoryEditing = true">
        <v-icon class="mr-2">far fa-plus</v-icon>{{ $t('PROJECTS.CATEGORY') }}
      </v-btn>
    </template>
  </div>
</template>

<script>
  import ProjectCategory from '@/components/Projects/ProjectCategory';
  import ProjectCategoryEdit from '@/components/Projects/ProjectCategoryEdit';

  export default {
    components: {
      ProjectCategory,
      ProjectCategoryEdit,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        category: this.planning.meta?.category,
        categoryEditing: false,
        projectCategories: [],
      };
    },
    computed: {
      userIsProjectAdmin() {
        return this.planning.meta.access_right == 'admin';
      },
    },
    watch: {
      categoryEditing(newVal) {
        if (! newVal) return;
        window.apiSrv.call('plannings/categories', 'index').then((response) => {
          this.projectCategories = response.data && response.data.categories || [];
        });
      },
    },
    methods: {
      saveCategory() {
        setTimeout(() => {
          window.apiSrv.call(`plannings`, 'update', { id: this.planning.id, category: this.category }).then(() => {
            this.planning.meta.category = this.category;
          }).catch((message) => {
            this.$store.dispatch('ui/msgbox/open', { title: "Error : Category was not modified", body: message || "" });
            this.category = this.planning.meta?.category;
          });
          this.categoryEditing = false;
        });
      },
      resetCategory() {
        this.category = this.planning.meta?.category;
        this.categoryEditing = false;
      },
    },
  };
</script>
