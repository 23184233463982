<template>
  <div class="pb-4" style="max-width: 800px">
    <div v-if="loading.inprogress" class="text-center ma-6" style="text-align: center;"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></div>
    <div v-if="! loading.inprogress">
      <div class="d-flex align-center">
        <v-select v-model="selected_view" :items="views" :item-title="view => view.id ? (view.title || $t('CUSTOM_VIEWS.UNTITLED')) : $t('CUSTOM_VIEWS.CREATE_VIEW')" return-object
                  density="compact" style="max-width: 70%">
        </v-select>
        <v-btn-icon v-show="selected_view.id" :title="$t('CUSTOM_VIEWS.TEST_VIEW')" :href="viewUrl" target="_blank" rel="noopener" size="small" class="ml-1">
          <v-icon color="primary">far fa-external-link-alt</v-icon>
        </v-btn-icon>
        <v-spacer></v-spacer>
        <v-btn-icon v-show="selected_view.id" :title="$t('CUSTOM_VIEWS.DELETE_VIEW')" size="small" @click="removeView()">
          <v-icon color="errorred">far fa-trash-alt</v-icon>
        </v-btn-icon>
      </div>
      <v-divider class="my-4"></v-divider>
      <div>
        <label for="inputViewTitle">{{ $t('CUSTOM_VIEWS.VIEW_NAME') }}</label>
        <span class="ml-2">
          <i v-if="saving.inprogress" class="far fa-fw fa-spinner fa-spin"></i>
          <i v-if="saving.success" :title="$t('CUSTOM_VIEWS.SAVED')" class="far fa-check text-successgreen"></i>
          <span v-if="saving.error"><i class="far fa-exclamation-triangle text-errorred"></i> &nbsp;{{ saving.error }}</span>
        </span>
        <v-text-field id="inputViewTitle" v-model="selected_view.title" class="mt-1" @change="selected_view.id && updateViewDebounced()">
        </v-text-field>
        <div v-show="selected_view.id" class="mt-4">
          <label for="inputViewReadOnlyLink">{{ $t('CUSTOM_VIEWS.RO_LINK') }}</label>
          <v-text-field id="inputViewReadOnlyLink" :model-value="viewReadonlyRoot + selected_view.read_only_token"
                        readonly class="mt-1" @focus="$event.target.select()">
            <template #append-inner>
              <v-btn-icon size="small" :title="$t('SHARE.COPY_CLIPBOARD')" @click="copyToClipboard()">
                <v-icon v-show="! copying.success && ! copying.error" size="large" color="primary">far fa-clipboard</v-icon>
                <v-icon v-show="copying.success" size="large" color="primary">far fa-check</v-icon>
                <v-icon v-show="copying.error" :title="$t('SHARE.COPY_FAILED')" size="large" color="primary">far fa-exclamation-triangle</v-icon>
              </v-btn-icon>
            </template>
          </v-text-field>
        </div>
        <div v-show="! selected_view.id" class="mt-2 text-center">
          <v-btn color="primary" rounded size="large" @click="createView()">{{ $t('CUSTOM_VIEWS.CREATE_THE_VIEW') }}</v-btn>
        </div>
      </div>
      <div v-show="selected_view.id">
        <!-- v-show and not v-if to load defaults acr from views-xxx-acr components -->
        <v-divider class="my-4"></v-divider>
        <div>
          <div>
            <b>{{ $t('CUSTOM_VIEWS.CONFIGURATION') }}</b>
            <span class="ml-2">
              <i v-if="saving.inprogress" class="far fa-fw fa-spinner fa-spin"></i>
              <i v-if="saving.success" :title="$t('CUSTOM_VIEWS.SAVED')" class="far fa-check text-successgreen"></i>
              <span v-if="saving.error"><i class="far fa-exclamation-triangle text-errorred"></i> &nbsp;{{ saving.error }}</span>
            </span>
          </div>
          <div v-if="configTabs.length > 1" class="mt-1">
            <a v-for="(tab, tabIndex) in configTabs" :key="tabIndex" :class="{ underlined: configTabsIndex == tabIndex }" href="" class="mr-2"
               @click.prevent="configTabsIndex = tabIndex">{{ configTabsTitles[tab] }}</a>
          </div>
          <views-lanes-acr v-if="configTabs.includes('lanes')" v-show="configTabs[configTabsIndex] == 'lanes'" :acr="selected_view.acr"
                           @update="updateView">
          </views-lanes-acr>
          <views-colors-acr v-if="configTabs.includes('colors')" v-show="configTabs[configTabsIndex] == 'colors'" :acr="selected_view.acr"
                            @update="updateViewDebounced">
          </views-colors-acr>
          <views-colors-acr v-if="configTabs.includes('consultcolors')" v-show="configTabs[configTabsIndex] == 'consultcolors'"
                            :acr="selected_view.acr" toggled-access-right="consult"
                            @update="updateViewDebounced">
          </views-colors-acr>
        </div>

        <v-card class="mt-8 pa-6">
          <div>
            <b>{{ $t('CUSTOM_VIEWS.AUTHORIZED_USERS') }}</b>
            <span class="ml-2">
              <i v-if="savingUsers.inprogress" class="fa fa-fw fa-spinner fa-spin"></i>
              <i v-if="savingUsers.success" :title="$t('CUSTOM_VIEWS.SAVED')" class="far fa-check text-successgreen"></i>
              <span v-if="savingUsers.error"><i class="far fa-exclamation-triangle text-errorred"></i> &nbsp;{{ savingUsers.error }}</span>
            </span>
          </div>
          <v-row class="mt-2">
            <v-col cols="6">
              <div style="border: 1px solid #ccc; border-radius: 4px; margin: -1px;">
                <div v-show="! selectedViewUsers.length" style="padding: 30px; text-align: center">{{ $t('CUSTOM_VIEWS.NO_USER_SELECTED') }}</div>
                <div v-show="selectedViewUsers.length" style="height: 254px; overflow-y: auto">
                  <div v-for="user in selectedViewUsers" :key="user.id" :title="$filters.username(user)"
                       :style="{ opacity: user_disabled[user.id] ? '.5' : '' }" class="company-user d-flex align-center">
                    <div><user-avatar :user="user" :size="32" show-username class="mr-2"></user-avatar></div>
                    <v-btn-icon title="Retirer" size="small" color="errorred" class="ml-auto" @click="! user_disabled[user.id] && removeViewUser(user)">
                      <v-icon size="large">far fa-times</v-icon>
                    </v-btn-icon>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <v-text-field v-model="filterCompanyUsers" :placeholder="`${$t('GLOBAL.SEARCH')}...`" density="compact" class="mb-1"></v-text-field>
                <div style="height: 220px; overflow-y: auto">
                  <div v-for="companyUser in notInViewUsers" :key="companyUser.id" :title="$filters.username(companyUser)" class="company-user d-flex align-center pointer"
                       @click="addViewUser(companyUser)">
                    <div><user-avatar :user="companyUser" :size="32" show-username class="mr-2"></user-avatar></div>
                    <v-icon color="primary" class="ml-auto">far fa-plus-circle</v-icon>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .company-user {
    padding: 8px 16px;
    background: #f4f4f4;
    border: 1px solid #ccc;
    position: relative;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      background: rgba(50,50,50,.15);
      box-shadow: 0 0 4px 1px #323232;
      z-index: 1;
    }

    img {
      width: 32px;
      border-radius: 50%;
      margin-right: 8px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }
  }
</style>

<script>
  import ViewsLanesAcr from './ViewsLanesAcr.vue';
  import ViewsColorsAcr from './ViewsColorsAcr.vue';

  export default {
    components: {
      ViewsLanesAcr,
      ViewsColorsAcr,
    },
    props: {
      planning: { type: Object, required: true },
    },
    data() {
      return {
        views: null,
        selected_view: null,
        filterCompanyUsers: '',
        loading: { inprogress: false },
        saving: {},
        savingUsers: {},
        copying: { success: false, error: false },
        user_disabled: {},
        configTabsIndex: 0,
        configTabsTitles: { lanes: this.$t('CUSTOM_VIEWS.LANES'), colors: this.$t('CUSTOM_VIEWS.COLORS'), consultcolors: this.$t('CUSTOM_VIEWS.COLORS_CONSULT') },
      };
    },
    computed: {
      configTabs() {
        const config = this.$store.getters['users/accessRight/config'].views;
        if (config == 'lrUniversite') return ['colors'];
        if (config == 'lanes+colors') return ['lanes', 'colors'];
        if (config == 'lanes+colors+consultcolors') return ['lanes', 'colors', 'consultcolors'];
        return ['lanes', 'colors'];
      },
      viewUrl() {
        if (! this.selected_view) return '';
        return `${window.location.protocol}//${window.location.host}${window.location.pathname}#/planningview/${this.selected_view.id}/${window.slugify(this.selected_view.title || this.$t('CUSTOM_VIEWS.NEW_VIEW'))}`;
      },
      viewReadonlyRoot() {
        if (! this.selected_view) return '';
        return `${this.viewUrl}?viewrotoken=`;
      },
      selectedViewUsers() {
        if (! this.selected_view) return [];
        return this.selected_view.users.slice().sort((a, b) => ((a.firstname + a.lastname) < (b.firstname + b.lastname) ? -1 : 1));
      },
      notInViewUsers() {
        let notInViewUsers = this.$store.getters['users/getCompanyUsers'];
        if (this.selected_view) notInViewUsers = notInViewUsers.filter(user => ! this.selected_view.users.find(item => item.id == user.id));
        if (this.filterCompanyUsers) {
          notInViewUsers = notInViewUsers.filter((user) => {
            const filterText = this.filterCompanyUsers.toLowerCase();
            return user.firstname.toLowerCase().indexOf(filterText) > -1 || user.lastname.toLowerCase().indexOf(filterText) > -1;
          });
        }
        return notInViewUsers.slice().sort((a, b) => ((a.firstname + a.lastname) < (b.firstname + b.lastname) ? -1 : 1));
      },
    },
    watch: {
      selected_view() {
        if (! this.selected_view) return;
        if (! this.selected_view.users) this.selected_view.users = [];
        if (! this.selected_view.acr || Array.isArray(this.selected_view.acr)) this.selected_view.acr = {};
      },
    },
    created() {
      this.initLoad();
    },
    methods: {
      initLoad() {
        this.loading.inprogress = true;
        return window.apiSrv.call(`plannings/${this.planning.id}/views`, 'index').then((response) => {
          this.views = (response && response.data && response.data.views || []).filter(a => a.acr).sort((a, b) => (a.title < b.title ? -1 : 1));
          this.views.push({ title: `${this.planning.meta && this.planning.meta.title ? `${this.planning.meta.title} - ` : ''}${this.$t('CUSTOM_VIEWS.NEW_VIEW')}`, acr: {}, users: [] });
          this.selected_view = this.views.reduce((a, b) => (b.updated_at && moment(b.updated_at).isAfter(a.updated_at) ? b : a), this.views[0]);
          this.loading.inprogress = false;
        });
      },
      updateView({ onSuccess, onError } = {}) {
        const view = this.selected_view;
        if (! view.id) return Promise.reject(new Error("unsaved view"));
        this.saving = { inprogress: true, success: false, error: "" };
        return window.apiSrv.call('views', 'update', view).then(() => {
          this.saving.inprogress = false;
          this.saving.success = true;
          if (onSuccess) onSuccess();
        }).catch((error) => {
          this.saving.inprogress = false;
          this.saving.error = error;
          if (onError) onError(error);
          return Promise.reject(error);
        });
      },
      updateViewDebounced: _.debounce(function (params) {
        this.updateView(params);
      }),
      deleteView(view) {
        if (! view.id) return Promise.reject(new Error("unsaved view"));
        return window.apiSrv.call('views', 'destroy', view);
      },
      updateViewUser(view, user) {
        if (! view.id) return Promise.reject(new Error("unsaved view"));
        return window.apiSrv.call(`views/${view.id}/user/${user.id}`, 'store', user);
      },
      deleteViewUser(view, user) {
        if (! view.id) return Promise.reject(new Error("unsaved view"));
        return window.apiSrv.call(`views/${view.id}/user`, 'destroy', user);
      },
      copyToClipboard() {
        const input = $("#inputViewReadOnlyLink");
        this.copying = { success: false, error: false };
        try {
          input.select();
          document.execCommand('selectAll');
          document.execCommand('copy');
          this.copying.success = true;
          setTimeout(() => { this.copying.success = false; }, 3000);
        } catch (e) {
          this.copying.error = true;
        }
      },
      createView() {
        this.loading.inprogress = true;
        this.saving = {};
        this.savingUsers = {};
        this.selected_view.planning_id = this.planning.id;
        window.apiSrv.call('views', 'store', this.selected_view).then(() => {
          this.initLoad();
        }).catch((message) => {
          this.loading.inprogress = false;
          if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : View was not saved", body: message, type: 'vdialog' });
        });
      },
      removeView() {
        this.$store.dispatch('ui/msgbox/open', {
          title: this.$t('CUSTOM_VIEWS.CONFIRM_DELETE_VIEW'),
          body: this.$t('CUSTOM_VIEWS.CANNOT_UNDO'),
          buttons: { ok: "GLOBAL.OK", cancel: "GLOBAL.CANCEL" },
          type: 'vdialog',
        }).then(() => {
          this.loading.inprogress = true;
          this.deleteView(this.selected_view).then(() => {
            this.initLoad();
          }).catch((message) => {
            this.loading.inprogress = false;
            if (message) this.$store.dispatch('ui/msgbox/open', { title: "Error : View was not deleted", body: message, type: 'vdialog' });
          });
        }).catch(() => {});
      },
      addViewUser(user) {
        this.selected_view.users.push(user);
        if (! this.selected_view.id) return;
        this.user_disabled[user.id] = true;
        this.savingUsers = { inprogress: true, success: false, error: "" };
        this.updateViewUser(this.selected_view, user).then(() => {
          this.savingUsers.success = true;
        }).catch((message) => {
          const index = this.selected_view.users.indexOf(user);
          if (index > -1) this.selected_view.users.splice(index, 1);
          this.savingUsers.error = message;
        }).finally(() => {
          this.user_disabled[user.id] = false;
          this.savingUsers.inprogress = false;
        });
      },
      removeViewUser(user) {
        if (! this.selected_view.id) {
          const index = this.selected_view.users.indexOf(user);
          if (index > -1) this.selected_view.users.splice(index, 1);
          return;
        }
        this.user_disabled[user.id] = true;
        this.savingUsers = { inprogress: true, success: false, error: "" };
        this.deleteViewUser(this.selected_view, user).then(() => {
          const index = this.selected_view.users.indexOf(user);
          if (index > -1) this.selected_view.users.splice(index, 1);
          this.savingUsers.success = true;
        }).catch((message) => {
          this.savingUsers.error = message;
        }).finally(() => {
          this.user_disabled[user.id] = false;
          this.savingUsers.inprogress = false;
        });
      },
    },
  };
</script>
