<template>
  <div class="d-flex align-center ml-2 mb-2">
    <span class="text-h6">{{ $t('HOME.MY_ACTIONS') }}</span>
    <v-tooltip location="left" max-width="200">
      <template #activator="{ props }">
        <v-icon color="info" size="small" class="ml-2" v-bind="props">far fa-question-circle</v-icon>
      </template>
      <span>{{ $t('HOME.ACTIONS_EXPLANATIONS') }}</span>
    </v-tooltip>
  </div>
  <div>
    <v-card v-if="! actions.length" class="pa-6 text-medium-emphasis">
      {{ $t('HOME.NO_ACTION_ACTIVITY') }}
    </v-card>
    <div v-else>
      <v-card v-for="actionList in actionLists" v-show="filteredActions[actionList.status].length" :key="actionList.status" class="mb-6">
        <div :class="`text-${actionList.color}`" class="px-3 py-2 bg-lightgrey d-flex align-center">
          <v-icon class="mr-1" style="color: inherit; flex: 0 0 28px">
            {{ actionList.icon }}
          </v-icon>
          {{ $t(actionList.label) }}
        </div>
        <div class="light-scrollbar pt-2 mb-1" style="max-height: 200px; overflow: auto">
          <table>
            <tr v-for="action in filteredActions[actionList.status]" :title="getActionMetaTitle(action)"
                :class="`text-${getActionIsDone(action) ? 'medium-emphasis' : actionList.color}`" class="hover-secondary">
              <td class="w-0">
                <v-btn :disabled="! canEditAction(action)" icon variant="text" size="x-small" class="mx-1" style="color: inherit" @click="actionClick(action)">
                  <v-icon>far {{ getActionIsDone(action) ? 'fa-check-square' : 'fa-square' }}</v-icon>
                </v-btn>
              </td>
              <td :class="{ 'text-decoration-line-through': getActionIsDone(action) }" class="nobr w-0">
                <b>{{ $filters.moment(getActionDueDate(action), 'mediumDateNoYear') }}</b>
              </td>
              <td class="px-2">
                {{ getActionTitle(action) }}
              </td>
              <td class="pr-4 rounded-e-pill">
                <span v-for="section in [getActionSection(action)].filter(item => item)" class="ml-2 text-medium-emphasis">
                  <!-- define scoped variable section = getActionSection(action) + v-if=section -->
                  <v-icon size="small" class="mr-1 mb-.5">{{ action.type == 'planning' ? 'far fa-folder-open' : 'far fa-users' }}</v-icon>
                  <router-link :to="section.url" style="color: inherit">
                    <i class="small">{{ section.title }}</i>
                  </router-link>
                </span>
              </td>
            </tr>
          </table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
  import Planning from '@/models/Planning';
  import PlanningElement from '@/models/PlanningElement';

  export default {
    props: {
      actions: { type: Array, required: true },
      dashboardElements: { type: Array, default: () => ([]) },
    },
    emits: ['save-el'],
    data() {
      return {
        actionLists: [
          { status: 'late', label: "HOME.LATE", color: 'errorred', icon: 'far fa-exclamation-triangle' },
          { status: 'urgent', label: "HOME.URGENT", color: 'warningorange', icon: 'far fa-clock' },
          { status: 'current', label: "HOME.CURRENT", color: 'primary', icon: 'far fa-hourglass-half' },
        ],
        elements: {},
      };
    },
    computed: {
      filteredActions() {
        return this.actionLists.reduce((result, actionList) => {
          const actionStatus = actionList.status;
          result[actionStatus] = this.actions.filter(action => action.meta.status == actionStatus).sort((a, b) => (a.due_date < b.due_date ? -1 : 1));
          return result;
        }, {});
      },
    },
    created() {
      /*
        this.elements acts as the elements database shared by reference -> auto synchronize actions with elements in kanban
        this is required within actions in case 2 actions come from the same task : without, each action.element would overwrite the other on saving
      */
      const elements = this.dashboardElements.reduce((acc, item) => {
        acc[`p${item.project_id}e${parseInt(item.id, 10)}`] = item;
        return acc;
      }, {});
      const plannings = {};
      this.actions.forEach((action) => {
        if (action.type == 'meeting') return;
        const { project_id: projectId, project_title: projectTitle } = action.meta;
        if (elements[`p${projectId}e${action.element.id}`]) return;
        if (! plannings[projectId]) plannings[projectId] = new Planning({ id: projectId, meta: { title: projectTitle } });
        const planning = plannings[projectId];
        elements[`p${projectId}e${action.element.id}`] = new PlanningElement(planning, action.element);
      });
      this.elements = elements;
    },
    methods: {
      canEditAction(action) {
        if (action.type == 'meeting') return true;
        return action.meta.project_access_right != 'consult';
      },
      getActionElement(action) {
        if (action.type == 'meeting') return null;
        return this.elements[`p${action.meta.project_id}e${action.element.id}`];
      },
      getActionItem(action) {
        if (action.type == 'meeting') return action.item;
        const checklist = this.getActionElement(action).getChecklist() || [];
        return checklist[action.item_index];
      },
      getActionTitle(action) {
        const item = this.getActionItem(action);
        return item.title;
      },
      getActionDueDate(action) {
        return action.due_date;
      },
      getActionIsDone(action) {
        const item = this.getActionItem(action);
        return action.type == 'planning' ? item.checked : !! item.completed_at;
      },
      getActionMetaTitle(action) {
        if (action.type != 'planning') return '';
        return `↪ ${action.meta.lane_label || ''}\n${action.element.title || ''}`;
      },
      getActionSection(action) {
        if (action.type == 'planning') {
          const { project_id: projectId, project_title: projectTitle, project_access_right: projectAccessRight } = action.meta;
          const isConsult = projectAccessRight == 'consult';
          return {
            url: `/${isConsult ? 'viewer/' : 'planning/'}${projectId}/${window.slugify(projectTitle || "New project")}?open=actions`,
            title: action.meta.project_title,
          };
        }
        return null;
      },
      actionClick(action) {
        const item = this.getActionItem(action);
        if (action.type == 'planning') {
          const el = this.getActionElement(action);
          const oldStateParam = { checklist: angular.copy(el.getChecklist()), progress: el.getProgress() };
          item.checked = ! item.checked;
          el.updateChecklistProgress();
          this.$emit('save-el', { el, props: ['checklist', 'progress'], oldStateParam });
        } else {
          item.completed_at = item.completed_at ? null : new Date();
          window.apiSrv.call('v2/meetings/actions', 'update', { id: action.id, completed_at: item.completed_at });
        }
      },
    },
  };
</script>
