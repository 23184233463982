<template>
  <!-- USERS WITH VIRTUAL PARTICIPANTS (for PLANNING USERS & KANBAN) -->
  <autocomplete-filter v-model="selectedAllUsers" :items="allUsersList" item-value="id" item-title="username"
                       :label="$t('MULTIPROJECTS.PARTICIPANTS')" :placeholder="selectedAllUsers.length ? '' : $t('MULTIPROJECTS.NO_USER_SELECTED')"
                       :prepend-inner-icon="smallScreen ? null : 'far fa-user'" multiple :menu-props="{ location: 'bottom' }" return-object
                       @update:model-value="update">
    <template #prepend-item>
      <v-list-item @click.stop>
        <v-switch v-model="filterOptions.unassigned" :label="$t('MULTIPROJECTS.INCLUDE_UNASSIGNED')" @update:model-value="update">
        </v-switch>
      </v-list-item>
      <filter-toggle :selected-ids="selectedCompanyUsers" :sublist-ids="companyUserIds"
                     @update="selectedCompanyUsers = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_COMPANY_USERS') }} ({{ companyUserIds.length }})
      </filter-toggle>
      <filter-toggle v-if="deletedUserIds.length" :selected-ids="selectedDeletedUsers" :sublist-ids="deletedUserIds"
                     @update="selectedDeletedUsers = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_DELETED_USERS') }} ({{ deletedUserIds.length }})
      </filter-toggle>
      <filter-toggle :selected-ids="selectedVirtualParticipants" :sublist-ids="virtualParticipantIds"
                     @update="selectedVirtualParticipants = $event; update()">
        {{ $t('MULTIPROJECTS.ALL_VIRTUAL_PARTICIPANTS') }} ({{ virtualParticipantIds.length }})
      </filter-toggle>
      <v-divider></v-divider>
      <v-list-group v-if="companyGroups.length" fluid>
        <template #activator="{ props }">
          <v-list-item v-bind="props">
            <v-list-item-title class="ml-4">{{ $t('GLOBAL.GROUPS') }}</v-list-item-title>
          </v-list-item>
        </template>
        <filter-toggle v-for="group in companyGroups" :key="group.id"
                       :selected-ids="selectedCompanyUsers" :sublist-ids="userIdsByGroupId[group.id]" indent
                       @update="selectedCompanyUsers = $event; update()">
          {{ group.title }} ({{ group.users.length }})
        </filter-toggle>
        <v-divider></v-divider>
      </v-list-group>
      <v-list-group v-if="organizationCompanies.length" fluid>
        <template #activator="{ props }">
          <v-list-item v-bind="props">
            <v-list-item-title class="ml-4">{{ $t('PROJECTS.ORGANIZATION') }}</v-list-item-title>
          </v-list-item>
        </template>
        <filter-toggle v-for="company in organizationCompanies" :key="company.id"
                       :selected-ids="selectedCompanyUsers" :sublist-ids="userIdsByOrganizationCompany[company.id]" indent
                       @update="selectedCompanyUsers = $event; update()">
          {{ company.name }} ({{ userIdsByOrganizationCompany[company.id].length }})
        </filter-toggle>
        <v-divider></v-divider>
      </v-list-group>
    </template>
    <template #item="{ props, item: { raw: item } }">
      <v-divider v-if="item.divider"></v-divider>
      <v-list-subheader v-else-if="item.header" :title="item.header"></v-list-subheader>
      <v-list-item v-else v-bind="props">
        <template #prepend>
          <user-avatar :user="item" :size="32"></user-avatar>
        </template>
      </v-list-item>
    </template>
    <template #selection="{ item: { raw: item }, index }">
      <span v-if="selectedAllUsers.length == 1" class="text-ellipsis">{{ item.username }}</span>
      <span v-else-if="selectedAllUsers.length == allUsersCount && index === 0" class="text-ellipsis">{{ $t('MULTIPROJECTS.ALL_PARTICIPANTS') }} ({{ allUsersCount }})</span>
      <span v-else-if="index === 0" class="text-ellipsis">{{ selectedAllUsers.length }}/{{ allUsersCount }}</span>
    </template>
  </autocomplete-filter>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      useActionUsers: { type: Boolean, default: false },
    },
    emits: ['update'],
    computed: {
      selectedCompanyUsers: {
        get() {
          return this.$store.state.multiprojects.selectedCompanyUsers;
        },
        set(newVal) {
          this.$store.state.multiprojects.selectedCompanyUsers.splice(0, this.$store.state.multiprojects.selectedCompanyUsers.length, ...newVal);
        },
      },
      selectedVirtualParticipants: {
        get() {
          return this.useActionUsers ? this.$store.state.multiprojects.selectedActionVirtualParticipants : this.$store.state.multiprojects.selectedVirtualParticipants;
        },
        set(newVal) {
          if (this.useActionUsers) {
            this.$store.state.multiprojects.selectedActionVirtualParticipants.splice(0, this.$store.state.multiprojects.selectedActionVirtualParticipants.length, ...newVal);
          } else {
            this.$store.state.multiprojects.selectedVirtualParticipants.splice(0, this.$store.state.multiprojects.selectedVirtualParticipants.length, ...newVal);
          }
        },
      },
      selectedDeletedUsers: {
        get() {
          return this.useActionUsers ? this.$store.state.multiprojects.selectedActionDeletedUsers : this.$store.state.multiprojects.selectedDeletedUsers;
        },
        set(newVal) {
          if (this.useActionUsers) {
            this.$store.state.multiprojects.selectedActionDeletedUsers.splice(0, this.$store.state.multiprojects.selectedActionDeletedUsers.length, ...newVal);
          } else {
            this.$store.state.multiprojects.selectedDeletedUsers.splice(0, this.$store.state.multiprojects.selectedDeletedUsers.length, ...newVal);
          }
        },
      },
      selectedAllUsers: {
        get() {
          const selectedAllUsers = [];
          this.companyUsers.forEach((user) => {
            if (this.selectedCompanyUsers.indexOf(user.id) == -1) return;
            selectedAllUsers.push(user);
          });
          this.deletedUsers.forEach((user) => {
            if (this.selectedDeletedUsers.indexOf(user.id) == -1) return;
            selectedAllUsers.push(user);
          });
          this.virtualParticipants.forEach((user) => {
            if (this.selectedVirtualParticipants.indexOf(user.username) == -1) return;
            selectedAllUsers.push(user);
          });
          return selectedAllUsers.map(item => _.pick(item, ['id', 'isDeleted', 'username'])); // autocomplete is not looking for reference equality
        },
        set(newVal) {
          this.selectedCompanyUsers = [];
          this.selectedDeletedUsers = [];
          this.selectedVirtualParticipants = [];
          (newVal || []).forEach((user) => {
            if (user.id && ! user.isDeleted) {
              this.selectedCompanyUsers.push(user.id);
            } else if (user.id && user.isDeleted) {
              this.selectedDeletedUsers.push(user.id);
            } else {
              this.selectedVirtualParticipants.push(user.username);
            }
          });
        },
      },
      organizationCompanies() {
        const { organization } = this.$store.state.users.user;
        if (organization && organization.getCompanies().length > 1) return organization.getCompanies().filter(item => item.users.length);
        return [];
      },
      userIdsByOrganizationCompany() {
        return this.organizationCompanies.reduce((acc, company) => {
          acc[company.id] = company.users.map(item => item.id);
          return acc;
        }, {});
      },
      allUsersList() {
        let allUsersList = this.companyUsers;
        if (this.organizationCompanies.length) {
          allUsersList = [];
          for (let i = 0; i < this.organizationCompanies.length; i++) {
            const company = this.organizationCompanies[i];
            const companyUsers = company.users.map(user => this.companyUsers.find(item => item.id == user.id)).filter(item => item);
            if (! companyUsers.length) continue;
            allUsersList.push({ header: company.name }, { divider: true }, ...companyUsers);
          }
        }
        if (this.deletedUsers.length) {
          allUsersList = allUsersList.concat([{ header: this.$t('MULTIPROJECTS.DELETED_USER', 2) }, { divider: true }]).concat(this.deletedUsers);
        }
        if (this.virtualParticipants.length) {
          allUsersList = allUsersList.concat([{ header: this.$t('MULTIPROJECTS.VIRTUAL_PARTICIPANTS') }, { divider: true }]).concat(this.virtualParticipants);
        }
        return allUsersList.map(item => _.pick(item, ['id', 'isDeleted', 'username', 'header', 'divider']));
      },
      allUsersCount() {
        return this.companyUsers.length + this.deletedUsers.length + this.virtualParticipants.length;
      },
      companyUserIds() {
        return this.companyUsers.map(item => item.id);
      },
      virtualParticipants() {
        return this.useActionUsers ? this.$store.state.multiprojects.actionVirtualParticipants : this.$store.state.multiprojects.virtualParticipants;
      },
      virtualParticipantIds() {
        return this.virtualParticipants.map(item => item.username);
      },
      companyGroups() {
        return (this.$store.getters['users/groups/getCompanyGroups'] || []).filter(group => group.users.length);
      },
      userIdsByGroupId() {
        return this.companyGroups.reduce((acc, group) => {
          acc[group.id] = group.users.map(user => user.id);
          return acc;
        }, {});
      },
      deletedUsers() {
        return this.useActionUsers ? this.$store.state.multiprojects.actionDeletedUsers : this.$store.state.multiprojects.deletedUsers;
      },
      deletedUserIds() {
        return this.deletedUsers.map(item => item.id);
      },
      smallScreen() { return this.$vuetify.display.mdAndDown; },
      ...mapState('multiprojects', ['companyUsers', 'filterOptions']),
    },
    methods: {
      update() {
        this.$emit('update');
      },
    },
  };
</script>
