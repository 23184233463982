<template>
  <div v-if="label && ! filter" class="mb-1"><label :for="inputId">{{ label }}</label></div>
  <v-menu v-model="menu" :close-on-content-click="false" :offset="nudgeDetails ? -22 : null"
          transition="scale-transition" min-width="290px">
    <template #activator="{ props }">
      <component :is="filter ? 'text-filter' : 'v-text-field'" v-model="inputDate" v-bind="{ ...$attrs, ...props }" :id="inputId" :rules="[validateFormat]"
                 :label="filter && label || null" :class="inputClass" autocomplete="off"
                 @click:clear="setUpdatedDate(null)">
      </component>
    </template>
    <v-date-picker ref="picker" v-model="updatedDate" :min="min" :max="max" :allowed-dates="allowedDates"
                   :events="eventDates" color="primary" event-color="accent"
                   hide-header show-adjacent-months
                   @update:model-value="setUpdatedDate(updatedDate); closeMenu()">
      <template #header></template>
    </v-date-picker>
  </v-menu>
</template>

<script>
  /*  Input: v-model (modelValue) can be set to a moment or string
      Output: v-model (@update:modelValue) will be a moment or null

      null = empty date string // Invalid formats (ie 35/12/2018) are not emitted
  */

  import { VTextField } from 'vuetify/components';

  export default {
    inheritAttrs: false,
    components: {
      VTextField,
    },
    props: {
      modelValue: { type: [String, Object], default: null },
      label: { type: String, default: '' },
      format: { type: String, default: '' },
      noNull: { type: Boolean, default: false },
      allowedDates: { type: Function, default: null },
      eventDates: { type: Array, default: () => [] },
      inputClass: { type: String, default: '' },
      min: { type: String, default: null },
      max: { type: String, default: null },
      nudgeDetails: { type: Boolean, default: false },
      filter: { type: Boolean, default: false }, // style as filter
    },
    emits: ['update:modelValue'],
    data() {
      return {
        updatedDate: null,
        menu: null,
        inputId: `datefield${this.$.uid}`,
      };
    },
    computed: {
      locale() {
        return this.$store.state.lang.lang;
      },
      dateFormat() {
        if (this.format) return this.format;
        return this.locale == 'en' ? 'YYYY-MM-DD' : 'DD/MM/YYYY';
      },
      inputDate: {
        get() {
          return this.updatedDate ? this.updatedDate.format(this.dateFormat) : null;
        },
        set(newVal) {
          if (! newVal) {
            this.setUpdatedDate(null);
            return;
          }
          if (! this.isFormatValid(newVal)) return;
          const newdate = moment(newVal, this.dateFormat);
          if (! newdate.isValid()) return;
          let loopLimit = 500;
          while (this.allowedDates && ! this.allowedDates(newdate) || ! loopLimit--) {
            newdate.add(1, 'day');
          }
          this.setUpdatedDate(newdate.startOf('day'));
        },
      },
    },
    watch: {
      modelValue: {
        handler(newVal) {
          if (! newVal) { this.updatedDate = null; return; }
          this.updatedDate = moment(newVal).startOf('day').locale(this.locale);
        },
        immediate: true,
      },
      locale() {
        if (this.updatedDate) this.updatedDate.locale(this.locale);
      },
    },
    methods: {
      setUpdatedDate(newVal) {
        this.updatedDate = newVal;
        if (this.noNull && newVal === null) return;
        this.$emit('update:modelValue', this.updatedDate);
      },
      closeMenu() {
        this.menu = false;
      },
      isFormatValid(str) {
        return moment(str, this.dateFormat, this.locale, true).isValid();
      },
      validateFormat(str) {
        if (! str) {
          return ! this.noNull || 'Date required';
        }
        return this.isFormatValid(str) || `Format : ${this.dateFormat}`;
      },
    },
  };
</script>
