<template>
  <div class="mb-1">{{ companyNonConsultUsers.length ? $t('MANAGE.CHOOSE_NEW_OWNER') : $t('MANAGE.NO_NEW_OWNER_AVAILABLE') }}</div>
  <div v-bind="$attrs">
    <select-user v-model="newOwner.user" :items="companyNonConsultUsers"
                 :disabled="! companyNonConsultUsers.length" class="my-1" style="max-width: 360px;">
    </select-user>
    <v-btn :disabled="! newOwner.user || ! newOwner.user.id || newOwner.sending" color="primary" rounded size="large" class="my-1" @click="setNewOwner()">
      {{ $t('MANAGE.SET_OWNER') }}
    </v-btn>
  </div>
</template>

<script>
  export default {
    props: {
      project: { type: Object, required: true },
    },
    emits: ['change-owner'],
    data() {
      return {
        newOwner: { user: null, sending: null },
      };
    },
    computed: {
      currentOwnerId() {
        return this.project.owner_id;
      },
      subcompaniesUsers() {
        if (this.$store.state.users.user.organization) {
          const subcompanies = this.$store.getters['users/getOrganizationSubCompanies'];
          return subcompanies.map(company => company.users).reduce((acc, val) => acc.concat(val), []); // reduce(...) could be replaced by flat() with ie polyfill
        }
        return this.$store.getters['users/getCompanyUsers'];
      },
      companyNonConsultUsers() {
        return this.subcompaniesUsers.filter(user => user.access_right != 'consult' && user.id != this.currentOwnerId);
      },
    },
    methods: {
      setNewOwner() {
        const projectId = this.project.id;
        const ownerId = this.newOwner.user && this.newOwner.user.id;
        if (! ownerId) return;
        this.newOwner.sending = true;
        window.apiSrv.call(`plannings/${projectId}/admin`, 'update', { owner_id: ownerId }).then(() => {
          this.newOwner = { user: null, sending: null };
          this.$emit('change-owner', { projectId, ownerId });
        }).catch((message) => {
          this.$store.dispatch('ui/msgbox/open', { title: "Error : Owner was not modified", body: message || "" });
          this.newOwner.sending = false;
        });
      },
    },
  };
</script>
