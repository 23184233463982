export default {
  hideManageAccount: true,
  sso: {
    protocol: 'openIDConnect',
    login: 'https://idpb2e.adeo.com/as/authorization.oauth2?client_id=0P42d46d175da500e1298634f0ccd13213&response_type=code&scope=openid%20email%20profile&redirect_uri=https%3A%2F%2Fleroymerlin.bubbleplan.net',
    response_type: 'code',
  },
  integrations: {
    availableIntegrations: ['google'],
  },
  links: {
    hideOneDrive: true,
    hideDropbox: true,
    hideGoogleDrive: false,
    hideFileUpload: true,
  },
  projects: {
    defaultPrivacy: 'private',
  },
  subprojects: {
    synthesisPopover: true,
  },
};
