<template>
  <div v-if="! projectCategoryTags" class="text-center pa-4" style="font-size: 18px">
    <i class="far fa-spinner fa-spin"></i>
  </div>
  <div v-else-if="! projectCategoryTags.length" class="text-medium-emphasis">{{ $t('MANAGE.CONFIG.NO_CATEGORY') }}</div>
  <v-table v-else dense class="manage-config-project-settings-categories">
    <tbody>
      <tr v-for="category in projectCategoryTags" :key="category">
        <td>{{ category }}</td>
        <td>
          <input :value="`#${getCategoryColor(category)}`" type="color" class="vcenter" @change="setCategoryColor(category, $event)">
          <v-btn v-if="getCategoryColor(category) != str2color(category)" :title="$t('MANAGE.CONFIG.DEFAULT_COLOR')" size="small" icon class="ml-1"
                 @click="deleteCategoryColor(category)">
            <v-icon>far fa-undo-alt</v-icon>
          </v-btn>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<style lang="scss">
  .manage-config-project-settings-categories {
    table {
      width: auto !important;
    }
    td {
      border-radius: 0 !important;
    }
  }
</style>

<script>
  export default {
    data() {
      return {
        categoryColors: {},
        projectCategoryTags: null,
      };
    },
    computed: {
      computedBusinessConfigCategoryColor() {
        return this.$store.state.users.accessRight.businessConfig.categoryColors || {};
      },
      getCategoryColor() { return this.$store.getters['users/accessRight/config'].getCategoryColor; },
      str2color() { return window.str2color; },
    },
    watch: {
      computedBusinessConfigCategoryColor: {
        handler(newVal) {
          this.categoryColors = { ...newVal };
        },
        immediate: true,
      },
    },
    created() {
      window.apiSrv.call('plannings/categories', 'index').then((response) => {
        this.projectCategoryTags = this.categories2tags(response.data?.categories || []).sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
      });
    },
    methods: {
      categories2tags(categories) {
        const tags = new Set();
        categories.forEach((item) => {
          (item || '').split(',').forEach((subitem) => {
            const tag = subitem && subitem.trim();
            if (tag) tags.add(tag);
          });
        });
        return [...tags];
      },
      setCategoryColor(category, { target: { value: color } }) {
        this.categoryColors[category] = color.replace('#', '');
        this.setParam('categoryColors', this.categoryColors);
      },
      deleteCategoryColor(category) {
        delete this.categoryColors[category];
        this.setParam('categoryColors', this.categoryColors);
      },
      setParam(prop, val) {
        this.$store.dispatch('users/accessRight/updateBusinessConfig', { [prop]: val });
      },
    },
  };
</script>
