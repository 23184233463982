<template>
  <div class="checklist">
    <div v-if="isMiniElement"><i class="fa fa-check-square"></i> {{ elCompletedCount }}<span style="margin: 0 1px">/</span>{{ elChecklist.length }}</div>
    <template v-else>
      <div v-for="item in elChecklistFiltered" :class="el.getChecklistItemClass(item)" :title="checklistItemTitle(item)">
        <i :class="item.checked ? 'fa-check-square' : 'fa-square'" class="far mr-1"
           @click.stop="$emit('checklist-click', item)">
        </i>
        <span>{{ item.title }}</span>
      </div>
    </template>
  </div>
</template>

<script>
  import workloadMixin from '@/components/Workload/workloadMixin';

  const momentMediumDateNoYearFormat = moment.localeData().custom && moment.localeData().custom('mediumDateNoYear') || 'MMM D';

  export default {
    mixins: [workloadMixin],
    props: {
      el: { type: Object, required: true },
      elChecklistFiltered: { type: Array, required: true },
      isMiniElement: { type: Boolean, default: false },
    },
    emits: ['checklist-click'],
    computed: {
      elChecklist() {
        return this.el.getChecklist() || [];
      },
      elCompletedCount() {
        return this.elChecklist.filter(item => item.checked).length;
      },
    },
    methods: {
      checklistItemTitle(item) {
        let username = this.$store.getters['users/getUsername']({ id: item.user_id, username: item.username });
        username = username && `${username}\n`;
        const duedate = item.duedate && `${moment(item.duedate).format(momentMediumDateNoYearFormat)}\n`;
        const workload = (item.workload || item.workload_used)
          && `${this.$t('HR.WORKLOAD')} : ${this.workloadToDisplay(item.workload || 0)}⯈${this.workloadToDisplay(item.workload_used || 0)}`;
        return (username || '') + (duedate || '') + (workload || '');
      },
    },
  };
</script>
