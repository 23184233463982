import { createStore } from 'vuex';
import Planning from '@/models/Planning'; // Must import first (even if not used) for custom dashboards to work
import planning from './planning/planning';
import selection from './planning/selection';
import subprojects from './planning/subprojects';
import undoredo from './planning/undoredo';
import integrations from './integrations';
import multiprojects from './multiprojects/multiprojects';
import eventbus from './eventbus';
import manage from './manage';
import monitoringplan from './monitoringplan';
import ui from './ui';
import lang from './lang';
import notifications from './notifications';
import users from './users';
import workloads from './module_hr/workloads';
import holidays from './module_hr/holidays';
import clientCustoms from './client_customs';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  state: {
  },
  modules: {
    planning,
    selection,
    integrations,
    subprojects,
    undoredo,
    eventbus,
    manage,
    monitoringplan,
    multiprojects,
    ui,
    lang,
    notifications,
    users,
    workloads,
    holidays,
    clientCustoms,
  },
  mutations: {
    refresh() {
      // console.log('refresh vuex state');
    },
  },
//  strict: debug,
});
