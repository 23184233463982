<template>
  <div id="kanban-col-headers" v-fix-to-top="{ placeholder: 'table-header-placeholder', topbar: '#topbar', xScroll: '#table-wrapper' }" class="d-flex">
    <draggable v-if="canModifyPlanning" :list="processSteps" item-key="id" handle=".horizontal-draggable-anchor" class="d-flex"
               @start="startChangingProcess()" @end="changingProcess()">
      <template #item="{ element: col }">
        <div :id="`kanbanheader-${col.id}`"
             :class="col.getColorClass()" class="kanban-col-header bg-el-color hovervisible-activator mx-1"
             :style="{ width: `${col.width - 8}px` }">
          <div v-if="col.width >= kanbanDefaultColWidth" :title="$t('KANBAN.DRAG_AND_DROP_STEPS')" class="horizontal-draggable-anchor grab hovervisible-item"></div>

          <div :title="col.label" class="d-flex align-stretch h-100">
            <!-- Icon -->
            <div v-if="col.hasIcon()" class="flex-center-center pointer" style="background: #ffffffaa; flex: 0 0 36px" @click.stop="selectColHeaderColor(col)">
              <v-icon :color="col.getIcon().color" size="16">
                {{ col.getIcon().name }}
              </v-icon>
            </div>
            <!-- Label -->
            <input v-if="col.width >= kanbanDefaultColWidth" :ref="`colHeaderInput${col.id}`" v-model.trim="col.label" v-contentautoselect :disabled="! col.id" spellcheck="false"
                   class="text-ellipsis text-center px-1 my-1" style="width: 100%; color: inherit"
                   @focus="startChangingProcess" @blur="changingProcess">
            <!-- Menu -->
            <div v-if="col.width >= kanbanDefaultColWidth">
              <v-menu location="bottom end" style="z-index: 999">
                <template #activator="{ props }">
                  <div :title="$t('KANBAN.CONFIGURE_COLUMN')" class="col-context-menu rounded-e-sm flex-center-center" v-bind="props">
                    <span class="far fa-ellipsis-v"></span>
                  </div>
                </template>
                <v-list density="compact">
                  <v-list-item v-if="col.id" @click="editColTitle(col)">
                    <i class="far fa-pen" style="width: 24px"></i>{{ $t('GLOBAL.RENAME') }}
                  </v-list-item>
                  <v-list-item @click="selectColHeaderColor(col)">
                    <i class="fas fa-tint" style="width: 24px"></i>{{ $t('PLANNING.SELECT_ICON_AND_COLOR') }}
                  </v-list-item>
                  <v-list-item>
                    <div class="d-flex align-center" :title="$t('KANBAN.COLUMN_WIDTH')">
                      <i class="far fa-arrows-alt-h" style="width: 24px"></i>
                      <btn-toggle-field :model-value="getColSize(col)" density="compact" :items="[{ title: 'xs', value: 'xs' }, { title: 'md', value: 'md' }, { title: 'xl', value: 'xl' }]"
                                        @update:model-value="toggleColSize(col, $event)">
                      </btn-toggle-field>
                    </div>
                  </v-list-item>
                  <v-list-item v-if="col.id" class="text-errorred" @click="deleteStep(col.id)">
                    <i class="far fa-trash-alt" style="width: 24px"></i>{{ $t('GLOBAL.DELETE') }}
                  </v-list-item>
                  <v-list-item v-if="! col.id">
                    <div class="d-flex align-center" :title="$t('KANBAN.SHOW_PLANNED_ELEMENTS')">
                      <i class="far fa-calendar-alt" style="width: 24px"></i>
                      <i class="far fa-calendar-alt bg-white" style="position: relative; left: -16px"></i>
                      <v-switch v-model="showPlannedElements" class="d-inline-block" density="compact" :label="$t('KANBAN.PLANNED_ELEMENTS')"></v-switch>
                      <span class="label-new ml-2">NEW</span>
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <!-- Xs col menu -->
            <div v-else :title="`${$t('KANBAN.SHOW_COLUMN')} ${col.label}`" class="col-context-menu rounded-sm flex-center-center" style="height: 100%; width: 100%"
                 @click="toggleColSize(col, 'md')">
              <v-icon size="x-small">far fa-chevron-right</v-icon>
            </div>
          </div>
        </div>
      </template>
    </draggable>

    <!-- viewer -->
    <div v-else class="d-flex">
      <div v-for="col in processSteps" :id="`kanbanheader-${col.id}`" :key="col.id"
           :class="col.getColorClass()" class="kanban-col-header bg-el-color mx-1"
           :style="{ width: `${col.width - 8}px` }">
        <div :title="col.label" class="d-flex align-stretch h-100">
          <!-- Icon -->
          <div v-if="col.hasIcon()" class="flex-center-center" style="background: #ffffffaa; flex: 1 0 36px">
            <v-icon :color="col.getIcon().color" size="16">
              {{ col.getIcon().name }}
            </v-icon>
          </div>
          <!-- Label -->
          <div v-if="col.width >= kanbanDefaultColWidth" class="text-ellipsis text-center px-1 my-1 nobr" style="width: 100%">{{ col.label }}</div>
        </div>
      </div>
    </div>

    <!-- Subprojects cols -->
    <div class="d-flex">
      <div v-for="col in subprojectsProcessSteps" :id="`kanbanheader-${col.id}`" :key="col.id"
           :class="col.getColorClass()" class="kanban-col-header bg-el-color hovervisible-activator mx-1"
           :style="{ width: `${col.width - 8}px` }">
        <div :title="col.label" class="d-flex align-stretch h-100">
          <!-- Icon -->
          <div v-if="col.hasIcon()" class="flex-center-center" style="background: #ffffffaa; flex: 0 0 36px">
            <v-icon :color="col.getIcon().color" size="16">
              {{ col.getIcon().name }}
            </v-icon>
          </div>
          <!-- Label -->
          <div v-if="col.width >= kanbanDefaultColWidth" class="kanban-subproject-col-label text-ellipsis text-center px-1 my-1 nobr" style="width: 100%">
            {{ col.label }}
            <div class="small">({{ $t('PLANNING.SUBPROJECT').toLowerCase() }})</div>
          </div>

          <!-- Menu (hide only) -->
          <div v-if="col.width >= kanbanDefaultColWidth" :title="`${$t('KANBAN.HIDE_COLUMN')} ${col.label}`"
               class="col-context-menu rounded-sm flex-center-center hovervisible-item position-absolute" style="height: 100%; width: 20px; top: 0; right: 0"
               @click="toggleSubstep(col, 'hide')">
            <v-icon size="x-small">far fa-chevron-left</v-icon>
          </div>
          <!-- Xs col menu -->
          <div v-else :title="`${$t('KANBAN.SHOW_COLUMN')} ${col.label}`" class="col-context-menu rounded-sm flex-center-center" style="height: 100%; width: 100%"
               @click="toggleSubstep(col, 'show')">
            <v-icon size="x-small">far fa-chevron-right</v-icon>
          </div>
        </div>
      </div>
    </div>

    <!-- MODALS -->
    <v-menu v-if="openedColorPickerColHeader" :model-value="!! openedColorPickerColHeader" :close-on-content-click="false"
            :target="`#kanbanheader-${openedColorPickerColHeader.id}`" location="end" offset="4" max-width="450"
            @update:model-value="closeColorEditingModal">
      <v-card>
        <color-picker :planning="planning" :target="openedColorPickerColHeader" @close="closeColorEditingModal"></color-picker>
      </v-card>
    </v-menu>
  </div>

  <!-- ADD NEW KANBAN COL -->
  <div v-if="canModifyPlanning" class="px-2" :style="{ left: `${processStepsLength}px` }" style="position: absolute; top: 20px"
       @click="addCol()">
    <v-btn :size="32" :title="$t('KANBAN.ADD_NEW_COLUMN')" class="el-color-0- bg-el-color" style="border-radius: 5px;">
      <v-icon>far fa-plus</v-icon>
    </v-btn>
  </div>
</template>

<style lang="scss">
  @import 'vuetify/lib/styles/tools/_elevation.sass';

  #kanban-col-headers.fixed {
    position: fixed;
    top: 0px;
    z-index: 300;
    width: inherit;
    @include elevation(4, $important: true);
    transform: translateZ(0);
    padding-top: 1px; /* prevent half pixel appearing behind timeline */
    margin-top: -1px;
    background: white;
  }

  .kanban-col-header {
    font-weight: bold;
    line-height: 32px;
    position: relative;
    border-radius: 4px;

    .horizontal-draggable-anchor {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 4px 4px 0 0;
    }

    .kanban-subproject-col-label {
      line-height: 14px;
      align-content: center;
    }
  }
</style>

<script>
  import { mapActions } from 'vuex';
  import draggable from 'vuedraggable';
  import constants from '@/js/constants.js';
  import PlanningProcessStep from '@/models/PlanningProcessStep';
  import ColorPicker from '@/components/ColorPicker/ColorPicker';

  export default {
    components: {
      draggable,
      ColorPicker,
    },
    props: {
      planning: { type: Object, required: true },
      dynamic: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      processStepsLength: { type: Number, default: NaN },
    },
    data() {
      const { kanbanDefaultColWidth } = constants;

      return {
        kanbanDefaultColWidth,
        defaultProcessSteps: this.$store.getters['users/accessRight/config'].processSteps.map((step) => {
          const width = step.id ? kanbanDefaultColWidth : 1.5 * kanbanDefaultColWidth;
          return new PlanningProcessStep(this.planning, { ...step, width });
        }),
        openedColorPickerColHeader: null,
      };
    },
    computed: {
      processSteps: {
        get() {
          return this.planning.process.steps;
        },
        set(newVal) {
          this.planning.process.steps = newVal;
        },
      },
      subprojectsProcessSteps() {
        return this.$store.getters['planning/process/subprojectsProcessSteps'];
      },
      showPlannedElements: {
        get() {
          return this.planning.process.config.getShowPlannedElements();
        },
        set(value) {
          this.startChangingProcess();
          this.planning.process.config.setShowPlannedElements(value);
          this.changingProcess();
        },
      },
      canModifyPlanning() {
        return this.dynamic && ! this.view;
      },
    },
    created() {
      if (! this.processSteps.length) this.processSteps = [this.defaultProcessSteps[0]]; // Backlog
    },
    methods: {
      addCol() {
        if (this.processSteps.length == 1) { // only Backlog
          this.addDefaultSteps(this.defaultProcessSteps.filter(step => step.id));
        } else {
          this.addStep(null);
        }
        setTimeout(() => { $("#table-wrapper").scrollLeft(99999); });
      },
      editColTitle(col) {
        this.$refs[`colHeaderInput${col.id}`].focus();
      },
      selectColHeaderColor(col) {
        this.startChangingProcess();
        this.openedColorPickerColHeader = col;
      },
      closeColorEditingModal() {
        this.changingProcess();
        this.openedColorPickerColHeader = null;
      },
      getColSize(col) {
        return col.width < constants.kanbanDefaultColWidth ? 'xs' : col.width == constants.kanbanDefaultColWidth ? 'md' : 'xl';
      },
      toggleColSize(col, size = 'md') {
        const width = size == 'xs' ? constants.kanbanXsColWidth : size == 'xl' ? 1.5 * constants.kanbanDefaultColWidth : constants.kanbanDefaultColWidth;
        this.startChangingProcess();
        col.width = width;
        this.changingProcess();
      },
      toggleSubstep(col, action = 'show') {
        this.startChangingProcess();
        if (action == 'hide') {
          this.planning.process.config.addHiddenSubstep(col.id);
        } else {
          this.planning.process.config.removeHiddenSubstep(col.id);
        }
        this.changingProcess();
      },
      ...mapActions('planning/process', ['addStep', 'addDefaultSteps', 'deleteStep', 'startChangingProcess', 'changingProcess']),
    },
  };
</script>
