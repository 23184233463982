export default {
  namespaced: true,
  state: {
    list: [],
  },
  actions: {
    open({ state, rootState }, { title = '', body = '', buttons = { ok: 'GLOBAL.OK' }, type = 'modal' }) {
      if (buttons.ok?.startsWith('GLOBAL.')) buttons.ok = rootState.lang.i18n.global.t(buttons.ok);
      if (buttons.cancel?.startsWith('GLOBAL.')) buttons.cancel = rootState.lang.i18n.global.t(buttons.cancel);
      return new Promise((resolve, reject) => {
        state.list.push({
          id: window.uuid(),
          type, // modal or vdialog
          title,
          body,
          buttons,
          close() {
            resolve();
            const index = state.list.findIndex(modal => modal.id === this.id);
            if (index > -1) state.list.splice(index, 1);
          },
          dismiss(reason) {
            if (buttons?.cancel) {
              reject(reason);
            } else {
              resolve();
            }
            const index = state.list.findIndex(modal => modal.id === this.id);
            if (index > -1) state.list.splice(index, 1);
          },
        });
      });
    },
  },
};
