<template>
  <v-card class="pa-8">
    <div class="d-flex align-center mb-2">
      <img v-if="integrationName == 'microsoft'" src="../../../img/integrations/outlook.png" style="width: 40px">
      <img v-else src="../../../img/integrations/gcal.png" style="width: 40px">
      <div class="ml-2 line-height-normal">
        <b>{{ $t(`INTEGRATIONS.${integrationName == 'microsoft' ? 'MICROSOFT_OUTLOOK' : 'GOOGLE_CALENDAR'}`) }}<br>↪ {{ $t('INTEGRATIONS.SYNC_ALL_PROJECTS') }}</b>
      </div>
    </div>
    <template v-if="plannings">
      <div class="py-1 pr-4 overflow-y-auto overflow-x-hidden light-scrollbar overscroll-behavior-y-contain">
        <div v-for="planning in userElementsByPlanning" :id="`syncplanning${planning.id}`" :key="planning.id">
          <v-icon v-if="syncStatus[planning.id] == 'current'" size="x-small" class="mr-1">far fa-sync fa-spin</v-icon>
          <v-icon v-else-if="syncStatus[planning.id] == 'done'" size="x-small" color="successgreen" class="mr-1">far fa-check</v-icon>
          <v-icon v-else-if="syncStatus[planning.id]" size="x-small" color="errorred" :title="syncStatus[planning.id]" class="mr-1">far fa-exclamation-triangle</v-icon>
          <v-icon v-else size="x-small" color="medium-emphasis" class="mr-1">far fa-sync</v-icon>
          {{ planning.title }}
          - {{ $t(`GLOBAL.${(elementsType == 'milestone' ? 'MILESTONES_COUNT' : 'BUBBLES_COUNT')}`, planning.elements.size) }}
        </div>
      </div>
      <v-btn v-if="! allDone" rounded size="large" :color="inprogress ? 'errorred' : 'primary'" class="mt-4" @click="startStopSync">
        {{ inprogress ? $t('INTEGRATIONS.STOP_SYNC') : $t('INTEGRATIONS.START_SYNC') }}
      </v-btn>
      <v-btn v-else rounded variant="outlined" size="large" class="mt-4" @click="close">{{ $t('GLOBAL.CLOSE') }}</v-btn>
    </template>
    <div v-else class="pa-6 text-center"><i class="fas fa-spinner fa-spin fa-2x fa-fw"></i></div>

    <v-btn-icon color="midgrey" :disabled="inprogress" style="position: absolute; top: 4px; right: 4px" @click="close">
      <v-icon size="large">far fa-times</v-icon>
    </v-btn-icon>
  </v-card>
</template>

<script>
  export default {
    props: {
      isOpened: { type: Boolean, default: false },
      integrationName: { type: String, required: true },
      syncElementType: { type: String, required: true },
    },
    emits: ['close'],
    data() {
      return {
        plannings: null,
        syncStatus: {},
        inprogress: false,
      };
    },
    computed: {
      user() { return this.$store.state.users.user; },
      elementsType() { return { sync_tasks: 'task', sync_milestones: 'milestone' }[this.syncElementType] || ''; },
      userElementsByPlanning() {
        return this.plannings.reduce((acc, planning) => {
          const planningElements = new Set();
          planning.elements.forEach((el) => {
            if (! el.isType(this.elementsType) || ! el.hasDates()) return; // integrations server needs both dates
            el.getUsers()?.forEach((user) => {
              if (user.id != this.user.id) return;
              planningElements.add(el);
            });
          });
          if (planningElements.size) acc.push({ id: planning.id, title: planning.getTitle(), elements: planningElements });
          return acc;
        }, []).sort((a, b) => a.title.localeCompare(b.title));
      },
      allDone() {
        return this.userElementsByPlanning.every(planning => this.syncStatus[planning.id] == 'done');
      },
    },
    created() {
      this.$store.dispatch('multiprojects/plannings/loadPlanningsQuery', { user_ids: [this.user.id] }).then((plannings) => {
        this.plannings = plannings;
      });
    },
    methods: {
      async startStopSync() {
        this.inprogress = ! this.inprogress;
        if (! this.inprogress) return;

        const allPromises = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const planning of this.userElementsByPlanning) { // for of loop to await between iterations
          if (this.syncStatus[planning.id] == 'done') continue;
          document.getElementById(`syncplanning${planning.id}`).scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
          this.syncStatus[planning.id] = 'current';

          const syncElementPromises = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const el of planning.elements) {
            if (! this.inprogress) { // sync was stopped
              delete this.syncStatus[planning.id];
              return;
            }
            const postEventPromise = this.$store.dispatch(`integrations/${this.integrationName}/postEvent`, { el, elId: el.o_id });
            syncElementPromises.push(postEventPromise);
            // eslint-disable-next-line no-await-in-loop
            await new Promise((resolve) => { setTimeout(resolve, 300); }); // pause to respect google and microsoft api rate limit
          }
          allPromises.push(Promise.all(syncElementPromises).then(() => {
            this.syncStatus[planning.id] = 'done';
          }).catch((error) => { // triggered on first promise error
            this.syncStatus[planning.id] = error;
          }));
        }
        Promise.all(allPromises).then(() => {
          this.inprogress = false;
        });
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
