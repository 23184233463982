<template>
  <v-dialog v-if="displayLoginWindow" :model-value="displayLoginWindow" content-class="bg-background pa-sm-12" scroll-strategy="none" fullscreen persistent>
    <v-row style="flex-grow: 0">
      <v-col cols="12" md="6">
        <div class="ml-auto mr-auto mr-md-0 d-flex flex-column" style="height: calc(100% + 48px); max-width: 600px; position: relative">
          <login-window></login-window>
        </div>
      </v-col>
      <v-col cols="6" class="hidden-sm-and-down pt-6" style="max-width: 600px; max-height: calc(100vh - 96px); padding-left: 100px;">
        <div class="text-right mb-12"><img src="@/img/bubble-plan-logo-color.png" style="height: 40px;"></div>
        <img src="@/img/animations/illustration-login.gif" style="max-width: 100%; max-height: calc(100% - 40px - 48px)">
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
  import LoginWindow from './LoginWindow';

  export default {
    components: {
      LoginWindow,
    },
    computed: {
      displayLoginWindow() {
        return this.$store.state.ui.displayLoginWindow;
      },
    },
  };
</script>
