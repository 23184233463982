<template>
  <teleport to="#planning-drawer-title">
    <div class="w-100">
      <v-tabs v-model="openedTabIndex" slider-color="primary" density="compact">
        <v-tab v-if="tabsContain('timeline')" value="timeline">{{ $t('CONFIG.TIMELINE') }}</v-tab>
        <v-tab v-if="tabsContain('lanes')" value="lanes">{{ $t('CONFIG.LANES') }}</v-tab>
        <v-tab v-if="tabsContain('design')" value="design">{{ $t('CONFIG.DESIGN') }}</v-tab>
      </v-tabs>
    </div>
  </teleport>

  <v-window v-model="openedTabIndex" v-bind="$attrs">
    <!--    TAB CONFIG TIMELINE -->
    <v-window-item v-if="tabsContain('timeline')" value="timeline">
      <config-timeline :timeline="planning.timeline"></config-timeline>
    </v-window-item>

    <!--    TAB CONFIG LANES -->
    <v-window-item v-if="tabsContain('lanes')" value="lanes">
      <config-lanes :planning="planning" @close="close"></config-lanes>
    </v-window-item>

    <!--    TAB CONFIG DESIGN -->
    <v-window-item v-if="tabsContain('design')" value="design">
      <config-design :planning="planning"></config-design>
    </v-window-item>
  </v-window>
</template>

<script>
  import { mapState } from 'vuex';
  import ConfigTimeline from './ConfigTimeline.vue';
  import ConfigDesign from './ConfigDesign.vue';
  import ConfigLanes from './ConfigLanes.vue';

  export default {
    components: {
      ConfigTimeline,
      ConfigDesign,
      ConfigLanes,
    },
    props: {
      planning: { type: Object, required: true },
      tabs: { type: Array, default: null },
    },
    emits: ['close'],
    data() {
      return {
        openedTabIndex: null,
      };
    },
    computed: {
      availableTabs() {
        return this.dashboard ? new Set(['timeline', 'design']) : new Set(['timeline', 'lanes', 'design']);
      },
      ...mapState('ui/planning', ['dashboard']),
    },
    watch: {
      openedTabIndex() {
        window.sessionStorageWrapper.setItem('option.configTab', this.openedTabIndex);
      },
    },
    created() {
      const configTab = window.sessionStorageWrapper.getItem('option.configTab');
      if (configTab && this.tabsContain(configTab)) this.openedTabIndex = configTab;
    },
    methods: {
      tabsContain(tabName) {
        return this.availableTabs.has(tabName);
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
