<template>
  <router-link :to="view ? viewLink : projectLink(project, project.access_right == 'consult' || statusInfo.readonly)"
               :style="{ cursor: archived ? 'default' : null }" style="color:inherit; text-decoration:none;">
    <project-preview :project="project" :thumbnail="view ? defaultBg : project.imgurl">
      <template v-if="view" #body-details>
        <div>
          <div>
            <i class="far fa-clock"></i> {{ $filters.moment(project.project.date_of_modification, 'calendar') }}
            <div class="text-medium-emphasis small">
              {{ $t('PROJECTS.LAST_MODIFICATION_BY') }} {{ translateApiText(project.project.last_modification_user_name) }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="text-center small" style="margin-top: auto">Vue personnalisée</div>
        </div>
      </template>
      <template v-else #body-details>
        <div v-if="! archived" class="mt-1 d-flex align-end">
          <div>
            <i class="far fa-clock"></i> {{ $filters.moment(project.date_of_modification, 'calendar') }}
            <div class="text-medium-emphasis small">
              {{ $t('PROJECTS.LAST_MODIFICATION_BY') }} {{ translateApiText(project.last_modification_user_name) }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <div :title="`${$t('GLOBAL.OWNER')} : ${project.owner_name == 'API.ME' ? $t('API.ME') : (owner ? owner_username : project.owner_name)}`" class="d-flex mr-1">
            <user-avatar :user="owner" :size="32"></user-avatar>
          </div>
        </div>
        <div v-else class="text-medium-emphasis small d-flex align-end">
          {{ $t('PROJECTS.ARCHIVED_ON') }} {{ $filters.moment(project.deleted_at, 'date') }}
          <v-spacer></v-spacer>
          <div :title="`${$t('GLOBAL.OWNER')} : ${project.owner_name == 'API.ME' ? $t('API.ME') : (owner ? owner_username : project.owner_name)}`" class="d-flex mr-1">
            <user-avatar :user="owner" :size="32"></user-avatar>
          </div>
        </div>
      </template>
      <template v-if="! view" #notifications>
        <div class="d-flex align-center" style="padding-bottom: 1px">
          <div v-if="projectNotifsCount" :title="$t('NOTIFICATIONS.NOTIFICATIONS')" class="text-errorred nobr mr-2">
            <i class="far fa-bell"></i>&nbsp;{{ projectNotifsCount }}
          </div>
          <div v-if="project.access_right == 'admin' && ! statusInfo.readonly" :title="$t('PROJECTS.ADMIN_RIGHTS')">
            <i class="far fa-fw fa-unlock-alt text-warningorange"></i>
          </div>
          <div v-else-if="project.access_right == 'modify' && ! statusInfo.readonly" :title="$t('PROJECTS.MODIFY_RIGHTS')">
            <i class="far fa-fw fa-pencil-alt text-successgreen"></i>
          </div>
          <div v-else :title="$t('PROJECTS.READ_ONLY')">
            <i class="far fa-fw fa-eye text-successgreen"></i>
          </div>
        </div>
      </template>

      <template v-if="! userConsultOnly && ! view" #menu>
        <v-menu location="bottom end">
          <template #activator="{ props }">
            <v-btn icon size="small" variant="text" class="projectmenu" v-bind="props" @click.prevent>
              <v-icon>far fa-ellipsis-v</v-icon>
            </v-btn>
          </template>
          <v-list density="compact">
            <template v-if="! archived">
              <v-list-item @click="openedProjectSheetProject = project">
                <i class="far fa-flag" style="width: 24px"></i>{{ $t('PROJECT_SHEET.PROJECT_SHEET') }}
              </v-list-item>
              <v-menu open-on-hover location="end">
                <template #activator="{ props }">
                  <v-list-item v-if="isBusiness" :disabled="! isProjectAdmin" v-bind="props" @click.stop>
                    <div class="flex-grow-1 d-flex align-center">
                      <i class="far fa-asterisk" style="width: 24px"></i>{{ $t('PROJECTS.STATUS.STATUS') }}
                      <v-icon v-if="isProjectAdmin" class="ml-auto">fas fa-caret-right</v-icon>
                    </div>
                  </v-list-item>
                </template>
                <v-list>
                  <v-list-item v-for="status in otherStatusKeys" :key="status" @click="updateProjectStatus(status)">
                    {{ status ? projectStatusConfig[status].label : $t('PROJECTS.STATUS.NONE') }}
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-list-item :to="`manage?open=${project.id}`">
                <i class="far fa-lock" style="width: 24px"></i>{{ $t('PROJECTS.MANAGE_ACCESS') }}
              </v-list-item>
              <v-list-item v-if="isBusiness" :to="projectHistoryLink">
                <i class="far fa-history" style="width: 24px"></i>{{ $t('PROJECTS.HISTORY') }}
              </v-list-item>
              <v-list-item class="px-1">
                <div class="d-flex">
                  <div v-if="statusInfo.readonly && ['admin', 'modify'].includes(project.access_right)" class="text-center" style="flex-grow: 1">
                    <!-- LINK TO ACCESS IN MODIFICATION WHEN statusInfo.readonly -->
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-btn :to="projectLink(project)" icon size="small" variant="text" v-bind="props">
                          <v-icon color="successgreen">far fa-pencil-alt</v-icon>
                        </v-btn>
                      </template>
                      {{ $t('PROJECTS.MODIFY') }}
                    </v-tooltip>
                  </div>
                  <div v-else class="text-center" style="flex-grow: 1">
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-btn :disabled="project.access_right == 'consult'" :to="projectLink(project, true)" icon size="small" variant="text" v-bind="props">
                          <v-icon color="successgreen">far fa-eye</v-icon>
                        </v-btn>
                      </template>
                      {{ $t('PROJECTS.READ_ONLY') }}
                    </v-tooltip>
                  </div>
                  <v-divider vertical class="my-1"></v-divider>
                  <div class="text-center" style="flex-grow: 1">
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-btn :disabled="! isProjectAdmin" icon size="small" variant="text" v-bind="props" @click="$emit('duplicate')">
                          <v-icon color="info">far fa-copy</v-icon>
                        </v-btn>
                      </template>
                      {{ $t('PROJECTS.DUPLICATE') }}
                    </v-tooltip>
                  </div>
                  <v-divider vertical class="my-1"></v-divider>
                  <div class="text-center" style="flex-grow: 4">
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-btn :disabled="! isProjectAdmin" icon size="small" variant="text" v-bind="props" @click="$emit('archive')">
                          <v-icon color="errorred">far fa-archive</v-icon>
                        </v-btn>
                      </template>
                      {{ $t('PROJECTS.ARCHIVE') }}
                    </v-tooltip>
                  </div>
                </div>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item :disabled="! isProjectAdmin" @click="$emit('restore')">
                <i class="far fa-trash-restore" style="width: 24px"></i>{{ $t('PROJECTS.RESTORE') }}
              </v-list-item>
              <v-list-item :disabled="! isProjectAdmin" :class="isProjectAdmin ? 'text-errorred' : ''" @click="$emit('delete')">
                <i class="far fa-trash-alt" style="width: 24px"></i>{{ $t('PROJECTS.DELETE') }}
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </project-preview>
    <modal-project-sheet v-if="openedProjectSheetProject" :project="openedProjectSheetProject" :readonly="statusInfo.readonly" @close="openedProjectSheetProject = null">
    </modal-project-sheet>
  </router-link>
</template>

<script>
  import ModalProjectSheet from '@/components/ProjectSheet/ModalProjectSheet';
  import HomeProjectsMixin from './HomeProjectsMixin';
  import ProjectPreview from './ProjectPreview';

  import defaultBg from '@/img/view_bg.jpg';

  export default {
    components: {
      ModalProjectSheet,
      ProjectPreview,
    },
    mixins: [HomeProjectsMixin],
    props: {
      project: { required: true, type: Object },
      archived: { type: Boolean, default: false },
      view: { type: Boolean, default: false },
      statusInfo: { type: Object, default: () => ({}) },
    },
    emits: ['duplicate', 'archive', 'restore', 'delete'],
    data() {
      return {
        userConsultOnly: this.$parent.userConsultOnly,
        openedProjectSheetProject: null,
        defaultBg,
      };
    },
    computed: {
      projectStatusConfig() {
        return this.$store.getters['users/accessRight/config'].projectStatus || {};
      },
      otherStatusKeys() {
        return [...Object.keys(this.projectStatusConfig), ''].filter(status => status != (this.project.status || ''));
      },
      notifications() {
        return this.$store.state.notifications.all;
      },
      owner() {
        return this.$store.getters['users/getUserById'](this.project.owner_id) || {};
      },
      owner_username() {
        return this.$store.getters['users/getUsername'](this.owner, 'short');
      },
      projectNotifsCount() {
        return this.notifications.filter(notif => notif.section_type == 'planning' && notif.section_id == this.project.id && ! notif.seen_at).length;
      },
      viewLink() {
        if (this.archived || ! this.view) return '';
        return `/planningview/${this.project.id}/${window.slugify(this.project.title || 'New view')}`;
      },
      projectHistoryLink() {
        return `/analysis/planningversions/${this.project.id}/${window.slugify(this.project.title || this.$t('PLANNING.UNNAMED_PROJECT'))}`;
      },
      isProjectAdmin() {
        return this.project.access_right == 'admin';
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
    methods: {
      updateProjectStatus(projectStatus) {
        setTimeout(() => {
          window.apiSrv.call(`plannings`, 'update', { id: this.project.id, status: projectStatus }).then(() => {
            this.project.status = projectStatus;
          }).catch((message) => {
            this.$store.dispatch('ui/msgbox/open', { title: "Error : Status was not modified", body: message || "" });
          });
        });
      },
    },
  };
</script>
