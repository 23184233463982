<template>
  <v-container class="pa-6">
    <b class="text-primary text-uppercase">{{ $t('SUBPROJECTS.INCLUDE_SUB-PROJECT') }}</b>
    <div class="px-6">
      <div class="small text-medium-emphasis py-1">{{ $t('SUBPROJECTS.DESCRIPTION') }}</div>
      <v-autocomplete v-model="newsubproject" :items="availableProjects" :placeholder="$t('IMPORT_TEMPLATE.SEARCH_PROJECT_TITLE')"
                      :disabled="! availableProjects.length" :loading="! projects.length" item-title="title" item-value="id" return-object>
      </v-autocomplete>
    </div>
    <template v-if="availableProjects.length">
      <div :style="{ opacity: newsubproject ? null : .3, 'pointer-events': newsubproject ? null : 'none' }" class="mt-6">
        <b class="text-primary text-uppercase">{{ $t('GLOBAL.OPTIONS') }}</b>
        <div class="px-6 py-1">
          <field-label :label="$t('EXPORT.LANES')">
            <template #default="{ id }">
              <v-select v-model="selectedLanesList" :id="id" :items="availableLanes" prepend-inner-icon="far fa-th-list"
                        :disabled="! templateContent" :loading="newsubproject && ! templateContent" item-value="id" item-title="label" multiple>
                <template #prepend-item>
                  <filter-toggle :selected-ids="selectedLanesList" :sublist-ids="availableLanesIds"
                                 @update="selectedLanesList = $event">
                    {{ $t('EXPORT.ALL_LANES') }} ({{ availableLanesIds.length }})
                  </filter-toggle>
                  <v-divider></v-divider>
                </template>
                <template #selection="{ item: { raw: item }, index }">
                  <span v-if="selectedLanesList.length == 1">{{ item.label }}</span>
                  <span v-else-if="selectedLanesList.length == availableLanes.length && index === 0">{{ $t('EXPORT.ALL_LANES') }} ({{ availableLanes.length }})</span>
                  <span v-else-if="index === 0">{{ selectedLanesList.length }}/{{ availableLanes.length }}</span>
                </template>
              </v-select>
            </template>
          </field-label>
        </div>
      </div>
      <div class="text-center mt-8">
        <v-btn :disabled="! newsubproject" color="primary" rounded :min-width="180" @click="addNewSubproject()">
          {{ $t('SUBPROJECTS.ADD') }}
        </v-btn>
      </div>
    </template>
    <div v-else-if="projects.length">
      <div class="px-6 mt-2 pre">{{ $t('SUBPROJECTS.NO_PROJECT_AVAILABLE') }}</div>
      <div class="text-center mt-8">
        <v-btn rounded variant="outlined" :min-width="180" @click="close()">
          {{ $t('GLOBAL.CLOSE') }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
  import ProjectSrv from '@/components/Projects/ProjectSrv';

  export default {
    props: {
      planning: { type: Object, default: () => ({}) }, // used to exclude current project from subproject list
    },
    emits: ['close'],
    data() {
      return {
        newsubproject: null,
        projects: [],
        templateContent: null,
        selectedLanesList: [],
        loadingSubproject: false,
      };
    },
    computed: {
      availableProjects() {
        return this.projects.filter(item => item.id != this.planning.id); // exclude current project from subproject list
      },
      availableLanes() {
        if (! this.templateContent) return [];
        return this.templateContent.lanes.map(item => ({ id: item.id, label: item.label }));
      },
      availableLanesIds() {
        return this.availableLanes.map(item => item.id);
      },
    },
    watch: {
      newsubproject(newVal) {
        if (! newVal) return;
        this.templateContent = null;
        this.loadingSubproject = true;
        ProjectSrv.get(newVal.id).then((content) => {
          this.templateContent = content;
        }).finally(() => {
          this.loadingSubproject = false;
        });
      },
      availableLanes() {
        this.selectedLanesList = this.availableLanes.map(item => item.id);
      },
    },
    created() {
      ProjectSrv.list().then((data) => {
        this.projects = data.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
        setTimeout(() => {
          document.getElementById('inputSubproject')?.focus();
        }, 100);
      });
    },
    methods: {
      close() {
        this.$emit('close');
      },
      addNewSubproject() {
        if (! this.templateContent) return;
        const projectHiddenSublanesId = this.availableLanes.filter(item => ! this.selectedLanesList.find(id => id == item.id)).map(lane => lane.id);
        this.$store.dispatch('planning/lanes/addLane').then((lane) => {
          lane.project_hidden_sublanes = projectHiddenSublanesId;
          lane.project_id = this.newsubproject.id;
          lane.label = this.newsubproject.title;
          lane.project_rotoken = this.newsubproject.read_only_token;
          this.$store.dispatch('planning/save');
        });
        this.close();
      },
    },
  };
</script>
