<template>
  <v-list-item>
    <avatar-tooltiped v-if="getUserById(action.data.user_id)" :user="getUserById(action.data.user_id)"></avatar-tooltiped>
    <div :style="! getUserById(action.data.user_id) ? { 'margin-left': '56px' } : {}">
      <v-list-item-title>{{ action.data.title || $t('MEETING.NEW_ACTION') }}</v-list-item-title>
      <v-list-item-subtitle v-if="completed">
        {{ $t('MEETING.VALIDATED_ON') }} {{ $filters.moment(action.data.completed_at, 'mediumDate') }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="action.data.due_date" :class="isLate ? 'text-errorred' : 'text-secondary'">
        {{ $filters.moment(action.data.due_date, 'mediumDate') }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>{{ action.data.category }}</v-list-item-subtitle>
    </div>
    <v-list-item-action>
      <v-btn v-if="completed" icon ripple @click.stop="uncomplete">
        <v-icon color="darkgrey">far fa-check-circle</v-icon>
      </v-btn>
      <v-btn v-else :title="$t('MEETING.MARK_AS_FINISHED')" icon ripple @click.stop="complete">
        <v-icon color="darkgrey">far fa-circle</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  import AvatarTooltiped from './AvatarTooltiped';

  export default {
    components: {
      AvatarTooltiped,
    },
    props: {
      action: { type: Object, default: null },
    },
    emits: ['click-complete', 'click-uncomplete'],
    computed: {
      getUserById() {
        return this.$store.getters['users/getUserById'];
      },
      completed() {
        return !! this.action.data.completed_at;
      },
      isLate() {
        return moment(this.action.data.due_date).format() < moment().format();
      },
    },
    methods: {
      complete() {
        this.$emit('click-complete');
      },
      uncomplete() {
        this.$emit('click-uncomplete');
      },
    },
  };
</script>
