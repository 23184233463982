<template>
  <div>
    <v-card class="views-color-acr d-flex px-4 py-2 mt-2 mx-2">
      <!--      COLOR PICKER-->
      <div>
        <b class="ml-1">{{ $t('CUSTOM_VIEWS.LANES_COLORS') }}</b>
        <div v-for="colorIndex in [0, 1, 2, 3, 4, 5, 6, 7]" :key="colorIndex">
          <div class="d-flex py-2">
            <div :class="`el-color-${colorIndex}-`" :style="{ opacity: laneColorAcr(colorIndex, null) == toggledAccessRight ? '' : '.2' }" class="color-square bg-el-color pointer"
                 @click="selectLaneColor(colorIndex, null)">
            </div>
            <div v-for="shadeIndex in [0, 1, 2, 3]" :class="`el-color-${colorIndex}-${shadeIndex}`"
                 :style="{ opacity: laneColorAcr(colorIndex, shadeIndex) == toggledAccessRight ? '' : '.2' }" class="color-square bg-el-color pointer"
                 @click="selectLaneColor(colorIndex, shadeIndex)">
            </div>
          </div>
        </div>
      </div>
      <v-divider vertical class="mx-2"></v-divider>
      <div class="ml-1">
        <b class="ml-1">{{ $t('CUSTOM_VIEWS.BUBBLES_COLORS') }}</b>
        <div v-for="colorIndex in [0, 1, 2, 3, 4, 5, 6, 7]" :key="colorIndex">
          <div class="d-flex py-2">
            <div :class="`el-color-${colorIndex}-`" :style="{ opacity: elementColorAcr(colorIndex, null) == toggledAccessRight ? '' : '.2' }" class="color-square bg-el-color pointer"
                 @click="selectElementColor(colorIndex, null)">
            </div>
            <div v-for="shadeIndex in [0, 1, 2, 3]" :class="`el-color-${colorIndex}-${shadeIndex}`"
                 :style="{ opacity: elementColorAcr(colorIndex, shadeIndex) == toggledAccessRight ? '' : '.2' }" class="color-square bg-el-color pointer"
                 @click="selectElementColor(colorIndex, shadeIndex)">
            </div>
          </div>
        </div>
      </div>
      <v-divider vertical class="mx-2"></v-divider>
      <div>
        <b class="ml-2">{{ $t('CUSTOM_VIEWS.ICONS') }}</b>
        <div class="mt-1"></div>
        <div :style="{ opacity: elementIconAcr('null') == toggledAccessRight ? '' : '.2' }" class="pointer ml-2 mb-1" style="padding: 3px;"
             @click="selectElementIcon('null')">
          {{ $t('CUSTOM_VIEWS.WITHOUT_ICON') }}
        </div>
        <div style="display:flex; flex-flow: wrap;">
          <div v-for="(iconData, iconCode) in icons" :key="iconCode" :style="{ opacity: elementIconAcr(iconCode) == toggledAccessRight ? '' : '.2' }"
               class="text-center" style="padding: 3px 3px 10px;width:16.666666%; overflow: hidden">
            <v-icon :color="iconData.color" class="pointer" @click="selectElementIcon(iconCode)">{{ iconData.name }}</v-icon>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<style>
  .views-color-acr .color-square {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: 2px;
    border-radius: 50%;
  }
</style>

<script>
  import viewsMixin from './viewsMixin';

  export default {
    mixins: [viewsMixin],
    props: {
      acr: { type: Object, required: true },
      toggledAccessRight: { type: String, default: 'modify' },
    },
    emits: ['update'],
    computed: {
      icons() {
        return this.$store.state.planning.config.icons;
      },
      configIsLrUniversite() {
        return this.$store.getters['users/accessRight/config'].views == 'lrUniversite'; // only colors
      },
    },
    created() {
      this.initAcr();
    },
    updated() {
      this.initAcr();
    },
    methods: {
      initAcr() {
        // LR Université defaults
        if (! this.acr.default) this.acr.default = { access_right: 'none' };
        if (! this.acr.lanes) this.acr.lanes = {};
        if (! this.acr.lanes.colors) this.acr.lanes.colors = {}; // no default : right is defined by acr.lanes.default
        if (! this.acr.lanes.colors.default && this.configIsLrUniversite) this.acr.lanes.colors.default = { access_right: 'modify' }; // has only colors, thus needs a default

        if (! this.acr.elements) this.acr.elements = {};
        if (! this.acr.elements.colors) this.acr.elements.colors = { default: { access_right: 'modify' } };
        if (! this.acr.elements.icons) this.acr.elements.icons = { default: { access_right: 'modify' } };
      },
      selectColor(type, colorIndex, shadeIndex) {
        const colorsAcr = this.acr[type].colors;
        const colorKey = `color${colorIndex}`;
        if (! colorsAcr[colorKey]) colorsAcr[colorKey] = {};
        const selectedColorAcr = colorsAcr[colorKey];
        if (shadeIndex == null) {
          if (selectedColorAcr.access_right == this.toggledAccessRight) {
            delete selectedColorAcr.access_right;
          } else {
            selectedColorAcr.access_right = this.toggledAccessRight;
          }
        } else {
          const shadeKey = `shade${shadeIndex}`;
          if (! selectedColorAcr.shades) selectedColorAcr.shades = {};
          if (! selectedColorAcr.shades[shadeKey]) selectedColorAcr.shades[shadeKey] = {};
          if (selectedColorAcr.shades[shadeKey].access_right == this.toggledAccessRight) {
            delete selectedColorAcr.shades[shadeKey];
          } else {
            selectedColorAcr.shades[shadeKey].access_right = this.toggledAccessRight;
          }
        }
        if (! selectedColorAcr.access_right && _.isEmpty(selectedColorAcr.shades)) delete colorsAcr[colorKey];
        delete colorsAcr.default;
        if (! _.isEmpty(colorsAcr)) colorsAcr.default = { access_right: 'none' };
        if (! colorsAcr.default && this.configIsLrUniversite) colorsAcr.default = { access_right: 'modify' };
      },
      selectLaneColor(colorIndex, shadeIndex) {
        this.selectColor('lanes', colorIndex, shadeIndex);
        this.$emit('update');
      },
      selectElementColor(colorIndex, shadeIndex) {
        this.selectColor('elements', colorIndex, shadeIndex);
        this.$emit('update');
      },
      selectElementIcon(iconCode) {
        const iconsAcr = this.acr.elements.icons;
        if (! iconsAcr[iconCode]) iconsAcr[iconCode] = {};
        const selectedIconAcr = iconsAcr[iconCode];
        if (selectedIconAcr.access_right == this.toggledAccessRight) {
          delete iconsAcr[iconCode];
        } else {
          selectedIconAcr.access_right = this.toggledAccessRight;
        }
        delete iconsAcr.default;
        iconsAcr.default = { access_right: _.isEmpty(iconsAcr) ? 'modify' : 'none' };
        this.$emit('update');
      },
    },
  };
</script>
