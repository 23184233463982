<template>
  <v-toolbar density="compact" extension-height="32" class="toolbar-scrollable mb-4" style="position: relative; z-index: 200">
    <slot name="prepend"></slot>
    <template v-if="filters.includes('Projects')">
      <projects-filter @update="update"></projects-filter>
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-btn size="small" color="secondary" icon class="align-self-start mt-1 ml-1" v-bind="props" @click="reload()">
            <v-icon>far fa-sync-alt</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('MULTIPROJECTS.REFRESH_PROJECTS') }}</span>
      </v-tooltip>
      <slot name="append-projects"></slot>
      <div class="dashboard-filters-spacer"></div>
    </template>
    <template v-if="canAssignGroups && filters.includes('Groups')">
      <v-switch v-model="groupsOptions.useGroups" class="flex-grow-0 mr-3" @update:model-value="update">
        <template #label>
          <small class="nobr" style="line-height: 1.2em">{{ $t('MULTIPROJECTS.GROUP_BY_TEAMS') }}</small>
        </template>
      </v-switch>
    </template>
    <template v-if="canAssignGroups && filters.includes('GroupUsers')">
      <group-users-filter @update="update"></group-users-filter>
      <div class="dashboard-filters-spacer"></div>
    </template>
    <template v-if="filters.includes('Users')">
      <users-filter :use-action-users="useActionUsers" @update="update"></users-filter>
      <div class="dashboard-filters-spacer"></div>
    </template>
    <template v-if="filters.includes('Dates')">
      <div :style="{ flex: smallScreen ? '0 0 120px' : '0 0 170px' }">
        <date-field v-model="selectedDates.starttime" :label="$t('MULTIPROJECTS.START')" :prepend-inner-icon="smallScreen ? null : 'far fa-calendar-alt'" :clearable="! smallScreen" filter
                    @update:model-value="setDates()">
        </date-field>
      </div>
      <div style="flex: 0 1 12px"></div>
      <div :style="{ flex: smallScreen ? '0 0 120px' : '0 0 170px' }">
        <date-field v-model="selectedDates.endtime" :label="$t('MULTIPROJECTS.END')" :prepend-inner-icon="smallScreen ? null : 'far fa-flag-checkered'" :clearable="! smallScreen" filter
                    @update:model-value="setDates()">
        </date-field>
      </div>
      <div v-if="filters.includes('Display')" class="dashboard-filters-spacer"></div>
    </template>
    <slot name="prepend-display"></slot>
    <template v-if="filters.includes('Display')">
      <select-filter v-model="selectedDisplayList"
                     :items="selectedDisplayOptions" :label="$t('MULTIPROJECTS.DISPLAY')"
                     :prepend-inner-icon="smallScreen ? null : 'far fa-eye'" multiple :menu-props="{ location: 'bottom' }"
                     @update:model-value="updateDisplay">
        <template #prepend-item>
          <v-list-item class="d-block">
            <div v-if="canHideElements" class="d-flex mb-n1">
              <v-switch v-model="filterOptions.hide_elements" :label="$t('MULTIPROJECTS.HIDE_ELEMENTS')"
                        @update:model-value="updateDisplay">
              </v-switch>
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <v-icon color="info" class="mt-4 ml-2" v-bind="props">far fa-question-circle</v-icon>
                </template>
                <span>{{ $t('MULTIPROJECTS.DISPLAY_WORKLOAD_PLAN_ONLY') }}</span>
              </v-tooltip>
            </div>
            <v-radio-group v-model="filterOptions.el_type" :disabled="canHideElements && filterOptions.hide_elements" inline class="flex-nowrap mb-3"
                           @update:model-value="update">
              <v-radio :label="$t('MULTIPROJECTS.BUBBLES')" value="task"></v-radio>
              <v-radio :label="$t('MULTIPROJECTS.MILESTONES')" value="milestone"></v-radio>
              <v-radio :label="$t('MULTIPROJECTS.BOTH')" value="both" class="mr-0"></v-radio>
            </v-radio-group>
          </v-list-item>
          <v-divider></v-divider>
        </template>
        <template #selection="{ index }">
          <span v-if="index === 0">{{ selectedDisplayList.length }}/{{ selectedDisplayOptions.length }}</span>
        </template>
      </select-filter>
    </template>
    <slot></slot>
    <template v-if="! hideSaveFilters">
      <div class="dashboard-filters-spacer"></div>
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <div>
            <v-btn color="primary" size="small" icon class="ml-1" v-bind="props" @click="saveFilters">
              <v-icon size="large">{{ filtersSaved ? 'fas fa-check' : 'fas fa-save' }}</v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{ $t('MULTIPROJECTS.SAVE_DEFAULT_FILTERS') }}</span>
      </v-tooltip>
    </template>
    <slot name="append">
      <v-spacer></v-spacer>
    </slot>
  </v-toolbar>
</template>

<style>
  .v-input--radio-group--row.flex-nowrap .v-input--radio-group__input {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .dashboard-filters-spacer {
    flex: 1 0 4px;
    max-width: 32px;
  }
</style>

<script>
  import { mapState, mapActions } from 'vuex';
  import ProjectsFilter from './ProjectsFilter.vue';
  import GroupUsersFilter from './GroupUsersFilter.vue';
  import UsersFilter from './UsersFilter.vue';

  export default {
    components: {
      ProjectsFilter,
      GroupUsersFilter,
      UsersFilter,
    },
    props: {
      filters: { type: Array, default: () => [] },
      useActionUsers: { type: Boolean, default: false },
      hideSaveFilters: { type: Boolean, default: false },
      canHideElements: { type: Boolean, default: false },
    },
    emits: ['update', 'update-display'],
    data() {
      return {
        selectedDisplayOptions: [
          { value: 'projecttitle', title: this.$t('MULTIPROJECTS.PROJECT_TITLE') },
          { value: 'description', title: this.$t('MULTIPROJECTS.DESCRIPTION') },
          { value: 'checklist', title: this.$t('MULTIPROJECTS.CHECKLIST') },
          { value: 'progress', title: this.$t('MULTIPROJECTS.PROGRESS') },
          { value: 'users', title: this.$t('MULTIPROJECTS.PARTICIPANTS') },
          { value: 'dates', title: this.$t('MULTIPROJECTS.DATES') },
          { value: 'links', title: this.$t('MULTIPROJECTS.LINKS') },
          { value: 'budgets', title: this.$t('MULTIPROJECTS.BUDGET') },
        ],
        filtersSaved: false,
      };
    },
    computed: {
      selectedDisplayList: {
        get() {
          return Object.keys(this.selectedDisplay).filter(item => this.selectedDisplay[item]);
        },
        set(newVal) {
          Object.keys(this.selectedDisplay).forEach((item) => {
            this.selectedDisplay[item] = newVal.includes(item);
          });
        },
      },
      smallScreen() { return this.$vuetify.display.mdAndDown; },
      canAssignGroups() { return this.$store.state.users.accessRight.canAssignGroups; },
      ...mapState('multiprojects', ['selectedDates', 'selectedDisplay', 'filterOptions', 'groupsOptions']),
    },
    methods: {
      update: _.debounce(function () {
        this.$emit('update');
      }, 1000),
      updateDisplay: _.debounce(function () {
        this.$emit('update-display');
      }, 1000),
      setDates() {
        this.update();
      },
      saveFilters() {
        const dashboardName = this.$route.path.slice(1).replace(/\//g, '_');
        this.$store.commit('multiprojects/saveFilters', dashboardName);
        this.filtersSaved = true;
        setTimeout(() => { this.filtersSaved = false; }, 5000);
      },
      ...mapActions('multiprojects', ['reload']),
    },
  };
</script>
