<!-- eslint-disable vuetify/no-deprecated-components -->
<template>
  <div>
    <navigation></navigation>

    <template v-if="isBusiness">
      <v-progress-linear v-if="! loading.complete" :model-value="loading.counter.toLoad && loading.counter.loaded ? loading.counter.loaded / loading.counter.toLoad * 100 : 2"
                         color="accent" rounded>
      </v-progress-linear>

      <personal-calendar-filters v-if="loading.complete" @update="updateRange()"></personal-calendar-filters>

      <v-card v-if="loading.complete" v-show="viewIsReady" id="personal-calendar" class="bg-white px-2 py-1">
        <personal-calendar-add-button :date="startOfMonthDateString"
                                      @open-action="openedAction = $event" @open-holiday="openedHoliday = $event">
        </personal-calendar-add-button>
        <!-- CALENDAR BODY -->
        <v-sheet>
          <v-calendar ref="calendar" v-model="focus" color="primary" :events="eventsList" :event-color="event => event.color"
                      :view-mode="calendarFormat" :event-height="34" :first-interval="dayStartTime" :interval-count="dayHours" :locale="locale"
                      :text="$t('PERSONAL_CALENDAR.TODAY')"
                      @change="updateRange">
            <template #event="{ event }">
              <div :title="event.title" :class="event.type == 'holiday' ? 'bg-warningorange pa-1 justify-center' : null" class="d-flex align-center pointer mb-notlastchild-2"
                   @click="showEvent(event)">
                <v-icon v-if="event.icon" :color="event.icon.color" :title="event.icon.label" size="small" class="mx-1"
                        @click="checkAction(event) && $event.stopPropagation()">
                  {{ event.icon.name }}
                </v-icon>
                <planning-element-view v-if="event.el" :el="event.el" :show-details-items="['synthesis']"
                                       disable-mini-element style="position: static; color: #333; flex-grow: 1">
                </planning-element-view>
                <div v-else :class="event.labelClass" class="pl-1 line-height-normal">
                  {{ event.name }}
                </div>
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-card>
      <div v-if="loading.complete && ! viewIsReady" class="text-center pa-4" style="font-size: 18px">
        <i class="far fa-spinner fa-spin fa-2x fa-fw"></i>
      </div>

      <!-- MODALS -->
      <dashboards-element-modal></dashboards-element-modal>
      <modal v-if="openedAction" card @close="closeOpenedAction">
        <open-action :action="openedAction" @open-element="closeOpenedAction(); elementClick($event)" @close="closeOpenedAction"></open-action>
      </modal>
      <modal v-if="openedHoliday" @close="closeOpenedHoliday">
        <personal-calendar-holidays :holiday="openedHoliday" @close="closeOpenedHoliday"></personal-calendar-holidays>
      </modal>
    </template>

    <div v-if="userLoaded && ! isBusiness">{{ $t('PREMIUM.BUSINESS.DASHBOARD_IS_BUSINESS') }}</div>
  </div>
</template>

<style>
  #personal-calendar .element .task-icon, #personal-calendar .element .milestone-icon {
   display: none;
  }

  #personal-calendar .v-calendar-weekly__day-label, #personal-calendar .v-calendar-weekly__day-label * {
    cursor: unset;
  }

  .mb-notlastchild-2:not(:last-child) {
    margin-bottom: 8px;
  }
</style>

<script>
  import { useDate } from 'vuetify';
  import { mapState } from 'vuex';
  import { VCalendar } from 'vuetify/labs/VCalendar';
  import Navigation from '@/components/Navigation/Navigation.vue';
  import PlanningElementView from '@/components/Planning/PlanningElementView.vue';
  import DashboardsElementModal from '../partials/DashboardsElementModal.vue';
  import OpenAction from '../Actions/OpenAction.vue';
  import PersonalCalendarFilters from './PersonalCalendarFilters.vue';
  import PersonalCalendarHolidays from './PersonalCalendarHolidays.vue';
  import PersonalCalendarAddButton from './PersonalCalendarAddButton.vue';

  export default {
    components: {
      VCalendar,
      Navigation,
      PlanningElementView,
      DashboardsElementModal,
      OpenAction,
      PersonalCalendarFilters,
      PersonalCalendarHolidays,
      PersonalCalendarAddButton,
    },

    data() {
      return {
        focus: [moment()],
        calendarFormat: 'month',
        events: [],
        selectedOpen: false,
        selectedEvent: {},
        viewIsReady: false,
        openedAction: null,
        openedHoliday: null,
        holidayIcons: _.indexBy(this.$store.getters['holidays/icons'], 'id'),
        selectedDay: '',
        dayDefaultStartTime: 8, // day start is 08:00am
        dayDefaultEndTime: 18, // day end is 18:00pm
        defaultDayHours: 10,
        // frenchHolidays: [],
      };
    },
    computed: {
      startOfMonthDateString() {
        return moment(this.focus[0]).startOf('month').format();
      },
      // elements of the day
      dayElements() {
        if (! this.selectedDay) return [];
        const els = this.allPlanningElements.filter((el) => {
          const elStartTime = el.getStartTime();
          const elEndTime = el.getEndTime();
          if (elStartTime.format('YYYY-MM-DD') !== this.selectedDay) {
            if (! (elStartTime.isBefore(moment(this.selectedDay)) && elEndTime.isAfter(moment(this.selectedDay)))) {
              return false;
            }
          }
          return true;
        });
        return els;
      },
      minStartTime() {
        const elements = this.dayElements.filter(el => this.selectedDay == el.getStartTime().format('YYYY-MM-DD'));
        const elsScheduleStart = elements.filter((el) => {
          if (! el.getSchedule().start) return false;
          return true;
        });
        const elsScheduleStartMoments = elsScheduleStart.map(el => el.getSchedule().start);
        const minStartTime = moment.min(elsScheduleStartMoments).hour();
        return minStartTime;
      },
      maxEndTime() {
        const elements = this.dayElements.filter(el => this.selectedDay == el.getEndTime().format('YYYY-MM-DD'));
        const elsScheduleEnd = elements.filter((el) => {
          if (! el.getSchedule().end) return false;
          return true;
        });
        const elsScheduleEndMoments = elsScheduleEnd.map(el => el.getSchedule().end);
        const maxEndTime = moment.max(elsScheduleEndMoments).hour();
        return maxEndTime;
      },
      // Selected day start time
      dayStartTime() {
        if (! this.dayElements.length) return 0;
        if (this.minStartTime < this.dayDefaultStartTime) return this.minStartTime; // if hour of an element less than 08:00am
        return this.dayDefaultStartTime; // 08:00am
      },
      // Selected day hours
      dayHours() {
        if (! this.dayElements.length) return null;
        const elsScheduleStart = this.dayElements.filter((el) => {
          if (! el.getSchedule().start) return false;
          return true;
        });
        if (! elsScheduleStart.length) return null; // if no elements has a start hour

        let dayHours = this.defaultDayHours;
        if (this.dayStartTime < this.dayDefaultStartTime && this.maxEndTime > this.dayDefaultEndTime) { // if hour of an element less than 8:00am and more than 6:00pm
          const diffStartTime = this.dayDefaultStartTime - this.dayStartTime;
          const diffEndTime = this.maxEndTime - this.dayDefaultEndTime;
          dayHours += (diffStartTime + diffEndTime);
        } else if (this.dayStartTime < this.dayDefaultStartTime) { // if hour of an element less than 8:00am
          const diffStartTime = this.dayDefaultStartTime - this.dayStartTime;
          dayHours += diffStartTime;
        } else if (this.maxEndTime > this.dayDefaultEndTime) { // if hour of an element more than 6:00pm
          const diffEndTime = this.maxEndTime - this.dayDefaultEndTime;
          dayHours += diffEndTime;
        }
        return dayHours;
      },
      // selected plannings elements
      allPlanningElements() {
        const elements = [];
        const selectedProjectsSet = new Set(this.selectedProjects);
        this.projects.forEach((projectData) => {
          if (! projectData.elements || ! selectedProjectsSet.has(projectData.id)) return;
          projectData.elements.forEach((el) => {
            if (! el.hasDates()) return;

            // Users Filter
            if (! this.useGroups) {
              if (! this.filterOptions.unassigned) {
                if (! el.getUsers()) return;
                const elUsers = el.getUsers().map(user => user.id || user.username);
                if (! elUsers.includes(this.selectedUser)) return;
              } else if (el.getUsers() !== null) {
                const elUsers = el.getUsers().map(user => user.id || user.username);
                if (! elUsers.includes(this.selectedUser)) return;
              }
            } else { // GroupUsers Filter
              if (! el.getUsers()) return;
              const elGroups = el.getUsers().map(group => group.group_id);
              if (! elGroups.includes(this.selectedGroup)) return;
            }
            // Display Filter
            if (! this.calendarDisplay.milestone && el.getType() === "milestone") return;
            if (! this.calendarDisplay.shorttask && el.getType() === "task") {
              if (el.getEndTime().diff(el.getStartTime(), 'days') <= 3) return;
            }
            if (! this.calendarDisplay.longtask && el.getType() === "task") {
              if (el.getEndTime().diff(el.getStartTime(), 'days') > 3) return;
            }

            elements.push(el);
          });
        });
        return elements;
      },
      // actions from selected plannings
      planningActions() {
        return this.$store.getters['multiprojects/planningActions'].filter((action) => {
          // Display Filter
          if (! this.calendarDisplay.planningactions) return false;
          // Users Filter
          if (! this.useGroups) {
            if (! this.filterOptions.unassigned) {
              if (! action.getUserId()) return false;
              if (action.getUserId() !== this.selectedUser) return false;
            } else if (action.getGroupId() || action.getUserId() && (action.getUserId() !== this.selectedUser)) {
              return false;
            }
          } else { // GroupUsers Filter
            if (! action.getGroupId()) return false;
            if (action.getGroupId() !== this.selectedGroup) return false;
          }

          return this.selectedProjects.includes(action.getPlanningId());
        });
      },
      // actions from meetings
      meetingActions() {
        return this.$store.state.multiprojects.meetingActions.filter((action) => {
          // GroupUsers Filter
          if (this.useGroups) return false;
          // Display Filter
          if (! this.calendarDisplay.meetingactions) return false;
          // Users Filter
          if (! this.filterOptions.unassigned) {
            if (! action.getUserId()) return false;
            if (action.getUserId() !== this.selectedUser) return false;
          } else if (action.getUserId() && (action.getUserId() !== this.selectedUser)) {
            return false;
          }
          return true;
        });
      },
      allHolidays() {
        return this.$store.state.holidays.byUser[this.selectedUser] || [];
      },
      selectedUser() {
        return this.selectedCompanyUsers.concat(this.selectedVirtualParticipants)[0];
      },
      selectedGroup() {
        return this.selectedCompanyGroups[0];
      },
      useGroups() {
        return this.canAssignGroups && !! (this.groupsOptions.useGroups && this.companyGroups);
      },
      companyGroups() {
        return this.$store.getters['users/groups/getCompanyGroups'];
      },
      allActions() {
        return this.meetingActions.concat(this.planningActions);
      },
      locale() {
        this.updateRange();
        return this.$store.state.lang.lang;
      },
      eventsList() { return this.events; },
      userLoaded() { return this.$store.state.users.user.id > 0; },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
      canAssignGroups() { return this.$store.state.users.accessRight.canAssignGroups; },
      ...mapState('multiprojects', ['loading', 'projects', 'selectedProjects', 'filterOptions', 'selectedCompanyUsers', 'companyUsers',
                                    'selectedVirtualParticipants', 'selectedCompanyGroups', 'groupsOptions', 'calendarDisplay']),
    },
    watch: {
      'loading.complete': function (newVal) {
        this.viewIsReady = !! newVal;
      },
    },
    created() {
      this.loading.complete = false;

      this.$store.state.users.userPromise.then((user) => {
        this.$store.commit('multiprojects/loadFilters', {
          dashboardName: this.$route.path.slice(1).replace(/\//g, '_'),
          groupsOptions: { useGroups: false },
          selectedCompanyUsers: [user.id],
        });
        Promise.all([this.$store.dispatch('multiprojects/load'), this.$store.dispatch('multiprojects/loadMeetingActions')]);
      });
      // French holidays (20 years in the past and 5 years in the future)
      // this.getFrenchHolidays();

      // Load holidays datas
      this.$store.dispatch('holidays/load');
    },
    mounted() {
      if (this.$refs.calendar) this.$refs.calendar.checkChange();
    },
    methods: {
      // async getFrenchHolidays() {
      //   try {
      //     const res = await fetch("https://calendrier.api.gouv.fr/jours-feries/metropole.json");
      //     if (! res.ok) throw new Error(res.status);
      //     const response = await res.json();

      //     Object.entries(response).forEach(([date, dayName]) => {
      //       this.frenchHolidays.push({
      //         id: `${window.uuid()}.${window.uuid()}`,
      //         type: 'publicholiday',
      //         name: dayName,
      //         icon: { color: 'white', label: 'Action', name: 'far fa-store-slash' },
      //         start: date,
      //         end: date,
      //         color: '#5B8930',
      //         title: 'Jour férié',
      //       });
      //     });
      //   } catch (error) {
      //     // console.log(error);
      //   }
      // },
      showEvent(event) {
        const open = () => {
          this.selectedEvent = event;
          if (this.selectedEvent.type === 'action') {
            const action = this.allActions.find(item => item.id === this.selectedEvent.id);
            if (action) this.openedAction = action;
          } else if (this.selectedEvent.type === 'holiday') {
            const holiday = this.allHolidays.find(item => item.id === this.selectedEvent.id);
            if (holiday) this.openedHoliday = holiday;
          } else if (this.selectedEvent.type === 'element') {
            const el = this.allPlanningElements.find(item => item.id === this.selectedEvent.id);
            this.elementClick(el);
          }
          setTimeout(() => { this.selectedOpen = true; }, 10);
        };
        if (this.selectedOpen) {
          this.selectedOpen = false;
          setTimeout(open, 10);
        } else {
          open();
        }
      },
      closeOpenedAction(reason) {
        if (reason == 'save') {
          // todo : handle case when save failed
          if (this.openedAction.type == 'meeting' && ! this.$store.state.multiprojects.meetingActions.includes(this.openedAction)) {
            this.$store.state.multiprojects.meetingActions.push(this.openedAction);
          }
        } else if (reason == 'delete') {
          // todo : handle case when delete failed
          if (this.openedAction.type == 'meeting') {
            const index = this.$store.state.multiprojects.meetingActions.findIndex(item => item == this.openedAction);
            if (index > -1) this.$store.state.multiprojects.meetingActions.splice(index, 1);
          }
        } else {
          this.openedAction.reset();
        }
        this.openedAction = null;
      },
      closeOpenedHoliday(reason, holidayInfo) {
        const originalAllHolidays = this.allHolidays.slice();
        if (reason == 'save') {
          const index = this.allHolidays.indexOf(this.openedHoliday);
          if (index > -1) {
            this.allHolidays.splice(index, 1, holidayInfo);
          } else {
            this.allHolidays.push(holidayInfo);
          }
        } else if (reason == 'delete') {
          const index = this.allHolidays.indexOf(this.openedHoliday);
          if (index > -1) this.allHolidays.splice(index, 1);
        }
        if (reason) {
          const userId = this.selectedUser;
          this.$store.commit('holidays/set', { userId, holidays: this.allHolidays });
          this.$store.dispatch('holidays/save', userId).catch(() => {
            this.$store.commit('holidays/set', { userId, holidays: originalAllHolidays });
          });
        }
        this.openedHoliday = null;
      },
      elementClick(el) {
        this.$store.dispatch('ui/dashboards/openElementDetails', el);
      },
      checkAction(event) {
        if (event.type != 'action') return false;
        const { action } = event;
        action.setChecked(! action.getChecked());
        action.save();
        event.update();
        return true;
      },
      updateRange() {
        const events = [];
        // All elements
        this.allPlanningElements.forEach((el) => {
          const elStartTime = el.getStartTime();
          const elEndTime = el.getEndTime();

          let elStart = elStartTime.format('YYYY-MM-DD');
          let elEnd = elEndTime.format('YYYY-MM-DD');
          const schedule = el.getSchedule();
          if (schedule.start) {
            elStart = elStartTime.hours(schedule.start.hours()).minutes(schedule.start.minutes()).format('YYYY-MM-DD HH:mm');
            if (schedule.end) {
              elEnd = elEndTime.hours(schedule.end.hours()).minutes(schedule.end.minutes()).format('YYYY-MM-DD HH:mm');
            } else {
              elEnd = elEndTime.format('YYYY-MM-DD HH:mm');
            }
          }
          events.push({
            el,
            id: el.id,
            type: 'element',
            name: el.getTitle() || this.$t('PLANNING.NEW_BUBBLE'),
            icon: el.hasIcon() && el.getIcon() || null,
            start: elStart,
            end: elEnd,
            color: el.getType() === 'task' ? `${el.getSecondaryColor()}99` : '',
            title: el.getPlanning().getTitle(),
          });
        });
        // All actions
        this.allActions.forEach((action) => {
          if (! action.getEndTime()) return;
          const event = {
            action,
            id: action.id,
            type: 'action',
            name: action.getTitle() || this.$t('MEETING.NEW_ACTION'),
            color: "#00000022",
            title: action.getElement() ? `${action.getElement().getPlanning()?.getTitle() || ''} > ${action.getElement().getTitle()}` : null,
            update() {
              const endTime = action.getEndTime();
              if (! endTime) {
                // remove from events
                const index = events.findIndex(item => item.id === this.id);
                if (index > -1) events.splice(index, 1);
              }
              const iconColor = action.getColor();
              this.icon = { color: iconColor, label: 'Action', name: action.getChecked() ? 'far fa-check-square' : 'far fa-square' };
              this.start = endTime.format('YYYY-MM-DD');
              this.end = endTime.format('YYYY-MM-DD');
              this.labelClass = `text-${iconColor}`;
            },
          };
          event.update();

          events.push(event);
        });
        this.allHolidays.forEach((holiday) => {
          events.push({
            id: holiday.id,
            type: 'holiday',
            name: holiday.title + (holiday.occupancy && holiday.occupancy != 1 ? ` (${holiday.occupancy * 100}%)` : ''),
            icon: this.holidayIcons[holiday.icon] || this.holidayIcons.holiday,
            start: moment(holiday.start).format('YYYY-MM-DD'),
            end: moment(holiday.end).format('YYYY-MM-DD'),
            color: '#FDB813',
            labelClass: 'text-white',
          });
        });
        // this.frenchHolidays.forEach((holiday) => {});
        this.events = events;
      },
    },
  };
</script>
