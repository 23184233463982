import WorkingView from '@/models/WorkingView';

export default {
  namespaced: true,
  state: {
    currentPlanningId: null,
    currentPlanningWorkingViews: null,
    activeWorkingViewByPlanning: window.safeParseJSON(window.localStorageWrapper.getItem('activeWorkingViewByPlanning')) || {},
  },
  getters: {
    getActiveWorkingView(state) {
      const viewId = state.activeWorkingViewByPlanning[state.currentPlanningId];
      return viewId && state.currentPlanningWorkingViews?.find(item => item.id == viewId) || null;
    },
    planningHasActiveWorkingView: () => (planning) => {
      return !! planning.workingView;
    },
  },
  mutations: {
    reset(state) {
      state.currentPlanningId = null;
      state.currentPlanningWorkingViews = null;
    },
    sort(state) {
      state.currentPlanningWorkingViews.sort((a, b) => (a.title < b.title ? -1 : 1));
    },
    setActiveWorkingView(state, view) {
      if (view?.id) {
        state.activeWorkingViewByPlanning[view.planning_id] = view.id;
      } else {
        delete state.activeWorkingViewByPlanning[state.currentPlanningId];
      }
      window.localStorageWrapper.setItem('activeWorkingViewByPlanning', JSON.stringify(state.activeWorkingViewByPlanning));
    },
  },
  actions: {
    loadPlanningViews({ state }, planningId) {
      return window.apiSrv.call(`plannings/${planningId}/views`, 'index').then((response) => {
        state.currentPlanningId = planningId;
        state.currentPlanningWorkingViews = (response?.data?.views || []).filter(a => a.config).sort((a, b) => (a.title < b.title ? -1 : 1)).map(viewData => new WorkingView(viewData));
      });
    },
    updatePlanning({ commit, dispatch, rootState, rootGetters }, { planning, view }) {
      if (view) { // if saved or new view
        planning.applyWorkingView(view);
        if (rootState.subprojects.promises.length) {
          Promise.all(rootState.subprojects.promises).then(() => {
            if (planning.workingView != view) return;
            rootGetters['subprojects/getAll'].forEach((subproject) => {
              subproject.applyWorkingView(view, { isSubproject: true });
            });
            dispatch('planning/timeline/updateTimeline', planning.timeline, { root: true });
          });
        }
      } else {
        planning.applyLastSavedProjectVersion();
        rootGetters['subprojects/getAll'].forEach((subproject) => {
          subproject.applyLastSavedProjectVersion({ isSubproject: true });
        });
      }
      dispatch('planning/timeline/updateTimeline', planning.timeline, { root: true });
      commit('setActiveWorkingView', view);
    },
  },
};
