<template>
  <div>
    <div class="d-flex align-center mb-2">
      <v-icon class="mr-2" style="flex: 0 0 24px">far fa-user</v-icon>
      <b>{{ $t('PROJECT_SHEET.USERS') }}</b>
    </div>
    <v-card class="pa-6">
      <!-- Users -->
      <div v-for="(user, id) in projectUsers" :key="id" class="vcenter mr-2 mb-2 py-1 px-2 bg-background rounded hovervisible-activator" style="position: relative">
        <user-avatar :user="user" show-username class="mr-2"></user-avatar>

        <v-btn v-if="isBusiness" :title="$t('PROJECT_SHEET.REPLACE_USER_IN_PROJECT')" icon size="small" density="comfortable" color="primary"
               class="hovervisible-item" style="position: absolute; right: -4px"
               @click="userToReplace = user">
          <v-icon>far fa-exchange</v-icon>
        </v-btn>
      </div>

      <!-- Replace user -->
      <div v-if="isBusiness && userToReplace" ref="replaceuser" class="mt-2 export-hidden">
        <div class="d-flex justify-space-between align-center">
          <b>{{ $t('PROJECT_SHEET.REPLACEMENT_USERS') }}</b>
          <v-btn-icon size="small" @click="userToReplace = null">
            <v-icon size="large">far fa-times</v-icon>
          </v-btn-icon>
        </div>
        <div>
          <span class="small text-medium-emphasis">{{ $t('PROJECT_SHEET.REPLACE_USER_DESC') }}</span>
          <div class="d-flex align-center mt-2">
            <v-icon style="flex: 0 0 16px; margin-right: 9px">far fa-user</v-icon>
            <span style="flex: 0 1 211px">{{ $t('MANAGE.CONFIG.REPLACE_USER.REPLACE') }}</span>
            <div class="py-1 px-2 bg-background rounded">
              <template v-if="userToReplace.id">
                <img v-if="getUserById(userToReplace.id) && userToReplace.avatar" :src="userToReplace.avatar" class="rounded-circle" style="max-height: 20px">
                <v-icon v-else :title="$t('MULTIPROJECTS.DELETED_USER', 1)" size="small" class="color-inherit">far fa-user-slash</v-icon>
              </template>
              <template v-else-if="userToReplace.group_id">
                <v-icon v-if="getGroupById(userToReplace.group_id)" size="small" class="color-inherit">far fa-users</v-icon>
                <v-icon v-else :title="$t('MULTIPROJECTS.DELETED_GROUP')" size="small" class="color-inherit">far fa-users-slash</v-icon>
              </template>
              <v-icon v-else size="small" class="color-inherit">far fa-user</v-icon>
              <span class="ml-1">{{ userToReplace.group_id ? userToReplace.title : $filters.username(userToReplace) }}</span>
            </div>
          </div>

          <div class="mt-1 d-flex align-center">
            <v-select v-model="replaceBy.type" :items="replaceOptions" :label="$t('MANAGE.CONFIG.REPLACE_USER.BY')" prepend-icon="far fa-arrow-right"
                      class="mr-4" style="max-width: 220px">
            </v-select>
            <v-text-field v-if="replaceBy.type == 'username'" v-model="replaceBy.username" autocomplete="nope">
            </v-text-field>
            <v-select v-else-if="replaceBy.type == 'user'" v-model="replaceBy.user" :items="organizationUsers.filter(item => item.id != userToReplace.id)"
                      :item-title="$filters.username" item-value="id" :menu-props="{ location: 'bottom' }">
            </v-select>
            <v-select v-else-if="replaceBy.type == 'group'" v-model="replaceBy.group" :items="companyGroups.filter(item => item.id != userToReplace.group_id)"
                      item-title="title" item-value="id" :menu-props="{ location: 'bottom' }">
            </v-select>
          </div>

          <div class="text-center mt-4">
            <v-btn :disabled="! btnEnabled" :loading="replaceUserInProgress" rounded size="large" class="mx-2" color="primary" @click="replaceUser">
              {{ $t('MANAGE.CONFIG.REPLACE_USER.REPLACE_IN_PROJECTS', 1) }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    props: {
      planning: { type: Object, required: true },
    },
    emits: ['reload-planning'],
    data() {
      return {
        userToReplace: null,
        replaceBy: {
          type: 'user',
          user: null,
          group: null,
          username: '',
        },
        replaceOptions: [
          { value: 'username', title: this.$t('MANAGE.CONFIG.REPLACE_USER.VIRTUAL_PARTICIPANT', 1) },
          { value: 'user', title: this.$t('MANAGE.CONFIG.REPLACE_USER.REGISTERED_USER') },
          { value: 'group', title: this.$t('GLOBAL.GROUP') },
          { value: 'remove', title: this.$t('MANAGE.CONFIG.REPLACE_USER.REMOVE_USER') },
        ],
        replaceUserInProgress: false,
      };
    },
    computed: {
      projectUsers() {
        const users = {};
        this.planning.elements.forEach((item) => {
          if (! item.getUsers()) return;
          item.getUsers().forEach((user) => {
            const id = user.id || user.group_id || user.username || user.email;
            users[id] = user;
          });
        });
        return users;
      },
      getUserById() {
        return this.$store.getters['users/getUserById'];
      },
      getGroupById() {
        return this.$store.getters['users/groups/getGroupById'];
      },
      organizationUsers() {
        return this.$store.getters['users/getOrganizationUsers'];
      },
      companyGroups() {
        return this.$store.getters['users/groups/getCompanyGroups'];
      },
      btnEnabled() {
        return this.userToReplace && (this.replaceBy.type == 'remove' || this.replaceBy[this.replaceBy.type]);
      },
      isBusiness() { return this.$store.state.users.accessRight.isBusiness; },
    },
    watch: {
      userToReplace(newVal) {
        if (newVal) this.$nextTick(() => { this.$refs.replaceuser?.scrollIntoView({ behavior: "smooth" }); });
      },
    },
    methods: {
      replaceUser() {
        if (! this.btnEnabled) return; // anti html hack
        this.replaceUserInProgress = true;
        window.apiSrv.call('plannings/tasks/massreplaceusers', 'store', {
          planning_ids: [this.planning.id],
          replace_type: this.userToReplace.id ? 'user' : (this.userToReplace.group_id ? 'group' : 'username'),
          replace_user_id: this.userToReplace.id || this.userToReplace.group_id || this.userToReplace.username,
          replace_by_type: this.replaceBy.type,
          replace_by_user_id: this.replaceBy[this.replaceBy.type],
        }).then(() => {
          this.userToReplace = null;
          this.replaceBy.user = null;
          this.replaceBy.group = null;
          this.replaceBy.username = '';

          this.$emit('reload-planning'); // reload and update planning according to context (inside or outside of planning view)
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: this.$t('PROJECT_SHEET.REPLACE_USER_ERROR_MESSAGE'), body: message || "" });
        }).then(() => {
          this.replaceUserInProgress = false;
        });
      },
    },
  };
</script>
