<template>
  <v-toolbar style="z-index: 200">
    <!-- Saved Versions -->
    <template v-if="savedProjectVersions.length">
      <autocomplete-filter v-model="selectedSavedVersion" :items="savedProjectVersions" :item-title="getItemText" item-value="id"
                           :label="$t('PLANNING_VERSIONS.MY_SAVED_VERSIONS')" :placeholder="$t('PLANNING_VERSIONS.SEARCH_VERSION')"
                           clearable return-object class="ml-2" style="max-width: 300px"
                           @update:model-value="selectSavedVersion">
      </autocomplete-filter>
      <v-spacer></v-spacer>
    </template>

    <!-- Date & Time Pickers -->
    <div v-if="! savedProjectVersions.length && ! selectedDate" class="small text-successgreen mr-2">
      {{ $t('PLANNING_VERSIONS.SELECT_VERSION') }}
    </div>
    <date-field v-model="selectedDate" :allowed-dates="allowedDates" :event-dates="savedProjectVersionsDates"
                :placeholder="$t('ELEMENT-DETAIL.DATE')" :label="selectedDate && $t('ELEMENT-DETAIL.DATE')" :min="minDate" :max="maxDate"
                readonly prepend-inner-icon="far fa-calendar-alt" clearable filter class="mr-2" style="max-width: 180px"
                @update:model-value="selectDate()">
    </date-field>
    <div>
      <select-filter v-if="projectVersionSchedules.length > 1" v-model="selectedSchedule" :items="projectVersionSchedules"
                     :label="$t('ELEMENT-DETAIL.SCHEDULE_AT')"
                     @update:model-value="selectSchedule()">
      </select-filter>
    </div>
    <v-spacer></v-spacer>

    <!-- Edit Version -->
    <div class="d-flex align-center">
      <div class="small text-medium-emphasis">{{ $t('PLANNING_VERSIONS.SAVE_VERSION') }}</div>
      <v-tooltip location="left" max-width="250">
        <template #activator="{ props }">
          <v-icon class="mx-2 mt-1" color="info" v-bind="props">far fa-question-circle</v-icon>
        </template>
        <span>{{ $t('PLANNING_VERSIONS.SAVED_VERSIONS_EXPLANATION') }}</span>
      </v-tooltip>
      <div class="pr-2" style="width: 250px">
        <v-text-field v-model="projectVersionName" :disabled="! selectedDate" :placeholder="$t('PLANNING_VERSIONS.VERSION_NAME')"
                      density="compact">
        </v-text-field>
      </div>
      <v-btn :disabled="! (initialProject && initialProject.version_name)" class="mr-2" color="primary" rounded
             @click="$emit('update-project-version')">
        {{ $t('PLANNING_VERSIONS.SAVE') }}
      </v-btn>
      <v-btn :disabled="! (initialProject && initialProject.version_name)"
             class="text-center" :title="$t('PLANNING_VERSIONS.DELETE_VERSION')" icon size="small" variant="outlined" color="errorred"
             @click="$emit('remove-project-version')">
        <v-icon>far fa-trash-alt</v-icon>
      </v-btn>
    </div>

    <!-- Restore Version -->
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <v-btn :disabled="! selectedDate || initialProject && initialProject.isLocked" class="mr-2" rounded
           @click="$emit('restore-project-version')">
      {{ $t('PLANNING_VERSIONS.RESTORE') }}
    </v-btn>
  </v-toolbar>
</template>

<script>
  export default {
    props: {
      projectVersionsList: { type: Array, default: () => [] },
      initialProject: { type: Object, default: null },
    },
    emits: ['update-project-version', 'remove-project-version', 'restore-project-version', 'update-display'],
    data() {
      return {
        selectedDate: null,
        selectedSchedule: null,
        selectedSavedVersion: null,
      };
    },
    computed: {
      projectVersionName: {
        get() {
          return this.selectedSavedVersion?.version_name || null;
        },
        set(newVal) {
          this.selectedSavedVersion.version_name = newVal;
        },
      },
      projectVersionsDates() {
        return new Set(this.projectVersionsDatesMoments.map(dateMoment => dateMoment.format('YYYY-MM-DD')));
      },
      projectVersionSchedules() {
        const versionsInDay = this.projectVersionsDatesMoments.filter(item => item.format('YYYY-MM-DD') == this.selectedDate?.format('YYYY-MM-DD'));
        return _.uniq(versionsInDay.map(version => version.format('HH:mm')));
      },
      projectVersionsDatesMoments() {
        const versions = this.projectVersionsList.filter(version => version.date_of_modification);
        return versions.map(item => moment(item.date_of_modification));
      },
      minDate() {
        return moment.min(this.projectVersionsDatesMoments).format('YYYY-MM-DD');
      },
      maxDate() {
        return moment.max(this.projectVersionsDatesMoments).format('YYYY-MM-DD');
      },
      savedProjectVersions() {
        const savedVersions = this.projectVersionsList.filter(version => version.version_name && ! version.isLocked);
        return savedVersions.sort((a, b) => moment(b?.date_of_modification) - moment(a?.date_of_modification));
      },
      savedProjectVersionsDates() {
        return this.savedProjectVersions.filter(version => version.date_of_modification).map(item => moment(item.date_of_modification).format('YYYY-MM-DD'));
      },
    },
    watch: {
      selectedDate(newVal) {
        if (newVal) {
          this.selectedSchedule = this.initialProject ? moment(this.initialProject.date_of_modification).format('HH:mm') : null;
          this.selectedSavedVersion = this.initialProject;
        } else {
          this.selectedSchedule = null;
          this.selectedSavedVersion = null;
        }
      },
      selectedSchedule(newVal) {
        if (newVal) {
          this.selectedSavedVersion = this.initialProject;
        } else {
          this.selectedSavedVersion = null;
        }
      },
    },
    methods: {
      allowedDates(val) {
        return this.projectVersionsDates.has(val.format('YYYY-MM-DD'));
      },
      selectDate() {
        const versionInfo = { date: this.selectedDate?.format('YYYY-MM-DD') };
        this.updateDisplay(versionInfo);
      },
      selectSchedule() {
        const versionInfo = { date: this.selectedDate?.format('YYYY-MM-DD'), schedule: this.selectedSchedule };
        this.updateDisplay(versionInfo);
      },
      selectSavedVersion() {
        const savedVersionDate = this.selectedSavedVersion ? moment(this.selectedSavedVersion.date_of_modification) : null;
        this.selectedDate = savedVersionDate; // to update date field
        const versionInfo = { date: savedVersionDate?.format('YYYY-MM-DD'), schedule: savedVersionDate?.format('HH:mm') };
        this.updateDisplay(versionInfo);
      },
      updateDisplay(versionInfo) {
        this.$emit('update-display', versionInfo);
      },
      getItemText(item) {
        return `${moment(item.date_of_modification).format('L')} - ${item.version_name}`;
      },
    },
  };
</script>
