<template>
  <b>{{ $t('MANAGE.CONFIG.SPLIT_PLANNING.TITLE') }}</b>
  <v-card class="mt-2 pa-6">
    <div>{{ $t('MANAGE.CONFIG.SPLIT_PLANNING.DESC') }}</div>
    <div class="pt-3">
      <v-autocomplete v-model="selectedProjectId"
                      :items="projects" :item-title="(project) => project.title || $t('PLANNING.UNNAMED_PROJECT')" item-value="id"
                      :label="$t('MANAGE.CONFIG.SPLIT_PLANNING.PROJECT_TO_SPLIT')" :placeholder="selectedProjectId ? '' : $t('MULTIPROJECTS.NO_PROJECT_SELECTED')"
                      prepend-inner-icon="far fa-folder-open" :menu-props="{ location: 'bottom' }">
      </v-autocomplete>
      <div class="my-4">
        <date-field v-model="splitdate" :label="$t('MANAGE.CONFIG.SPLIT_PLANNING.SPLIT_DATE')" format="dddd DD MMMM YYYY" readonly no-null prepend-inner-icon="far fa-calendar-alt"></date-field>
      </div>
      <v-checkbox v-model="disclaimer" class="mt-6">
        <template #label>
          <span>
            {{ $t('MANAGE.CONFIG.SPLIT_PLANNING.DISCLAIMER') }}
            <b>{{ $t('MANAGE.CONFIG.SPLIT_PLANNING.DISCLAIMER2') }}</b>
          </span>
        </template>
      </v-checkbox>
      <v-btn :disabled="! btnEnabled" :loading="splitInProgress" rounded size="large" color="primary" block class="mt-2" @click="splitPlanning">
        {{ $t('MANAGE.CONFIG.SPLIT_PLANNING.SPLIT_ON') }} {{ $filters.moment(splitdate, 'mediumDate') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    data() {
      return {
        selectedProjectId: null,
        disclaimer: false,
        splitInProgress: false,
        splitdate: moment().startOf('day'),
      };
    },
    computed: {
      btnEnabled() {
        return this.disclaimer && this.selectedProjectId && this.splitdate;
      },
      ...mapState('manage', ['projects']),
    },
    methods: {
      splitPlanning() {
        if (! this.btnEnabled) return; // anti html hack
        this.splitInProgress = true;
        window.apiSrv.call(`plannings/tasks/splitondate/${this.selectedProjectId}`, 'store', { split_date: this.splitdate.format() }).then((response) => {
          this.$store.dispatch('ui/msgbox/open', { title: this.$t('MANAGE.CONFIG.SPLIT_PLANNING.SPLIT_SUCCESSFUL'), body: response?.data?.message || "" });
          this.selectedProjectId = null;
          this.$store.dispatch('manage/loadProjects');
        }).catch((message) => {
          if (message) this.$store.dispatch('ui/msgbox/open', { title: this.$t('MANAGE.CONFIG.SPLIT_PLANNING.ERROR_NOT_MODIFIED'), body: message || "" });
        }).then(() => {
          this.splitInProgress = false;
        });
      },
    },
  };
</script>
