<template>
  <v-container class="pt-6 text-center">
    <v-icon color="errorred" size="x-large">far fa-trash-alt</v-icon>
    <h4 class="text-primary mt-12">{{ $t('INTEGRATIONS.MICROSOFTTEAMS.DELETE_TAB') }}</h4>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        microsoftTeams: {},
      };
    },
    created() {
      import('@microsoft/teams-js').then((teams) => {
        this.microsoftTeams = teams;
        this.microsoftTeams.initialize();
        this.updateTeamsParams();
      });
    },
    methods: {
      updateTeamsParams() {
        // Reset microsoft teams params
        this.microsoftTeams.settings.registerOnRemoveHandler((removeEvent) => {
          this.microsoftTeams.settings.getSettings((settings) => {
            settings.websiteUrl = "";
            settings.contentUrl = "";
          });
          removeEvent.notifySuccess();
        });
      },
    },
  };
</script>
