<template>
  <div>
    <div v-show="openedTabIndex == 'export-image'" class="flex-wrap flex-md-nowrap gap-8" style="display: flex;">
      <div style="flex: 0 1 400px">
        <template v-if="isKanbanMode">
          <div class="mb-1">{{ $t('EXPORT.STEPS') }}</div>
          <v-select v-model="selectedStepsList" :items="availableSteps" item-value="id" item-title="label" multiple prepend-inner-icon="far fa-th-list">
            <template #prepend-item>
              <filter-toggle :selected-ids="selectedStepsList" :sublist-ids="availableStepsIds"
                             @update="selectedStepsList = $event">
                {{ $t('EXPORT.ALL_STEPS') }} ({{ availableStepsIds.length }})
              </filter-toggle>
              <v-divider></v-divider>
            </template>
            <template #selection="{ item: { raw: item }, index }">
              <span v-if="selectedStepsList.length == 1">{{ item.label }}</span>
              <span v-else-if="selectedStepsList.length == availableSteps.length && index === 0">{{ $t('EXPORT.ALL_STEPS') }} ({{ availableSteps.length }})</span>
              <span v-else-if="index === 0">{{ selectedStepsList.length }}/{{ availableSteps.length }}</span>
            </template>
          </v-select>
          <div class="mt-4"></div>
        </template>
        <template v-else>
          <div class="d-flex column-gap-4">
            <div style="flex: 0 1 50%">
              <date-field v-model="startdate" :label="$t('TIMELINE.START_DATE')" format="DD MMMM YYYY" readonly no-null prepend-inner-icon="far fa-calendar-alt"></date-field>
            </div>
            <div style="flex: 0 1 50%">
              <date-field v-model="enddate" :label="$t('TIMELINE.HORIZON_DATE')" format="DD MMMM YYYY" readonly no-null prepend-inner-icon="far fa-flag-checkered"></date-field>
            </div>
          </div>
          <div class="mt-4"></div>
          <btn-toggle-field v-model="timeline" :items="availableTimelineOptions" :label="$t('TIMELINE.TIMELINE')"></btn-toggle-field>
          <div class="mt-4"></div>
          <btn-toggle-field v-model="subtimeline" :items="availableSubtimelineOptions" :label="$t('TIMELINE.SUB_TIMELINE')"></btn-toggle-field>
          <div class="mt-4"></div>
          <btn-toggle-field v-model.number="colwidth" :items="availableColWidthOptions" :label="$t('TIMELINE.TIMELINE_WIDTH')"></btn-toggle-field>
          <div class="mt-4"></div>
        </template>
        <div class="mb-1">{{ $t('EXPORT.LANES') }}</div>
        <v-select v-model="selectedLanesList" :items="availableLanes" item-value="id" item-title="label" multiple prepend-inner-icon="far fa-th-list">
          <template #prepend-item>
            <filter-toggle :selected-ids="selectedLanesList" :sublist-ids="availableLanesIds"
                           @update="selectedLanesList = $event">
              {{ $t('EXPORT.ALL_LANES') }} ({{ availableLanesIds.length }})
            </filter-toggle>
            <v-divider></v-divider>
          </template>
          <template #selection="{ item: { raw: item }, index }">
            <span v-if="selectedLanesList.length == 1">{{ item.label }}</span>
            <span v-else-if="selectedLanesList.length == availableLanes.length && index === 0">{{ $t('EXPORT.ALL_LANES') }} ({{ availableLanes.length }})</span>
            <span v-else-if="index === 0">{{ selectedLanesList.length }}/{{ availableLanes.length }}</span>
          </template>
        </v-select>
        <div class="mt-4"></div>
        <div class="mb-1">{{ $t('EXPORT.QUALITY') }}</div>
        <v-tooltip :disabled="isPremium" location="center">
          <template #activator="{ props }">
            <div class="d-inline-block" v-bind="props">
              <btn-toggle-field v-model="format" :items="availableFormatOptions"></btn-toggle-field>
            </div>
          </template>
          <span>{{ $t('PREMIUM.PREMIUM_FEATURE') }}</span>
        </v-tooltip>
      </div>

      <div class="flex-grow-1">
        <v-card class="pa-4" style="height: 100%; display: flex; flex-direction: column;">
          <div class="d-flex align-center">
            <v-btn color="primary" rounded size="large" @click="refreshExport()">
              <span v-if="format == 'img'">{{ $t('EXPORT.GENERATE_IMAGE') }}</span>
              <span v-else-if="format == 'hd'">{{ $t('EXPORT.GENERATE_HD') }}</span>
              <span v-else-if="format == 'pdf'">{{ $t('EXPORT.GENERATE_PDF') }}</span>
            </v-btn>
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn :href="imgurl" :disabled="! imgurl" icon size="small" elevation="2" color="primary" target="_blank" rel="noopener" class="ml-auto" v-bind="props">
                  <v-icon size="20">far fa-file-download</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('EXPORT.SAVE_FILE') }}</span>
            </v-tooltip>
          </div>
          <div class="py-3"></div>
          <div v-show="errormsg" class="pa-4">{{ errormsg }}</div>
          <div style="min-height:300px;position:relative;margin-bottom: 15px;flex:1 0 auto">
            <a v-if="imgformat != 'pdf'" v-show="imgurl" :href="imgurl" target="_blank" rel="noopener">
              <img :src="imgurl" style="width:100%">
            </a>
            <!-- object needs to be destroyed with v-if to reload correctly with new data -->
            <object v-if="imgformat == 'pdf' && imgurl && ! imgloading" :data="imgurl" type="application/pdf" width="100%" height="100%"
                    style="height: 100%; width: 100%; min-height: 300px;">
              <p>This browser does not support PDFs. Please download the PDF to view it.</p>
            </object>
            <div v-show="imgloading" style="position:absolute;top:0;left:0;width:100%;height:100%;background:#eee;background:rgba(200,200,200,.8)">
              <span style="position: absolute;top:50%;left:50%;margin-top: -15px;"><i class="far fa-spinner fa-spin fa-2x fa-fw"></i></span>
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <export-csv v-show="openedTabIndex == 'export-csv'" :planning="planning"></export-csv>
  </div>
</template>

<script>
  import defaultConfig from '@/js/defaultConfig';
  import ExportCsv from './ExportCsv.vue';

  export default {
    components: {
      ExportCsv,
    },
    props: {
      planning: { type: Object, required: true },
      planningViewId: { type: Number, default: 0 },
      openedTabIndex: { type: String, required: true },
    },
    emits: ['close'],
    data() {
      return {
        options: { id: this.planningViewId || this.planning.id, rotoken: this.planning.meta.read_only_token, hd: '', pdf: '', urlparams: '' },
        imgloading: false,
        imgformat: '',
        imgurl: null,
        errormsg: "",
        timeline: this.planning.timeline.steps[0],
        subtimeline: this.planning.timeline.steps.length >= 2 ? this.planning.timeline.steps.at(-1) : 'none',
        timelocale: this.planning.timeline.timelocale,
        colwidth: this.planning.timeline.colwidth,
        selectedLanesList: [],
        selectedStepsList: [],
        format: 'img',
        timelineOptions: defaultConfig.timelineOptions(),
        availableColWidthOptions: defaultConfig.availableColWidthOptions(),
        startdate: this.planning.visibleTimeline.starttime,
        enddate: this.planning.visibleTimeline.endtime,
      };
    },
    computed: {
      availableLanes() {
        return this.planning.lanes;
      },
      availableLanesIds() {
        return this.availableLanes.map(item => item.id);
      },
      availableTimelineOptions() {
        return this.timelineOptions.filter(item => item.value != 'none');
      },
      availableSubtimelineOptions() {
        return this.timelineOptions.slice(this.timelineOptions.findIndex(item => item.value == this.timeline) + 1);
      },
      availableFormatOptions() {
        return [
          { value: 'img', title: this.$t('EXPORT.STANDARD') },
          { value: 'hd', title: this.$t('EXPORT.HD'), disabled: ! this.isPremium },
          { value: 'pdf', title: this.$t('EXPORT.PDF'), disabled: ! this.isPremium },
        ];
      },
      availableSteps() {
        return this.planning.process.steps;
      },
      availableStepsIds() {
        return this.availableSteps.map(item => item.id);
      },
      isKanbanMode() { return this.$store.state.ui.planning.mode == 'kanban'; },
      isPremium() { return this.$store.state.users.accessRight.isPremium; },
    },
    watch: {
      timeline() {
        this.subtimeline = this.availableSubtimelineOptions[0].value;
        this.updateTimelineWidth();
      },
      subtimeline() {
        this.updateTimelineWidth();
      },
      startdate() {
        this.updateTimelineWidth();
      },
      enddate() {
        this.updateTimelineWidth();
      },
    },
    created() {
      const subdomain = window.location.hostname.replace(/^(.*)\.bubbleplan\.net/, '$1');
      if (subdomain != window.location.hostname) this.options.subdomain = subdomain;
      this.selectedLanesList = this.availableLanesIds.slice();
      this.selectedStepsList = this.availableStepsIds.slice();
    },
    methods: {
      close() {
        this.$emit('close');
      },
      safeProjectName() {
        return this.planning.meta.title.replace(/<(?:.|\n)*?>/gm, ' ').replace(/&nbsp;/g, " ").replace(/&amp;/g, "&").replace(/&gt;/g, ">")
          .replace(/&lt;/g, "<")
          .replace(/\//g, ' ');
      },
      generateExport() {
        this.imgloading = true;
        const api = this.planningViewId ? 'view' : 'planning';
        this.imgformat = this.options.pdf ? 'pdf' : 'png';
        window.apiSrv.call(api, 'export', this.options).then((result) => {
          if (result.data) this.imgurl = `${result.data.imgurl}/${this.safeProjectName()}.${this.imgformat}`;
          this.imgloading = false;
        }).catch((message) => {
          this.errormsg = message || "Unable to load export : Please try again";
          this.imgloading = false;
        });
      },
      refreshExport() {
        this.errormsg = "";
        this.options.hd = this.format == 'hd' ? "1" : "";
        this.options.pdf = this.format == 'pdf' ? "1" : "";
        this.options.urlparams = `hiddenbefore=${moment(this.startdate).toISOString()}`
          + `&hiddenafter=${moment(this.enddate).toISOString()}`
          + `&steps=${this.timeline}${this.subtimeline !== 'none' ? `,${this.subtimeline}` : ""}`
          + `&colwidth=${this.colwidth}`
          + `&laneshidden=${_.without(this.availableLanesIds, ...this.selectedLanesList).join(",")}`
          + `&stepshidden=${_.without(this.availableStepsIds, ...this.selectedStepsList).map(item => item || 'backlog').join(",")}`
          + `&mode=${this.isKanbanMode ? 'kanban' : ''}`;
        this.generateExport();
      },
      updateTimelineWidth() {
        const steps = [this.timeline];
        if (this.subtimeline !== 'none') steps.push(this.subtimeline);
        this.colwidth = this.planning.timeline.bestColWidth(this.startdate, this.enddate, steps);
      },
    },
  };
</script>
