<template>
  <div class="element-details">
    <teleport v-if="el" to="#planning-drawer-title">
      <div class="rounded-t" :class="isMilestone ? null : `el-color-${color}-${colorShade} bg-el-color`"
           style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; transition-duration: .1s">
      </div>
      <div class="flex-center-center" style="position: relative">
        <div v-if="elIcon.name" class="bg-white rounded-circle mr-2 flex-center-center" style="padding: 6px">
          <v-icon :color="elIcon.color" size="x-small" style="transition-duration: .1s">{{ elIcon.name }}</v-icon>
        </div>
        {{ elTitle }}
      </div>
    </teleport>
    <!-- MENU -->
    <div v-if="el && cards.length > 1" :class="{ invisible: ! menu.show }"
         class="element-details-menu d-flex justify-center" style="pointer-events: none"
         v-scroll:#planning-drawer-scroll-container="onScroll">
      <div class="d-inline-flex bg-secondary rounded-b elevation-2 px-3" style="pointer-events: all">
        <v-tooltip v-for="card in cards" :key="card.id" location="bottom">
          <template #activator="{ props }">
            <v-btn-icon size="x-small" v-bind="props" @click="displayCard(card.id)">
              <v-icon size="large">{{ card.icon }}</v-icon>
            </v-btn-icon>
          </template>
          {{ card.label }}
        </v-tooltip>
      </div>
    </div>

    <!-- CONTENT -->
    <div :class="compact ? null : 'pb-8'" class="color-picker element-details-content">
      <template v-for="card in cards" :key="card.id">
        <div :id="`edcard-${card.id}`" class="element-details-card">
          <h3 v-if="! compact" class="text-primary d-inline-flex align-center pointer" @click="display[card.id] = ! display[card.id]">
            <v-icon size="small" style="width: 32px">{{ card.icon }}</v-icon>
            {{ card.label }}
            <v-icon v-if="! display[card.id]" end class="align-self-end">far fa-angle-right</v-icon>
          </h3>
          <v-expand-transition>
            <div v-show="display[card.id]" :class="compact ? 'pa-0' : 'pt-2 pr-8'" class="element-details-card-content">
              <component v-if="card.id == 'colors'" :is="card.customComponent ? 'client-custom-component' : 'color-picker-color'" v-bind="card.customComponent && { name: card.customComponent }"
                         v-model:color="color" v-model:color-shade="colorShade"
                         :target="target" :planning="planning" :disable-renaming="disableRenaming" :compact="compact"
                         @selected="selected">
              </component>
              <component v-else-if="card.id == 'icons'" :is="card.customComponent ? 'client-custom-component' : 'color-picker-icon'" v-bind="card.customComponent && { name: card.customComponent }"
                         v-model:icon="icon" :target="target" :planning="planning" :disable-renaming="disableRenaming"
                         @selected="selected">
              </component>
              <color-picker-time-bar v-else-if="card.id == 'timebar'" v-model:time-bar-color-id="timeBarColorId" :target="target"
                                     @selected="selected">
              </color-picker-time-bar>
            </div>
          </v-expand-transition>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
  .color-picker {
    .color-block {
      margin: 2px 3px 0;
      width: 40px;
      overflow: hidden;
    }
    .color-square {
      height: 28px;
      width: 28px;
      margin: auto;
      border-radius: 50%;
    }
    .color-picker-label-input {
      width: 100%;
      text-align: center;
      border-width: 0 0 1px;
      font-size: 10px;
    }
    .color-picker-label {
      font-size: 10px;
      line-height: 1.4;
    }
  }
</style>

<script>
  import clientConfig from '@/client_customs/config';
  import { useAlertBeforeUnload } from '@/components/ElementDetails/partials/alertBeforeUnload';
  import ColorPickerColor from '@/components/ColorPicker/ColorPickerColor.vue';
  import ColorPickerIcon from '@/components/ColorPicker/ColorPickerIcon.vue';
  import ColorPickerTimeBar from '@/components/ColorPicker/ColorPickerTimeBar.vue';

  export default {
    components: {
      ColorPickerColor,
      ColorPickerIcon,
      ColorPickerTimeBar,
    },
    props: {
      target: { type: Object, required: true }, // element or lane or kanbanHeader
      planning: { type: Object, required: true },
      disableRenaming: { type: Boolean, default: false },
      compact: { type: Boolean, default: false },
    },
    emits: ['close'],
    setup(props, { emit }) {
      if (props.target.isType?.('macro', 'milestone', 'task')) { // only for els (in planning drawer)
        useAlertBeforeUnload(props.target, () => emit('close'));
      }
    },
    data() {
      return {
        el: this.target.isType?.('macro', 'milestone', 'task') ? this.target : null,
        color: this.target.getColorId(),
        colorShade: this.target.getColorShadeId(),
        icon: this.target.getIconId?.(),
        timeBarColorId: this.target.getTimeBar?.().colorId,
        planningIcons: this.planning.config.icons,
        display: {
          colors: true,
          icons: true,
          timebar: true,
        },
        menu: {
          scrollTop: 0,
          show: true,
          clickTime: 0,
        },
      };
    },
    computed: {
      isMilestone() {
        return this.el && this.el.isType('milestone');
      },
      elTitle() {
        if (! this.el) return '';
        const defaultTitle = this.isMilestone ? this.$t('PLANNING.MILESTONE') : this.$t('PLANNING.NEW_BUBBLE');
        return this.el.getTitle() || defaultTitle;
      },
      showColors() {
        return ! this.isMilestone; // lane or element (not milestone) or kanbanHeader
      },
      showIcons() {
        return !! this.target.getIconId; // element or kanbanHeader
      },
      showTimeBar() {
        return !! this.el;
      },
      elIcon() {
        return this.planningIcons[this.icon] || {};
      },
      cards() {
        const cards = clientConfig.colorsOptions?.cardsForTarget?.(this.target) || [
          { id: 'colors', icon: 'far fa-tint', label: this.$t('COLORPICKER.COLOR') },
          { id: 'icons', icon: 'far fa-icons', label: this.$t('COLORPICKER.ICON') },
          { id: 'timebar', icon: 'far fa-grip-lines-vertical', label: this.$t('COLORPICKER.VERTICAL_MARKER') },
        ];
        return cards.filter((card) => {
          if (card.id == 'colors') return this.showColors;
          if (card.id == 'icons') return this.showIcons;
          if (card.id == 'timebar') return this.showTimeBar;
          return true;
        });
      },
    },
    methods: {
      onScroll(e) {
        const scrollDiff = e.target.scrollTop - this.menu.scrollTop;
        if (scrollDiff > 0 || scrollDiff < -70) {
          if (Date.now() > this.menu.clickTime + 1000) this.menu.show = scrollDiff < 0; // do not show menu if scroll is due to click on menu
          this.menu.scrollTop = e.target.scrollTop;
        }
      },
      displayCard(prop) {
        this.display[prop] = true;
        const displayNode = document.getElementById(`edcard-${prop}`);
        if (displayNode && displayNode.scrollIntoView) {
          this.menu.show = false;
          this.menu.clickTime = Date.now();
          setTimeout(() => displayNode.scrollIntoView({ behavior: 'smooth' }));
        }
      },
      selected() {
        if (! this.el) this.$emit('close');
      },
    },
  };
</script>
