<template>
  <v-menu v-model="dropdownIsOpen" :close-on-content-click="false" location="bottom center" transition="slide-y-transition" offset="4" :max-width="500">
    <template #activator="{ props: onMenu }">
      <v-tooltip :disabled="dropdownIsOpen" location="bottom">
        <template #activator="{ props: onTooltip }">
          <v-btn variant="text" icon size="small" v-bind="mergeProps(onMenu, onTooltip)">
            <v-badge :model-value="!! unseenNotifications.length" :content="unseenNotifications.length" color="errorred" offset-x="-2" offset-y="-4">
              <v-icon>far fa-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>{{ $t('NOTIFICATIONS.NOTIFICATIONS') }}</span>
      </v-tooltip>
    </template>

    <!-- list of sections with unseen notifications -->
    <v-list v-if="! selectedSection" density="comfortable" class="py-0">
      <v-list-subheader style="position: relative">
        <div class="flex-center-center">
          {{ $t('NOTIFICATIONS.NOTIFICATIONS_COUNT', unseenNotifications.length) }}
          <v-tooltip location="bottom" max-width="200">
            <template #activator="{ props }">
              <v-icon color="info" style="position: absolute; right: 12px" v-bind="props">far fa-question-circle</v-icon>
            </template>
            <span>{{ $t('NOTIFICATIONS.FIND_HERE_NOTIFICATIONS') }}</span>
          </v-tooltip>
        </div>
      </v-list-subheader>
      <v-list-item v-for="sectionNotif in unseenNotificationsCountBySection" :key="`${sectionNotif.section_type}|${sectionNotif.section_id}`"
                   :title="sectionNotif.section_type == 'planning' ? projectsMap.get(sectionNotif.section_id)?.title || '...' : null"
                   :subtitle="$t('NOTIFICATIONS.NOTIFICATIONS_COUNT', sectionNotif.count)"
                   @click="selectedSection = sectionNotif">
        <template #prepend>
          <v-img v-if="sectionNotif.section_type == 'planning'" :src="projectsMap.get(sectionNotif.section_id)?.imgurl" :width="48" :max-height="40" cover position="0 0" class="mr-2"></v-img>
          <v-icon v-else size="small" start>far fa-check-square</v-icon>
        </template>
        <template #append>
          <v-btn-icon :to="notifSectionUrl(sectionNotif)" size="small" class="ml-2 mr-n2" @click.stop><v-icon>far fa-external-link</v-icon></v-btn-icon>
        </template>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item to="/manage#profile-notifications" :active="false">
        <div class="d-flex align-center">
          <v-icon size="small" start>far fa-cog</v-icon>
          {{ $t('ACCOUNT.CONFIGURE_EMAILS') }}
        </div>
      </v-list-item>
    </v-list>

    <!-- selectedSection notifications -->
    <v-list v-else density="compact" class="py-0">
      <v-list-subheader style="padding-inline-start: 4px !important; position: relative">
        <div class="flex-center-center">
          <v-btn-icon @click="selectedSection = null" size="small"><v-icon>far fa-chevron-left</v-icon></v-btn-icon>
          {{ selectedSectionTitle }}
          <v-btn-icon v-if="hasUnseenNotifications" size="small" color="successgreen" style="position: absolute; right: 0" @click="seenAllNotifications">
            <v-icon>fas fa-check-circle</v-icon>
          </v-btn-icon>
        </div>
        <div v-if="! selectedSectionNotifications.length" class="pl-4 pb-2">{{ $t('NOTIFICATIONS.NOTIFICATIONS_COUNT', 0) }}</div>
      </v-list-subheader>
      <v-list-item v-for="notif in selectedSectionNotifications" :key="notif.id">
        <template #prepend>
          <v-icon :style="{ visibility: notif.seen_at ? 'hidden' : '' }" color="successgreen" size="9" start>fas fa-circle</v-icon>
          <user-avatar v-if="notif.user" :user="notif.user" tooltip></user-avatar>
        </template>
        <v-list-item-title class="line-height-normal mb-.5" style="white-space: normal" @click.stop>
          <router-link v-if="notifHasTarget(notif)" :to="notifTargetUrl(notif)">{{ notif.message }}</router-link>
          <span v-else>{{ notif.message }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $filters.moment(notif.date_of_creation, 'fromNow') }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-divider></v-divider>
      <navigation-notifications-planning-switch v-if="planning && selectedSection.section_type == 'planning' && selectedSection.section_id == planning.id" :planning="planning">
      </navigation-notifications-planning-switch>
    </v-list>
  </v-menu>
</template>

<script>
  import { mergeProps } from 'vue';
  import ProjectSrv from '@/components/Projects/ProjectSrv';
  import NavigationNotificationsPlanningSwitch from './NavigationNotificationsPlanningSwitch';

  export default {
    props: {
      planning: { type: Object, default: null },
    },
    components: {
      NavigationNotificationsPlanningSwitch,
    },
    data() {
      return {
        dropdownIsOpen: null,
        projectsMap: new Map(),
        selectedSection: null, // { section_type: 'planning', section_id: 123 }
      };
    },
    computed: {
      unseenNotifications() {
        return this.$store.getters['notifications/unseen'];
      },
      unseenNotificationsCountBySection() {
        const bySection = this.unseenNotifications.reduce((acc, notif) => {
          if (! acc[`${notif.section_type}|${notif.section_id}`]) acc[`${notif.section_type}|${notif.section_id}`] = { section_type: notif.section_type, section_id: notif.section_id, count: 0 };
          acc[`${notif.section_type}|${notif.section_id}`].count++;
          return acc;
        }, {});
        return Object.values(bySection);
      },
      notifications() {
        return this.$store.state.notifications.all;
      },
      selectedSectionNotifications() {
        return this.selectedSection && this.notifications.filter(notif => notif.section_type == this.selectedSection.section_type && notif.section_id == this.selectedSection.section_id)
          .sort((a, b) => (a.date_of_creation < b.date_of_creation ? 1 : -1));
      },
      selectedSectionTitle() {
        if (this.selectedSection?.section_type == 'planning') {
          if (this.planning && this.selectedSection.section_id == this.planning.id) return this.planning.getTitle();
          return this.projectsMap.get(this.selectedSection.section_id)?.title || null;
        }
        return null;
      },
      hasUnseenNotifications() {
        return this.selectedSectionNotifications && this.selectedSectionNotifications.some(notif => ! notif.seen_at);
      },
    },
    watch: {
      dropdownIsOpen(newVal) {
        if (! newVal && this.planning) {
          // reset selectedSection on closing
          setTimeout(() => {
            if (! this.dropdownIsOpen) this.selectedSection = { section_type: 'planning', section_id: this.planning.id };
          }, 1000);
        }

        // load projects data on first open
        if (! newVal || this.projectsMap.size || ! this.unseenNotifications.length) return;
        ProjectSrv.list(true).then((projects) => {
          projects.forEach((project) => {
            this.projectsMap.set(project.id, project);
          });
        });
      },
      'planning.id': {
        handler(newVal) {
          if (newVal) this.selectedSection = { section_type: 'planning', section_id: this.planning.id };
        },
        immediate: true,
      },
    },
    mounted() {
      if (this.$route.query.open == 'notifications') {
        this.$router.replace({ query: { ...this.$route.query, open: undefined } });
        // this.dropdownIsOpen = true; // bug to fix : dropdown position
      }
    },
    methods: {
      notifSectionUrl(sectionNotif) {
        if (sectionNotif.section_type == 'planning') {
          const project = this.projectsMap.get(sectionNotif.section_id);
          if (! project) return `/planning/${sectionNotif.section_id}?open=notifications`;
          return `/${(project.access_right == 'consult' ? 'viewer/' : 'planning/') + project.id}/${window.slugify(project.title || "New project")}?open=notifications`;
        }
        return '/analysis/actions';
      },
      notifHasTarget(notif) {
        if (notif.section_type == 'planning' && notif.section_id) {
          if (notif.target_type == 'element' && notif.target_id) return true;
          if (notif.target_type == 'exchangeCenter') return true;
        }
        return false;
      },
      notifTargetUrl(notif) {
        if (notif.section_type == 'planning' && notif.section_id && notif.target_type == 'element' && notif.target_id) {
          return `${this.projectUrl(notif)}?el=${parseInt(notif.target_id, 10)}`;
        }
        if (notif.section_type == 'planning' && notif.section_id && notif.target_type == 'exchangeCenter') {
          return `${this.projectUrl(notif)}?open=exchanges`;
        }
        return null;
      },
      projectUrl(notif) {
        if (notif.section_type != 'planning' || ! notif.section_id) return null;
        const project = this.projectsMap.get(notif.section_id);
        if (! project) return null;
        const { title: projectTitle, access_right: projectAccessRight } = project;
        const isConsult = projectAccessRight == 'consult';
        return `/${isConsult ? 'viewer/' : 'planning/'}${project.id}/${window.slugify(projectTitle || "New project")}`;
      },
      seenAllNotifications() {
        window.notificationsSrv.seenNotifs(this.selectedSection);
      },
      mergeProps,
    },
  };
</script>
